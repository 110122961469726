import React from 'react';
import ReactDOM from 'react-dom';
import { connectToSocket } from 'services/socketService/connectToSocket';
import { setConnectToSocket } from 'utils/connectToSocketProxy';
import {
    setEmotionsAnimations,
    setEmotionsAssetsTypeAll,
    setEmotionsAssetsTypeList,
} from 'utils/emotionsAssets';
import App from './App';
import './index.css';
import * as serviceWorkerRegistration from './serviceWorkerRegistration';

if (!document.location.pathname.startsWith('/ws/')) {
    setConnectToSocket(connectToSocket);
    setEmotionsAssetsTypeList(require.context('assets/emotions/', false, /(.?)List/));
    setEmotionsAssetsTypeAll(require.context('assets/emotions/', false));
    setEmotionsAnimations(require.context('assets/emotions/animations/', false));
    ReactDOM.render(
        <React.StrictMode>
            <App />
        </React.StrictMode>,
        document.getElementById('root'),
    );
}

serviceWorkerRegistration.register();
