import { t } from '@lingui/macro';
import { ReactComponent as RecordSvg } from 'assets/Record.svg';
import { HoverLabel } from 'components/common/HoverLabel';
import { ConferenceButton } from 'components/ConferenceButton';
import styles from './ConferenceRecordBtn.module.scss';
import { IDispatchProps, IStateProps } from './types';
import { getButtonText, getTooltipText } from './utils';
import { classNames } from 'utils/classNames';

export const ConferenceRecordBtn: React.FC<IDispatchProps & IStateProps> = ({
    toggleRecordState,
    recording,
    participantIsOwner,
}) => {
    const clickHandler = () => {
        if (participantIsOwner) {
            toggleRecordState();
        }
    };

    const imgWrapperDynamicStyles = classNames([
        recording === 'on' ? styles.imgWrapper_active : '',
        recording === 'terminate' || recording === 'launch' ? styles.imgWrapper_processing :'',
        !participantIsOwner ? styles.imgWrapper_disabled : '',
    ]);

    const buttonStyle = classNames([
        participantIsOwner ? '' : styles.btn_disabled,
        participantIsOwner && (recording === 'terminate' || recording === 'launch')
            ? styles.btn_processing
            : '',
    ]);
    const tooltip = getTooltipText({ participantIsOwner, recording });
    const text = getButtonText({ recording, participantIsOwner });
    return (
        <div className={classNames([styles.wrapper])}>
            <div className={styles.tooltipLabel}>
                <HoverLabel>{tooltip}</HoverLabel>
            </div>
            <ConferenceButton text={text} clickHandler={clickHandler} className={buttonStyle}>
                <div className={`${styles.imgWrapper} ${imgWrapperDynamicStyles}`}>
                    <RecordSvg className={styles.recordImg} />
                </div>
            </ConferenceButton>
        </div>
    );
};
