import { ConferenceConnectionSettingsFormMobile } from 'components/forms/ConferenceConnectionSettingsFormMobile';
import { MobilePageTemplate } from 'components/MobilePageTemplate';
import { IDispatchProps, IOwnProps, IStateProps } from './types';

export const JoinConferencePageMobile: React.FC<IDispatchProps & IStateProps & IOwnProps> = ({
    displayName,
    onClickBack,
    prepareToConferenceConnectMobile,
}) => (
    <MobilePageTemplate onClickBack={onClickBack}>
        <ConferenceConnectionSettingsFormMobile
            prepareToConferenceConnectMobile={prepareToConferenceConnectMobile}
            initialValues={{ displayName }}
        />
    </MobilePageTemplate>
);
