import { setViewSettingsAction } from 'actions/applicationView/setViewSettings';
import { connect } from 'react-redux';
import { TAppDispatch } from 'types/app/TAppDispatch';
import { TAppState } from 'types/app/TAppStore';
import { ConferenceViewButton } from './ConferenceViewButton';
import { IDispatchProps, IStateProps } from './types';

const mapStateToProps = (state: TAppState): IStateProps => ({
    isConferenceOwner: state.conferenceSession.currentUserIsOwner,
    maxVideoFrames: state.applicationView.maxVideoFrames,
});

const mapDispatchToProps = (dispatch: TAppDispatch): IDispatchProps => ({
    changeDisplayView: (settings) => dispatch(setViewSettingsAction(settings)),
});

export const ConferenceViewButtonConnected = connect(
    mapStateToProps,
    mapDispatchToProps,
)(ConferenceViewButton);
