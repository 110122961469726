import FilterImg from 'assets/FilterGray.svg';
import { format, getYear } from 'date-fns';
import React, { useRef, useState } from 'react';
import { default as ReactDatePicker } from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import { getCurrentDate } from 'utils/dates/getCurrentDate';
import { getLocaleByLanguage } from 'utils/getLocaleByLanguage';
import styles from './DatePickerMobile.module.scss';
import { IOwnPropsMobile, IStateProps } from './types';
import { getCustomHeader, getInitialDates } from './utils';

export const DatePickerMobile: React.FC<IStateProps & IOwnPropsMobile> = ({
    lang,
    handleDateChange,
    end,
    start,
    maxDate,
    minDate,
}) => {
    const initialValues = getInitialDates({ from: start, to: end });
    const componentRef = useRef<ReactDatePicker>(null);

    const [dates, setDates] = useState<{ start: Date | null; end: Date | null }>(initialValues);

    const [year, setYear] = useState(
        initialValues.start ? getYear(initialValues.start) : getYear(getCurrentDate()),
    );

    const resetYear = () => {
        setYear(dates.start ? getYear(dates.start) : getYear(getCurrentDate()));
    };

    const renderDayContents = (day: number) => {
        return <span className='react-datepicker__dayNumber'>{day}</span>;
    };

    const onSave = () => {
        const { start, end } = dates;
        const formattedDate = {
            start: start ? format(start, 'dd.MM.yyyy') : undefined,
            end: end ? format(end, 'dd.MM.yyyy') : undefined,
        };
        handleDateChange(formattedDate);
        if (componentRef.current) componentRef.current.setOpen(false);
    };

    const onClose = () => {
        if (componentRef.current) {
            resetYear();
            setDates({ end: initialValues.end, start: initialValues.start });
            componentRef.current.setOpen(false);
        }
    };

    const CustomInput = React.forwardRef<
        any,
        React.DetailedHTMLProps<React.InputHTMLAttributes<HTMLDivElement>, HTMLDivElement>
    >((props, ref) => <div ref={ref} {...props} />);

    return (
        <div className={styles.datePicker}>
            <ReactDatePicker
                portalId='wrapper'
                ref={componentRef}
                customInput={<CustomInput />}
                popperClassName={styles.datePicker__popper}
                renderCustomHeader={({
                    monthDate,
                    customHeaderCount,
                    decreaseMonth,
                    increaseMonth,
                    changeYear,
                }) =>
                    getCustomHeader({
                        monthDate,
                        changeYear,
                        customHeaderCount,
                        decreaseMonth,
                        increaseMonth,
                        setYear,
                        year,
                        lang,
                        onClose,
                        onSave,
                    })
                }
                focusSelectedMonth
                shouldCloseOnSelect={false}
                selected={dates.start}
                renderDayContents={renderDayContents}
                startDate={dates.start}
                endDate={dates.end}
                calendarClassName={styles.datePicker__calendar}
                monthsShown={2}
                locale={getLocaleByLanguage(lang)}
                onChange={(date) => {
                    if (date) {
                        const datesAsArray = date as [Date | null, Date | null];
                        setDates({ start: datesAsArray[0], end: datesAsArray[1] });
                    } else {
                        setDates({ end: null, start: null });
                    }
                }}
                onCalendarClose={resetYear}
                dateFormat='dd.MM.yyyy'
                selectsRange
                maxDate={maxDate}
                minDate={minDate}
            />
            <img
                src={FilterImg}
                alt='Date picker'
                onClick={() =>
                    componentRef.current ? componentRef.current.setOpen(true) : undefined
                }
            />
        </div>
    );
};
