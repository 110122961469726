import { t } from '@lingui/macro';
import { Avatar } from 'components/common/Avatar';
import { DynamicHoverTooltip } from 'components/common/DynamicHoverTooltip';
import { useState } from 'react';
import { getUserColor } from 'utils/users/getUserColor';
import { IOwnProps } from './types';
import styles from './VideoCallMember.module.scss';

export const VideoCallMember: React.FC<IOwnProps> = ({ initials, avatar, name, email, userId }) => {
    const [isPointerOnMember, setIsPointerOnMember] = useState(false);

    const onMouseEnter = () => {
        setIsPointerOnMember(true);
    };
    const onMouseLeave = () => {
        setIsPointerOnMember(false);
    };

    const userBgColor = name.trim() ? getUserColor(name) : '#242424';
    return (
        <div
            className={styles.avatarWrapper}
            onMouseEnter={onMouseEnter}
            onMouseLeave={onMouseLeave}>
            {isPointerOnMember && (
                <DynamicHoverTooltip
                    name={name.trim() || email || ''}
                    description={userId ? '' : t({ id: 'Guest' })}
                />
            )}
            <Avatar
                initials={initials}
                image={avatar}
                style={{
                    margin: '6px',
                }}
                userBgColor={userBgColor}
            />
        </div>
    );
};
