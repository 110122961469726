import OutsideClick from 'components/common/OutsideClick';
import React from 'react';
import { IMenuItem } from 'types/IMenuItem';
import { getParticipantContextMenuItems } from 'utils/participants/getParticipantContextMenuItems';
import styles from './ParticipantContextMenuDropdown.module.scss';
import { IDispatchProps, IOwnProps, IStateProps } from './types';

export const ParticipantContextMenuDropdown: React.FC<IOwnProps & IStateProps & IDispatchProps> = ({
    setIsOpen,
    title,
    orientation,
    setLocalStreamProps,
    ...props
}) => {
    if (props.isCurrentUser) {
        props.changeAudioMute = () =>
            setLocalStreamProps({ useAudio: !props.audioVideoProps.useAudio });
        props.changeVideoMute = () =>
            setLocalStreamProps({ useVideo: !props.audioVideoProps.useVideo });
    }
    const items: IMenuItem[] = getParticipantContextMenuItems(props);

    const menuContainer = React.useCallback((node) => {
        if (node !== null) {
            const rect = node.getBoundingClientRect();
            if (rect.x < 0) {
                node.style.removeProperty('bottom');
                node.style.removeProperty('right');
                node.style.top = '35px';
                node.style.left = '0';
            } else {
                node.style.removeProperty('top');
                node.style.removeProperty('left');
                node.style.bottom = '35px';
                node.style.right = '0';
            }
        }
    }, []);

    const renderItems = () =>
        items.map((elem, idx) =>
            elem.text ? (
                <li
                    className={`${styles.item} ${elem.danger ? styles.item_red : ''} ${
                        elem.disabled ? styles.item_disable : ''
                    }`}
                    key={`${elem.id}${idx}`}
                    onClick={elem.disabled ? (evt) => evt.stopPropagation() : elem.onClick}>
                    {elem.text}
                </li>
            ) : null,
        );

    return (
        <OutsideClick onOutsideClick={() => setIsOpen(false)}>
            <div className={`${styles.listWrapper}  ${styles[`listWrapper_${orientation}`]}`}>
                {title && <div className={styles.header}>{title}</div>}
                <ul
                    // style={{ bottom: '35px', right: '0' }}
                    className={styles.list}
                    ref={menuContainer}>
                    {renderItems()}
                </ul>
            </div>
        </OutsideClick>
    );
};
