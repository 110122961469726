import { getMonthLastDay } from './getMonthLastDay';
import { EPredefinedDatesRange } from 'types/dates/EPredefinedDatesRange';
import { IDatesRange } from 'types/dates/IDatesRange';
import { getCurrentDate } from 'utils/dates/getCurrentDate';
import { getRangeForToday } from './getRangeForToday';
import { getWeekFirstDay } from './getWeekFirstDay';
import { setDateToDayEnd } from './setDateToDayEnd';
import { setDateToDayStart } from './setDateToDayStart';

export function getDatesByPredefinedRange(
    type: EPredefinedDatesRange | null,
    isPast: boolean,
): IDatesRange {
    const currentDate = getCurrentDate();
    let startDate: Date;
    let endDate: Date;
    switch (type) {
        case EPredefinedDatesRange.Today:
            return getRangeForToday();
        case EPredefinedDatesRange.Tomorrow: {
            const tomorrow = new Date(currentDate).setDate(currentDate.getDate() + 1);
            startDate = new Date(tomorrow);
            endDate = new Date(tomorrow);
            break;
        }
        case EPredefinedDatesRange.Yesterday: {
            const yesterday = new Date(currentDate).setDate(currentDate.getDate() - 1);
            startDate = new Date(yesterday);
            endDate = new Date(yesterday);
            break;
        }
        case EPredefinedDatesRange.ThisWeek: {
            const firstWeekDay = getWeekFirstDay(currentDate);
            startDate = isPast ? new Date(firstWeekDay) : new Date(currentDate);
            endDate = isPast
                ? new Date(currentDate)
                : new Date(firstWeekDay.setDate(firstWeekDay.getDate() + 6));
            break;
        }
        case EPredefinedDatesRange.LastWeek: {
            const firstWeekDay = getWeekFirstDay(currentDate);
            startDate = new Date(firstWeekDay.setDate(firstWeekDay.getDate() - 7));
            endDate = new Date(firstWeekDay.setDate(firstWeekDay.getDate() + 6));
            break;
        }
        case EPredefinedDatesRange.NextWeek: {
            const firstWeekDay = getWeekFirstDay(currentDate);
            startDate = new Date(firstWeekDay.setDate(firstWeekDay.getDate() + 7));
            endDate = new Date(firstWeekDay.setDate(firstWeekDay.getDate() + 6));
            break;
        }
        case EPredefinedDatesRange.ThisMonth: {
            startDate = new Date(currentDate);
            if (isPast) {
                startDate.setDate(1);
            }
            endDate = isPast ? new Date(currentDate) : getMonthLastDay(startDate);
            break;
        }
        case EPredefinedDatesRange.LastMonth: {
            startDate = new Date(currentDate);
            startDate.setDate(1);
            startDate.setMonth(startDate.getMonth() - 1);
            endDate = getMonthLastDay(startDate);
            break;
        }
        case EPredefinedDatesRange.NextMonth: {
            startDate = new Date(currentDate);
            startDate.setDate(1);
            startDate.setMonth(startDate.getMonth() + 1);
            endDate = getMonthLastDay(startDate);
            break;
        }
        default:
            return getRangeForToday();
    }
    setDateToDayStart(startDate);
    setDateToDayEnd(endDate);
    return { startDate, endDate };
}
