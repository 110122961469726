import { createAsyncThunk } from '@reduxjs/toolkit';
import { editUserRolesApiCall } from 'admin-app/api/user/editUserRolesApiCall';
import { EUserThunkActions } from 'admin-app/constants/thunk/EUserThunkActions';
import { TUserRole } from 'admin-app/types/IAdminPanelUser';
import { IApiCallErrorData } from 'admin-app/types/IApiCallErrorData';
import { getNetworkService } from 'api/getNetworkService';

interface IPayload {
    userId: string;
    roles: TUserRole[];
}

export const editUserRolesThunkAction = createAsyncThunk<IApiCallErrorData | undefined, IPayload>(
    EUserThunkActions.EDIT_USER_ROLES,
    async (data: IPayload) => {
        const { success, errorCode, errorDescription } = await editUserRolesApiCall(
            getNetworkService(),
            data,
        );
        if (!success) {
            return { errorCode, errorDescription };
        }
    },
);
