import { IOwnProps } from './types';
import { HoverLabel } from 'components/common/HoverLabel/HoverLabel';
import styles from './HoverTooltip.module.scss';

export const HoverTooltip: React.FC<IOwnProps> = ({ name, description, color }) => (
    <HoverLabel color={color}>
        <div className={styles.wrapper}>
            <div className={styles.name}>{name}</div>
            {description && <div className={styles.description}>{description}</div>}
        </div>
    </HoverLabel>
);
