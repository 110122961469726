import { useEffect, useRef, useState } from 'react';
import { createPortal } from 'react-dom';
import { IOwnProps } from './types';

export const Portal: React.FC<IOwnProps> = ({ attributes, container, children }) => {
    const [containerCreated, setContainerCreated] = useState<boolean>(false);
    const targetContainerNodeRef = useRef<Element | null>(null);
    const isAdded = useRef(false);

    const createElement = () => {
        const node = document.createElement('div');
        attributes?.forEach(({ name, value }) => {
            node.setAttribute(name, value);
        });
        targetContainerNodeRef.current = node;
        setContainerCreated(true);
    };

    const insertElement = () => {
        if (!targetContainerNodeRef.current) {
            return;
        }
        const element = container ? container : document.body;
        element?.appendChild(targetContainerNodeRef.current);
        isAdded.current = true;
    };

    const clearData = () => {
        targetContainerNodeRef.current && targetContainerNodeRef.current.remove();
        targetContainerNodeRef.current = null;
        isAdded.current = false;
        setContainerCreated(false);
    };

    useEffect(() => {
        createElement();
        return () => {
            clearData();
        };
    }, []);

    useEffect(() => {
        insertElement();
    }, [containerCreated]);

    if (!targetContainerNodeRef.current) {
        return null;
    }
    return createPortal(children, targetContainerNodeRef.current);
};
