import { createAction } from '@reduxjs/toolkit';
import { EConferencesActions } from 'constants/actions/EConferencesActions';
import { ESocketConferenceParticipantActionType } from 'types/conferences/ESocketConferenceParticipantActionType';
import { IConferenceParticipant } from 'types/IConferenceParticipant';

interface IPayload {
    actionType: ESocketConferenceParticipantActionType;
    participant: IConferenceParticipant;
}

export const updateStoreCurrentConferenceParticipantsAction = createAction<IPayload>(
    EConferencesActions.UPDATE_STORE_CONFERENCE_PARTICIPANTS_DATA,
);
