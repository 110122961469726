import { ConferencePageTemplateConnected } from 'pages/templates/ConferencePageTemplate';
import { useEffect } from 'react';
import { LoginForJoinFormConnected } from './LoginForJoinForm';
import { IDispatchProps, IStateProps } from './types';

export const LoginForJoinPage: React.FC<IStateProps & IDispatchProps> = ({
    conferenceConnectInfo,
    currentConferenceId,
    goToHomePage,
    isAuthUser,
}) => {
    useEffect(() => {
        if (!currentConferenceId || isAuthUser) {
            goToHomePage();
        }
    }, []);

    return (
        <ConferencePageTemplateConnected
            conferenceConnectInfo={conferenceConnectInfo}
            showLogo={true}>
            <LoginForJoinFormConnected id={currentConferenceId} />
        </ConferencePageTemplateConnected>
    );
};
