import { t } from '@lingui/macro';
//import { Trans } from '@lingui/react';
import { Button } from 'components/common/Button';
import { Cross } from 'components/common/Cross';
import styles from './ConferenceChatHeader.module.scss';
import { IOwnProps } from './types';
import { ReactComponent as ArrowSvgComponent } from 'assets/ArrowBack.svg';

export const ConferenceChatHeader: React.FC<IOwnProps> = ({
    historyMode,
    isMobile,
    isUltraSmallScreen,
    //exitConference,
    onClickCross,
    onClickBack,
}) => {
    const headerTextChat = t({ message: 'Чат', id: 'Chat' });
    const headerTextHistory = t({ message: 'История чата', id: 'Chat history' });

    const mobileTemplate = () => (
        <>
            <Button styleType='common' className={styles.backBtn} clickHandler={onClickBack}>
                <ArrowSvgComponent className={styles.backImg} />
            </Button>
            <span className={styles.mobileTitle}>
                {historyMode ? headerTextHistory : headerTextChat}
            </span>
            {/*<Button styleType='red' className={styles.exitBtn} clickHandler={exitConference}>
                <Trans id='Exit.conference' message='Выйти' />
            </Button>*/}
        </>
    );

    const desktopTemplate = () => (
        <>
            <span>{historyMode ? headerTextHistory : headerTextChat}</span>
            {!historyMode && (
                <Cross className={styles.cross} color={'gray'} onClick={onClickCross}></Cross>
            )}
        </>
    );

    return (
        <div className={styles.chatHeader}>
            {isMobile || isUltraSmallScreen ? mobileTemplate() : desktopTemplate()}
        </div>
    );
};
