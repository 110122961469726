import { removeRemoteStreamAction } from 'actions/janus/removeRemoteStreamAction';
import { startJanusPublisherStreamAction } from 'actions/janus/startJanusPublisherStreamAction';
import { TAppState } from 'types/app/TAppStore';
import { janusVideoRoomTracksLogger } from 'utils/logger';
import { janusCtx } from './janusCtx';

/**
 * janusCtx.subscriptions[feed_id][mid] - shows if we subscribed to mid of publisher
 * janusCtx.subStreams[mid] - stores { feed_id } for getting it by mid
 */

/**
 * Internal subscribe/unsubscribe on publisher streams
 * @param pluginHandler
 * @param sources
 * @returns
 */
const subscribeInternal = (pluginHandler: any, sources: any[]) => {
    let added = null;
    let removed = null;
    // Prepare the streams to subscribe to, as an array: we have the list of
    // streams the feeds are publishing, so we can choose what to pick or skip
    // const subscription = [];
    for (const streams of sources) {
        for (const stream of streams) {
            // If the publisher is VP8/VP9 and this is an older Safari, let's avoid video
            if (
                stream.type === 'video' &&
                janusCtx.browser === 'safari' &&
                (stream.codec === 'vp9' || (stream.codec === 'vp8' && !janusCtx.safariVp8))
            ) {
                janusVideoRoomTracksLogger.error(
                    `Publisher is using ${stream.codec.toUpperCase}, but Safari doesn't support it: disabling video stream #${stream.mindex}`,
                );
                continue;
            }
            if (stream.disabled) {
                janusVideoRoomTracksLogger.debug('Disabled stream:', stream);
                if (
                    janusCtx.multiStreamSubscriptions[stream.id] &&
                    janusCtx.multiStreamSubscriptions[stream.id][stream.mid]
                ) {
                    if (!removed) {
                        removed = [];
                    }
                    removed.push({ feed: stream.id, mid: stream.mid });
                    delete janusCtx.multiStreamSubscriptions[stream.id][stream.mid];
                    janusCtx.dispatch(
                        removeRemoteStreamAction({ participantId: stream.id, mid: stream.mid }),
                    );
                }
                continue;
            }
            if (
                janusCtx.multiStreamSubscriptions[stream.id] &&
                janusCtx.multiStreamSubscriptions[stream.id][stream.mid]
            ) {
                janusVideoRoomTracksLogger.debug('Already subscribed to stream, skipping:', stream);
                continue;
            }
            if (!added) {
                added = [];
            }
            added.push({ feed: stream.id, mid: stream.mid });
            if (!janusCtx.multiStreamSubscriptions[stream.id]) {
                janusCtx.multiStreamSubscriptions[stream.id] = {};
            }
            janusCtx.multiStreamSubscriptions[stream.id][stream.mid] = '';
        }
    }
    if ((!added || added.length === 0) && (!removed || removed.length === 0)) {
        // Nothing to do
        return;
    }
    const update: any = { request: 'update' };
    if (added) {
        update.subscribe = added;
    }
    if (removed) {
        update.unsubscribe = removed;
    }
    pluginHandler.send({
        message: update,
    });
};

export const multiStreamSubscribe = (sources: any[]) => {
    if (janusCtx.creatingSubscriptionsHandler) {
        setTimeout(() => {
            multiStreamSubscribe(sources), 1000;
        });
    } else {
        if (janusCtx.videoRoomSubscriberPluginHandler) {
            subscribeInternal(janusCtx.videoRoomSubscriberPluginHandler, sources);
            return;
        }
    }
    janusCtx.creatingSubscriptionsHandler = true;
    janusCtx.janusInstance.attach({
        plugin: 'janus.plugin.videoroom',
        opaqueId: janusCtx.opaqueId,
        success: function (pluginHandle: any) {
            const {
                janus: { publishers },
            } = janusCtx.getState() as TAppState;
            janusCtx.videoRoomSubscriberPluginHandler = pluginHandle;
            janusCtx.creatingSubscriptionsHandler = false;
            janusVideoRoomTracksLogger.debug(
                'Plugin attached! (' +
                    janusCtx.videoRoomSubscriberPluginHandler.getPlugin() +
                    ', id=' +
                    janusCtx.videoRoomSubscriberPluginHandler.getId() +
                    ')',
            );
            janusVideoRoomTracksLogger.debug('  -- This is a multistream subscriber');
            // Prepare the streams to subscribe to, as an array: we have the list of
            // streams the feed is publishing, so we can choose what to pick or skip
            const subscription = [];
            for (const s in sources) {
                const streams = sources[s];
                for (const i in streams) {
                    const stream = streams[i];
                    // If the publisher is VP8/VP9 and this is an older Safari, let's avoid video
                    if (
                        stream.type === 'video' &&
                        janusCtx.browser === 'safari' &&
                        (stream.codec === 'vp9' || (stream.codec === 'vp8' && !janusCtx.safariVp8))
                    ) {
                        janusVideoRoomTracksLogger.error(
                            `Publisher is using ${stream.codec.toUpperCase} , but Safari doesn't support it: disabling video stream #${stream.mindex}`,
                        );
                        continue;
                    }
                    if (stream.disabled) {
                        janusVideoRoomTracksLogger.debug('Disabled stream:', stream);
                        // TODO Skipping for now, we should unsubscribe
                        continue;
                    }
                    janusVideoRoomTracksLogger.debug(
                        'Subscribed to ' + stream.id + '/' + stream.mid + '?',
                        publishers,
                    );
                    if (
                        janusCtx.multiStreamSubscriptions[stream.id] &&
                        janusCtx.multiStreamSubscriptions[stream.id][stream.mid]
                    ) {
                        // && subscriptions[stream.id][stream.mid]
                        janusVideoRoomTracksLogger.debug(
                            'Already subscribed to stream, skipping:',
                            stream,
                        );
                        continue;
                    }
                    subscription.push({
                        feed: stream.id, // This is mandatory
                        mid: stream.mid, // This is optional (all streams, if missing)
                    });
                    if (!janusCtx.multiStreamSubscriptions[stream.id]) {
                        janusCtx.multiStreamSubscriptions[stream.id] = {};
                    }
                    janusCtx.multiStreamSubscriptions[stream.id][stream.mid] = stream.feed_mid;
                }
            }
            // We wait for the plugin to send us an offer
            const message = {
                request: 'join',
                room: janusCtx.roomId,
                ptype: 'subscriber',
                streams: subscription,
                private_id: janusCtx.mypvtid,
                pin: (janusCtx.getState() as TAppState).conferenceSession.currentConferencePin,
            };
            janusCtx.videoRoomSubscriberPluginHandler.send({ message });
        },
        error: function (error: any) {
            janusVideoRoomTracksLogger.error('  -- Error attaching plugin...', error);
        },
        iceState: function (state: any) {
            janusVideoRoomTracksLogger.debug('ICE state (remote feed) changed to ' + state);
        },
        webrtcState: function (on: any) {
            janusVideoRoomTracksLogger.debug(
                `Janus says this WebRTC PeerConnection (remote feed) is ${on ? 'up' : 'down'} now`,
            );
        },
        slowLink: function (uplink: any, lost: any, mid: any) {
            janusVideoRoomTracksLogger.debug(
                `Janus reports problems ${
                    uplink ? 'sending' : 'receiving'
                } packets on mid ${mid} (${lost} lost packets)`,
            );
        },
        onmessage: function (msg: any, jsep: any) {
            janusVideoRoomTracksLogger.debug(
                ' ::: Got a message (multi stream subscriber) :::',
                msg,
            );
            const event = msg['videoroom'];
            janusVideoRoomTracksLogger.debug('multiStreamSubscribe Event: ' + event);
            if (msg['error']) {
                // janusVideoRoomTracksLogger.error(msg['error']);
            } else if (event) {
                if (event === 'attached') {
                    janusCtx.creatingSubscriptionsHandler = false;
                    janusVideoRoomTracksLogger.debug(
                        `Successfully attached to feed in room ${msg['room']}`,
                    );
                    const streams = msg['streams'];
                    streams.forEach((stream: any) => {
                        const { feed_id, feed_mid, mid } = stream;
                        janusCtx.multiStreamSubscriptions[feed_id][feed_mid] = mid;
                    });
                } else if (event === 'event') {
                    // Check if we got an event on a simulcast-related event from this publisher
                    const mid = msg['mid'];
                    const substream = msg['substream'];
                    const temporal = msg['temporal'];
                    if (
                        (substream !== null && substream !== undefined) ||
                        (temporal !== null && temporal !== undefined)
                    ) {
                        const sub = janusCtx.multiStreamSubStreams[mid];
                        // const feed = janusCtx.feedStreams[sub.feed_id];
                        // const slot = janusCtx.slots[mid];
                        // FIXME
                        // if (!janusCtx.simulcastStarted[slot]) {
                        //     janusCtx.simulcastStarted[slot] = true;
                        // }
                    }
                } else {
                    // What has just happened?
                }
            }
            if (msg['streams']) {
                // Update map of subscriptions by mid
                const streams = msg['streams'];
                for (const i in streams) {
                    const subStream = streams[i];
                    const mid = subStream.mid;
                    janusCtx.multiStreamSubStreams[mid] = subStream;
                    const feed_id = subStream.feed_id;
                    const feed = janusCtx.multiStreamFeedStreams[feed_id];
                    // FIXME
                    if (feed) {
                        // janusCtx.slots[mid] = feed.slot;
                        // janusCtx.mids[feed.slot] = mid;
                    }
                }
            }
            if (jsep) {
                janusVideoRoomTracksLogger.debug('Handling SDP as well...', jsep);
                // Answer and attach
                janusCtx.videoRoomSubscriberPluginHandler.createAnswer({
                    jsep,
                    // Add data:true here if you want to subscribe to datachannels as well
                    // (obviously only works if the publisher offered them in the first place)
                    tracks: [{ type: 'data' }],
                    success: function (jsep: any) {
                        janusVideoRoomTracksLogger.debug('Got SDP!');
                        janusVideoRoomTracksLogger.debug(jsep);
                        const body = { request: 'start', room: janusCtx.roomId };
                        janusCtx.videoRoomSubscriberPluginHandler.send({
                            message: body,
                            jsep: jsep,
                        });
                    },
                    error: function (error: any) {
                        janusVideoRoomTracksLogger.error('WebRTC error:', error);
                    },
                });
            }
        },
        onlocaltrack: function () {
            // The subscriber stream is recvonly, we don't expect anything here
        },
        onremotetrack: function (track: any, mid: any, on: any) {
            janusVideoRoomTracksLogger.debug(
                `Remote track (mid=${mid} ${on ? 'added' : 'removed'}):`,
                track,
            );
            // Which publisher are we getting on this mid?
            const sub = janusCtx.multiStreamSubStreams[mid];
            if (!sub) {
                janusVideoRoomTracksLogger.debug(` >> Substream was not found ${mid}:`);
                return;
            }
            const feed = janusCtx.multiStreamFeedStreams[sub.feed_id];
            console.log({ sub, feed });
            janusVideoRoomTracksLogger.debug(
                ` >> This track is coming from feed ${sub.feed_id}:`,
                feed,
            );
            if (!on) {
                // Track removed, get rid of the stream and the rendering
                const track = janusCtx.multiStreamRemoteTracks[mid];
                if (track) {
                    try {
                        // track.stop();
                    } catch {
                        //
                    }
                }
                delete janusCtx.multiStreamRemoteTracks[mid];
                const participantId = janusCtx.multiStreamSubStreams[mid].feed_id;
                // janusCtx.multiStreamSubscriptions[participantId][]
                janusCtx.dispatch(removeRemoteStreamAction({ participantId, mid }));
                return;
            }

            let stream;
            if (track.kind === 'audio') {
                stream = new MediaStream();
                stream.addTrack(track.clone());
                janusCtx.multiStreamRemoteTracks[mid] = track;
                janusVideoRoomTracksLogger.debug({ muted: track.muted });
                janusCtx.dispatch(
                    startJanusPublisherStreamAction({
                        stream,
                        id: sub.feed_id,
                        type: 'audio',
                        mid,
                    }),
                );
            } else {
                feed.remoteStreams++;
                stream = new MediaStream();
                stream.addTrack(track.clone());
                janusCtx.multiStreamRemoteTracks[mid] = track;
                janusVideoRoomTracksLogger.debug('Add stream', stream);
                janusCtx.dispatch(
                    startJanusPublisherStreamAction({
                        stream,
                        id: sub.feed_id,
                        type: 'video',
                        mid,
                    }),
                );
            }
        },
        oncleanup: function () {
            const {
                janus: { publishers },
            } = janusCtx.getState() as TAppState;
            janusVideoRoomTracksLogger.debug(' ::: Got a cleanup notification (remote feed) :::');
            for (let i = 1; i < Object.keys(publishers).length; i++) {
                if (janusCtx.bitrateTimer[i]) {
                    clearInterval(janusCtx.bitrateTimer[i]);
                    janusCtx.bitrateTimer[i] = null;
                }
                if (janusCtx.multiStreamFeedStreams[i]) {
                    janusCtx.multiStreamFeedStreams[i].simulcastStarted = false;
                    janusCtx.multiStreamFeedStreams[i].remoteStreams = 0;
                }
            }
            janusCtx.multiStreamRemoteTracks = {};
        },
    });
};
