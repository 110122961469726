import { Trans } from '@lingui/react';
import { Avatar } from 'components/common/Avatar';
import { LinkWithPendingActionCheckConnected } from 'components/common/LinkWithPendingActionCheck';
import { getAvatarUrl } from 'utils/users/getAvatarUrl';
import { getUserInitials } from 'utils/users/getUserInitials';
import styles from './LoggedInMenu.module.scss';
import { IDispatchProps, IOwnProps, IStateProps } from './types';

export const LoggedInMenu: React.FC<IStateProps & IOwnProps & IDispatchProps> = ({
    clickHandler,
    createNewConference,
    currentUser,
    handleJoinConference,
    disabledStartConfButton,
}) => {
    let avatar: string | undefined;
    if (currentUser.etag) {
        const { id, etag } = currentUser;
        avatar = getAvatarUrl({ id, etag, size: '50' });
    }
    const initials = getUserInitials({
        lastName: currentUser.name?.last,
        firstName: currentUser.name?.first,
        email: currentUser.contact.email,
    });
    return (
        <div className={styles.menu}>
            <LinkWithPendingActionCheckConnected
                onClick={handleJoinConference}
                data-qa='joinConferenceHeaderButton'>
                <Trans message='Присоединиться к конференции' id='Join a conference' />
            </LinkWithPendingActionCheckConnected>
            <LinkWithPendingActionCheckConnected
                to='/account/conferences/scheduling'
                data-qa='scheduleConferenceHeaderButton'>
                <Trans message='Запланировать конференцию' id='Schedule a conference' />
            </LinkWithPendingActionCheckConnected>
            <LinkWithPendingActionCheckConnected
                onClick={() => !disabledStartConfButton && createNewConference()}
                data-qa='startConferenceHeaderButton'>
                <Trans message='Начать конференцию' id='Start a conference' />
            </LinkWithPendingActionCheckConnected>
            <LinkWithPendingActionCheckConnected onClick={clickHandler}>
                <Avatar
                    style={{
                        cursor: 'pointer',
                        minWidth: '46px',
                    }}
                    initials={initials}
                    image={avatar}
                />
            </LinkWithPendingActionCheckConnected>
        </div>
    );
};
