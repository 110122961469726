import { connect } from 'react-redux';
import { TAppDispatch } from 'types/app/TAppDispatch';
import { ShowLog } from './ShowLog';
import { IDispatchProps, IStateProps } from './types';
import { TAppState } from 'types/app/TAppStore';
import { clearJanusLogAction } from 'actions/janus/clearJanusLogAction';

const mapStateToProps = (state: TAppState): IStateProps => ({
    log: state.janus.log,
    conferenceChatOpen: state.applicationView.showChat,
});

const mapDispatchToProps = (dispatch: TAppDispatch): IDispatchProps => ({
    clearLog: () => dispatch(clearJanusLogAction()),
});

export const ShowLogConnected = connect(mapStateToProps, mapDispatchToProps)(ShowLog);
