import { createAsyncThunk } from '@reduxjs/toolkit';
import { getConferenceInvitedUsersApiCall } from 'api/conference/getConferenceInvitedUsersApiCall';
import { getNetworkService } from 'api/getNetworkService';
import { TAppState } from 'types/app/TAppStore';

export const getCurrentConferenceInvitedUsersThunkAction = createAsyncThunk(
    'getCurrentConferenceInvitedUsers',
    async (_: void, { getState }) => {
        const {
            conferenceSession: { currentConferencePin, currentConferenceId, currentUserIsOwner },
        } = getState() as TAppState;
        let tPin = '';

        if (!currentUserIsOwner) {
            if (!currentConferencePin) {
                return;
            }
            tPin = currentConferencePin;
        }
        const response = await getConferenceInvitedUsersApiCall(
            getNetworkService(),
            tPin,
            currentConferenceId,
        );
        if (!response.success) {
            return { error: true };
        }
        return response.data;
    },
);
