import { AnimationEventName, AnimationItem } from 'lottie-web';

export const addEventListeners = (
    events: { eventName: AnimationEventName; callback: () => void }[] | undefined,
    animation: AnimationItem,
) => {
    if (events) {
        events.forEach(({ callback, eventName }) => {
            animation.addEventListener(eventName, callback);
        });
    }
};

export const removeEventListeners = (
    events: { eventName: AnimationEventName; callback: () => void }[] | undefined,
    animation: AnimationItem,
) => {
    if (events) {
        events.forEach(({ callback, eventName }) => {
            animation.removeEventListener(eventName, callback);
        });
    }
};
