export enum EApplicationViewActions {
    CLOSE_REDIRECT_CONFIRMATION = 'ApplicationView/closeRedirectConfirmationAction',
    MODIFY_PARTICIPANT_TALKING = 'ApplicationView/modifyParticipantTalkingAction',
    RESET_PENDING_CONFIRMATION = 'ApplicationView/resetPendingConfirmationAction',
    SET_CONFERENCE_INFO_FRAME_VISIBILITY = 'ApplicationView/ConferenceInfoFrame',
    SET_CONFIRMATION_DIALOG_VISIBILITY = 'ApplicationView/ConferenceExitConfirmationDialog',
    SET_CURRENT_LANGUAGE = 'ApplicationView/setCurrentLanguage',
    SET_DEVICE_PROPS = 'ApplicationView/setDeviceProps',
    SET_DISABLED_START_CONF_BUTTON = 'ApplicationView/setDisabledStartConfButtonAction',
    SET_DISPLAY_LAYOUT = 'ApplicationView/setDisplayLayout',
    SET_FORM_EDIT_FLAG = 'ApplicationView/setFormEditFlagAction',
    SET_FRAME_TO_FULLSCREEN = 'ApplicationView/SetFrameToFullscreen',
    SET_FULLSCREEN_MENU_VISIBILITY = 'ApplicationView/setFullscreenMenuVisibility',
    SET_LOGIN_DIALOG_VISIBILITY = 'ApplicationView/LoginDialog',
    SET_NOTIFICATION_FOR_DISPLAY = 'ApplicationView/SetNotificationForDisplay',
    SET_PARTICIPANTS_FULLSCREEN = 'ApplicationView/setParticipantsFullScreen',
    SET_PARTICIPANTS_LAYOUT_PROPERTIES = 'ApplicationView/setParticipantsLayoutProperties',
    SET_PARTICIPANTS_LIST_OPEN = 'ApplicationView/SetParticipantsListOpen',
    SET_PARTICIPANT_EMOTION_TO_SHOW = 'ApplicationView/setParticipantEmotionToShow',
    SET_PARTICIPANT_LAST_ACTIVITY = 'ApplicationView/SetParticipantLastActivity',
    SET_PARTICIPANT_LOCAL_MUTE_STATUS = 'ApplicationView/setParticipantLocalMuteStatusAction',
    SET_PENDING_OPERATION = 'ApplicationView/setPendingOperation',
    SET_SHOW_REDIRECT_CONFIRMATION = 'ApplicationView/setShowRedirectConfirmationAction',
    SET_VIEW_SETTINGS = 'ApplicationView/setViewSettings',
    SHOW_AUDIO_DIALOG_PERMISSION = 'ApplicationView/showAudioDialogPermission',
    UPDATE_PARTICIPANTS_FRAME = 'ApplicationView/updateParticipantsFrame',
    REFRESH_LAYOUT = 'ApplicationView/refreshLayout',
}
