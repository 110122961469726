import { Trans } from '@lingui/react';
import { DatePickerMobileConnected } from 'components/common/DatePicker/DatePickerMobileConnected';
import { IDateFilters } from 'types/IDateFilters';
import styles from './DateFilterMobile.module.scss';
import { IOwnPropsMobile } from './types';
import { getFilterStringRepresentation, getStartEndDates } from './utils';

export const DateFilterMobile: React.FC<IOwnPropsMobile> = ({
    filtersSet,
    filters,
    type,
    handleChangeFilter,
}) => {
    const { end, start } = getStartEndDates(filters, type);

    const onChangeFilter = ({ end, start }: { start?: string; end?: string }) => {
        if (type === 'conferences') {
            const { finished } = filters as IDateFilters;
            if (finished) {
                handleChangeFilter({ past: { from: start, to: end } });
            } else {
                handleChangeFilter({ upcoming: { from: start, to: end } });
            }
        } else if (type === 'records') {
            handleChangeFilter({ from: start, to: end });
        }
    };

    return (
        <div className={styles.filter}>
            {type === 'conferences' && (
                <div
                    className={`${styles.filter__block} ${
                        filtersSet ? styles.filter__block_spaceBetween : ''
                    }`}>
                    <div
                        className={`${styles.filter__item} ${
                            !(filters as IDateFilters).finished || filtersSet
                                ? styles.filter__item_active
                                : ''
                        }`}
                        onClick={() =>
                            filtersSet ? false : handleChangeFilter({ finished: false })
                        }>
                        {filtersSet ? (
                            getFilterStringRepresentation(filters, type)
                        ) : (
                            <Trans id='Upcoming' message='Предстоящие' />
                        )}
                    </div>
                    {!filtersSet && (
                        <div
                            className={`${styles.filter__item} ${
                                (filters as IDateFilters).finished ? styles.filter__item_active : ''
                            }`}
                            onClick={() => handleChangeFilter({ finished: true })}>
                            <Trans id='Past' message='Прошедшие' />
                        </div>
                    )}
                    {filtersSet && (
                        <div
                            className={`${styles.filter__item} ${styles.filter__item_cancel}`}
                            onClick={() => handleChangeFilter({ past: {}, upcoming: {} })}>
                            <Trans id='Cancel' />
                        </div>
                    )}
                </div>
            )}
            {type === 'records' && (
                <div
                    className={`${styles.filter__block} ${
                        filtersSet ? styles.filter__block_spaceBetween : ''
                    }`}>
                    {filtersSet && (
                        <>
                            <div className={`${styles.filter__item} ${styles.filter__item_active}`}>
                                {getFilterStringRepresentation(filters, type)}
                            </div>

                            <div
                                className={`${styles.filter__item} ${styles.filter__item_cancel}`}
                                onClick={() =>
                                    handleChangeFilter({ from: undefined, to: undefined })
                                }>
                                <Trans id='Cancel' />
                            </div>
                        </>
                    )}
                </div>
            )}
            {!filtersSet && (
                <div className={styles.filter__block}>
                    <DatePickerMobileConnected
                        handleDateChange={onChangeFilter}
                        start={start}
                        end={end}
                    />
                </div>
            )}
        </div>
    );
};
