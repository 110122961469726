import { t } from '@lingui/macro';
import { Button } from 'components/common/Button/Button';
import { Input } from 'components/common/Input/Input';
import { EChatMessageStatus } from 'constants/EChatMessageStatus';
import { EChatMessageType } from 'constants/EChatMessageType';
import { ConferenceChatMsgLengthWarning } from 'pages/templates/ConferencePageTemplate/ConferenceChat/ConferenceChatMsgLengthWarning';
import React, { useEffect, useRef } from 'react';
import { Form, FormRenderProps } from 'react-final-form';
import { createRandomId } from 'utils/createRandomId';
import styles from './ConferenceChatQuickMessage.module.scss';
import { IDispatchProps, IFormRenderProps, IStateProps } from './types';

let draftChatMessageLocal = '';

export const ConferenceChatQuickMessage: React.FC<IStateProps & IDispatchProps> = ({
    chatState,
    ownUserId,
    confUserId,
    toggleChat,
    addNewMessage,
    setChatAutoScroll,
    participantsListOpen,
    setParticipantsListOpen,
    priorityFrameEnable,
    maxMessageLength,
    setDraftChatMessage,
    draftChatMessage,
}) => {
    const inputRef = useRef<HTMLInputElement | null>(null);

    const saveValue = () => {
        draftChatMessageLocal = inputRef.current?.value || '';
    };

    useEffect(() => {
        draftChatMessageLocal = draftChatMessage;
    }, [draftChatMessage]);

    useEffect(() => {
        return () => {
            setDraftChatMessage(draftChatMessageLocal);
        };
    }, []);

    const handleSubmit = (values: IFormRenderProps) => {
        if (values.fastChatTextMessage) {
            addNewMessage({
                text: values.fastChatTextMessage,
                contentType: EChatMessageType.TEXT,
                status: EChatMessageStatus.SENT,
                ownUserId,
                confUserId,
                id: createRandomId(),
            });
            participantsListOpen ? setParticipantsListOpen(!participantsListOpen) : false;
            !chatState ? toggleChat(!chatState) : false;
            setChatAutoScroll(true);
            draftChatMessageLocal = '';
            if (draftChatMessage !== '') {
                setDraftChatMessage('');
            }
        }
    };

    const renderForm = ({
        handleSubmit,
        submitting,
        values,
    }: FormRenderProps<IFormRenderProps>) => (
        <form
            className={`${styles.quickMessageForm} ${
                priorityFrameEnable ? styles.quickMessageForm_withPriorityFrame : ''
            }`}
            onSubmit={handleSubmit}>
            <ConferenceChatMsgLengthWarning
                currentLength={values.fastChatTextMessage?.length || 0}
                maxLength={maxMessageLength}
                className={styles.msgLengthWarning}
                blinkedClassName={styles.msgLengthWarningIsBlinked}
            />
            <div className={styles.quickMessageForm__wrapper}>
                <Input
                    placeholder={t({
                        id: 'Quick message in chat',
                        message: 'Быстрое сообщение в чат',
                    })}
                    name='fastChatTextMessage'
                    type='text'
                    maxLength={maxMessageLength}
                    ref={inputRef}
                    handleInput={saveValue}
                />
                <Button
                    className={styles.button}
                    styleType='send'
                    disabled={submitting}
                    type='submit'
                />
            </div>
        </form>
    );

    return (
        <div className={styles.container}>
            <Form
                onSubmit={(values, formApi) => {
                    handleSubmit(values);
                    formApi.reset();
                }}
                render={renderForm}
                initialValues={{fastChatTextMessage: draftChatMessage}}
            />
        </div>
    );
};
