import { TCapacitorPlatform, TPlatform } from 'types/TPlatform';

export const getExtendedPlatform = (platform: TCapacitorPlatform): TPlatform => {
    const isMobilePlatform = platform === 'android' || platform === 'ios';
    const isMobileProduction = isMobilePlatform && process.env.NODE_ENV === 'production';
    const isWebProduction = !isMobilePlatform && process.env.NODE_ENV === 'production';

    if (isWebProduction) { 
        return 'web-production';
    } else if (isMobileProduction) {
        return 'mobile-production';
    }

    return 'web-test';
}