import { IWebHook } from 'admin-app/types/IWebHook';
import { INetworkService, IRequestResult } from 'types/api/INetworkService';

interface IPayload {
    url: string;
    token: string;
}

export const createWebHookApiCall = (
    networkService: INetworkService,
    { url, token }: IPayload,
): Promise<IRequestResult<IWebHook>> => {
    return networkService.post({
        url: '/api/admin/webhooks',
        data: {
            url,
            token,
        },
    });
};
