import { createAsyncThunk } from '@reduxjs/toolkit';
import { getConferenceParticipantsApiCall } from 'api/conference/getConferenceParticipantsApiCall';
import { getNetworkService } from 'api/getNetworkService';
import { IConferenceParticipantFromServer } from 'types/server/IConferenceParticipantFromServer';
import { setConferencesParticipantsAction } from 'actions/conferences/setConferencesParticipantsAction';

interface IPayload {
    id: string,
    pin: string
}

type IResponse = IConferenceParticipantFromServer[] | undefined

export const getConferenceParticipantsThunkAction = createAsyncThunk<IResponse, IPayload>(
    'getConferenceParticipants',
    async ({id, pin}, {dispatch}) => {
        const response = await getConferenceParticipantsApiCall(getNetworkService(), {id, pin});
        if (!response.success) {
            return;
        }
        dispatch(setConferencesParticipantsAction({ id, users: response.data }))
        return response.data
    },
);
