import { createAsyncThunk } from '@reduxjs/toolkit';
import { resetConferenceChatStoreAction } from 'actions/chat/resetConferenceChatStoreAction';
import { storeCurrentConferenceParticipantsAction } from 'actions/conferences/storeCurrentConferenceParticipantsAction';
import { resetConferenceSessionStoreAction } from 'actions/conferenceSession/resetConferenceSessionStoreAction';
import { resetJanusStoreAction } from 'actions/janus/resetJanusStoreAction';
import { resetLobbyStoreAction } from 'actions/lobby/resetLobbyStoreAction';
import { EConferenceThunkActions } from 'constants/thunk/EConferenceThunkActions';
import { unsubscribeFromConferenceTopics } from 'services/socketService/unsubscribeFromConferenceTopics';
import { setFramesToFullscreenThunkAction } from 'thunk/appView/setFramesToFullscreenThunkAction';
import { stopPingSocketConnection } from 'thunk/socket/pingSocketConnectionThunkAction';
import { TAppState } from 'types/app/TAppStore';
import { destroyJanusSession } from 'utils/janus/destroyJanusSession';
import { janusCtx } from 'utils/janus/janusCtx';
import { stopConferenceJanus } from 'utils/janus/stopConferenceJanus';
import { redirect } from 'utils/redirect';
import { setDefaultDocumentTitle } from 'utils/setDefaultDocumentTitle';
import { leaveFromLobbyThunkAction } from './leaveFromLobbyThunkAction';

interface IPayload {
    stopJanus?: boolean;
    redirect?: boolean;
}

export const exitFromConferenceThunkAction = createAsyncThunk(
    EConferenceThunkActions.EXIT_FROM_CONFERENCE,
    async (
        { stopJanus = true, redirect: redirectAfter = true }: IPayload,
        { dispatch, getState },
    ) => {
        const lobbyPage = location.pathname.split('/')[1] === 'lobby';
        const finishPage = location.pathname.split('/')[3] === 'finished';
        const connectPage = location.pathname.split('/')[1] === 'connect';

        const {
            janus: { ownParticipantId },
            conferenceSession: { currentConferenceId, currentUserIsOwner },
            conferences: { conferencesConnectInfo },
            applicationView: { fullScreenFrames },
        } = getState() as TAppState;
        if (lobbyPage) await dispatch(leaveFromLobbyThunkAction());

        if (janusCtx) {
            if (stopJanus) {
                stopConferenceJanus();
            }
            destroyJanusSession();
        }
        stopPingSocketConnection(currentConferenceId);

        if (
            ownParticipantId !== '' &&
            fullScreenFrames.some((id) => id === ownParticipantId) &&
            currentConferenceId
        ) {
            if (currentUserIsOwner) {
                // If the current user was a priority frame - reset
                await dispatch(
                    setFramesToFullscreenThunkAction({
                        global: true,
                        participantIds: fullScreenFrames.filter((id) => id !== ownParticipantId),
                    }),
                );
            }
        }
        if (fullScreenFrames.length > 0) {
            // If current user leave from conference where were priority frame - reset local
            await dispatch(setFramesToFullscreenThunkAction({ global: false, participantIds: [] }));
        }

        dispatch(resetLobbyStoreAction());
        dispatch(resetJanusStoreAction());
        dispatch(resetConferenceChatStoreAction());
        if (!connectPage) {
            dispatch(resetConferenceSessionStoreAction());
        }
        if (!finishPage && currentConferenceId) {
            dispatch(storeCurrentConferenceParticipantsAction([]));
        }

        try {
            unsubscribeFromConferenceTopics();
        } finally {
            if (redirectAfter) {
                if (finishPage) {
                    redirect('/');
                } else if (
                    currentConferenceId &&
                    conferencesConnectInfo[currentConferenceId].status === 'FINISHED' &&
                    (lobbyPage || connectPage)
                ) {
                    redirect(`/conference/${currentConferenceId}/finished`);
                } else {
                    redirect('/');
                }
            }
        }
        setDefaultDocumentTitle();
    },
);
