import { createAsyncThunk } from '@reduxjs/toolkit';
import { setAppSettingsAction } from 'actions/auth/setAppSettingsAction';
import { getAppSettingsApiCall } from 'api/authorization/getAppSettingsApiCall';
import { getNetworkService } from 'api/getNetworkService';
import { appSettingsStructure } from 'constants/appSettingsStructure';
import { IAppSettings } from 'types/app/IAppSettings';
import { unitSettingsWithDefault } from 'utils/getAppSettings';
import { settingsMigration } from 'reducers/utils/authReducerUtils';

interface IGetAppSettingsThunk {
    newSettings?: IAppSettings;
}

export const getAppSettingsThunkAction = createAsyncThunk(
    'getAppSettings',
    async ({ newSettings }: IGetAppSettingsThunk, { dispatch }) => {
        let settings = {};
        if (!newSettings) {
            const response = await getAppSettingsApiCall(getNetworkService());
            if (response.success) {
                const isAnonymUserData = !Object.keys(response.data).length;
                if (!isAnonymUserData) {
                    settings = response.data;
                }
            }
        } else {
            settings = newSettings;
        }

        const settingsUnionWithDefault = unitSettingsWithDefault(settings, appSettingsStructure);
        const migratedSettings = settingsMigration(settingsUnionWithDefault);
        dispatch(setAppSettingsAction(migratedSettings));
    },
);
