import { createAsyncThunk } from '@reduxjs/toolkit';
import { getCurrentConferenceConnectInfoSelector } from 'selectors/getCurrentConferenceConnectInfoSelector';
import { loadDataForConnectAndConferencePagesThunkAction } from 'thunk/conference/loadDataForConnectAndConferencePagesThunkAction';
import { subscribeToConferenceEventsActionThunk } from 'thunk/socket/subscribeToConferenceEventsThunkAction';
import { subscribeToConferencePermissionJoinThunkAction } from 'thunk/socket/subscribeToConferencePermissionJoinThunkAction';
import { TAppState } from 'types/app/TAppStore';

interface IPayload {
    id: string;
    page: string;
}

export const loadDataForConferencePageMobileThunkAction = createAsyncThunk(
    'loadDataForConferencePageMobile',
    async ({ id, page }: IPayload, { getState, dispatch }) => {
        await dispatch(loadDataForConnectAndConferencePagesThunkAction({ id, page }));
        await dispatch(subscribeToConferenceEventsActionThunk(id));
        const state = getState() as TAppState;
        const conferenceConnectInfo = getCurrentConferenceConnectInfoSelector(state);
        const {
            conferenceSession: { currentUserIsOwner },
        } = state;
        if (conferenceConnectInfo && currentUserIsOwner && conferenceConnectInfo.lobby) {
            await dispatch(subscribeToConferencePermissionJoinThunkAction(id));
        }
    },
);
