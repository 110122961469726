import { getCurrentDate } from './getCurrentDate';
import { getUserTimeZone } from 'utils/timeZones/getUserTimeZone';
import { convertStringToDateByTemplate } from './convertStringToDateByTemplate';
import { getDateBasedOnTimezone } from './getDateBasedOnTimezone';

export const getCurrentDateWithTimezone = () => {
    const userTimeZone = getUserTimeZone();
    const currentDateUTC0 = convertStringToDateByTemplate(
        getCurrentDate().toISOString(),
        'yyyy-MM-dd HH:mm:ss.SSS',
        true,
    );
    //todo в getCurrentDate().toISOString() - теряем часовой пояс
    return getDateBasedOnTimezone(currentDateUTC0, userTimeZone);
};
