import { appHistory } from 'appHistory';
import { connect } from 'react-redux';
import { getCurrentConferenceConnectInfoSelector } from 'selectors/getCurrentConferenceConnectInfoSelector';
import { getCurrentConferenceInvitedUsersThunkAction } from 'thunk/conference/getCurrentConferenceInvitedUsersThunkAction';
import { joinToCurrentConferenceAndStartJanusThunkAction } from 'thunk/conference/joinToCurrentConferenceAndStartJanusThunkAction';
import { getCurrentConferenceParticipantsThunkAction } from 'thunk/conference/participants/getCurrentConferenceParticipantsThunkAction';
import { startJanusConferenceThunkAction } from 'thunk/conference/startJanusConferenceThunkAction';
import { showNotifyThunkAction } from 'thunk/notify/showNotifyThunkAction';
import { TAppDispatch } from 'types/app/TAppDispatch';
import { TAppState } from 'types/app/TAppStore';
import { getDefaultErrorNotification } from 'utils/getDefaultErrorNotification';
import { redirect } from 'utils/redirect';
import { IDispatchProps, IStateProps } from './types';
import { VideoCallMembers } from './VideoCallMembers';
import { executePendingOperationThunkAction } from 'thunk/appView/executePendingOperationThunkAction';
import { EPendingOperationsThunkActions } from 'constants/thunk/EPendingOperationsThunkActions';

const mapDispatchToProps = (dispatch: TAppDispatch): IDispatchProps => ({
    joinToJanusConference: () => dispatch(startJanusConferenceThunkAction()),
    getInvitedUsers: () => dispatch(getCurrentConferenceInvitedUsersThunkAction()),
    goToConferencePage: (id) => appHistory.replace(`/conference/${id}`),
    connectToConference: () => dispatch(joinToCurrentConferenceAndStartJanusThunkAction(true)),
    goToHomePage: () => redirect('/'),
    showNotificationError: () => dispatch(showNotifyThunkAction(getDefaultErrorNotification())),
    getConferenceParticipants: () =>
        dispatch(
            executePendingOperationThunkAction({
                operation: EPendingOperationsThunkActions.GETTING_PARTICIPANTS,
                pendingFunction: async () =>
                    await dispatch(getCurrentConferenceParticipantsThunkAction()),
            }),
        ),
});

const mapStateToProps = (state: TAppState): IStateProps => ({
    anonymous: state.auth.anonymous,
    janusToken: state.janus.token,
    currentPin: state.conferenceSession.currentConferencePin,
    userIsConferenceOwner: state.conferenceSession.currentUserIsOwner,
    currentSessionId: state.conferenceSession.currentSessionId,
    conferenceConnectInfo: getCurrentConferenceConnectInfoSelector(state),
    conferenceId: state.conferenceSession.currentConferenceId,
    currentParticipants: state.conferenceSession.currentConferenceParticipants,
    currentParticipantsIds: state.conferenceSession.currentConferenceSortedParticipantsIds,
    isGettingCurrentParticipants: !!state.applicationView.pendingOperations[EPendingOperationsThunkActions.GETTING_PARTICIPANTS],
});

export const VideoCallMembersConnected = connect(
    mapStateToProps,
    mapDispatchToProps,
)(VideoCallMembers);
