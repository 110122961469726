import { connect } from 'react-redux';
import { getCurrentConferenceConnectInfoSelector } from 'selectors/getCurrentConferenceConnectInfoSelector';
import { TAppState } from 'types/app/TAppStore';
import { replace } from 'utils/redirect';
import { LoginForJoinPage } from './LoginForJoinPage';
import { IDispatchProps, IStateProps } from './types';

const mapStateToProps = (state: TAppState): IStateProps => ({
    conferenceConnectInfo: getCurrentConferenceConnectInfoSelector(state),
    currentConferenceId: state.conferenceSession.currentConferenceId,
    isAuthUser: !!state.auth.currentUser,
});

const mapDispatchToProps = (): IDispatchProps => ({
    goToHomePage: () => replace('/'),
});

export const LoginForJoinPageConnected = connect(
    mapStateToProps,
    mapDispatchToProps,
)(LoginForJoinPage);
