import { t, Trans } from '@lingui/macro';
import { Button } from 'components/common/Button';
import { Cross } from 'components/common/Cross';
import { Input } from 'components/common/Input';
import { MobileFormBlock } from 'components/common/MobileFormBlock';
import { Form, FormRenderProps } from 'react-final-form';
import { getDefaultErrorNotification } from 'utils/getDefaultErrorNotification';
import styles from './EmailChangeDialogMobile.module.scss';
import { IDispatchProps, IFormRenderProps, IOwnProps, IStateProps } from './types';

const noteLingui = (email: string) => (
    <Trans id={'email.change.note'}>
        Для смены адреса электронной почты привязанной к учетной записи MeeVox необходимо ввести
        новый адрес и пароль, после этого на почту <strong>{email}</strong> придет письмо со ссылкой
        на подтверждение смены эл. почты.
    </Trans>
);

const getLiguiSuccessText = () => t({ id: 'email.sent.success', message: 'Письмо отправлено' });

export const EmailChangeDialogMobile: React.FC<IOwnProps & IDispatchProps & IStateProps> = ({
    changeEmail,
    showNotification,
    handleClose,
    currentEmail,
}) => {
    const onSubmit = async ({ newEmail, password }: IFormRenderProps) => {
        if (!password || !newEmail) {
            const errors: { [key: string]: string } = {};
            if (!password) errors.password = 'Required';
            if (!newEmail) errors.newEmail = 'Required';
            return errors;
        }

        const { payload: error } = await changeEmail({ email: newEmail, password });
        if (!error) {
            showNotification({ text: getLiguiSuccessText(), timeout: 3000, type: 'success' });
            handleClose();
        } else {
            showNotification({ ...getDefaultErrorNotification(), text: error.text });
            return {
                [error.field]: 'Error',
            };
        }
    };

    const render = ({ handleSubmit }: FormRenderProps<IFormRenderProps>) => (
        <form onSubmit={handleSubmit} className={styles.form}>
            <div className={styles.header}>
                <Trans id='Email change'>Смена электронной почты</Trans>
                <Cross className={styles.cross} onClick={handleClose} />
            </div>
            <div className={styles.text}>{noteLingui(currentEmail)}</div>
            <MobileFormBlock annotation={t({ id: 'new.email' })} className={styles.block}>
                <Input
                    name='newEmail'
                    type='email'
                    styleType='mobile'
                    placeholder={t({
                        id: 'enter.new.email',
                        message: 'Введите новый адрес эл. почты',
                    })}
                />
            </MobileFormBlock>
            <MobileFormBlock annotation={t({ id: 'Password' })} className={styles.block}>
                <Input
                    name='password'
                    type='password'
                    styleType='mobile'
                    placeholder={t({
                        id: 'enter.password',
                        message: 'Введите пароль',
                    })}
                />
            </MobileFormBlock>
            <div className={styles.submit}>
                <Button
                    type='submit'
                    styleType='mobilePrimaryAction'
                    caption={t({ id: 'email.change.button' })}
                />
            </div>
        </form>
    );

    return <Form onSubmit={onSubmit} render={render} />;
};
