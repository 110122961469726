import { compareAsc } from 'date-fns';
import { addUsersTimeZoneToDate } from 'utils/dates/addUsersTimeZoneToDate';
import { convertStringToDateByTemplate } from 'utils/dates/convertStringToDateByTemplate';
import { getCurrentDate } from 'utils/dates/getCurrentDate';
import { getDateBasedOnTimezone } from 'utils/dates/getDateBasedOnTimezone';
import { getDateBasedOnUTC0 } from 'utils/dates/getDateBasedOnUTC0';

export const getDateInfoFromStartedConference = (
    conferenceTimeStart: string,
    browserTimeZone: string,
    userTimeZone: string,
): { currentDate: Date; startDate: Date; compareResult: number } => {
    let currentDate;
    if (browserTimeZone !== userTimeZone && userTimeZone) {
        const dateWithUTC0 = getDateBasedOnUTC0(getCurrentDate(), browserTimeZone);
        currentDate = getDateBasedOnTimezone(dateWithUTC0, userTimeZone);
    } else {
        currentDate = getCurrentDate();
    }
    const startDate = addUsersTimeZoneToDate(
        convertStringToDateByTemplate(conferenceTimeStart, 'yyyy-MM-dd HH:mm:ss.SSSSSS'),
    );
    const compareResult = compareAsc(currentDate, startDate);
    return { currentDate, startDate, compareResult };
};
