import { i18n } from '@lingui/core';
import { I18nProvider } from '@lingui/react';
import { activateLanguage } from 'locales/activateLanguage';
import { useEffect, useState } from 'react';
import { IStateProps } from './types';

export const I18nWrapper: React.FC<IStateProps> = ({ currentLanguage, children }) => {
    const [languageLoaded, setLanguageLoaded] = useState(false);
    useEffect(() => {
        activateLanguage(currentLanguage, () => setLanguageLoaded(true));
    }, [currentLanguage]);
    return (
        <>
            {languageLoaded && <I18nProvider i18n={i18n}>
                {children}
            </I18nProvider>}
        </>
    );
};