import { MobileWrapperConnected } from 'components/common/MobileWrapper/MobileWrapperConnected';
import { LoginPageConnected } from './LoginPageConnected';
import { LoginPageMobileConnected } from './LoginPageMobileConnected';

export const LoginPageResponsive = () => (
    <MobileWrapperConnected
        renderDesktop={() => <LoginPageConnected />}
        renderMobile={() => <LoginPageMobileConnected />}
    />
);
