import { Trans } from '@lingui/react';
import CircleImg from 'assets/Circle.svg';
import { Button } from 'components/common/Button';
import { StatusLabel } from 'components/common/StatusLabel';
import { Form, FormRenderProps } from 'react-final-form';
import { IRecurringData } from 'types/conferences/IRecurringData';
import { ConferenceRepeatInfoConnected } from '../ConferenceRepeatInfo';
import styles from './ConferenceRepeatDialog.module.scss';
import { Header } from './Header';
import { RepeatDay } from './RepeatDay';
import { IOwnProps } from './types';
import {
    getInitialValues,
    getStatusLabelText,
    mainFormField,
    validateFormValues,
    validateFormValuesOnChange,
} from './utils';

export const ConferenceRepeatDialog: React.FC<IOwnProps> = ({
    recurringData,
    selectedOption,
    specifiedDate,
    specifiedTime,
    closePopup,
    updateField,
}) => {
    const validation = (values: IRecurringData) => {
        return validateFormValues({
            values,
            recurringStartDate: recurringData?.startDate,
            recurringEndDate: recurringData?.endDate,
        });
    };

    const submitHandler = (values: IRecurringData) => {
        if (values.step === 'week' && values.days.length > 0) {
            updateField(mainFormField, { ...values, count: '1' });
        } else {
            updateField(mainFormField, null);
            updateField('startDate', values.startDate);
            updateField('startTime', values.time);
        }
        closePopup();
    };

    const cancelClickHandler = () => {
        closePopup();
    };

    const render = ({
        handleSubmit,
        values,
        errors,
        pristine,
        form,
    }: FormRenderProps<IRecurringData>) => {
        let warnings: { [id: string]: string } = {};
        form.subscribe(
            ({ values }) => {
                warnings = validateFormValuesOnChange({
                    values,
                    recurringStartDate: recurringData?.startDate,
                });
            },
            { values: true },
        );
        return (
            <form onSubmit={handleSubmit}>
                <div className={styles.errorContainer}>
                    <StatusLabel text={getStatusLabelText(errors)} />
                </div>

                <Header
                    startDate={values.startDate}
                    endDate={values.startDate}
                    time={values.time}
                    errors={errors}
                    warnings={warnings}
                />
                <div className={styles.body}>
                    <img src={CircleImg} className={styles.img} alt='Repeat frequency' />
                    {/* <RepeatFrequency step={values.step} /> */}
                    {values.step === 'week' && <RepeatDay />}
                    <ConferenceRepeatInfoConnected
                        formData={values}
                        isEndDateValid={!errors?.endDate}
                        className={styles.resultTextInfo}
                    />
                </div>
                <div className={styles.footer}>
                    <Button
                        styleType='secondaryAction'
                        type='button'
                        className={styles.actionButton}
                        clickHandler={cancelClickHandler}>
                        <Trans id='Cancel' message='Отмена' />
                    </Button>
                    <Button
                        styleType='primaryAction'
                        type='submit'
                        className={styles.actionButton}
                        disabled={
                            (pristine && recurringData?.step === values.step) ||
                            (pristine && values.days.length === 0)
                        }>
                        <Trans id='Save' message='Сохранить' />
                    </Button>
                </div>
            </form>
        );
    };
    return (
        <div className={styles.container}>
            <Form
                render={render}
                onSubmit={submitHandler}
                validate={validation}
                initialValues={getInitialValues(recurringData, selectedOption, {
                    specifiedDate,
                    specifiedTime,
                })}
            />
        </div>
    );
};
