import { Trans } from '@lingui/react';
import RecordGray from 'assets/RecordGray.svg';
import RecordRed from 'assets/RecordRed.svg';
import { Spinner } from 'components/common/Spinner';
import { format, parseISO } from 'date-fns';
import { convertFromSecondsToHoursMinutesSeconds } from 'utils/convertFromSecondsToHoursMinutesSeconds';
import { convertRecordSize } from 'utils/records/convertRecordSize';
import styles from './ConferenceRecordInfoMobile.module.scss';
import { IOwnProps } from './types';
import { getDurationInSeconds } from './utils';

export const ConferenceRecordInfoMobile: React.FC<IOwnProps> = ({ record }) => {
    const { name, recordReady, timeEnd, timeStart, record: recordInfo } = record;
    const durationInSeconds = getDurationInSeconds(timeEnd, timeStart, recordInfo?.duration);
    const { hours, minutes, seconds } = convertFromSecondsToHoursMinutesSeconds(durationInSeconds);
    const formattedTime = {
        hours: hours < 10 ? `0${hours}` : `${hours}`,
        minutes: minutes < 10 ? `0${minutes}` : `${minutes}`,
        seconds: seconds < 10 ? `0${seconds}` : `${seconds}`,
    };
    const parseRecordDate = parseISO(timeStart);
    const recordDate = format(parseRecordDate, 'dd/MM/yyyy');
    const recordTime = format(parseRecordDate, 'HH:mm');
    const recordError = recordInfo?.success === false;

    const getRecordStatus = () => {
        if (!recordReady) {
            return (
                <div className={styles.inProgress}>
                    <Spinner wrapperClass='spinner_inProgress' />
                    <span>
                        <Trans
                            id='finished.conference.record.text.id'
                            message='Обрабатывается...'
                        />
                    </span>
                </div>
            );
        }
        if (recordError) {
            return (
                <div>
                    <Trans id='notification.error' />
                </div>
            );
        }

        return <div>{convertRecordSize(record.record?.size)}</div>;
    };

    return (
        <div className={styles.container}>
            <img
                src={`${recordReady && recordInfo?.success ? RecordRed : RecordGray}`}
                width='16'
                height='16'
                alt='record status'
            />
            <div className={styles.right}>
                <div className={styles.top}>
                    <div>
                        <span className={styles.date}>{recordDate}</span>
                        <span>,&nbsp;</span>
                        <span className={styles.time}>{recordTime}</span>
                        <span>&nbsp;-&nbsp;</span>
                        <span className={styles.name}>{name}</span>
                    </div>
                    <div>
                        {`${formattedTime.hours}:${formattedTime.minutes}:${formattedTime.seconds}`}
                    </div>
                </div>
                <div className={styles.bottom}>{getRecordStatus()}</div>
            </div>
        </div>
    );
};
