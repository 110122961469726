import { Trans } from '@lingui/react';
import { ReactComponent as KebabSvg } from 'assets/Kebab.svg';
import { Button } from 'components/common/Button';
import { StatusLabel } from 'components/common/StatusLabel';
import React from 'react';

import { Form, FormRenderProps } from 'react-final-form';
import { AudioDevice } from './DeviceField/AudioDevice';
import styles from './ConferenceJoinSettingsFormMobile.module.scss';
import { IFormProps, IOwnProps } from './types';
import { VideoDevice } from './DeviceField/VideoDevice';

export const ConferenceJoinSettingsFormMobile: React.FC<IOwnProps> = ({
    joinToConference,
    defaultMediaValues,
    isAudioForbid,
    isVideoForbid,
}) => {
    const getInitialValues = () => {
        return defaultMediaValues;
    };

    const onSubmit = async (value: IFormProps) => {
        const error = await joinToConference(value);
        if (error) {
            return {
                submit: error,
            };
        }
    };
    const formRender = ({
        handleSubmit,
        submitting,
        dirtySinceLastSubmit,
        submitErrors,
    }: FormRenderProps<IFormProps>) => (
        <form onSubmit={handleSubmit} className={styles.root}>
            <StatusLabel
                text={!dirtySinceLastSubmit && submitErrors?.submit ? submitErrors.submit : ''}
                className={styles.issueText}
            />
            <div className={styles.controls}>
                <AudioDevice disabled={isAudioForbid} />
                <VideoDevice disabled={isVideoForbid} />
                <div>
                    <Button
                        styleType='common'
                        disabled={submitting}
                        type='button'
                        className={styles.additionalOptions}>
                        <KebabSvg className={styles.additionalOptions__img} />
                        <Trans id='More' message='Еще' />
                    </Button>
                </div>
            </div>
            <div className={styles.submitBtnWrapper}>
                <Button
                    styleType='mobilePrimaryAction'
                    disabled={submitting}
                    type='submit'
                    tabIndex={2}>
                    <Trans id='Join' message='Присоединиться' />
                </Button>
            </div>
        </form>
    );

    return <Form onSubmit={onSubmit} render={formRender} initialValues={getInitialValues()} />;
};
