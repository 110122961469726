import { audioSettings, conferenceSettings, videoSettings } from 'constants/appSettingsStructure';
import { TAppState } from 'types/app/TAppStore';
import { ISettingsPartStateProps } from './types';
import { EAppSettingsFieldsName } from 'constants/EAppSettingsFieldsName';

export const getSettingsWithout3DAudio = () => {
    const filteredAudioSettings = { ...audioSettings };
    filteredAudioSettings.fields = filteredAudioSettings.fields.filter(
        (field) => field.name !== EAppSettingsFieldsName.AUDIO_3D_AUDIO,
    );
    return [conferenceSettings, videoSettings, filteredAudioSettings];
};

export const mapSettingsPartStateToProps = (state: TAppState): ISettingsPartStateProps => ({
    settings: state.auth.appSettings,
});
