import { MediaPermissionDialogConnected } from 'pages/ConferencePage/MediaPermissionDialog';
import { ParticipantsLayoutConnected } from 'pages/ConferencePage/ParticipantsLayout';
import { SimpleConferencePageTemplate } from 'pages/templates/SimpleConferencePageTemplate';
import { useEffect } from 'react';
import styles from './SimpleConferencePage.module.scss';
import { IDispatchProps, IStateProps } from './types';
import { SimpleConferencePagePlaceholderConnected } from './SimpleConferencePagePlaceholder';

export const SimpleConferencePage: React.FC<IStateProps & IDispatchProps> = ({
    id,
    janusIsReady,
    closeJanusSession,
    subscribeToConferenceEvents,
    showAudioPermissionDialog,
    isNoFramesToDisplay,
}) => {
    useEffect(() => {
        return () => {
            closeJanusSession();
        };
    }, []);

    useEffect(() => {
        if (janusIsReady) {
            subscribeToConferenceEvents(id);
        }
    }, [janusIsReady]);

    if (isNoFramesToDisplay) {
        return <SimpleConferencePagePlaceholderConnected />;
    }

    return (
        <SimpleConferencePageTemplate>
            <div className={styles.notification}>
                {showAudioPermissionDialog && <MediaPermissionDialogConnected />}
            </div>
            <ParticipantsLayoutConnected key={id} hideOwnFrame={true} forRecorder={true} />
        </SimpleConferencePageTemplate>
    );
};
