import { Trans } from '@lingui/react';
import ErrorImg from 'assets/Error.svg';
import { HorizontalLoader } from 'components/common/HorizontalLoader';
import { useEffect, useRef, useState } from 'react';
import { redirect } from 'utils/redirect';
import styles from './ConfigErrorPage.module.scss';
import { IDispatchProps } from './types';

export const ConfigErrorPage: React.FC<IDispatchProps> = ({ getAppConfigFromServer }) => {
    const [requestStatus, setRequestStatus] = useState<null | boolean>(null);
    const componentMounted = useRef(true);
    const timeoutTimer = useRef<NodeJS.Timeout | null>(null);

    const getConfig = async () => {
        const response = await getAppConfigFromServer();
        if (componentMounted.current) {
            if (response?.payload) {
                setRequestStatus(true);
                timeoutTimer.current = null;
            } else {
                setRequestStatus(false);
                timeoutTimer.current = setTimeout(getConfig, 5000);
            }
        }
    };

    useEffect(() => {
        getConfig();
        return () => {
            componentMounted.current = false;
            timeoutTimer.current && clearInterval(timeoutTimer.current);
        };
    }, []);

    useEffect(() => {
        if (requestStatus) {
            redirect('/');
        }
    }, [requestStatus]);

    const getText = () => {
        if (requestStatus === null) {
            return (
                <Trans
                    id='config.error.text'
                    message='Что-то пошло не так, пожалуйста подождите...'
                />
            );
        }
        if (!requestStatus) {
            return (
                <Trans
                    id='config.error.unsuccess'
                    message='Что-то пошло не так, попробуйте позже'
                />
            );
        }
    };
    return (
        <div className={styles.container}>
            <HorizontalLoader color='blue' />
            <span className={styles.description}>{getText()}</span>
            <img src={ErrorImg} className={styles.img} alt='Error' />
        </div>
    );
};
