import { t } from '@lingui/macro';
import { Button } from 'components/common/Button';
import { IOwnProps } from './types';
import styles from './PastConferenceControls.module.scss';
import { HoverTooltip } from 'components/common/HoverTooltip';

export const PastConferenceControls: React.FC<IOwnProps> = ({
    handleConferenceHistory,
    handleStartAgain,
}) => {
    const startAgainText = t({ id: 'start.again' });
    const conferenceHistoryText = t({ id: 'Conference history' });
    return (
        <div className={styles.conference__controls} data-past-conf-controls>
            <Button
                styleType={'secondaryActionNew'}
                caption={conferenceHistoryText}
                clickHandler={handleConferenceHistory}
                className={styles.conference__controlsButton}
            />
            <Button
                styleType={'primaryActionNew'}
                clickHandler={handleStartAgain}
                disabled={!handleStartAgain}
                className={styles.conference__controlsButton}
            >
                {startAgainText}
                { !handleStartAgain && <HoverTooltip name={t({id: 'pastConference.buttonAgain.disabled'})} /> }
            </Button>
        </div>
    );
};
