import { t } from '@lingui/macro';
import { setBrowserBtnBackWasClicked } from 'utils/browserBtnBackWasClicked';
import { isExitFromConference } from 'utils/isExitFromConference';

interface IExitAndFinishConferencePayload {
    finishConference: () => Promise<boolean>;
    showNotificationError: () => void;
    exitFromConference: () => void;
    pathname: string;
}

export const exitAndFinishConference = async ({
    finishConference,
    showNotificationError,
    exitFromConference,
    pathname,
}: IExitAndFinishConferencePayload) => {
    setBrowserBtnBackWasClicked(false);
    const isSuccess = await finishConference();
    //TODO возможно добавить закрытие чата, чтобы избежать повторных рендеров
    if (!isSuccess) {
        showNotificationError();
        return;
    }
    exitFromConference();
    if (!isExitFromConference(pathname)) {
        setBrowserBtnBackWasClicked(true);
    }
};

interface IExitConferencePayload {
    exitFromConference: () => void;
    pathname: string;
}

export const exitConference = ({ exitFromConference, pathname }: IExitConferencePayload) => {
    setBrowserBtnBackWasClicked(false);
    exitFromConference();
    if (!isExitFromConference(pathname)) {
        setBrowserBtnBackWasClicked(true);
    }
};

export const getDialogText = (currentUserIsOwner?: boolean) => {
    return !currentUserIsOwner
        ? t({
            id: 'conference.page.exit.text.id',
            message: 'Вы действительно хотите выйти из конференции?',
        })
        : t({
            id: 'conference.page.exit.owner.text.id',
            message: 'Вы действительно хотите выйти и завершить конференцию?',
        });
};
