import { IPreferredDevices } from 'types/IPreferredDevices';
import { IUserDevice } from 'types/IUserDevice';

export const getPreferredDevicesByDevice = (device: IUserDevice): IPreferredDevices => {
    if (device.kind === 'audioinput') {
        return { audio: { id: device.deviceId } };
    }
    if (device.kind === 'videoinput') {
        return { video: { id: device.deviceId } };
    }
    return {};
};
