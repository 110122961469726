import { IDispatchProps } from './types';
import { useEffect, useState } from 'react';
import { PublicKeyForm } from './PublicKeyForm/PublicKeyForm';

export const Authorization: React.FC<IDispatchProps> = ({ getPublicKey, editPublicKey }) => {
    const [publicKey, setPublicKey] = useState('');

    const getCurrentPublicKey = async () => {
        const data = await getPublicKey();
        if (typeof data === 'string') {
            setPublicKey(data);
        }
    };

    const onFormSubmit = async (newKey: string) => {
        const data = await editPublicKey(newKey);
        if (typeof data === 'string') {
            setPublicKey(data);
        } else {
            return data
        }
    };

    useEffect(() => {
        getCurrentPublicKey();
    }, []);

    return (
        <div>
            <PublicKeyForm currentValue={publicKey} handleSubmit={onFormSubmit} />
        </div>
    );
};
