import { getDeviceChanges, storeInitialDevices } from 'components/HOCs/ComponentWithDevices/utils';
import { getUserDevicesList, playStream, stopStream } from 'utils/streamOperations';
import { IOwnProps, IWithDeviceProps } from './types';

export const ComponentWithDevices = <T extends IWithDeviceProps = IWithDeviceProps>(
    WrappedComponent: React.FC<T>,
    props?: IOwnProps,
) => {
    const videoOutput = props?.videoOutput;

    const handles: IWithDeviceProps = {
        async handleMount(setDeviceList, setDeviceListIsLoading, showDeviceNotification) {
            setDeviceListIsLoading(true);
            let success = true;
            try {
                let devices = await getUserDevicesList();
                if (devices && devices.length > 0) {
                    if (devices.some((device) => device.label === '')) {
                        // Get stream for correct media info update
                        const userMediaConstraints: { video?: boolean; audio?: boolean } = {};

                        devices.forEach((device) => {
                            const deviceType = device.kind;
                            if (deviceType === 'audioinput') {
                                userMediaConstraints.audio = true;
                            }
                            if (deviceType === 'videoinput') {
                                userMediaConstraints.video = true;
                            }
                        });

                        const stream = await navigator.mediaDevices.getUserMedia(
                            userMediaConstraints,
                        );
                        // Request devices again
                        devices = await getUserDevicesList();
                        // Stop temporary stream
                        const tracks = stream.getTracks();
                        tracks.forEach((track: any) => {
                            track.stop();
                        });
                    }
                }
                if (showDeviceNotification) {
                    storeInitialDevices(devices);
                }
                setDeviceList(devices);
                navigator.mediaDevices.ondevicechange = async (event) => {
                    const newMediaDevices = event.currentTarget as MediaDevices;
                    const newDevicesList = await newMediaDevices.enumerateDevices();

                    if (showDeviceNotification) {
                        const device = getDeviceChanges(newDevicesList);
                        if (device) {
                            showDeviceNotification(device);
                        }
                    }
                    setDeviceList(newDevicesList);
                };
            } catch (e) {
                success = false;
            } finally {
                setDeviceListIsLoading(false);
            }
            return success;
        },

        handleUnmount(localCurrentVideoOutput, videoOutputLocal) {
            const output = !videoOutputLocal ? videoOutput : videoOutputLocal;
            stopStream(output, localCurrentVideoOutput);
        },

        async handlePropsChange(
            usedDevices,
            videoOutputLocal?,
            preferredDevices?: {
                video?: { id: string };
                audio?: { id: string };
            },
        ) {
            const output = !videoOutputLocal ? videoOutput : videoOutputLocal;
            if (!usedDevices.audio && !usedDevices.video) {
                stopStream(output);
                return true;
            } else {
                let success = true;
                try {
                    await playStream(usedDevices, output, preferredDevices);
                } catch (e) {
                    success = false;
                }
                return success;
            }
        },

        getDeviceListByType(kind, devices) {
            if (devices.length === 0) {
                return [];
            }
            return devices.filter((elem) => elem.kind === kind);
        },
    };

    const ComponentWithDevice: React.FC<Omit<T, keyof IWithDeviceProps>> = (props) => {
        const deviceProps = handles;
        return <WrappedComponent {...deviceProps} {...(props as T)} />;
    };

    return ComponentWithDevice;
};
