import { removeRemoteStreamAction } from 'actions/janus/removeRemoteStreamAction';
import { startJanusPublisherStreamAction } from 'actions/janus/startJanusPublisherStreamAction';
import { MAX_FEEDS } from 'constants/janusConstants';
import { checkActiveParticipantsAndCanPublishThunkAction } from 'thunk/janus/checkActiveParticipantsAndCanPublishThunkAction';
import { TAppState } from 'types/app/TAppStore';
import { IHandleSubscription } from 'types/janus/IHandleSubscriptions';
import { janusVideoRoomLogger, janusVideoRoomMessagesLogger } from 'utils/logger';
import { janusVideoRoomTracksLogger } from '../logger';
import { escapeXmlTags } from './helpers/escapeXmlTags';
import { janusCtx } from './janusCtx';

export const singleStreamSubscribe = (id: string, display: any, newStreams: any) => {
    janusVideoRoomTracksLogger.debug('New remote feed ', { id, display, new_streams: newStreams });
    // A new feed has been published, create a new plugin handle and attach to it as a subscriber
    // let streams = new_streams;
    const {
        janus: { streams: listOfStreams },
    } = janusCtx.getState() as TAppState;
    janusCtx.singleStreamPublishersStreams[id] = newStreams;
    const currentStreams = listOfStreams[id];
    // if (!streams) {
    //     streams = currentStreams;
    // }

    let subscribeHandler: any;
    for (let i = 1; i < MAX_FEEDS; i++) {
        if (
            janusCtx.singleStreamSubscribeHandlers[i] &&
            janusCtx.singleStreamSubscribeHandlers[i].rfid === id
        ) {
            subscribeHandler = janusCtx.singleStreamSubscribeHandlers[i];
            break;
        }
    }

    if (subscribeHandler) {
        janusVideoRoomTracksLogger.debug('Found plugin, need to subscribe');
        // Already subscribed, need only to send message
        const message: any = { request: 'update' };
        newStreams.forEach((stream: any) => {
            janusVideoRoomTracksLogger.log({ currentStreams });
            let needToSubscribe = !currentStreams;
            if (stream.type === 'audio' && !needToSubscribe) {
                needToSubscribe = !currentStreams.audio;
            }
            if (stream.type === 'video' && !needToSubscribe) {
                needToSubscribe = !currentStreams.video || !currentStreams.video.stream.active;
            }
            janusVideoRoomTracksLogger.log({ needToSubscribe });
            if (needToSubscribe) {
                if (!message.subscribe) {
                    message.subscribe = [];
                }
                message.subscribe.push({ feed: id, mid: stream.mid });
            }
            if (currentStreams && stream.disabled) {
                if (!message.unsubscribe) {
                    message.unsubscribe = [];
                }
                message.unsubscribe.push({ feed: id, mid: stream.mid });
                // Remove stream
                janusCtx.dispatch(removeRemoteStreamAction({ participantId: id, mid: stream.mid }));
            }
        });

        if (message.unsubscribe || message.subscribe) {
            janusVideoRoomTracksLogger.debug(`Send update message ${JSON.stringify(message)}`);
            subscribeHandler.send({
                message,
            });
        }
        return;
    }
    janusVideoRoomTracksLogger.debug('Create new video room plugin instance for subscribe');
    janusCtx.janusInstance.attach({
        plugin: 'janus.plugin.videoroom',
        opaqueId: janusCtx.opaqueId,
        success: (pluginHandle: any) => {
            subscribeHandler = pluginHandle;
            successAttachHandle(newStreams, subscribeHandler, id);
        },
        error: (error: any) => {
            janusVideoRoomTracksLogger.error('  -- Error attaching plugin...', error);
        },
        onmessage: (msg: any, jsep: any) => onMessageHandle(subscribeHandler, msg, jsep),
        iceState: (state: any) => {
            janusVideoRoomTracksLogger.debug(
                'ICE state of this WebRTC PeerConnection (feed #' +
                    subscribeHandler.rfid +
                    ') changed to ' +
                    state,
            );
        },
        webrtcState: (on: any) => {
            janusVideoRoomTracksLogger.debug(
                `Janus says this WebRTC PeerConnection (feed ${subscribeHandler.rfid}) is ${
                    on ? 'up' : 'down'
                } now`,
            );
        },
        onlocaltrack: (stream: any) => {
            // The subscriber stream is recvonly, we don't expect anything here
            janusVideoRoomTracksLogger.debug('onlocaltrack');
            janusVideoRoomTracksLogger.debug(stream);
        },
        onremotetrack: (track: MediaStreamTrack, mid: string, on: boolean) => {
            janusVideoRoomTracksLogger.debug(
                `Remote feed #${subscribeHandler.rfid}, track:`,
                track,
            );
            janusVideoRoomTracksLogger.debug({ mid, on });
            const { janus } = janusCtx.getState() as TAppState;
            if (!janus) {
                return;
            }
            janusVideoRoomTracksLogger.log({ janus });
            const { streams } = janus;
            janusVideoRoomTracksLogger.log({ streams });
            if (!on) {
                const publisherStreams = streams ? streams[id] : undefined;
                if (!publisherStreams) {
                    return;
                }
                let stream = null;
                if (publisherStreams.audio?.mid === mid) {
                    stream = publisherStreams.audio.stream;
                } else if (publisherStreams.video?.mid === mid) {
                    stream = publisherStreams.video.stream;
                }
                janusVideoRoomTracksLogger.log({ subscribeHandler });
                if (stream) {
                    try {
                        const tracks = stream.getTracks();
                        for (const t of tracks) {
                            if (t) {
                                t.stop();
                            }
                        }
                    } catch (e) {
                        janusVideoRoomTracksLogger.error(e);
                    }
                    janusCtx.dispatch(removeRemoteStreamAction({ participantId: id, mid }));
                    if (subscribeHandler) {
                        const message = { request: 'update', unsubscribe: [{ feed: id, mid }] };
                        janusVideoRoomTracksLogger.debug({ message });
                        subscribeHandler.send({
                            message,
                        });
                        janusCtx.singleStreamSubscriptions[id] = janusCtx.singleStreamSubscriptions[
                            id
                        ].filter(({ mid: subscribedMid }) => mid !== subscribedMid);
                    }
                } else {
                    janusVideoRoomTracksLogger.debug(
                        `Attempt to unsubscribe from stream with mid ${mid}, but looks like we already do it`,
                    );
                }
                const feedStreams = janusCtx.singleStreamPublishersStreams[subscribeHandler.rfid];
                const feedStream = feedStreams.find((stream: any) => stream.mid === mid);
                const audioVideoProps = (janusCtx.getState() as TAppState).janus.publishers[
                    subscribeHandler.rfid
                ].audioVideoProps;
                let needReconnect = false;
                if (feedStream) {
                    if (feedStream.type === 'audio') {
                        if (audioVideoProps.useAudio) {
                            needReconnect = true;
                        }
                    } else if (feedStream.type === 'video') {
                        if (audioVideoProps.useVideo) {
                            needReconnect = true;
                        }
                    }
                }
                janusVideoRoomTracksLogger.log({ feedStreams, audioVideoProps });
                if (needReconnect) {
                    reconnect(subscribeHandler);
                }
                return;
            }

            if (track.kind === 'audio') {
                const audio = streams[id]?.audio;
                if (audio) {
                    janusVideoRoomMessagesLogger.debug('Audio track already exist');
                    const oldTrack = audio.stream.getAudioTracks()[0];
                    janusVideoRoomMessagesLogger.debug(`Same track: ${track === oldTrack}`);
                    if (audio.mid !== mid) {
                        janusVideoRoomMessagesLogger.debug(
                            `New mid for audio, was ${audio.mid}, become ${mid}`,
                        );
                        if (oldTrack) {
                            janusVideoRoomTracksLogger.debug('Stop old track');
                            oldTrack.stop();
                        }
                        subscribeHandler.send({
                            request: 'update',
                            unsubscribe: [{ feed: id, mid: audio.mid }],
                        });
                    }
                }
                const stream = new MediaStream();
                stream.addTrack(track);
                janusCtx.dispatch(
                    startJanusPublisherStreamAction({ stream, id, type: 'audio', mid }),
                );
            } else {
                const video = streams[id]?.video;
                if (video) {
                    janusVideoRoomLogger.debug('Video track already exist');
                    const oldTrack = video?.stream.getVideoTracks()[0];
                    janusVideoRoomTracksLogger.debug(`Same track: ${track === oldTrack}`);
                    if (video.mid !== mid) {
                        janusVideoRoomMessagesLogger.debug(
                            `New mid for video, was ${video.mid}, become ${mid}`,
                        );
                        if (oldTrack) {
                            janusVideoRoomTracksLogger.debug('Stop old track');
                            oldTrack.stop();
                        }
                        subscribeHandler.send({
                            request: 'update',
                            unsubscribe: [{ feed: id, mid: video.mid }],
                        });
                        // janusCtx.subscriptions[id]=janus
                    }
                }
                const stream = new MediaStream();
                stream.addTrack(track);
                janusCtx.dispatch(
                    startJanusPublisherStreamAction({ stream, id, type: 'video', mid }),
                );
                janusVideoRoomTracksLogger.debug('Store new stream for display');
            }
            janusCtx.dispatch(checkActiveParticipantsAndCanPublishThunkAction());
        },
        oncleanup: () => {
            janusVideoRoomTracksLogger.debug(
                ` ::: Got a cleanup notification (remote feed ${id}) :::`,
            );
            if (janusCtx.bitrateTimer[subscribeHandler.rfindex]) {
                clearInterval(janusCtx.bitrateTimer[subscribeHandler.rfindex]);
            }
            janusCtx.bitrateTimer[subscribeHandler.rfindex] = null;
            subscribeHandler.simulcastStarted = false;
        },
    });
};

const successAttachHandle = (streams: any[], subscribeHandler: any, id: string) => {
    janusVideoRoomTracksLogger.debug(
        'Plugin attached! (' +
            subscribeHandler.getPlugin() +
            ', id=' +
            subscribeHandler.getId() +
            ')',
    );
    janusVideoRoomTracksLogger.debug('  -- This is a subscriber');
    // Prepare the streams to subscribe to, as an array: we have the list of
    // streams the feed is publishing, so we can choose what to pick or skip
    const subscription: { feed: string; mid: string }[] = [];
    const handleSubscriptions: IHandleSubscription[] = [];
    for (const i in streams) {
        const stream = streams[i];
        // If the publisher is VP8/VP9 and this is an older Safari, let's avoid video
        if (
            stream.type === 'video' &&
            janusCtx.browser === 'safari' &&
            (stream.codec === 'vp9' || (stream.codec === 'vp8' && !janusCtx.safariVp8))
        ) {
            janusVideoRoomTracksLogger.error(
                `Publisher is using ${stream.codec.toUpperCase}, but Safari doesn't support it: disabling video stream #${stream.mindex}`,
            );
            continue;
        }
        subscription.push({
            feed: stream.id, // This is mandatory
            mid: stream.mid, // This is optional (all streams, if missing)
        });
        handleSubscriptions.push({
            type: stream.type as 'audio' | 'video',
            feed_mid: stream.mid,
            mid: stream.mid,
        });
        // FIXME Right now, this is always the same feed: in the future, it won't
        subscribeHandler.rfid = stream.id;
        subscribeHandler.rfdisplay = escapeXmlTags(stream.display);
    }
    // We wait for the plugin to send us an offer
    const message = {
        request: 'join',
        room: janusCtx.roomId,
        ptype: 'subscriber',
        // feed: id,
        private_id: janusCtx.mypvtid,
        pin: (janusCtx.getState() as TAppState).conferenceSession.currentConferencePin,
        streams: subscription,
    };
    subscribeHandler.send({
        message,
        success: () => {
            janusCtx.singleStreamSubscriptions[id] = handleSubscriptions;
        },
    });
};

const onMessageHandle = (subscribeHandler: any, msg: any, jsep: any) => {
    janusVideoRoomTracksLogger.debug(
        ' ::: Got a message (subscriber) singleStreamSubscriber :::',
        msg,
    );
    const event = msg['videoroom'];
    janusVideoRoomTracksLogger.debug('Event: ' + event);

    const checkConnection = () => {
        janusVideoRoomTracksLogger.log({ subscribeHandler });
        const publisherId = subscribeHandler.rfid;
        const {
            janus: { streams },
        } = janusCtx.getState() as TAppState;
        const pstreams = streams[publisherId];
        if (!pstreams) {
            // FIXME
            return;
        }
        janusVideoRoomTracksLogger.log(pstreams.video?.stream);

        let needToReconnect = false;
        if (!pstreams) {
            janusVideoRoomTracksLogger.debug('Check after start - but nothing to do with it');
            needToReconnect = true;
        } else {
            janusVideoRoomTracksLogger.log(pstreams.video?.stream);
            if (pstreams.video?.stream.active === false) {
                needToReconnect = true;
            }
            if (pstreams.audio?.stream.active === false) {
                needToReconnect = true;
            }
        }
        const transceivers = subscribeHandler.webrtcStuff?.pc?.getTransceivers();
        if (transceivers && transceivers.length > 2) {
            needToReconnect = true;
        }
        if (needToReconnect) {
            reconnect(subscribeHandler);
        }
    };

    if (msg['error']) {
        // Some error handle
    } else if (event) {
        if (event === 'attached') {
            // Subscriber created and attached
            for (let i = 1; i < MAX_FEEDS; i++) {
                if (!janusCtx.singleStreamSubscribeHandlers[i]) {
                    janusCtx.singleStreamSubscribeHandlers[i] = subscribeHandler;
                    subscribeHandler.rfindex = i;
                    break;
                }
            }
            janusVideoRoomTracksLogger.debug(
                `Successfully attached to feed ${subscribeHandler.rfid} (${subscribeHandler.rfdisplay}) in room ${msg['room']}`,
            );
        } else if (event === 'event') {
            // Check if we got a simulcast-related event from this publisher
            const { substream, temporal } = msg;
            if (
                (substream !== null && substream !== undefined) ||
                (temporal !== null && temporal !== undefined)
            ) {
                if (!subscribeHandler.simulcastStarted) {
                    subscribeHandler.simulcastStarted = true;
                }
            }
            if (msg['started'] === 'ok') {
                // Check streams again
                setTimeout(checkConnection, 2000);
            }
        } else {
            // What has just happened?
            janusVideoRoomTracksLogger.debug('Unprocessed message ');
        }
    }

    if (jsep) {
        janusVideoRoomTracksLogger.debug('Handling SDP as well...', jsep);
        // Answer and attach
        subscribeHandler.createAnswer({
            jsep,
            tracks: [{ type: 'data' }],
            success: function (jsep: any) {
                janusVideoRoomTracksLogger.debug('Got SDP!', jsep);
                const message = { request: 'start', room: janusCtx.roomId };
                subscribeHandler.send({ message, jsep });
            },
            error: function (error: any) {
                janusVideoRoomTracksLogger.error('WebRTC error:', error);
                // bootbox.alert('WebRTC error... ' + error.message);
            },
        });
    }
};

const reconnect = (subscribeHandler: any) => {
    setTimeout(() => {
        janusVideoRoomLogger.debug('Reconnect current peerConnection');
        janusCtx.singleStreamSubscribeHandlers[subscribeHandler.rfindex] = null;
        janusVideoRoomMessagesLogger.log({ subscribeHandler });
        subscribeHandler.hangup(false);
        for (let i = 1; i < MAX_FEEDS; i++) {
            if (
                janusCtx.singleStreamSubscribeHandlers[i] &&
                janusCtx.singleStreamSubscribeHandlers[i].rfid === subscribeHandler.rfid
            ) {
                janusCtx.singleStreamSubscribeHandlers[i] = null;
                break;
            }
        }
        const streams = janusCtx.singleStreamPublishersStreams[subscribeHandler.rfid];
        janusVideoRoomTracksLogger.debug({ currentFeeds: streams });
        singleStreamSubscribe(
            subscribeHandler.rfid,
            subscribeHandler.display,
            janusCtx.singleStreamPublishersStreams[subscribeHandler.rfid],
        );
    }, 500);
};
