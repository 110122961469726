import { createAsyncThunk } from '@reduxjs/toolkit';
import { EPaths } from 'constants/EPaths';
import { redirect } from 'utils/redirect';
import { deleteConferenceThunkAction } from './deleteConferenceThunkAction';

export const deleteConferenceFromConferenceSettingsFormMobileThunkAction = createAsyncThunk(
    'deleteConferenceFromConferenceSettingsFormMobile',
    async (id: string, { dispatch }) => {
        const success = await dispatch(deleteConferenceThunkAction(id)).unwrap();
        if (success) {
            redirect(EPaths.UPCOMING_CONFERENCES);
        }
    },
);
