import { IDatesRange } from 'types/dates/IDatesRange';
import { getCurrentDate } from './getCurrentDate';
import { setDateToDayEnd } from './setDateToDayEnd';
import { setDateToDayStart } from './setDateToDayStart';

export function getRangeForToday(): IDatesRange {
    const startDate = getCurrentDate();
    setDateToDayStart(startDate);
    const endDate = new Date(startDate);
    setDateToDayEnd(endDate);
    return {
        startDate,
        endDate,
    };
}
