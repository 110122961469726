import { createReducer } from '@reduxjs/toolkit';
import { deleteConferenceInfoAction } from 'actions/conferences/deleteConferenceInfoAction';
import { resetStoreAction } from 'actions/resetStoreAction';
import { addConferenceIdAtPositionAction } from 'actions/sessionSettings/addConferenceIdAtPositionAction';
import { changeConferencesListFilterAction } from 'actions/sessionSettings/changeConferencesListFilterAction';
import { changeRecordsFilterAction } from 'actions/sessionSettings/changeRecordsFilterAction';
import { resetSessionSettingsAction } from 'actions/sessionSettings/resetSessionSettingsAction';
import { setConferenceIdsToShowAction } from 'actions/sessionSettings/setConferenceIdsToShowAction';
import { setConferenceListScrollOffsetAction } from 'actions/sessionSettings/setConferenceListScrollOffsetAction';
import { format } from 'date-fns';
import { EPredefinedDatesRange } from 'types/dates/EPredefinedDatesRange';
import { IDateFilters } from 'types/IDateFilters';
import { IRecordsFilter } from 'types/IRecordsFilters';
import { getDatesByPredefinedRange } from 'utils/dates/getDatesByPredefinedRange';

export interface ISessionSettingsReducer {
    conferencesFilter: IDateFilters;
    recordsFilter: IRecordsFilter;
    conferenceListScrollOffset: number | null;
    conferenceIdsToShow: string[];
}
const range = EPredefinedDatesRange.ThisWeek;
const thisWeekPast = getDatesByPredefinedRange(range, true);
const thisWeekUpcoming = getDatesByPredefinedRange(range, false);

export const sessionSettingsReducerInitialState: ISessionSettingsReducer = {
    conferencesFilter: {
        finished: false,
        past: {
            from: format(thisWeekPast.startDate, 'dd.MM.yyyy'),
            to: format(thisWeekPast.endDate, 'dd.MM.yyyy'),
            range,
        },
        upcoming: {
            from: format(thisWeekUpcoming.startDate, 'dd.MM.yyyy'),
            to: format(thisWeekUpcoming.endDate, 'dd.MM.yyyy'),
            range,
        },
    },
    recordsFilter: {
        from: format(thisWeekPast.startDate, 'dd.MM.yyyy'),
        to: format(thisWeekPast.endDate, 'dd.MM.yyyy'),
    },
    conferenceListScrollOffset: null,
    conferenceIdsToShow: [],
};

export const sessionSettingsReducer = createReducer(sessionSettingsReducerInitialState, (builder) =>
    builder
        .addCase(resetSessionSettingsAction, () => {
            return sessionSettingsReducerInitialState;
        })
        .addCase(
            addConferenceIdAtPositionAction,
            (state, { payload: { position, newConferenceId } }) => {
                if (position) {
                    const targetPosition = state.conferenceIdsToShow.indexOf(position.targetId);
                    const newConferenceIdToShow = [...state.conferenceIdsToShow];
                    newConferenceIdToShow.splice(
                        position.isAfterTarget ? targetPosition + 1 : targetPosition,
                        0,
                        newConferenceId,
                    );
                    state.conferenceIdsToShow = newConferenceIdToShow;
                } else {
                    state.conferenceIdsToShow.push(newConferenceId);
                }
            },
        )
        .addCase(changeConferencesListFilterAction, (state, { payload: newSettings }) => {
            state.conferencesFilter = { ...state.conferencesFilter, ...newSettings };
        })
        .addCase(changeRecordsFilterAction, (state, { payload: newSettings }) => {
            state.recordsFilter = newSettings;
        })
        .addCase(setConferenceListScrollOffsetAction, (state, { payload: offset }) => {
            state.conferenceListScrollOffset = offset;
        })
        .addCase(setConferenceIdsToShowAction, (state, { payload: ids }) => {
            state.conferenceIdsToShow = ids;
        })
        .addCase(deleteConferenceInfoAction, (state, { payload: conferenceId }) => {
            // Remove conference from page view
            state.conferenceIdsToShow = state.conferenceIdsToShow.filter(
                (id) => id !== conferenceId,
            );
        })
        .addCase(resetStoreAction, () => sessionSettingsReducerInitialState),
);
