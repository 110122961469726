import { isUserIsConferenceOwner } from 'utils/users/isUserIsConferenceOwner';
import { IOwnPropsMobile } from './types';
import { UpcomingConferenceMobile } from './UpcomingConferenceMobile';

export const UpcomingConferencesMobile: React.FC<IOwnPropsMobile> = ({
    conferences,
    currentUser,
    swipedConferenceId,
    handleTouchMove,
    handleTouchStart,
    goToConnectConference,
    closeActionButtons,
    editConferenceHandler,
    deleteConferenceHandler,
}) => {
    return (
        <>
            {conferences.map((conference) => {
                const userIsOwner = isUserIsConferenceOwner(currentUser, conference);
                const actionButtonsOpen = swipedConferenceId === conference.id;
                return (
                    <UpcomingConferenceMobile
                        key={conference.id}
                        conference={conference}
                        userIsOwner={userIsOwner}
                        actionButtonsOpen={actionButtonsOpen}
                        closeActionButtons={closeActionButtons}
                        handleTouchMove={handleTouchMove}
                        handleTouchStart={handleTouchStart}
                        goToConnectConference={goToConnectConference}
                        editConferenceHandler={editConferenceHandler}
                        deleteConferenceHandler={deleteConferenceHandler}
                    />
                );
            })}
        </>
    );
};
