import { connect } from 'react-redux';
import { TAppState } from 'types/app/TAppStore';
import { getActualRatioForDisplayVideo } from 'utils/getActualRatioForDisplayVideo';
import { ParticipantsLayout } from './ParticipantsLayout';
import { IStateProps, IDispatchProps } from './types';
import { TAppDispatch } from 'types/app/TAppDispatch';
import { setParticipantsLayoutPropertiesAction } from 'actions/applicationView/setParticipantsLayoutPropertiesAction';

const mapStateToProps = (state: TAppState): IStateProps => ({
    currentParticipantId: state.janus.ownParticipantId,
    displayedVideoRatio: getActualRatioForDisplayVideo(state),
    hasFullScreenFrames: !!state.applicationView.fullScreenFrames[0],
    participantFrameSize: state.applicationView.displayedFrameSize,
    displayedVideoFrames: state.applicationView.displayedVideoFrames,
});

const mapDispatchToProps = (dispatch: TAppDispatch): IDispatchProps => ({
    setParticipantsLayoutProperties: (payload) => dispatch(setParticipantsLayoutPropertiesAction(payload)),
})

export const ParticipantsLayoutConnected = connect(mapStateToProps, mapDispatchToProps)(ParticipantsLayout);
