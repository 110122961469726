import { IConferenceConnectInfo } from 'types/conferences/IConferenceConnectInfo';
import { IPublisher } from 'types/IPublisher';

interface IPayload {
    display: string;
    participantId: string;
    isOwner: boolean;
    conferenceConnectInfo?: IConferenceConnectInfo;
    moderationPermissions: {
        allowedStreams: {
            audio: string[];
            video: string[];
        };
        forbiddenStreams: {
            audio: string[];
            video: string[];
        };
    };
}

export const getNewPublisher = ({
    display,
    participantId,
    isOwner,
    conferenceConnectInfo,
    moderationPermissions,
}: IPayload): IPublisher => {
    const result = {
        displayName: display,
        participantId,
        audioVideoProps: {
            audioPermitted: true,
            useAudio: false,
            useVideo: false,
            videoPermitted: true,
            screenShared: false,
        },
    };
    if (!conferenceConnectInfo || isOwner) {
        return result;
    }
    if (conferenceConnectInfo.forbidAudio) {
        if (!moderationPermissions.allowedStreams.audio.some((id) => id === participantId)) {
            result.audioVideoProps.audioPermitted = false;
        }
    }
    if (conferenceConnectInfo.forbidVideo) {
        if (!moderationPermissions.allowedStreams.video.some((id) => id === participantId)) {
            result.audioVideoProps.videoPermitted = false;
        }
    }

    return result;
};
