import { differenceInSeconds } from 'date-fns';
import { convertStringToDateByTemplate } from 'utils/dates/convertStringToDateByTemplate';

export const getDurationInSeconds = (
    timeStart: string,
    timeEnd: string,
    duration: number | undefined,
): number => {
    if (duration) return duration;

    const timeStartAsDate = convertStringToDateByTemplate(timeStart, 'yyyy-MM-dd HH:mm:ss.SSSSSS');
    const timeEndAsDate = convertStringToDateByTemplate(timeEnd, 'yyyy-MM-dd HH:mm:ss.SSSSSS');
    return differenceInSeconds(timeStartAsDate, timeEndAsDate);
};
