import { t } from '@lingui/macro';
import { Trans } from '@lingui/react';
import { FullScreenPopup } from 'components/common/FullScreenPopup';
import { PopupDialog } from 'components/common/PopupDialog';
import { Select } from 'components/common/Select';
import { ConferenceRepeatDialog } from 'components/ConferenceRepeatDialog';
import { mainFormField } from 'components/ConferenceRepeatDialog/utils';
import { ConferenceRepeatInfoConnected } from 'components/ConferenceRepeatInfo';
import { useEffect, useState } from 'react';
import { TStep } from 'types/conferences/TStep';
import { setEscCloseDropdown } from 'utils/escCloseDropdown';
import styles from './ConferenceRepeatBlock.module.scss';
import { IOwnProps } from './types';
import { getOptions } from './utils';

export const ConferenceRepeatBlock: React.FC<IOwnProps> = ({
    updateField,
    recurringData,
    specifiedDate,
    specifiedTime,
    popupContainer,
}) => {
    const options = getOptions();
    const [dialog, setDialog] = useState<{ isOpen: boolean; selectedOption: string }>({
        isOpen: false,
        selectedOption: '',
    });

    useEffect(() => {
        setEscCloseDropdown(dialog.isOpen);
    }, [dialog]);

    const changeHandler = (value: string) => {
        if (value === 'never') {
            updateField(mainFormField, null);
            return;
        }
        setDialog({ isOpen: true, selectedOption: value });
    };
    const closePopup = () => setDialog({ isOpen: false, selectedOption: '' });

    return (
        <div className={styles.container}>
            <div className={styles.block}>
                <div
                    className={`${styles.block__top} ${
                        recurringData ? styles.block__top_noMargin : ''
                    }`}>
                    <span className={styles.label}>
                        <Trans id='Repeat' message='Повтор' />:
                    </span>
                    <Select
                        items={options}
                        selectedValue={recurringData?.step || options[0].value}
                        displayFilteredHeader={true}
                        changeHandler={changeHandler}
                    />
                </div>
                <div className={styles.block__bottom}>
                    {recurringData && (
                        <ConferenceRepeatInfoConnected
                            formData={recurringData}
                            handleEdit={() => setDialog({ isOpen: true, selectedOption: 'week' })}
                        />
                    )}
                </div>
            </div>
            {dialog.isOpen && (
                <FullScreenPopup className={styles.popupContainer} container={popupContainer}>
                    <PopupDialog
                        crossPosition='inside'
                        dialogClassName={styles.popup}
                        onClose={closePopup}
                        title={t({
                            id: 'recurring.conference.dialog.title',
                            message: 'Повтор конференции',
                        })}>
                        <ConferenceRepeatDialog
                            updateField={updateField}
                            closePopup={closePopup}
                            selectedOption={dialog.selectedOption as TStep}
                            recurringData={recurringData}
                            specifiedDate={specifiedDate}
                            specifiedTime={specifiedTime}
                        />
                    </PopupDialog>
                </FullScreenPopup>
            )}
        </div>
    );
};
