import { createAsyncThunk } from '@reduxjs/toolkit';
import { EPaths } from 'constants/EPaths';
import { TAppState } from 'types/app/TAppStore';
import { isDateInFilterRange } from 'utils/dates/isDateInFilterRange';
import { isTargetPath } from 'utils/isTargetPath';
import { getConferenceRecordsByIdThunkAction } from './getConferenceRecordsByIdThunkAction';

interface ISetConferenceRecordPayload {
    conferenceId: string;
    timeStart: string;
    sessionId?: string;
}

export const setConferenceRecordThunkAction = createAsyncThunk<void, ISetConferenceRecordPayload>(
    'setConferenceRecord',
    async ({ conferenceId,sessionId, timeStart }, { dispatch, getState }) => {
        if (!sessionId) {
            return;
        }
        const {
            sessionSettings: {
                conferencesFilter: { past: pastFilter },
            },
        } = getState() as TAppState;

        if (isTargetPath(EPaths.PAST_CONFERENCES) || isTargetPath(EPaths.RECORDS)) {
            if (isDateInFilterRange(pastFilter, timeStart)) {
                await dispatch(
                    getConferenceRecordsByIdThunkAction({
                        conferenceId,
                        sessionId,
                        forceUpdate: true,
                    }),
                );
            }
        }
    },
);
