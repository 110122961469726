import { t } from '@lingui/macro';
import { IStateProps } from './types';

type IGetButtonTextPayload = IStateProps;

export const getButtonText = ({ participantIsOwner, recording }: IGetButtonTextPayload) => {
    const textInactive = t({ id: 'record', message: 'Запись' });
    const textDisabled = t({ id: 'record.disabled', message: 'Запись' });
    const textLaunching = t({ id: 'record.launching', message: 'Запуск...' });
    const textTerminating = t({ id: 'record.terminating', message: 'Остановка...' });
    const textActive = t({ id: 'stop.record', message: 'Ост. запись' });
    let text = '';

    if (recording === 'on') {
        if (!participantIsOwner) {
            text = textDisabled;
        } else {
            text = textActive;
        }
    } else if (recording === 'off') {
        if (!participantIsOwner) {
            text = textDisabled;
        } else {
            text = textInactive;
        }
    } else if (recording === 'launch') {
        text = textLaunching;
    } else if (recording === 'terminate') {
        text = textTerminating;
    }

    return text;
};

type IGetTooltipTextPayload = IStateProps;

export const getTooltipText = ({ participantIsOwner, recording }: IGetTooltipTextPayload) => {
    let text = '';
    if (!participantIsOwner) {
        text = t({ id: 'record.notAllowed', message: 'Записью управляет только владелец' });
    } else if (recording === 'launch') {
        text = t({ id: 'record.launch', message: 'Включение записи' });
    } else if (recording === 'terminate') {
        text = t({ id: 'record.terminate', message: 'Выключение записи' });
    } else if (recording === 'off') {
        text = t({ id: 'record.switchOn', message: 'Включить запись' });
    } else {
        text = t({ id: 'record.switchOff', message: 'Выключить запись' });
    }

    return text;
};
