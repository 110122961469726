import { IOwnProps } from './types';
import { IOwnProps as ITableFilterProps } from './TableFilter/types';
import styles from '../UsersPage.module.scss';
import { Trans } from '@lingui/react';
import { Button } from 'admin-app/components/common/Button';
import { PlusIcon } from 'admin-app/components/common/icons';
import { TableFilter } from './TableFilter';

export const UsersPageHeader: React.FC<IOwnProps & ITableFilterProps> = ({
    ldap,
    openInviteUserDialog,
    ...props
}) => {
    return (
        <div className={styles.header}>
            <h1 className={styles.header__title}>
                <Trans id='admin.users' />
            </h1>
            {!ldap && (
                <Button onClick={openInviteUserDialog} className={styles.header__inviteBtn}>
                    <PlusIcon />
                    <Trans id='admin.newUser' />
                </Button>
            )}
            <div className={styles.header__filterWrapper}>
                <TableFilter {...props} />
            </div>
        </div>
    );
};
