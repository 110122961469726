import { connect } from 'react-redux';
import { getCurrentConferenceNameSelector } from 'selectors/getCurrentConferenceNameSelector';
import { TAppState } from 'types/app/TAppStore';
import { SimpleConferencePagePlaceholder } from './SimpleConferencePagePlaceholder';
import { IStateProps } from './types';

const mapStateToProps = (state: TAppState): IStateProps => ({
    conferenceName: getCurrentConferenceNameSelector(state),
});

export const SimpleConferencePagePlaceholderConnected = connect(mapStateToProps)(
    SimpleConferencePagePlaceholder,
);
