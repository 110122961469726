export const getMsByTemplate = (date: string, template: string) => {
    const msInTemplateCount = template.match(/S/g)?.length;
    const msDate = date.split('.')[1];
    const msInDate = msDate?.replace('Z', '');
    const msInDateCount = msInDate?.length;
    if (msInTemplateCount) {
        let msToDate = msInDate;
        if (!msInDate) {
            msToDate = '0'.repeat(msInTemplateCount);
        } else if (msInDateCount < msInTemplateCount) {
            msToDate = msInDate + '0'.repeat(msInTemplateCount - msInDateCount);
        } else if (msInDateCount > msInTemplateCount) {
            msToDate = msInDate.slice(0, msInTemplateCount);
        }
        return msToDate;
    }
};
