export const isValidUrl = (url: string) => {
    if (url.match(/[A-Z]/)) {
        return false;
    }
    if (url.match(/[а-я]/i)) {
        return false;
    }
    return true;
};

export const isTokenUrl = (url: string) => {
    if (url.match(/[а-я]/i)) {
        return false;
    }
    return true;
};
