import { t } from '@lingui/macro';
import { Button } from 'components/common/Button';
import { Spinner } from 'components/common/Spinner';
import { isFormHasValidationErrors } from 'utils/isFormHasValidationErrors';
import { IOwnProps } from './types';
import styles from './ConferenceSettingsFooter.module.scss';

export const ConferenceSettingsFooter: React.FC<IOwnProps> = ({
    submitting,
    errors,
    pristine,
    conferenceInfo,
    form,
    handleCancelClick,
}) => {
    const submitButtonText = t({ id: 'Save' });

    return (
        <div className={styles.conferenceSettingsFooter}>
            <Button
                styleType={submitting ? 'loading' : 'primaryAction'}
                type='submit'
                disabled={
                    submitting ||
                    isFormHasValidationErrors(errors) ||
                    (pristine && !!conferenceInfo)
                }
                clickHandler={() => form.change('operation', 'save')}>
                {submitting ? <Spinner /> : submitButtonText}
            </Button>
            <Button
                styleType='secondaryAction'
                caption={t({
                    message: 'Отмена',
                    id: 'Cancel',
                })}
                disabled={submitting}
                clickHandler={handleCancelClick}
            />
        </div>
    );
};
