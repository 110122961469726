import { createAsyncThunk } from '@reduxjs/toolkit';
import { setCurrentSessionIdAction } from 'actions/conferenceSession/setCurrentSessionIdAction';
import { saveJanusTokenAction } from 'actions/janus/saveJanusTokenAction';
import { saveOwnParticipantIdAction } from 'actions/janus/saveOwnParticipantIdAction';
import { joinConferenceApiCall } from 'api/conference/joinConferenceApiCall';
import { getNetworkService } from 'api/getNetworkService';
import { batch } from 'react-redux';
import { IRequestError } from 'types/api/IRequestError';
import { TAppState } from 'types/app/TAppStore';
import { setDisabledStartConfButtonAction } from 'actions/applicationView/setDisabledStartConfButtonAction';

export const joinToCurrentConferenceThunkAction = createAsyncThunk(
    'joinToCurrentConference',
    async (recorderId: string | undefined, { dispatch, getState }) => {
        const {
            conferenceSession: { currentConferenceId, currentConferencePin },
            auth: { anonymousUser },
        } = getState() as TAppState;
        const name = anonymousUser ? anonymousUser.name : undefined;
        const response = await joinConferenceApiCall(getNetworkService(), {
            pin: currentConferencePin,
            id: currentConferenceId,
            recorderId,
            name,
        });
        if (response.success) {
            const {
                participant: { participantId: ownParticipantId },
                token,
                sessionId,
            } = response.data;

            batch(() => {
                dispatch(setDisabledStartConfButtonAction(false));
                dispatch(saveOwnParticipantIdAction(ownParticipantId));
                dispatch(saveJanusTokenAction(token));
                dispatch(setCurrentSessionIdAction(sessionId));
            });
        } else {
            return response.data as IRequestError;
        }
    },
);
