import { setConferenceExitConfirmationDialogVisibilityAction } from 'actions/applicationView/setConferenceExitConfirmationDialogVisibilityAction';
import { setParticipantsListOpenAction } from 'actions/applicationView/setParticipantsListOpenAction';
import { toggleConferenceChatAction } from 'actions/applicationView/toggleConferenceChatAction';
import { setJanusStreamPropsAction } from 'actions/janus/setJanusStreamPropsAction';
import { ComponentWithDevices } from 'components/HOCs/ComponentWithDevices';
import { connect } from 'react-redux';
import { setUse3dAudioThunkAction } from 'thunk/auth/setUse3dAudioThunkAction';
import { TAppDispatch } from 'types/app/TAppDispatch';
import { TAppState } from 'types/app/TAppStore';
import { ConferenceFooter } from './ConferenceFooter';
import { IDispatchProps, IStateProps } from './types';
import { showDeviceNotificationThunkAction } from 'thunk/conference/showDeviceNotificationThunkAction';
import {
    getAllowedStreams,
    getForbiddenStreams,
    getForbiddenStreamsByConferenceSettings,
} from 'utils/conferences/getDataAboutStreams';

const mapStateToProps = (state: TAppState): IStateProps => ({
    ...state.janus.localStreamProps,
    allowedStreams: getAllowedStreams(state),
    audioStreamChangeInProgress: state.janus.audioStreamChangeInProgress,
    canPublish: state.janus.canPublish,
    chatState: state.applicationView.showChat,
    currentUserIsOwner: state.conferenceSession.currentUserIsOwner,
    forbiddenStreams: getForbiddenStreams(state),
    forbiddenStreamsByConferenceSettings: getForbiddenStreamsByConferenceSettings(state),
    participantsCount: state.conferenceSession.currentConferenceSortedParticipantsIds.length,
    participantsListOpen: state.applicationView.participantListOpen,
    priorityFrameEnable: !!state.applicationView.fullScreenFrames.length,
    use3dAudio: state.auth.appSettings?.use3dAudio,
    use3dAudioFeature: state.features.features.use3dAudio,
    videoRoomConnected: state.janus.videoRoomConnected,
    videoStreamChangeInProgress: state.janus.videoStreamChangeInProgress,
});

const mapDispatchToProps = (dispatch: TAppDispatch): IDispatchProps => ({
    setStreamProps: (streamProps) => dispatch(setJanusStreamPropsAction(streamProps)),
    toggleChat: (chatState) => dispatch(toggleConferenceChatAction(chatState)),
    showExitConfirmation: () => dispatch(setConferenceExitConfirmationDialogVisibilityAction(true)),
    setParticipantsListOpen: (participantsListState) =>
        dispatch(setParticipantsListOpenAction(participantsListState)),
    setUse3dAudio: (use3dAudio) => dispatch(setUse3dAudioThunkAction({ use3dAudio })),
    showDeviceNotification: (notification) =>
        dispatch(showDeviceNotificationThunkAction(notification)),
});

export const ConferenceFooterConnectedWithDevices = ComponentWithDevices(
    connect(mapStateToProps, mapDispatchToProps)(ConferenceFooter),
);
