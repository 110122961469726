import { t } from '@lingui/macro';
import { Trans } from '@lingui/react';
import { Button } from 'components/common/Button';
import { runMediaStreamStartCallbacks } from 'utils/media/mediaStreamStartCallbacks';
import styles from './MediaPermissionDialogMobile.module.scss';
import { IDispatchProps } from './types';

export const MediaPermissionDialogMobile: React.FC<IDispatchProps> = ({
    closeMediaPermissionDialog,
}) => (
    <div className={styles.root}>
        <div>
            <Trans id='media.permission.request' />
        </div>
        <Button
            styleType='lobbyDenyMobile'
            clickHandler={closeMediaPermissionDialog}
            caption={t({ id: 'lobby.mobile.deny', message: 'Отклон.' })}
        />
        <Button
            styleType='lobbyAllowMobile'
            clickHandler={runMediaStreamStartCallbacks}
            caption={t({ id: 'lobby.mobile.allow', message: 'Разреш.' })}
        />
    </div>
);
