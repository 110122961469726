import { INetworkService, IRequestResult } from 'types/api/INetworkService';
import { IConferenceRecordsFromServer } from 'types/server/IConferenceRecordsFromServer';

interface IPayload {
    conferenceId: string;
    sessionId: string;
}

export const getConferenceRecordsByIdApiCall = (
    networkService: INetworkService,
    { conferenceId, sessionId }: IPayload,
): Promise<IRequestResult<IConferenceRecordsFromServer>> =>
    networkService.get({ url: `/api/conferences/${conferenceId}/sessions/${sessionId}/records` });
