import { Trans } from '@lingui/react';
import { AccountInformationFormConnected } from './AccountInformationForm';
import styles from './AccountInformationPage.module.scss';

export const AccountInformationPage: React.FC = () => (
    <>
        <div className={styles.header}>
            <span>
                <Trans message='Профиль' id='Profile' />
            </span>
        </div>
        <AccountInformationFormConnected />
    </>
);
