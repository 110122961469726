import { IStreamProps } from 'types/IStreamProps';

export const extractVideoStreamProps = (videoStream: MediaStream): Pick<IStreamProps, 'video'> => {
    const videoTrack = videoStream.getVideoTracks()[0];
    const videoSettings = videoTrack?.getSettings();
    const bitrate = `${videoSettings?.frameRate || ''}`;
    const codec = 'vp8'; // FIXME
    const width = videoSettings?.width;
    const height = videoSettings?.height;
    const frameRate =
        videoSettings && videoSettings.frameRate && videoSettings.frameRate !== Infinity
            ? `${Math.floor(videoSettings.frameRate)}`
            : '';
    return {
        video: {
            frameRate,
            bitrate,
            codec,
            width,
            height,
        },
    };
};
