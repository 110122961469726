import { INetworkService, IRequestResult } from 'types/api/INetworkService';
import { IConferenceRecordsFromServer } from 'types/server/IConferenceRecordsFromServer';

interface IPayload {
    from?: string;
    to?: string;
}

export const getConferencesRecordsApiCall = (
    networkService: INetworkService,
    { from, to }: IPayload = {},
): Promise<IRequestResult<IConferenceRecordsFromServer[]>> => {
    const paramsToUrl =
        from || to
            ? '?' + `${from ? 'from=' + from + '&' : ''}` + `${to ? 'to=' + to + '&' : ''}`
            : '';
    return networkService.get({
        url: `/api/conferences/my-records${paramsToUrl.slice(0, paramsToUrl.length - 1)}`,
    });
};
