import { connect } from 'react-redux';
import { TAppState } from 'types/app/TAppStore';
import { redirect } from 'utils/redirect';
import { AccountPageMobileFooter } from './AccountPageMobileFooter';
import { IDispatchProps, IStateProps } from './types';

const mapStateToProps = (state: TAppState): IStateProps => ({
    conferenceListRouteToPast: state.sessionSettings.conferencesFilter.finished,
});

const mapDispatchToProps = (): IDispatchProps => ({
    goToRoute: (path) => redirect(path),
});

export const AccountPageMobileFooterConnected = connect(
    mapStateToProps,
    mapDispatchToProps,
)(AccountPageMobileFooter);
