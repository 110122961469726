import { IUpcomingConferenceInfo } from 'types/conferencesList/IUpcomingConferenceInfo';
import { IUserLdapData } from 'types/user/IUserLdapData';

export const getUnknownEmailsFromConference = (
    conferences: IUpcomingConferenceInfo[],
    ldapUsers: { [email: string]: IUserLdapData },
) => {
    const emails: Set<string> = new Set();
    for (const conference of conferences) {
        if (conference.invitedUsers) {
            for (const email of conference.invitedUsers) {
                if (!ldapUsers[email]) emails.add(email);
            }
        }
    }
    return Array.from(emails);
};
