import { IOwnProps, IDispatchProps } from './types';
import { FullScreenPopup } from 'components/common/FullScreenPopup';
import styles from './SelectSharingScreen.module.scss';
import { PopupDialog } from 'components/common/PopupDialog';
import { useEffect, useState } from 'react';
import { IDesktopWindow } from 'types/desktop/IDesktopWindow';
import { IDesktopSharingFrame } from 'types/desktop/IDesktopSharingFrame';
import { Screen } from './Screen';
import { Button } from 'components/common/Button';
import { Trans } from '@lingui/react';
import { classNames } from 'utils/classNames';
import { t } from '@lingui/macro';
import { log } from 'utils/log';
import { CircleLoader } from 'components/common/CircleLoader';

export const SelectSharingScreen: React.FC<IOwnProps & IDispatchProps> = ({
    isMobile,
    changeScreenShareStatus,
    closeDialog,
    showNotification,
}) => {
    const [availableFrames, setAvailableFrames] = useState<IDesktopSharingFrame[]>([]);
    const [selectedScreenId, setSelectedScreenId] = useState('');

    const desktopWindow = window as typeof window & IDesktopWindow;

    const getAvailableFrames = async () => {
        const getScreens = desktopWindow?.meevox?.getScreens;
        if (!getScreens) {
            log.debug('getScreen is undefined');
            return;
        }
        const targetFrames = await getScreens();
        if (!targetFrames || !targetFrames.length) {
            log.debug('No available sources');
            showNotification(
                t({
                    id: 'selectSharingScreenDialog.noAvailableSources',
                    message: 'Нет доступных источников',
                }),
            );
            return;
        }
        setAvailableFrames(targetFrames);
    };

    const changeSharingStatusAndSetFrameId = () => {
        if (!selectedScreenId || !desktopWindow?.meevox) {
            log.debug('Share screen change error');
            return;
        }
        changeScreenShareStatus(selectedScreenId);
        closeDialog();
    };

    const renderBodyContent = () => {
        if (!availableFrames.length) {
            return (
                <div className={styles.container__bodyLoader}>
                    <CircleLoader color='blue' />
                </div>
            );
        }
        return availableFrames.map(({ name, image, id }) => (
            <Screen
                key={id}
                label={name}
                preview={image}
                isActive={id === selectedScreenId}
                handleClick={() => setSelectedScreenId(id)}
            />
        ));
    };

    useEffect(() => {
        getAvailableFrames();
    }, []);

    return (
        <FullScreenPopup closePopup={closeDialog}>
            <PopupDialog
                onClose={closeDialog}
                dialogClassName={styles.dialog}
                titleClassName={styles.dialog__header}
                title={t({
                    id: 'selectSharingScreenDialog.title',
                    message: 'Выберите окно или приложение, которым вы хотите поделиться',
                })}>
                <div className={styles.container}>
                    <div
                        className={classNames([
                            styles.container__body,
                            isMobile ? styles.container__body_mobile : '',
                            !availableFrames.length ? styles.container__body_fixed : '',
                        ])}>
                        {renderBodyContent()}
                    </div>
                    {availableFrames.length ? (
                        <div className={styles.container__footer}>
                            <Button
                                styleType='common'
                                className={styles.container__submit}
                                clickHandler={changeSharingStatusAndSetFrameId}>
                                <Trans id='share' />
                            </Button>
                        </div>
                    ) : null}
                </div>
            </PopupDialog>
        </FullScreenPopup>
    );
};
