import { addMinutes, format, intervalToDuration } from 'date-fns';
import { Dispatch, MutableRefObject, SetStateAction } from 'react';
import { getDateInfoFromStartedConference } from 'utils/conferences/getDateInfoFromStartedConference';
import { convertStringToDateByTemplate } from 'utils/dates/convertStringToDateByTemplate';
import { getDateBasedOnTimezone } from 'utils/dates/getDateBasedOnTimezone';
import { getBrowserTimeZone } from 'utils/timeZones/getBrowserTimeZone';
import { getUserTimeZone } from 'utils/timeZones/getUserTimeZone';
import { ITime, TDynamicTimerPayload } from './types';

type TTimerRef = MutableRefObject<NodeJS.Timeout | null>;

const getIntervalData = (conferenceTimeStart: string, isConferenceStarted: boolean): ITime => {
    const { startDate, currentDate } = getDateInfoFromStartedConference(
        conferenceTimeStart,
        getBrowserTimeZone(),
        getUserTimeZone(),
    );
    const {
        hours = 0,
        minutes = 0,
        seconds = 0,
    } = intervalToDuration({
        start: !isConferenceStarted ? currentDate : startDate,
        end: !isConferenceStarted ? startDate : currentDate,
    });
    return { hours, minutes, seconds };
};

const updateUpcomingConferenceTime = ({
    conferenceTimeStart,
    isConferenceStarted,
    setTime,
}: TDynamicTimerPayload) => {
    setTime(getIntervalData(conferenceTimeStart, isConferenceStarted));
}

const twoMinutes = 120000;
export const startUpcomingConferenceTimer = (
    payload: TDynamicTimerPayload | undefined,
    timerRef: TTimerRef,
) => {
    if (!payload) {
        return;
    }
    const delay = payload.isConferenceStarted ? 1000 : twoMinutes;
    const userTimeZone = getUserTimeZone();
    if (payload.conferenceTimeStart && userTimeZone) {
        updateUpcomingConferenceTime(payload);
        if (!timerRef.current) {
            timerRef.current = setInterval(() => {
                updateUpcomingConferenceTime(payload);
            }, delay);
        }
    }
};

export const resetUpcomingConferenceTimer = (
    payload: TDynamicTimerPayload | undefined,
    timerRef: TTimerRef,
) => {
    if (payload && timerRef.current && payload.conferenceTimeStart) {
        clearInterval(timerRef.current);
        timerRef.current = null;
        startUpcomingConferenceTimer(payload, timerRef);
    }
};

export const getTimeRange = (timeStart: string, duration: number) => {
    const dateStart = getDateBasedOnTimezone(
        convertStringToDateByTemplate(timeStart, 'yyyy-MM-dd HH:mm:ss.SSSSSS'),
        getUserTimeZone(),
    );
    const dateEnd = duration ? format(addMinutes(dateStart, duration), 'HH:mm') : '';
    return format(dateStart, 'HH:mm') + ' - ' + dateEnd;
};
