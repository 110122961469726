import { plural, t } from '@lingui/macro';
import { CircleLoader } from 'components/common/CircleLoader';
import { DateFilterMobile } from 'components/common/DateFilter/DateFilterMobile';
import { format } from 'date-fns';
import { useEffect } from 'react';
import { convertStringToDateByTemplate } from 'utils/dates/convertStringToDateByTemplate';
import { getCurrentDate } from 'utils/dates/getCurrentDate';
import { getDateBasedOnTimezone } from 'utils/dates/getDateBasedOnTimezone';
import { ConferenceRecordInfoMobile } from '../AccountConferencesPage/ConferenceRecordInfo/ConferenceRecordInfoMobile';
import styles from './ConferencesRecordsPageMobile.module.scss';
import { IDispatchProps, IStateProps } from './types';
import { useRenderRecordPlayer } from './useShowRecord';
import { getGroupedRecords, getGroupedRecordsByConference } from './utils';

export const ConferencesRecordsPageMobile: React.FC<IDispatchProps & IStateProps> = ({
    recordsList,
    currentUser,
    defaultOperationPending,
    changeFilter,
    filters,
    reset,
}) => {
    useEffect(() => {
        changeFilter(filters);
        // Fix for cashing
        return reset;
    }, []);
    const [recordToShow, getRecordClickHandler] = useRenderRecordPlayer(styles);
    const filterSet: boolean = filters.from !== undefined || filters.to !== undefined;
    const groupedRecords = getGroupedRecordsByConference(recordsList);

    const recordsRender = groupedRecords.map((item) => {
        const resultDay =
            item.day === format(getCurrentDate(), 'dd.MM.yyyy')
                ? t({ message: 'Сегодня', id: 'Today' })
                : item.day;
        return (
            <div className={styles.group} key={resultDay}>
                <div className={styles.group__date}>{resultDay}</div>
                {Object.keys(item.records).map((conference) => {
                    const currentRecords = item.records[conference];
                    const confName = currentRecords[0].name;
                    const recordsCount = currentRecords.length;
                    const dateStart = getDateBasedOnTimezone(
                        convertStringToDateByTemplate(
                            currentRecords[0].timeStart as string,
                            'yyyy-MM-dd HH:mm:ss.SSSSSS',
                        ),
                        currentUser?.timeZone as string,
                    );
                    const timeRange = format(dateStart, 'HH:mm');
                    const recordsCountPlural = plural(recordsCount, {
                        one: 'запись',
                        few: 'записи',
                        many: 'записей',
                        other: '',
                    });
                    return (
                        <div id={conference} key={conference}>
                            <div className={styles.conference}>
                                <div className={styles.conference__info}>
                                    <div className={styles.header}>
                                        <div>{confName}</div>
                                        <div>{timeRange}</div>
                                    </div>
                                    <div
                                        className={
                                            styles.footer
                                        }>{`${recordsCount} ${recordsCountPlural}`}</div>
                                </div>
                            </div>
                            <div className={styles.records}>
                                {currentRecords.map((record) => (
                                    <div onClick={getRecordClickHandler(record)} key={record.id}>
                                        <ConferenceRecordInfoMobile
                                            record={record}
                                            downloadRecord={() => {}}
                                        />
                                    </div>
                                ))}
                            </div>
                        </div>
                    );
                })}
            </div>
        );
    });

    const renderContent = () => {
        if (!defaultOperationPending) {
            return recordsRender;
        }
        return <CircleLoader color='blue' />;
    };

    return (
        <div className={styles.wrapper}>
            <DateFilterMobile
                filters={filters}
                filtersSet={filterSet}
                handleChangeFilter={changeFilter}
                type='records'
            />
            {renderContent()}
            {recordToShow()}
        </div>
    );
};
