import { t } from '@lingui/macro';
import { IConferenceParticipant } from 'types/IConferenceParticipant';
import { IItemType } from './types';

export const getItems = (participants: IConferenceParticipant[]): IItemType[] => {
    const result: IItemType[] = [
        {
            displayValue: t({ id: 'mobile.selectLayout.all', message: 'Все' }),
            value: {
                layoutType: 'default',
                participantId: '',
            },
        },
        {
            displayValue: t({ id: 'mobile.selectLayout.talking', message: 'Авто' }),
            value: {
                layoutType: 'talking',
            },
        },
    ];
    participants.forEach((participant) =>
        result.push({
            displayValue: participant.name,
            value: {
                layoutType: 'default',
                participantId: participant.participantId,
            },
        }),
    );
    return result;
};
