import { Field } from 'react-final-form';
import styles from './Checkbox.module.scss';
import { IOwnProps } from './types';

export const Checkbox: React.FC<IOwnProps> = ({ text, name, className = '', disabled }) => (
    <Field name={name} type='checkbox'>
        {({ input }) => {
            const style = disabled
                ? `${styles.checkbox} ${styles.checkbox_disabled}`
                : styles.checkbox;

            return (
                <div className={`${style} ${className}`}>
                    <input id={name} type='checkbox' {...input} disabled={disabled} />
                    <label htmlFor={name}>{text}</label>
                </div>
            );
        }}
    </Field>
);
