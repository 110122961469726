import { FC, ButtonHTMLAttributes } from 'react';
import styles from './Button.module.scss';
import { classNames } from 'utils/classNames';

export const Button: FC<ButtonHTMLAttributes<HTMLButtonElement> & { unset?: boolean }> = ({
    children,
    unset,
    className,
    ...props
}) => {
    return (
        <button className={classNames([!unset ? styles.button : '', className])} {...props}>
            {children}
        </button>
    );
};
