import { createAsyncThunk } from '@reduxjs/toolkit';
import { setParticipantsFullScreenAction } from 'actions/applicationView/setParticipantsFullScreenAction';
import { setConferenceVideoSettingsApiCall } from 'api/conference/setConferenceVideoSettingsApiCall';
import { getNetworkService } from 'api/getNetworkService';
import { EApplicationViewThunkActions } from 'constants/thunk/EApplicationViewThunkActions';
import { TAppState } from 'types/app/TAppStore';

interface IPayload {
    global: boolean;
    participantIds: string[];
}

export const setFramesToFullscreenThunkAction = createAsyncThunk(
    EApplicationViewThunkActions.SET_FRAME_TO_FULLSCREEN,
    async ({ global, participantIds }: IPayload, { dispatch, getState }) => {
        const {
            conferenceSession: { currentConferenceId },
        } = getState() as TAppState;
        if (!currentConferenceId) {
            return;
        }
        if (global) {
            await setConferenceVideoSettingsApiCall(getNetworkService(), {
                conferenceId: currentConferenceId,
                videoSettings: {
                    masterFrames: participantIds,
                },
            });
        }
        dispatch(
            setParticipantsFullScreenAction({ [global ? 'global' : 'local']: participantIds }),
        );
    },
);
