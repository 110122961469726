import React from 'react';
import styles from './MobileNotification.module.scss';
import { MobileNotificationAction } from './MobileNotificationAction';
import { IOwnProps } from './types';

export const MobileNotification: React.FC<IOwnProps> = ({
    notification: { text, timeout, type, closeAction, title, mobileThemeOptions },
}) => {
    const [isFadingOut, setIsFadingOut] = React.useState(false);
    const currentComponentRef = React.useRef(null);

    if (timeout) {
        setTimeout(() => {
            if (currentComponentRef.current) {
                setIsFadingOut(true);
            }
            if (closeAction) {
                setTimeout(closeAction, 200);
            }
        }, timeout - 200);
    }
    const notificationTypeStyle = styles[`mobileNotification_${type}`] || '';
    const notificationInteractiveStyle =
        mobileThemeOptions && mobileThemeOptions.theme === 'primaryAction'
            ? styles.mobileNotification_interactive
            : '';
    const styleFadingOut = isFadingOut
        ? `${notificationTypeStyle} ${styles.itemFadeOut}`
        : notificationTypeStyle;

    const onNotificationClick = () => {
        if (mobileThemeOptions && mobileThemeOptions.theme === 'primaryAction'){
            mobileThemeOptions.action()
        }
    }
    
    return (
        <div
            className={[
                styles.mobileNotification,
                notificationInteractiveStyle,
                styleFadingOut,
            ].join(' ')}
            onClick={onNotificationClick}
            ref={currentComponentRef}>
            <div className={styles.mobileNotification__main}>
                {title && <div className={styles.title}>{title}</div>}
                {text && <div className={styles.text}>{text}</div>}
            </div>
            {mobileThemeOptions && <MobileNotificationAction options={mobileThemeOptions} />}
        </div>
    );
};
