// import { IOwnProps } from './types';
import { Trans } from '@lingui/react';
import { InviteFormConnected } from './InviteForm';
import styles from './NewUserModalInvite.module.scss';

export const NewUserModalInvite: React.FC = () => {
    return (
        <div className={styles.modalInvite}>
            <h3 className={styles.modalInvite__title}>
                <Trans id='admin.invite' message='Приглашение' />
            </h3>
            <span className={styles.modalInvite__description}>
                <Trans
                    id='admin.invite.text'
                    message='Отправьте приглашение на регистрацию по почте. Чтобы добавить получателей, введите email через запятую.'
                />
            </span>
            <span className={styles.modalInvite__formTitle}>
                <Trans id='whom' message='Кому' />
            </span>
            <InviteFormConnected />
        </div>
    );
};
