import { FC, useEffect, useState } from 'react';
import { Trans } from '@lingui/react';
import { ConferenceUser } from 'pages/AccountPage/AccountConferencesPage/ConferencesList/ConferenceUsers/ConferenceUser';
import { Button } from 'components/common/Button';
import { Tabs } from 'components/common/Tabs';
import Plus from 'assets/Plus.svg'
import { classNames } from 'utils/classNames';
import { t } from '@lingui/macro';
import { getFilterTabs, tabItems } from './utils'
import { ETabsValue, IOwnProps } from './types';
import { IConferenceParticipant } from 'types/IConferenceParticipant';
import { IConferenceCreator } from 'types/IConferenceCreator';
import { IConferenceUser } from 'types/IConferenceUser';
import styles from './AccountConferenceDetailsPageParticipantsList.module.scss';
import { ConferenceShareDialog } from 'components/ConferenceShareDialog';

type ICommonConferenceUser = IConferenceCreator | IConferenceUser | IConferenceParticipant

export const AccountConferenceDetailsPageParticipantsList: FC<IOwnProps> = ({
    conferenceInfo,
    invitedUsers,
    currentUserIsCreator,
    participants,
    isPastConference,
}) => {
    const tabs = getFilterTabs(tabItems, conferenceInfo.status, isPastConference);
    const [openPopup, setOpenPopup] = useState<boolean>(false);
    const [activeTab, setActiveTab] = useState<ETabsValue>(tabs[0].value);
    const [users, setUsers] = useState<(ICommonConferenceUser)[]>([]);
    const [inputValue, setInputValue] = useState<string>('');

    const getCurrentUsers = ():(ICommonConferenceUser)[] => {
        if (activeTab === ETabsValue.ALL_PARTICIPANTS) {
            return [conferenceInfo.creator, ...invitedUsers]
        }
        return participants
    }

    useEffect(() => {
        setUsers(getCurrentUsers());
        setInputValue('')
    }, [activeTab])

    useEffect(() => {
        filterUsers(inputValue ?? '')
    }, [inputValue])

    const filterUsers = (searchValue: string) => {
        const searchValueLowerCase = searchValue.toLowerCase().trim();
        if (searchValue.length === 0) {
            setUsers(getCurrentUsers())
            return;
        }
        setUsers(getCurrentUsers().filter(participant => {
            if (participant.email?.toLowerCase()?.includes(searchValueLowerCase)) {
                return true;
            }
            if ('name' in participant) {
                return participant.name.toLowerCase().includes(searchValueLowerCase)
            }
            return (participant.firstName + ' ' + participant.lastName).toLowerCase().includes(searchValueLowerCase)
        }))
    }

    const getUserListItem = (user: ICommonConferenceUser, index: number): JSX.Element => {
        const isCreator = user.userId === conferenceInfo.creator.userId;
        const isParticipant = 'name' in user;
        const userForAvatar = { id: user.userId ?? '', firstName: '', lastName: '', avatar: ''};
        let key=`${index}`;
        if (isParticipant) {
            const userName = user.name.split(' ')
            userForAvatar.firstName = userName[0];
            userForAvatar.lastName = userName[1];
            userForAvatar.avatar = user.etag ?? '';
            key=user.participantId;
        } else {
            userForAvatar.firstName = user.firstName;
            userForAvatar.lastName = user.lastName;
            key=user.userId;
        }
        return (
            <li key={key}>
                <div
                    className={classNames([styles.listItem, isCreator && styles.creator])}>
                    <ConferenceUser
                        userData={userForAvatar}
                        isDesktop
                        email={user.email}
                        avatarSize={32}
                        userNumber={index}
                    />
                    <span>{isParticipant ? user.name : `${user.firstName} ${user.lastName}`}</span>
                </div>
                {isCreator && <div><Trans id='detailsPage.creator'/></div>}
            </li>
        )
    }

    return (
        <div className={styles.participants}>
            <div className={styles.participants__title}><Trans id='Participants'/></div>
            <div className={styles.participantsFlex}>
                <div>
                    <input
                        type='text'
                        placeholder={t({id: 'detailsPage.searchInput', message: 'Введите имя или email участника'})}
                        onChange={(e) => setInputValue(e.target.value)}
                        value={inputValue}
                    />
                </div>
                {currentUserIsCreator && !isPastConference && (
                    <Button
                        styleType='secondaryActionNew'
                        type='button'
                        clickHandler={() => setOpenPopup(true)}
                    >
                        <img src={Plus}/>
                        <Trans id='detailsPage.inviteUsers'/>
                    </Button>
                )}
            </div>
            <Tabs
                propsValue={activeTab}
                className={styles.tabs}
                items={tabs.map(({value, label}) => {
                    const isAllParticipants = value === ETabsValue.ALL_PARTICIPANTS;
                    return {value, label: label( isAllParticipants ? invitedUsers.length + 1 :participants.length)}
                })}
                onChange={setActiveTab}
            />
            <ul>{users.map(getUserListItem)}</ul>
            {openPopup && currentUserIsCreator && (
                <ConferenceShareDialog
                    closeDialog={() => setOpenPopup(false)}
                    conferenceInfo={conferenceInfo}
                    isOwner={currentUserIsCreator}
                />
            )}
        </div>
    )
}
