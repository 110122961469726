import { t } from '@lingui/macro';
import { IOwnProps } from '../types';

export const getTooltipText = ({
    canPublish,
    deviceList,
    deviceOn,
    devicePermittedByAdmin,
    mediaEnabled,
    readyToStream,
}: Pick<
    IOwnProps,
    | 'canPublish'
    | 'deviceOn'
    | 'devicePermittedByAdmin'
    | 'mediaEnabled'
    | 'deviceList'
    | 'readyToStream'
>) => {
    if (!devicePermittedByAdmin) {
        return t({
            id: 'audio.device.disabled.by.admin',
            message: 'Микрофон не доступен в конференции',
        });
    }
    if (!mediaEnabled) {
        return t({
            id: 'audio.device.media.disabled',
            message: 'Микрофон запрещён в браузере',
        });
    }
    if (deviceList.length === 0) {
        return t({
            id: 'audio.device.media.no.devices',
            message: 'Нет доступных микрофонов',
        });
    }
    if (!readyToStream) {
        return t({
            id: 'device.media.no.connection',
            message: 'Ждите подключения к конференции',
        });
    }
    if (!canPublish) {
        return t({
            id: 'device.media.no.maxPublishers',
            message: 'Нет свободных слотов для включения вашей трансляции',
        });
    }
    return deviceOn
        ? t({
            id: 'audio.turnOff',
            message: 'Выключить микрофон',
        })
        : t({
            id: 'audio.turnOn',
            message: 'Включить микрофон',
        });
};
