import { MobileWrapperConnected } from 'components/common/MobileWrapper';
import { AccountConferencesPageConnected } from './AccountConferencesPageConnected';
import { AccountConferencesPageMobileConnected } from './AccountConferencesPageMobileConnected';
import { IOwnProps } from './types';

export const AccountConferencePageResponsive: React.FC<IOwnProps> = ({ containerRef }) => (
    <MobileWrapperConnected
        renderDesktop={() => <AccountConferencesPageConnected containerRef={containerRef} />}
        renderMobile={() => <AccountConferencesPageMobileConnected containerRef={containerRef} />}
    />
);
