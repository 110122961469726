export const isNewList = ({
    currentPermissions,
    permissionType,
    streamType,
    newParticipantsId,
}: {
    permissionType: 'allowedStreams' | 'forbiddenStreams';
    streamType: 'audio' | 'video';
    newParticipantsId: string[];
    currentPermissions: {
        allowedStreams: {
            audio: string[];
            video: string[];
        };
        forbiddenStreams: {
            audio: string[];
            video: string[];
        };
    };
}) => {
    const currentParticipantsId = currentPermissions[permissionType][streamType];
    return currentParticipantsId.length !== newParticipantsId.length
};