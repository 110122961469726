import { Cross } from '../Cross';
import styles from './PopupDialog.module.scss';
import { IOwnProps } from './types';

export const PopupDialog: React.FC<IOwnProps> = ({
    crossPosition = 'outside',
    title = '',
    isScrollContent,
    dialogClassName,
    titleClassName,
    contentClassName = '',
    theme = 'light',
    onClose,
    children,
}) => {
    const dialogBackground = styles[`dialog_${theme}`];
    const crossPositionClassName = styles[`cross_${crossPosition}`];
    const handleContainerClick = (e: any) => e.stopPropagation();
    const cNames = [styles.dialog];
    if (isScrollContent) {
        cNames.push(styles.dialogIsScroll);
    }
    if (dialogBackground) {
        cNames.push(dialogBackground);
    }
    if (dialogClassName) {
        cNames.push(dialogClassName);
    }
    return (
        <div className={cNames.join(' ')} onClick={handleContainerClick}>
            <div className={styles.dialog__header}>
                <span className={titleClassName || ''}>{title}</span>
                <Cross
                    color={theme === 'light' ? undefined : 'white'}
                    className={`${crossPositionClassName}`}
                    onClick={onClose}
                />
            </div>
            <div className={`${styles.dialogContent} ${contentClassName}`}>{children}</div>
        </div>
    );
};
