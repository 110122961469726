import { AnyAction, ThunkDispatch, createAsyncThunk } from '@reduxjs/toolkit';
import { addAdminUsersAction } from 'admin-app/actions/addAdminUsersAction';
import { setUsersSearchAction } from 'admin-app/actions/setUsersSearchAction';
import { storeAdminUsersAction } from 'admin-app/actions/storeAdminUsersAction';
import { getUsersApiCall } from 'admin-app/api/getUsersApiCall';
import { EPendingOperations } from 'admin-app/constants/EPendingOperations';
import { EUsersThunkActions } from 'admin-app/constants/thunk/EUsersThunkActions';
import { IAdminPanelUser } from 'admin-app/types/IAdminPanelUser';
import { IApiCallErrorData } from 'admin-app/types/IApiCallErrorData';
import { getNetworkService } from 'api/getNetworkService';
import { executePendingOperationThunkAction } from 'thunk/appView/executePendingOperationThunkAction';
import { TAppState } from 'types/app/TAppStore';

export type IPayload = Parameters<typeof getUsersApiCall>[1];

const getUsersThunkAction = createAsyncThunk<
    IApiCallErrorData | number,
    IPayload | undefined
>(EUsersThunkActions.GET_USERS, async (requestParams, { dispatch, getState }) => {
    const requestPayload: IPayload = {
        sort: { target: 'firstName' },
        ...requestParams,
    };

    const { success, data, errorCode, errorDescription } = await getUsersApiCall(
        getNetworkService(),
        requestPayload,
    );
    if (success) {
        processSearchParam(dispatch, getState, requestParams?.search);
        processPageParam(dispatch, requestParams?.page, data);
        return data.count;
    }

    return { errorCode, errorDescription };
});

const processSearchParam = (
    dispatch: ThunkDispatch<unknown, unknown, AnyAction>,
    getState: () => unknown,
    searchVal: string | undefined,
) => {
    const {
        admin: { usersSearch },
    } = getState() as TAppState;

    if (searchVal) {
        dispatch(setUsersSearchAction(searchVal));
    } else if (!searchVal && usersSearch) {
        dispatch(setUsersSearchAction(''));
    }
};

const processPageParam = (
    dispatch: ThunkDispatch<unknown, unknown, AnyAction>,
    pageVal: number | undefined,
    responseData: {
        count: number;
        array: IAdminPanelUser[];
    },
) => {
    if (pageVal) {
        dispatch(addAdminUsersAction(responseData.array));
    } else {
        dispatch(storeAdminUsersAction({ list: responseData.array, total: responseData.count }));
    }
};

export const getUsersPendingThunkAction = createAsyncThunk<
    IApiCallErrorData | number,
    IPayload | undefined
>(`${EUsersThunkActions.GET_USERS}/pending`, async (payload, { dispatch }) => {
    const result = await dispatch(
        executePendingOperationThunkAction({
            operation: EPendingOperations.GETTING_USERS_LIST,
            pendingFunction: () => dispatch(getUsersThunkAction(payload)),
        }),
    ).unwrap();

    return result;
});
