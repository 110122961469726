import { MobileWrapperConnected } from 'components/common/MobileWrapper';
import { AccountInformationPageConnected } from './AccountInformationPageConnected';
import { AccountInformationPageMobileConnected } from './AccountInformationPageMobileConnected';

export const AccountInformationPageResponsive: React.FC = () => (
    <MobileWrapperConnected
        renderDesktop={() => <AccountInformationPageConnected />}
        renderMobile={() => <AccountInformationPageMobileConnected />}
    />
);
