import { connect } from 'react-redux';
import { AccountSettingsPageMobile } from './AccountSettingsPageMobile';
import { TAppState } from 'types/app/TAppStore';
import { IStateMobileProps } from './types';
import { appSettingsMobileStructure } from 'constants/appSettingsStructure';
import { getSettingsWithout3DAudio } from './utils';

export const mapStateToProps = (state: TAppState): IStateMobileProps => ({
    settings: state.auth.appSettings,
    structure: state.features.features.use3dAudio
        ? appSettingsMobileStructure
        : getSettingsWithout3DAudio(),
});

export const AccountSettingsPageMobileConnected =
    connect(mapStateToProps)(AccountSettingsPageMobile);
