export const convertFromSecondsToHoursMinutesSeconds = (
    seconds: number,
): { hours: number; minutes: number; seconds: number } => {
    const hours: number = seconds / 60 / 60;
    const rhours = Math.floor(hours);
    const minutes = seconds / 60;
    const rminutes = Math.floor(minutes - rhours * 60);
    const rseconds = Math.floor(seconds % 60);
    return { hours: rhours, minutes: rminutes, seconds: rseconds };
};
