import { format } from 'date-fns';
import { connect } from 'react-redux';
import { changeConferencesListFilterThunkAction } from 'thunk/conferences/changeConferencesListFilterThunkAction';
import { TAppDispatch } from 'types/app/TAppDispatch';
import { TAppState } from 'types/app/TAppStore';
import { DateFilterType2 } from './DateFilterType2';
import { IDispatchProps, IStateProps } from './types';

const mapStateToProps = (state: TAppState): IStateProps => {
    const isPast = state.sessionSettings.conferencesFilter.finished;
    const filter = isPast
        ? state.sessionSettings.conferencesFilter.past
        : state.sessionSettings.conferencesFilter.upcoming;
    return {
        lang: state.auth.currentUser?.language,
        isPast,
        filter,
    };
};

const mapDispatchToProps = (dispatch: TAppDispatch): IDispatchProps => ({
    changeFilter: (dates, range, isPast) => {
        const from = format(dates.startDate, 'dd.MM.yyyy');
        const to = format(dates.endDate, 'dd.MM.yyyy');
        dispatch(
            changeConferencesListFilterThunkAction(
                isPast
                    ? {
                        past: {
                            to,
                            from,
                            range,
                        },
                    }
                    : {
                        upcoming:{
                            to,
                            from,
                            range,
                        },
                    },
            ),
        );
    },
});

export const DateFilterType2Connected = connect(
    mapStateToProps,
    mapDispatchToProps,
)(DateFilterType2);
