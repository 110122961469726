import { t } from '@lingui/macro';
import MicrophoneOnImg from 'assets/Microphone.svg';
import MicrophoneGrayImg from 'assets/MicrophoneGray.svg';
import MicrophoneOffImg from 'assets/MicrophoneOffAvatar.svg';
import React from 'react';
import { ConferenceDevice } from '../ConferenceDevice';
import { IOwnProps as IOwnAudioProps } from './types';
import { IDeviceTypeProps, IOwnProps  } from '../types';
import styles from './ConferenceAudioDevice.module.scss';
import { getTooltipText } from './utils';

export const ConferenceAudioDevice: React.FC<IOwnProps & IOwnAudioProps> = (props) => {
    const audioDeviceProps: IDeviceTypeProps = {
        imgOn: MicrophoneOnImg,
        imgOff: MicrophoneOffImg,
        imgDisabled: MicrophoneGrayImg,
        classNameImgOff: `${styles.audio} ${
            props.deviceList.length === 0 ? styles.audio_top10 : ''
        }`,
        menuHeaderText: t({ id: 'Microphone', message: 'Микрофон' }),
        text: t({ id: 'Microphone', message: 'Микрофон' }),
        tooltip: getTooltipText(props),
    };
    return <ConferenceDevice {...props} {...audioDeviceProps} />;
};
