import { connect } from 'react-redux';
import { getCurrentConferenceConnectInfoSelector } from 'selectors/getCurrentConferenceConnectInfoSelector';
import { updateConferenceInfoThunkAction } from 'thunk/conference/updateConferenceInfoThunkAction';
import { copyToClipboardThunkAction } from 'thunk/navigator/copyToClipboardThunkAction';
import { showNotifyThunkAction } from 'thunk/notify/showNotifyThunkAction';
import { TAppDispatch } from 'types/app/TAppDispatch';
import { TAppState } from 'types/app/TAppStore';
import { ConferenceInfoFrame } from './ConferenceInfoFrame';
import { IDispatchProps, IStateProps } from './types';

const mapStateToProps = (state: TAppState): IStateProps => ({
    conferenceConnectInfo: getCurrentConferenceConnectInfoSelector(state),
});

const mapDispatchToProps = (dispatch: TAppDispatch): IDispatchProps => ({
    updateConference: (data) => dispatch(updateConferenceInfoThunkAction(data)).unwrap(),
});

export const ConferenceInfoFrameConnected = connect(
    mapStateToProps,
    mapDispatchToProps,
)(ConferenceInfoFrame);
