import { t } from '@lingui/macro';
import { ETabsValue, ITabsItem } from './types'
import { TConferenceStatus } from 'types/TConferenceStatus';

export const tabItems: ITabsItem[] = [
    {
        value: ETabsValue.WERE_AT_CONFERENCE,
        label: (count) => t({ id: 'detailsPage.tabs.wereAtConference', values: { '0': count } }),
    },
    {
        value: ETabsValue.IS_AT_CONFERENCE,
        label: (count) => t({ id: 'detailsPage.tabs.isAtConference', values: { '0': count } }),
    },
    {
        value: ETabsValue.ALL_PARTICIPANTS,
        label: (count) => t({ id: 'detailsPage.tabs.allParticipants', values: { '0': count } }),
    },
]

export const getFilterTabs = (tabs: ITabsItem[], status: TConferenceStatus, isPastConference: boolean): ITabsItem[] => {
    if (isPastConference) {
        return tabs.filter(tab => tab.value === ETabsValue.ALL_PARTICIPANTS || tab.value === ETabsValue.WERE_AT_CONFERENCE)
    }
    if (status === 'STARTED') {
        return tabs.filter(tab => tab.value === ETabsValue.ALL_PARTICIPANTS || tab.value === ETabsValue.IS_AT_CONFERENCE)
    }
    return tabs.filter(tab => tab.value === ETabsValue.ALL_PARTICIPANTS)
}
