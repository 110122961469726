import { ESocketAppActions } from 'constants/ESocketAppActions';
import { INotification } from 'types/INotification';
import { TConferenceEvents } from 'types/socket/conferenceEvents/TConferenceEvents';

export const createConferenceEventsNotificationDynamicDataChecker = (
    actionType: TConferenceEvents,
    conferenceId: string,
    getCurrentConferenceId: () => string | undefined,
) => {
    if (actionType === ESocketAppActions.CONFERENCE_STARTED) {
        return (notification: INotification | null, callback: (data: INotification) => void) => {
            if (
                conferenceId === getCurrentConferenceId() &&
                notification?.mobileThemeOptions?.theme === 'buttonAction'
            ) {
                callback({ ...notification, ...{ mobileThemeOptions: undefined } });
            }
        };
    }
};
