import { connect } from 'react-redux';
import { TAppState } from 'types/app/TAppStore';
import { ConferenceRepeatInfo } from './ConferenceRepeatInfo';
import { IStateProps } from './types';

const mapStateToProps = (state: TAppState): IStateProps => ({
    lang: state.auth.currentUser?.language,
});

export const ConferenceRepeatInfoConnected = connect(mapStateToProps)(ConferenceRepeatInfo);
