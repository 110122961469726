import { t } from '@lingui/macro';
import { Trans } from '@lingui/react';
import { Toggle } from 'components/common/Toggle/Toggle';
import { useContext } from 'react';
import { createInviteLink } from 'utils/conferences/createInviteLink';
import { getDisplayName } from 'utils/users/getDisplayName';
import { ConferencePageTemplateContext } from '../utils';
import styles from './ConferenceInfoFrame.module.scss';
import { IDispatchProps, IStateProps } from './types';

export const ConferenceInfoFrame: React.FC<IStateProps & IDispatchProps> = ({
    conferenceConnectInfo,
    updateConference,
}) => {
    if (!conferenceConnectInfo) {
        return null;
    }
    const { id, name, pin, registeredOnly } = conferenceConnectInfo;
    const { isUpdating, setUpdatingStatus } = useContext(ConferencePageTemplateContext);

    const onClickCopyInviteLink = (e: React.MouseEvent<HTMLButtonElement>) => {};

    const handleChangeOnlyRegistered = async () => {
        setUpdatingStatus(true);
        await updateConference({ id, registeredOnly: !registeredOnly });
        setUpdatingStatus(false);
    };

    return (
        <div className={styles.conferenceInfoFrame}>
            <ul className={styles.conferenceInfoFrame__list}>
                <li className={styles.conferenceInfoFrame__item}>
                    <span>
                        <Trans message='Тема конференции:' id='conference.name' />
                    </span>
                    <div>{name}</div>
                </li>
                <li className={styles.conferenceInfoFrame__item}>
                    <span>
                        <Trans message='Организатор:' id='conference.owner' />
                    </span>
                    <div>{getDisplayName(conferenceConnectInfo.creator)}</div>
                </li>
                <li className={styles.conferenceInfoFrame__item}>
                    <span>
                        <Trans message='Код доступа:' id='conference.pin' />
                    </span>
                    <div>{pin || ''}</div>
                </li>
                <li className={styles.conferenceInfoFrame__item}>
                    <span>
                        <Trans message='Ссылка приглашения:' id='conference.inviteLink' />
                    </span>
                    <div>
                        <span className={styles.conferenceInfoFrame__linkText}>
                            {createInviteLink(id, pin)}
                        </span>
                        <button
                            className={styles.conferenceInfoFrame__link}
                            data-invite-link={createInviteLink(id, pin)}
                            onClick={onClickCopyInviteLink}>
                            <Trans message='Копировать ссылку' id='conference.inviteLink.copy' />
                        </button>
                    </div>
                </li>
            </ul>
            <div className={styles.conferenceInfoFrame__registeredOnly}>
                <Trans
                    id='Only for registered users'
                    message='Только для зарегистрированных пользователей'
                />
                <Toggle
                    value={registeredOnly}
                    disabled={isUpdating}
                    onChangeValue={handleChangeOnlyRegistered}
                />
            </div>
        </div>
    );
};
