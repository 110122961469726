import { useRef, useState } from 'react';
import styles from './DesktopNotification.module.scss';
import { IOwnProps } from './types';

export const DesktopNotification: React.FC<IOwnProps> = ({
    children,
    notification: { text, timeout, type, closeAction },
}) => {
    const [isFadingOut, setIsFadingOut] = useState(false);
    const currentComponentRef = useRef(null);

    if (timeout !== 0) {
        setTimeout(() => {
            if (currentComponentRef.current) {
                setIsFadingOut(true);
            }
            if (closeAction) {
                closeAction();
            }
        }, timeout - 200);
        // 400ms because animation to fade in 200ms + animation to fade out 200ms.
        // After full delay the component will be unmount
    }

    const style = styles[`style_${type}`];
    const styleFadingOut = isFadingOut ? `${style} ${styles.itemFadeOut}` : style;
    return (
        <div className={[styles.style, styleFadingOut].join(' ')} ref={currentComponentRef}>
            {children ? children : text}
        </div>
    );
};

