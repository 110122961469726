import { Trans } from '@lingui/react';
import { PageTemplate } from '../templates/PageTemplate';
import { RegistrationFormConnected } from './RegistrationForm';
import styles from './RegistrationPage.module.scss';

export const RegistrationPage: React.FC = () => (
    <PageTemplate showLoginBtn={true} showRegisterBtn={false}>
        <div className={styles.title}>
            <Trans id='Registration' message='Регистрация' />
        </div>
        <div className={styles.form}>
            <RegistrationFormConnected />
        </div>
    </PageTemplate>
);
