import { MobileWrapperConnected } from 'components/common/MobileWrapper';
import { ConferencePageConnected } from './ConferencePageConnected';
import { ConferencePageMobileConnected } from './ConferencePageMobileConnected';
import { IOwnProps } from './types';

export const ConferencePageResponsive: React.FC<IOwnProps> = ({ id }) => (
    <MobileWrapperConnected
        renderDesktop={() => <ConferencePageConnected id={id} />}
        renderMobile={() => <ConferencePageMobileConnected id={id} />}
    />
);
