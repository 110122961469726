import { createAsyncThunk } from '@reduxjs/toolkit';
import { TAppState } from 'types/app/TAppStore';


export const updateSubscriptionsVideoStreamsAction = createAsyncThunk(
    'updateSubscriptionsVideoStreamsAction',
    async (payload, { getState }) => {
        const {
            applicationView: {
                videoStreamsSubscriptionsUpdate: { subscribe, unsubscribe },
            },
            janus: { streams },
        } = getState() as TAppState;

        type StreamsSubscriptions = { feed: string, mid: string }[];
        const payloads: Record<string, { subscribe?: StreamsSubscriptions, unsubscribe?: StreamsSubscriptions }> = {};

        subscribe.forEach(id => {
            const video = streams[id]?.video;
            if (video?.stream) {
                if (!payloads[id]?.subscribe) {
                    payloads[id] = { subscribe: [] }
                }
                payloads[id].subscribe?.push({ feed: id, mid: video.mid })
            }
        });
        unsubscribe.forEach(id => {
            const video = streams[id]?.video;
            if (video?.stream) {
                if (!payloads[id]?.unsubscribe) {
                    payloads[id] = { unsubscribe: [] }
                }
                payloads[id].unsubscribe?.push({ feed: id, mid: video.mid})
            }
        });

        // Object.keys(payloads).forEach(id => {
        //     if (payloads[id]) {
        //         const {subscribe, unsubscribe} = payloads[id]
        //         janusCtx.videoRoomSubscriberPluginHandlers[id]?.send({
        //             message: { request: 'update', subscribe, unsubscribe},
        //         })
        //     }
        // })
    },
);
