import { createAsyncThunk } from '@reduxjs/toolkit';
import { getTenantDataApiCall } from 'admin-app/api/getTenantDataApiCall';
import { EUserThunkActions } from 'admin-app/constants/thunk/EUserThunkActions';
import { IApiCallErrorData } from 'admin-app/types/IApiCallErrorData';
import { getNetworkService } from 'api/getNetworkService';

export const getPublicKeyThunkAction = createAsyncThunk<IApiCallErrorData | string>(
    EUserThunkActions.GET_PUBLIC_KEY,
    async () => {
        const { success, data, errorCode, errorDescription } = await getTenantDataApiCall(
            getNetworkService(),
        );
        if (!success) {
            return { errorCode, errorDescription };
        }
        return data.publicKey || '';
    },
);
