import { createAsyncThunk } from '@reduxjs/toolkit';
import { addConferenceChatMessagesAction } from 'actions/chat/addConferenceChatMessagesAction';
import { saveChatMessageApiCall } from 'api/conference/saveChatMessageApiCall';
import { getNetworkService } from 'api/getNetworkService';
import { TAppState } from 'types/app/TAppStore';
import { IChatMessageToStore } from 'types/chat/ICharMessageToStore';
import { IChatMessageToSend } from 'types/chat/IChatMessageToSend';
import { IConferenceChatMessage } from 'types/chat/IConferenceChatMessage';
import { sendMessageToConferenceJanus } from 'utils/janus/sendMessageToConferenceJanus';

export const addMessageToConferenceChatThunkAction = createAsyncThunk(
    'addMessageToConferenceChat',
    async (chatMessage: IChatMessageToSend, { dispatch, getState }) => {
        const {
            conferenceSession: { currentConferenceId, currentSessionId },
        } = getState() as TAppState;
        if (!currentConferenceId) {
            return;
        }
        // else if (maxMessageLength && chatMessage.messageType === 'text') {
        //     const text = Array.isArray(chatMessage.value)
        //         ? JSON.stringify(chatMessage.value)
        //         : chatMessage.value;
        //     if (text.length > maxMessageLength) {
        //         dispatch(
        //             showNotifyThunkAction({
        //                 text: t({
        //                     id: 'Chat message over max length',
        //                     message: 'Сообщение больше допустимой длины {0}. Текущая длина {1}.',
        //                     values: {
        //                         '0': maxMessageLength,
        //                         '1': text.length,
        //                     },
        //                 }),
        //                 timeout: 3000,
        //                 type: 'error',
        //             }),
        //         );
        //         return;
        //     }
        // }
        const sendMessage: IChatMessageToStore = {
            text: chatMessage.text,
            contentType: chatMessage.contentType,
        };
        if (chatMessage.files?.length) {
            sendMessage.files = chatMessage.files;
        }
        const response = await saveChatMessageApiCall(getNetworkService(), {
            text: JSON.stringify(sendMessage),
            conferenceId: currentConferenceId,
            sessionId: currentSessionId,
        });
        if (!response?.success) {
            return false;
        }
        const conferenceChatMessage: IConferenceChatMessage = {
            ...chatMessage,
            time: response?.data,
        }
        dispatch(
            addConferenceChatMessagesAction({
                messages: [conferenceChatMessage],
            }),
        );
        sendMessageToConferenceJanus(conferenceChatMessage);
    },
);
