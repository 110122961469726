
interface IAddHandlerToOwnVideoTrackPayload {
    videoStream?: MediaStream;
    turnOffScreenSharing: () => void;
}

export const addHandlerToOwnVideoTrack = ({
    videoStream,
    turnOffScreenSharing,
}: IAddHandlerToOwnVideoTrackPayload) => {
    if (videoStream && turnOffScreenSharing) {
        const track = videoStream.getVideoTracks()[0];
        track?.addEventListener('ended', turnOffScreenSharing);
        return () => track && track.removeEventListener('ended', turnOffScreenSharing);
    }
};
