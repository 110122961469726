import { MobileWrapperConnected } from 'components/common/MobileWrapper';
import { PastConferences } from './PastConferences';
import { PastConferencesMobile } from './PastConferencesMobile';
import { IOwnProps } from './types';

export const PastConferencesResponsive: React.FC<IOwnProps> = (props) => (
    <MobileWrapperConnected
        renderDesktop={() => <PastConferences {...props} />}
        renderMobile={() => <PastConferencesMobile {...props} />}
    />
);
