import { plural, t } from '@lingui/macro';
import { useEffect, useRef, useState } from 'react';
import styles from './UpcomingConferenceTime.module.scss';
import { IOwnProps, ITime, TDynamicTimerPayload } from './types';
import {
    getTimeRange,
    resetUpcomingConferenceTimer,
    startUpcomingConferenceTimer,
} from './utils';

export const UpcomingConferenceTime: React.FC<IOwnProps> = ({
    isConferenceStarted,
    isTodayConferences,
    conferenceActualTimeStart,
    conferenceScheduleTimeStart,
    conferenceDuration,
}) => {
    const [beforeStartTime, setBeforeStartTime] = useState<ITime | null>(null);
    const [currentDuration, setCurrentDuration] = useState<ITime | null>(null);
    const timerRef = useRef<NodeJS.Timeout | null>(null);

    const getTimerPayload = (): TDynamicTimerPayload | undefined => {
        if (!isConferenceStarted) {
            return {
                conferenceTimeStart: conferenceScheduleTimeStart,
                isConferenceStarted,
                setTime: setBeforeStartTime,
            };
        }
        if (conferenceActualTimeStart) {
            return {
                conferenceTimeStart: conferenceActualTimeStart,
                isConferenceStarted,
                setTime: setCurrentDuration,
            };
        }
    };

    const renderTime = () => {
        if (isConferenceStarted) {
            if (!currentDuration) {
                return;
            }
            return (
                <span className={styles.conferenceTime__started}>
                    {getTimeStr(currentDuration)}
                </span>
            );
        }
        if (beforeStartTime && isTodayConferences) {
            let text: string;
            if (beforeStartTime.hours === 0 && beforeStartTime.minutes < 5) {
                text = t({
                    id: 'startSoon',
                    message: 'Скоро начнется',
                });
            } else {
                const first = t({
                    id: 'upcomingConf.beforeStartText.firstPart',
                    message: 'Через',
                });
                if (beforeStartTime.hours > 0) {
                    const time = t({
                        id: 'conference.join.error.notStarted.timerShort',
                        message: '{hours} ч {minutes} мин',
                        values: {
                            hours: beforeStartTime.hours,
                            minutes: beforeStartTime.minutes,
                        },
                    });
                    text = `${first} ${time}`;
                } else {
                    const min = plural(beforeStartTime.minutes, {
                        one: 'минуту',
                        few: 'минуты',
                        many: 'минут',
                        other: '',
                    });
                    text = `${first} ${beforeStartTime.minutes} ${min}`;
                }
            }
            return (
                <span className={styles.conferenceTime__beforeStart}>
                    {text}
                </span>
            );
        }
    };

    const isShowTimer = isTodayConferences || isConferenceStarted;

    useEffect(() => {
        isShowTimer &&
            resetUpcomingConferenceTimer(getTimerPayload(), timerRef);
    }, [conferenceScheduleTimeStart]);

    useEffect(() => {
        if (beforeStartTime?.hours === 0 && beforeStartTime.minutes < 5) {
            timerRef.current && clearInterval(timerRef.current);
            timerRef.current = null;
        }
    }, [beforeStartTime]);

    useEffect(() => {
        isShowTimer &&
            startUpcomingConferenceTimer(getTimerPayload(), timerRef);
        return () => {
            timerRef.current ? clearInterval(timerRef.current) : false;
        };
    }, []);

    return (
        <div
            className={`${styles.conferenceTime} ${
                isConferenceStarted ? styles.conferenceTime_blue : ''
            }`}
        >
            <span className={styles.conferenceTime__range}>
                {getTimeRange(conferenceScheduleTimeStart, conferenceDuration)}
            </span>
            {renderTime()}
        </div>
    );
};

const getTimeStr = (time: ITime) =>
    time.hours
        ? [time.hours, time.minutes, time.seconds].map(getTimePart).join(':')
        : [time.minutes, time.seconds].map(getTimePart).join(':');

const getTimePart = (num: number) => (num < 10 ? `0${num}` : num);
