import { t } from '@lingui/macro';
import { Cross } from 'components/common/Cross/Cross';
import React from 'react';
import styles from './ConferenceParticipantsList.module.scss';
import { ParticipantListItemConnected } from './ParticipantListItem/index';
import { IDispatchProps, IStateProps } from './types';

export const ConferenceParticipantsList: React.FC<IDispatchProps & IStateProps> = ({
    conferenceConnectInfo,
    currentConferenceParticipants,
    currentConferenceParticipantsIdsSorted,
    ownParticipantId,
    participantsListOpen,
    publishers,
    participantsCount,
    setParticipantsListOpen,
}) => (
    <aside className={styles.conferenceParticipantsList}>
        <div className={styles.conferenceParticipantsList__header}>
            <div>
                <span>{t({ message: 'Участники', id: 'Participants' })}</span>
                <span className={styles.conferenceParticipantsList__counter}>
                    {participantsCount}
                </span>
            </div>
            <Cross
                className={styles.cross}
                color={'gray'}
                onClick={() =>
                    setParticipantsListOpen ? setParticipantsListOpen(!participantsListOpen) : false
                }></Cross>
        </div>
        <ul className={styles.conferenceParticipantsList__body}>
            {conferenceConnectInfo &&
                currentConferenceParticipantsIdsSorted.map((participantId) => {
                    const participant = currentConferenceParticipants[participantId];
                    return (
                        <ParticipantListItemConnected
                            publisher={publishers[participant.participantId]}
                            isMobile={false}
                            itIsCurrentUserItem={participant.participantId === ownParticipantId}
                            key={participant.participantId}
                        />
                    );
                })}
        </ul>
    </aside>
);
