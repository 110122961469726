import { getPendingOperationStatusSelector } from 'selectors/getPendingOperationStatusSelector';
import { executePendingOperationThunkAction } from 'thunk/appView/executePendingOperationThunkAction';
import { getConferenceConnectInfoThunkAction } from 'thunk/conference/getConferenceConnectInfoThunkAction';
import { TAppDispatch } from 'types/app/TAppDispatch';
import { TAppState } from 'types/app/TAppStore';
import { replace } from 'utils/redirect';
import { IDispatchProps, IStateProps } from './types';

export const mapStateToProps = (state: TAppState): IStateProps => ({
    conferencesConnectInfo: state.conferences.conferencesConnectInfo,
    defaultOperationPending: getPendingOperationStatusSelector(state, 'defaultOperation'),
});

export const mapDispatchToProps = (dispatch: TAppDispatch): IDispatchProps => ({
    goToHome: () => replace('/'),
    getConferenceConnectInfo: async (id) =>
        dispatch(
            executePendingOperationThunkAction({
                operation: 'defaultOperation',
                pendingFunction: async () =>
                    await dispatch(getConferenceConnectInfoThunkAction({ id })),
            }),
        ),
});
