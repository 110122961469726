import { createAsyncThunk } from '@reduxjs/toolkit';
import { setCurrentConferencePinAction } from 'actions/conferenceSession/setCurrentConferencePinAction';
import { checkConferencePinApiCall } from 'api/conference/checkConferencePinApiCall';
import { getNetworkService } from 'api/getNetworkService';

interface IPayload {
    id: string;
    pin: string;
}

export interface ICheckPinConferenceThunkReturnedData {
    isSuccess: boolean;
}

export const checkPinConferenceThunkAction = createAsyncThunk(
    'checkPinConference',
    async ({ id, pin }: IPayload, { dispatch }): Promise<ICheckPinConferenceThunkReturnedData> => {
        const response = await checkConferencePinApiCall(getNetworkService(), {
            id,
            pin,
        });
        if (!response.success) {
            return { isSuccess: false };
        } else {
            dispatch(setCurrentConferencePinAction(pin));
            return { isSuccess: true };
        }
    },
);
