import { INetworkService } from 'types/api/INetworkService';

interface IPayload {
    role?: 'OWNER' | 'ANY';
    from?: string;
    to?: string;
    type: 'upcoming' | 'past';
}

export const getCurrentUserConferencesNewApiCall = (
    networkService: INetworkService,
    { type, role = 'OWNER', from, to }: IPayload,
) => {
    const queryParams = from || to ? (from ? `&from=${from}` : '') + (to ? `&to=${to}` : '') : '';
    return networkService.get({
        url: `/api/conferences/${type}/my?role=${role}${queryParams}`,
    });
};
