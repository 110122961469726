import React from 'react';
import { i18n } from '@lingui/core';
import { t } from '@lingui/macro';
import { Input } from 'components/common/Input';
import { MobileFormBlock } from 'components/common/MobileFormBlock';
import { ToggleField } from 'components/common/ToggleField';
import { Form, FormRenderProps } from 'react-final-form';
import { getDefaultErrorNotification } from 'utils/getDefaultErrorNotification';
import styles from './DynamicFormMobile.module.scss';
import { IDispatchProps, IFormRenderProps, IOwnProps } from './types';
import { SelectField } from 'components/common/SelectField';

export const DynamicFormMobile: React.FC<IOwnProps & IDispatchProps> = ({
    structure,
    saveSettings,
    initialValues,
    showNotification,
    onModified,
}) => {
    const inputStyleType = 'mobile';
    const likeInputStyle = styles.likeInput;
    const renderSections = () =>
        structure.map((section, index) => (
            <div className={styles.section} key={`el-${index}`}>
                {section.fields.map((field, idx) => {
                    const sectionTitle = idx === 0 ? section.sectionTitle : undefined;
                    if (field.type === 'text') {
                        const currentPlaceholder = field.placeholder
                            ? i18n._(field.placeholder)
                            : '';
                        return (
                            <React.Fragment key={field.name}>
                                {sectionTitle && (
                                    <div className={styles.sectionTitle}>
                                        {sectionTitle && i18n._(sectionTitle)}
                                    </div>
                                )}
                                <MobileFormBlock
                                    annotation={i18n._(field.title)}
                                    className={styles.inputBlock}>
                                    <Input
                                        name={field.name}
                                        placeholder={currentPlaceholder}
                                        styleType={inputStyleType}
                                        autoFocus={index === 0}
                                    />
                                </MobileFormBlock>
                            </React.Fragment>
                        );
                    }
                    if (field.type === 'checkbox') {
                        return (
                            <React.Fragment key={field.name}>
                                {sectionTitle && (
                                    <div className={styles.sectionTitle}>
                                        {i18n._(sectionTitle)}
                                    </div>
                                )}
                                <MobileFormBlock className={styles.checkboxBlock}>
                                    <div className={likeInputStyle}>
                                        <div>{i18n._(field.title)}</div>
                                        <ToggleField styleType='mobile' name={field.name} />
                                    </div>
                                </MobileFormBlock>
                            </React.Fragment>
                        );
                    }
                    const items = field.values?.map((value, idx) => {
                        let resultDisplayValue;
                        if (field.displayValues) {
                            const currentDisplayValue = field?.displayValues[idx];
                            resultDisplayValue =
                                typeof currentDisplayValue === 'object'
                                    ? i18n._(currentDisplayValue)
                                    : currentDisplayValue;
                        }
                        return { value, displayValue: resultDisplayValue };
                    });
                    return (
                        <React.Fragment key={field.name}>
                            {sectionTitle && (
                                <div className={styles.sectionTitle}>{i18n._(sectionTitle)}</div>
                            )}
                            <MobileFormBlock className={styles.inputBlock}>
                                <div className={styles.likeInput}>
                                    <div>{i18n._(field.title)}</div>
                                    <SelectField
                                        className={styles.likeInput__select}
                                        name={field.name}
                                        items={items}
                                    />
                                </div>
                            </MobileFormBlock>
                        </React.Fragment>
                    );
                })}
            </div>
        ));

    const onSubmitClick = async (values: any) => {
        const success = await saveSettings(values);
        if (success) {
            showNotification({
                text: t({
                    message: 'Изменения успешно сохранены',
                    id: 'The changes were saved successfully',
                }),
                type: 'success',
            });
        } else {
            showNotification(getDefaultErrorNotification());
        }
    };
    const render = ({
        handleSubmit,
        form,
        dirty,
        submitting,
        pristine,
    }: FormRenderProps<IFormRenderProps>) => {
        setTimeout(() => onModified(dirty), 0);
        const disableButton = pristine || submitting;
        return (
            <form onSubmit={handleSubmit} className={styles.form}>
                <div className={styles.buttons}>
                    <button
                        className={styles.cancel}
                        disabled={disableButton}
                        onClick={() => form.restart()}>
                        {t({ message: 'Отмена', id: 'Cancel' })}
                    </button>
                    <button type='submit' className={styles.submit} disabled={disableButton}>
                        {t({ message: 'Сохранить', id: 'save' })}
                    </button>
                </div>
                {renderSections()}
            </form>
        );
    };
    return (
        <Form onSubmit={onSubmitClick} render={render} initialValues={initialValues || undefined} />
    );
};
