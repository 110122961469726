import { connect } from 'react-redux';
import { getApplicationConfigThunkAction } from 'thunk/applicationConfiguration/getApplicationConfigThunkAction';
import { getCurrentUserProfileDataThunkAction } from 'thunk/auth/getCurrentUserProfileDataThunkAction';
import { TAppDispatch } from 'types/app/TAppDispatch';
import { ConfigErrorPage } from './ConfigErrorPage';
import { IDispatchProps } from './types';

const mapDispatchToProps = (dispatch: TAppDispatch): IDispatchProps => ({
    getAppConfigFromServer: async () => {
        const result = await dispatch(getApplicationConfigThunkAction());
        if (result.payload) {
            await dispatch(getCurrentUserProfileDataThunkAction());
        }
        return result;
    },
});

export const ConfigErrorPageConnected = connect(null, mapDispatchToProps)(ConfigErrorPage);
