import { Avatar } from 'components/common/Avatar';
import { EmotionContainer } from 'components/common/EmotionContainer/EmotionContainer';
import { HoverLabel } from 'components/common/HoverLabel';
import React, { useState } from 'react';
import { getEmotionImg } from 'utils/emotions/getEmotionImg';
import { getPublisherInitials } from 'utils/participants/getPublisherInitials';
import { getAvatarUrl } from 'utils/users/getAvatarUrl';
import { getInitials } from 'utils/users/getInitials';
import { getUserColor } from 'utils/users/getUserColor';
import { ParticipantAudioContent } from '../ParticipantsLayout/ParticipantAudioContent/ParticipantAudioContent';
import styles from './AudioFrames.module.scss';
import { IDispatchProps, IOwnProps, IStateProps, TPublisherHover } from './types';

const AVATAR_SIZE = 46;
const AVATAR_MARGIN = 5;

const getEmotion = (emotionName: string) => (
    <div className={styles.emotion}>
        <EmotionContainer style='top'>
            <img src={getEmotionImg(emotionName, 'Avatar')} alt={emotionName} />
        </EmotionContainer>
    </div>
);

export const AudioFrames: React.FC<IOwnProps & IStateProps & IDispatchProps> = ({
    anonymousUser,
    currentConferenceParticipants,
    currentParticipantId,
    currentUser,
    displayedAudioFrames,
    displayedVideoFrames,
    fullScreenFrames,
    hideOwnAvatar,
    isMobile,
    isParticipantsListOpen,
    ownEmotionToShow,
    // ownStream,
    ownTalkingStatus,
    participants,
    participantsAudio,
    participantsEmotion,
    participantsTalking,
    publishers,
    streams,
    toggleParticipantsListState,
}) => {
    const [publisherHover, setPublisherHover] = useState<TPublisherHover | null>(null);
    const [width, setWidth] = useState(0);
    const usersRef = React.useRef<HTMLDivElement>(null);

    const onResize = () => {
        if (usersRef.current && !isMobile) {
            setWidth((usersRef.current?.parentNode as HTMLDivElement)?.clientWidth);
        } else {
            setWidth(window.screen.availWidth);
        }
    };
    React.useEffect(() => {
        window.addEventListener('resize', onResize);
        return () => {
            window.removeEventListener('resize', onResize);
        };
    }, []);
    React.useEffect(() => {
        if (usersRef.current && !isMobile) {
            setWidth((usersRef.current?.parentNode as HTMLDivElement)?.clientWidth);
        } else {
            setWidth(window.screen.availWidth);
        }
    }, [usersRef]);

    const renderAudio = () =>
        participants.map((participantId) => {
            if (participantId === currentParticipantId) {
                return;
            }
            const publisher = publishers[participantId];
            if (!publisher) {
                return null;
            }
            const mediaStream = streams[publisher.participantId];
            return (
                <ParticipantAudioContent
                    key={participantId}
                    participantId={participantId}
                    muted={!participantsAudio[publisher.participantId]}
                    audioStream={mediaStream?.audio?.stream}
                />
            );
        });

    if ((displayedVideoFrames.length === 0 && fullScreenFrames.length === 0) || isMobile) {
        return <>{renderAudio()}</>;
    }

    let userAvatar = '';
    if (currentUser?.etag) {
        const { id, etag } = currentUser;
        userAvatar = getAvatarUrl({ id, etag, size: String(AVATAR_SIZE) });
    }

    const onAvatarMouseEnter = (participant: TPublisherHover) => {
        setPublisherHover(participant);
    };

    const onAvatarMouseLeave = () => {
        setPublisherHover(null);
    };

    const panelSize = width - 600;
    let visibleAvatarsCount = Math.max(
        Math.floor(panelSize / (AVATAR_SIZE + AVATAR_MARGIN)) - 2,
        2,
    );
    if (visibleAvatarsCount === displayedAudioFrames.length - 1) {
        visibleAvatarsCount = displayedAudioFrames.length;
    }
    const hiddenAudioFrames = displayedAudioFrames.slice();
    const visibleAudioFrames = hiddenAudioFrames.splice(0, visibleAvatarsCount);

    const renderTooltip = () => {
        let tooltipStyle = {};
        let tooltipText = '';

        if (publisherHover) {
            const foundIndex = visibleAudioFrames.indexOf(publisherHover.participantId);
            if (foundIndex !== -1) {
                let shift = visibleAudioFrames.length - foundIndex - 1;
                if (hiddenAudioFrames.length > 0) {
                    shift++;
                }
                tooltipStyle = {
                    right: `calc(
                        ${AVATAR_SIZE}px / 2
                            + (${AVATAR_SIZE}px + ${AVATAR_MARGIN * 2}px) * ${shift}
                    )`,
                };
                tooltipText = publisherHover.displayName;
            }
        }

        return (
            <div
                className={`${styles.tooltipWrapper} ${
                    tooltipText ? styles.tooltipWrapper_hover : ''
                }`}>
                <div className={styles.tooltipLabel} style={tooltipStyle}>
                    <HoverLabel type='username'>{tooltipText}</HoverLabel>
                </div>
            </div>
        );
    };

    return (
        <>
            {renderAudio()}
            <div className={styles.avatarListWrapper} ref={usersRef}>
                <div className={styles.avatarList}>
                    <div>
                        {visibleAudioFrames.map((participantId) => {
                            const publisher = publishers[participantId];
                            if (!publisher) {
                                return null;
                            }
                            if (participantId === currentParticipantId) {
                                if (hideOwnAvatar) {
                                    return null;
                                }
                                const currentPublisher: TPublisherHover = {
                                    participantId,
                                    displayName: publisher?.displayName || '',
                                };
                                return (
                                    <div key={participantId} className={styles.participant}>
                                        {/* <div className={styles.displayNone}>
                                            <ParticipantAudioContentManagedConnected
                                                participantId={participantId}
                                                muted
                                                audioStream={ownStream?.audio?.stream}
                                                displayedFrames={displayedAudioFrames}
                                            />
                                        </div> */}
                                        <Avatar
                                            initials={getInitials({
                                                user: currentUser,
                                                anonymousUser: anonymousUser,
                                            })}
                                            image={anonymousUser ? undefined : userAvatar}
                                            style={{
                                                width: AVATAR_SIZE + 'px',
                                                height: AVATAR_SIZE + 'px',
                                            }}
                                            userBgColor={getUserColor(currentPublisher.displayName)}
                                            active={ownTalkingStatus}
                                            mouseEnterHandler={() => {
                                                onAvatarMouseEnter(currentPublisher);
                                            }}
                                            mouseLeaveHandler={() => {
                                                onAvatarMouseLeave();
                                            }}
                                        />
                                        {ownEmotionToShow &&
                                            getEmotion(ownEmotionToShow.emotionName)}
                                    </div>
                                );
                            }

                            const participant =
                                currentConferenceParticipants[publisher.participantId];
                            let avatarUrl = undefined;
                            const emotion = participantsEmotion[publisher.participantId];
                            const talkingStatus = participantsTalking[publisher.participantId];
                            // const mediaStream = streams[publisher.participantId];
                            if (participant && participant?.userId !== undefined) {
                                const { userId: id, etag } = participant;
                                avatarUrl = getAvatarUrl({ id, etag, size: String(AVATAR_SIZE) });
                            }
                            return (
                                <div key={participantId} className={styles.participant}>
                                    {/* <div className={styles.displayNone}>
                                        <ParticipantAudioContentManagedConnected
                                            participantId={publisher.participantId}
                                            muted={!participantsAudio[publisher.participantId]}
                                            audioStream={mediaStream?.audio?.stream}
                                            displayedFrames={displayedAudioFrames}
                                        />
                                    </div> */}
                                    <Avatar
                                        initials={getPublisherInitials(publisher)}
                                        image={anonymousUser ? undefined : avatarUrl}
                                        style={{
                                            width: AVATAR_SIZE + 'px',
                                            height: AVATAR_SIZE + 'px',
                                        }}
                                        userBgColor={getUserColor(publisher.displayName)}
                                        active={talkingStatus}
                                        mouseEnterHandler={() => {
                                            onAvatarMouseEnter(publisher);
                                        }}
                                        mouseLeaveHandler={() => {
                                            onAvatarMouseLeave();
                                        }}
                                    />
                                    {emotion && getEmotion(emotion.emotionName)}
                                </div>
                            );
                        })}
                        {hiddenAudioFrames.length > 0 && (
                            <Avatar
                                initials={`${hiddenAudioFrames.length}`}
                                image={undefined}
                                clickHandler={() =>
                                    toggleParticipantsListState(isParticipantsListOpen)
                                }
                                style={{
                                    width: AVATAR_SIZE + 'px',
                                    height: AVATAR_SIZE + 'px',
                                    cursor: 'pointer',
                                }}
                                userBgColor='#2493FB'
                                userColor='#FFFFFF'
                            />
                        )}
                    </div>
                </div>
                {renderTooltip()}
            </div>
        </>
    );
};
