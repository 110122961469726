// import styles from './AccountConferenceDetailsPage.module.scss';
import { useEffect } from 'react';
import { redirect } from 'utils/redirect';
import { EPaths } from 'constants/EPaths';

export const AccountConferenceDetailsPageMobile: React.FC = () => {
    useEffect(() => {
        redirect(EPaths.UPCOMING_CONFERENCES);
    }, []);

    return <div></div>;
};
