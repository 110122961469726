import React from 'react';
import styles from './Arrow.module.scss';
import { IOwnProps } from './types';

export const Arrow: React.FC<IOwnProps> = (
    { className = '', handleClick, orientation = 'down', size = 'medium', color = 'gray',borderSize = 'bold' },
) => {
    const arrowClass = `${styles.arrow} ${className}`;
    const orientationClass = styles[`arrow_${orientation}`];
    const sizeClass = styles[`arrow_${size}`];
    const colorClass = styles[`arrow_${color}`];
    const borderClass = styles[`arrow_border-${borderSize}`];

    return (
        <div
            className={`${arrowClass} ${sizeClass} ${orientationClass} ${colorClass} ${borderClass}`}
            onClick={handleClick}
        />
    );
};