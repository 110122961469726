import { setConferenceInfoFrameVisibility } from 'actions/applicationView/setConferenceInfoFrameVisibility';
import { connect } from 'react-redux';
import { exitFromConferenceThunkAction } from 'thunk/conference/exitFromConferenceThunkAction';
import { loadDataForConferencePageMobileThunkAction } from 'thunk/conference/loadDataForConferencePageMobileThunkAction';
import { allowedJoinConferenceThunkAction } from 'thunk/lobby/allowedJoinConferenceThunkAction';
import { IAppSettingsTyped } from 'types/app/IAppSettings';
import { TAppDispatch } from 'types/app/TAppDispatch';
import { TAppState } from 'types/app/TAppStore';
import { ConferencePageMobile } from './ConferencePageMobile';
import { IDispatchPropsMobile, IStatePropsMobile } from './types';
import {
    getAllowedStreams,
    getForbiddenStreams,
    getForbiddenStreamsByConferenceSettings,
} from 'utils/conferences/getDataAboutStreams';
import { setJanusStreamPropsAction } from 'actions/janus/setJanusStreamPropsAction';

const getOwnAudioVideoPermissions = (state:TAppState) => {
    const {audioPermitted,videoPermitted} = state.janus.localStreamProps
    return {audioPermitted,videoPermitted}
}

const mapStateToProps = (state: TAppState): IStatePropsMobile => ({
    allowedStreams: getAllowedStreams(state),
    conferencesInfo: state.conferences.conferencesInfo,
    currentUserIsOwner: state.conferenceSession.currentUserIsOwner,
    forbiddenStreams: getForbiddenStreams(state),
    forbiddenStreamsByConferenceSettings: getForbiddenStreamsByConferenceSettings(state),
    isAudioNotification: (state.auth.appSettings as IAppSettingsTyped).audioNotification,
    isShowExitDialog: state.applicationView.showConferenceExitConfirmationDialog,
    lobbyQueueToEnter: state.lobby.waitingList,
    ownAudioVideoPermissions: getOwnAudioVideoPermissions(state),
    participantsListOpen: state.applicationView.participantListOpen,
    rememberMeRequestStatus: state.auth.rememberMeRequestStatus,
    showAudioPermissionDialog: state.applicationView.showAudioPermissionDialog,
    showChat: state.applicationView.showChat,
    showConferenceInfoFrame: state.applicationView.showConferenceInfoFrame,
});

const mapDispatchToProps = (dispatch: TAppDispatch): IDispatchPropsMobile => ({
    sendPermissionToJoinConference: (data) => dispatch(allowedJoinConferenceThunkAction(data)),
    exitFromConferenceWithoutRedirect: () =>
        dispatch(exitFromConferenceThunkAction({ redirect: false })),
    loadData: (id) =>
        dispatch(loadDataForConferencePageMobileThunkAction({ id, page: 'conference' })),
    hideConferenceInfoFrame: () => dispatch(setConferenceInfoFrameVisibility(false)),
    setStreamProps: (streamProps) => dispatch(setJanusStreamPropsAction(streamProps)),
});

export const ConferencePageMobileConnected = connect(
    mapStateToProps,
    mapDispatchToProps,
)(ConferencePageMobile);
