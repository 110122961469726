import React, { MutableRefObject } from 'react';

export const useRefState = <S>(
    initial: S,
): [state: S, stateRef: MutableRefObject<S>, setState: (data: S) => void] => {
    const [state, _setState] = React.useState<S>(initial);

    const stateRef = React.useRef<S>(initial);
    const setState = (data: S) => {
        stateRef.current = data;
        _setState(data);
    };

    return [state, stateRef, setState];
};
