import React from 'react';
import styles from './ParticipantsLayout.module.scss';
import { ParticipantsViewConnected } from './ParticipantsView';
import { IStateProps, IDispatchProps, IOwnProps } from './types';

export const ParticipantsLayout: React.FC< IOwnProps & IStateProps & IDispatchProps> = ({
    displayedVideoRatio,
    hasFullScreenFrames,
    setParticipantsLayoutProperties,
    participantFrameSize,
    displayedVideoFrames,
    hideOwnFrame,
    forRecorder,
}) => {
    const stageCanvasRef = React.useRef(null);
    
    const updateSize = () => {
        setParticipantsLayoutProperties({
            width: (stageCanvasRef?.current as any)?.offsetWidth,
            height: (stageCanvasRef?.current as any)?.offsetHeight,
            ratio: displayedVideoRatio,
        })
    };
    React.useEffect(() => {
        if (stageCanvasRef) {
            updateSize();
        }
    }, [ stageCanvasRef, hasFullScreenFrames, displayedVideoFrames ]);

    React.useEffect(() => {
        window.addEventListener('resize', updateSize);
        updateSize();
        return () => {
            window.removeEventListener('resize', updateSize);
        };
    }, []);

    return (
        <div className={styles.conferenceMembers} ref={stageCanvasRef}>
            <ParticipantsViewConnected
                participantFrameHeight={participantFrameSize.height}
                participantFrameWidth={participantFrameSize.width}
                hideOwnFrame={hideOwnFrame}
                forRecorder={forRecorder}
            />
        </div>
    );
};
