import { EPaths } from 'constants/EPaths';
import { connect } from 'react-redux';
import { logoutThunkAction } from 'thunk/auth/logoutThunkAction';
import { TAppDispatch } from 'types/app/TAppDispatch';
import { TAppState } from 'types/app/TAppStore';
import { redirect, replace } from 'utils/redirect';
import { HomePageMobile } from './HomePageMobile';
import { IMobileDispatchProps, IMobileStateProps } from './types';

const mapStateToProps = (state: TAppState): IMobileStateProps => ({
    logged: !!state.auth.currentUser,
    ldap: state.applicationConfiguration.config.ldap,
});

const mapDispatchToProps = (dispatch: TAppDispatch): IMobileDispatchProps => ({
    login: () => redirect('/login'),
    logout: () => dispatch(logoutThunkAction()),
    register: () => redirect('/register'),
    goToConferences: () => replace(EPaths.UPCOMING_CONFERENCES),
});

export const HomePageMobileConnected = connect(mapStateToProps, mapDispatchToProps)(HomePageMobile);
