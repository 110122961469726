import { t } from '@lingui/macro';
import { MobilePageTemplate } from 'components/MobilePageTemplate';
import React from 'react';
import { redirect } from 'utils/redirect';
import { RegistrationFormMobileConnected } from './RegistrationForm';
import styles from './RegistrationPage.module.scss';

export const RegistrationPageMobile: React.FC = () => (
    <MobilePageTemplate
        title={t({ id: 'registration.page.title', message: 'Регистрация' })}
        titleClassName={styles.title_mobile}
        onClickBack={() => redirect('/')}>
        <RegistrationFormMobileConnected />
    </MobilePageTemplate>
);
