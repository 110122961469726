import { Dispatch, SetStateAction, useEffect, useRef, useState } from 'react';
import { convertStringToDateByTemplate } from 'utils/dates/convertStringToDateByTemplate';
import { getCurrentDate } from 'utils/dates/getCurrentDate';
import { removeUserTimeZoneFromDate } from 'utils/dates/removeUserTimeZoneFromDate';

export const useConferenceTimeCheck = (conferenceTimeStart?: string) => {
    const [isConferenceCanBeStarted, setIsConferenceCanBeStarted] = useState(false);
    const timer = useRef<NodeJS.Timeout | null>(null);

    useEffect(() => {
        if (conferenceTimeStart) {
            updateCheckTimer({
                conferenceTimeStart,
                timerRef: timer,
                setIsConferenceCanBeStarted,
            });
        }
    }, [conferenceTimeStart]);

    useEffect(() => {
        if (conferenceTimeStart) {
            if (!inTimeRange(conferenceTimeStart)) {
                startCheckTimer({
                    conferenceTimeStart,
                    timerRef: timer,
                    setIsConferenceCanBeStarted,
                });
            } else {
                setIsConferenceCanBeStarted(true);
            }
        }

        return () => {
            deleteCheckTimer(timer);
        };
    }, []);

    if (!conferenceTimeStart) {
        return false;
    }

    return isConferenceCanBeStarted;
};

const inTimeRange = (conferenceTimeStart: string) => {
    const confTimeStartDate = convertStringToDateByTemplate(
        conferenceTimeStart,
        'yyyy-MM-dd HH:mm:ss.SSSSSS',
    );
    const userCurrentDateUTC0 = removeUserTimeZoneFromDate(getCurrentDate());
    const maxRange = 43200000; // 12 hours in milliseconds
    return confTimeStartDate.getTime() - userCurrentDateUTC0.getTime() > maxRange ? false : true;
};

interface IStartCheckTimerPayload {
    conferenceTimeStart: string;
    timerRef: React.MutableRefObject<NodeJS.Timeout | null>;
    setIsConferenceCanBeStarted: Dispatch<SetStateAction<boolean>>;
}

const startCheckTimer = ({
    conferenceTimeStart,
    timerRef,
    setIsConferenceCanBeStarted,
}: IStartCheckTimerPayload) => {
    if (timerRef.current) {
        return;
    }
    timerRef.current = setInterval(() => {
        if (inTimeRange(conferenceTimeStart)) {
            setIsConferenceCanBeStarted(true);
            deleteCheckTimer(timerRef);
        }
    }, 5000);
};

type TUpdateCheckTimerPayload = IStartCheckTimerPayload;

const updateCheckTimer = ({
    conferenceTimeStart,
    timerRef,
    setIsConferenceCanBeStarted,
}: TUpdateCheckTimerPayload) => {
    deleteCheckTimer(timerRef);
    if (!inTimeRange(conferenceTimeStart)) {
        setIsConferenceCanBeStarted(false);
        startCheckTimer({ conferenceTimeStart, timerRef, setIsConferenceCanBeStarted });
        return;
    }
    setIsConferenceCanBeStarted(true);
};

const deleteCheckTimer = (timerRef: React.MutableRefObject<NodeJS.Timeout | null>) => {
    timerRef.current && clearInterval(timerRef.current);
    timerRef.current = null;
};
