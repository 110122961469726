import { hoursToSeconds, intervalToDuration, minutesToSeconds } from 'date-fns';
import { IConferenceRecordsFromServer } from 'types/server/IConferenceRecordsFromServer';
import { IConferenceRecord } from 'types/IConferenceRecord';
import { IConferenceRecordServer } from 'types/IConferenceRecordServer';
import { convertStringToDateByTemplate } from 'utils/dates/convertStringToDateByTemplate';

export const recordServerToClient = ({
    isOwner,
    ...record
}: IConferenceRecordServer): IConferenceRecord => ({
    ...record,
    currentUserIsOwner: isOwner,
});

export const covertServerRecords = (
    data: IConferenceRecordsFromServer[],
): IConferenceRecord[] => {
    const resultRecordsList: IConferenceRecord[] = [];

    data.forEach((conferenceData) => {
        conferenceData.records.forEach((record) => {
            const { hours, minutes, seconds } = intervalToDuration({
                start: convertStringToDateByTemplate(record.timeEnd, 'yyyy-MM-dd HH:mm:ss.SSSSSS'),
                end: convertStringToDateByTemplate(record.timeStart, 'yyyy-MM-dd HH:mm:ss.SSSSSS'),
            });
            const durationInSeconds =
                hoursToSeconds(hours || 0) + minutesToSeconds(minutes || 0) + (seconds || 0);

            const formattedRecordData: IConferenceRecord = {
                id: record.id,
                sessionId: conferenceData.id,
                conferenceId: conferenceData.conferenceId,
                name:conferenceData.name,
                timeStart: record.timeStart,
                timeEnd: record.timeEnd,
                notifyAboutFinished: false,
                recordReady: !!record.ready,
                currentUserIsOwner:conferenceData.isOwner,
                record: {
                    size: record.size,
                    success: !!record.ready, //duplicate recordReady
                    duration:durationInSeconds,
                },
            };
            resultRecordsList.push(formattedRecordData);
        });
    });

    return resultRecordsList
};
