import { t } from '@lingui/macro';
import { EErrorCodes } from 'constants/EErrorCodes';
import { FORM_ERROR } from 'final-form';
import { IRequestError } from 'types/api/IRequestError';
import { IDispatchProps, IFormRenderProps } from './types';

export const handleSubmit = async (
    values: IFormRenderProps,
    { login }: Pick<IDispatchProps, 'login'>,
    successCallback?: () => void,
) => {
    const { payload: response } = await login({
        username: (values?.email || values?.ldap) as string,
        password: values.password,
        rememberMe: values.rememberMe || false,
    });
    if (response === undefined) {
        successCallback && successCallback();
        return;
    }
    if (response) {
        const error = response as IRequestError;

        if (error.errorCode === EErrorCodes.AUTHENTICATION_FAILED) {
            if (error.errorDescription.match(/user is disabled/i)) {
                return {
                    [FORM_ERROR]: t({
                        id: 'loginForm.submitErrors.confirmAccount',
                        message:
                            'Подтвердите свой адрес электронной почты с помощью электронного письма, отправленного на указанный адрес',
                    }),
                };
            }
            if (error.errorDescription.match(/bad credentials/i)) {
                return {
                    emailPassword: true,
                    [FORM_ERROR]: t({
                        id: 'Wrong login/password',
                        message: 'Неверный логин/пароль',
                    }),
                };
            }
        }
    }
};
