import { Trans } from '@lingui/react';
import { PageTemplate } from '../templates/PageTemplate';
import { RestorePasswordFormConnected } from './RestorePasswordForm';
import styles from './RestorePasswordPage.module.scss';

export const RestorePasswordPage: React.FC = () => (
    <PageTemplate showLoginBtn={true}>
        <div className={styles.title}>
            <Trans id='Restore password' message='Восстановить пароль' />
        </div>
        <div className={styles.form}>
            <RestorePasswordFormConnected />
        </div>
    </PageTemplate>
);
