import { connect } from 'react-redux';
import { TAppState } from 'types/app/TAppStore';
import { DatePickerMobile } from './DatePickerMobile';
import { IStateProps } from './types';

const mapStateToProps = (state: TAppState): IStateProps => ({
    lang: state.auth.currentUser?.language,
});

export const DatePickerMobileConnected = connect(mapStateToProps)(DatePickerMobile);
