import { addMinutes } from 'date-fns';
import { getTimeZonesArray } from 'utils/timeZones/getTimeZonesArray';

const timeZonesArray = getTimeZonesArray();
export const getDateBasedOnTimezone = (date: Date, timeZone: string) => {
    const foundTimeZone = timeZonesArray.filter((tz) => tz.value === timeZone)[0];
    if (!foundTimeZone) {
        return date;
    }
    return addMinutes(date, foundTimeZone.shiftInMinutes);
};
