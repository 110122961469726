import { IJanusLocalStreamProps } from 'types/janus/IJanusLocalStreamProps';

export const needToUnpublish = (
    propsChanges: Partial<IJanusLocalStreamProps>,
    currentProps: IJanusLocalStreamProps,
): boolean => {
    if (
        propsChanges.useAudio === undefined &&
        propsChanges.useVideo === undefined &&
        propsChanges.screenShared === undefined
    )
        return false;

    const useAudio =
        propsChanges.useAudio !== undefined ? propsChanges.useAudio : currentProps.useAudio;
    const useVideo =
        propsChanges.useVideo !== undefined ? propsChanges.useVideo : currentProps.useVideo;
    const useScreenSharing =
        propsChanges.screenShared !== undefined
            ? propsChanges.screenShared
            : currentProps.screenShared;
    return (useAudio || useVideo || useScreenSharing) === false;
};
