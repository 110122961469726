import { IUserData } from 'types/user/IUserData';
import { IUserDataFromServer } from 'types/server/IUserDataFromServer';

export const convertUserDataFromServer = ({
    email,
    firstName: first,
    id,
    lastName: last,
    mobilePhone: phone,
    avatar,
    locale,
    timeZone,
}: IUserDataFromServer): IUserData => ({
    contact: {
        email,
        phone,
    },
    id,
    name: {
        first,
        last,
    },
    etag: avatar?.replace('etag=', ''),
    language: locale,
    timeZone: timeZone === 'Etc/UTC' ? '' : timeZone,
});
