import { CSSProperties } from 'react';
import { IPastConferenceParticipant } from 'types/conferencesList/IPastConferenceParticipants';
import { IUserLdapData } from 'types/user/IUserLdapData';

const AVATARS_OVERLAP = 10;

export const calcMaxAvatarCount = (
    avatarsCount: number,
    width: number,
    avatarSize: number,
    maxPercentOfControl: number,
    isDesktop: boolean,
): number => {
    if (avatarsCount === 0) {
        return 0;
    }
    const overlap = isDesktop ? AVATARS_OVERLAP : 0;
    const avatarOccupiedSize = isDesktop ? avatarSize - AVATARS_OVERLAP : avatarSize;
    const avatarWidth = avatarOccupiedSize * avatarsCount + overlap;
    const maxWidthForAvatars = width * (maxPercentOfControl / 100);
    if (avatarWidth > maxWidthForAvatars) {
        return Math.floor((maxWidthForAvatars - overlap) / avatarOccupiedSize);
    }
    return avatarsCount;
};

type TGetUsersListPayload = {
    emails?: string[];
    ldapUsers?: { [email: string]: IUserLdapData };
    participants?: IPastConferenceParticipant[];
};

export const getUsersList = ({ emails, ldapUsers, participants }: TGetUsersListPayload) => {
    if (emails && ldapUsers) {
        return emails.map((email) => {
            const user = ldapUsers[email];
            if (user) {
                const { id, firstName, lastName, etag } = user;
                return { email, userData: { id, firstName, lastName, avatar: etag } };
            }

            return {
                email,
                userData: {
                    id: email,
                    firstName: '',
                    lastName: '',
                    avatar: '',
                },
            };
        });
    } else if (participants) {
        return participants.map((participant) => {
            const splittedName = participant.name.split(' ');
            return {
                email: '',
                userData: {
                    firstName: splittedName[0],
                    lastName: splittedName[1],
                    id: participant.userId || participant.participantId,
                    avatar: participant.avatar,
                },
            };
        });
    }
    return [];
};

export const getStyle = (avatarSize: number, isDesktop?: boolean): CSSProperties => ({
    width: `${avatarSize}px`,
    height: `${avatarSize}px`,
    // transform: isDesktop ? `translateX(${userNumber * -10}px)` : '0',
    marginLeft: !isDesktop ? '4px' : '',
    overflow: 'visible',
});
