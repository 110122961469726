import { t } from '@lingui/macro';
import { createAsyncThunk } from '@reduxjs/toolkit';
import { IConferenceConnectInfo } from 'types/conferences/IConferenceConnectInfo';
import { getConferenceConnectInfoThunkAction } from './getConferenceConnectInfoThunkAction';

export const getAndCheckConferenceForErrorsThunkAction = createAsyncThunk<
    Promise<{ error: string } | IConferenceConnectInfo>,
    string
>('getAndCheckConferenceForErrorsThunkAction', async (id, { dispatch }) => {
    const { payload: result } = await dispatch(getConferenceConnectInfoThunkAction({ id }));
    if (!result) {
        return { error: t({ id: 'conference.not.exist' }) };
    }
    const conference = result as IConferenceConnectInfo;
    // Now we can connect to the finished conference
    // if (conference.status === 'FINISHED') {
    //     return { error: t({ id: 'conference.join.error.end.id' }) };
    // }
    return conference;
});
