import { createAsyncThunk } from '@reduxjs/toolkit';
import { addConferenceChatMessagesAction } from 'actions/chat/addConferenceChatMessagesAction';
import { getChatMessagesApiCall } from 'api/conference/getChatMessagesApiCall';
import { getNetworkService } from 'api/getNetworkService';
import { EChatMessageStatus } from 'constants/EChatMessageStatus';
import { compareAsc, format } from 'date-fns';
import { IConferenceReport } from 'types/conferenceReport/IConferenceReport';
import { IChatHistoryMessage } from 'types/chat/IChatHistoryMessage';
import { IConferenceChatMessage } from 'types/chat/IConferenceChatMessage';
import { TAppState } from 'types/app/TAppStore';
import { addTAndZToISOFormat } from 'utils/dates/addTAndZToISOFormat';
import { convertDateToStringByTemplate } from 'utils/dates/convertDateToStringByTemplate';
import { convertStringToDateByTemplate } from 'utils/dates/convertStringToDateByTemplate';
import { getDateBasedOnTimezone } from 'utils/dates/getDateBasedOnTimezone';
import { getFormattedMessageDataForStore } from 'utils/getFormattedMessageDataForStore';
import { randomString } from 'utils/randomString';
import { getBrowserTimeZone } from 'utils/timeZones/getBrowserTimeZone';
import { EChatMessageType } from 'constants/EChatMessageType';
import { getDateBasedOnUTC0 } from 'utils/dates/getDateBasedOnUTC0';

export const getMessagesListForChatHistoryThunkAction = createAsyncThunk(
    'getMessagesListForChatHistory',
    async (
        {
            conferenceId,
            conferenceReport,
        }: { conferenceId: string; conferenceReport: IConferenceReport },
        { dispatch, getState },
    ) => {
        const status = EChatMessageStatus.DELIVERED;
        const unsortedMessagesArray: (Omit<IConferenceChatMessage, 'time'> & {
            dispatchTimeDate: Date;
        })[] = [];
        const {
            auth: { currentUser },
        } = getState() as TAppState;
        const timeZone = currentUser?.timeZone || getBrowserTimeZone();
        const getChatMessagesResponse = await getChatMessagesApiCall(getNetworkService(), {
            conferenceId,
            sessionId: conferenceReport.id,
        });
        if (getChatMessagesResponse.success) {
            getChatMessagesResponse?.data?.array?.forEach((message: IChatHistoryMessage) => {
                const formattedDispatchDate = convertStringToDateByTemplate(
                    message.sentTime,
                    'yyyy-MM-dd HH:mm:ss.SSS',
                );
                const messageData = getFormattedMessageDataForStore(message);
                unsortedMessagesArray.push({
                    ...messageData,
                    ...{ dispatchTimeDate: formattedDispatchDate },
                });
            });
        }
        conferenceReport.participants.forEach((participant) => {
            const ownUserId = participant.userId;
            participant.sessions.forEach((session) => {
                const confUserId = session.participantId;
                const template = {
                    text: participant.name,
                    confUserId,
                    ownUserId,
                    status,
                    time: '',
                };
                unsortedMessagesArray.push({
                    ...template,
                    dispatchTimeDate: getDateBasedOnTimezone(session.timeJoin, timeZone),
                    contentType: EChatMessageType.SYSTEM_JOIN,
                    id: randomString(7),
                });
                unsortedMessagesArray.push({
                    ...template,
                    dispatchTimeDate: getDateBasedOnTimezone(session.timeLeave, timeZone),
                    contentType: EChatMessageType.SYSTEM_LEAVE,
                    id: randomString(7),
                });
            });
        });

        const sortedMessagesArray = unsortedMessagesArray.sort((a, b) => {
            const firstDateWithTimeZone =
                a.contentType === EChatMessageType.SYSTEM_JOIN ||
                a.contentType === EChatMessageType.SYSTEM_LEAVE
                    ? getDateBasedOnUTC0(a.dispatchTimeDate, timeZone)
                    : a.dispatchTimeDate;
            const secondDateWithTimeZone =
                b.contentType === EChatMessageType.SYSTEM_JOIN ||
                b.contentType === EChatMessageType.SYSTEM_LEAVE
                    ? getDateBasedOnUTC0(b.dispatchTimeDate, timeZone)
                    : b.dispatchTimeDate;
        
            return compareAsc(firstDateWithTimeZone, secondDateWithTimeZone);
        });
        const resultMessagesArray = sortedMessagesArray.map((message) => {
            const time =
                message.contentType === EChatMessageType.SYSTEM_JOIN ||
                message.contentType === EChatMessageType.SYSTEM_LEAVE
                    ? format(message.dispatchTimeDate, 'HH:mm')
                    : addTAndZToISOFormat(
                        convertDateToStringByTemplate(
                            message.dispatchTimeDate,
                            'yyyy-MM-dd HH:mm:ss',
                        ),
                    );
            return {
                id: message.id,
                contentType: message.contentType,
                text: message.text,
                files: message.files,
                time,
                status: message.status,
                confUserId: message.confUserId,
                ownUserId: message.ownUserId,
            };
        });
        dispatch(addConferenceChatMessagesAction({ messages: resultMessagesArray, history: true }));
    },
);
