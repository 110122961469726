import { Trans } from '@lingui/react';
import { LoginFormMobileConnected } from 'components/forms/LoginForm';
import { Link, useLocation } from 'react-router-dom';
import { IConferenceConnectInfo } from 'types/conferences/IConferenceConnectInfo';
import styles from './LoginForJoinFormMobile.module.scss';
import { IDispatchProps, IOwnProps, IStateProps } from './types';

export const LoginForJoinFormMobile: React.FC<IDispatchProps & IStateProps & IOwnProps> = ({
    id,
    ldap,
    currentPin,
    showNotificationError,
    updateConferenceShortInfo,
    setCurrentConferenceData,
    goToConnectPage,
    goToPinInputPage,
}) => {
    const { state } = useLocation();
    const pathState = state as { [key: string]: string };

    const successCallback = async () => {
        if (pathState?.from) {
            const result = await updateConferenceShortInfo(id);
            if (result) {
                // Update conference short info. Maybe we are creator or invited user and have already had pin
                const conferenceConnectInfo = result as IConferenceConnectInfo;
                setCurrentConferenceData({
                    conferenceId: id,
                    creator: conferenceConnectInfo.creator,
                    pin: conferenceConnectInfo.pin,
                });
                if (conferenceConnectInfo.pin) {
                    goToConnectPage(id);
                } else if (currentPin) {
                    goToConnectPage(id);
                } else {
                    goToPinInputPage(id);
                }
            } else {
                showNotificationError();
            }
        }
    };

    return (
        <div className={styles.wrapper}>
            <span className={styles.formDescription}>
                <Trans
                    id='loginForJoin.description'
                    message='Для подключения к этой конференции нужно войти в учетную запись MeeVox'
                />
            </span>
            {!ldap && (
                <Link to='/register' className={styles.registrationLink}>
                    <Trans id='loginForJoin.registration.link' message='Регистрация' />
                </Link>
            )}
            <LoginFormMobileConnected successCallback={successCallback} />
        </div>
    );
};
