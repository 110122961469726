import { conferenceSessionReducer } from 'reducers/conferenceSessionReducer';
import { setJanusStreamPropsAction } from 'actions/janus/setJanusStreamPropsAction';
import { connect } from 'react-redux';
import { setFramesToFullscreenThunkAction } from 'thunk/appView/setFramesToFullscreenThunkAction';
import { changeParticipantMuteThunkAction } from 'thunk/conference/changeParticipantMuteThunkAction';
import { changeAudioVideoPermissionThunkAction } from 'thunk/conference/moderate/changeAudioVideoPermissionThunkAction';
import { kickFromConferenceThunkAction } from 'thunk/conference/moderate/kickFromConferenceThunkAction';
import { TAppDispatch } from 'types/app/TAppDispatch';
import { TAppState } from 'types/app/TAppStore';
import { ParticipantContextMenuDropdown } from './ParticipantContextMenuDropdown';
import { IDispatchProps, IOwnProps, IStateProps } from './types';

const mapStateToProps = (state: TAppState, { participantId }: IOwnProps): IStateProps => {
    const {
        applicationView: {
            fullScreenFrames,
            participantsFullScreen,
            participantsFullScreenGlobal,
            participantsLocalMuteStatus,
        },
        conferenceSession: { currentConferenceParticipants, currentConferenceCreator },
        auth: { currentUser },
        janus: { ownParticipantId, localStreamProps, publishers },
    } = state;
    const currentUserIsOwner = currentConferenceCreator?.userId === currentUser?.id;
    const isCurrentUser = ownParticipantId === participantId;
    const isUserIsOwner =
        currentConferenceParticipants[participantId].userId === currentConferenceCreator?.userId;
    const audioVideoProps = isCurrentUser
        ? localStreamProps
        : publishers[participantId].audioVideoProps;

    return {
        audioVideoProps,
        participantLocalMuteStatus: participantsLocalMuteStatus[participantId],
        currentUserIsOwner,
        isCurrentUser,
        isUserIsOwner,
        isUserWithPriorityFrame: fullScreenFrames.some((id) => id === participantId),
        participantsFullScreen,
        participantsFullScreenGlobal,
    };
};

const mapDispatchToProps = (
    dispatch: TAppDispatch,
    { participantId }: IOwnProps,
): IDispatchProps => ({
    changeAudioMute: () =>
        dispatch(changeParticipantMuteThunkAction({ participantId, type: 'audio' })),
    changeVideoMute: () =>
        dispatch(changeParticipantMuteThunkAction({ participantId, type: 'video' })),
    changeAudioPermission: () =>
        dispatch(changeAudioVideoPermissionThunkAction({ participantId, type: 'audio' })),
    changeVideoPermission: () =>
        dispatch(changeAudioVideoPermissionThunkAction({ participantId, type: 'video' })),
    kickParticipant: () => dispatch(kickFromConferenceThunkAction(participantId)),
    setLocalStreamProps: (args) => dispatch(setJanusStreamPropsAction(args)),
    setPriorityFrames: (args) => dispatch(setFramesToFullscreenThunkAction(args)),
});

export const ParticipantContextMenuDropdownConnected = connect(
    mapStateToProps,
    mapDispatchToProps,
)(ParticipantContextMenuDropdown);
