import { IOwnProps } from './types';
import styles from './DynamicFormCheckbox.module.scss';
import { ToggleField } from 'components/common/ToggleField';

export const DynamicFormCheckbox: React.FC<IOwnProps> = ({ name, title, description }) => (
    <div className={styles.field}>
        <div className={styles.field__text}>
            <label className={styles.field__title}>{title}</label>
            {description && <span className={styles.field__description}>{description}</span>}
        </div>
        <div data-form-control>
            <ToggleField name={name} styleType={'mobile'} />
        </div>
    </div>
);
