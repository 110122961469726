import { INetworkService } from 'types/api/INetworkService';

interface IPayload {
    conferenceId: string;
    participantId: string;
}

export const kickFromConferenceApiCall = (
    networkService: INetworkService,
    { conferenceId, participantId }: IPayload,
) =>
    networkService.put({
        url: `/api/conferences/${conferenceId}/participants/${participantId}/kick`,
    });
