import { Trans } from '@lingui/react';
import InfoIcon from 'assets/Info.svg';
import React, { useEffect, useRef, useState } from 'react';
import { IUserLdapData } from 'types/user/IUserLdapData';
import { getUserColor } from 'utils/users/getUserColor';
import { getUserInitials } from 'utils/users/getUserInitials';
import { getAvatarUrl } from 'utils/users/getAvatarUrl';
import { Avatar } from '../Avatar';
import { FloatUsersListConnected } from '../FloatUsersList';
import styles from './EmailListEditor.module.scss';
import { IDispatchProps, IInvalidEmail, IOwnProps, IStateProps } from './types';
import {fakeFieldPasteHandler, getListEmailsWithoutDuplicates} from './utils';
import { isEmailValid } from 'utils/isEmailValid';

export const EmailListEditor: React.FC<IOwnProps & IStateProps & IDispatchProps> = ({
    inputValues,
    ldap,
    ldUsersData,
    containerClassName,
    tip,
    showNotification,
    setFormField,
    setLdUsersData,
    resetLdUsersData,
    getInvitedUsersData,
    copyToClipboard,
}) => {
    const [fakeFieldValue, setFakeFieldValue] = useState('');
    const [invalidEmail, setInvalidEmail] = useState<IInvalidEmail>({
        isInvalid: false,
        value: '',
    });
    const [messageAboutPasted, setMessageAboutPasted] = useState<string>('');
    const [containerInFocus, setContainerInFocus] = useState(false);
    const [searchTimeOut, setSearchTimeOut] = useState<any>(null);
    const [showUsers, setShowUsers] = useState(false);

    const fakeInputRef = useRef<HTMLInputElement>(null);

    const separatesRegex = /^[,|\s]/gi;
    const separatesAtEndRegex = /^.*[,|\s]{1,}$/;
    const symbolOrDigitRegex = /[a-zA-z]$|[0-9]$/gi;

    const fakeFieldChangeHandler = (event: React.ChangeEvent<HTMLInputElement>) => {
        setFakeFieldValue(event.target.value);
    };

    const fakeFieldValidation = () => {
        const targetVal = fakeFieldValue;
        const clearTargetValue = targetVal.trim().replace(/[,|\s]/g, '');
        const symbolOrDigitCondition = targetVal.match(symbolOrDigitRegex);
        const emptyValueWithSeparateAtStartCondition =
            targetVal.match(separatesRegex) && !fakeFieldValue;
        let validEmailWithSeparateAtEndCondition = false;
        const isContainSeparate = targetVal.match(separatesAtEndRegex);
        if (isContainSeparate) {
            validEmailWithSeparateAtEndCondition = isEmailValid(clearTargetValue);
        }

        if (fakeFieldValue && invalidEmail.value !== fakeFieldValue) {
            invalidEmail.isInvalid
                ? setInvalidEmail({ isInvalid: false, value: fakeFieldValue })
                : false;
        }
        searchTimeOut ? clearTimeout(searchTimeOut) : false;
        showUsers ? setShowUsers(false) : false;

        if (!targetVal) {
            invalidEmail.isInvalid ? setInvalidEmail({ isInvalid: true, value: '' }) : false;
            return;
        }

        if (emptyValueWithSeparateAtStartCondition) {
            setFakeFieldValue('');
            return;
        }

        if (symbolOrDigitCondition) {
            setSearchTimeOut(setTimeout(() => setShowUsers(true), 500));
        }

        if (isContainSeparate) {
            if (!validEmailWithSeparateAtEndCondition) {
                setInvalidEmail({ isInvalid: true, value: clearTargetValue });
                setFakeFieldValue(clearTargetValue);
                return;
            }
        }
        if (validEmailWithSeparateAtEndCondition) {
            setFormField(getListEmailsWithoutDuplicates([...inputValues, ...[clearTargetValue]]));
            setFakeFieldValue('');
            return;
        }
    };

    const dellClickHandler = (event: React.MouseEvent<HTMLButtonElement>) => {
        const targetId = event.currentTarget.id;
        const newInputValues = inputValues.filter((elem, idx) => {
            return Number(targetId) !== idx;
        });
        setFormField(newInputValues);
    };

    const fakeFieldKeyDownHandler = (event: React.KeyboardEvent<HTMLInputElement>) => {
        if (event.code === 'Backspace' && !fakeFieldValue) {
            if (inputValues.length === 0) {
                return;
            }
            event.preventDefault();
            setFakeFieldValue(inputValues[inputValues.length - 1]);
            setFormField([...inputValues.slice(0, inputValues.length - 1)]);
            return;
        }
        if (event.code === 'Enter') {
            event.stopPropagation();
            setFakeFieldValue((prevVal) => `${prevVal},`);
            return;
        }
    };

    const setFakeInputWidth = () => {
        const fakeFieldValueLength = fakeFieldValue?.length || 0;
        const style = fakeInputRef?.current?.style;
        if (!style) {
            return;
        }
        style.width = fakeFieldValueLength === 0 ? '1em' : `${fakeFieldValueLength}em`;
    };

    const setInputValue = (email: string) => {
        setFormField([...inputValues, ...[email]]);
        setFakeFieldValue('');
        setShowUsers(false);
    };

    const setShowUsersState = (value: boolean) => {
        setShowUsers(value);
    };

    const outsideClickHandler = () => {
        containerInFocus ? setContainerInFocus(false) : false;
        setTimeout(() => {
            showUsers ? setShowUsers(false) : false;
        }, 300);
        const validEmailWithSeparateAtEndCondition = isEmailValid(fakeFieldValue);
        const clearTargetValue = fakeFieldValue.trim().replace(/[,|\s]/g, '');
        if (!clearTargetValue) {
            return;
        }

        if (!validEmailWithSeparateAtEndCondition) {
            setInvalidEmail({ isInvalid: true, value: clearTargetValue });
            return;
        }
        setFormField([...inputValues, ...[clearTargetValue]]);
        setFakeFieldValue('');
    };

    const ldapUserClickHandler = (userData: IUserLdapData) => {
        setFakeFieldValue('');
        const alreadyContain = ldUsersData.find((user) => user.email === userData.email);
        if (!alreadyContain) {
            setLdUsersData([...ldUsersData, ...[userData]]);
        }
        setFormField([...inputValues, ...[userData.email]]);
        setShowUsers(false);
        setInvalidEmail({ isInvalid: false, value: '' });
        fakeInputRef?.current?.focus();
    };

    const containerClickHandler = (event: React.MouseEvent<HTMLUListElement>) => {
        event.preventDefault();
        event.currentTarget.dataset.invitesContainer ? fakeInputRef?.current?.focus() : false;
    };

    useEffect(() => {
        fakeFieldValidation();
        setFakeInputWidth();
    }, [fakeFieldValue]);

    useEffect(() => {
        ldap && getInvitedUsersData(inputValues);
    }, [inputValues]);

    useEffect(() => {
        return () => {
            resetLdUsersData();
        };
    }, []);

    const renderMailTags = () =>
        inputValues.map((tag, index) => {
            let displayName = tag;
            let displayAvatar = undefined;
            let bgColor = 'transparent';
            let initials = '';
            let isLdapUser = false;
            const userInfo = ldUsersData.find((user) => user.email.toLowerCase() === tag.toLowerCase());
            if (ldap && userInfo) {
                isLdapUser = true;
                const { etag, id, firstName, lastName } = userInfo;
                displayName = `${firstName} ${lastName}`;
                displayAvatar = etag ? getAvatarUrl({ id, etag, size: '30' }) : '';
                bgColor = getUserColor(`${firstName}${lastName}`);
                initials = getUserInitials({
                    lastName,
                    firstName,
                    email: tag,
                });
            }

            return (
                <li className={styles.tag} key={tag}>
                    {ldap && isLdapUser && (
                        <Avatar
                            style={{
                                marginRight: '5px',
                                width: '23px',
                                height: '23px',
                                fontSize: '10px',
                            }}
                            initials={initials}
                            image={displayAvatar}
                            userBgColor={bgColor}
                        />
                    )}
                    <span>{displayName}</span>
                    <button
                        type='button'
                        onClick={dellClickHandler}
                        className={styles.cross}
                        id={`${index}`}></button>
                </li>
            );
        });

    const listComputedStyles = ` ${containerClassName || ''} ${
        containerInFocus ? styles.container_focus : ''
    }`;
    const fakeInputComputedStyles = `${invalidEmail.isInvalid ? styles.error : ''}`;
    return (
        <div className={styles.wrapper}>
            <div>
                <ul
                    className={`${styles.container} ${listComputedStyles}`}
                    onClick={containerClickHandler}
                    data-invites-container>
                    {inputValues && renderMailTags()}
                    <li>
                        <input
                            type='text'
                            onChange={fakeFieldChangeHandler}
                            onPaste={(event: React.ClipboardEvent) =>
                                fakeFieldPasteHandler({
                                    event,
                                    setFakeFieldValue,
                                    setFormField,
                                    setInvalidEmail,
                                    showNotification,
                                    inputValues,
                                    setMessageAboutPasted,
                                    copyToClipboard,
                                })
                            }
                            onFocus={() => setContainerInFocus(true)}
                            onBlur={outsideClickHandler}
                            onKeyDown={fakeFieldKeyDownHandler}
                            value={fakeFieldValue}
                            ref={fakeInputRef}
                            className={`${styles.fakeInput} ${fakeInputComputedStyles}`}
                        />
                    </li>
                    {!containerInFocus && !inputValues.length && !fakeFieldValue && (
                        <div className={styles.placeholder}>
                            <Trans
                                id='invitesPlaceholder'
                                message='Введите email или имя пользователя'
                            />
                        </div>
                    )}
                </ul>
                {showUsers && ldap && (
                    <FloatUsersListConnected
                        userClickHandler={ldapUserClickHandler}
                        targetInput={fakeInputRef?.current}
                        searchValue={fakeFieldValue}
                        setShowUsers={setShowUsersState}
                        setInputValue={setInputValue}
                        inputValues={inputValues}
                    />
                )}
            </div>
            {messageAboutPasted.length ? (
                <div className={`${styles.info__container} ${styles.error}`}>
                    {messageAboutPasted}
                </div>
            ) : null}
            {tip && (
                <div className={styles.info__container}>
                    <img src={InfoIcon} alt='info icon' />
                    <span className={styles.info__text}>{tip}</span>
                </div>
            )}
        </div>
    );
};
