export enum EConferencesActions {
    ADD_CONFERENCE_CHAT_MESSAGES = 'Conference/addConferenceChatMessages',
    ADD_CURRENT_USER_CONFERENCE_ID = 'Conferences/addCurrentUserConferenceId',
    DELETE_CONFERENCE_INFO = 'Conference/deleteConferenceInfo',
    RESET_CONFERENCES_STORE = 'Conferences/resetConferencesStore',
    RESET_CONFERENCE_CHAT_STORE = 'Conference/resetConferenceChat',
    SET_CONFERENCE_CHAT_AUTO_SCROLL = 'Conference/setConferenceChatAutoScroll',
    SET_CONFERENCE_CHAT_MESSAGE_STATUS = 'Conference/setConferenceChatMessageStatus',
    STORE_CONFERENCES_DATA = 'Conferences/storeConferences',
    STORE_CONFERENCE_CONNECT_INFO = 'Conferences/storeConferenceConnectInfo',
    STORE_CONFERENCE_INFO = 'Conferences/storeConferenceInfo',
    STORE_CONFERENCE_INVITED_USERS = 'Conference/storeConferenceInvitedUsers',
    STORE_CONFERENCE_PARTICIPANTS_DATA = 'Conference/storeConferenceParticipantsData',
    UPDATE_STORE_CONFERENCE_PARTICIPANTS_DATA = 'Conference/updateStoreConferenceParticipantsData',
    UPDATE_CONFERENCE_PARTICIPANTS_DATA = 'Conference/updateConferenceParticipantsData',
    TOGGLE_CONFERENCE_CHAT = 'Conference/toggleConferenceChat',
    SAVE_CHAT_DRAFT_MESSAGE = 'Conference/saveChatDraftMessage',
    STORE_DEVICE_NOTIFICATION = 'Conference/storeDeviceNotification',
    RESET_CONFERENCE_INVITED_USERS = 'Conference/resetConferenceInvitedUsers',
    SET_CONFERENCES_INVITED_USERS = 'Conference/setConferencesInvitedUsers',
    SET_CONFERENCES_PARTICIPANTS = 'Conference/setConferencesParticipants',
    SET_PAST_CONFERENCES_INFO = 'Conference/setPastConferencesInfo',
}
