import { t } from '@lingui/macro';
import { EErrorCodes } from 'constants/EErrorCodes';
import { FORM_ERROR } from 'final-form';

export const getSubmitErrorString = (errorCode: string | undefined) => {
    if (errorCode === EErrorCodes.EMAIL_ALREADY_IN_USE) {
        return {
            [FORM_ERROR]: t({
                id: 'This email address is already in use',
            }),
        };
    }
};
