import { t } from '@lingui/macro';

export const convertRecordSize = (size: number | undefined): string => {
    const mbLingui = t({ id: 'mb', message: 'МБ' });
    const gbLingui = t({ id: 'gb', message: 'ГБ' });
    if (!size) return '0 ' + mbLingui;
    const mBytes = size / 1048576;
    const gBytes = size / 1073741824;
    const gbEnd = ' ' + gbLingui;
    if (gBytes > 1) {
        const gBytesWithFraction = gBytes.toFixed(1);
        const gBytesRound = gBytes.toFixed();
        return gBytesWithFraction === gBytesRound + '.0'
            ? gBytesRound + gbEnd
            : gBytesWithFraction + gbEnd;
    }
    if (mBytes > 1) return mBytes.toFixed().toString() + ' ' + mbLingui;
    return mBytes.toFixed(2).toString() + ' ' + mbLingui;
};
