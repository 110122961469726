import { createAsyncThunk } from '@reduxjs/toolkit';
import { deleteUpcomingConferenceAction } from 'actions/conferencesList/deleteUpcomingConferenceAction';
import { changeConferencesListFilterThunkAction } from 'thunk/conferences/changeConferencesListFilterThunkAction';
import { TAppState } from 'types/app/TAppStore';

interface IDeleteExistingUpcomingConferencePayload {
    conferenceId: string;
    isRecurring?: boolean;
}

export const deleteExistingUpcomingConferenceThunkAction = createAsyncThunk<
    void,
    IDeleteExistingUpcomingConferencePayload
>(
    'deleteExistingUpcomingConference',
    async ({ conferenceId, isRecurring }, { dispatch, getState }) => {
        const {
            sessionSettings: {
                conferencesFilter: { upcoming },
            },
        } = getState() as TAppState;
        if (isRecurring) {
            dispatch(
                changeConferencesListFilterThunkAction({
                    upcoming: upcoming,
                    finished: false,
                }),
            );
        } else {
            dispatch(deleteUpcomingConferenceAction(conferenceId));
        }
    },
);
