import { setJanusStreamPropsAction } from 'actions/janus/setJanusStreamPropsAction';
import { applicationStore } from 'store/applicationStore';

const stopStream = (stream: MediaStream) => {
    const tracks = stream.getTracks();
    tracks.forEach((track: any) => {
        track.stop();
    });
};

export const checkOwnMediaBeforePublish = async (useVideo: boolean, useAudio: boolean) => {
    try {
        const stream = await navigator.mediaDevices.getUserMedia({
            video: useVideo,
            audio: useAudio,
        });
        stopStream(stream);
        return { useVideo, useAudio };
    } catch (e) {
        return processUserMediaError(useVideo, useAudio);
    }
};

const processUserMediaError = async (useVideo: boolean, useAudio: boolean) => {
    const resultDevicesStatus = { useVideo, useAudio };
    if (useVideo && !useAudio) {
        resultDevicesStatus.useVideo = false;
    } else if (!useVideo && useAudio) {
        resultDevicesStatus.useAudio = false;
    } else {
        try {
            const stream = await navigator.mediaDevices.getUserMedia({
                video: false,
                audio: useAudio,
            });
            resultDevicesStatus.useVideo = false;
            stopStream(stream);
        } catch (e) {
            resultDevicesStatus.useAudio = false;
        }
        try {
            const stream = await navigator.mediaDevices.getUserMedia({
                video: useVideo,
                audio: false,
            });
            resultDevicesStatus.useAudio = false;
            stopStream(stream);
        } catch (e) {
            resultDevicesStatus.useVideo = false;
        }
    }
    applicationStore.dispatch(setJanusStreamPropsAction(resultDevicesStatus));
    return resultDevicesStatus;
};
