import { t } from '@lingui/macro';
import { Trans } from '@lingui/react';
import { EUserRoles } from 'admin-app/constants/EUserRoles';
import { useSuccessNotification } from 'admin-app/hooks/useSuccessNotification';
import { Avatar } from 'components/common/Avatar';
import { Input } from 'components/common/Input';
import { StatusLabel } from 'components/common/StatusLabel';
import { ToggleField } from 'components/common/ToggleField';
import { FormApi } from 'final-form';
import { useEffect, useRef, useState } from 'react';
import { Field, Form, FormRenderProps } from 'react-final-form';
import { classNames } from 'utils/classNames';
import { getAvatarUrl } from 'utils/users/getAvatarUrl';
import AdminIcon from '../../assets/Admin.svg';
import CameraIcon from '../../assets/Camera.svg';
import OrganizerIcon from '../../assets/Organizer.svg';
import ParticipantIcon from '../../assets/Participant.svg';
import styles from './UserCardForm.module.scss';
import { IOwnProps, IStateProps, IUserCardFormFields } from './types';
import { getInitialValues } from './utils';
import { SelectField } from '../common/SelectField';

export const UserCardForm: React.FC<IOwnProps & IStateProps> = ({
    user,
    // maxAvatarSize,
    controls: Controls,
    className,
    handleSubmit,
}) => {
    const avatarRef = useRef<HTMLInputElement>(null);
    const [avatar, setAvatar] = useState<string | undefined>();
    const currentComponentRef = useRef(null);

    const [successSubmitText, setSuccessSubmitText, cleanupCallback] = useSuccessNotification();

    const setAvatarFromServer = () => {
        if (user && user.avatar) {
            setAvatar(getAvatarUrl({ id: user.id, etag: user.avatar, size: '180' }));
        } else {
            setAvatar(undefined);
        }
    };

    useEffect(() => {
        setAvatarFromServer();
    }, [user]);

    useEffect(() => {
        return () => {
            cleanupCallback();
        };
    }, []);

    const getUserRoles = () => [
        {
            value: 'participant',
            displayValue: 'Participant',
            img: ParticipantIcon,
            className: styles.role__participant,
        },
        {
            value: EUserRoles.ORGANIZER,
            displayValue: 'Organizer',
            img: OrganizerIcon,
            className: styles.role__organizer,
        },
        {
            value: EUserRoles.ADMIN,
            displayValue: 'Admin',
            img: AdminIcon,
            className: styles.role__admin,
        },
    ];
    const onSubmit = async (
        values: IUserCardFormFields,
        form: FormApi<IUserCardFormFields, IUserCardFormFields>,
    ) => {
        if (handleSubmit) {
            const submitErrors = await handleSubmit(values, form);
            if (!submitErrors) {
                form.reset();
                setSuccessSubmitText(t({ id: 'Changes are saved' }));
            }
        }
    };
    // const removeAvatar = (form: any) => {
    //     if (avatar !== null) {
    //         form.change('avatarWasChanged', true);
    //     }
    //     setAvatar(undefined);
    //     if (avatarRef.current) {
    //         avatarRef.current.value = '';
    //     }
    // };
    // const showDellButton = (user?.etag && avatar) || avatar;

    const render = ({
        handleSubmit,
        submitting,
        submitError,
        form,
        pristine,
    }: FormRenderProps<IUserCardFormFields>) => {
        const disableSubmit = pristine || submitError;
        return (
            <>
                <form
                    onSubmit={handleSubmit}
                    className={classNames([styles.form, className])}
                    ref={currentComponentRef}>
                    <div className={styles.wrapper}>
                        <StatusLabel
                            text={successSubmitText && !submitError ? successSubmitText : ''}
                            className={styles.form__submitSuccessMessage}
                        />
                        <Field name='avatarWasChanged'>{() => null}</Field>
                        <div
                            className={classNames([styles.form__field, styles.form__field_avatar])}>
                            <label htmlFor='avatarField' className={styles.form__inputLabel}>
                                <Trans id='Photo' message='Фото' />
                            </label>
                            <div>
                                <input
                                    type='file'
                                    ref={avatarRef}
                                    name='avatarField'
                                    onChange={() => {}}
                                    // onChange={() =>
                                    //     onChangeAvatar(
                                    //         form as any,
                                    //         avatarRef,
                                    //         maxAvatarSize,
                                    //         () => {},
                                    //         setAvatar,
                                    //     )
                                    // }
                                    accept='image/png, image/jpeg'
                                />
                                <Avatar
                                    image={avatar || CameraIcon}
                                    style={{
                                        // cursor: canChangeAvatar ? 'pointer' : 'default',
                                        width: '160px',
                                        height: '160px',
                                        background: !avatar ? '#D7D8E1' : 'transparent',
                                    }}
                                    imageStyle={{
                                        backgroundSize: !avatar ? '60px' : 'contain',
                                    }}
                                    clickHandler={
                                        () => {}
                                        // () => avatarRef.current?.click()
                                        // canChangeAvatar && avatarRef.current?.click()
                                    }
                                />
                            </div>
                        </div>
                        <div className={styles.form__body}>
                            <div className={styles.form__field}>
                                <label className={styles.form__inputLabel} htmlFor='firstName'>
                                    <Trans id='First name' message='Имя' />
                                </label>
                                <Input
                                    name='firstName'
                                    type='text'
                                    className={styles.form__input}
                                    disabled={true}
                                    // disabled={!canEditPersonalData}
                                />
                            </div>
                            <div className={styles.form__field}>
                                <label className={styles.form__inputLabel} htmlFor='lastName'>
                                    <Trans id='Last name' message='Фамилия' />
                                </label>
                                <Input
                                    name='lastName'
                                    type='text'
                                    className={styles.form__input}
                                    disabled={true}
                                    // disabled={!canEditPersonalData}
                                />
                            </div>
                            <div className={styles.form__field}>
                                <label className={styles.form__inputLabel} htmlFor='email'>
                                    <Trans id='Email' message='Электронная почта' />
                                </label>
                                <Input
                                    name='email'
                                    type='text'
                                    className={styles.form__input}
                                    disabled={true}

                                    // disabled={!canEditPersonalData}
                                />
                            </div>
                            <div className={styles.form__field}>
                                <label className={styles.form__inputLabel} htmlFor='role'>
                                    <Trans id='role' message='Роль' />
                                </label>
                                <SelectField
                                    name='role'
                                    containerClassName={styles.form__role}
                                    options={getUserRoles()}
                                />
                            </div>
                            <div className={styles.form__field}>
                                <label className={styles.form__inputLabel} htmlFor='activated'>
                                    <Trans id='status' message='Статус' />
                                </label>
                                <ToggleField
                                    name='activated'
                                    activeColor='Green'
                                    inactiveColor='Orange'
                                    viewMode={true}
                                />
                            </div>
                        </div>
                        {Controls && (
                            <Controls
                                submitting={submitting}
                                disableSubmit={disableSubmit}
                                submit={form.submit as any}
                            />
                        )}
                    </div>
                </form>
            </>
        );
    };

    return <Form onSubmit={onSubmit} render={render} initialValues={getInitialValues(user)} />;
};

// {true && (
//     <div
//         className={styles.avatar__delete}
//         onClick={() => removeAvatar(form)}>
//         <Trans message='Удалить фотографию' id='Delete photo' />
//     </div>
// )}
