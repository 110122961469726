import { redirect } from './redirect';

export const exitConferenceOrShowConfirmation = (
    exit: () => void,
    showConfirmation: () => void,
    location: string,
    onlyRedirect?: boolean,
) => {
    if (onlyRedirect) {
        return ()=>redirect('/');
    }
    const exitImmediately =
        location.match(/finished/g) !== null ||
        location.match(/enter/g) !== null ||
        location.match(/inputUsername/g) !== null;
    if (exitImmediately) return exit;
    return showConfirmation;
};
