import { FC, MouseEvent, useEffect, useRef } from 'react';
import { Button } from 'components/common/Button';
import { Trans } from '@lingui/react';
import { createInviteLink } from 'utils/conferences/createInviteLink';
import QRIcon from 'assets/QR.svg';
import CopyIcon from 'assets/CopyFile.svg';
import { ECopyToClipboardNotification } from 'constants/ECopyToClipboardNotification';
import { IOwnProps, IDispatchProps } from './types';
import styles from './ConferenceConnectInfo.module.scss';
import { QrConferenceInviteLink } from 'components/common/QrConferenceInviteLink';

export const ConferenceConnectInfo: FC<IOwnProps & IDispatchProps> = ({
    conferenceInfo,
    prepareToConnectToConference,
    copyToClipboard,
}) => {
    const inviteLink = createInviteLink(conferenceInfo.id, conferenceInfo.pin);
    const onClickCopyLink = (event: MouseEvent) => {
        event.stopPropagation();
        copyToClipboard({
            dataToCopy: inviteLink,
            notifyTextKey: ECopyToClipboardNotification.INVITE_LINK,
            notificationOptions: { type: 'notify' },
        });
    };

    const onClickCopyConfId = (event: MouseEvent) => {
        event.stopPropagation();
        copyToClipboard({
            dataToCopy: conferenceInfo.id,
            notifyTextKey: ECopyToClipboardNotification.CONF_ID,
            notificationOptions: { type: 'notify' },
        });
    };

    const onClickCopyConfPin = (event: MouseEvent) => {
        event.stopPropagation();
        copyToClipboard({
            dataToCopy: conferenceInfo.pin ? conferenceInfo.pin.toString() : '',
            notifyTextKey: ECopyToClipboardNotification.CONF_PIN,
            notificationOptions: { type: 'notify' },
        });
    };

    return (
        <div className={styles.connectionInfo}>
            <QrConferenceInviteLink link={inviteLink} size={300} foregroundColor='#48587D' />
            <Button
                type='button'
                styleType='primaryActionNew'
                clickHandler={() => prepareToConnectToConference(conferenceInfo.id)}>
                <Trans id='Join' />
            </Button>

            <div className={styles.connectionInfo__title}>
                <Trans id='detailsPage.inviteLink' />
            </div>
            <div className={styles.connectionInfo__text}>
                <Trans id='detailsPage.inviteLink.description' />
            </div>
            <div className={styles.connectionInfo__value} onClick={onClickCopyLink}>
                <span>{inviteLink}</span>
                <div>
                    <img src={CopyIcon} />
                    <Trans id='Copy' />
                </div>
            </div>

            <div className={styles.connectionInfo__title}>
                <Trans id='detailsPage.pin' />
            </div>
            <div className={styles.connectionInfo__text}>
                <Trans id='detailsPage.pin.description' />
            </div>
            <div className={styles.connectionInfo__value} onClick={onClickCopyConfPin}>
                <span>{conferenceInfo.pin}</span>
                <div>
                    <img src={CopyIcon} />
                    <Trans id='Copy' />
                </div>
            </div>

            <div className={styles.connectionInfo__title}>
                <Trans id='detailsPage.idConference' />
            </div>
            <div className={styles.connectionInfo__text}>
                <Trans id='detailsPage.idConference.description' />
            </div>
            <div className={styles.connectionInfo__value} onClick={onClickCopyConfId}>
                <span>{conferenceInfo.id}</span>
                <div>
                    <img src={CopyIcon} />
                    <Trans id='Copy' />
                </div>
            </div>
        </div>
    );
};
