import { AsyncThunk, createAsyncThunk } from '@reduxjs/toolkit';

const dummyThunks: Record<string, AsyncThunk<any, any, any>> = {};

export const getDummyThunk = (name: string) => {
    if (!dummyThunks[name]) {
        dummyThunks[name] = createAsyncThunk<void, void>(name, () => {});
    }
    return dummyThunks[name];
};
