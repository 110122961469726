import { connect } from 'react-redux';
import { TAppState } from 'types/app/TAppStore';
import { ConferenceUsers } from './ConferenceUsers';
import { IStateProps } from './types';

const mapStateToProps = (state: TAppState): IStateProps => ({
    ldapUsers: state.users.ldapUsers,
});

export const ConferenceUsersConnected = connect(mapStateToProps)(ConferenceUsers);
