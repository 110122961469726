import { LinkWithPendingActionCheckConnected } from 'components/common/LinkWithPendingActionCheck';
import { useLocation } from 'react-router-dom';
import { IOwnProps } from './types';
import styles from './AccountSettingsPageNavigation.module.scss';

export const AccountSettingsPageNavigation: React.FC<IOwnProps> = ({ routes }) => {
    const { pathname } = useLocation();
    const isActiveRoute = (targetRoute: string) => {
        return pathname.match(targetRoute);
    };
    return (
        <ul className={styles.navigationList}>
            {routes.map(({ path, title }) => {
                const dynamicStyle = isActiveRoute(path) ? styles.navigationList__item_active : ''
                return (
                    <li className={dynamicStyle} key={path}>
                        <LinkWithPendingActionCheckConnected to={path} data-qa=''>
                            <span>{title}</span>
                        </LinkWithPendingActionCheckConnected>
                    </li>
                )
            })}
        </ul>
    );
};
