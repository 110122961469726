import { appHistory } from 'appHistory';
import { connect } from 'react-redux';
import { showNotifyThunkAction } from 'thunk/notify/showNotifyThunkAction';
import { confirmAccountThunkAction } from 'thunk/profile/confirmAccountThunkAction';
import { TAppDispatch } from 'types/app/TAppDispatch';
import { TAppState } from 'types/app/TAppStore';
import { ConfirmAccountRegistrationPage } from './ConfirmAccountRegistrationPage';
import { IDispatchProps, IStateProps } from './types';

const mapStateToProps = (state: TAppState): IStateProps => ({
    loggedIn: state.auth.currentUser !== null,
});

const mapDispatchToProps = (dispatch: TAppDispatch): IDispatchProps => ({
    sendRequestToConfirmAccount: (token) => dispatch(confirmAccountThunkAction(token)),
    showNotification: (params) => dispatch(showNotifyThunkAction(params)),
    goToLogin: () => appHistory.replace('/login'),
    goToHome: () => appHistory.replace('/'),
});

export const ConfirmAccountRegistrationPageConnected = connect(
    mapStateToProps,
    mapDispatchToProps,
)(ConfirmAccountRegistrationPage);
