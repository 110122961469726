import { MobileWrapperConnected } from 'components/common/MobileWrapper';
import { ConferenceStartedSettingsFormConnected } from './ConferenceStartedSettingsFormConnected';
import { ConferenceStartedSettingsFormMobileConnected } from './ConferenceStartedSettingsFormMobileConnected';
import { IOwnProps } from './types';

export const ConferenceStartedSettingsFormResponsive: React.FC<Partial<IOwnProps>> = (props) => (
    <MobileWrapperConnected
        renderDesktop={() => <ConferenceStartedSettingsFormConnected {...props} />}
        renderMobile={() => <ConferenceStartedSettingsFormMobileConnected {...props} />}
    />
);
