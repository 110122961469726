import { Button } from 'components/common/Button';
import { Cross } from 'components/common/Cross';
import OutsideClick from 'components/common/OutsideClick/OutsideClick';
import React, { useEffect } from 'react';
import styles from './ConfirmDialog.module.scss';
import { IOwnProps } from './types';

export const ConfirmDialog: React.FC<IOwnProps> = ({
    handlePrimaryAction,
    handleSecondaryAction,
    handleClose,
    textPrimaryAction,
    textSecondaryAction,
    textHeader,
    textBody,
    theme,
    isDark,
}) => {
    const onEscPress = (e: KeyboardEvent) => {
        if (e.code === 'Escape') {
            e.stopPropagation();
            handleClose();
        }
    };

    useEffect(() => {
        document.addEventListener('keydown', onEscPress, { capture: true });
        return () => {
            document.removeEventListener('keydown', onEscPress, { capture: true });
        };
    }, []);

    return (
        <div className={styles.background}>
            <OutsideClick onOutsideClick={handleClose}>
                <div className={`${styles.dialog} ${isDark ? styles.dialog_dark : ''}`}>
                    <div className={styles.header}>
                        <span className={styles.header__text}>{textHeader}</span>
                        <div className={styles.cross}>
                            <Cross onClick={handleClose} />
                        </div>
                    </div>
                    {textBody && (
                        <div className={styles.description}>
                            <div className={styles.text}>{textBody}</div>
                        </div>
                    )}
                    <div className={styles.footer}>
                        <Button
                            styleType={isDark ? 'common' : 'secondaryActionNew'}
                            className={styles.button}
                            color={theme}
                            clickHandler={handleSecondaryAction}>
                            {textSecondaryAction}
                        </Button>
                        <Button
                            styleType={'primaryActionNew'}
                            className={styles.button}
                            color={theme}
                            clickHandler={handlePrimaryAction}>
                            {textPrimaryAction}
                        </Button>
                    </div>
                </div>
            </OutsideClick>
        </div>
    );
};
