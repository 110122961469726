import { t } from '@lingui/macro';
import BinImg from 'assets/Bin.svg';
import PencilImg from 'assets/Pencil.svg';
import styles from './ConferenceActionButtonsMobile.module.scss';
import { IOwnPropsMobile } from './types';

export const ConferenceActionButtonsMobile: React.FC<IOwnPropsMobile> = ({
    actionButtonsOpen,
    scheduled,
    onDelete,
    onEdit,
}) => {
    const openStyle = actionButtonsOpen
        ? scheduled
            ? styles.buttons_open_scheduled
            : styles.buttons_open
        : '';
    return (
        <div className={`${styles.buttons} ${openStyle}`}>
            <div className={styles.buttons__edit} onClick={onEdit}>
                <img src={PencilImg} alt='Pencil' />
                <div>{t({ id: 'edit.short', message: 'Редакт.' })}</div>
            </div>
            {scheduled && (
                <div className={styles.buttons__delete} onClick={onDelete}>
                    <img src={BinImg} alt='Bin' />
                    <div>{t({ id: 'Delete' })}</div>
                </div>
            )}
        </div>
    );
};
