import { createAsyncThunk } from '@reduxjs/toolkit';
import { storePastConferenceAction } from 'actions/conferencesList/storePastConferenceAction';
import { setConferencesRecordsAction } from 'actions/records/setConferencesRecordsAction';
import { addConferenceIdAtPositionAction } from 'actions/sessionSettings/addConferenceIdAtPositionAction';
import { getPastConferenceInfoApiCall } from 'api/conference/getPastConferenceInfoApiCall';
import { getNetworkService } from 'api/getNetworkService';
import { TAppState } from 'types/app/TAppStore';
import { IPastConferenceInfo } from 'types/conferencesList/IPastConferenceInfo';
import { isDateInFilterRange } from 'utils/dates/isDateInFilterRange';
import { createProcessingRecord } from 'utils/records/createProcessingRecord';
import { getConferencePositionInList } from 'utils/conferences/getConferencePositionInList';

interface IStoreNewPastConferencePayload {
    conferenceId: string;
    sessionId: string;
}

export const storeNewPastConferenceThunkAction = createAsyncThunk<
    void,
    IStoreNewPastConferencePayload
>('storeNewPastConference', async (dataToRequest, { dispatch, getState }) => {
    const response = await getPastConferenceInfoApiCall(getNetworkService(), dataToRequest);

    if (response.success) {
        const conference = response.data as IPastConferenceInfo;
        const {
            sessionSettings: {
                conferenceIdsToShow,
                conferencesFilter: { past },
            },
            conferencesRecords: { conferencesRecords },
            conferencesList: { pastConferences },
            auth: { currentUser },
        } = getState() as TAppState;

        if (isDateInFilterRange(past, conference.timeStart)) {
            dispatch(storePastConferenceAction(conference));
            dispatch(
                setConferencesRecordsAction([
                    ...conferencesRecords,
                    createProcessingRecord({
                        id: `${Math.floor(Math.random() * 1000 + 1)}-new`,
                        sessionId: conference.id,
                        conferenceId: conference.conferenceId,
                        name: conference.name,
                        timeStart: conference.timeStart,
                        currentUserIsOwner: conference.creator.userId === currentUser?.id,
                    }),
                ]),
            );

            dispatch(
                addConferenceIdAtPositionAction({
                    position: getConferencePositionInList(
                        conferenceIdsToShow,
                        pastConferences,
                        conference,
                    ),
                    newConferenceId: conference.id,
                }),
            );
        }
    }
});
