import { createAction } from '@reduxjs/toolkit';
import { EApplicationViewActions } from 'constants/actions/EApplicationViewActions';

interface IPayload {
    local?: string[],
    global?: string[],
}

export const setParticipantsFullScreenAction = createAction<IPayload>(
    EApplicationViewActions.SET_PARTICIPANTS_FULLSCREEN,
);
