import { appHistory } from 'appHistory';
import { connect } from 'react-redux';
import { TAppState } from 'types/app/TAppStore';
import { PersonalInformationFormMobile } from './PersonalInformationFormMobile';
import { IDispatchProps, IStateProps } from './types';
import { updateProfileInfoThunkAction } from 'thunk/profile/updateProfileInfoThunkAction';
import { TAppDispatch } from 'types/app/TAppDispatch';

const mapStateToProps = (state: TAppState): IStateProps => ({
    user: state.auth.currentUser,
    notLoggedIn: state.auth.rememberMeRequestStatus === 'error',
});

const mapDispatchToProps = (dispatch: TAppDispatch): IDispatchProps => ({
    goToHomePage: () => appHistory.push('/'),
    save: (data: any) => dispatch(updateProfileInfoThunkAction(data)).unwrap(),
});

export const PersonalInformationFormMobileConnected = connect(
    mapStateToProps,
    mapDispatchToProps,
)(PersonalInformationFormMobile);
