import { t } from '@lingui/macro';
import { MobilePageTemplate } from 'components/MobilePageTemplate';
import React from 'react';
import { redirect } from 'utils/redirect';
import { PersonalInformationFormMobileConnected } from './PersonalInformationForm';
import styles from './PersonalInformationPage.module.scss';

export const PersonalInformationPageMobile: React.FC = () => (
    <MobilePageTemplate
        title={t({ id: 'personal.info.title', message: 'Личные данные' })}
        titleClassName={styles.title_mobile}
        onClickBack={() => redirect('/')}>
        <PersonalInformationFormMobileConnected />
    </MobilePageTemplate>
);
