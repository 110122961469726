import { TAppState } from 'types/app/TAppStore';
import { janusVideoRoomLogger } from 'utils/logger';
import { getDisplayName } from 'utils/users/getDisplayName';
import { janusCtx } from './janusCtx';

export const videoRoomPluginSuccessConnectedHandler = (pluginHandle: any) => {
    // $('#details').remove();
    janusCtx.videoRoomPublisherPluginHandler = pluginHandle;
    janusVideoRoomLogger.debug(
        'Plugin attached! (' +
            janusCtx.videoRoomPublisherPluginHandler.getPlugin() +
            ', id=' +
            janusCtx.videoRoomPublisherPluginHandler.getId() +
            ')',
    );
    janusVideoRoomLogger.debug(janusCtx.videoRoomPublisherPluginHandler);
    janusVideoRoomLogger.debug('  -- This is a publisher/manager');
    // Register in conference
    const {
        auth: { currentUser, anonymousUser },
        janus: { ownParticipantId },
        conferenceSession: { currentConferenceCreator, currentConferencePin: pin },
    } = janusCtx.getState() as TAppState;
    const request =
        currentUser?.contact.email === currentConferenceCreator?.email
            ? 'joinandconfigure'
            : 'join';
    const message = {
        display: getDisplayName(currentUser || anonymousUser),
        id: ownParticipantId,
        pin,
        ptype: 'publisher',
        request,
        room: janusCtx.roomId,
        notify_joining: true,
        token: ownParticipantId,
    };
    janusCtx.videoRoomPublisherPluginHandler.send({ message });
};
