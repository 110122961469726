import { LoginFormMobileConnected } from 'components/forms/LoginForm';
import { MobilePageTemplate } from 'components/MobilePageTemplate';
import { EPaths } from 'constants/EPaths';
import React, { useEffect } from 'react';
import { redirect } from 'utils/redirect';
import { IStateProps } from './types';

export const LoginPageMobile: React.FC<IStateProps> = ({ rememberMeRequestStatus }) => {
    useEffect(() => {
        if (rememberMeRequestStatus === 'success') {
            redirect(EPaths.UPCOMING_CONFERENCES);
        }
    }, [rememberMeRequestStatus]);

    return rememberMeRequestStatus === 'error' ? (
        <MobilePageTemplate showIcon={true} onClickBack={() => redirect('/')}>
            <LoginFormMobileConnected />
        </MobilePageTemplate>
    ) : null;
};
