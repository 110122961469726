import { ReactComponent as VideoMutedSvg } from 'assets/CameraOff.svg';
import CrownImg from 'assets/Crown.svg';
import { ReactComponent as AudioMutedSvg } from 'assets/MicrophoneOff.svg';
import { Avatar } from 'components/common/Avatar/Avatar';
import { LottieAnimation } from 'components/common/LottieAnimation';
import { Spinner } from 'components/common/Spinner/Spinner';
import { ParticipantContextMenu } from 'components/ParticipantContextMenu';
import { getEmotionAnimation } from 'utils/emotions/getEmotionAnimation';
import styles from './ParticipantFrame.module.scss';
import { IOwnProps } from './types';
import { classNames } from 'utils/classNames';

const ownerSquare = () => (
    <div className={`${styles.squareOwner} ${styles.iconWrapper}`}>
        <div className={styles.square}>
            <img src={CrownImg} alt='Owner' />
        </div>
    </div>
);

const muteIcon = (use: boolean, permitted: boolean, type: 'video' | 'audio') => {
    const classNames = [styles.mediaDevice];
    if (permitted && use) {
        return null;
    }
    if (permitted) {
        if (!use) {
            classNames.push(styles.mediaDevice__disabled);
        }
    } else {
        classNames.push(styles.mediaDevice__forbidden);
    }
    const className = classNames.join(' ');
    return (
        <div className={styles.iconWrapper}>
            <div className={styles.square}>
                {type === 'audio' && <AudioMutedSvg className={className} />}
                {type === 'video' && <VideoMutedSvg className={className} />}
            </div>
        </div>
    );
};

const getEmotion = (emotionName: string, priorityFrameEnable?: boolean) => (
    <div className={`${styles.emotion} ${priorityFrameEnable ? styles.emotion_priorityFrame : ''}`}>
        <LottieAnimation animationData={getEmotionAnimation(emotionName)} />
    </div>
);

export const ParticipantFrame: React.FC<IOwnProps> = ({
    active,
    anonymous,
    audioVideoProps,
    avatar,
    avatarMin,
    children,
    displayName,
    emotionToShow,
    height,
    initials,
    isMobile,
    isPriorityFrame,
    loading,
    ownFrame,
    owner,
    participantId,
    width,
    forRecorder,
}) => {
    const activeStyle = [styles.member, active && !isPriorityFrame ? styles.active : ''].join(' ');
    const { audioPermitted, videoPermitted, useAudio, useVideo, screenShared } = audioVideoProps;
    const showAvatar = (!videoPermitted || !useVideo) && !loading && !screenShared;
    return (
        <div
            className={`${activeStyle} ${isPriorityFrame ? styles.member_withPriorityFrame : ''} ${
                ownFrame && !audioVideoProps.screenShared ? styles.member_mirrored : ''
            }`}
            style={{ width, height }}>
            {owner && !isPriorityFrame && ownerSquare()}
            <div className={styles.iconsRightBlock}>
                {owner && isPriorityFrame && ownerSquare()}
                {muteIcon(useAudio, audioPermitted, 'audio')}
                {muteIcon(useVideo, videoPermitted, 'video')}
            </div>
            {children}
            {(showAvatar || loading) && (
                <div className={styles.block}>
                    {showAvatar && (
                        <Avatar
                            initials={initials}
                            image={avatarMin || avatar}
                            style={{
                                width: '50%',
                                height: '90%',
                                maxHeight: '150px',
                                maxWidth: '150px',
                            }}
                            guest={anonymous}
                        />
                    )}
                    {loading && (
                        <div className={styles.loading}>
                            <Spinner />
                        </div>
                    )}
                </div>
            )}
            {emotionToShow && getEmotion(emotionToShow.emotionName, isPriorityFrame)}
            {!isMobile && (
                <div
                    className={classNames([
                        styles.hoverMenu,
                        forRecorder ? styles.hoverMenu_visible : '',
                    ])}>
                    {isPriorityFrame && (
                        <Avatar
                            initials={initials}
                            image={avatarMin || avatar}
                            style={{ width: '40px', height: '40px' }}
                            active={active}
                        />
                    )}
                    <div className={styles.name}>{displayName}</div>
                    {!forRecorder && (
                        <ParticipantContextMenu
                            title={displayName}
                            participantId={participantId}
                            orientation={isPriorityFrame ? 'down-right' : 'up-left'}
                        />
                    )}
                </div>
            )}
        </div>
    );
};
