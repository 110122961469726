import { SyntheticEvent } from 'react';
import { IOwnProps, IDispatchProps } from './types';

export const LinkWithPendingActionCheck: React.FC<IOwnProps & IDispatchProps> = (props) => {
    const newProps: any = { ...props };
    const tryRedirect = newProps.tryRedirect;
    delete newProps.tryRedirect;
    if (newProps.to) {
        newProps.href = newProps.to;
        delete newProps.to;
    }
    delete newProps.method;
    delete newProps.onClick;
    newProps.onClick = (evt: SyntheticEvent) => {
        if (newProps.href || props.onClick) {
            tryRedirect(newProps.href, props.method, props.onClick);
        }
        evt.preventDefault();
    };
    return <a {...newProps} />;
};
