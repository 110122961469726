import { connect } from 'react-redux';
import { TAppState } from 'types/app/TAppStore';
import { TAppDispatch } from 'types/app/TAppDispatch';
import { LoggedInMenu } from './LoggedInMenu';
import { IStateProps, IDispatchProps } from './types';
import { appHistory } from 'appHistory';
import { createNewConferenceThunkAction } from 'thunk/conference/createNewConferenceThunkAction';
import { IUserData } from 'types/user/IUserData';
import { EPaths } from 'constants/EPaths';

const mapStateToProps = (state: TAppState): IStateProps => ({
    currentUser: state.auth.currentUser as IUserData,
    disabledStartConfButton: state.applicationView.disabledStartConfButton,
});

const mapDispatchToProps = (dispatch: TAppDispatch): IDispatchProps => ({
    goToConnect: (id) => {
        appHistory.push(`${EPaths.CONNECT_TO_CONFERENCE}/${id}`);
    },
    createNewConference: () => dispatch(createNewConferenceThunkAction()),
});

export const LoggedInMenuConnected = connect(mapStateToProps, mapDispatchToProps)(LoggedInMenu);
