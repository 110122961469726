import { IOwnProps, IFormProps, IDispatchProps } from './types';
import styles from './InviteForm.module.scss';
import { Form, FormRenderProps } from 'react-final-form';
import { EmailListEditorWrapper } from 'components/common/EmailListEditor/EmailListEditorWrapper';
import { Button } from 'admin-app/components/common/Button';
import { Trans } from '@lingui/react';
import { EmailsField } from './EmailsField';
import { FORM_ERROR } from 'final-form';
import { getSubmitErrorString } from './utils';
import { StatusLabel } from 'components/common/StatusLabel';
import { useContext, useEffect, useRef, useState } from 'react';
import { NewUserModalContext } from '../../utils';
import { t } from '@lingui/macro';

export const InviteForm: React.FC<IOwnProps & IDispatchProps> = ({ sendUserInvite }) => {
    const [isSuccess, setIsSuccess] = useState(false);
    const timerRef = useRef<NodeJS.Timeout | null>(null);
    const { closeModal } = useContext(NewUserModalContext);

    useEffect(() => {
        return () => {
            timerRef.current && clearTimeout(timerRef.current);
        };
    }, []);

    const handleSubmit = async (values: IFormProps) => {
        if (!values.emailsToInvite.length) {
            return;
        }
        const errors = await sendUserInvite(values.emailsToInvite[0]);
        if (errors) {
            return { [FORM_ERROR]: getSubmitErrorString(errors.errorCode) };
        }

        setIsSuccess(true);
        timerRef.current = setTimeout(() => {
            closeModal();
        }, 3000);
    };

    const renderForm = ({
        values,
        handleSubmit,
        submitting,
        submitError,
        dirtySinceLastSubmit,
    }: FormRenderProps<IFormProps>) => {
        const isPristine = values.emailsToInvite.length;
        return (
            <form onSubmit={handleSubmit}>
                {isSuccess && (
                    <div className={styles.form__errorWrapper}>
                        <StatusLabel
                            text={t({
                                id: 'admin.invitesForm.success',
                                message: 'Приглашения успешно отправлены',
                            })}
                            className={styles.form__submitSuccessMessage}
                        />
                    </div>
                )}
                <div className={styles.form__errorWrapper}>
                    <StatusLabel
                        text={submitError && !dirtySinceLastSubmit ? submitError[FORM_ERROR] : ''}
                    />
                </div>
                <EmailsField name='emailsToInvite' />
                <Button
                    className={styles.form__submit}
                    type='submit'
                    disabled={!isPristine || submitting || (!dirtySinceLastSubmit && submitError)}>
                    <Trans id='sendInvite' message='Отправить приглашение' />
                </Button>
            </form>
        );
    };

    const initialValues = {
        emailsToInvite: [],
    };

    return <Form onSubmit={handleSubmit} initialValues={initialValues} render={renderForm} />;
};
