import { t } from '@lingui/macro';
import { resetPendingConfirmationAction } from 'actions/applicationView/resetPendingConfirmationAction';
import { setFormEditFlagAction } from 'actions/applicationView/setFormEditFlagAction';
import { EAudioVideoComboValues } from 'constants/appSettingsStructure';
import { EPaths } from 'constants/EPaths';
import { fieldsToExpression, parseExpression } from 'cron-parser';
import {
    differenceInCalendarMonths,
    format,
    getDaysInMonth,
    isValid,
    parse,
    setMonth,
    startOfDay,
} from 'date-fns';
import { ru } from 'date-fns/locale';
import { FORM_ERROR } from 'final-form';
import { useLocation } from 'react-router-dom';
import { applicationStore } from 'store/applicationStore';
import { deleteConferenceFromConferenceSettingsFormMobileThunkAction } from 'thunk/conference/deleteConferenceFromConferenceSettingsFormMobileThunkAction';
import { scheduleConferenceThunkAction } from 'thunk/conference/scheduleConferenceThunkAction';
import { updateConferenceInfoThunkAction } from 'thunk/conference/updateConferenceInfoThunkAction';
import { showNotifyThunkAction } from 'thunk/notify/showNotifyThunkAction';
import { IAppSettingsTyped } from 'types/app/IAppSettings';
import { TAppDispatch } from 'types/app/TAppDispatch';
import { TAppState } from 'types/app/TAppStore';
import { IConferenceInfo } from 'types/conferences/IConferenceInfo';
import { IRecurringData } from 'types/conferences/IRecurringData';
import { TDays } from 'types/conferences/TDays';
import { TStep } from 'types/conferences/TStep';
import { IThunkResult } from 'types/IThunkResult';
import { convertFromSecondsToHoursMinutesSeconds } from 'utils/convertFromSecondsToHoursMinutesSeconds';
import { addTAndZToISOFormat } from 'utils/dates/addTAndZToISOFormat';
import { addUsersTimeZoneToDate } from 'utils/dates/addUsersTimeZoneToDate';
import { convertDateToStringByTemplate } from 'utils/dates/convertDateToStringByTemplate';
import { convertStringToDateByTemplate } from 'utils/dates/convertStringToDateByTemplate';
import { getCurrentDate } from 'utils/dates/getCurrentDate';
import { getDateBasedOnTimezone } from 'utils/dates/getDateBasedOnTimezone';
import { getDateBasedOnUTC0 } from 'utils/dates/getDateBasedOnUTC0';
import { getSuggestedDate, getSuggestedTime } from 'utils/dates/getSuggestedDate';
import { removeUserTimeZoneFromDate } from 'utils/dates/removeUserTimeZoneFromDate';
import { getParticipantsAudioVideoPermissions } from 'utils/forms/getParticipantsAudioVideoPermissions';
import { getResultErrorString } from 'utils/forms/getResultErrorString';
import { getDefaultErrorNotification } from 'utils/getDefaultErrorNotification';
import { getInvitedUsersToSend } from 'utils/getInvitedUsersToSend';
import { getRandomPin } from 'utils/getRandomPin';
import { redirect } from 'utils/redirect';
import { getTimeZoneByName } from 'utils/timeZones/getTimeZoneByName';
import { getTimeZoneName } from 'utils/timeZones/getTimeZoneName';
import { getUserTimeZone } from 'utils/timeZones/getUserTimeZone';
import { IDispatchProps, IFormProps, IStateProps } from './types';
import * as utils from './utils';

export const onAccountPage = (): boolean => {
    return useLocation().pathname.match(/account/g) !== null;
};

const checkDay = (typedDay: string, currentDate: Date, errors: { [key: string]: string }) => {
    const currentMonthDays = getDaysInMonth(currentDate);
    if (!Number(typedDay) || Number(typedDay) > currentMonthDays) {
        errors.day = 'Invalid';
    }
};

const checkMonth = (typedMonth: string, errors: { [key: string]: string }) => {
    if (!Number(typedMonth) && Number(typedMonth) > 12) {
        errors.month = 'Invalid';
    }
};

const checkYear = (typedYear: string, currentDate: Date, errors: { [key: string]: string }) => {
    if (!Number(typedYear)) {
        errors.month = 'Invalid';
        return;
    }
    const currentYear = currentDate.getFullYear();
    const currentYearFormatted = currentYear.toString().slice(0, typedYear.length);
    if (Number(typedYear) !== Number(currentYearFormatted)) {
        errors.month = 'Invalid';
    }
};

export const validateOnStartDateBlur = (value: string) => {
    const valueWithoutExtraSpaces = value.trim();
    const splittedValue = valueWithoutExtraSpaces.split(/\W/);
    const currentDate = getCurrentDate();
    const errors: { [key: string]: string } = {};
    if (valueWithoutExtraSpaces.match(/^\s*[0-9]{2}\W{0,1}\s*$/)) {
        checkDay(splittedValue[0], currentDate, errors);
    } else if (valueWithoutExtraSpaces.match(/^\s*[0-9]{2}\W[0-9]{2}\W{0,1}\s*$/)) {
        checkMonth(splittedValue[1], errors);
        checkDay(splittedValue[0], setMonth(currentDate, Number(splittedValue[1]) - 1), errors);
    } else if (valueWithoutExtraSpaces.match(/^\s*[0-9]{2}\W[0-9]{2}\W[0-9]{0,3}\s*$/)) {
        checkMonth(splittedValue[1], errors);
        checkDay(splittedValue[0], setMonth(currentDate, Number(splittedValue[1]) - 1), errors);
        checkYear(splittedValue[2], currentDate, errors);
    } else if (valueWithoutExtraSpaces.match(/^\s*[0-9]{2}\W[0-9]{2}\W[0-9]{4}\s*$/)) {
        return true;
    } else {
        return null;
    }

    return !Object.keys(errors).length;
};

export const formatOnStartDateBlur = (value: string, setFieldValue: (arg: any) => void) => {
    if (value.match(/^\s*[0-9]{2}\W[0-9]{2}\W[0-9]{4}\s*$/)) {
        setFieldValue(value.trim());
        return;
    }
    const resultString = getSuggestedDate(value);
    value !== resultString && setFieldValue(resultString);
};

const checkHours = (typedHours: string, errors: { [key: string]: string }) => {
    if (
        Number(typedHours) < 0 ||
        Number(typedHours) > 23 ||
        (typedHours.length === 1 && Number(typedHours) > 2)
    ) {
        errors.hours = 'Invalid';
    }
};

const checkMinutes = (typedMinutes: string, errors: { [key: string]: string }) => {
    if (Number(typedMinutes) < 0 || Number(typedMinutes) > 5) {
        errors.minutes = 'Invalid';
        return;
    }
};

export const validateOnStartTimeBlur = (value: string) => {
    const valueWithoutExtraSpaces = value.trim();
    const splittedValue = valueWithoutExtraSpaces.split(/\W/);
    const errors: { [key: string]: string } = {};

    if (valueWithoutExtraSpaces.match(/^\s*[0-9]{1,2}\W{0,1}$/)) {
        checkHours(splittedValue[0], errors);
    } else if (valueWithoutExtraSpaces.match(/^\s*[0-9]{2}\W{1}[0-9]{1}\s*$/)) {
        checkHours(splittedValue[0], errors);
        checkMinutes(splittedValue[1], errors);
    } else if (valueWithoutExtraSpaces.match(/^\s*[0-9]{2}\W{1}[0-9]{2}\s*$/)) {
        return true;
    } else {
        return null;
    }

    return !Object.keys(errors).length;
};

export const formatOnStartTimeBlur = (value: string) => {
    if (value.match(/^\s*[0-9]{2}\W{1}[0-9]{2}\s*$/)) {
        return value.trim();
    }
    const resultString = getSuggestedTime(value);
    if (value !== resultString) {
        return resultString;
    }
    return value;
};

export const getDefaultFormProps = (): Partial<IFormProps> => {
    const {
        auth: { appSettings },
    } = applicationStore.getState() as TAppState;
    const currentDate = getCurrentDate();
    const { startDate, startTime } = getStartDateTime(currentDate);
    const randomPin = process.env.NODE_ENV === 'test' ? '000000' : getRandomPin(); // For stories snapshots
    const typedAppSettings = appSettings as IAppSettingsTyped | null;
    return {
        durationHours: '0',
        durationMinutes: '30',
        lobby: false,
        participantsVideoPermission: typedAppSettings
            ? typedAppSettings.videoParticipants
            : EAudioVideoComboValues.OFF,
        pin: randomPin,
        pinUse: true,
        recording: typedAppSettings?.recordConference,
        registeredOnly: false,
        startDate,
        startTime,
        timeZone: getTimeZoneName(getUserTimeZone()),
        topic: t({ id: 'new.conference', message: 'Новая конференция' }),
        invitedUsers: [],
        videoOwner: typedAppSettings?.ownVideoOnConnect,
        participantsAudioPermission: typedAppSettings
            ? typedAppSettings.audioParticipants
            : EAudioVideoComboValues.OFF,
        waitCreator: false,
    };
};

export const getStartDateTime = (date: Date): { startDate: string; startTime: string } => {
    const nextHour = parseInt(format(date, 'HH')) + 1;
    if (nextHour === 24) {
        const nextDay = new Date(date.getTime() + 24 * 60 * 60 * 1000);
        return {
            startDate: convertDateToStringByTemplate(nextDay, 'dd.MM.yyyy'),
            startTime: '00:00',
        };
    } else {
        const nextHourString = nextHour.toString();
        return {
            startDate: convertDateToStringByTemplate(date, 'dd.MM.yyyy'),
            startTime:
                nextHourString.length === 1 ? `0${nextHourString}:00` : `${nextHourString}:00`,
        };
    }
};

export const getConferenceValues = (conferenceInfo: IConferenceInfo) => {
    const participantsAudioVideo = getParticipantsAudioVideoPermissions(conferenceInfo);
    const duration = convertFromSecondsToHoursMinutesSeconds((conferenceInfo.duration || 0) * 60);
    const startDateString = conferenceInfo.startDateOfRecurrence
        ? conferenceInfo.startDateOfRecurrence
        : conferenceInfo.timeStart;
    const startDate = getDateBasedOnTimezone(
        convertStringToDateByTemplate(startDateString, 'yyyy-MM-dd HH:mm:ss.SSSSSS'),
        conferenceInfo.timeZone as string,
    );

    let recurringData: IRecurringData | undefined = undefined;
    if (
        conferenceInfo.cron &&
        conferenceInfo.startDateOfRecurrence &&
        conferenceInfo.endDateOfRecurrence
    ) {
        recurringData = convertCronToRecurringData(
            conferenceInfo.cron,
            conferenceInfo.startDateOfRecurrence,
            conferenceInfo.endDateOfRecurrence,
        );
    }
    return {
        recurringData,
        topic: conferenceInfo.name,
        description: conferenceInfo.description,
        timeZone: getTimeZoneName(conferenceInfo.timeZone),
        recording: conferenceInfo.recording,
        pin: conferenceInfo.pin,
        pinUse: conferenceInfo.pin !== '' && conferenceInfo.pin ? true : false,
        startTime: convertDateToStringByTemplate(startDate, 'HH:mm'),
        startDate: convertDateToStringByTemplate(startDate, 'dd.MM.yyyy'),
        durationHours: duration.hours.toString(),
        durationMinutes: duration.minutes.toString(),
        videoOwner: conferenceInfo.videoCreator,
        participantsVideoPermission: participantsAudioVideo.video,
        participantsAudioPermission: participantsAudioVideo.audio,
        lobby: conferenceInfo.lobby,
        invitedUsers: conferenceInfo.invitedUsers || [],
        registeredOnly: conferenceInfo.registeredOnly,
        waitCreator: conferenceInfo.waitCreator,
    };
};

export const convertCronToRecurringData = (
    cron: string,
    startDateOfRecurrence: string,
    endDateOfRecurrence: string,
): IRecurringData => {
    const interval = parseExpression(cron);
    const daysOfWeekList = [
        'sunday',
        'monday',
        'tuesday',
        'wednesday',
        'thursday',
        'friday',
        'saturday',
        'sunday',
    ];
    const { fields } = JSON.parse(JSON.stringify(interval));
    const time = `${fields.hour < 10 ? `0${fields.hour}` : fields.hour}:${
        fields.minute < 10 ? `0${fields.minute}` : fields.minute
    }`;
    const parsedStartTime = parse(time, 'HH:mm', new Date());
    const timeToData = convertDateToStringByTemplate(
        addUsersTimeZoneToDate(parsedStartTime),
        'HH:mm',
    );
    // Case: 0 30 15 * * * - sunday will be duplicated at the end and at the beginning
    const daysWithoutDuplicate = new Set<TDays>(
        fields.dayOfWeek.map((dayNumber: number) => daysOfWeekList[dayNumber]),
    );

    return {
        startDate: convertDateToStringByTemplate(
            addUsersTimeZoneToDate(
                convertStringToDateByTemplate(startDateOfRecurrence, 'yyyy-MM-dd HH:mm:ss.SSSSSS'),
            ),
            'dd.MM.yyyy',
        ),
        endDate: convertDateToStringByTemplate(
            addUsersTimeZoneToDate(
                convertStringToDateByTemplate(endDateOfRecurrence, 'yyyy-MM-dd HH:mm:ss.SSSSSS'),
            ),
            'dd.MM.yyyy',
        ),
        count: '1',
        days: Array.from(daysWithoutDuplicate),
        step: 'week',
        time: timeToData,
    };
};

const getParticipantsAudioVideoToSend = (formValues: IFormProps) => {
    const { participantsAudioPermission, participantsVideoPermission } = formValues;
    const resultObj = {
        forbidVideo: false,
        forbidAudio: false,
        videoParticipants: true,
        muteUponEntry: false,
    };
    switch (participantsVideoPermission) {
        case EAudioVideoComboValues.DISABLED:
            resultObj.forbidVideo = true;
            resultObj.videoParticipants = false;
            break;
        case EAudioVideoComboValues.OFF:
            resultObj.videoParticipants = false;
            break;
        case EAudioVideoComboValues.ON:
            resultObj.videoParticipants = true;
            break;
    }

    switch (participantsAudioPermission) {
        case EAudioVideoComboValues.DISABLED:
            resultObj.forbidAudio = true;
            resultObj.muteUponEntry = true;
            break;
        case EAudioVideoComboValues.OFF:
            resultObj.muteUponEntry = true;
            break;
        case EAudioVideoComboValues.ON:
            resultObj.muteUponEntry = false;
            break;
    }
    return resultObj;
};

export const getConferenceDataToSend = (values: IFormProps, conferenceInfo?: IConferenceInfo) => {
    const invitedUsers = getInvitedUsersToSend(values.invitedUsers, conferenceInfo?.invitedUsers);
    const participantsAudioVideo = getParticipantsAudioVideoToSend(values);
    const selectedTZ = getTimeZoneByName(values.timeZone);
    const startDateUTC0 = getDateBasedOnUTC0(
        convertStringToDateByTemplate(
            `${values.startDate} ${values.startTime}`,
            'dd.MM.yyyy HH:mm',
            false,
        ),
        selectedTZ,
    );
    const duration = parseInt(values.durationHours) * 60 + parseInt(values.durationMinutes);
    const dataForRepeat = getDataForRepeatField({
        recurringData: values.recurringData,
        timeZone: selectedTZ,
    });
    const result: { [id: string]: any } = {
        name: values.topic,
        timeZone: selectedTZ,
        pin: values.pin,
        lobby: values.lobby,
        description: values.description,
        videoCreator: values.videoOwner,
        duration: duration,
        invitedUsers,
        registeredOnly: values.registeredOnly,
        recording: values.recording,
        waitCreator: values.waitCreator,
        ...participantsAudioVideo,
        ...dataForRepeat,
    };
    if (!values.recurringData) {
        result.timeStart = addTAndZToISOFormat(
            convertDateToStringByTemplate(startDateUTC0, 'yyyy-MM-dd HH:mm:ss'),
        );
    }
    return result;
};

export const getDataForRepeatField = ({
    recurringData,
    timeZone,
}: {
    recurringData: IRecurringData | null;
    timeZone: string;
}): { cron: string; startDateOfRecurrence: string; endDateOfRecurrence: string } | undefined => {
    if (!recurringData) {
        return;
    }
    const daysOfWeekList = {
        monday: 1,
        tuesday: 2,
        wednesday: 3,
        thursday: 4,
        friday: 5,
        saturday: 6,
        sunday: 7,
    };
    const parsedStartTime = parse(recurringData.time, 'HH:mm', new Date());
    const conferenceStartDateUTC0 = removeUserTimeZoneFromDate(parsedStartTime);
    const recurringStartDateUTC0 = getDateBasedOnUTC0(
        convertStringToDateByTemplate(
            `${recurringData.startDate} 00:00`,
            'dd.MM.yyyy HH:mm',
            false,
        ),
        timeZone,
    );
    const recurringEndDateUTC0 = getDateBasedOnUTC0(
        convertStringToDateByTemplate(
            `${recurringData.endDate} 23:59:59`,
            'dd.MM.yyyy HH:mm:ss',
            false,
        ),
        timeZone,
    );

    const interval = parseExpression('* * * * *');
    const fields = JSON.parse(JSON.stringify(interval.fields));

    fields.hour = [conferenceStartDateUTC0.getHours()];
    fields.minute = [conferenceStartDateUTC0.getMinutes()];
    if (recurringData.days.length > 0) {
        const days = recurringData.days.map((day) => daysOfWeekList[day]).sort();
        fields.dayOfWeek = days;
    }
    const modifiedInterval = fieldsToExpression(fields);
    const cronString = modifiedInterval.stringify();
    const cronFormattedString = addSecondToCronString(
        addCountToCron(cronString, recurringData.step, recurringData.count),
    );
    return {
        cron: cronFormattedString,
        startDateOfRecurrence: addTAndZToISOFormat(
            convertDateToStringByTemplate(recurringStartDateUTC0, 'yyyy-MM-dd HH:mm:ss'),
        ),
        endDateOfRecurrence: addTAndZToISOFormat(
            convertDateToStringByTemplate(recurringEndDateUTC0, 'yyyy-MM-dd HH:mm:ss'),
        ),
    };
};

export const addSecondToCronString = (cron: string) => `0 ${cron}`;

/**
 * @param cronString - minute/hour/dayOfMonth/month/dayOfWeek
 */
export const addCountToCron = (cronString: string, field: TStep, count: string) => {
    const cronParts = cronString.split(' ');
    if (field === 'day' && Number(count) > 1) {
        cronParts[2] = `*/${count}`;
    }
    return cronParts.join(' ');
};

export const sendData = async (
    values: IFormProps,
    {
        saveConference,
        updateConference,
    }: Pick<IDispatchProps, 'saveConference' | 'updateConference'>,
    conferenceInfo?: IConferenceInfo,
) => {
    const dataToSend = getConferenceDataToSend(values, conferenceInfo);
    let response: IThunkResult<IConferenceInfo | undefined>;
    if (!conferenceInfo) {
        response = await saveConference(dataToSend);
    } else {
        response = await updateConference({ id: conferenceInfo?.id, ...dataToSend });
    }

    return response;
};

export const handleSubmitClick = async (
    values: IFormProps,
    {
        saveConference,
        updateConference,
        goToConferencesList,
        showNotification,
    }: Pick<
        IDispatchProps,
        'saveConference' | 'updateConference' | 'goToConferencesList' | 'showNotification'
    >,
    conferenceInfo?: IConferenceInfo,
    handleAfterSubmit?: () => void,
) => {
    let dateValidateResult;
    if (conferenceInfo && values.recurringData && conferenceInfo.startDateOfRecurrence) {
        if (
            convertDateToStringByTemplate(
                addUsersTimeZoneToDate(
                    convertStringToDateByTemplate(
                        conferenceInfo.startDateOfRecurrence,
                        'yyyy-MM-dd HH:mm:ss.SSSSSS',
                    ),
                ),
                'dd.MM.yyyy',
            ) !== values.recurringData.startDate
        ) {
            dateValidateResult = utils.validateDate(values.startDate, values.startTime);
        }
    } else if (conferenceInfo?.status !== 'STARTED') {
        dateValidateResult = utils.validateDate(values.startDate, values.startTime);
    }
    if (!values.recurringData) {
        if (dateValidateResult?.startDate && dateValidateResult?.startTime) {
            return { startDate: 'Expired', startTime: 'Expired' };
        } else if (dateValidateResult?.startTime) {
            return { startTime: 'Expired' };
        }
    }

    const sendDataResponse = await sendData(
        values,
        {
            saveConference,
            updateConference,
        },
        conferenceInfo,
    );

    if (sendDataResponse.isSuccess) {
        if (conferenceInfo) {
            showNotification({
                text: t({ id: 'The changes were saved successfully' }),
                timeout: 3000,
                type: 'success',
            });
        } else {
            showNotification({
                text: t({ id: 'Conference saved successfully' }),
                timeout: 3000,
                type: 'success',
            });
        }
        if (handleAfterSubmit) {
            handleAfterSubmit();
        } else if (sendDataResponse.data) {
            redirect(`${EPaths.UPCOMING_CONFERENCES}/${sendDataResponse.data.id}`);
        } else {
            goToConferencesList();
        }
    } else {
        const { errorDescription, errorCode } = sendDataResponse;
        if (errorDescription && errorCode) {
            return { [FORM_ERROR]: getResultErrorString({ errorCode, errorDescription }) };
        }
        showNotification(getDefaultErrorNotification());
    }
};

export const validateDate = (startDate: string, startTime: string): { [id: string]: string } => {
    const dateISO = parse(`${startDate} ${startTime}`, 'dd.MM.yyyy HH:mm', getCurrentDate(), {
        locale: ru,
    });

    const errors: { [id: string]: string } = {};

    if (!isValid(dateISO)) {
        errors.startTime = 'Error';
        errors.startDate = 'Error';
        return errors;
    }

    if (utils.dateIsExpired(dateISO)) {
        if (startOfDay(dateISO) < startOfDay(getCurrentDate())) {
            errors.startDate = 'Expired';
            errors.startTime = 'Expired';
        } else {
            errors.startTime = 'Expired';
        }
    }
    return errors;
};

export const validateDateOnChange = (
    startDate: string,
    startTime: string,
): { [id: string]: string } => {
    const dateISO = parse(`${startDate} ${startTime}`, 'dd.MM.yyyy HH:mm', getCurrentDate(), {
        locale: ru,
    });

    const warnings: { [id: string]: string } = {};
    if (differenceInCalendarMonths(dateISO, getCurrentDate()) >= 6) {
        warnings.startDate = t({
            id: 'incorrect.date',
            message: 'Проверьте корректность даты',
        });
    }
    return warnings;
};

const checkTime = (value: string) => {
    if (value.length > 0 && value[value.length - 1].match(/\D/)) {
        return value.length === 1 ? '' : value.slice(0, value.length - 1);
    }

    if (value.length > 2) {
        return value.slice(0, 2);
    }
};

export const checkDurationMinutes = (minutes: string) => {
    if (parseInt(minutes) > 59) {
        return '59';
    }
    const result = checkTime(minutes);
    return result ?? minutes;
};

export const checkDurationHours = (hours: string): string => {
    const result = checkTime(hours);
    return result ?? hours;
};

export const dateIsExpired = (date: Date) => {
    const nowDate = getCurrentDate();
    if (date < nowDate) {
        return true;
    }
    return false;
};

export const validateOnChange = (values: IFormProps) => {
    return utils.validateDateOnChange(values.startDate, values.startTime);
};

export const validate = (values: IFormProps, conferenceInfo: IConferenceInfo | undefined) => {
    const {
        applicationConfiguration: {
            config: { conferenceModelRestrictions },
        },
    } = applicationStore.getState() as TAppState;

    const errors: { [key: string]: string } = {};
    if (values.operation !== 'save') {
        return errors;
    }

    const requiredFieldList = ['topic', 'durationHours', 'durationMinutes', 'timeZone'];

    if (!values.recurringData) {
        requiredFieldList.push(...['startTime', 'startDate']);
    }
    let dateValidateResult;
    if (conferenceInfo && values.recurringData && conferenceInfo.startDateOfRecurrence) {
        if (
            convertDateToStringByTemplate(
                addUsersTimeZoneToDate(
                    convertStringToDateByTemplate(
                        conferenceInfo.startDateOfRecurrence,
                        'yyyy-MM-dd HH:mm:ss.SSSSSS',
                    ),
                ),
                'dd.MM.yyyy',
            ) !== values.recurringData.startDate
        ) {
            dateValidateResult = utils.validateDate(values.startDate, values.startTime);
        }
    } else if (!(conferenceInfo && conferenceInfo.status !== 'SCHEDULED')) {
        dateValidateResult = utils.validateDate(values.startDate, values.startTime);
    }
    if (!values.recurringData) {
        if (dateValidateResult?.startDate) {
            errors.startDate = 'Expired';
        }
        if (dateValidateResult?.startTime) {
            errors.startTime = 'Expired';
        }
    }

    for (const field of requiredFieldList) {
        const f = field as keyof IFormProps;
        if (!values[f] || values[f] === '') {
            errors[f] = 'Required';
        }
        if (f === 'durationMinutes' || f === 'durationHours') {
            if (values['durationMinutes'] === '0' && values['durationHours'] === '0') {
                errors['durationMinutes'] = 'Required';
            }
        }
    }

    if (values.topic && values.topic.length > conferenceModelRestrictions.name) {
        errors.topic = t({
            id: 'scheduleConferenceErrors.conferenceName',
        });
    }

    if (values.description && values.description.length > conferenceModelRestrictions.description) {
        errors.description = t({
            id: 'scheduleConferenceErrors.conferenceDescription',
        });
    }
    return errors;
};

export const tryConvertToDate = (date: string): undefined | Date => {
    const parseDate = parse(`${date}`, 'dd.MM.yyyy', getCurrentDate(), {
        locale: ru,
    });
    return isValid(parseDate) ? parseDate : undefined;
};

export const getDateFormatProps = () => ({
    parse: (value: any) => {
        if (value && typeof value.getMonth === 'function') {
            return format(value, 'dd.MM.yyyy');
        }
    },
});

export const mapStateToProps = (state: TAppState): IStateProps => ({
    currentUser: state.auth.currentUser,
    themeMaxLength: state.applicationConfiguration.config.conferenceModelRestrictions.name,
});
export const mapDispatchToProps = (dispatch: TAppDispatch): IDispatchProps => ({
    saveConference: (conferenceInfo) =>
        dispatch(scheduleConferenceThunkAction(conferenceInfo)).unwrap(),
    goToConferencesList: () => redirect(EPaths.UPCOMING_CONFERENCES),
    updateConference: (conferenceInfo) =>
        dispatch(updateConferenceInfoThunkAction(conferenceInfo)).unwrap(),
    goToHome: () => redirect('/'),
    showNotification: (args) => dispatch(showNotifyThunkAction(args)),
    deleteConference: (id) =>
        dispatch(deleteConferenceFromConferenceSettingsFormMobileThunkAction(id)).unwrap(),
    onModified: (isEdit: boolean) => dispatch(setFormEditFlagAction(isEdit)),
    resetModified: () => dispatch(resetPendingConfirmationAction()),
});
