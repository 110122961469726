import { t } from '@lingui/macro';
import { showAudioDialogPermissionAction } from 'actions/applicationView/showAudioDialogPermissionAction';
import { INSIDE_ATCHAT } from 'constants/janusConstants';
import { LAST_USED_AUDIO_DEVICE, LAST_USED_VIDEO_DEVICE } from 'constants/localStorageKeys';
import { sendOwnAudioVideoPropsToServerThunkAction } from 'thunk/conference/sendOwnAudioVideoPropsToServerThunkAction';
import { showNotifyThunkAction } from 'thunk/notify/showNotifyThunkAction';
import { IAppSettingsTyped } from 'types/app/IAppSettings';
import { TAppState } from 'types/app/TAppStore';
import { IChangeJanusStreamProps } from 'types/janus/IChangeJanusStreamProps';
import { IJanusTrack } from 'types/janus/IJanusTrack';
import { setToLocalStorage } from 'utils/localStorage/setToLocalStorage';
import { janusVideoRoomTracksLogger } from 'utils/logger';
import { setJanusStreamPropsAction } from './../../actions/janus/setJanusStreamPropsAction';
import { wait } from './../../__fixtures__/wait';
import { calculateOfferData2 } from './calculateOfferData2';
import { janusCtx } from './janusCtx';
import { unpublishOwnFeed } from './unpublishOwnFeed';

export const changeJanusStreamProps = async (
    props: Partial<IChangeJanusStreamProps>,
    callback: () => void,
) => {
    if (!janusCtx?.videoRoomPublisherPluginHandler) {
        // Janus not initialized
        callback();
        return;
    }
    const {
        applicationView: {
            showAudioPermissionDialog,
            deviceProps: { isTabletOrMobile },
        },
        janus: { localStreamProps, videoRoomConnected },
        auth: { appSettings },
    } = janusCtx.getState() as TAppState;

    if (!videoRoomConnected) {
        callback();
        return;
    }

    if (props.screenShared && props.useVideo) {
        props.useVideo = false;
    }
    if ((props.useAudio || props.useVideo || props.screenShared) && showAudioPermissionDialog) {
        janusCtx.dispatch(showAudioDialogPermissionAction(false));
    }
    // const { media, streamActions, message } = calculateOfferMedia(
    //     props,
    //     localStreamProps,
    //     appSettings,
    //     janusCtx,
    // );
    const { tracks, streamActions, message, replaceTracks } = await calculateOfferData2(
        props,
        localStreamProps,
        appSettings as IAppSettingsTyped,
        janusCtx,
        isTabletOrMobile,
    );
    janusVideoRoomTracksLogger.debug({ tracks, streamActions, message, replaceTracks });
    if (streamActions.republish) {
        janusVideoRoomTracksLogger.debug('Republish');
        // Apply change to state
        callback();
        // Store audio and video state
        const { useAudio, useVideo } = (janusCtx.getState() as TAppState).janus.localStreamProps;
        // Cause unpublish
        await janusCtx.dispatch(setJanusStreamPropsAction({ useAudio: false, useVideo: false }));
        await wait(500);
        // Cause publish
        janusCtx.dispatch(setJanusStreamPropsAction({ useAudio, useVideo }));
        return;
    }
    if (streamActions.unpublish) {
        const changes: Record<string, { active: boolean; type: 'VIDEO' | 'AUDIO' }> = {};
        const { audio_mid, video_mid } = janusCtx.transceivers;
        if (audio_mid) {
            changes[audio_mid] = { type: 'AUDIO', active: false };
        }
        if (video_mid) {
            changes[video_mid] = { type: 'VIDEO', active: false };
        }
        janusCtx.dispatch(sendOwnAudioVideoPropsToServerThunkAction(changes));
        unpublishOwnFeed();
        janusCtx.published = false;
        janusCtx.transceivers = {};
    }
    if (streamActions.muteAudio) {
        janusCtx.videoRoomPublisherPluginHandler.muteAudio();
    }
    if (streamActions.muteVideo) {
        janusCtx.videoRoomPublisherPluginHandler.muteVideo();
    }
    if (streamActions.unmuteAudio) {
        janusCtx.videoRoomPublisherPluginHandler.unmuteAudio();
    }
    if (streamActions.unmuteVideo) {
        janusCtx.videoRoomPublisherPluginHandler.unmuteVideo();
    }
    // if (streamActions.audio) {
    //     await mute(streamActions.audio.mid, 'audio', streamActions.audio.mute, props.removeMedia);
    // }
    // if (streamActions.video) {
    //     await mute(streamActions.video.mid, 'video', streamActions.video.mute);
    // }
    // if (streamActions.screenSharing) {
    //     try {
    //         const success = await switchScreenSharing(
    //             streamActions.screenSharing.mid,
    //             props.useVideo || localStreamProps.useVideo,
    //         );
    //         if (!success) {
    //             janusCtx.dispatch(showNotifyThunkAction(getDefaultErrorNotification()));
    //             return;
    //         }
    //     } catch (err) {
    //         const error = err as Error;
    //         if (
    //             error?.message !== 'Permission denied' && //chrome
    //             error.message !==
    //                 'The request is not allowed by the user agent or the platform in the current context.' //FF
    //         ) {
    //             printError(error);
    //             return;
    //         }
    //         return;
    //     }
    // }
    // if (streamActions.switchDevice) {
    //     const { deviceId, mid, type } = streamActions.switchDevice;
    //     const success = await switchDevice(mid, type, deviceId);
    //     if (!success) {
    //         LOG_JANUS && log.error('Failed to switch device');
    //         janusCtx.dispatch(showNotifyThunkAction(getDefaultErrorNotification()));
    //         return;
    //     }
    // }
    if (props.videoDevice?.deviceId) {
        setToLocalStorage(LAST_USED_VIDEO_DEVICE, props.videoDevice?.deviceId);
    }
    if (props.audioDevice?.deviceId) {
        setToLocalStorage(LAST_USED_AUDIO_DEVICE, props.audioDevice?.deviceId);
    }

    // LOG_JANUS && log.debug('mediaOffer', media);
    // createOffer(media)
    if (
        replaceTracks.length > 0 ||
        tracks.length > 0 ||
        streamActions.muteAudio ||
        streamActions.muteVideo
    ) {
        const changes: Record<string, { active: boolean; type: 'VIDEO' | 'AUDIO' }> = {};
        const allTracks = [...tracks, ...replaceTracks];
        for (const track of allTracks) {
            if (track.remove && track.mid) {
                changes[track.mid] = {
                    type: track.type === 'audio' ? 'AUDIO' : 'VIDEO',
                    active: false,
                };
            }
            if ((track.add || track.replace) && track.mid) {
                changes[track.mid] = {
                    type: track.type === 'audio' ? 'AUDIO' : 'VIDEO',
                    active: true,
                };
            }
        }
        if (streamActions.muteAudio && janusCtx.transceivers.audio_mid) {
            changes[janusCtx.transceivers.audio_mid] = {
                type: 'AUDIO',
                active: false,
            };
        }
        if (streamActions.muteVideo && janusCtx.transceivers.video_mid) {
            changes[janusCtx.transceivers.video_mid] = {
                type: 'VIDEO',
                active: false,
            };
        }
        janusVideoRoomTracksLogger.debug({ changes });
        janusCtx.dispatch(sendOwnAudioVideoPropsToServerThunkAction(changes));
    }
    if (replaceTracks.length > 0) {
        if (INSIDE_ATCHAT) {
            const senders: RTCRtpSender[] =
                janusCtx.videoRoomPublisherPluginHandler.webrtcStuff.pc.getSenders();
            for (const track of replaceTracks) {
                if (track.capture && typeof track.capture === 'object') {
                    const replaceTrack = track.capture as MediaStreamTrack;
                    senders.forEach((sender) => {
                        if (sender.track?.kind === replaceTrack.kind) {
                            sender.replaceTrack(replaceTrack);
                        }
                    });
                }
            }
        } else {
            janusCtx.videoRoomPublisherPluginHandler.replaceTracks({ tracks: replaceTracks });
        }
    }
    if (tracks.length > 0) {
        janusVideoRoomTracksLogger.debug('Create offer');
        createOfferFromTrack(tracks)
            .then((jsep: any) => {
                callback();
                janusVideoRoomTracksLogger.debug('Created publisher SDP: ', jsep);
                janusCtx.videoRoomPublisherPluginHandler.send({ message, jsep });
                janusCtx.published = true;
            })
            .catch(printError);
        return;
    }
    callback();
};
// const createOffer = (media: IJanusMedia) => {
//     return new Promise((resolve, reject) => {
//         janusCtx.videoRoomPublisherPluginHandler.createOffer({
//             // iceRestart: true,
//             media,
//             success: resolve,
//             error: reject,
//         });
//     });
// };
const createOfferFromTrack = (tracks: IJanusTrack[]) => {
    return new Promise((success, error) => {
        janusCtx.videoRoomPublisherPluginHandler.createOffer({
            iceRestart: true,
            tracks,
            success,
            error,
        });
    });
};
function printError(error: Error) {
    const errorText = error?.message || error;
    janusVideoRoomTracksLogger.error('WebRTC error:', errorText);
    if (!janusCtx.dispatch) {
        // Can be undefined if all is ok
        return;
    }
    janusCtx.dispatch(
        showNotifyThunkAction({
            text: t({
                id: 'webRTCErrorOnMediaChange',
                message: 'Failed to change media. WebRTC error: {0}',
                values: { '0': `${errorText || error}` },
            }),
            type: 'error',
            timeout: 5000,
        }),
    );
}

// const mute = async (
//     mid: string,
//     type: 'video' | 'audio',
//     mute: boolean,
//     removeMedia?: { audio?: boolean; video?: boolean },
// ) => {
//     const webrtcStuff = janusCtx.videoRoomPublisherPluginHandler.webrtcStuff;
//     const {
//         janus: { ownStream },
//     } = janusCtx.getState() as TAppState;
//     if (type === 'audio') {
//         if (mute) {
//             janusCtx.videoRoomPublisherPluginHandler.muteAudio(mid);
//         } else {
//             janusCtx.videoRoomPublisherPluginHandler.unmuteAudio(mid);
//         }
//         janusCtx.dispatch(
//             sendOwnAudioVideoPropsToServerThunkAction({ [mid]: { active: !mute, type: 'AUDIO' } }),
//         );
//         if (removeMedia?.audio) {
//             const streamTracks = ownStream[type]?.stream.getAudioTracks();
//             streamTracks?.forEach((t) => {
//                 webrtcStuff.myStream.removeTrack(t);
//                 t.stop();
//             });
//         }
//         return;
//     }
//     // This for video stream
//     const transceiver = webrtcStuff.pc
//         .getTransceivers()
//         .find((t: RTCRtpTransceiver) => t.mid === mid && t.sender.track?.kind === type);
//     const streamTracks = ownStream[type]?.stream.getVideoTracks();
//     streamTracks?.forEach((t) => {
//         webrtcStuff.myStream.removeTrack(t);
//         t.stop();
//     });

//     if (mute) {
//         // Canvas for video stream
//         const canvas = document.createElement('canvas');
//         const ctx = canvas.getContext('2d');
//         if (ctx) {
//             ctx.fillStyle = 'black';
//             ctx.fillRect(0, 0, 640, 360);
//         }
//         const canvasStream = canvas.captureStream(1);
//         const canvasTrack = canvasStream.getVideoTracks()[0];
//         canvasTrack.enabled = false;
//         transceiver.sender.replaceTrack(canvasTrack), webrtcStuff.myStream.addTrack(canvasTrack);
//         janusCtx.dispatch(
//             setLocalStreamForJanusAction({
//                 stream: canvasStream,
//                 type,
//                 mid,
//             }),
//         );
//         janusCtx.dispatch(
//             sendOwnAudioVideoPropsToServerThunkAction({ [mid]: { active: !mute, type: 'VIDEO' } }),
//         );
//         return;
//     }

//     let settings: { [id: string]: any };
//     const {
//         auth: { appSettings },
//         janus: { localStreamProps },
//     } = janusCtx.getState() as TAppState;
//     settings = calculateWidthAndHeight(
//         appSettings ? appSettings['resolution'] : EVideoResolution.STD_RES,
//     );
//     if (localStreamProps) {
//         settings.deviceId = localStreamProps.videoDevice?.deviceId;
//     }
//     settings = { video: { ...settings } };
//     const stream = await navigator.mediaDevices.getUserMedia(settings);
//     const tracks = stream.getVideoTracks();
//     if (tracks.length) {
//         transceiver.sender.replaceTrack(tracks[0]), webrtcStuff.myStream.addTrack(tracks[0]);
//         janusCtx.dispatch(
//             setLocalStreamForJanusAction({
//                 stream,
//                 type,
//                 mid,
//             }),
//         );
//         janusCtx.dispatch(
//             sendOwnAudioVideoPropsToServerThunkAction({ [mid]: { active: !mute, type: 'VIDEO' } }),
//         );
//     }
// };

// const switchScreenSharing = async (mid: string, useVideo: boolean): Promise<boolean> => {
//     console.log({ switchScreenSharing: { mid, useVideo } });
//     const {
//         janus: {
//             ownStream: { video },
//         },
//     } = janusCtx.getState() as TAppState;
//     const constrains = calculateWidthAndHeight(EVideoResolution.FHD_RES);
//     const stream = await navigator.mediaDevices.getDisplayMedia({
//         video: { ...constrains, frameRate: { max: 10 } },
//     });
//     const tracks = stream.getVideoTracks();
//     if (tracks.length) {
//         const webrtcStuff = janusCtx.videoRoomPublisherPluginHandler.webrtcStuff;
//         const streamTracks = video?.stream.getVideoTracks();
//         streamTracks?.forEach((t) => {
//             webrtcStuff.myStream.removeTrack(t);
//             t.stop();
//         });
//         const transceiver = webrtcStuff.pc
//             .getTransceivers()
//             .find((t: RTCRtpTransceiver) => t.mid === mid && t.sender.track?.kind === 'video');
//         transceiver.sender.replaceTrack(tracks[0]), webrtcStuff.myStream.addTrack(tracks[0]);
//         janusCtx.dispatch(
//             setLocalStreamForJanusAction({
//                 stream,
//                 type: 'video',
//                 mid,
//             }),
//         );
//         if (!useVideo)
//             janusCtx.dispatch(
//                 sendOwnAudioVideoPropsToServerThunkAction({
//                     [mid]: { active: true, type: 'VIDEO' },
//                 }),
//             );
//         return true;
//     }
//     return false;
// };

// const switchDevice = async (mid: string, type: 'audio' | 'video', deviceId: string) => {
//     try {
//         const {
//             auth: { appSettings },
//             janus: {
//                 ownStream: { video, audio },
//             },
//         } = janusCtx.getState() as TAppState;
//         let constrains: { [id: string]: any } = {};
//         let currentStream;
//         if (type === 'video') {
//             constrains = {
//                 video: {
//                     ...calculateWidthAndHeight(
//                         appSettings ? appSettings['resolution'] : EVideoResolution.STD_RES,
//                     ),
//                     deviceId,
//                 },
//             };
//             currentStream = video;
//         } else {
//             constrains = { audio: { deviceId } };
//             currentStream = audio;
//         }
//         const stream = await navigator.mediaDevices.getUserMedia(constrains);
//         const tracks = stream[`get${capitalizeFirstLetter(type) as 'Audio' | 'Video'}Tracks`]();
//         if (tracks.length) {
//             const webrtcStuff = janusCtx.videoRoomPublisherPluginHandler.webrtcStuff;
//             const streamTracks =
//                 currentStream?.stream[
//                     `get${capitalizeFirstLetter(type) as 'Audio' | 'Video'}Tracks`
//                 ]();
//             streamTracks?.forEach((t) => {
//                 t.stop();
//                 webrtcStuff.myStream.removeTrack(t);
//             });
//             const transceiver = webrtcStuff.pc
//                 .getTransceivers()
//                 .find((t: RTCRtpTransceiver) => t.mid === mid && t.sender.track?.kind === type);
//             transceiver.sender.replaceTrack(tracks[0]), webrtcStuff.myStream.addTrack(tracks[0]);
//             janusCtx.dispatch(
//                 setLocalStreamForJanusAction({
//                     stream,
//                     type,
//                     mid,
//                 }),
//             );
//             return true;
//         }
//         return false;
//     } catch {
//         return false;
//     }
// };
