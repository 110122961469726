import { convertDateToStringByTemplate } from './convertDateToStringByTemplate';
import { getCurrentDate } from './getCurrentDate';

const setDateDayOrMonth = ({
    createdDate,
    isMonth = false,
    currentData,
}: {
    createdDate: Date;
    isMonth?: boolean;
    currentData?: string;
}) => {
    if (!createdDate) {
        return;
    }
    const currentDataNumber = Number(currentData);
    if (currentDataNumber) {
        !isMonth
            ? createdDate.setDate(currentDataNumber)
            : createdDate.setMonth(currentDataNumber - 1);
    }
};

export const getSuggestedDate = (currentValue: string) => {
    const valuesSplittedSymbols = currentValue.trim().split(/\W/);
    const datePaths = valuesSplittedSymbols.length;
    const createdDate = getCurrentDate();

    if (datePaths >= 2) {
        setDateDayOrMonth({
            createdDate,
            currentData: valuesSplittedSymbols[1],
            isMonth: true,
        });
    }

    if (datePaths >= 1) {
        setDateDayOrMonth({ createdDate, currentData: valuesSplittedSymbols[0] });
    }


    return convertDateToStringByTemplate(createdDate, 'dd.MM.yyyy');
};

const setTime = ({
    createdDate,
    currentData,
    isMinutes = false,
}: {
    createdDate: Date;
    currentData: string;
    isMinutes?: boolean;
}) => {
    let newTime = 0;

    if (currentData.length === 1) {
        const createdTimePath = isMinutes ? createdDate.getMinutes() : createdDate.getHours();
        if (createdTimePath.toString()[0] === currentData) {
            newTime = createdTimePath;
        } else {
            newTime = Number(`${currentData}0`);
        }
    } else {
        newTime = Number(currentData);
    }

    isMinutes ? createdDate.setMinutes(newTime) : createdDate.setHours(newTime);
};

export const getSuggestedTime = (currentValue: string) => {
    const valuesSplittedSymbols = currentValue.trim().split(/\W/);
    const timePaths = valuesSplittedSymbols.length;
    const createdDate = getCurrentDate();

    if (timePaths >= 1) {
        setTime({ createdDate, currentData: valuesSplittedSymbols[0] });
    }
    if (timePaths >= 2) {
        setTime({
            createdDate,
            currentData: valuesSplittedSymbols[1],
            isMinutes: true,
        });
    }

    return convertDateToStringByTemplate(createdDate, 'HH:mm');
};
