import { plural } from '@lingui/macro';
import { getDurationInSeconds } from 'pages/AccountPage/AccountConferencesPage/ConferenceRecordInfo/utils';
import { convertFromSecondsToHoursMinutesSeconds } from 'utils/convertFromSecondsToHoursMinutesSeconds';

const getConferenceDuration = (timeStart: string, timeEnd: string) => {
    const durationInSeconds = getDurationInSeconds(timeStart, timeEnd, undefined);
    const { hours, minutes } = convertFromSecondsToHoursMinutesSeconds(durationInSeconds);
    return { hours, minutes };
};

export const getConferenceDurationString = (timeStart: string, timeEnd: string) => {
    const { hours, minutes } = getConferenceDuration(timeStart, timeEnd);
    let hoursStr = '';
    let minutesStr = '';
    if (hours) {
        const hoursPlural = plural(hours, {
            one: 'час',
            few: 'часа',
            many: 'часов',
            other: '',
        });
        const formattedHours = hours < 10 ? `0${hours}` : hours;
        hoursStr = `${formattedHours} ${hoursPlural}`;
    }

    const minutesPlural = plural(minutes, {
        one: 'минуту',
        few: 'минуты',
        many: 'минут',
        other: '',
    });
    const formattedMinutes = minutes < 10 ? `0${minutes}` : minutes;
    minutesStr = `${formattedMinutes} ${minutesPlural}`;

    if (hoursStr) {
        return `${hoursStr}:${minutesStr}`
    }
    return minutesStr
};
