import styles from './Button.module.scss';
import { IOwnProps } from './types';

export const Button: React.FC<IOwnProps> = (props) => {
    const {
        type,
        clickHandler,
        className,
        children,
        caption,
        styleType,
        color,
        loading,
        ...restProps
    } = props;
    const cNames = [styles.button, styles[`button_${styleType}`]];
    if (color && styles[`button_${styleType}-${color}`]) {
        cNames.push(styles[`button_${styleType}-${color}`]);
    }
    if (className) {
        cNames.push(className);
    }
    if (loading) {
        cNames.push(styles.button_loading);
    }
    return (
        <button
            className={cNames.join(' ')}
            onClick={clickHandler}
            type={type}
            data-qa={props['data-qa']}
            {...restProps}>
            {children === undefined ? caption : children}
        </button>
    );
};
