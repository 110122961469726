import { INetworkService } from 'types/api/INetworkService';

interface IPayload {
    conferenceId: string;
    participantId: string;
    permission: {
        streamId: string;
        mute: boolean;
        streamType: 'AUDIO' | 'VIDEO';
    };
}

export const setAudioVideoPermissionApiCall = (
    networkService: INetworkService,
    { conferenceId, participantId, permission: data }: IPayload,
) =>
    networkService.put({
        url: `/api/conferences/${conferenceId}/participants/${participantId}/mute`,
        data,
    });
