import React, { useEffect, useRef, useState } from 'react';
import { useLocation } from 'react-router';
import { getEscCloseDropdown } from 'utils/escCloseDropdown';
import { exitConferenceOrShowConfirmation } from 'utils/exitConferenceOrShowConfirmation';
import { ConferenceChatConnected } from './ConferenceChat';
import { ConferenceHeaderConnected } from './ConferenceHeader';
import styles from './ConferencePageTemplate.module.scss';
import { ConferenceParticipantsListConnected } from './ConferenceParticipantsList';
import { ExitConfirmationDialogConnected } from './ExitConfirmationDialog';
import { IDispatchProps, IOwnProps, IStateProps } from './types';
import {
    ConferencePageTemplateContext,
    setHandlersToCheckUserActivity,
    showFullScreenExitNotification,
} from './utils';

export const ConferencePageTemplate: React.FC<IOwnProps & IDispatchProps & IStateProps> = ({
    chatState,
    children,
    conferenceConnectInfo,
    conferenceSessionId,
    exitFromConference,
    handleSettingsClick,
    participantsListOpen,
    priorityFrameId,
    setPriorityFrames,
    showConferenceExitConfirmationDialog,
    showExitConfirmation,
    hideExitConfirmation,
    showNotification,
    showLogo,
    showViewBtn,
}) => {
    const [isUpdating, setIsUpdating] = useState(false);
    const [userInActivity, setUserInActivity] = useState<boolean | null>(null);

    const location = useLocation();

    const priorityFrameIdRef = useRef(priorityFrameId);
    const userInActivityTimerRef = useRef<null | NodeJS.Timeout>(null);

    const exitOrShowConfirmation = exitConferenceOrShowConfirmation(
        exitFromConference,
        showExitConfirmation,
        location.pathname,
        conferenceSessionId === '',
    );

    const onEscClick = (e: KeyboardEvent) => {
        if (e.code === 'Escape' && !getEscCloseDropdown()) {
            if (priorityFrameIdRef.current) {
                setPriorityFrames({
                    global: false,
                    participantIds: [],
                });
                return;
            }
            if (showConferenceExitConfirmationDialog) {
                hideExitConfirmation();
            } else {
                exitOrShowConfirmation();
            }
        }
    };

    const startInActivityTimer = () => {
        userInActivityTimerRef.current = setTimeout(() => {
            setUserInActivity(true);
        }, 3000);
    };

    const resetInActivityTimer = () => {
        userInActivityTimerRef.current && clearTimeout(userInActivityTimerRef.current);
        userInActivityTimerRef.current = null;
    };

    const changeUserInActivity = () => {
        const isUserAlreadyInActive = userInActivity;
        const resetInActivityTimerCondition =
            isUserAlreadyInActive || !priorityFrameId || participantsListOpen || chatState;
        const userIsActiveAndPriorityFrameCondition =
            !userInActivity &&
            userInActivity !== null &&
            !userInActivityTimerRef.current &&
            priorityFrameId;

        if (resetInActivityTimerCondition) {
            resetInActivityTimer();
        } else if (userIsActiveAndPriorityFrameCondition) {
            startInActivityTimer();
        }
    };

    useEffect(() => {
        priorityFrameIdRef.current = priorityFrameId;

        showFullScreenExitNotification({
            isShowNotification: !priorityFrameIdRef.current,
            setUserInActivity: () => setUserInActivity(false),
            showNotification,
        });
    }, [priorityFrameId]);

    useEffect(() => {
        let clearCallback: (() => void) | undefined;

        if (priorityFrameId && !participantsListOpen && !chatState) {
            clearCallback = setHandlersToCheckUserActivity(setUserInActivity);
        } else {
            setUserInActivity(null);
        }
        return () => {
            clearCallback && clearCallback();
        };
    }, [priorityFrameId, participantsListOpen, chatState]);

    useEffect(() => {
        changeUserInActivity();
    }, [userInActivity]);

    useEffect(() => {
        document.addEventListener('keydown', onEscClick);
        return () => document.removeEventListener('keydown', onEscClick);
    }, [conferenceConnectInfo, showConferenceExitConfirmationDialog, exitOrShowConfirmation]);

    const headerVisibility = true;
    if (!conferenceConnectInfo) {
        return null;
    }
    return (
        <div className={styles.page} id='conferencePage'>
            <ConferencePageTemplateContext.Provider
                value={{
                    isUserInactive: !!userInActivity,
                    isUpdating,
                    setUpdatingStatus: setIsUpdating,
                }}>
                <div className={styles.wrapper}>
                    {headerVisibility && (
                        <ConferenceHeaderConnected
                            showLogo={showLogo}
                            showViewBtn={showViewBtn}
                            handleSettingsClick={handleSettingsClick}
                        />
                    )}
                    {children}
                </div>
                {chatState && <ConferenceChatConnected />}
                {participantsListOpen && <ConferenceParticipantsListConnected />}
                {showConferenceExitConfirmationDialog && (
                    <ExitConfirmationDialogConnected className={styles.exitConfirmationDialog} />
                )}
            </ConferencePageTemplateContext.Provider>
        </div>
    );
};
