import React from 'react';
import styles from './Toggle.module.scss';
import { IOwnProps } from './types';
import { HoverLabel } from 'components/common/HoverLabel';
import { classNames } from 'utils/classNames';

export const Toggle: React.FC<IOwnProps> = ({
    value,
    onChangeValue,
    styleType = 'default',
    disabled,
    tooltip,
    activeColor,
    inactiveColor,
    viewMode,
}) => {
    const onClickHandle = () => {
        if (!disabled && onChangeValue && !viewMode) {
            onChangeValue(!value);
        }
    };

    const toggleStyle = classNames([
        styles.toggle,
        styles[`toggle_${styleType}`],
        value ? styles.toggle_active : '',
        inactiveColor && !value ? styles[`toggle_${inactiveColor}`] : '',
        activeColor && value ? styles[`toggle_active${activeColor}`] : '',
        disabled ? styles.toggle_disabled : '',
        viewMode ? styles.toggle_view : '',
    ]);
    const ballStyle = classNames([
        styles.ball,
        styles[`ball_${styleType}`],
        value ? styles[`ball_${styleType}_active`] : '',
    ]);

    return (
        <div className={toggleStyle} onClick={onClickHandle}>
            <div className={ballStyle} />
            {tooltip && (
                <div className={styles.tooltipLabel}>
                    <HoverLabel>{tooltip}</HoverLabel>
                </div>
            )}
        </div>
    );
};
