export interface IText {
    text: string;
    href?: string;
}

export const textToLinks = (text: string) => {
    const newText: IText[] = [];
    const textLen = text.length;
    for (let i = 0; i < textLen;) {
        const startLinkPos = getHrefBeginIdx(text, i);
        if (startLinkPos === -1) {
            newText.push({ text: text.substring(i) });
            return newText;
        }
        if (startLinkPos !== 0) {
            newText.push({ text: text.substring(i, startLinkPos) });
        }
        for (i = startLinkPos; !isSpace(text[i]) && i < textLen; i++);
        const href = text.substring(startLinkPos, i);
        newText.push({ text: href, href: getHref(href) });
    }
    return newText;
};

const protocols = ['http://', 'https://', '//', 'www.'];
const getHrefBeginIdx = (str: string, pos = 0) => {
    str = str.toLocaleLowerCase();
    let idx = -1;
    for (const expr of protocols) {
        const i = str.indexOf(expr, pos);
        if (i !== -1 && (idx === -1 || i < idx)) {
            idx = i;
        }
    }
    return idx;
};

// Alternative logic with if ends on domain - "temporarily" remove this logic
// export const textToLinks = (text: string) => {
//     const newText: IText[] = [];
//     const textLen = text.length;
//     let start = 0;
//     for (let i = 0; i < textLen; i++) {
//         const startLinkPos = getHrefBeginIdx(text, i);
//         if (startLinkPos === -1) {
//             continue;
//         }
//         if (startLinkPos !== 0) {
//             newText.push({ text: text.substring(start, startLinkPos) });
//         }
//         for (i = startLinkPos; !isSpace(text[i]) && i < textLen; i++);
//         const href = text.substring(startLinkPos, i);
//         newText.push({ text: href, href: getHref(href) });
//         start = i;
//     }
//     if (start !== textLen - 1) {
//         newText.push({ text: text.substring(start) });
//     }
//     return newText;
// };
//
// const protocols = ['http://', 'https://', '//', 'www.'];
// const counriies = ['.com', '.ru', '.net', '.org', '.by', '.kz', '.by', '.uk', '.ua'];
// const getHrefBeginIdx = (str: string, pos = 0) => {
//     str = str.toLocaleLowerCase();
//     for (const expr of protocols) {
//         if (str.substr(pos, expr.length) === expr) {
//             return pos;
//         }
//     }
//     for (const country of counriies) {
//         if (str.substr(pos, country.length) === country && !isSpace(str[pos - 1])) {
//             for (let i = pos - 1; i > -1; i--) {
//                 if (isSpace(str[i])) {
//                     return i + 1;
//                 }
//             }
//         }
//     }
//     return -1;
// };

const getHref = (href: string) =>
    href.toLocaleLowerCase().substring(0, 4) === 'http' || href.substring(0, 2) === '//'
        ? href
        : '//' + href;
const spaceRe = /\s+/;
export const isSpace = (c: string) => spaceRe.test(c);
