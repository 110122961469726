import { getUserThunkAction } from 'admin-app/thunk/getUserThunkAction';
import { connect } from 'react-redux';
import { TAppDispatch } from 'types/app/TAppDispatch';
import { TAppState } from 'types/app/TAppStore';
import { IDispatchProps, IStateProps } from './types';
import { UserPage } from './UserPage';
import { editUserRolesThunkAction } from 'admin-app/thunk/editUserRolesThunkAction';


const mapDispatchToProps = (dispatch: TAppDispatch): IDispatchProps => ({
    getUserData: () => dispatch(getUserThunkAction()).unwrap(),
    editUserRoles: (data) => dispatch(editUserRolesThunkAction(data)).unwrap(),
});

export const UserPageConnected = connect(null, mapDispatchToProps)(UserPage);
