import { Trans } from '@lingui/react';
import { CircleLoader } from 'components/common/CircleLoader';
import { DateFilterType2Connected } from 'components/common/DateFilterType2';
import { PlanConferenceButtonConnected } from 'components/common/PlanConferenceButton';
import { useConferenceDeleteDialog } from 'hooks/useConferenceDeleteDialog';
import { useConferenceEditDialog } from 'hooks/useConferenceEditDialog';
import { useConferenceShareDialog } from 'hooks/useConferenceShareDialog';
import { useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import { IConferenceRecordsInfo } from 'types/conferencesList/IConferenceRecordsInfo';
import { IPastConferenceInfo } from 'types/conferencesList/IPastConferenceInfo';
import { IUpcomingConferenceInfo } from 'types/conferencesList/IUpcomingConferenceInfo';
import styles from './AccountConferencesPage.module.scss';
import { PastConferencesResponsive } from './ConferencesList/PastConferences';
import { UpcomingConferences } from './ConferencesList/UpcomingConferences/UpcomingConferences';
import { UpcomingConferencesEmpty } from './ConferencesList/UpcomingConferences/UpcomingConferencesEmpty';
import {
    EConferencesGroup,
    IDispatchProps,
    IOwnProps,
    IStateProps,
    TGroupContainerItem,
} from './types';
import { getGroupedConferences, getGroupTitle } from './utils';

export const AccountConferencesPage: React.FC<IStateProps & IDispatchProps & IOwnProps> = ({
    changeFilter,
    clearScrollOffset,
    conferenceIdsToShow,
    containerRef,
    currentUser,
    deleteConference,
    filters,
    goToConferenceHistory,
    goToEditConference,
    pastConferences,
    pastConferencesListOperationPending,
    prepareToConnectToConference,
    recordsList,
    reset,
    scrollOffset,
    upcomingConferences,
    upcomingConferencesListOperationPending,
}) => {
    const isContainerHasScroll =
        containerRef.current?.scrollHeight !== containerRef.current?.clientHeight;
    const [editConferenceHandler, editConferenceConfirmDialogRender] = useConferenceEditDialog(
        currentUser,
        goToEditConference,
    );
    const [deleteConferenceHandler, deleteConferenceConfirmDialogRender] =
        useConferenceDeleteDialog(currentUser, deleteConference);

    const [conferenceShareDialogHandler, conferenceShareDialog] = useConferenceShareDialog();

    useEffect(() => {
        if (scrollOffset && isContainerHasScroll) {
            containerRef.current?.scrollTo({ top: scrollOffset, behavior: 'smooth' });
            clearScrollOffset();
        }
    });

    const location = useLocation();
    const splittedPath = location.pathname.split('/');
    const pathLastPart = splittedPath[splittedPath.length - 1];

    useEffect(() => {
        changeFilter({
            past: filters.past,
            upcoming: filters.upcoming,
            finished: pathLastPart == 'past' ? true : false,
        });
        return () => reset();
    }, []);
    let groupRender: (group: any, isTodayConferences: boolean) => JSX.Element;
    if (filters.finished) {
        groupRender = (group: TGroupContainerItem) => (
            <PastConferencesResponsive
                conferences={group.conferences as (IPastConferenceInfo & IConferenceRecordsInfo)[]}
                currentUser={currentUser}
                scrollableWrapper={containerRef}
                goToConferenceHistory={goToConferenceHistory}
                goToConnectConference={prepareToConnectToConference}
            />
        );
    } else {
        groupRender = (group: TGroupContainerItem, isTodayConferences: boolean) => (
            <UpcomingConferences
                conferences={group.conferences as IUpcomingConferenceInfo[]}
                currentUser={currentUser}
                isTodayConferences={isTodayConferences}
                goToConnectConference={prepareToConnectToConference}
                editConferenceHandler={editConferenceHandler}
                deleteConferenceHandler={deleteConferenceHandler}
                shareConferenceHandler={conferenceShareDialogHandler}
                key={group.key}
            />
        );
    }
    const renderContent = () => {
        if (
            (filters.finished && pastConferencesListOperationPending) ||
            (!filters.finished && upcomingConferencesListOperationPending)
        ) {
            return <CircleLoader color='blue' />;
        }
        if (!filters.finished && !Object.keys(upcomingConferences).length) {
            return <UpcomingConferencesEmpty />;
        }
        return (
            <div className={styles.conferences}>
                {getGroupedConferences(
                    pastConferences,
                    upcomingConferences,
                    filters.finished,
                    recordsList,
                    conferenceIdsToShow,
                ).map(
                    (group: TGroupContainerItem) =>
                        group.conferences.length !== 0 && (
                            <div className={styles.group} key={group.key}>
                                <div className={styles.group__date}>{getGroupTitle(group)}</div>
                                {groupRender(group, group.key === EConferencesGroup.Today)}
                            </div>
                        ),
                )}
            </div>
        );
    };
    return (
        <>
            {editConferenceConfirmDialogRender() ||
                deleteConferenceConfirmDialogRender() ||
                conferenceShareDialog}
            <div className={styles.header}>
                <span>
                    <Trans id='Conferences' message='Конференции' />
                </span>
                <PlanConferenceButtonConnected />
            </div>
            <div className={styles.filters}>
                <div
                    className={`${styles.filters__item} ${
                        !filters.finished ? styles.filters__item_active : ''
                    }`}
                    onClick={() => changeFilter({ finished: false })}>
                    <Trans id='Upcoming' message='Предстоящие' />
                </div>
                <div
                    className={`${styles.filters__item} ${
                        filters.finished ? styles.filters__item_active : ''
                    }`}
                    onClick={() => changeFilter({ finished: true })}>
                    <Trans id='Past' message='Прошедшие' />
                </div>
            </div>
            <DateFilterType2Connected
                className={styles.dates}
                key={filters.finished ? 'past' : 'upcoming'}
            />
            {renderContent()}
        </>
    );
};
