import { setJanusStreamPropsAction } from 'actions/janus/setJanusStreamPropsAction';
import { setParticipantMuteStatusAction } from 'actions/janus/setParticipantMuteStatusAction';
import { TAppState } from 'types/app/TAppStore';
import { janusCtx } from './janusCtx';

export const applyAudioVideoPermissionFromModerator = ({
    id: participantId,
    mid,
    moderation: status,
}: {
    id: string;
    mid: string;
    moderation: 'muted' | 'unmuted';
}) => {
    const {
        janus: {
            publishers,
            localStreamProps,
            ownStream: { audio, video },
            streams,
        },
    } = janusCtx.getState() as TAppState;
    if (participantId === janusCtx.myid) {
        if (video?.mid === mid) {
            if (status === 'muted' && localStreamProps.useVideo) {
                janusCtx.dispatch(setJanusStreamPropsAction({ useVideo: false }));
                delete janusCtx.transceivers.video_mid;
            }
        } else if (audio?.mid === mid) {
            if (status === 'muted' && localStreamProps.useAudio) {
                janusCtx.dispatch(setJanusStreamPropsAction({ useAudio: false }));
                delete janusCtx.transceivers.video_mid;
            }
        }
    } else {
        const publisher = publishers[participantId];
        const stream = streams[participantId];
        if (publisher && stream) {
            const videoPermitted =
                stream.video?.mid === mid
                    ? status === 'unmuted'
                    : publisher.audioVideoProps.videoPermitted;
            const audioPermitted =
                stream.audio?.mid === mid
                    ? status === 'unmuted'
                    : publisher.audioVideoProps.audioPermitted;
            janusCtx.dispatch(
                setParticipantMuteStatusAction({
                    participantId,
                    audioVideoProps: { audioPermitted, videoPermitted },
                }),
            );
        }
    }
};
