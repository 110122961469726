import { CircleLoader } from 'components/common/CircleLoader';
import { useRefState } from 'hooks/useRefState';
import React from 'react';
import { IStreamProps } from 'types/IStreamProps';
import { extractVideoStreamProps } from 'utils/extractVideoStreamProps';
import { logger } from 'utils/logger';
import {
    registerMediaStreamStartCallback,
    runMediaStreamStartCallbacks,
} from 'utils/media/mediaStreamStartCallbacks';
import styles from './ParticipantMediaContent.module.scss';
import { StreamInfoHood } from './StreamInfoHood';
import { IOwnProps } from './types';

export const ParticipantMediaContent: React.FC<IOwnProps> = ({
    audioLevel,
    // audioStream,
    changePriorityFrame,
    // currentParticipantId,
    displayUsersFrameInfo,
    // displayedVideoFrames,
    // muted,
    notifyFrameSize,
    participantId,
    videoStream,
}) => {
    const videoRef = React.useRef<any>();
    const videoStreamRef = React.useRef<MediaStream>();
    const timerRef = React.useRef<NodeJS.Timeout>();
    const [videoStreamProps, videoStreamPropsRef, setVideoStreamProps] = useRefState<
        IStreamProps['video'] | undefined
    >(undefined);

    const updateData = () => {
        if (timerRef.current) {
            clearTimeout(timerRef.current);
            timerRef.current = undefined;
        }
        if (videoStream && videoRef.current) {
            const props = extractVideoStreamProps(videoStream);
            const propsIsChanged =
                props.video.width !== videoStreamPropsRef.current?.width ||
                props.video.height !== videoStreamPropsRef.current?.height;
            if (notifyFrameSize && props.video.width && props.video.height && propsIsChanged) {
                notifyFrameSize({
                    id: participantId,
                    width: props.video.width,
                    height: props.video.height,
                });
            }
            setVideoStreamProps(props.video);
            timerRef.current = setTimeout(updateData, 5000);
        }
    };

    React.useEffect(() => {
        videoStreamRef.current = videoStream;
        if (videoStream) {
            const vidElement = videoRef.current;
            vidElement.muted = true;
            if (vidElement) {
                vidElement.srcObject = videoStream;
                vidElement.onloadedmetadata = () => {
                    vidElement
                        .play()
                        .then(() => {
                            runMediaStreamStartCallbacks();
                        })
                        .catch((error: any) => {
                            logger.debug({ e: error });
                            registerMediaStreamStartCallback({
                                participantId,
                                type: 'video',
                                callback: () => vidElement.play(),
                            });
                        });
                    updateData();
                };
            }
        }
    }, [videoStream]);

    React.useEffect(() => {
        return () => {
            if (timerRef.current) {
                clearTimeout(timerRef.current);
                timerRef.current = undefined;
            }
        };
    }, []);

    const showStreamProps = videoStreamProps && displayUsersFrameInfo;

    const videoActive = videoStream?.active;
    const videoMuted = videoStream?.getVideoTracks()[0].muted;
    const showVideoProgress = videoStream && (!videoActive || videoMuted);
    return (
        <div className={styles.wrapper}>
            {videoStream && (
                <video playsInline autoPlay ref={videoRef} onDoubleClick={changePriorityFrame} />
            )}
            {showVideoProgress && (
                <div className={styles.progress}>
                    <CircleLoader color={'white'} />
                </div>
            )}
            {showStreamProps && (
                <StreamInfoHood videoStreamInfo={videoStreamProps} audioLevel={audioLevel} />
            )}
            {/* {currentParticipantId !== participantId && (
                <ParticipantAudioContentManagedConnected
                    participantId={participantId}
                    audioStream={audioStream}
                    muted={muted}
                    displayedFrames={displayedVideoFrames}
                />
            )} */}
        </div>
    );
};
