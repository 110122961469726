import { INetworkService } from 'types/api/INetworkService';

interface IPayload {
    id: string;
    pin: string;
    name?: string;
    recorderId?: string;
}
export const joinConferenceApiCall = (
    networkService: INetworkService,
    { id, pin, name, recorderId }: IPayload,
) =>
    networkService.post({
        url: `/api/conferences/${id}/join`,
        data: {
            pin,
            id,
            name,
            recorderId,
        },
    });
