import { Trans } from '@lingui/macro';
import { Cross } from 'components/common/Cross';
import { FullScreenPopup } from 'components/common/FullScreenPopup';
import { useState } from 'react';
import styles from './NewUserModal.module.scss';
import { NewUserModalInvite } from './NewUserModalInvite';
import { NewUserModalNav } from './NewUserModalNav';
import { IOwnProps, TModalNavTab } from './types';
import { NewUserModalContext } from './utils';

export const NewUserModal: React.FC<IOwnProps> = ({ closeModal }) => {
    const [activeTab, setActiveTab] = useState<TModalNavTab>('invite');

    return (
        <NewUserModalContext.Provider value={{ closeModal }}>
            <FullScreenPopup closePopup={closeModal}>
                <div className={styles.modal}>
                    <div className={styles.modal__header}>
                        <span>
                            <Trans id='admin.newUser' />
                        </span>
                        <Cross onClick={closeModal} />
                    </div>
                    <NewUserModalNav changeTab={setActiveTab} activeTab={activeTab} />
                    <div className={styles.modal__body}>
                        {/* {activeTab === 'create' && (
                            <CreateUserForm className={styles.modal__form} />
                        )} */}
                        {activeTab === 'invite' && <NewUserModalInvite />}
                    </div>
                </div>
            </FullScreenPopup>
        </NewUserModalContext.Provider>
    );
};
