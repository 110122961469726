import { createAsyncThunk } from '@reduxjs/toolkit';
import { setApplicationLicenseAction } from 'actions/auth/setApplicationLicenseAction';
import { getLicenseApiCall } from 'api/authorization/getLicenseApiCall';
import { getNetworkService } from 'api/getNetworkService';

export const getApplicationLicenseThunkAction = createAsyncThunk(
    'getApplicationLicense',
    async (_, { dispatch }) => {
        const response = await getLicenseApiCall(getNetworkService());
        if (response.success) {
            const settings = response.data;
            dispatch(setApplicationLicenseAction(settings));
            return true;
        }
    },
);
