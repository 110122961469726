import { createAsyncThunk } from '@reduxjs/toolkit';
import { appHistory } from 'appHistory';
import { TAppState } from 'types/app/TAppStore';

export const planNewConferenceThunkAction = createAsyncThunk(
    'painNewConference',
    async (_: void, { getState }) => {
        const {
            auth: { currentUser, appSettings, anonymous },
        } = getState() as TAppState;
        if (anonymous) {
            appHistory.push('/login?action=planNewConference');
            return;
        }
        if (currentUser && appSettings) {
            appHistory.push('/account/conferences/scheduling');
        }
    },
);
