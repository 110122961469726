import styles from './ConferenceSettingsBlock.module.scss';
import { IOwnProps } from './types';

export const ConferenceSettingsBlock: React.FC<IOwnProps> = ({
    children,
    text,
    className,
    classNameFields,
    textClassName,
    isOnSmallScreen,
    isComplexField,
}) => {
    return (
        <div
            className={` ${className ? className : ''} ${styles.block} ${
                isOnSmallScreen ? styles.block_smallScreen : ''
            }`}>
            <div className={`${styles.text} ${textClassName ? styles[textClassName] : ''}`}>
                {text}
            </div>
            <div
                className={`${styles.fields} ${isComplexField ? styles.fields_complex : ''} ${
                    classNameFields ? classNameFields : ''
                } `}>
                {children}
            </div>
        </div>
    );
};
