import { i18n } from '@lingui/core';
import { t } from '@lingui/macro';
import { FormApi } from 'final-form';
import { Form, FormRenderProps } from 'react-final-form';
import { getDefaultErrorNotification } from 'utils/getDefaultErrorNotification';
import styles from './DynamicForm.module.scss';
import { DynamicFormCheckbox } from './DynamicFormCheckbox/DynamicFormCheckbox';
import { DynamicFormFooter } from './DynamicFormFooter/DynamicFormFooter';
import { DynamicFormInput } from './DynamicFormInput/DynamicFormInput';
import { DynamicFormSection } from './DynamicFormSection';
import { DynamicFormSelect } from './DynamicFormSelect/DynamicFormSelect';
import { IDispatchProps, IFormRenderProps, IOwnProps } from './types';

export const DynamicForm: React.FC<IOwnProps & IDispatchProps> = ({
    structure,
    saveSettings,
    initialValues,
    showNotification,
    onModified,
    isDefaultForm,
    updateAppSettings,
}) => {
    const renderSections = () =>
        structure.map(({ sectionTitle, fields }, index) => (
            <DynamicFormSection sectionTitle={sectionTitle ? i18n._(sectionTitle) : ''} key={index}>
                {fields.map(
                    ({ type, name, title, description, values, displayValues, placeholder }) => {
                        const resultDescription = description ? i18n._(description) : '';
                        if (type === 'checkbox') {
                            return (
                                <DynamicFormCheckbox
                                    name={name}
                                    title={i18n._(title)}
                                    description={resultDescription}
                                    key={name}
                                />
                            );
                        }
                        if (type === 'combo') {
                            const displayFilteredHeader =
                                displayValues && typeof displayValues[0] === 'object'
                                    ? true
                                    : false;
                            const items = values?.map((value, idx) => {
                                let resultDisplayValue;
                                if (displayValues) {
                                    const currentDisplayValue = displayValues[idx];
                                    resultDisplayValue =
                                        typeof currentDisplayValue === 'object'
                                            ? i18n._(currentDisplayValue)
                                            : currentDisplayValue;
                                }
                                return { value, displayValue: resultDisplayValue };
                            });
                            return (
                                <DynamicFormSelect
                                    name={name}
                                    title={i18n._(title)}
                                    description={resultDescription}
                                    items={items}
                                    key={name}
                                    displayFilteredHeader={displayFilteredHeader}
                                />
                            );
                        }
                        if (type === 'text') {
                            const resultPlaceholder = placeholder ? i18n._(placeholder) : '';
                            return (
                                <DynamicFormInput
                                    name={name}
                                    title={i18n._(title)}
                                    placeholder={resultPlaceholder}
                                    key={name}
                                    autoFocus={index === 0}
                                />
                            );
                        }
                    },
                )}
            </DynamicFormSection>
        ));

    const onSubmit = async (values: IFormRenderProps, form: FormApi) => {
        const success = await saveSettings(values);
        if (success) {
            isDefaultForm &&
                showNotification({
                    text: t({
                        message: 'Изменения успешно сохранены',
                        id: 'The changes were saved successfully',
                    }),
                    type: 'success',
                });
            updateAppSettings(values);
            form.initialize(values);
        } else {
            showNotification(getDefaultErrorNotification());
            !isDefaultForm && form.reset();
        }
    };

    const render = ({
        handleSubmit,
        form,
        dirty,
        submitting,
        pristine,
    }: FormRenderProps<IFormRenderProps>) => {
        if (isDefaultForm) {
            setTimeout(() => onModified(dirty), 0);
        }
        if (!isDefaultForm && dirty && !submitting) {
            form.submit();
        }
        return (
            <form
                onSubmit={handleSubmit}
                className={`${styles.form} ${submitting ? styles.form_disabled : ''}`}>
                {renderSections()}
                {isDefaultForm && (
                    <DynamicFormFooter
                        submitting={submitting}
                        pristine={pristine}
                        onResetClick={() => {
                            form.restart();
                        }}
                    />
                )}
            </form>
        );
    };
    return <Form onSubmit={onSubmit} render={render} initialValues={initialValues || undefined} />;
};
