import { connect } from 'react-redux';
import { getConferenceChatMediaFileThunkAction } from 'thunk/chat/getConferenceChatMediaFile';
import { TAppDispatch } from 'types/app/TAppDispatch';
import { ImageViewPopup } from './ImageViewPopup';
import { IDispatchProps } from './types';

const mapDispatchToProps = (dispatch: TAppDispatch): IDispatchProps => ({
    getImage: (id) => dispatch(getConferenceChatMediaFileThunkAction(id)).unwrap(),
});

export const ImageViewPopupConnected = connect(null, mapDispatchToProps)(ImageViewPopup);
