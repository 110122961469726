import { IOwnProps } from './types';
import styles from './ConferenceShareDialogSection.module.scss';

export const ConferenceShareDialogSection: React.FC<IOwnProps> = ({
    title,
    description,
    children,
}) => (
    <div className={styles.shareDialog__section}>
        <div className={styles.shareDialog__sectionHeader}>
            <h3 className={styles.shareDialog__sectionTitle}>{title}</h3>
            <span className={styles.shareDialog__sectionDescription}>{description}</span>
        </div>
        <div className={styles.shareDialog__sectionBody}>{children}</div>
    </div>
);
