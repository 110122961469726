import { createReducer } from '@reduxjs/toolkit';
import { resetStoreAction } from 'actions/resetStoreAction';
import { resetFoundUsersAction } from 'actions/schedulingConference/resetFoundUsersAction';
import { setFoundUsersAction } from 'actions/schedulingConference/setFoundUsersAction';
import { setInvitedUserAction } from 'actions/schedulingConference/setInvitedUserAction';
import { IUserLdapData } from 'types/user/IUserLdapData';

export interface ISchedulingConferenceReducer {
    ldSearchUsers: IUserLdapData[];
    invitedUsers: IUserLdapData[];
}

const schedulingConferenceInitialState: ISchedulingConferenceReducer = {
    ldSearchUsers: [],
    invitedUsers: [],
};

export const schedulingConferenceReducer = createReducer(
    schedulingConferenceInitialState,
    (builder) =>
        builder
            .addCase(resetFoundUsersAction, (state) => {
                state.invitedUsers = [];
            })
            .addCase(setInvitedUserAction, (state, { payload: invitedUserData }) => {
                state.invitedUsers = [...invitedUserData];
            })
            .addCase(setFoundUsersAction, (state, { payload: users }) => {
                state.ldSearchUsers = [...users];
            })
            .addCase(resetStoreAction, () => schedulingConferenceInitialState),
);
