import { format } from 'date-fns';
import { dateTemplate } from 'pages/AccountPage/AccountConferencesPage/types';
import { ConferenceUsersConnected } from '../../ConferenceUsers';
import { UpcomingConferenceInfo } from './UpcomingConferenceInfo';
import { UpcomingConferenceKebabButton } from './UpcomingConferenceKebabButton';
import { UpcomingConferenceMainButton } from './UpcomingConferenceMainButton';
import { UpcomingConferenceTime } from './UpcomingConferenceTime';
import { IOwnProps } from './types';
import styles from './UpcomingConference.module.scss';
import { redirect } from 'utils/redirect';
import { EPaths } from 'constants/EPaths';

export const UpcomingConference: React.FC<IOwnProps> = ({
    conference,
    started,
    scheduled,
    userIsOwner,
    isTodayConferences,
    goToConnectConference,
    shareConferenceHandler,
    editConferenceHandler,
    deleteConferenceHandler,
}) => {
    const conferenceId = conference.seriesId || conference.id;
    const goToDetailsPage = () => redirect(`${EPaths.UPCOMING_CONFERENCES}/${conferenceId}`);

    return (
        <div
            className={`${styles.conference} ${started ? styles.conference_started : ''}`}
            key={conference.id}
            id={conference.id}>
            <UpcomingConferenceTime
                isConferenceStarted={started}
                isTodayConferences={isTodayConferences}
                conferenceActualTimeStart={conference?.startedAt}
                conferenceScheduleTimeStart={conference.timeStart}
                conferenceDuration={conference.duration}
            />
            <UpcomingConferenceInfo
                day={format(new Date(conference.timeStart), dateTemplate)}
                conferenceName={conference.name}
                conferenceDescription={conference.description}
                isRecurringConference={!!conference.seriesId}
                conferenceDetailsHandler={goToDetailsPage}
            />
            {conference.invitedUsers && (
                <ConferenceUsersConnected
                    usersData={{ emails: conference.invitedUsers }}
                    isDesktop={true}
                />
            )}
            {/* For space in the grid*/}
            {!conference.invitedUsers && <div></div>}

            <UpcomingConferenceMainButton
                goToConnectConference={goToConnectConference}
                isConferenceStarted={started}
                isConferenceScheduled={scheduled}
                isOwner={userIsOwner}
                conferenceId={conferenceId}
            />
            <UpcomingConferenceKebabButton
                isConferenceStarted={started}
                isConferenceScheduled={scheduled}
                isOwner={userIsOwner}
                conferenceId={conferenceId}
                shareConferenceHandler={() => shareConferenceHandler(conference)}
                editConferenceHandler={() => editConferenceHandler(conference)}
                deleteConferenceHandler={() => deleteConferenceHandler(conference)}
                conferenceDetailsHandler={goToDetailsPage}
            />
        </div>
    );
};
