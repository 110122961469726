import { Trans } from '@lingui/react';
import { Button } from 'components/common/Button';
import { LobbyLoader } from 'pages/LobbyPage/LobbyLoader/LobbyLoader';
import React, { useEffect } from 'react';
import { ConferencePageTemplateConnected } from '../templates/ConferencePageTemplate';
import { AccessDeniedDialogConnected } from './AccessDeniedDialog';
import styles from './LobbyPage.module.scss';
import { IDispatchProps, IStateProps } from './types';
import {redirect} from '../../utils/redirect';

export const LobbyPage: React.FC<IStateProps & IDispatchProps> = ({
    conferenceConnectInfo,
    confId,
    responseToConferenceJoinRequest,
    goToConferencePage,
    goToUpcomingConferences,
    leaveFromLobby,
    isAnonymous,
}) => {
    useEffect(() => {
        if (!conferenceConnectInfo) {
            goToUpcomingConferences();
        }
        const leaveHandler = () => {
            if (responseToConferenceJoinRequest === null) {
                leaveFromLobby();
            }
        };
        window.addEventListener('popstate', leaveHandler);
        return () => window.removeEventListener('popstate', leaveHandler);
    }, []);

    useEffect(() => {
        if (responseToConferenceJoinRequest === null) return;
        if (responseToConferenceJoinRequest) {
            goToConferencePage(confId);
        }
    }, [responseToConferenceJoinRequest]);

    const handleCancelClick = () => {
        leaveFromLobby();
        if (isAnonymous) {
            redirect('/');
        } else {
            goToUpcomingConferences();
        }

    };

    return (
        <ConferencePageTemplateConnected
            conferenceConnectInfo={conferenceConnectInfo}
            showLogo={true}>
            <div className={styles.lobby}>
                {responseToConferenceJoinRequest === null && <LobbyLoader />}
                {responseToConferenceJoinRequest === false && <AccessDeniedDialogConnected />}
                <Button
                    styleType='primaryActionNew'
                    className={styles.lobby__cancelBtn}
                    clickHandler={handleCancelClick}>
                    <Trans id='Cancel' />
                </Button>
            </div>
        </ConferencePageTemplateConnected>
    );
};
