import { IOwnProps } from './types';
import styles from './ConferenceOwnerInfo.module.scss';
import { Avatar } from '../Avatar';
import { getUserColor } from 'utils/users/getUserColor';

export const ConferenceOwnerInfo: React.FC<IOwnProps> = ({ anonymous, owner }) => (
    <div className={styles.owner}>
        <Avatar
            image={!anonymous ? owner.avatar : undefined}
            initials={owner.initials}
            userBgColor={getUserColor(owner.fullName)}></Avatar>
        <div className={styles.info}>
            <div className={styles.fullName}>{owner.fullName}</div>
            <div className={styles.email}>{owner.email}</div>
        </div>
    </div>
);
