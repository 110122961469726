import { TAppDispatch } from 'types/app/TAppDispatch';
import { IDispatchProps, IFields, IOwnProps, IValues } from './types';
import { submitPinEnterFormThunkActon } from 'thunk/conference/submitPinEnterFormThunkActon';
import { t } from '@lingui/macro';

export const mapDispatchToProps = (dispatch: TAppDispatch): IDispatchProps => ({
    submitFormData: (data) => dispatch(submitPinEnterFormThunkActon(data)),
});
export function getSubmitHandler({
    submitFormData,
    successSubmitCallback,
}: IOwnProps & IDispatchProps) {
    return async (values: IValues) => {
        const { payload: error } = await submitFormData({
            values,
            successSubmitCallback,
        });
        return error;
    };
}
export function handleValidate(values: IValues) {
    const errors: IFields = {};
    if (values.pin === '' || !values.pin) {
        errors.pin = t({ id: 'RequiredField' });
    }
    return errors;
}
