import { t } from '@lingui/macro';
import { Trans } from '@lingui/react';
import { LinkWithPendingActionCheckConnected } from 'components/common/LinkWithPendingActionCheck';
import { EPaths } from 'constants/EPaths';
import { format } from 'date-fns';
import { useRef } from 'react';
import { Route, useLocation } from 'react-router-dom';
import { PageTemplate } from '../templates/PageTemplate';
import { AccountConferencePageResponsive } from './AccountConferencesPage';
import { AccountConferenceDetailsPageResponsive } from './AccountConferencesPage/AccountConferenceDetailsPage';
import { AccountInformationPageResponsive } from './AccountInformationPage';
import styles from './AccountPage.module.scss';
import { AccountSettingsPageResponsive } from './AccountSettingsPage';
import { ConferenceSchedulingPageConnected } from './ConferenceSchedulingPage/ConferenceSchedulingPageConnected';
import { ConferencesRecordsPageConnected } from './ConferencesRecordsPage';
import { IDispatchProps, IStateProps } from './types';
import { getDesktopSections } from './utils';
import { HorizontalLoader } from 'components/common/HorizontalLoader';

export const AccountPage: React.FC<IDispatchProps & IStateProps> = ({
    logout,
    conferenceListRouteToPast,
    licenseName,
    validDate,
    version,
    rememberMeRequestStatus,
}) => {
    const contentRef = useRef<HTMLDivElement>(null);
    const location = useLocation();
    const splittedPath = location.pathname.split('/');
    const pathThisLevel = splittedPath[2];

    const itemRender = getDesktopSections().map((item, idx) => {
        let id = '';
        let thisIsActive = false;
        if (item.route instanceof Array) {
            id = item.route[0].substr(0, item.route[0].indexOf('/'));
            item.route.forEach((route) => {
                const splittedRoute = route.split('/');
                if (pathThisLevel === splittedRoute[0]) {
                    thisIsActive = true;
                }
            });
        } else {
            id = item.route;
            if (pathThisLevel === item.route) {
                thisIsActive = true;
            }
        }
        const cName = [styles.menuItem, styles['menuItem_' + id.toLocaleLowerCase()]];
        if (thisIsActive) {
            cName.push(styles.selected);
        }
        const qa =
            item.route !== 'Exit' ? `account${id[0].toLocaleUpperCase() + id.substr(1)}` : 'exit';
        if (item.route != 'Exit') {
            const route =
                item.route instanceof Array
                    ? item.route[conferenceListRouteToPast ? 1 : 0]
                    : item.route;
            return (
                <LinkWithPendingActionCheckConnected
                    className={cName.join(' ')}
                    key={idx}
                    to={`/account/${route}`}
                    data-qa={qa}>
                    <span className={styles.menuItemText}>{item.name}</span>
                </LinkWithPendingActionCheckConnected>
            );
        }
        return (
            <LinkWithPendingActionCheckConnected
                className={cName.join(' ')}
                key={idx}
                onClick={logout}
                data-qa={qa}>
                <span className={styles.menuItemText}>{item.name}</span>
            </LinkWithPendingActionCheckConnected>
        );
    });
    return (
        <PageTemplate
            thisIsHomePage={false}
            showBcgImg={false}
            showBcgGradient={false}
            className={styles.noScroll}>
            <div className={styles.wrapper}>
                {rememberMeRequestStatus === 'error' || rememberMeRequestStatus === 'success' ? (
                    <>
                        <div className={styles.sidebar}>
                            <div className={styles.header}>
                                <Trans id='ACCOUNT' message='АККАУНТ' />
                            </div>
                            <div className={styles.menu}>{itemRender}</div>
                            <div className={styles.version}>
                                <span>{version}</span>
                                <span>{licenseName}</span>
                                {validDate && (
                                    <span>
                                        {t({
                                            message: 'Срок действия: {date}',
                                            id: 'license.validDate',
                                            values: {
                                                date: format(new Date(validDate), 'yyyy.MM.dd'),
                                            },
                                        })}
                                    </span>
                                )}
                            </div>
                        </div>
                        <div
                            className={styles.content}
                            ref={contentRef}
                            data-page-content
                            tabIndex={0}>
                            <Route
                                exact
                                path='/account/profile'
                                component={AccountInformationPageResponsive}
                            />
                            <Route
                                exact
                                path='/account/conferences/scheduling'
                                component={ConferenceSchedulingPageConnected}
                            />
                            <Route
                                exact
                                path={`${EPaths.EDIT_CONFERENCE}/:id`}
                                render={({
                                    match: {
                                        params: { id },
                                    },
                                }) => <ConferenceSchedulingPageConnected id={id} />}
                            />
                            <Route
                                exact
                                path={[
                                    '/account/conferences',
                                    EPaths.UPCOMING_CONFERENCES,
                                    '/account/conferences/past',
                                ]}
                                render={() => {
                                    return (
                                        <AccountConferencePageResponsive
                                            containerRef={contentRef}
                                        />
                                    );
                                }}
                            />
                            <Route
                                exact
                                path={`${EPaths.UPCOMING_CONFERENCES}/:conferenceId`}
                                render={({
                                    match: {
                                        params: { conferenceId },
                                    },
                                }) => {
                                    return (
                                        <AccountConferenceDetailsPageResponsive
                                            conferenceId={conferenceId}
                                        />
                                    );
                                }}
                            />
                            <Route
                                exact
                                path={`${EPaths.PAST_CONFERENCES}/:conferenceId/sessions/:sessionId`}
                                render={({
                                    match: {
                                        params: { conferenceId, sessionId },
                                    },
                                }) => {
                                    return (
                                        <AccountConferenceDetailsPageResponsive
                                            conferenceId={conferenceId}
                                            sessionId={sessionId}
                                        />
                                    );
                                }}
                            />
                            <Route
                                path='/account/settings'
                                component={AccountSettingsPageResponsive}
                            />
                            <Route
                                exact
                                path='/account/records'
                                component={ConferencesRecordsPageConnected}
                            />
                        </div>
                    </>
                ) : (
                    <HorizontalLoader color='blue' className={styles.loader}/>
                )}
            </div>
        </PageTemplate>
    );
};
