import { createReducer } from '@reduxjs/toolkit';
import { deleteUpcomingConferenceAction } from 'actions/conferencesList/deleteUpcomingConferenceAction';
import { resetConferencesListStoreAction } from 'actions/conferencesList/resetConferencesListStoreAction';
import { storePastConferenceAction } from 'actions/conferencesList/storePastConferenceAction';
import { storePastConferencesAction } from 'actions/conferencesList/storePastConferencesAction';
import { storeUpcomingConferencesAction } from 'actions/conferencesList/storeUpcomingConferencesAction';
import { updateUpcomingConferenceAction } from 'actions/conferencesList/updateUpcomingConferenceAction';
import { resetStoreAction } from 'actions/resetStoreAction';
import { IPastConferenceInfo } from 'types/conferencesList/IPastConferenceInfo';
import { IUpcomingConferenceInfo } from 'types/conferencesList/IUpcomingConferenceInfo';

export interface IConferencesListReducer {
    upcomingConferences: { [id: string]: IUpcomingConferenceInfo };
    pastConferences: { [id: string]: IPastConferenceInfo };
}

export const conferencesListReducerInitialState: IConferencesListReducer = {
    upcomingConferences: {},
    pastConferences: {},
};

export const conferencesListReducer = createReducer(conferencesListReducerInitialState, (builder) =>
    builder
        .addCase(storePastConferencesAction, (state, { payload: conferences }) => {
            const newConferences: { [id: string]: IPastConferenceInfo } = {};
            for (const conf of conferences) {
                newConferences[conf.id] = conf;
            }
            state.pastConferences = newConferences;
        })
        .addCase(storePastConferenceAction, (state, { payload: conference }) => {
            state.pastConferences[conference.id] = conference;
        })
        .addCase(storeUpcomingConferencesAction, (state, { payload: conferences }) => {
            const newConferences: { [id: string]: IUpcomingConferenceInfo } = {};
            for (const conf of conferences) {
                newConferences[conf.id] = conf;
            }
            state.upcomingConferences = newConferences;
        })
        .addCase(deleteUpcomingConferenceAction, (state, { payload: conferenceId }) => {
            delete state.upcomingConferences[conferenceId];
        })
        .addCase(updateUpcomingConferenceAction, (state, { payload: conference }) => {
            state.upcomingConferences[conference.id] = conference;
        })
        .addCase(resetConferencesListStoreAction, () => {
            return conferencesListReducerInitialState;
        })
        .addCase(resetStoreAction, () => conferencesListReducerInitialState),
);
