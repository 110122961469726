import { t } from '@lingui/macro';
import CameraOnImg from 'assets/Camera.svg';
import CameraGrayImg from 'assets/CameraGray.svg';
import CameraOffWhiteImg from 'assets/CameraOffWhite.svg';
import React from 'react';
import { ConferenceDevice } from '../ConferenceDevice';
import { IDeviceTypeProps, IOwnProps } from '../types';
import styles from './ConferenceVideoDevice.module.scss';
import { getTooltipText } from './utils';

export const ConferenceVideoDevice: React.FC<IOwnProps> = (props) => {
    const videoDeviceProps: IDeviceTypeProps = {
        imgOn: CameraOnImg,
        imgOff: CameraOffWhiteImg,
        imgDisabled: CameraGrayImg,
        classNameImgOff: `${styles.video} ${
            props.deviceList.length === 0 ? styles.video_top10 : ''
        }`,
        menuHeaderText: t({ id: 'Camera', message: 'Камера' }),
        text: t({ id: 'Camera', message: 'Камера' }),
        tooltip: getTooltipText(props),
    };
    return <ConferenceDevice {...props} {...videoDeviceProps} />;
};
