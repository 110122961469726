import { t } from '@lingui/macro';
import { Trans } from '@lingui/react';
import LockImg from 'assets/Lock.svg';
import { Button } from 'components/common/Button';
import { Input } from 'components/common/Input';
import { Spinner } from 'components/common/Spinner';
import { StatusLabel } from 'components/common/StatusLabel';
import { Form, FormRenderProps } from 'react-final-form';
import commonStyles from '../PinEnterForm.module.scss';
import { IOwnProps, IDispatchProps, IValues } from '../types';
import { getSubmitHandler, handleValidate } from '../utils';
import styles from './PinEnterFormDesktop.module.scss';

export const PinEnterFormDesktop: React.FC<IOwnProps & IDispatchProps> = ({
    submitFormData,
    successSubmitCallback,
}) => {
    const handleSubmit = getSubmitHandler({ submitFormData, successSubmitCallback });
    const render = ({
        handleSubmit,
        submitting,
        submitErrors,
        errors,
    }: FormRenderProps<IValues>) => (
        <form onSubmit={handleSubmit} className={styles.form}>
            <img src={LockImg} alt='lock' />
            <div className={styles.headerText}>
                <Trans
                    message='Для подключения к этой конференции нужен код доступа'
                    id='To connect to this conference, you need an access code'
                />
            </div>
            <StatusLabel
                text={submitErrors?.pin && t({ id: 'Pin error', message: 'Введён неверный код' })}
                className={styles.statusLabel}
            />
            <div className={commonStyles.block}>
                <div className={styles.text}>
                    <Trans message='Код доступа' id='Access code' />
                </div>
                <Input
                    placeholder={t({ id: 'Enter pin', message: 'Введите код доступа' })}
                    name='pin'
                    type='password'
                    className={styles.inputStyle}
                    autoFocus={true}
                    touched={false}
                    errorText={errors?.pin}
                />
            </div>
            <Button
                styleType={submitting ? 'loading' : 'primaryAction'}
                disabled={submitting}
                type='submit'
                className={styles.button}>
                {submitting ? <Spinner /> : t({ id: 'Join', message: 'Присоединиться' })}
            </Button>
        </form>
    );
    return <Form onSubmit={handleSubmit} validate={handleValidate} render={render} />;
};
