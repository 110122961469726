import { TAppDispatch } from 'types/app/TAppDispatch';
import { TAppState } from 'types/app/TAppStore';
import { janusInternalLogger, janusLogger } from 'utils/logger';
import { initializeJanusCtx, janusCtx } from './janusCtx';
import { janusInitializationCallback } from './janusInitializationCallback';
import { loadJanus } from './loadJanusScript';

export const startConferenceJanus = async (
    roomId: string,
    dispatch: TAppDispatch,
    getState: () => TAppState,
) => {
    const {
        auth: { appSettings },
        janus: { token },
        conferenceSession: { currentConferenceId },
        conferences: { conferencesConnectInfo },
    } = getState() as TAppState;

    const conferenceConnectInfo = conferencesConnectInfo[currentConferenceId];
    const janusAddress = appSettings ? appSettings['janusAddress'] || '' : '';
    const server = janusAddress ? janusAddress : conferenceConnectInfo?.janusUrl;
    initializeJanusCtx({ dispatch, getState, roomId, server, token });
    janusLogger.info(`Connect to Janus at ${janusCtx.server}`);
    await loadJanus();
    Janus.init({
        logger: janusInternalLogger,
        dependencies: Janus.useDefaultDependencies(),
        callback: janusInitializationCallback,
    });
};
