import { IUserDevice } from 'types/IUserDevice';

export const handleSelectDevice = (
    device: IUserDevice,
    enabled: boolean,
    onChangeDevice?: (type: any, item: IUserDevice) => void,
) => {
    let type: string | undefined = undefined;
    if (onChangeDevice !== undefined) {
        if (device.kind === 'audioinput') {
            type = 'audio';
        } else if (device.kind === 'videoinput') {
            type = 'video';
        }
        onChangeDevice(type, device);
    }
};
