import { IUserLdapDataFromServer } from 'types/server/IUserLdapDataFromServer';
import { IUserLdapData } from 'types/user/IUserLdapData';

export const convertLdapUserDataFromServer = ({
    email,
    firstName,
    id,
    lastName,
    login,
    avatar,
}: IUserLdapDataFromServer): IUserLdapData => ({
    email,
    firstName,
    id,
    lastName,
    login,
    etag: avatar ? avatar.replace('etag=', '') : undefined,
});
