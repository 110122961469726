import { setJanusStreamPropsAction } from 'actions/janus/setJanusStreamPropsAction';
import { TAppState } from 'types/app/TAppStore';
import { janusCtx } from 'utils/janus/janusCtx';
import { janusLogger } from 'utils/logger';
import { wait } from '__fixtures__/wait';

export const janusErrorInterceptor = (...args: any[]): boolean => {
    // Check janus error and can handle some of them
    // If error should be hidden, should return true
    const {
        janus: { localStreamProps },
    } = janusCtx.getState() as TAppState;
    janusLogger.debug({ args });
    if (args[0].name === 'NotAllowedError') {
        janusLogger.debug({ localStreamProps });
        // No need to show this error
        if (localStreamProps.useVideo) {
            setTimeout(
                () => janusCtx.dispatch(setJanusStreamPropsAction({ useVideo: false })),
                100,
            );
        }
        return true;
    } else if (args[0] === 'WebRTC error:') {
        const { useAudio, useVideo } = localStreamProps;
        (async () => {
            janusLogger.debug('Try to restart publisher peer connection');
            // Cause unpublish
            await janusCtx.dispatch(
                setJanusStreamPropsAction({ useAudio: false, useVideo: false }),
            );
            await wait(500);
            // Cause publish
            janusCtx.dispatch(setJanusStreamPropsAction({ useAudio, useVideo }));
        })();
        return true;
    }
    return false;
};
