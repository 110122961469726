import { connect } from 'react-redux';
import { getCurrentConferenceConnectInfoSelector } from 'selectors/getCurrentConferenceConnectInfoSelector';
import { updateConferenceUsersListThunkAction } from 'thunk/conference/updateConferenceUsersListThunkAction';
import { TAppDispatch } from 'types/app/TAppDispatch';
import { TAppState } from 'types/app/TAppStore';
import { ConferenceMembersMobile } from './ConferenceMembersMobile';
import { IDispatchProps, IStateProps } from './types';
import { EPendingOperationsThunkActions } from 'constants/thunk/EPendingOperationsThunkActions';
import { executePendingOperationThunkAction } from 'thunk/appView/executePendingOperationThunkAction';

const mapStateToProps = (state: TAppState): IStateProps => ({
    conferenceConnectInfo: getCurrentConferenceConnectInfoSelector(state),
    creator: state.conferenceSession.currentConferenceCreator,
    currentParticipants: state.conferenceSession.currentConferenceParticipants,
    currentParticipantsIds: state.conferenceSession.currentConferenceSortedParticipantsIds,
    invitedUsers: state.conferenceSession.currentConferenceInvitedUsers,
    isGettingCurrentParticipants:
        !!state.applicationView.pendingOperations[
            EPendingOperationsThunkActions.GETTING_PARTICIPANTS
        ],
});

const mapDispatchToProps = (dispatch: TAppDispatch): IDispatchProps => ({
    updateUsers: (getInvitedUsers = false) =>
        dispatch(
            executePendingOperationThunkAction({
                operation: EPendingOperationsThunkActions.GETTING_PARTICIPANTS,
                pendingFunction: () =>
                    dispatch(updateConferenceUsersListThunkAction(getInvitedUsers)),
            }),
        ),
});

export const ConferenceMembersMobileConnected = connect(
    mapStateToProps,
    mapDispatchToProps,
)(ConferenceMembersMobile);
