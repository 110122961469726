import { t } from '@lingui/macro';
import { Trans } from '@lingui/react';
import { ReactComponent as DatePickerSvg } from 'assets/DatePicker.svg';
import { tryConvertToDate } from 'components/ConferenceScheduleSettingsForm/utils';
import { DatePickerConnected } from 'components/common/DatePicker';
import { Input } from 'components/common/Input';
import { format } from 'date-fns';
import { getMaxDate, getMinDate } from '../utils';
import styles from './Header.module.scss';
import { IOwnProps } from './types';
//import { parseDate } from './utils';
 
export const Header: React.FC<IOwnProps> = ({ startDate, endDate, errors, warnings }) => (
    <div className={styles.header}>
        <div className={styles.dates}>
            <div className={styles.dateWrapper}>
                <label className={`${styles.label} ${styles.label_first}`}>
                    <Trans id='repeatRange' message='Начиная с' />
                </label>
                <Input
                    placeholder={t({
                        id: 'recurring.conference.dialog.start',
                        message: 'ДД.ММ.ГГ',
                    })}
                    name='startDate'
                    type='text'
                    autocomplete='off'
                    errorWithoutConditions={errors?.startDate}
                    errorText={warnings?.startDate}
                    errorType={warnings?.startDate ? 'warning' : 'error'}
                    statusClassName={styles.dateField__statusText}
                    className={styles.dateField}
                />
                <DatePickerConnected
                    name='startDate'
                    parse={(value: any) => {
                        if (value && typeof value.getMonth === 'function') {
                            return format(value, 'dd.MM.yyyy');
                        }
                    }}
                    currentValue={tryConvertToDate(startDate)}
                    minDate={getMinDate()}
                />
            </div>
            <div className={styles.dateWrapper}>
                <label className={`${styles.label} ${!startDate ? styles.label_disabled : ''}`}>
                    <Trans id='Before' message='До' />
                </label>
                <Input
                    placeholder={t({
                        id: 'recurring.conference.dialog.start',
                        message: 'ДД.ММ.ГГ',
                    })}
                    name='endDate'
                    type='text'
                    autocomplete='off'
                    className={styles.dateField}
                    errorWithoutConditions={errors?.endDate}
                    disabled={startDate ? false : true}
                />
                {!startDate && <DatePickerSvg className={`${styles.img} ${styles.img_disabled}`} />}
                {startDate && (
                    <DatePickerConnected
                        name='endDate'
                        parse={(value: any) => {
                            if (value && typeof value.getMonth === 'function') {
                                return format(value, 'dd.MM.yyyy');
                            }
                        }}
                        currentValue={tryConvertToDate(endDate)}
                        minDate={getMinDate()}
                        maxDate={getMaxDate(startDate)}
                    />
                )}
            </div>
        </div>
        <div className={styles.time}>
            <label className={`${styles.label} ${styles.label_first}`}>
                <Trans id='Time' message='Время' />
            </label>
            <Input
                placeholder={t({ id: 'hh:mm', message: 'чч:мм' })}
                name='time'
                type='text'
                autocomplete='off'
                errorWithoutConditions={errors?.time}
                className={`${styles.dateField} ${styles.dateField__time}`}
            />
        </div>
    </div>
);
