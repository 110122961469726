import { getExtensionInLowCase } from './getExtensionInLowCase';
export const isImageFile = (fileName: string): boolean => {
    switch (getExtensionInLowCase(fileName)) {
        case 'jpg':
        case 'jpeg':
        case 'svg':
        case 'png':
        case 'gif':
            return true;
    }
    return false;
};
