import { Avatar } from 'components/common/Avatar';
import { getAvatarUrl } from 'utils/users/getAvatarUrl';
import { getUserColor } from 'utils/users/getUserColor';
import { getUserInitials } from 'utils/users/getUserInitials';
import { getStyle } from '../utils';
import { TOwnProps } from './types';

export const ConferenceUser: React.FC<TOwnProps> = ({ userData, email, isDesktop, avatarSize }) => {
    const style = getStyle(avatarSize, isDesktop);
    const divStyle = isDesktop ? { width: `${avatarSize - 10}px` } : {};
    if (userData) {
        const { firstName, lastName, avatar, id } = userData;
        const userAvatar = avatar ? getAvatarUrl({ id, etag: avatar, size: `${avatarSize}` }) : '';
        const initials = getUserInitials({ email, firstName, lastName });
        return (
            <div key={id} style={divStyle}>
                <Avatar
                    image={userAvatar}
                    initials={initials}
                    style={style}
                    userBgColor={getUserColor(`${firstName}${lastName}`)}
                />
            </div>
        );
    }
    if (email) {
        const initials = getUserInitials({ email });
        return (
            <div key={email} style={divStyle}>
                <Avatar style={style} initials={initials} />
            </div>
        );
    }
    return <div></div>;
};
