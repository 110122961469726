import { Trans } from '@lingui/react';
import { runMediaStreamStartCallbacks } from 'utils/media/mediaStreamStartCallbacks';
import styles from './MediaPermissionDialog.module.scss';
import { IDispatchProps } from './types';

export const MediaPermissionDialog: React.FC<IDispatchProps> = ({ closeMediaPermissionDialog }) => (
    <div className={styles.dialog}>
        <div className={styles.header}>
            <Trans id='media.permission.request' />
        </div>
        <div className={styles.footer}>
            <div className={styles.allow} onClick={runMediaStreamStartCallbacks}>
                <Trans message='Включить' id='allow' />
            </div>
            <div className={styles.denied} onClick={closeMediaPermissionDialog}>
                <Trans message='Отмена' id='deny' />
            </div>
        </div>
    </div>
);
