import { ESocketRequestStatus } from 'constants/ESocketRequestStatus';
import {
    getSocketConnectionUrl,
    getSocketServiceState,
    setSocket,
    setSocketConnectionStatus,
    setStompClient,
} from './socketService';
import sockjs from 'sockjs-client';
import Stomp, { Frame } from 'stompjs';
import { log } from 'utils/log';
import { disconnectFromSocket } from './disconnectFromSocket';
import { removeCancelTabCloseEvent } from 'utils/cancelTabCloseEventManage';
import { Capacitor } from '@capacitor/core';
import { TCapacitorPlatform } from 'types/TPlatform';
import { getExtendedPlatform } from 'utils/getExtendedPlatform';

const reloadRepeatMtime = 30000;
let wsConnectState = false;

interface ICallbacksManager {
    callbacks: {
        [name: string]: () => void;
    };
    push: (callback: () => void, callbackName: string) => void;
    run: () => void;
    clearCallbacks: () => void;
}

const callbacksManager: ICallbacksManager = {
    callbacks: {},
    push: (callback: () => void, callbackName: string) => {
        if (callbacksManager.callbacks[callbackName]) {
            return;
        }
        callbacksManager.callbacks[callbackName] = callback;
    },
    run: () => {
        const { callbacks } = callbacksManager;
        for (const callbackName in callbacks) {
            if (callbacks.hasOwnProperty(callbackName)) {
                callbacks[callbackName]();
            }
        }
        callbacksManager.clearCallbacks();
    },
    clearCallbacks: () => {
        callbacksManager.callbacks = {};
    },
};
export const connectToSocket = (callback: () => void, callbackName: string) => {
    const { connected } = getSocketServiceState();
    if (connected === ESocketRequestStatus.REQUEST) {
        callbacksManager.push(callback, callbackName);
        return;
    }
    if (connected === ESocketRequestStatus.SUCCESS) {
        callback();
        return;
    }
    if (connected === ESocketRequestStatus.INITIAL || connected === ESocketRequestStatus.FAILURE) {
        setSocketConnectionStatus(ESocketRequestStatus.REQUEST);
        callbacksManager.push(callback, callbackName);
        const currentPlatform = getExtendedPlatform(Capacitor.getPlatform() as TCapacitorPlatform);
        const socket = sockjs(getSocketConnectionUrl(currentPlatform));
        setSocket(socket);
        const stompClient = Stomp.over(socket);
        stompClient.debug = () => {};
        setStompClient(stompClient);
        stompClient.ws.addEventListener('close', () => {
            const lDateStr = sessionStorage.getItem('closeWsLastDate');
            if (lDateStr) {
                if (new Date().getTime() - new Date(lDateStr).getTime() < reloadRepeatMtime) {
                    setTimeout(reloadPage, reloadRepeatMtime);
                    return;
                }
            }
            reloadPage();
        });
        stompClient.connect(
            {},
            (connectFrame: Frame | undefined) => {
                if (connectFrame && connectFrame.command === 'CONNECTED') {
                    setSocketConnectionStatus(ESocketRequestStatus.SUCCESS);
                    callbacksManager.run();
                    setConnectionState(true);
                    return;
                }
                log.error('Problems with websocket connection.');
                disconnectFromSocket();
            },

            (error: Frame | string) => {
                log.error(error);
                disconnectFromSocket();
                setConnectionState(false);
            },
        );
    }
};
function setConnectionState(state: boolean) {
    wsConnectState = state;
    if (state) {
        sessionStorage.setItem('closeWsLastDate', '');
    }
}
function reloadPage() {
    if (wsConnectState) {
        return;
    }
    sessionStorage.setItem('closeWsLastDate', new Date().toString());
    try {
        removeCancelTabCloseEvent();
    } catch (err) {
        log.error(err);
    }
    if (sessionStorage.getItem('isLogoutAction')) {
        return;
    }
    location.reload();
}
