export enum EPredefinedDatesRange {
    LastMonth = 1,
    LastWeek,
    Yesterday,
    Today,
    ThisWeek,
    ThisMonth,
    Tomorrow,
    NextWeek,
    NextMonth,
}
