import { Trans } from '@lingui/react';
import { Spinner } from 'components/common/Spinner';
import styles from './RecordLoader.module.scss';

export const RecordLoader: React.FC<{ error?: boolean }> = ({ error }) => (
    <div className={styles.root}>
        <div className={styles.wrapper}>
            {error && <Trans id='notification.error' />}
            {!error && (
                <>
                    <Spinner wrapperClass='spinner_quarter' />
                    <div>
                        <Trans
                            id='finished.conference.record.text.id'
                            message='Обрабатывается...'
                        />
                    </div>
                </>
            )}
        </div>
    </div>
);
