import { ConferenceUsersConnected } from '../../ConferenceUsers';
import { IOwnProps } from './types';
import styles from './PastConferenceParticipants.module.scss'

export const PastConferenceParticipants: React.FC<IOwnProps> = ({ participants }) => {
    const userData = { participants };
    return (
        <div className={styles.container} data-past-conf-participants>
            <ConferenceUsersConnected usersData={userData} isDesktop={true} />
        </div>
    );
};
