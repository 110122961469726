import { t } from '@lingui/macro';
import { Button } from 'components/common/Button/Button';
import { FullScreenPopup } from 'components/common/FullScreenPopup';
import { Input } from 'components/common/Input/Input';
import { PopupDialog } from 'components/common/PopupDialog';
import { ConferenceEmotionsButtonConnected } from 'components/ConferenceFooter/ConferenceEmotionsButton';
import { EChatMessageStatus } from 'constants/EChatMessageStatus';
import { EChatMessageType } from 'constants/EChatMessageType';
import React, { useEffect, useRef, useState } from 'react';
import { Form, FormRenderProps } from 'react-final-form';
import { createRandomId } from 'utils/createRandomId';
import { ClipboardImagePreview } from '../ClipboardImagePreview';
import { ConferenceChatMsgLengthWarning } from '../ConferenceChatMsgLengthWarning';
import styles from './ConferenceChatForm.module.scss';
import { IDispatchProps, IFormRenderProps, IOwnProps, IStateProps } from './types';
import { checkFiles, getResizeImg } from './utils';

let chatDraftMessageLocal = '';

export const ConferenceChatForm: React.FC<IDispatchProps & IStateProps & IOwnProps> = ({
    addNewMessage,
    saveMedia,
    ownUserId,
    confUserId,
    setChatAutoScroll,
    chatAutoScrollState,
    showNotification,
    isMobile,
    maxMessageLength,
    maxMediaFileSizeByte,
    draftChatMessage,
    setDraftChatMessage,
}) => {
    const [clipboardImage, setClipboardImage] = useState<{ image: string; file: File } | null>(
        null,
    );
    const fileRef = useRef<HTMLInputElement>(null);
    const sendMedia = async (mediaFiles: File[]) => {
        const files = await saveMedia(mediaFiles);
        if (fileRef.current !== null) {
            fileRef.current.value = '';
        }
        if (files === null || files.length === 0) {
            showNotification(
                t({ id: 'filesValidation.sendError', message: 'Не удалось загрузить файлы' }),
            );
            return;
        }
        addNewMessage({
            text: '',
            contentType: EChatMessageType.TEXT,
            status: EChatMessageStatus.IN_PROGRESS,
            confUserId,
            ownUserId,
            id: files[0].id,
            files,
        });
        !chatAutoScrollState ? setChatAutoScroll(true) : false;
    };

    const inputRef = useRef<HTMLInputElement | null>(null);
    const saveMessage = () => {
        chatDraftMessageLocal = inputRef.current?.value || '';
    };

    useEffect(() => {
        chatDraftMessageLocal = draftChatMessage;
    }, [draftChatMessage]);

    useEffect(() => {
        return () => {
            setDraftChatMessage(chatDraftMessageLocal);
        };
    }, []);

    const confirmClickHandler = async () => {
        if (!clipboardImage) {
            return;
        }
        await sendMedia([clipboardImage.file]);
        setClipboardImage(null);
    };
    const fileChangeHandler = ({ target }: { target: HTMLInputElement }) => {
        const files = checkFiles(target.files, maxMediaFileSizeByte, showNotification);
        if (files) {
            sendMedia(files);
        } else if (fileRef.current !== null) {
            fileRef.current.value = '';
        }
    };
    const pasteHandler = async (e: ClipboardEvent) => {
        const files = checkFiles(e.clipboardData?.files, maxMediaFileSizeByte, showNotification);
        if (!files) {
            return;
        }
        const imageUrl = await getResizeImg(files[0]);
        if (!imageUrl) {
            return;
        }
        setClipboardImage({ image: imageUrl, file: files[0] });
    };
    const submitHandler = (values: IFormRenderProps) => {
        if (!values?.chatTextMessage) {
            return;
        }
        if (values.chatTextMessage.trim()) {
            addNewMessage({
                text: values.chatTextMessage,
                contentType: EChatMessageType.TEXT,
                status: EChatMessageStatus.IN_PROGRESS,
                confUserId,
                ownUserId,
                id: createRandomId(),
            });
            !chatAutoScrollState ? setChatAutoScroll(true) : false;
            if (draftChatMessage !== '') {
                setDraftChatMessage('');
            }
            chatDraftMessageLocal = '';
        }
    };
    const render = ({ handleSubmit, values }: FormRenderProps<IFormRenderProps>) => (
        <form
            className={`${styles.ConferenceChatForm} ${
                isMobile ? styles.ConferenceChatForm_mobile : ''
            }`}
            onSubmit={handleSubmit}
            onTouchStart={(e: React.TouchEvent) => e.stopPropagation()}>
            <label
                className={styles.ConferenceChatForm__labelAttach}
                htmlFor='chatTextAttach'></label>
            <input
                ref={fileRef}
                type='file'
                id='chatTextAttach'
                name='chatTextAttach'
                onChange={fileChangeHandler}
                multiple
            />
            <div
                className={`${!isMobile ? styles.ConferenceChatForm__inputWrapper : ''} ${
                    isMobile ? styles.ConferenceChatForm__inputWrapper_mobile : ''
                }`}>
                <ConferenceChatMsgLengthWarning
                    currentLength={values.chatTextMessage?.length || 0}
                    maxLength={maxMessageLength}
                    className={styles.msgLengthWarning}
                    blinkedClassName={styles.msgLengthWarningIsBlinked}
                />
                <Input
                    placeholder={t({ id: 'Message...', message: 'Сообщение...' })}
                    name='chatTextMessage'
                    type='text'
                    autocomplete='off'
                    className={styles.ConferenceChatForm__input}
                    handlePaste={pasteHandler}
                    maxLength={maxMessageLength}
                    autoFocus
                    ref={inputRef}
                    handleInput={saveMessage}
                />
                {isMobile && (
                    <ConferenceEmotionsButtonConnected
                        smallImage={true}
                        mobile={true}
                        inChat={true}
                    />
                )}
            </div>
            {!isMobile && <Button styleType='send' type='submit' />}
        </form>
    );
    const closePopup = () => setClipboardImage(null);
    return (
        <>
            <Form
                onSubmit={(values, formApi) => {
                    submitHandler(values);
                    formApi.reset();
                }}
                render={render}
                initialValues={{ chatTextMessage: draftChatMessage }}
            />
            {clipboardImage && (
                <FullScreenPopup theme='dark' closePopup={closePopup}>
                    <PopupDialog
                        crossPosition='inside'
                        theme='dark'
                        onClose={closePopup}
                        title={t({ id: 'send.image', message: 'Отправить фото' })}
                        dialogClassName={styles.popupDialog_sm}
                        titleClassName={styles.popupDialogTitle}>
                        <ClipboardImagePreview
                            image={clipboardImage.image}
                            clickHandler={confirmClickHandler}
                        />
                    </PopupDialog>
                </FullScreenPopup>
            )}
        </>
    );
};
