import { ITenantData } from 'admin-app/types/ITenantData';
import { INetworkService, IRequestResult } from 'types/api/INetworkService';

export const getTenantDataApiCall = (
    networkService: INetworkService,
): Promise<IRequestResult<ITenantData>> => {
    return networkService.get({
        url: '/api/admin/tenants',
    });
};
