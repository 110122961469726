import { t } from '@lingui/macro';
import ArrowUpImg from 'assets/ArrowUp.svg';
import CalendarImg from 'assets/CalendarBig.svg';
import DialogImg from 'assets/CameraDialog.svg';
import OutsideClick from 'components/common/OutsideClick';
import { PopupDialog } from 'components/common/PopupDialog';
import { useEffect, useState } from 'react';
import styles from './MainMobileMenu.module.scss';
import { IDispatchProps, IOwnProps, IStateProps } from './types';

export const MainMobileMenu: React.FC<IDispatchProps & IOwnProps & IStateProps> = ({
    closeCallback,
    onJoin,
    scheduleConference,
    startConference,
    disabled,
}) => {
    const [open, setOpen] = useState<boolean>(false);
    useEffect(() => {
        setOpen(true);
    }, []);

    const onTransitionEnd = () => {
        if (!open) {
            closeCallback();
        }
    };
    const onClose = () => setOpen(false);
    const style = open ? styles.wrapper_open : '';

    const onClick = (cb: () => void) => {
        if (!disabled) {
            closeCallback();
            cb();
        }
    };

    return (
        <OutsideClick onOutsideClick={onClose}>
            <div className={`${styles.wrapper} ${style}`} onTransitionEnd={onTransitionEnd}>
                <PopupDialog
                    title={t({ id: 'Create', message: 'Создать' })}
                    dialogClassName={styles.dialog}
                    titleClassName={styles.header}
                    crossPosition='inside'
                    onClose={onClose}>
                    <nav className={styles.list}>
                        <ul>
                            <li>
                                <a
                                    onClick={() => onClick(startConference)}
                                    data-qa='startConferenceMainButton'>
                                    <img src={DialogImg} alt='Start' />
                                    <span>{t({ id: 'Start a conference' })}</span>
                                </a>
                            </li>
                            <li>
                                <a
                                    onClick={() => onClick(scheduleConference)}
                                    data-qa='scheduleConferenceMainButton'>
                                    <img src={CalendarImg} alt='Schedule' />
                                    <span>{t({ id: 'Schedule a conference' })}</span>
                                </a>
                            </li>
                            <li>
                                <a
                                    onClick={() => onClick(onJoin)}
                                    data-qa='joinConferenceMainButton'>
                                    <img src={ArrowUpImg} alt='Join' />
                                    <span>{t({ id: 'Join a conference' })}</span>
                                </a>
                            </li>
                        </ul>
                    </nav>
                </PopupDialog>
            </div>
        </OutsideClick>
    );
};
