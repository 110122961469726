import { createAsyncThunk } from '@reduxjs/toolkit';
import { addConferenceChatMessagesAction } from 'actions/chat/addConferenceChatMessagesAction';
import { setSessionConferenceUsersAction } from 'actions/conferenceSession/setSessionConferenceUsersAction';
import { getChatMessagesApiCall } from 'api/conference/getChatMessagesApiCall';
import { getNetworkService } from 'api/getNetworkService';
import { IChatHistoryMessage } from 'types/chat/IChatHistoryMessage';
import { IConferenceChatMessage } from 'types/chat/IConferenceChatMessage';
import { IConferenceParticipant } from 'types/IConferenceParticipant';
import { TAppState } from 'types/app/TAppStore';
import { getFormattedMessageDataForStore } from 'utils/getFormattedMessageDataForStore';

export const getChatMessagesThunkAction = createAsyncThunk(
    'getChatMessages',
    async (
        { conferenceId, sessionId }: { conferenceId: string; sessionId: string },
        { dispatch, getState },
    ) => {
        const {
            conferenceSession: { existAndExitParticipants },
        } = getState() as TAppState;
        const response = await getChatMessagesApiCall(getNetworkService(), {
            conferenceId,
            sessionId,
        });
        if (!response.success) {
            return;
        }
        const { newAnonymUsers, messagesFromHistory } = getDataFromResponse(
            response?.data?.array,
            existAndExitParticipants,
        );
        newAnonymUsers.length > 0 &&
            messagesFromHistory.length > 0 &&
            dispatch(
                setSessionConferenceUsersAction([...existAndExitParticipants, ...newAnonymUsers]),
            );
        messagesFromHistory.length > 0 &&
            dispatch(
                addConferenceChatMessagesAction({
                    messages: messagesFromHistory,
                    history: true,
                }),
            );
    },
);

export const getDataFromResponse = (
    chatHistoryMessages: IChatHistoryMessage[] | undefined,
    conferenceParticipants: IConferenceParticipant[],
) => {
    const resultMessagesArray: IConferenceChatMessage[] = [];
    const resultAnonymUsersArray: IConferenceParticipant[] = [];
    if (!chatHistoryMessages) {
        return { messagesFromHistory: [], newAnonymUsers: [] };
    }
    chatHistoryMessages.forEach((messageData) => {
        resultMessagesArray.push(getFormattedMessageDataForStore(messageData));
        if (messageData.userId) {
            return;
        }
        const participantFromResultArray = resultAnonymUsersArray.find(
            (participant) => participant.participantId === messageData.senderId,
        );
        if (participantFromResultArray) {
            return;
        }
        if (conferenceParticipants.length > 0) {
            const participantFromStore = conferenceParticipants.find(
                (participant) => participant.participantId === messageData.senderId,
            );
            !participantFromStore &&
                resultAnonymUsersArray.push(getAnonymUser(messageData.name, messageData.senderId));
        } else {
            resultAnonymUsersArray.push(getAnonymUser(messageData.name, messageData.senderId));
        }
    });
    return { messagesFromHistory: resultMessagesArray, newAnonymUsers: resultAnonymUsersArray };
};

const getAnonymUser = (name: string, participantId: string) => {
    return { name, participantId, creator: undefined, etag: undefined, userId: '' };
};
