import { useEffect, useRef, useState } from 'react';
import { IUserLdapData } from 'types/user/IUserLdapData';
import { getUserColor } from 'utils/users/getUserColor';
import { getUserInitials } from 'utils/users/getUserInitials';
import { getAvatarUrl } from 'utils/users/getAvatarUrl';
import { Avatar } from '../Avatar';
import { Spinner } from '../Spinner';
import styles from './FloatUsersList.module.scss';
import { IDispatchProps, IOwnProps, IStateProps, TScrollDirection } from './types';
import {
    addKeyboardNavigation,
    deleteKeyboardNavigation,
    getContainerPosition,
    scrollToPoint,
    setDataActive,
} from './utils';

export const FloatUsersList: React.FC<IOwnProps & IStateProps & IDispatchProps> = ({
    targetInput,
    inputValues,
    searchValue,
    users,
    updateUsersList,
    resetUsersList,
    userClickHandler,
    setShowUsers,
    setInputValue,
}) => {
    const [position, setPosition] = useState({ x: 0, y: 0 });
    const [activeUser, setActiveUser] = useState<string | null>(null);
    const [scrollDirection, setScrollDirection] = useState<TScrollDirection | null>(null);
    const [targetUsersList, setTargetUsersList] = useState<IUserLdapData[]>([]);
    const [showLoader, setShowLoader] = useState(true);
    const [isCrossViewport, setIsCrossViewport] = useState(false);

    const usersRef = useRef(users);
    const activeIdRef = useRef<string | null>(null);
    const containerRef = useRef<HTMLUListElement>(null);
    const currentScrollPositionRef = useRef<number>(0);

    const clickHandler = userClickHandler ? userClickHandler : () => {};

    const getFormattedUsersList = () => {
        const newUsers = users.filter((user) => {
            const isUserAlreadyInvited = inputValues.find(
                (invitedUser) => invitedUser === user.email,
            );
            if (!isUserAlreadyInvited) {
                return user;
            }
        });

        setTargetUsersList(newUsers);
        usersRef.current = users;
    };

    const pressEscHandler = () => {
        setShowUsers(false);
    };

    const pressEnterHandler = () => {
        if (!activeIdRef?.current) {
            return;
        }
        const targetUser = usersRef.current.find((user) => user.id === activeIdRef?.current);
        targetUser ? setInputValue(targetUser.email) : false;
    };

    const updateUsersInfo = async () => {
        await updateUsersList(searchValue);
        setShowLoader(false);
    };

    const setActiveItemCallback = (prevValue: string | null, newValue: string | null) => {
        const containerNode = containerRef?.current;
        if (prevValue) {
            setDataActive(false, prevValue, containerNode);
        }
        newValue ? setDataActive(true, newValue, containerNode) : false;
        return newValue;
    };

    const setBoundaryItem = (containerNode: HTMLUListElement | null, up: boolean) => {
        const targetItem = up ? containerNode?.lastElementChild : containerNode?.firstElementChild;
        const targetId = targetItem?.id || null;
        setActiveUser((prevValue) => setActiveItemCallback(prevValue, targetId));
        activeIdRef.current = targetId;
        return;
    };

    const pressDirectionHandler = (up: boolean) => {
        const containerNode = containerRef?.current;
        const activeIdNode = containerNode?.querySelector(`[id='${activeIdRef.current}']`);
        const nextActiveItem = up
            ? activeIdNode?.previousElementSibling
            : activeIdNode?.nextElementSibling;
        const isBoundary = up
            ? containerNode?.firstChild === activeIdNode
            : containerNode?.lastChild === activeIdNode;

        if (!activeIdRef.current || (isBoundary && activeIdRef.current)) {
            if (up) {
                setScrollDirection('up');
                setBoundaryItem(containerNode, true);
                return;
            }
            setScrollDirection('down');
            setBoundaryItem(containerNode, false);
            return;
        }

        const newActiveId = nextActiveItem?.id || null;
        setScrollDirection(up ? 'up' : 'down');
        setActiveUser((prevValue: any) => setActiveItemCallback(prevValue, newActiveId));
        activeIdRef.current = newActiveId;
    };

    const setListPosition = () => {
        if (!targetInput) {
            return;
        }
        const { x, y, outOfViewport } = getContainerPosition(targetInput);
        outOfViewport ? setIsCrossViewport(true) : setIsCrossViewport(false);

        setPosition({ x, y });
    };

    const windowResizeHandler = () => setListPosition();

    useEffect(() => {
        updateUsersInfo();
        window.addEventListener('resize', windowResizeHandler);
        addKeyboardNavigation({
            pressDownHandler: () => pressDirectionHandler(false),
            pressUpHandler: () => pressDirectionHandler(true),
            pressEscHandler,
            pressEnterHandler,
        });

        return () => {
            deleteKeyboardNavigation();
            resetUsersList();
            window.removeEventListener('resize', windowResizeHandler);
        };
    }, []);

    useEffect(() => {
        setListPosition();
        getFormattedUsersList();
    }, [users]);

    useEffect(() => {
        scrollToPoint(containerRef?.current, activeUser, scrollDirection, currentScrollPositionRef);
    }, [activeUser]);

    useEffect(() => {
        if (!activeUser && targetUsersList.length > 0) {
            setActiveUser((prevValue: any) =>
                setActiveItemCallback(prevValue, targetUsersList[0].id),
            );
            activeIdRef.current = targetUsersList[0].id;
        }
        if (!showLoader && targetUsersList.length === 0) {
            setShowUsers(false);
        }
    }, [targetUsersList]);

    const renderLoader = () => {
        return (
            <div className={styles.loaderWrapper}>
                <Spinner wrapperClass='spinner_medium' />
            </div>
        );
    };

    const containerStyles = {
        left: `${position.x}px`,
        top: `${position.y}px`,
    };

    const renderUsersList = () =>
        targetUsersList.map((user, index) => {
            const { firstName, lastName, email, etag, id } = user;
            const styleAvatarBackground = getUserColor(`${firstName}${lastName}`);
            const avatar = etag ? getAvatarUrl({ id, etag, size: '23' }) : '';
            const initials = getUserInitials({
                lastName,
                firstName,
                email,
            });
            return (
                <li
                    className={styles.user}
                    id={id}
                    key={index}
                    data-active='false'
                    onClick={() => {
                        clickHandler(user);
                    }}>
                    <Avatar
                        style={{
                            cursor: 'pointer',
                            width: '23px',
                            height: '23px',
                            fontSize: '10px',
                        }}
                        initials={initials}
                        image={avatar}
                        userBgColor={styleAvatarBackground}
                    />
                    <div className={styles.user__info}>
                        <span className={styles.user__infoName}>{`${firstName} ${lastName}`}</span>
                        <span className={styles.user__infoMail}>{email}</span>
                    </div>
                </li>
            );
        });
    const outOfViewportStyles = isCrossViewport ? styles.usersList_transform : '';
    return (
        <ul
            className={`${styles.usersList} ${outOfViewportStyles}`}
            style={containerStyles}
            ref={containerRef}>
            {showLoader ? renderLoader() : renderUsersList()}
        </ul>
    );
};
