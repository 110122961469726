import { IOwnProps } from './types';
import { Field } from 'react-final-form';
import { Input } from '../Input';
import { classNames } from 'utils/classNames';

export const InputField: React.FC<IOwnProps> = ({
    name,
    errorClassName,
    wrapperClassName,
    ...props
}) => {
    return (
        <Field name={name}>
            {({ input, meta }) => {
                const errorStyles =
                    errorClassName && !meta.active && meta.touched ? errorClassName : '';
                return (
                    <Input
                        {...props}
                        {...input}
                        wrapperClassName={classNames([wrapperClassName, errorStyles])}
                    />
                );
            }}
        </Field>
    );
};
