import { createAsyncThunk } from '@reduxjs/toolkit';
import { updateAppSettingsThunkAction } from './updateAppSettingsThunkAction';

interface IPayload {
    use3dAudio?: boolean;
}

export const setUse3dAudioThunkAction = createAsyncThunk(
    'setUse3dAudioThunkAction',
    async ({ use3dAudio }: IPayload, { dispatch }): Promise<void> => {
        await dispatch(updateAppSettingsThunkAction({ use3dAudio: !!use3dAudio }));
    },
);
