export const getUrlsWithAnonymousAccess = (): string[] => {
    return [
        'change',
        'conference',
        'connect',
        'enter',
        'inputUsername',
        'link',
        'lobby',
        'login',
        'register',
        'restore',
        'error',
        'confirm',
    ];
};

export const isAnonymousAccess = (path: string) => {
    const pathParts = path.split('/');
    if (pathParts.length > 1) {
        if (pathParts[1] === '') return true;
        const urls = getUrlsWithAnonymousAccess();
        const findUrl = urls.find((url) => pathParts[1] === url);
        return findUrl ? true : false;
    }
    return true;
};
