import { IConferenceCreator } from 'types/IConferenceCreator';
import { IConferenceParticipant } from 'types/IConferenceParticipant';
import { IConferenceParticipantFromServer } from 'types/server/IConferenceParticipantFromServer';

export const convertParticipantFromServerToParticipant = (
    { name, participantId, avatar, userId }: IConferenceParticipantFromServer,
    currentConferenceCreator: IConferenceCreator | null,
): IConferenceParticipant => {
    let creator = false;
    let etag;
    if (userId === currentConferenceCreator?.userId) {
        creator = true;
    }
    if (avatar) {
        etag = avatar.replace('etag=', '');
    }
    return { name, participantId, creator, etag, userId };
};
