import { createAsyncThunk } from '@reduxjs/toolkit';
import { setPendingOperationAction } from 'actions/applicationView/setPendingOperationAction';

interface IPayload {
    operation: string;
    pendingFunction: () => Promise<any>;
}

export const executePendingOperationThunkAction = createAsyncThunk<any, IPayload>(
    'executePendingOperation',
    async ({ pendingFunction, operation }, { dispatch }) => {
        dispatch(setPendingOperationAction({ operation, pending: true }));
        const result = await pendingFunction();
        dispatch(setPendingOperationAction({ operation, pending: false }));
        return result?.payload;
    },
);
