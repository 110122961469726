import { INetworkService } from 'types/api/INetworkService';

/**
 * @param createdAt - format '2022-08-18T06:46:10.606Z'
 * @param updatedAt - format '2022-08-18T06:46:10.606Z'
 */
type IGetCurrentUserProfileDataApiResponseData =
    | {
          id: string;
          createdAt: string;
          updatedAt: string;
          login: string;
          email: string;
          firstName: string;
          lastName: string;
          mobilePhone: string;
          locale: string;
          timeZone: string;
          activated: boolean;
          avatar: string;
      }
    | {
          anonymous: boolean;
      };

export const getCurrentUserProfileDataApiCall = (networkService: INetworkService) =>
    networkService.get({ url: '/api/account/me' });
