import { createAsyncThunk } from '@reduxjs/toolkit';
import { closeRedirectConfirmationAction } from 'actions/applicationView/closeRedirectConfirmationAction';
import { appHistory } from 'appHistory';
import { TAppState } from 'types/app/TAppStore';

export const redirectThunkAction = createAsyncThunk(
    'redirect',
    async (payload, { dispatch, getState }) => {
        const {
            applicationView: {
                pendingConfirmation: { redirectData },
            },
        } = getState() as TAppState;
        dispatch(closeRedirectConfirmationAction(true));
        if (!redirectData) {
            return;
        }
        if (redirectData?.onClick) {
            redirectData.onClick();
        }
        if (redirectData.url) {
            if (redirectData.method === 'push') {
                appHistory.push(redirectData.url);
            } else {
                appHistory.replace(redirectData.url);
            }
        }
    },
);
