import LogoImg from 'assets/LogoWhite.svg';
import { Cross } from 'components/common/Cross';
import { EPaths } from 'constants/EPaths';
import { AudioFramesConnected } from 'pages/ConferencePage/AudioFrames/AudioFramesConnected';
import React, { useContext, useEffect, useRef, useState } from 'react';
import { useLocation } from 'react-router-dom';
import { classNames } from 'utils/classNames';
import { exitConferenceOrShowConfirmation } from 'utils/exitConferenceOrShowConfirmation';
import { isElementInContainer } from 'utils/isElementInContainer';
import { isTargetPath } from 'utils/isTargetPath';
import { addContainerPointerHandlers, ConferencePageTemplateContext } from '../utils';
import { ConferenceFullScreenButton } from './ConferenceFullScreenButton';
import styles from './ConferenceHeader.module.scss';
import { ConferenceInviteButton } from './ConferenceInviteButton';
import { ConferenceViewButtonConnected } from './ConferenceViewButton';
import { IDispatchProps, IOwnProps, IStateProps } from './types';

export const ConferenceHeader: React.FC<IOwnProps & IDispatchProps & IStateProps> = ({
    audioFramesExist,
    conferenceStatus,
    conferenceName,
    exitFromConference,
    fullScreenFrames,
    handleSettingsClick,
    showExitConfirmation,
    showLogo = false,
    showViewBtn,
    isAuthorizedUser,
}) => {
    const { isUserInactive } = useContext(ConferencePageTemplateContext);
    const [isPointerInContainer, setIsPointerInContainer] = useState(false);

    const currentComponentRef = useRef<HTMLDivElement>(null);
    const location = useLocation();

    const isConnectPage = isTargetPath(EPaths.CONNECT_TO_CONFERENCE);
    const isLobbyPage = isTargetPath(EPaths.LOBBY);

    const exitOrShowConfirmation = exitConferenceOrShowConfirmation(
        exitFromConference,
        showExitConfirmation,
        location.pathname,
        conferenceStatus === 'SCHEDULED' || isConnectPage || isLobbyPage,
    );

    const isShowInviteBtn = !showLogo && isAuthorizedUser;

    useEffect(() => {
        const container = currentComponentRef.current;
        let clearCallback: (() => void) | undefined;

        if (container && fullScreenFrames) {
            clearCallback = addContainerPointerHandlers(container, setIsPointerInContainer);
        }
        return () => {
            clearCallback && clearCallback();
        };
    }, [currentComponentRef, fullScreenFrames]);

    const hideHeaderCondition =
        isUserInactive &&
        !isPointerInContainer &&
        currentComponentRef.current &&
        !isElementInContainer(currentComponentRef.current, document.activeElement);

    if (!isConnectPage && !isLobbyPage && fullScreenFrames) {
        return (
            <div
                className={classNames([
                    styles.header,
                    styles.header_audioFrames,
                    fullScreenFrames ? styles.header_fullScreen : '',
                    hideHeaderCondition ? styles.header_hidden : '',
                ])}
                ref={currentComponentRef}>
                <div className={styles.header__left}>
                    {showLogo && <img src={LogoImg} alt='logo' onClick={exitOrShowConfirmation} />}
                    {isShowInviteBtn && (
                        <div className={styles.header__inviteBtn}>
                            <ConferenceInviteButton isShowSmallButton={true} />
                        </div>
                    )}
                    <div className={styles.header__conferenceName}>{conferenceName}</div>
                </div>
                <AudioFramesConnected />
                <div className={styles.header__right}>
                    {showViewBtn && (
                        <ConferenceViewButtonConnected handleSettingsClick={handleSettingsClick} />
                    )}
                    <div className={styles.header__browserScreenBtn}>
                        <ConferenceFullScreenButton />
                    </div>
                </div>
            </div>
        );
    }

    return (
        <div
            className={classNames([
                styles.header,
                showViewBtn ? styles.header__justifySpaceBetween : '',
                !isAuthorizedUser && !showLogo ? styles.header__justifyEnd : '',
            ])}
            ref={currentComponentRef}>
            <div className={styles.header__left}>
                {showLogo && <img src={LogoImg} alt='logo' onClick={exitOrShowConfirmation} />}
                {isShowInviteBtn && <ConferenceInviteButton isShowSmallButton={true} />}
                <div className={styles.header__conferenceName}>{conferenceName}</div>
                {(isConnectPage || isLobbyPage) && (
                    <Cross
                        color='white'
                        className={styles.cross}
                        onClick={exitOrShowConfirmation}
                    />
                )}
            </div>
            <AudioFramesConnected />
            <div className={styles.header__right}>
                {showViewBtn && (
                    <ConferenceViewButtonConnected handleSettingsClick={handleSettingsClick} />
                )}
                <div className={styles.header__browserScreenBtn}>
                    <ConferenceFullScreenButton />
                </div>
            </div>
        </div>
    );
};
