import { Trans } from '@lingui/react';
import { PageTemplate } from '../templates/PageTemplate';
import { SetNewPasswordFormConnected } from './SetNewPasswordForm';
import styles from './SetNewPasswordPage.module.scss';

export const SetNewPasswordPage: React.FC = () => (
    <PageTemplate showLoginBtn={true}>
        <div className={styles.title}>
            <Trans id='Enter password' message='Введите и подтвердите новый пароль' />
        </div>
        <div className={styles.form}>
            <SetNewPasswordFormConnected />
        </div>
    </PageTemplate>
);
