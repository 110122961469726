import { createAsyncThunk } from '@reduxjs/toolkit';
import { setAnonymousUserDataAction } from 'actions/auth/setAnonymousUserDataAction';
import { IFields } from 'components/forms/InputUsernameForm/types';
import { EPaths } from 'constants/EPaths';
import { TAppState } from 'types/app/TAppStore';
import { IConferenceLocalStorageData } from 'types/IConferenceLocalStorageData';
import { getFromLocalStorage } from 'utils/localStorage/getFromLocalStorage';
import { setToLocalStorage } from 'utils/localStorage/setToLocalStorage';
import { replace } from 'utils/redirect';

interface IPayload {
    values: IFields;
    successSubmitCallback?: (name: string) => void;
}
export const submitInputUsernameFromThunkAction = createAsyncThunk(
    'submitInputUsernameFrom',
    async ({ values, successSubmitCallback }: IPayload, { dispatch, getState }) => {
        if (!values.username) {
            return;
        }
        const state = getState() as TAppState;
        const {
            conferenceSession: { currentConferencePin, currentConferenceId },
        } = state;
        dispatch(setAnonymousUserDataAction({ name: values.username }));
        storeUsernameToLocalStorage(values.username, currentConferenceId);
        if (successSubmitCallback) {
            successSubmitCallback(values.username);
        } else {
            if (currentConferencePin != '') {
                replace(`${EPaths.CONNECT_TO_CONFERENCE}/${currentConferenceId}`);
            } else {
                replace(`/enter/${currentConferenceId}`);
            }
        }
    },
);
export function storeUsernameToLocalStorage(username: string, conferenceId: string) {
    const data = getFromLocalStorage(conferenceId) as IConferenceLocalStorageData;
    if (data) {
        data.anonymousUsername = username;
        setToLocalStorage(conferenceId, data);
        return;
    }
    setToLocalStorage(conferenceId, { anonymousUsername: username });
}
