import { t } from '@lingui/macro';
import MembersImg from 'assets/Members.svg';
import { ConferenceButton } from 'components/ConferenceButton/ConferenceButton';
import React from 'react';
import styles from './ConferenceParticipantsButton.module.scss';
import { IOwnProps } from './types';

export const ConferenceParticipantsButton: React.FC<IOwnProps> = ({
    chatState,
    mobile,
    participantsCount,
    participantsListOpen,
    setParticipantsListOpen,
    toggleChat,
    className,
}) => {
    const toggleParticipantsListState = (): void => {
        if (chatState) {
            toggleChat(!chatState)
        }
        setParticipantsListOpen(!participantsListOpen);
    };

    return (
        <div className={`${styles.participants} ${className || ''}`}>
            <ConferenceButton
                img={MembersImg}
                text={t({ message: 'Участники', id: 'Participants' })}
                clickHandler={toggleParticipantsListState}
                mobile={mobile}
            />
            <div className={styles.membersCount}>{participantsCount}</div>
        </div>
    );
};
