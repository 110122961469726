import React, { FC } from 'react'
import { IStateProps } from './types';
import styles from './ConferenceDeviceNotification.module.scss'
import { t } from '@lingui/macro';
import { classNames } from 'utils/classNames';

export const ConferenceDeviceNotification:FC<IStateProps> = ({ deviceNotification}) => {
    const localizationText = deviceNotification?.type === 'connected'
        ? t({
            id: 'connected',
            message: '{0} подключен',
            values: { '0': deviceNotification?.device.label },
        })
        : t({
            id: 'disconnected',
            message: '{0} отключен',
            values: { '0': deviceNotification?.device.label },
        });

    return deviceNotification ? (
        <div className={classNames([styles.root, styles.highPriority])}>
            {localizationText}
        </div>
    ) : null;
}
