import { EAudioVideoComboValues } from 'constants/appSettingsStructure';
import { IConferenceInfo } from 'types/conferences/IConferenceInfo';

export const getParticipantsAudioVideoPermissions = (conferenceInfo: IConferenceInfo) => {
    const { forbidAudio, forbidVideo, videoParticipants, muteUponEntry } = conferenceInfo;
    const resultObj = { audio: EAudioVideoComboValues.ON, video: EAudioVideoComboValues.ON };

    if (forbidAudio) {
        resultObj.audio = EAudioVideoComboValues.DISABLED;
    } else if (muteUponEntry) {
        resultObj.audio = EAudioVideoComboValues.OFF;
    }

    if (forbidVideo) {
        resultObj.video = EAudioVideoComboValues.DISABLED;
    } else if (!videoParticipants) {
        resultObj.video = EAudioVideoComboValues.OFF;
    }
    return resultObj;
};