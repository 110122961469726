import { connect } from 'react-redux';
import { loadDataForConnectAndConferencePagesThunkAction } from 'thunk/conference/loadDataForConnectAndConferencePagesThunkAction';
import { TAppDispatch } from 'types/app/TAppDispatch';
import { InputUsernamePageMobile } from './InputUsernamePageMobile';
import { IDispatchProps } from './types';

const mapDispatchToProps = (dispatch: TAppDispatch): IDispatchProps => ({
    loadData: (id) =>
        dispatch(loadDataForConnectAndConferencePagesThunkAction({ id, page: 'inputUsername' })),
});

export const InputUsernamePageMobileConnected = connect(
    null,
    mapDispatchToProps,
)(InputUsernamePageMobile);
