import { createAsyncThunk } from '@reduxjs/toolkit';
import { updateUpcomingConferenceAction } from 'actions/conferencesList/updateUpcomingConferenceAction';
import { changeConferencesListFilterThunkAction } from 'thunk/conferences/changeConferencesListFilterThunkAction';
import { TAppState } from 'types/app/TAppStore';
import { IUpcomingConferenceInfo } from 'types/conferencesList/IUpcomingConferenceInfo';
import { isDateInFilterRange } from 'utils/dates/isDateInFilterRange';

interface IUpdateExistingUpcomingConferencePayload {
    newConferenceData: IUpcomingConferenceInfo | undefined;
    isRecurring?: boolean;
}

export const updateExistingUpcomingConferenceThunkAction = createAsyncThunk<
    void,
    IUpdateExistingUpcomingConferencePayload
>(
    'updateExistingUpcomingConference',
    async ({ newConferenceData, isRecurring }, { dispatch, getState }) => {
        const {
            sessionSettings: {
                conferencesFilter: { upcoming },
            },
        } = getState() as TAppState;

        if (
            newConferenceData &&
            isDateInFilterRange(upcoming, newConferenceData.timeStart) //Maybe should take timeStart from the current state
        ) {
            if (isRecurring) {
                dispatch(
                    changeConferencesListFilterThunkAction({
                        upcoming: upcoming,
                        finished: false,
                    }),
                );
            } else {
                dispatch(updateUpcomingConferenceAction(newConferenceData));
            }
        }
    },
);
