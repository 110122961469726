import { t } from '@lingui/macro';
import { resetPendingConfirmationAction } from 'actions/applicationView/resetPendingConfirmationAction';
import { setFormEditFlagAction } from 'actions/applicationView/setFormEditFlagAction';
import { FormApi } from 'final-form';
import { Dispatch, RefObject, SetStateAction } from 'react';
import { logoutThunkAction } from 'thunk/auth/logoutThunkAction';
import { showNotifyThunkAction } from 'thunk/notify/showNotifyThunkAction';
import { accountInformationSubmitThunkAction } from 'thunk/profile/accountInformationSubmitThunkAction';
import { TAppDispatch } from 'types/app/TAppDispatch';
import { TAppState } from 'types/app/TAppStore';
import { INotification } from 'types/INotification';
import { TLanguages } from 'types/TLanguages';
import { IUserData } from 'types/user/IUserData';
import { formatBytes } from 'utils/formatBytes';
import { getTextByLanguage } from 'utils/languagesOperations';
import { getTimeZoneName } from 'utils/timeZones/getTimeZoneName';
import { IAccountInformationFormRenderProps, IDispatchProps, IStateProps } from './types';
import { getBrowserTimeZone } from 'utils/timeZones/getBrowserTimeZone';

export const mapStateToProps = (state: TAppState): IStateProps => ({
    canChangeAvatar: !state.applicationConfiguration.config.ldap,
    canEditPersonalData: !state.applicationConfiguration.config.ldap,
    maxAvatarSize: state.applicationConfiguration.config.maxAvatarFileSizeByte,
    user: state.auth.currentUser,
});
export const mapDispatchToProps = (dispatch: TAppDispatch): IDispatchProps => ({
    logout: () => dispatch(logoutThunkAction()),
    onModified: (isEdit: boolean) => dispatch(setFormEditFlagAction(isEdit)),
    resetModified: () => dispatch(resetPendingConfirmationAction()),
    showNotification: (params) => dispatch(showNotifyThunkAction(params)),
    submitHandler: (data) => dispatch(accountInformationSubmitThunkAction(data)).unwrap(),
});
export const validate = (values: IAccountInformationFormRenderProps) => {
    const errors: { [key: string]: string } = {};
    const requiredFieldList = ['firstName', 'lastName', 'email'];

    for (const field of requiredFieldList) {
        const f = field as keyof IAccountInformationFormRenderProps;
        if (!values[f] || values[f] === '') {
            errors[f] = 'Required';
        }
    }
    return errors;
};

export const onChangeAvatar = (
    form: FormApi<IAccountInformationFormRenderProps>,
    avatarRef: RefObject<HTMLInputElement>,
    maxAvatarSize: number,
    showNotification: (params: INotification) => void,
    setAvatar: Dispatch<SetStateAction<string | undefined>>,
) => {
    const files = avatarRef.current?.files;
    if (!files?.length) {
        return;
    }
    if (files[0].size <= maxAvatarSize) {
        const file = URL.createObjectURL(files[0]);
        form.change('avatarWasChanged', true);
        setAvatar(file);
        return;
    }
    showNotification({
        text: t({
            id: 'avatar.error.maxSize',
            message: 'Максимальный размер файла {size}',
            values: {
                size: formatBytes(maxAvatarSize),
            },
        }),
        timeout: 3000,
        type: 'error',
    });
};

export const getInitialValues = (
    user: IUserData | null,
): Partial<IAccountInformationFormRenderProps> => ({
    firstName: user?.name?.first || '',
    lastName: user?.name?.last || '',
    email: user?.contact.email || '',
    phone: user?.contact.phone || '',
    timeZone: getTimeZoneName(user?.timeZone || getBrowserTimeZone()),
    language: getTextByLanguage(user?.language as TLanguages),
    avatarWasChanged: false,
});

export const getStyleAvatarBackground = (avatar: string | null | undefined) => {
    return avatar ? 'rgba(0,0,0,0)' : '#85B66F';
};
