import { createAction } from '@reduxjs/toolkit';
import { EConferencesActions } from 'constants/actions/EConferencesActions';
import { IConferenceUser } from 'types/IConferenceUser';

interface IPayload {
    users: IConferenceUser[]
    id: string
}

export const setConferencesInvitedUsersAction = createAction<IPayload>(
    EConferencesActions.SET_CONFERENCES_INVITED_USERS,
);
