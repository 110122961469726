import { createAsyncThunk } from '@reduxjs/toolkit';
import { setCurrentLanguageAction } from 'actions/applicationView/setCurrentLanguageAction';
import { setCurrentUserDataAction } from 'actions/auth/setCurrentUserDataAction';
import { setRememberMeRequestStatusAction } from 'actions/auth/setRememberMeRequestStatusAction';
import { getNetworkService } from 'api/getNetworkService';
import { getCurrentUserProfileDataApiCall } from 'api/profile/getCurrentUserProfileDataApiCall';
import { batch } from 'react-redux';
import { subscribeToAppEventsThunkAction } from 'thunk/socket/subscribeToAppEventsThunkAction';
import { TAppState } from 'types/app/TAppStore';
import { convertUserDataFromServer } from 'utils/users/convertUserDataFromServer';
import { getApplicationLicenseThunkAction } from './getApplicationLicenseThunkAction';
import { getAppSettingsThunkAction } from './getAppSettingsThunkAction';

export const getCurrentUserProfileDataThunkAction = createAsyncThunk(
    'getCurrentUserProfileData',
    async (_: void, { dispatch, getState }) => {
        const {
            auth: { rememberMeRequestStatus },
            applicationView: { currentLanguage },
        } = getState() as TAppState;
        if (rememberMeRequestStatus === 'initial') {
            dispatch(setRememberMeRequestStatusAction('requested'));
        }
        await dispatch(getAppSettingsThunkAction({}));
        const response = await getCurrentUserProfileDataApiCall(getNetworkService());
        if (response.success && !response.data?.anonymous) {
            const userData = convertUserDataFromServer(response.data);
            batch(() => {
                dispatch(setCurrentUserDataAction(userData));
                dispatch(setRememberMeRequestStatusAction('success'));
                if (userData.language && userData.language !== currentLanguage) {
                    dispatch(setCurrentLanguageAction(userData.language));
                }
            });
            dispatch(getApplicationLicenseThunkAction());
            dispatch(subscribeToAppEventsThunkAction(userData.id));
        } else {
            dispatch(setRememberMeRequestStatusAction('error'));
        }
    },
);
