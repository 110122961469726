import { IOwnProps } from './types';
import styles from './DynamicFormSection.module.scss';

export const DynamicFormSection: React.FC<IOwnProps> = ({ sectionTitle, children }) => {
    return (
        <fieldset className={styles.formSection}>
            {sectionTitle && <legend className={styles.formSection__title}>{sectionTitle}</legend>}
            <div className={styles.formSection__content}>{children}</div>
        </fieldset>
    );
};
