import { SimpleConferenceHeaderConnected } from './SimpleConferenceHeader';
import styles from './SimpleConferencePageTemplate.module.scss';

export const SimpleConferencePageTemplate: React.FC = ({ children }) => {
    return (
        <div className={styles.page} id='conferencePage'>
            <div className={styles.wrapper}>
                <SimpleConferenceHeaderConnected />
                {children}
            </div>
        </div>
    );
};
