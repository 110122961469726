import { Field } from 'react-final-form';
import styles from './RadioButton.module.scss';
import { IOwnProps } from './types';

export const RadioButton: React.FC<IOwnProps> = ({ text, name, value, id, format, parse }) => (
    <Field name={name}
        type='radio'
        value={value}
        format={format}
        parse={parse}
    >
        {({ input }) => (
            <div className={styles.radioButton}>
                <input id={id} type='radio' {...input} />
                <label htmlFor={id}>{text}</label>
            </div>
        )}
    </Field>
);
