import { convertRecordDuration } from 'utils/records/convertRecordDuration';
import styles from '../ConferenceReport.module.scss';
import { RecordLoader } from '../RecordLoader';
import { IOwnProps } from './types';

export const ReportRecord: React.FC<IOwnProps> = ({ record, reportData, recordPreviewRender }) => {
    return (
        <div className={styles.record}>
            <div
                className={`${styles.record__preview} ${
                    record.recordReady && record.record?.success
                        ? styles.preview_clickable
                        : styles.record_fixHeight
                }`}>
                {!record.recordReady || !record.record?.success ? (
                    <RecordLoader />
                ) : (
                    recordPreviewRender(record)
                )}
            </div>
            <div className={styles.record__info}>
                <span className={styles.record__infoName}>{reportData.name}</span>
                <span className={styles.record__infoDuration}>
                    {convertRecordDuration(record.record?.duration)}
                </span>
            </div>
        </div>
    );
};
