import { INetworkService } from 'types/api/INetworkService';

interface IPayload {
    conferenceId: string;
    isStarted?: boolean;
}

export const sendConferenceScreenSharingApiCall = (
    networkService: INetworkService,
    { conferenceId, isStarted }: IPayload,
) =>
    networkService.post({
        url: `/api/conferences/${conferenceId}/screen-sharing/${isStarted ? 'start' : 'finish'}`,
    });
