import { createAction } from '@reduxjs/toolkit';
import { EApplicationViewActions } from 'constants/actions/EApplicationViewActions';
import { IEmotion } from 'types/IEmotion';

interface IPayload {
    emotionToShow: IEmotion | null;
    participantId: string;
}

export const setParticipantEmotionToShowAction = createAction<IPayload>(
    EApplicationViewActions.SET_PARTICIPANT_EMOTION_TO_SHOW,
);
