import { connect } from 'react-redux';
import { TAppState } from 'types/app/TAppStore';
import { ParticipantIcons } from './ParticipantIcons';
import { IOwnProps, IStateProps } from './types';

const mapStateToProps = (state: TAppState, { participantId }: IOwnProps): IStateProps => {
    const {
        janus: { ownParticipantId, localStreamProps, publishers },
        applicationView: { participantsAudio, participantsVideo },
    } = state;
    const isCurrentUser = ownParticipantId === participantId;
    const useAudio = isCurrentUser ? localStreamProps.useAudio : participantsAudio[participantId];
    const useVideo = isCurrentUser ? localStreamProps.useVideo : participantsVideo[participantId];
    const videoPermitted = isCurrentUser
        ? localStreamProps.videoPermitted
        : !!publishers[participantId]?.audioVideoProps.videoPermitted;
    const audioPermitted = isCurrentUser
        ? localStreamProps.audioPermitted
        : !!publishers[participantId]?.audioVideoProps.audioPermitted;
    return { useAudio, useVideo, audioPermitted,videoPermitted};
};
export const ParticipantIconsConnected = connect(mapStateToProps)(ParticipantIcons);
