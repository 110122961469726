import { EVideoResolution } from 'constants/EVideoResolution';
import { IAppSettingsTyped } from 'types/app/IAppSettings';
import { IUserDevice } from 'types/IUserDevice';
import { IChangeJanusStreamProps } from 'types/janus/IChangeJanusStreamProps';
import { IJanusContext } from 'types/janus/IJanusContext';
import { IJanusLocalStreamProps } from 'types/janus/IJanusLocalStreamProps';
import { IJanusConfigureMessage } from 'types/janus/IJanusMessage';
import { IJanusAudioTrack, IJanusTrack, IJanusVideoTrack } from 'types/janus/IJanusTrack';
import { IJanusTransceivers } from 'types/janus/IJanusTransceivers';
import { log } from 'utils/log';
import { janusVideoRoomTracksLogger } from 'utils/logger';
import { calculateWidthAndHeight } from './helpers/calculateWidthAndHeight';
import { IStreamActions } from './IStreamOperations';
import { needToUnpublish } from './needToUnpublish';

const getAudioTrackAddOrReplace = (
    transceivers: IJanusTransceivers,
    audioDevice?: IUserDevice,
): IJanusAudioTrack => {
    const { audio_mid } = transceivers;
    const audioTrack: IJanusAudioTrack = { type: 'audio', capture: true };
    if (audio_mid) {
        audioTrack.mid = audio_mid;
        audioTrack.replace = true;
    } else {
        audioTrack.add = true;
    }
    if (audioDevice) {
        audioTrack.capture = { deviceId: audioDevice.deviceId };
    }
    return audioTrack;
};

const getVideoTrackAddOrReplace = (
    transceivers: IJanusTransceivers,
    type: 'video' | 'screen',
    appSettings: Pick<IAppSettingsTyped, 'resolution'> | null,
    videoDevice?: IUserDevice,
): IJanusVideoTrack => {
    const { video_mid } = transceivers;
    const resolution =
        type === 'video'
            ? appSettings
                ? appSettings.resolution
                : EVideoResolution.STD_RES
            : EVideoResolution.FHD_RES;
    const { width, height } = calculateWidthAndHeight(resolution);
    const capture: any = { width, height };
    if (type === 'screen') {
        capture.frameRate = {
            max: 10,
        };
    }
    if (videoDevice && type === 'video') {
        capture.deviceId = videoDevice.deviceId;
    }
    const videoTrack: IJanusVideoTrack = { type, capture };
    if (video_mid) {
        videoTrack.mid = video_mid;
        videoTrack.replace = true;
    } else {
        videoTrack.add = true;
    }
    return videoTrack;
};

export const calculateOfferData = (
    propsChanges: Partial<IChangeJanusStreamProps>,
    currentProps: IJanusLocalStreamProps,
    appSettings: Pick<IAppSettingsTyped, 'resolution'> | null,
    janusCtx: Pick<IJanusContext, 'published' | 'transceivers'>,
): {
    tracks: IJanusTrack[];
    message: IJanusConfigureMessage;
    streamActions: IStreamActions;
    replaceTracks: IJanusTrack[];
} => {
    const tracks: IJanusTrack[] = [];
    const message: IJanusConfigureMessage = {};
    const streamActions: IStreamActions = {};
    const replaceTracks: IJanusTrack[] = [];
    const {
        transceivers,
        transceivers: { audio_mid, video_mid },
    } = janusCtx;
    janusVideoRoomTracksLogger.debug({ propsChange: { ...propsChanges }, currentProps });

    // No changes?
    if (
        !currentProps.useAudio &&
        !currentProps.useVideo &&
        !currentProps.screenShared &&
        propsChanges.useAudio === undefined &&
        propsChanges.useVideo === undefined &&
        propsChanges.screenShared === undefined
    ) {
        return { tracks, message, streamActions, replaceTracks };
    }

    // Check that should be unpublished
    const unpublish = needToUnpublish(propsChanges, currentProps);
    if (unpublish) {
        const streamActions: IStreamActions = janusCtx.published ? { unpublish: true } : {};
        janusCtx.transceivers = {};
        return { tracks, message, streamActions, replaceTracks };
    }

    if (propsChanges.audioDevice) {
        if (currentProps.useAudio) {
            if (audio_mid) {
                replaceTracks.push({ type: 'audio', mid: audio_mid, remove: true });
                replaceTracks.push(
                    getAudioTrackAddOrReplace(transceivers, propsChanges.audioDevice),
                );
            }
        }
    }

    // Check audio
    if (propsChanges.useAudio) {
        if (currentProps.useAudio) {
            // Nothing to do
        } else {
            // Audio is switched on
            tracks.push(getAudioTrackAddOrReplace(transceivers, currentProps.audioDevice));
        }
    } else if (propsChanges.useAudio === false) {
        if (currentProps.useAudio && audio_mid) {
            // Audio is switched off
            tracks.push({ type: 'audio', mid: audio_mid, remove: true });
        } else {
            // Nothing to do
        }
    }

    if (propsChanges.videoDevice) {
        if (currentProps.useVideo && video_mid) {
            replaceTracks.push({ type: 'video', mid: video_mid, remove: true });
            replaceTracks.push(
                getVideoTrackAddOrReplace(
                    transceivers,
                    'video',
                    appSettings,
                    propsChanges.videoDevice,
                ),
            );
        }
    }
    // Check video
    if (propsChanges.useVideo) {
        if (propsChanges.screenShared) {
            // {useVideo:true, screenShared: true}
            log.error('Unsupported condition');
        } else if (propsChanges.screenShared === false) {
            // {useVideo:true, screenShared: false}
            if (currentProps.useVideo && currentProps.screenShared) {
                log.error('Unsupported condition');
            } else if (!currentProps.useVideo && currentProps.screenShared) {
                // tracks.push({ type: 'screen', remove: true });
                const track = getVideoTrackAddOrReplace(
                    transceivers,
                    'video',
                    appSettings,
                    currentProps.videoDevice,
                );
                if (track.replace && video_mid) {
                    replaceTracks.push({ type: 'video', mid: video_mid, remove: true });
                    tracks.push(track);
                } else {
                    tracks.push(track);
                }
            } else if (!currentProps.useVideo && !currentProps.screenShared) {
                tracks.push(
                    getVideoTrackAddOrReplace(
                        transceivers,
                        'video',
                        appSettings,
                        currentProps.videoDevice,
                    ),
                );
            } else if (currentProps.useVideo && !currentProps.screenShared) {
                log.error('Video already send');
            }
        } else {
            // {useVideo:true}
            if (currentProps.useVideo && currentProps.screenShared) {
                log.error('Unsupported condition');
            } else if (!currentProps.useVideo && currentProps.screenShared) {
                tracks.push(
                    getVideoTrackAddOrReplace(
                        transceivers,
                        'video',
                        appSettings,
                        currentProps.videoDevice,
                    ),
                );
            } else if (!currentProps.useVideo && !currentProps.screenShared) {
                tracks.push(
                    getVideoTrackAddOrReplace(
                        transceivers,
                        'video',
                        appSettings,
                        currentProps.videoDevice,
                    ),
                );
            } else if (currentProps.useVideo && !currentProps.screenShared) {
                log.error('Video already send');
            }
        }
    } else if (propsChanges.useVideo === false) {
        if (propsChanges.screenShared) {
            // {useVideo:false, screenShared: true}
            if (!currentProps.useVideo && !currentProps.screenShared) {
                tracks.push(
                    getVideoTrackAddOrReplace(
                        transceivers,
                        'screen',
                        appSettings,
                        currentProps.videoDevice,
                    ),
                );
            } else if (currentProps.useVideo && !currentProps.screenShared) {
                // tracks.push({ type: 'video', remove: true });
                const track = getVideoTrackAddOrReplace(
                    transceivers,
                    'screen',
                    appSettings,
                    currentProps.videoDevice,
                );
                if (track.replace && video_mid) {
                    replaceTracks.push({ type: 'video', mid: video_mid, remove: true });
                    tracks.push(track);
                } else {
                    tracks.push(track);
                }
            } else if (!currentProps.useVideo && currentProps.screenShared) {
                log.error('Screen already shared');
            } else if (currentProps.useVideo && currentProps.screenShared) {
                log.error('Unsupported condition');
            }
        } else if (propsChanges.screenShared === false) {
            // {useVideo:false, screenShared: false}
            if (currentProps.useVideo && currentProps.screenShared) {
                log.error('Unsupported condition');
            } else if (currentProps.useVideo && !currentProps.screenShared) {
                tracks.push({
                    type: 'video',
                    mid: video_mid,
                    remove: true,
                });
            } else if (!currentProps.useVideo && currentProps.screenShared) {
                tracks.push({
                    type: 'screen',
                    mid: video_mid,
                    remove: true,
                });
            }
        } else {
            // {useVideo:false}
            if (currentProps.useVideo && currentProps.screenShared) {
                log.error('Unsupported condition');
            } else if (currentProps.useVideo && !currentProps.screenShared) {
                tracks.push({ type: 'video', mid: video_mid, remove: true });
            } else if (!currentProps.useVideo && currentProps.screenShared) {
                tracks.push({ type: 'screen', mid: video_mid, remove: true });
            } else if (!currentProps.useVideo && !currentProps.screenShared) {
                // FIXME
            }
        }
    } else {
        if (propsChanges.screenShared) {
            // {screenShared: true}
            if (currentProps.useVideo && currentProps.screenShared) {
                log.error('Unsupported condition');
            } else if (currentProps.useVideo && !currentProps.screenShared) {
                // tracks.push(/{ type: 'video', remove: true });
                const track = getVideoTrackAddOrReplace(
                    transceivers,
                    'screen',
                    appSettings,
                    currentProps.videoDevice,
                );
                if (track.replace && transceivers.video_mid) {
                    replaceTracks.push({ type: 'video', mid: video_mid, remove: true });
                    tracks.push(track);
                } else {
                    tracks.push(track);
                }
            } else if (!currentProps.useVideo && currentProps.screenShared) {
                log.error('Screen already shared');
            } else if (!currentProps.useVideo && !currentProps.screenShared) {
                tracks.push(
                    getVideoTrackAddOrReplace(
                        transceivers,
                        'screen',
                        appSettings,
                        currentProps.videoDevice,
                    ),
                );
            }
        } else if (propsChanges.screenShared === false) {
            if (currentProps.useVideo && currentProps.screenShared) {
                log.error('Unsupported condition');
            } else if (currentProps.useVideo && !currentProps.screenShared) {
                log.error('Screen was not shared');
            } else if (!currentProps.useVideo && currentProps.screenShared) {
                tracks.push({ type: 'screen', mid: video_mid, remove: true });
            } else if (!currentProps.useVideo && !currentProps.screenShared) {
                log.error('Screen was not shared');
            }
        } else {
            // {}
            // Nothing to do?
        }
    }

    // calculate message
    message.request = 'configure';
    // message.audio =
    //     propsChanges.useAudio === undefined ? currentProps.useAudio : propsChanges.useAudio;
    // message.video =
    //     propsChanges.useVideo === undefined && propsChanges.screenShared === undefined
    //         ? currentProps.useVideo || (currentProps.screenShared ?? false)
    //         : propsChanges.useVideo || (propsChanges.screenShared ?? false);

    // if (janusCtx.acodec && message.audio) {
    //     message.audiocodec = janusCtx.acodec;
    // }
    // if (janusCtx.vcodec && message.video) {
    //     message.videocodec = janusCtx.vcodec;
    // }

    return { tracks, streamActions, message, replaceTracks };
};
