import OutsideClick from 'components/common/OutsideClick/OutsideClick';
import React from 'react';
import styles from './ConfirmDialogMobile.module.scss';
import { TOwnMobileProps } from './types';

export const ConfirmDialogMobile: React.FC<TOwnMobileProps> = ({
    handlePrimaryAction,
    handleSecondaryAction,
    handleClose,
    textPrimaryAction,
    textSecondaryAction,
    textHeader,
    theme,
}) => (
    <div className={styles.background}>
        <OutsideClick onOutsideClick={handleClose}>
            <div className={`${styles.dialog} ${theme ? styles[`dialog_${theme}`] : ''}`}>
                <div className={styles.header}>{textHeader}</div>
                <div className={styles.footer}>
                    {textSecondaryAction && (
                        <button className={styles.secondaryAction} onClick={handleSecondaryAction}>
                            {textSecondaryAction}
                        </button>
                    )}
                    <button className={styles.primaryAction} onClick={handlePrimaryAction}>
                        {textPrimaryAction}
                    </button>
                </div>
            </div>
        </OutsideClick>
    </div>
);
