import { createAsyncThunk } from '@reduxjs/toolkit';
import { changeRecordsFilterAction } from 'actions/sessionSettings/changeRecordsFilterAction';
import { batch } from 'react-redux';
import { TAppState } from 'types/app/TAppStore';
import { IRecordsFilter } from 'types/IRecordsFilters';
import { getStartEndTimeUtc0 } from 'utils/timeZones/getStartEndTimeUtc0';
import { getConferencesRecordsThunkAction } from './getConferencesRecordsThunkAction';

export const changeRecordsListFilterThunkAction = createAsyncThunk(
    'changeRecordsListFilter',
    async (filters: IRecordsFilter, { dispatch, getState }) => {
        batch(async () => {
            dispatch(changeRecordsFilterAction(filters));
            const {
                sessionSettings: { recordsFilter: newFilters },
            } = getState() as TAppState;
            const [startISO, endISO] = getStartEndTimeUtc0(newFilters.from, newFilters.to);
            await dispatch(getConferencesRecordsThunkAction({ from: startISO, to: endISO }));
        });
    },
);
