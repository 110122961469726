import { JoinConfFormConnected } from 'components/forms/JoinConfForm/JoinConfFormConnected';
import styles from './JoinConfDialog.module.scss';
import { Cross } from 'components/common/Cross/Cross';
import { IOwnProps } from './types';

export const JoinConfDialog: React.FC<IOwnProps> = ({ handleClose }) => (
    <div className={styles.background} onClick={(e) => { e.stopPropagation(); handleClose(); }}>
        <div className={styles.dialog} onClick={(e) => e.stopPropagation()}>
            <JoinConfFormConnected />
            <div className={styles.cross}>
                <Cross onClick={handleClose} />
            </div>
        </div>
    </div>
);