import { createAsyncThunk } from '@reduxjs/toolkit';
import { leaveConferenceApiCall } from 'api/conference/leaveConferenceApiCall';
import { getNetworkService } from 'api/getNetworkService';
import { showNotifyThunkAction } from 'thunk/notify/showNotifyThunkAction';
import { TAppState } from 'types/app/TAppStore';
import { getDefaultErrorNotification } from 'utils/getDefaultErrorNotification';

export const leaveFromLobbyThunkAction = createAsyncThunk(
    'leaveFromLobby',
    async (_: void, { getState, dispatch }) => {
        const {
            conferenceSession: { currentConferenceId },
        } = getState() as TAppState;
        if (currentConferenceId) {
            const response = await leaveConferenceApiCall(getNetworkService(), {
                conferenceId: currentConferenceId,
            });
            if (!response.success) {
                dispatch(showNotifyThunkAction(getDefaultErrorNotification()));
            }
        }
    },
);
