import { Trans } from '@lingui/react';
import { Avatar } from 'components/common/Avatar/Avatar';
import { useEffect } from 'react';
import { getUserColor } from 'utils/users/getUserColor';
import { getUserInitials } from 'utils/users/getUserInitials';
import styles from './LobbyAdminDialog.module.scss';
import { IOwnProps } from './types';
import { showNotifies } from './utils';

export const LobbyAdminDialog: React.FC<IOwnProps> = ({
    username,
    isAudioNotification,
    handleAccept,
    handleDenied,
}) => {
    const [firstName, lastName] = username.split('');
    const initials = getUserInitials({ firstName, lastName });

    useEffect(() => {
        const cleanUpCallback = showNotifies(isAudioNotification);

        return () => {
            cleanUpCallback && cleanUpCallback();
        };
    }, []);

    return (
        <div className={styles.dialog}>
            <div className={styles.header}>
                <Avatar initials={initials} userBgColor={getUserColor(username)} />
                <div className={styles.info}>
                    <div className={styles.fullName}>{username}</div>
                    <div className={styles.text}>
                        <Trans
                            message='хочет подключиться к конференции'
                            id='wants to connect to the conference'
                        />
                    </div>
                </div>
            </div>
            <div className={styles.footer}>
                <div className={styles.allow} onClick={handleAccept}>
                    <Trans message='Разрешить' id='allow' />
                </div>
                <div className={styles.denied} onClick={handleDenied}>
                    <Trans message='Отмена' id='deny' />
                </div>
            </div>
        </div>
    );
};
