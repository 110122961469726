import { Input } from 'components/common/Input';
import OutsideClick from 'components/common/OutsideClick';
import { useEffect, useRef, useState } from 'react';
import styles from './DropDownInput.module.scss';

interface IPayload {
    name: string;
    placeholder?: string;
    className?: string;
    errors: boolean;
    type: string;
    disabled: boolean;
    listItems: string[];
    onChanged: (name: any, value: any) => void;
    getValue: (name: any) => any;
    validateOnBlur: (value: string) => boolean | null;
    formatting?: (value: string) => string;
    initialValue: string;
}
export const DropDownInput = ({
    name,
    placeholder,
    className,
    errors,
    type = 'text',
    validateOnBlur,
    disabled,
    listItems,
    onChanged,
    formatting,
    getValue,
    initialValue,
}: IPayload) => {
    const [isShowDropdown, setIsShowDropdown] = useState(false);
    const [errorsToShow, setErrorsToShow] = useState(errors);
    const [currentValue, setCurrentValue] = useState(initialValue);
    const listRef = useRef(null);
    const activeItem = useRef(null);

    useEffect(() => {
        if (isShowDropdown) {
            scrollToActiveItem();
        }
    }, [isShowDropdown]);

    const validation = (currentValue: string) => {
        const resultValidation = validateOnBlur(currentValue);
        if (resultValidation) {
            errorsToShow && setErrorsToShow(false);
        } else if (resultValidation !== null) {
            setErrorsToShow(true);
        }
        return resultValidation;
    };

    const setValue = (value: string) => {
        if (formatting) {
            onChanged(name, formatting(value));
        } else {
            onChanged(name, value);
        }
    };

    const blurHandlers = (currentValue: string) => {
        if (validation(currentValue)) {
            setValue(currentValue);
        }
    };

    const onOutsideClick = () => {
        isShowDropdown && setIsShowDropdown(false);
    };

    const scrollToActiveItem = () => {
        if (listRef.current && activeItem.current) {
            const element = activeItem.current as unknown as HTMLElement;
            const container = listRef.current as unknown as HTMLElement;
            const scroll = container.scrollHeight > container.clientHeight;
            scroll && element.scrollIntoView({ behavior: 'smooth', block: 'center' });
        }
    };

    const onOpenList = () => {
        if (!isShowDropdown) {
            setCurrentValue(getValue(name).value);
            setIsShowDropdown(true);
        }
    };

    return (
        <OutsideClick onOutsideClick={onOutsideClick}>
            <div className={styles.inputWithListBlock}>
                <Input
                    name={name}
                    placeholder={placeholder}
                    className={className}
                    errorWithoutConditions={errors || errorsToShow}
                    type={type}
                    disabled={disabled}
                    handleClick={onOpenList}
                    handleBlur={blurHandlers}
                    handleKeyDown={(e) => e.code === 'Tab' && onOutsideClick()}
                />
                {isShowDropdown ? (
                    <ul className={styles.list} ref={listRef}>
                        {listItems.map((item, i) => (
                            <li
                                key={`${name}-${i}`}
                                onClick={() => {
                                    validation(item);
                                    setValue(item);
                                    setIsShowDropdown(false);
                                }}
                                className={styles.listItem}
                                ref={currentValue === item ? activeItem : null}>
                                {item}
                            </li>
                        ))}
                    </ul>
                ) : null}
            </div>
        </OutsideClick>
    );
};
