import { connect } from 'react-redux';
import { TAppDispatch } from 'types/app/TAppDispatch';
import { IDispatchProps } from './types';
import { sendUserInviteThunkAction } from 'admin-app/thunk/sendUserInviteThunkAction';
import { InviteForm } from './InviteForm';

const mapDispatchToProps = (dispatch: TAppDispatch): IDispatchProps => ({
    sendUserInvite: (email) => dispatch(sendUserInviteThunkAction({ email })).unwrap(),
});

export const InviteFormConnected = connect(null, mapDispatchToProps)(InviteForm);
