import { Button } from 'admin-app/components/common/Button';
import { IOwnProps } from './types';
import styles from './WebHookList.module.scss';
import NetworkImg from '../../../../../assets/Network.svg';
import DeleteImg from '../../../../../assets/Delete.svg';
import EditImg from '../../../../../assets/Edit.svg';

export const WebHookList: React.FC<IOwnProps> = ({ hookList, editHook, dellHook }) => (
    <ul className={styles.list}>
        {hookList.map((hook) => (
            <li key={hook.id} className={styles.list__item}>
                <img src={NetworkImg} width={22} alt='Network' />
                <span className={styles.list__itemUrl}>{hook.url}</span>
                <div className={styles.list__itemControls}>
                    <Button className={styles.list__itemButton} onClick={() => editHook(hook)}>
                        <img src={EditImg} width={15} />
                    </Button>
                    <Button className={styles.list__itemButton} onClick={() => dellHook(hook)}>
                        <img src={DeleteImg} width={17} />
                    </Button>
                </div>
            </li>
        ))}
    </ul>
);
