import { createAsyncThunk } from '@reduxjs/toolkit';
import { setCurrentConferenceCreatorAction } from 'actions/conferenceSession/setCurrentConferenceCreatorAction';
import { setCurrentConferenceIdAction } from 'actions/conferenceSession/setCurrentConferenceIdAction';
import { setCurrentConferenceOwnerAction } from 'actions/conferenceSession/setCurrentConferenceOwnerAction';
import { setCurrentConferencePinAction } from 'actions/conferenceSession/setCurrentConferencePinAction';
import { setCurrentSessionIdAction } from 'actions/conferenceSession/setCurrentSessionIdAction';
import { batch } from 'react-redux';
import { TAppState } from 'types/app/TAppStore';
import { IConferenceCreator } from 'types/IConferenceCreator';

export interface IPayload {
    conferenceId: string;
    pin?: string;
    sessionId?: string;
    creator?: IConferenceCreator;
}

export const setCurrentConferenceDataThunkAction = createAsyncThunk(
    'setCurrentConferenceData',
    async (
        { creator, conferenceId, sessionId = '', pin = '' }: IPayload,
        { dispatch, getState },
    ) => {
        const {
            auth: { currentUser },
        } = getState() as TAppState;
        let isConferenceOwner = false;
        if (creator?.userId && currentUser?.id && creator.userId === currentUser?.id) {
            isConferenceOwner = true;
        }
        batch(() => {
            dispatch(setCurrentConferenceOwnerAction(isConferenceOwner));
            dispatch(setCurrentConferenceIdAction(conferenceId));
            dispatch(setCurrentConferencePinAction(pin));
            dispatch(setCurrentSessionIdAction(sessionId));
            creator ? dispatch(setCurrentConferenceCreatorAction(creator)) : false;
        });
    },
);
