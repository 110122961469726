import { connect } from 'react-redux';
import { getAndCheckConferenceForErrorsThunkAction } from 'thunk/conference/getAndCheckConferenceForErrorsThunkAction';
import { prepareToConnectToConferenceThunkAction } from 'thunk/conference/prepareToConnectToConferenceThunkAction';
import { TAppDispatch } from 'types/app/TAppDispatch';
import { TAppState } from 'types/app/TAppStore';
import { redirect } from 'utils/redirect';
import { JoinConfForm } from './JoinConfForm';
import { IDispatchProps, IStateProps } from './types';

const mapStateToProps = (state: TAppState): IStateProps => ({
    anonymous: state.auth.anonymous,
});

const mapDispatchToProps = (dispatch: TAppDispatch): IDispatchProps => ({
    getConference: (id) => dispatch(getAndCheckConferenceForErrorsThunkAction(id)),
    prepareToConnectToConference: (conferenceId) =>
        dispatch(prepareToConnectToConferenceThunkAction({ conferenceId })),
    goToConnectByLinkPage: (path) => redirect(path),
});

export const JoinConfFormConnected = connect(mapStateToProps, mapDispatchToProps)(JoinConfForm);
