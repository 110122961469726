import { useEffect, useState } from 'react';
import styles from './StatusLabel.module.scss';
import { IOwnProps } from './types';

export const StatusLabel: React.FC<IOwnProps> = ({
    text,
    className,
    fadeOutCallback,
    children,
}) => {
    const [isShow, setIsShow] = useState(!!text);
    useEffect(() => setIsShow(!!text));
    if (!isShow) {
        return null;
    }    
    const cName = [styles.label];
    if (!text) {
        cName.push(styles.itemFadeOut);
    }
    if (className) {
        cName.push(className);
    }
    return (
        <div
            className={cName.join(' ')}
            onAnimationEnd={() => {
                if (fadeOutCallback) {
                    fadeOutCallback();
                }
            }}>
            {children ? children : text}
        </div>
    );
};
