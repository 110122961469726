import { IAppSettings } from 'types/app/IAppSettings';

export const settingsMigration = (appSetting: any):IAppSettings => {
    if (appSetting.videoParticipants === true || appSetting.videoParticipants === false) {
        appSetting.videoParticipants = appSetting.videoParticipants ? 'on' : 'off'
    }
    if (appSetting.audioParticipants === true || appSetting.audioParticipants === false) {
        appSetting.audioParticipants = appSetting.audioParticipants ? 'on' : 'off'
    }
    return appSetting;
}
