import { connect } from 'react-redux';
import { hideCurrentNotifyThunkAction } from 'thunk/notify/showNotifyThunkAction';
import { TAppDispatch } from 'types/app/TAppDispatch';
import { TAppState } from 'types/app/TAppStore';
import { NotificationPanel } from './NotificationPanel';
import { IDispatchProps, IStateProps } from './types';

const mapStateToProps = (state: TAppState): IStateProps => ({
    mobile: state.applicationView.deviceProps.isTabletOrMobile,
    notification: state.applicationView.notificationToShow,
});

const mapDispatchToProps = (dispatch: TAppDispatch): IDispatchProps => ({
    removeNotification: () => dispatch(hideCurrentNotifyThunkAction()),
});

export const NotificationPanelConnected = connect(
    mapStateToProps,
    mapDispatchToProps,
)(NotificationPanel);
