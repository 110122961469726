import { IOwnProps } from './types';
import styles from '../ConferenceChatMessageContent.module.scss';
import React from 'react';
import { IText, textToLinks } from './utils';

export const ConferenceChatMessageWithLinks: React.FC<IOwnProps> = ({ text }) => {
    const words = textToLinks(text);
    if (words.length === 1 && !words[0].href) {
        return <>{words[0].text}</>;
    }
    return (
        <>
            {words.map(({ text, href }: IText, key: number) =>
                href ? (
                    <a key={key} href={href} target='_blank' className={styles.link}>
                        {text}
                    </a>
                ) : (
                    <span key={key}>{text}</span>
                ),
            )}
        </>
    );
};
