import { t } from '@lingui/macro';
import { Button } from 'components/common/Button';
import { ConferenceUsersConnected } from 'pages/AccountPage/AccountConferencesPage/ConferencesList/ConferenceUsers';
import styles from '../../UpcomingConferenceMobile.module.scss';
import { IOwnProps } from './types';

export const UpcomingConferenceInfoFooterMobile: React.FC<IOwnProps> = ({
    creatorFullName,
    invitedUsers,
    conferenceResultId,
    isStartedConference,
    goToConnectConference,
}) => {
    return (
        <div className={styles.footer}>
            <div className={styles.footer__left}>
                <div>{creatorFullName}</div>
                {invitedUsers && (
                    <div className={styles.footer__invitedUsers}>
                        <ConferenceUsersConnected
                            usersData={{
                                emails: invitedUsers,
                            }}
                        />
                    </div>
                )}
            </div>
            <div className={styles.footer__right}>
                {isStartedConference && (
                    <Button
                        styleType='primaryAction'
                        caption={t({
                            id: 'Join',
                            message: 'Присоединиться',
                        })}
                        className={styles.button}
                        clickHandler={() => goToConnectConference(conferenceResultId)}
                    />
                )}
            </div>
        </div>
    );
};
