import { createAsyncThunk } from '@reduxjs/toolkit';
import { storeUpcomingConferencesAction } from 'actions/conferencesList/storeUpcomingConferencesAction';
import { setConferenceIdsToShowAction } from 'actions/sessionSettings/setConferenceIdsToShowAction';
import { deleteConferenceApiCall } from 'api/conference/deleteConferenceApiCall';
import { getNetworkService } from 'api/getNetworkService';
import { batch } from 'react-redux';
import { showNotifyThunkAction } from 'thunk/notify/showNotifyThunkAction';
import { TAppState } from 'types/app/TAppStore';
import { IUpcomingConferenceInfo } from 'types/conferencesList/IUpcomingConferenceInfo';
import { getDefaultErrorNotification } from 'utils/getDefaultErrorNotification';

export const deleteConferenceThunkAction = createAsyncThunk<Promise<boolean>, string>(
    'deleteConference',
    async (id, { dispatch, getState }) => {
        const response = await deleteConferenceApiCall(getNetworkService(), id);
        if (response.success) {
            const {
                conferencesList: { upcomingConferences },
            } = getState() as TAppState;
            const idsToShow: string[] = [];
            const upcomingConferencesNew: IUpcomingConferenceInfo[] = [];
            for (const confId in upcomingConferences) {
                const conference = upcomingConferences[confId];
                if (conference.seriesId !== id && conference.id !== id) {
                    idsToShow.push(confId);
                    upcomingConferencesNew.push(conference);
                }
            }
            batch(() => {
                dispatch(storeUpcomingConferencesAction(upcomingConferencesNew));
                dispatch(setConferenceIdsToShowAction(idsToShow));
            });
        } else {
            dispatch(showNotifyThunkAction(getDefaultErrorNotification()));
        }
        return response.success;
    },
);
