import BellImg from 'assets/Bell.svg';
import LogoWhiteMobileImg from 'assets/LogoWhiteMobile.svg';
import styles from './AccountPageMobileHeader.module.scss';
import { IStateProps } from './types';

export const AccountPageMobileHeader: React.FC<IStateProps> = () => (
    <div className={styles.header}>
        <img src={LogoWhiteMobileImg} alt='Logo' />
        <img src={BellImg} alt='Notifications' />
    </div>
);
