import { addConferenceChatMessagesAction } from 'actions/chat/addConferenceChatMessagesAction';
import { setConferenceChatMessageStatus } from 'actions/chat/setConferenceChatMessageStatus';
import { EChatMessageStatus } from 'constants/EChatMessageStatus';
import { EChatMessageType } from 'constants/EChatMessageType';
import { applicationStore } from 'store/applicationStore';
import { janusCtx } from 'utils/janus/janusCtx';
import { janusTextRoomLogger } from 'utils/logger';

export const janusTextRoomMessagesHandler = (data: any) => {
    janusTextRoomLogger.debug('Got data from the DataChannel!', data);
    //~ $('#datarecv').val(data);
    const json = JSON.parse(data);
    const message = json['text'] ? JSON.parse(json['text']) : null;
    const transaction = json['transaction'];
    if (janusCtx.textRoomTransactions[transaction]) {
        // Someone was waiting for this
        janusCtx.textRoomTransactions[transaction](json);
        delete janusCtx.textRoomTransactions[transaction];
        return;
    }
    const what = json['textroom'];
    if (what === 'message') {
        if (message === null) {
            janusTextRoomLogger.error('Message is not JSON:', json);
            return;
        }
        // Incoming message: public or private?
        /*let msg = dataText.text;
        if (typeof msg === 'string') {
            msg = msg.replace(new RegExp('<', 'g'), '&lt');
            msg = msg.replace(new RegExp('>', 'g'), '&gt');
        }*/
        const from = json['from'];
        const dateString = getDateString(json['date']);
        const whisper = json['whisper'];
        janusTextRoomLogger.debug({ data: { message, from, dateString, whisper } });
        if (whisper === true) {
            // Private message
            // $('#chatroom').append(
            //     '<p style="color: purple;">[' +
            //         dateString +
            //         '] <b>[whisper from ' +
            //         participants[from] +
            //         ']</b> ' +
            //         msg,
            // );
            // $('#chatroom').get(0).scrollTop = $('#chatroom').get(0).scrollHeight;
        } else {
            if (from === janusCtx.myid) {
                // FIXME Message come back
                applicationStore.dispatch(
                    setConferenceChatMessageStatus({
                        messageStatus: EChatMessageStatus.DELIVERED,
                        messageId: message.id,
                    }),
                );
            } else {
                janusCtx.dispatch(
                    addConferenceChatMessagesAction({
                        messages: [
                            {
                                confUserId: from,
                                ownUserId: message.ownUserId || null,
                                status: EChatMessageStatus.DELIVERED,
                                time: message.time,
                                id: message.id,
                                contentType: message.contentType || EChatMessageType.TEXT,
                                text: message.text,
                                files: message.files,
                            },
                        ],
                    }),
                );
            }
            // Public message
            // $('#chatroom').append(
            //     '<p>[' + dateString + '] <b>' + participants[from] + ':</b> ' + msg,
            // );
            // $('#chatroom').get(0).scrollTop = $('#chatroom').get(0).scrollHeight;
        }
    } else if (what === 'announcement') {
        // Room announcement
        // let text = msg.text;
        // text = text.replace(new RegExp('<', 'g'), '&lt');
        // text = text.replace(new RegExp('>', 'g'), '&gt');
        // const dateString = getDateString(json['date']);
        janusTextRoomLogger.debug({ text: message.text, time: getDateString(message.date) });
        // $('#chatroom').append('<p style="color: purple;">[' + dateString + '] <i>' + msg + '</i>');
        // $('#chatroom').get(0).scrollTop = $('#chatroom').get(0).scrollHeight;
    } else if (what === 'join') {
        // Somebody joined
        const username = json['username'];
        // const display = json['display'];
        // janusCtx.dataParticipants[username] = display ? display : username;
        // if (username !== janusCtx.myid && $('#rp' + username).length === 0) {
        //     // Add to the participants list
        //     $('#list').append(
        //         '<li id="rp' +
        //             username +
        //             '" class="list-group-item">' +
        //             participants[username] +
        //             '</li>',
        //     );
        //     $('#rp' + username)
        //         .css('cursor', 'pointer')
        //         .click(function () {
        //             var username = $(this).attr('id').split('rp')[1];
        //             sendPrivateMsg(username);
        //         });
        // }
        // $('#chatroom').append(
        //     '<p style="color: green;">[' +
        //         getDateString() +
        //         '] <i>' +
        //         participants[username] +
        //         ' joined</i></p>',
        // );
        janusTextRoomLogger.debug(`${username} joined to chat`);
        // $('#chatroom').get(0).scrollTop = $('#chatroom').get(0).scrollHeight;
    } else if (what === 'leave') {
        // Somebody left
        // const username = json['username'];
        const when = new Date();
        janusTextRoomLogger.debug({ when });
        // $('#rp' + username).remove();
        // $('#chatroom').append(
        //     '<p style="color: green;">[' +
        //         getDateString() +
        //         '] <i>' +
        //         participants[username] +
        //         ' left</i></p>',
        // );
        // $('#chatroom').get(0).scrollTop = $('#chatroom').get(0).scrollHeight;
        // delete janusCtx.dataParticipants[username];
    } else if (what === 'kicked') {
        // Somebody was kicked
        // const username = json['username'];
        const when = new Date();
        janusTextRoomLogger.debug({ when });
        // $('#rp' + username).remove();
        // $('#chatroom').append(
        //     '<p style="color: green;">[' +
        //         getDateString() +
        //         '] <i>' +
        //         participants[username] +
        //         ' was kicked from the room</i></p>',
        // );
        // $('#chatroom').get(0).scrollTop = $('#chatroom').get(0).scrollHeight;
        // delete janusCtx.dataParticipants[username];
        // if (username === myid) {
        //     bootbox.alert('You have been kicked from the room', function () {
        //         window.location.reload();
        //     });
        // }
    } else if (what === 'destroyed') {
        if (json['room'] !== janusCtx.roomId) {
            return;
        }
        // Room was destroyed, goodbye!
        janusTextRoomLogger.debug('The room has been destroyed!');
        // bootbox.alert('The room has been destroyed', function () {
        //     window.location.reload();
        // });
    }
};
export const getDateString = (jsonDate: string) => {
    let when = new Date();
    if (jsonDate) {
        when = new Date(Date.parse(jsonDate));
    }
    const dateString =
        ('0' + when.getHours()).slice(-2) + ':' + ('0' + when.getMinutes()).slice(-2);
    return dateString;
};
