import { t } from '@lingui/macro';
import { TTableFilter } from '../../types';

export const tableFilterOptions = (): { value: TTableFilter; displayValue: string }[] => [
    {
        value: 'all',
        displayValue: t({ id: 'admin.allUsers', message: 'Все пользователи' }),
    },
    {
        value: 'active',
        displayValue: t({ id: 'admin.user.active' }),
    },
    {
        value: 'inactive',
        displayValue: t({ id: 'admin.user.inactive' }),
    },
];
