import { leaveFromChatJanus } from './leaveFromChatJanus';
import { leaveFromConferenceJanus } from './leaveFromConferenceJanus';
import { unpublishOwnFeed } from './unpublishOwnFeed';
import { janusCtx } from './janusCtx';

export const stopConferenceJanus = () => {
    if (janusCtx.videoRoomPublisherPluginHandler) {
        unpublishOwnFeed();
        leaveFromConferenceJanus();
    }
    if (janusCtx.textRoomPluginHandler) {
        leaveFromChatJanus();
    }
};
