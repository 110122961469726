import { createAsyncThunk } from '@reduxjs/toolkit';
import { setWebHooksAction } from 'admin-app/actions/webHooks/setWebHooksAction';
import { getWebHooksApiCall } from 'admin-app/api/webHooks/getWebHooksApiCall';
import { EWebHooksActions } from 'admin-app/constants/thunk/EWebHooksActions';
import { IApiCallErrorData } from 'admin-app/types/IApiCallErrorData';
import { getNetworkService } from 'api/getNetworkService';

export const getWebHooksThunkAction = createAsyncThunk<IApiCallErrorData | undefined>(
    EWebHooksActions.GET_HOOKS,
    async (_, { dispatch }) => {
        const { success, data, errorCode, errorDescription } = await getWebHooksApiCall(
            getNetworkService(),
        );
        if (!success) {
            return { errorCode, errorDescription };
        }
        dispatch(setWebHooksAction(data));
    },
);
