import { createAsyncThunk } from '@reduxjs/toolkit';
import { kickFromConferenceApiCall } from 'api/conference/moderate/kickFromConferenceApiCall';
import { getNetworkService } from 'api/getNetworkService';
import { showNotifyThunkAction } from 'thunk/notify/showNotifyThunkAction';
import { TAppState } from 'types/app/TAppStore';
import { getDefaultErrorNotification } from 'utils/getDefaultErrorNotification';

export const kickFromConferenceThunkAction = createAsyncThunk(
    'kickFromConference',
    async (participantId: string, { getState, dispatch }) => {
        const {
            conferenceSession: { currentConferenceId },
        } = getState() as TAppState;
        const response = await kickFromConferenceApiCall(getNetworkService(), {
            conferenceId: currentConferenceId,
            participantId,
        });
        if (!response.success) {
            dispatch(showNotifyThunkAction(getDefaultErrorNotification()));
        }
    },
);
