import { t } from '@lingui/macro';
import React from 'react';
interface IContext {
    isUserInactive: boolean;
    isUpdating: boolean;
    setUpdatingStatus: (newState: boolean) => void;
}
export const ConferencePageTemplateContext = React.createContext<IContext>({
    isUserInactive: false,
    isUpdating: false,
    setUpdatingStatus: () => {},
});

const deleteHandlersToCheckUserActivity = (
    handlersList: { name: string; callback: () => void }[],
) => {
    handlersList.forEach((handler) => {
        window.document.removeEventListener(handler.name, handler.callback);
    });
};

export const setHandlersToCheckUserActivity = (setState: (newState: any) => void) => {
    const events = ['mousemove', 'mousedown', 'keydown'];
    const handlersList = events.map((event) => {
        return {
            name: event,
            callback: () => setState(false),
        };
    });
    handlersList.forEach((handler) => {
        window.document.addEventListener(handler.name, handler.callback);
    });
    return () => deleteHandlersToCheckUserActivity(handlersList);
};

interface IShowFullScreenExitNotification {
    setUserInActivity: () => void;
    showNotification: any;
    isShowNotification: boolean;
}

export const showFullScreenExitNotification = ({
    isShowNotification,
    setUserInActivity,
    showNotification,
}: IShowFullScreenExitNotification) => {
    if (!isShowNotification) {
        setUserInActivity();
        showNotification(
            t({ id: 'youCanUseEsc', message: 'Чтобы выйти из этого режима нажмите ESC' }),
        );
    }
};

export const addContainerPointerHandlers = (container: HTMLElement, callback: (e: any) => void) => {
    const mouseEnterHandler = () => {
        callback(true);
    };
    const mouseLeaveHandler = () => {
        callback(false);
    };

    container.addEventListener('mouseenter', mouseEnterHandler);
    container.addEventListener('mouseleave', mouseLeaveHandler);

    return () => {
        container.removeEventListener('mouseenter', mouseEnterHandler);
        container.removeEventListener('mouseleave', mouseLeaveHandler);
    };
};
