export enum EAppSettingsFieldsName {
    AUDIO_3D_AUDIO = 'use3dAudio',
    AUDIO_OWN_AUDIO = 'ownAudioOnConnect',
    CONFERENCE_AUDIO_NOTIFICATION = 'audioNotification',
    CONFERENCE_PARTICIPANTS_AUDIO = 'audioParticipants',
    CONFERENCE_PARTICIPANTS_VIDEO = 'videoParticipants',
    CONFERENCE_RECORD = 'recordConference',
    DEV_3D_AUDIO_SETTINGS = 'use3dAudioSettings',
    DEV_AUDIO_CODEC = 'audioCodec',
    DEV_DEBUG_CONFIG = 'debugConfig',
    DEV_FEATURES = 'features',
    DEV_JANUS_ADDRESS = 'janusAddress',
    DEV_JANUS_DEBUG = 'debugJanus',
    DEV_VIDEO_CODEC = 'videoCodec',
    DEV_VIDEO_LAYOUT = 'displayedVideoLayout',
    VIDEO_ALWAYS_SHOW_OWN_STREAM = 'alwaysShowOwnStream',
    VIDEO_DISPLAY_USER_FRAME_INFO = 'displayUsersFrameInfo',
    VIDEO_OWN_VIDEO = 'ownVideoOnConnect',
    VIDEO_RESOLUTION = 'resolution',
}
