import { IOwnProps, IDispatchProps } from './types';
import { WebHookDialog } from '../WebHookDialog';
import { t } from '@lingui/macro';
import { IFormProps } from '../WebHookDialog/types';

export const EditWebHookDialog: React.FC<IOwnProps & IDispatchProps> = ({
    hookData,
    editWebHook,
    closeDialog,
}) => {
    const dialogTitle = t({
        id: 'admin.hook.editDialog.title',
        message: 'Редактирование веб-хука',
    });
    const buttonText = t({ id: 'admin.hook.save', message: 'Сохранить' });

    const handleSubmit = async (data: IFormProps) => {
        return await editWebHook({ id: hookData.id, ...data });
    };
    return (
        <WebHookDialog
            currentHook={hookData}
            title={dialogTitle}
            closeDialog={closeDialog}
            buttonText={buttonText}
            handleSubmit={handleSubmit}
        />
    );
};
