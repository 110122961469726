import { setConferenceChatAutoScroll } from 'actions/chat/setConferenceChatAutoScroll';
import { toggleConferenceChatAction } from 'actions/applicationView/toggleConferenceChatAction';
import { connect } from 'react-redux';
import { exitFromConferenceThunkAction } from 'thunk/conference/exitFromConferenceThunkAction';
import { TAppDispatch } from 'types/app/TAppDispatch';
import { TAppState } from 'types/app/TAppStore';
import { ConferenceChat } from './ConferenceChat';
import { IDispatchProps, IStateProps } from './types';

export const mapDispatchToProps = (dispatch: TAppDispatch): IDispatchProps => ({
    toggleChat: (chatState) => dispatch(toggleConferenceChatAction(chatState)),
    setChatAutoScroll: (chatAutoScrollState) =>
        dispatch(setConferenceChatAutoScroll(chatAutoScrollState)),
    exitConference: () => dispatch(exitFromConferenceThunkAction({ redirect: true })),
});

const mapStateToProps = (state: TAppState): IStateProps => ({
    chatMessages: state.conferenceChat.chatMessages,
    chatAutoScroll: state.conferenceChat.chatAutoScroll,
    isMobile: state.applicationView.deviceProps.isTabletOrMobile,
    isUltraSmallScreen: state.applicationView.deviceProps.isUltraSmallScreen,
});

export const ConferenceChatConnected = connect(mapStateToProps, mapDispatchToProps)(ConferenceChat);
