import { t } from '@lingui/macro';
import { createAsyncThunk } from '@reduxjs/toolkit';
import { requestPasswordRecoveryApiCall } from 'api/authorization/requestPasswordRecoveryApiCall';
import { getNetworkService } from 'api/getNetworkService';
import { showNotifyThunkAction } from 'thunk/notify/showNotifyThunkAction';

interface IPayload {
    email: string;
}

export const requestPasswordRecoveryThunkAction = createAsyncThunk(
    'requestPasswordRecovery',
    async ({ email }: IPayload, { dispatch }) => {
        const response = await requestPasswordRecoveryApiCall(getNetworkService(), { email });
        if (!response.success) {
            /*dispatch(
                showNotifyThunkAction({
                    text: t({
                        message: 'На почту отправлено письмо с ссылкой',
                        id: 'email.was.sent',
                    }),
                    timeout: 10000,
                    type: 'success',
                }),
            );
        } else { */
            return response.data.errorCode;
        }
    },
);
