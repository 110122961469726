import { INetworkService } from 'types/api/INetworkService';

interface IPayload {
    email: string;
    password: string;
    timeZone: string;
}

export const registerApiCall = (
    networkService: INetworkService,
    data: IPayload,
) =>
    networkService.post({
        url: '/api/account/register',
        data,
    });
