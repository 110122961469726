import { INetworkService } from 'types/api/INetworkService';

interface IPayload {
    conferenceId: string;
    videoSettings: { masterFrames?: string[] };
}

export const setConferenceVideoSettingsApiCall = (
    networkService: INetworkService,
    { conferenceId, videoSettings }: IPayload,
) =>
    networkService.put({
        url: `/api/conferences/${conferenceId}/settings`,
        data: {
            videoSettings,
        },
    });
