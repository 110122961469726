type EventHandler = ((e?: React.SyntheticEvent) => void) | undefined;
interface Mask {
    mask: string;
    div?: string;
    re: RegExp;
}
export const dateMask: Mask = {
    mask: '__.__.____',
    re: /\d/,
};
export const timeMask: Mask = {
    mask: '__:__',
    re: /\d/,
};
export const telMask: Mask = {
    mask: '+_ ___ ___ __ __',
    re: /\d/,
};
export function makeDate(handleInput: EventHandler) {
    return makeFormat(handleInput, dateMask);
}
export function makeTime(handleInput: EventHandler) {
    return makeFormat(handleInput, timeMask);
}
export function makeTel(handleInput: EventHandler) {
    return makeFormat(handleInput, telMask);
}
export function makeFormat(handleInput: EventHandler, mask: Mask) {
    const _handleInput = (revt: React.SyntheticEvent) => {
        const evt = revt.nativeEvent as InputEvent;
        const targetNode = evt.target as HTMLInputElement;
        let val = targetNode.value;
        let pos = targetNode.selectionStart || 0;
        let data = evt.data;
        if (!data) {
            data = '';
        }
        if (!mask.re.test(data)) {
            data = '';
        }
        let afterPos = pos;
        while (val[afterPos] && !mask.re.test(val[afterPos])) {
            afterPos++;
        }
        afterPos += data.length;
        val = getMaskString(
            val.substr(0, pos - data.length) + data + val.substr(afterPos),
            mask,
        );
        targetNode.value = val;
        if (data !== '') {
            while (val[pos - 1] && !mask.re.test(val[pos - 1])) {
                pos++;
            }
        }
        targetNode.setSelectionRange(pos, pos);
        if (handleInput) {
            handleInput(revt);
        }
    };
    return _handleInput;
}
export function getMaskString(val: string, mask: Mask, phoneCodeLen = 0): string {
    const arr = val;
    let i;
    if (phoneCodeLen !== 0) {
        i = phoneCodeLen;
        val = '+' + arr.substr(0, phoneCodeLen);
    } else {
        i = 0;
        val = '';
    }
    for (let j = phoneCodeLen === 0 ? 0 : 1; i < arr.length && j < mask.mask.length; ) {
        if (mask.mask[j] === '_') {
            if (!mask.re.test(arr[i])) {
                i++;
                continue;
            }
        } else if (arr[i] !== mask.mask[j]) {
            val += mask.mask[j];
            j++;
            continue;
        }
        val += arr[i];
        i++;
        j++;
    }
    if (mask === telMask) {
        if (phoneCodeLen === 0) {
            phoneCodeLen = clear(arr.substr(i), mask).length;
            if (phoneCodeLen !== 0) {
                val = clear(arr, mask);
                if (val.length > 14) {
                    val = val.substr(0, 14);
                }
                val = getMaskString(val, mask, phoneCodeLen);
            }
        }
    } else if (val.length > mask.mask.length) {
        val = val.substr(0, mask.mask.length);
    }
    return val;
}
function clear(str: string, mask: Mask) {
    let res = '';
    for (let i = str.length - 1; i > -1; i--) {
        if (mask.re.test(str[i])) {
            res = str[i] + res;
        }
    }
    return res;
}
