import { HoverTooltip } from 'components/common/HoverTooltip';
import { Portal } from 'components/common/Portal';
import { useRef, useState } from 'react';
import { displayDeviceTooltip, hideDeviceTooltip } from 'utils/conferences/deviceTooltipUtils';
import { getItemData } from './utils';
import styles from './SelectItem.module.scss';
import { IOwnProps } from './types';

export const SelectItem: React.FC<IOwnProps> = ({
    item,
    selectedValue,
    activeItem,
    setIsOpen,
    handleItemChange,
    enableTooltip,
    theme,
}) => {
    const [showTooltip, setShowTooltip] = useState(false);
    const timerId = useRef<any>(null);
    const [cursorPosition, setCursorPosition] = useState<{ x: 0; y: 0 } | null>(null);

    const { actualValue, displayValue } = getItemData(item);

    const mouseOverHandler = (e: any) => {
        if (!enableTooltip) {
            return;
        }
        const currentTarget = e.currentTarget;
        timerId.current = displayDeviceTooltip(currentTarget, showTooltip, setShowTooltip);
    };
    const mouseOutHandler = () => {
        if (!enableTooltip) {
            return;
        }
        hideDeviceTooltip(timerId.current, showTooltip, setShowTooltip);
        setCursorPosition(null);
    };
    const mouseMoveHandler = (e: any) => {
        const x = e.clientX;
        const y = e.clientY;
        !showTooltip && setCursorPosition({ x, y });
    };
    const itemClickHandler = (e: any) => {
        e.stopPropagation();
        setIsOpen(false);
        handleItemChange(actualValue);
    };
    const tooltipComputedStyles = {
        top: `${cursorPosition ? cursorPosition.y - 60 : ''}px`,
        bottom: '0',
        left: `${cursorPosition ? cursorPosition.x : ''}px`,
        transform: 'translateX(-50%)',
    };
    const isActiveItem = actualValue === selectedValue;
    const itemStyle = isActiveItem ? `${styles.selectItemInList} ${styles.item}` : styles.item;
    const themeStyles = `${theme !== 'light' ? styles[`${theme}Theme`] : ''}`;
    return (
        <div
            className={`${itemStyle} ${themeStyles}`}
            ref={isActiveItem ? activeItem : null}
            onMouseOver={mouseOverHandler}
            onMouseOut={mouseOutHandler}
            onMouseMove={mouseMoveHandler}
            onClick={itemClickHandler}>
            <span data-js-tooltip={enableTooltip ? 'true' : 'false'}> {displayValue}</span>

            {showTooltip && cursorPosition && (
                <Portal attributes={[{ name: 'id', value: 'tooltip' }]}>
                    <div className={styles.hoverWrapper} style={tooltipComputedStyles}>
                        <HoverTooltip name={displayValue} color='blue' />
                    </div>
                </Portal>
            )}
        </div>
    );
};
