import { t } from '@lingui/macro';
import AccessDeniedImg from 'assets/AccessDenied.svg';
import { Button } from 'components/common/Button/Button';
import styles from './ConferenceFinishedDialog.module.scss';
import { IOwnProps } from './types';

export const ConferenceFinishedDialog: React.FC<IOwnProps> = ({ goToHome, text }) => (
    <div className={styles.block}>
        <img src={AccessDeniedImg} alt='End' />
        <div className={styles.text}>
            {text}
        </div>
        <Button
            styleType='primaryAction'
            caption={t({ message: 'Выход', id: 'Exit' })}
            className={styles.button}
            type='submit'
            clickHandler={goToHome}
        />
    </div >
);
