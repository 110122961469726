import { Avatar } from 'components/common/Avatar';
import { MAX_DISPLAYED_AVATARS } from 'constants/conferenceConstants';
import { useEffect, useRef, useState } from 'react';
import { ConferenceUser } from './ConferenceUser/ConferenceUser';
import styles from './ConferenceUsers.module.scss';
import { IOwnProps, IStateProps } from './types';
import { calcMaxAvatarCount, getStyle, getUsersList } from './utils';

export const ConferenceUsers: React.FC<IOwnProps & IStateProps> = ({
    usersData,
    ldapUsers,
    isDesktop = false,
    maxPercentOfScreen: maxPercentOfControl = 100,
}) => {
    const [width, setWidth] = useState(0);
    const usersRef = useRef<HTMLDivElement>(null);
    const avatarsCount =
        'emails' in usersData ? usersData.emails?.length : usersData.participants?.length ?? 0;
    const avatarSize = isDesktop ? 32 : 24;
    const maxAvailableAvatars = calcMaxAvatarCount(
        avatarsCount,
        width,
        avatarSize,
        maxPercentOfControl,
        isDesktop,
    );
    let displayedAvatars = Math.min(maxAvailableAvatars, MAX_DISPLAYED_AVATARS);
    if (avatarsCount > 0) {
        if (displayedAvatars > avatarsCount) {
            displayedAvatars = avatarsCount;
        } else if (displayedAvatars < avatarsCount) {
            displayedAvatars = displayedAvatars - 1;
        }
    }
    const onResize = () => {
        if (usersRef.current && isDesktop) {
            setWidth(usersRef.current?.clientWidth);
        } else {
            setWidth(window.screen.availWidth);
        }
    };

    useEffect(() => {
        window.addEventListener('resize', onResize);
        return () => {
            window.removeEventListener('resize', onResize);
        };
    }, []);

    useEffect(() => {
        if (usersRef.current && isDesktop) {
            setWidth(usersRef.current?.clientWidth);
        } else {
            setWidth(window.screen.availWidth);
        }
    }, [usersRef]);

    const resultUsersList = getUsersList({ ...usersData, ldapUsers });
    const divStyle = isDesktop ? { width: `${avatarSize - 10}px` } : {};

    return (
        <div className={`${styles.users} ${isDesktop ? styles.users_desktop : ''}`} ref={usersRef}>
            {resultUsersList
                .map((user, idx) => (
                    <ConferenceUser
                        userData={user.userData}
                        email={user.email}
                        userNumber={idx}
                        isDesktop={isDesktop}
                        key={user.userData?.id || user.email}
                        avatarSize={avatarSize}
                    />
                ))
                .slice(0, displayedAvatars)}
            {displayedAvatars !== avatarsCount && (
                <div key='otherUsers' style={divStyle}>
                    <Avatar
                        initials={`+${avatarsCount - displayedAvatars}`}
                        style={getStyle(avatarSize, isDesktop)}
                        userBgColor='#2493FB'
                        userColor='#FFFFFF'
                    />
                </div>
            )}
        </div>
    );
};
