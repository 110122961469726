import { createAsyncThunk } from '@reduxjs/toolkit';
import { setCurrentUserDataAction } from 'actions/auth/setCurrentUserDataAction';
import { setRememberMeRequestStatusAction } from 'actions/auth/setRememberMeRequestStatusAction';
import { resetConferencesStoreAction } from 'actions/conferences/resetConferencesStoreAction';
import { resetSessionSettingsAction } from 'actions/sessionSettings/resetSessionSettingsAction';
import { logoutApiCall } from 'api/authorization/logoutApiCall';
import { getNetworkService } from 'api/getNetworkService';
import { disconnectFromSocket } from 'services/socketService/disconnectFromSocket';
import { redirect } from 'utils/redirect';

export const logoutThunkAction = createAsyncThunk('logout', async (_: void, { dispatch }) => {
    sessionStorage.setItem('isLogoutAction', 'true');
    const response = await logoutApiCall(getNetworkService());
    if (response.success) {
        disconnectFromSocket();
        dispatch(setRememberMeRequestStatusAction('initial'));
        dispatch(setCurrentUserDataAction(null));
        dispatch(resetConferencesStoreAction());
        dispatch(resetSessionSettingsAction());
        redirect('/');
    }
    sessionStorage.removeItem('isLogoutAction');
});
