import { t } from '@lingui/macro';
import { connect } from 'react-redux';
import { showNotifyThunkAction } from 'thunk/notify/showNotifyThunkAction';
import { TAppDispatch } from 'types/app/TAppDispatch';
import { DeviceField } from './DeviceField';
import { IDispatchProps } from './types';

const mapDispatchToProps = (dispatch: TAppDispatch): IDispatchProps => ({
    showNotification: (text) => dispatch(showNotifyThunkAction({ text, timeout: 3000, type: 'notify_green' })),
});

export const DeviceFieldConnected = connect(null, mapDispatchToProps)(DeviceField);
