export enum ELogCategories {
    JANUS = 'janus',
    JANUS_INTERNAL = 'janus.internal',
    JANUS_MESSAGES = 'janus.messages',
    JANUS_TEXT_ROOM = 'janus.text_room',
    JANUS_VIDEO_ROOM = 'janus.video_room',
    JANUS_VIDEO_ROOM_MESSAGES = 'janus.video_room.messages',
    JANUS_VIDEO_ROOM_TALKS = 'janus.video_room.talks',
    JANUS_VIDEO_ROOM_TRACKS = 'janus.video_room.tracks',
    ROOT = '',
    WEBSOCKET = 'websocket',
}
