export enum EJanusActions {
    ADD_DISCONNECT_PUBLISHER_TO_LOG = 'Janus/addDisconnectPublisherToLog',
    ADD_MODERATED_MEDIA_TO_LOG = 'Janus/addModeratedMediaToLog',
    ADD_PUBLISHER_TO_JANUS = 'Janus/addPublisherToJanus',
    AUDIO_STREAM_CHANGE_IN_PROGRESS = 'Janus/audioStreamChangeInProgress',
    CHANGE_LOBBY_ALLOW = 'Janus/changeLobbyAllow',
    CLEAR_JANUS_LOG = 'Janus/clearLog',
    MODIFY_JANUS_PUBLISHER = 'Janus/modifyPublisher',
    OVERFLOW_USERS_LOBBY_QUEUE = 'Janus/overflowUsersLobbyQueue',
    REMOVE_FROM_JANUS_LOG = 'Janus/removeFromLog',
    REMOVE_PARTICIPANT_TO_JANUS = 'Janus/removePublisherToJanus',
    REMOVE_REMOTE_STREAM = 'Janus/removeRemoteStream',
    RESET_JANUS_STORE = 'Janus/resetJanusStore',
    RESET_OWN_STREAM = 'Janus/resetOwnStream',
    RESET_PUBLISHER_STREAM = 'Janus/resetPublisherStream',
    SAVE_JANUS_TOKEN = 'Janus/saveJanusToken',
    SAVE_OWN_PARTICIPANT_ID = 'Janus/saveOwnParticipantId',
    SET_ACTIVE_PUBLISHERS = 'Janus/activePublishers',
    SET_CAN_PUBLISH = 'Janus/setCanPublish',
    SET_JANUS_STREAM_PROPS = 'Janus/setJanusStreamProps',
    SET_LOCAL_STREAM = 'Janus/setLocalStream',
    SET_OWN_EMOTION_TO_SHOW = 'Janus/setOwnEmotionToShow',
    SET_OWN_TALKING_STATUS = 'Janus/setOwnTalkingStatus',
    SET_PARTICIPANT_MUTE_STATUS = 'Janus/setParticipantMuteStatus',
    SET_VIDEO_ROOM_CONNECTED_STATUS = 'Janus/setVideoRoomConnectedStatus',
    START_JANUS_PUBLISHER_STREAM = 'Janus/startPublisherStream',
    UPDATE_PUBLISHER_STREAM_PROPS = 'Janus/updatePublisherStreamProps',
    VIDEO_STREAM_CHANGE_IN_PROGRESS = 'Janus/videoStreamChangeInProgress',
}
