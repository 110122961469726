import { INetworkService } from 'types/api/INetworkService';
import { IScheduledConferenceData } from 'types/IScheduledConferenceData';

export const scheduleConferenceApiCall = (
    networkService: INetworkService,
    data: IScheduledConferenceData,
) =>
    networkService.post({
        url: '/api/conferences/schedule',
        data,
    });
