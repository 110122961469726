import { createAsyncThunk } from '@reduxjs/toolkit';
import { resetLobbyStatusAction } from 'actions/lobby/resetLobbyStatusAction';
import { joinToCurrentConferenceThunkAction } from './joinToCurrentConferenceThunkAction';
import { TAppState } from 'types/app/TAppStore';
import { redirect } from 'utils/redirect';
import { subscribeToConferenceJoinRequestActionThunk } from 'thunk/socket/subscribeToConferenceJoinRequestThunkAction';

export const rejoinToConferenceThunkAction = createAsyncThunk(
    'rejoinToConference',
    async (_: void, { getState, dispatch }) => {
        dispatch(resetLobbyStatusAction());
        await dispatch(joinToCurrentConferenceThunkAction());
        const {
            conferenceSession: { currentConferenceId },
            lobby: { allowedJoinConference },
            janus: { ownParticipantId },
        } = getState() as TAppState;
        if (!allowedJoinConference) {
            dispatch(subscribeToConferenceJoinRequestActionThunk(ownParticipantId));
            redirect(`/lobby/${currentConferenceId}`);
        }
    },
);