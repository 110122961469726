import { createAsyncThunk } from '@reduxjs/toolkit';
import { setFoundUsersAction } from 'actions/schedulingConference/setFoundUsersAction';
import { getLdapUsersDataThunkAction } from 'thunk/users/getLdapUsersDataThunkAction';
import { IUserLdapData } from 'types/user/IUserLdapData';

export const getUsersSchedulingConfThunkAction = createAsyncThunk(
    'getUsersSchedulingConf',
    async (inputValue: string, { dispatch }): Promise<undefined | number> => {
        let result: any;
        const emailRegex = /^(\w{1,})(\S*)([@]{1})(\w{1,}[.]\w{1,})$/gi;
        if (inputValue.match(emailRegex)) {
            result = await dispatch(
                getLdapUsersDataThunkAction({ type: 'email', value: inputValue }),
            );
        }
        if (!result?.payload || result?.payload?.length === 0) {
            result = await dispatch(
                getLdapUsersDataThunkAction({ type: 'name', value: inputValue }),
            );
        }
        if (result.payload) {
            const resultUsersArr = result.payload as IUserLdapData[];
            dispatch(setFoundUsersAction(resultUsersArr));
            return 200;
        }
    },
);
