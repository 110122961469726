import { INetworkService } from 'types/api/INetworkService';

interface IDeleteConferenceRecordApiPayload {
    conferenceId: string;
    sessionId: string;
    recordId: string;
}

export const deleteConferenceRecordApiCall = (
    networkService: INetworkService,
    { conferenceId, sessionId, recordId }: IDeleteConferenceRecordApiPayload,
) =>
    networkService.delete({
        url: `/api/conferences/${conferenceId}/sessions/${sessionId}/records/${recordId}`,
    });
