import { IChangeJanusStreamProps } from 'types/janus/IChangeJanusStreamProps';
import { IJanusLocalStreamProps } from 'types/janus/IJanusLocalStreamProps';

interface IPayload {
    setStreamProps: (args: Partial<IChangeJanusStreamProps>) => void;
    currentUserIsOwner: boolean;
    forbiddenStreamsByConferenceSettings: {
        video: boolean;
        audio: boolean;
    };
    allowedStreams: {
        video: boolean;
        audio: boolean;
    };
    forbiddenStreams: {
        video: boolean;
        audio: boolean;
    };
    currentPermissions: {
        videoPermitted: boolean;
        audioPermitted: boolean;
    };
}
export const setOwnStreamsPermissions = (args: IPayload) => {
    const {
        forbiddenStreamsByConferenceSettings,
        allowedStreams,
        forbiddenStreams,
        currentPermissions,
        setStreamProps,
        currentUserIsOwner,
    } = args;
    // console.log({ args });
    const newStreamProps: Partial<IJanusLocalStreamProps> = {};
    if (
        (allowedStreams.video ||
            (!forbiddenStreamsByConferenceSettings.video && !forbiddenStreams.video) ||
            (forbiddenStreamsByConferenceSettings.video && allowedStreams.video)) &&
        !currentPermissions.videoPermitted
    ) {
        newStreamProps.videoPermitted = true;
    }

    if (
        (allowedStreams.audio ||
            (!forbiddenStreamsByConferenceSettings.audio && !forbiddenStreams.audio) ||
            (forbiddenStreamsByConferenceSettings.audio && allowedStreams.audio)) &&
        !currentPermissions.audioPermitted
    ) {
        newStreamProps.audioPermitted = true;
    }

    if (
        (forbiddenStreams.video ||
            (!allowedStreams.video && forbiddenStreamsByConferenceSettings.video)) &&
        currentPermissions.videoPermitted &&
        !currentUserIsOwner
    ) {
        newStreamProps.videoPermitted = false;
        newStreamProps.useVideo = false;
    }
    if (
        (forbiddenStreams.audio ||
            (forbiddenStreamsByConferenceSettings.audio && !allowedStreams.audio)) &&
        currentPermissions.audioPermitted &&
        !currentUserIsOwner
    ) {
        newStreamProps.audioPermitted = false;
        newStreamProps.useAudio = false;
    }
    // console.log({ ...newStreamProps });
    Object.keys(newStreamProps).length && setStreamProps(newStreamProps);
};
