import { IOwnProps } from './types';
import styles from './AccountSettingsTemplate.module.scss';

export const AccountSettingsTemplate: React.FC<IOwnProps> = ({
    settingsTitle,
    settingsDescription,
    children,
}) => {
    return (
        <div className={styles.settings}>
            <div className={styles.settings__header}>
                <h2 className={styles.settings__title}>{settingsTitle}</h2>
                <h5 className={styles.settings__description}>{settingsDescription}</h5>
            </div>
            <div className={styles.settings__content}>{children}</div>
        </div>
    );
};
