import { NewUserModal } from 'admin-app/components/NewUserModal/NewUserModal';
import { Table } from 'admin-app/components/common/Table';
import { IDispatchProps, IStateProps, TTableFilter } from 'admin-app/pages/Users/types';
import {
    columns,
    dynamicLoading,
    getConvertedFilterValue,
    setTableScrollToTop,
} from 'admin-app/pages/Users/utils';
import { IAdminPanelUser } from 'admin-app/types/IAdminPanelUser';
import { isScrollAtBottom } from 'admin-app/utils/isScrollAtBottom';
import { FC, useEffect, useState } from 'react';
import { getLocale } from 'utils/getLocale';
import { redirect } from 'utils/redirect';
import styles from './UsersPage.module.scss';
import { UsersPageHeader } from './UsersPageHeader';

export const UsersPage: FC<IStateProps & IDispatchProps> = ({
    users = null,
    totalUsersCount,
    ldap,
    lang,
    usersSearch,
    getUsers,
    gettingUsers,
}) => {
    const [showDialog, setShowDialog] = useState(false);
    const [tablePagination, setTablePagination] = useState(0);
    const [isDownloadingUsers, setIsDownloadingUsers] = useState(false);
    const [isMaxUsersCount, setIsMaxUsersCount] = useState(false);
    const [tableFilter, setTableFilter] = useState<TTableFilter>('all');

    const resetTableSettings = () => {
        setTablePagination(0);
        setIsMaxUsersCount(false);
        setTableScrollToTop();
        setTableFilter('all');
    };

    const handleTableFilterChange = (newVal: TTableFilter) => {
        if (gettingUsers) {
            return;
        }
        setTableFilter(newVal);
        getUsers({ activated: getConvertedFilterValue(newVal), search: usersSearch });
    };

    const openInviteUserDialog = () => setShowDialog(true);

    const onItemClick = (userData: IAdminPanelUser | undefined) => {
        userData && redirect(`/admin/user/${userData.id}`);
    };

    const handleTableScroll = (event: React.UIEvent<HTMLDivElement>) => {
        if (isScrollAtBottom(event.currentTarget) && !isMaxUsersCount && !isDownloadingUsers) {
            dynamicLoading({
                search: usersSearch,
                tableFilter: getConvertedFilterValue(tableFilter),
                currentPage: tablePagination,
                totalUsersCount,
                getUsers,
                setTablePagination,
                setIsDownloadingUsers,
                setIsMaxUsersCount,
            });
        }
    };

    useEffect(() => {
        if (!users && !gettingUsers) {
            getUsers();
        }
    }, []);

    useEffect(() => {
        resetTableSettings();
    }, [usersSearch]);

    if (!users) {
        return <div></div>;
    }

    return (
        <div className={styles.page}>
            {showDialog && !ldap && <NewUserModal closeModal={() => setShowDialog(false)} />}
            <UsersPageHeader
                ldap={ldap}
                openInviteUserDialog={openInviteUserDialog}
                tableFilterValue={tableFilter}
                handleTableFilterChange={handleTableFilterChange}
            />
            <Table
                columns={columns(getLocale(lang))}
                data={users}
                gridStyles={{ display: 'grid', gridTemplateColumns: '1fr 1fr 0.5fr 0.5fr 0.5fr' }}
                onItemClick={onItemClick}
                onScroll={handleTableScroll}
                showLoader={isDownloadingUsers}
            />
        </div>
    );
};
