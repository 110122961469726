import { FC, useEffect } from 'react';
import { IDispatchProps, IOwnProps, IStateProps } from './types'
import styles from './AccountConferenceDetailsPage.module.scss'
import { Button } from 'components/common/Button';
import { Trans } from '@lingui/react';
import PenIcon from 'assets/PenBlue.svg'
import RepeatArrowsWhiteIcon from 'assets/RepeatArrowsWhite.svg'
import Message from 'assets/Message.svg'
import FileCheck from 'assets/FileCheck.svg'
import RepeatArrows from 'assets/RepeatArrows.svg'
import LockIcon from 'assets/LockLightGray.svg'
import UserIcon from 'assets/UserGray.svg'
import { redirect } from 'utils/redirect';
import { EPaths } from 'constants/EPaths';
import { AccountConferenceDetailsPageParticipantsList } from './AccountConferenceDetailsPageParticipantsList';
import { IConferenceConnectInfo } from 'types/conferences/IConferenceConnectInfo';
import { ConferenceConnectInfoConnected } from './ConferenceConnectInfo';
import { CircleLoader } from 'components/common/CircleLoader';
import { IConferenceUser } from 'types/IConferenceUser';
import { getBreadcrumbsItems, getFormattedDate } from './utils';
import { IConferenceParticipant } from 'types/IConferenceParticipant';
import { Breadcrumbs } from 'components/common/Breadcrumbs';

export const AccountConferenceDetailsPage: FC<IDispatchProps & IOwnProps & IStateProps> = ({
    conferenceId,
    sessionId,
    conferencesConnectInfo,
    conferencesInvitedUsers,
    conferencesParticipants,
    currentUserId,
    prepareToConnectToConference,
    goToConferenceHistory,
    lang,
    getConferenceData,
}) => {
    const isPastConference = !!sessionId;
    const conferenceInfo: IConferenceConnectInfo | undefined = conferencesConnectInfo[conferenceId];
    const invitedUsers: IConferenceUser[] | undefined = conferencesInvitedUsers[conferenceId];
    const participants: IConferenceParticipant[] | undefined = conferencesParticipants[conferenceId];

    useEffect(() => {
        getConferenceData({conferenceId, sessionId})
    }, [conferenceInfo?.status])

    if (!conferenceInfo || !invitedUsers || !participants) {
        return <CircleLoader color='blue' />;
    }

    const currentUserIsCreator = currentUserId === conferenceInfo.creator.userId;

    return (
        <div className={styles.container}>
            <div className={styles.content}>
                <Breadcrumbs items={getBreadcrumbsItems(conferenceInfo, isPastConference)}/>
                <div className={styles.content__title}>{conferenceInfo.name}</div>
                <div className={styles.content__date}>{getFormattedDate(conferenceInfo, lang)}</div>
                {currentUserIsCreator && (
                    isPastConference ? (
                        <div className={styles.content__controls}>
                            <Button
                                styleType='primaryActionNew'
                                type='button'
                                clickHandler={() => prepareToConnectToConference(conferenceId)}
                            >
                                <img src={RepeatArrowsWhiteIcon}/>
                                <span><Trans id='start.again'/></span>
                            </Button>
                            <Button
                                styleType='secondaryActionNew'
                                type='button'
                                clickHandler={() => goToConferenceHistory({conferenceId, sessionId, offset: 0})}
                            >
                                <img src={Message}/>
                                <span><Trans id='Conference history'/></span>
                            </Button>
                        </div>
                    ) : (
                        <Button
                            styleType='secondaryActionNew'
                            type='button'
                            clickHandler={() => redirect(`${EPaths.EDIT_CONFERENCE}/${conferenceId}`)}
                        >
                            <img src={PenIcon}/>
                            <span><Trans id='edit'/></span>
                        </Button>
                    )
                )}
                <div className={styles.line} />
                {(conferenceInfo.description || conferenceInfo.seriesId || conferenceInfo.lobby || conferenceInfo.registeredOnly) &&
                    <div className={styles.detailsBlock}>
                        <div className={styles.detailsBlock__title}><Trans id='details' message='Детали'/></div>
                        {conferenceInfo.description && (
                            <div>
                                <img src={FileCheck}/>
                                {conferenceInfo.description}
                            </div>
                        )}
                        {conferenceInfo.seriesId && (
                            <div>
                                <img src={RepeatArrows}/>
                                <Trans id='detailsPage.weeklyMeeting'/>
                            </div>
                        )}
                        {conferenceInfo.lobby && (
                            <div>
                                <img src={LockIcon}/>
                                <Trans id='detailsPage.privateConference'/>
                            </div>
                        )}
                        {conferenceInfo.registeredOnly && (
                            <div>
                                <img src={UserIcon}/>
                                <Trans id='detailsPage.registeredOnly'/>
                            </div>
                        )}
                    </div>
                }
                <AccountConferenceDetailsPageParticipantsList
                    conferenceInfo={conferenceInfo}
                    invitedUsers={invitedUsers}
                    currentUserIsCreator={currentUserIsCreator}
                    participants={participants}
                    isPastConference={isPastConference}
                />
            </div>
            <ConferenceConnectInfoConnected conferenceInfo={conferenceInfo}/>
        </div>
    )
}
