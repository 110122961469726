import { t } from '@lingui/macro';
import { SelectField } from 'components/common/SelectField';
import styles from '../ConferenceScheduleSettingsForm.module.scss';
import { getComboValues } from './utils';

export const ConferenceAudioVideoSettings: React.FC<{ name: string}> =
    ({ name }) => (
        <>
            <div className={styles.text}>
                {t({ message: 'Участник', id: 'Participants' })}
            </div>
            <SelectField className={styles.select} items={getComboValues()} name={name} displayFilteredHeader={true}/>
        </>
    );
