import { t } from '@lingui/macro';
import { parse } from 'date-fns';
import { EPredefinedDatesRange } from 'types/dates/EPredefinedDatesRange';
import { IDatesRange } from 'types/dates/IDatesRange';
import { convertDateToStringByTemplate } from 'utils/dates/convertDateToStringByTemplate';
import { getDatesByPredefinedRange } from 'utils/dates/getDatesByPredefinedRange';
import { dateTemplate, IFilter } from './types';

export const IncomingConferencesFilter = [
    EPredefinedDatesRange.Today,
    EPredefinedDatesRange.Tomorrow,
    EPredefinedDatesRange.ThisWeek,
    EPredefinedDatesRange.NextWeek,
    EPredefinedDatesRange.ThisMonth,
    EPredefinedDatesRange.NextMonth,
];
export const PastConferencesFilter = [
    EPredefinedDatesRange.Today,
    EPredefinedDatesRange.Yesterday,
    EPredefinedDatesRange.ThisWeek,
    EPredefinedDatesRange.LastWeek,
    EPredefinedDatesRange.ThisMonth,
    EPredefinedDatesRange.LastMonth,
];
export function getFilterDefValue(
    args: {
        from?: string;
        to?: string;
        range?: EPredefinedDatesRange;
    },
    isPast: boolean,
): IFilter {
    const { range, from, to } = args;
    if (range) {
        const dates = getDatesByPredefinedRange(range, isPast);
        return {
            dates,
            predefinedRange: range,
        };
    }
    const defDates = {
        startDate: getDateByStr(from) || new Date(),
        endDate: getDateByStr(to) || new Date(),
    };
    return {
        dates: defDates,
        predefinedRange: undefined,
    };
}

export function getFilterTitle(filter: IFilter): string {
    if (filter.predefinedRange) {
        return getTitle(filter.predefinedRange);
    }
    return `${getDateStr(filter.dates.startDate)} - ${getDateStr(filter.dates.endDate)}`;
}
export function getTitle(type: EPredefinedDatesRange): string {
    switch (type) {
        case EPredefinedDatesRange.Today:
            return t({ id: 'DateFilter.today', message: 'Сегодня' });
        case EPredefinedDatesRange.Tomorrow:
            return t({ id: 'DateFilter.tomorrow', message: 'Завтра' });
        case EPredefinedDatesRange.Yesterday:
            return t({ id: 'DateFilter.yesterday', message: 'Вчера' });
        case EPredefinedDatesRange.ThisWeek:
            return t({ id: 'DateFilter.week', message: 'На этой неделе' });
        case EPredefinedDatesRange.NextWeek:
            return t({ id: 'DateFilter.nextWeek', message: 'На следующей неделе' });
        case EPredefinedDatesRange.LastWeek:
            return t({ id: 'DateFilter.lastWeek', message: 'На прошлой неделе' });
        case EPredefinedDatesRange.ThisMonth:
            return t({ id: 'DateFilter.month', message: 'В этом месяце' });
        case EPredefinedDatesRange.NextMonth:
            return t({ id: 'DateFilter.nextMonth', message: 'В следующем месяце' });
        case EPredefinedDatesRange.LastMonth:
            return t({ id: 'DateFilter.lastMonth', message: 'В прошлом месяце' });
    }
}
export function getDateStr(date: Date | null): string {
    return date !== null ? convertDateToStringByTemplate(date, dateTemplate) : '';
}
export function getDateByStr(str?: string): Date | undefined {
    return str !== undefined ? parse(str, dateTemplate, 0) : undefined;
}
export function getFilterDates(filter: IFilter, isPast: boolean): IDatesRange {
    return filter.predefinedRange
        ? getDatesByPredefinedRange(filter.predefinedRange, isPast)
        : filter.dates;
}
