

import { janusTextRoomLogger } from 'utils/logger';
import { randomString } from 'utils/randomString';
import { janusCtx } from './janusCtx';

export const createTextroom = (): Promise<void> =>
    new Promise((resolve, reject) => {
        const transaction = randomString(12);
        const create = {
            description: 'textroom',
            is_private: true,
            pin: 'pin',
            room: janusCtx.roomId,
            textroom: 'create',
            transaction,
            username: janusCtx.myid,
        };
        janusCtx.textRoomTransactions[transaction] = (dataResponse: any) => {
            janusTextRoomLogger.debug({ dataResponse });
        };
        janusCtx.textRoomPluginHandler.data({
            text: JSON.stringify(create),
            error: (error: any) => {
                janusTextRoomLogger.debug(`Join failed ${error}`);
                reject();
            },
            success: (a: any) => {
                janusTextRoomLogger.debug(`Joined to room ${a}`);
                resolve();
            },
        });
    });
