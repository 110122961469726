import { t } from '@lingui/macro';
import LockGrayImg from 'assets/LockGray.svg';
import { Arrow } from 'components/common/Arrow';
import { Checkbox } from 'components/common/Checkbox';
import { HoverLabel } from 'components/common/HoverLabel';
import OutsideClick from 'components/common/OutsideClick';
import { ConferenceDeviceItem } from 'components/ConferenceFooter/ConferenceDevice/ConferenceDeviceItem/ConferenceDeviceItem';
import React, { ReactNode, useEffect, useRef, useState } from 'react';
import { Form } from 'react-final-form';
import { IUserDevice } from 'types/IUserDevice';
import { setEscCloseDropdown } from 'utils/escCloseDropdown';
import styles from './ConferenceDevice.module.scss';
import { IDeviceTypeProps, IOwnProps } from './types';
import { classNames } from 'utils/classNames';
import { Button } from 'components/common/Button';

export const ConferenceDevice: React.FC<IOwnProps & IDeviceTypeProps> = ({
    canPublish,
    className = '',
    classNameImgOff = '',
    deviceList,
    deviceListIsLoading,
    deviceOn,
    devicePermittedByAdmin,
    imgDisabled,
    imgOff,
    imgOn,
    menuHeaderText,
    mobile,
    onChangeItem,
    onChangeValue,
    readyToStream,
    selectedDeviceId,
    selectedDeviceLabel,
    text,
    tooltip,
    use3dAudioFeature,
    use3dAudio,
    setUse3dAudio,
}) => {
    const [deviceListIsOpen, setDeviceListIsOpen] = useState(false);
    useEffect(() => {
        setEscCloseDropdown(deviceListIsOpen);
    }, [deviceListIsOpen]);

    const checkDeviceChanged = () => {
        if (!deviceList.length || !selectedDeviceId) {
            return;
        }
        const isCurrentDeviceStillConnected = deviceList.find((device) => {
            if (device.deviceId === selectedDeviceId) {
                return device.label === selectedDeviceLabel;
            }
            return false;
        });

        if (isCurrentDeviceStillConnected) {
            return;
        }

        const newDevice = deviceList[0];
        if (!newDevice) {
            return;
        }
        let newDeviceType = '';
        if (newDevice?.kind === 'audioinput') {
            newDeviceType = 'audio';
        } else if (newDevice?.kind === 'videoinput') {
            newDeviceType = 'video';
        }

        newDeviceType && onChangeItem && onChangeItem(newDeviceType, newDevice);
    };

    useEffect(() => {
        if (mobile) {
            return;
        }
        if (!deviceList.length && deviceOn && !deviceListIsLoading) {
            onChangeValue(true);
        } else if (deviceList.length) {
            checkDeviceChanged();
        }
    }, [deviceList]);

    const componentRef = useRef<HTMLDivElement>(null);

    let selectedItemId: string;
    if (selectedDeviceId) {
        selectedItemId = selectedDeviceId;
    } else {
        selectedItemId = '';
    }
    const deviceEnabled =
        (deviceList.length > 0 || mobile) && devicePermittedByAdmin && readyToStream && canPublish;

    const getDynamicDeviceImg = () => {
        if (!deviceEnabled) {
            return imgDisabled;
        }
        if (deviceOn) {
            return imgOn;
        }
        return imgOff;
    };
    const getDynamicDeviceStyle = () => {
        if (!deviceEnabled) {
            const deviceDisabledStyles = styles.device_disabled;
            if (!devicePermittedByAdmin) {
                return classNames([deviceDisabledStyles, styles.device_disabledByAdmin]);
            }
            return deviceDisabledStyles;
        }
        return '';
    };
    const deviceMobileStyle = mobile ? styles.device_mobile : '';
    const deviceStyle = classNames([
        styles.device,
        deviceListIsOpen ? styles.device_open : '',
        getDynamicDeviceStyle(),
        deviceMobileStyle,
    ]);

    const menuStyle = classNames([styles.menu, deviceListIsOpen ? styles.menu_open : '']);

    const onClickDevice = (e: any) => {
        e.stopPropagation();
        setDeviceListIsOpen(false);

        if (onChangeValue) {
            onChangeValue();
        }
    };

    const onClickOpenList = (e: any) => {
        e.stopPropagation();
        setDeviceListIsOpen(!deviceListIsOpen);
    };

    const closeDropDown = (e: any) => {
        if (e.type === 'keydown') {
            setDeviceListIsOpen(false);
            return;
        }
        const target = e.target as Element;
        // Case 1: two Components on a page. If you click on the first, the second should close
        // Case 2: click on an open Component should close the dropdown menu
        if (componentRef.current && !componentRef.current.contains(target)) {
            setDeviceListIsOpen(false);
        }
    };

    const calculatedSelectedStyle = (device: IUserDevice) => {
        if (device.deviceId === selectedItemId) {
            return classNames([styles.menu__item, styles.menu__item_selected]);
        }
        return styles.menu__item;
    };

    const renderDevicesList = (): ReactNode =>
        deviceList.map((device, idx) => (
            <ConferenceDeviceItem
                key={device.deviceId}
                idx={idx}
                device={device}
                className={calculatedSelectedStyle(device)}
                enabled={deviceOn}
                onSelectItem={onChangeItem}
                closeDropDown={() => setDeviceListIsOpen(false)}
            />
        ));

    const renderHeaderText = () => <div className={styles.menu__header}>{menuHeaderText}</div>;
    const renderSurroundSound = () => {
        if (!use3dAudioFeature || !setUse3dAudio) {
            return null;
        }
        return (
            <div className={classNames([styles.menu__item, styles.menu__item_surroundSound])}>
                <Form
                    onSubmit={(values) => {
                        setDeviceListIsOpen(false);
                        setUse3dAudio(values.use3dAudio);
                    }}
                    initialValues={{ use3dAudio }}>
                    {({ handleSubmit }) => (
                        <form onChange={handleSubmit}>
                            <label>
                                <Checkbox name='use3dAudio' className={styles.checkbox} />
                                {t({
                                    id: 'ConferenceDevice.surroundSound',
                                    message: 'Объёмный звук',
                                })}
                            </label>
                        </form>
                    )}
                </Form>
            </div>
        );
    };

    return (
        <div className={classNames([styles.wrapper, className])} ref={componentRef}>
            {deviceEnabled && deviceList.length > 0 && (
                <Button
                    styleType='common'
                    className={styles.device__arrowBlock}
                    clickHandler={deviceEnabled ? onClickOpenList : undefined}>
                    {!deviceEnabled ? (
                        <img src={LockGrayImg} alt='Lock' />
                    ) : (
                        <Arrow
                            className={styles.arrow}
                            size='medium'
                            color={'white'}
                            orientation='up'
                        />
                    )}
                </Button>
            )}
            <button className={deviceStyle} onClick={deviceEnabled ? onClickDevice : undefined}>
                <div className={styles.deviceImgWrapper}>
                    <img
                        src={getDynamicDeviceImg()}
                        alt='deviceImg'
                        className={deviceOn ? '' : classNameImgOff}
                    />
                </div>
                <div
                    className={classNames([
                        styles.device__statusText,
                        mobile ? styles.device__statusText__mobile : '',
                    ])}>
                    {text}
                </div>
            </button>
            {!mobile && deviceListIsOpen && (
                <OutsideClick onOutsideClick={closeDropDown}>
                    <div className={menuStyle}>
                        {menuHeaderText && renderHeaderText()}
                        <div className={styles.menu__items}>{renderDevicesList()}</div>
                        {renderSurroundSound()}
                    </div>
                </OutsideClick>
            )}
            {!deviceListIsOpen && !mobile && (
                <div className={styles.tooltipLabel}>
                    <HoverLabel>{tooltip}</HoverLabel>
                </div>
            )}
        </div>
    );
};
