import { createAsyncThunk } from '@reduxjs/toolkit';
import { storeUpcomingConferencesAction } from 'actions/conferencesList/storeUpcomingConferencesAction';
import { setConferenceIdsToShowAction } from 'actions/sessionSettings/setConferenceIdsToShowAction';
import { changeConferencesListFilterThunkAction } from 'thunk/conferences/changeConferencesListFilterThunkAction';
import { TAppState } from 'types/app/TAppStore';
import { IUpcomingConferenceInfo } from 'types/conferencesList/IUpcomingConferenceInfo';
import { isDateInFilterRange } from 'utils/dates/isDateInFilterRange';

interface IStoreNewUpcomingConferencePayload {
    newConferenceData: IUpcomingConferenceInfo | undefined;
    isRecurring?: boolean;
}

export const storeNewUpcomingConferenceThunkAction = createAsyncThunk<
    void,
    IStoreNewUpcomingConferencePayload
>(
    'storeNewUpcomingConference',
    async ({ newConferenceData, isRecurring }, { dispatch, getState }) => {
        if (newConferenceData) {
            const {
                sessionSettings: {
                    conferencesFilter: { upcoming },
                },
                conferencesList: { upcomingConferences },
            } = getState() as TAppState;

            if (isDateInFilterRange(upcoming, newConferenceData.timeStart)) {
                if (isRecurring) {
                    dispatch(
                        changeConferencesListFilterThunkAction({
                            upcoming: upcoming,
                            finished: false,
                        }),
                    );
                } else {
                    const upcomingConferencesNew: IUpcomingConferenceInfo[] = [newConferenceData];
                    for (const id in upcomingConferences) {
                        upcomingConferencesNew.push(upcomingConferences[id]);
                    }
                    dispatch(storeUpcomingConferencesAction(upcomingConferencesNew));
                    dispatch(
                        setConferenceIdsToShowAction(
                            upcomingConferencesNew
                                .sort(
                                    (a, b) =>
                                        new Date(a.timeStart).getTime() -
                                        new Date(b.timeStart).getTime(),
                                )
                                .map((i: IUpcomingConferenceInfo) => i.id),
                        ),
                    );
                }
            }
        }
    },
);
