import { EPaths } from 'constants/EPaths';
import useIsMounted from 'hooks/useMounted';
import { useEffect } from 'react';
import { redirect } from 'utils/redirect';
import { IStateProps } from './types';

export const NoMatchPage: React.FC<IStateProps> = ({ isMobile }) => {
    const isMounted = useIsMounted();

    const redirectToPage = () => {
        if (isMounted()) {
            if (isMobile) {
                redirect(EPaths.UPCOMING_CONFERENCES);
            } else {
                redirect('/');
            }
        }
    }

    useEffect(()=>{
        redirectToPage()
    })

    return null;
};
