import { IWebHook } from 'admin-app/types/IWebHook';
import { INetworkService, IRequestResult } from 'types/api/INetworkService';

export const getWebHooksApiCall = (
    networkService: INetworkService,
): Promise<IRequestResult<IWebHook[]>> => {
    return networkService.get({
        url: '/api/admin/webhooks',
    });
};
