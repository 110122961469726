import { modifyParticipantTalkingAction } from 'actions/applicationView/modifyParticipantTalkingAction';
import { setParticipantLastActivityTimeAction } from 'actions/applicationView/setParticipantLastActivityTime';
import { modifyJanusPublisherAction } from 'actions/janus/modifyJanusPublisherAction';
import { setOwnTalkingStatusAction } from 'actions/janus/setOwnTalkingStatusAction';
import { getJanusPublisherSelector } from 'selectors/getJanusPublisherSelector';
import { TAppState } from 'types/app/TAppStore';
import { calculateTalkingStatus } from './calculateTalkingStatus';
import { janusCtx } from './janusCtx';
export const setParticipantTalkingStatus = (
    publisherId: string,
    isTalking: boolean,
    audioLevel?: string,
) => {
    const {
        janus: { ownParticipantId },
        applicationView: { participantsLocalMuteStatus },
    } = janusCtx.getState() as TAppState;
    if (publisherId === ownParticipantId) {
        janusCtx.dispatch(setOwnTalkingStatusAction(isTalking));
        if (isTalking) {
            janusCtx.dispatch(
                setParticipantLastActivityTimeAction({
                    time: Date.now(),
                    id: ownParticipantId,
                }),
            );
        }
    } else {
        const eventPublisher = getJanusPublisherSelector(janusCtx.getState(), publisherId);
        const {
            applicationView: { participantsTalking },
        } = janusCtx.getState();
        const talkingStatus = eventPublisher
            ? participantsTalking[eventPublisher?.participantId]
            : undefined;

        if (eventPublisher && talkingStatus !== isTalking) {
            janusCtx.dispatch(
                modifyParticipantTalkingAction({
                    participantId: eventPublisher.participantId,
                    talking: calculateTalkingStatus(
                        eventPublisher.audioVideoProps,
                        eventPublisher.audioVideoProps,
                        isTalking,
                        participantsLocalMuteStatus[eventPublisher.participantId]?.audio,
                    ),
                }),
            );
            if (audioLevel) {
                janusCtx.dispatch(
                    modifyJanusPublisherAction({
                        participantId: eventPublisher.participantId,
                        audioLevel,
                    }),
                );
            }
            if (isTalking) {
                janusCtx.dispatch(
                    setParticipantLastActivityTimeAction({
                        time: Date.now(),
                        id: eventPublisher.participantId,
                    }),
                );
            }
        }
    }
};
