import styles from '../UpcomingConferenceMobile.module.scss';
import { IOwnProps } from './types';
import { UpcomingConferenceInfoFooterMobile } from './UpcomingConferenceInfoFooterMobile';
import { UpcomingConferenceInfoHeaderMobileConnected } from './UpcomingConferenceInfoHeaderMobile';

export const UpcomingConferenceInfoMobile: React.FC<IOwnProps> = ({
    conference,
    conferenceResultId,
    userIsOwner,
    goToConnectConference,
    handleTouchMove,
    handleTouchStart,
}) => {
    const creatorFullName = `${conference.creator.firstName} ${conference.creator.lastName}`;
    const isStartedConference = conference.status === 'STARTED';

    return (
        <div
            className={`${styles.info} ${isStartedConference ? styles.info_started : ''}`}
            onClick={() => goToConnectConference(conferenceResultId)}
            onTouchStart={handleTouchStart}
            onTouchMove={(e) => handleTouchMove(e, conference.id, userIsOwner)}>
            <UpcomingConferenceInfoHeaderMobileConnected
                conferenceName={conference.name}
                conferenceTimeStart={conference.timeStart}
                isRecurringConference={conference.seriesId !== undefined}
            />
            <UpcomingConferenceInfoFooterMobile
                invitedUsers={conference.invitedUsers}
                creatorFullName={creatorFullName}
                conferenceResultId={conferenceResultId}
                isStartedConference={isStartedConference}
                goToConnectConference={goToConnectConference}
            />
        </div>
    );
};
