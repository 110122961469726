import { storeCurrentConferenceParticipantsAction } from 'actions/conferences/storeCurrentConferenceParticipantsAction';
import { createReducer } from '@reduxjs/toolkit';
import { addToWaitingListAction } from 'actions/lobby/addToWaitingListAction';
import { allowedJoinToConferenceAction } from 'actions/lobby/allowedJoinToConferenceAction';
import { deleteFromWaitingListAction } from 'actions/lobby/deleteFromWaitingListAction';
import { resetLobbyStatusAction } from 'actions/lobby/resetLobbyStatusAction';
import { resetLobbyStoreAction } from 'actions/lobby/resetLobbyStoreAction';
import { setWaitingListAction } from 'actions/lobby/setWaitingListAction';
import { resetStoreAction } from 'actions/resetStoreAction';
import { IUserInLobby } from 'types/IUserInLobby';

export interface ILobbyReducer {
    waitingList: IUserInLobby[];
    allowedJoinConference: boolean | null;
}

export const lobbyReducerInitialState: ILobbyReducer = {
    waitingList: [],
    allowedJoinConference: null,
};

export const lobbyReducer = createReducer(lobbyReducerInitialState, (builder) =>
    builder
        .addCase(resetLobbyStoreAction, () => {
            return lobbyReducerInitialState;
        })
        .addCase(allowedJoinToConferenceAction, (state, { payload: allowed }) => {
            state.allowedJoinConference = allowed;
        })
        .addCase(resetLobbyStatusAction, (state) => {
            state.allowedJoinConference = null;
        })
        .addCase(setWaitingListAction, (state, { payload: participants }) => {
            state.waitingList = participants;
        })
        .addCase(addToWaitingListAction, (state, { payload: participant }) => {
            state.waitingList.push(participant);
        })
        .addCase(deleteFromWaitingListAction, (state, { payload: participantId }) => {
            state.waitingList = state.waitingList.filter(
                (el) => el.participantId !== participantId,
            );
        })
        .addCase(resetStoreAction, () => lobbyReducerInitialState)
        .addCase(storeCurrentConferenceParticipantsAction, (state, { payload: participants }) => {
            if (state.waitingList.length > 0) {
                participants.forEach(({ participantId }) => {
                    state.waitingList = state.waitingList.filter(
                        ({ participantId: waitingParticipantId }) =>
                            waitingParticipantId !== participantId,
                    );
                });
            }
        }),
);
