import { Field } from 'react-final-form';
import { Select } from '../Select';
import { IOwnProps } from './types';

export const SelectField: React.FC<IOwnProps> = ({ name, options, ...props }) => {
    return (
        <Field name={name}>
            {({ input: { onChange, value }, meta }) => {
                // const errorStyles = meta?.error ? styles.error : '';
                return <Select currentValue={value} options={options} onChange={onChange} { ...props}/>;
            }}
        </Field>
    );
};
