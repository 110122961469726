import { HoverTooltip } from 'components/common/HoverTooltip';
import { useEffect, useRef, useState } from 'react';
import styles from './ConferenceChatSystemMessage.module.scss';
import { IOwnProps } from './types';

export const ConferenceChatSystemMessage: React.FC<IOwnProps> = ({ event, text, time }) => {
    const element = useRef<HTMLDivElement | null>(null);
    const [showTooltip, setShowTooltip] = useState(false);

    useEffect(() => {
        if (element.current) {
            const width = element.current.offsetWidth;
            const parentWidth = element.current.parentElement?.offsetWidth;
            if (parentWidth && width > parentWidth - 32) {
                setShowTooltip(true);
            }
        }
    });

    return (
        <div
            className={`${styles.systemMessage} ${
                showTooltip ? styles.systemMessage_overflow : ''
            }`}>
            <div
                className={`${styles.systemMessage__tooltip} ${
                    !showTooltip ? styles.systemMessage__tooltip_neverShow : ''
                }`}>
                <HoverTooltip name={text} />
            </div>
            <span ref={element}>{text}</span>
            <span>
                <span>{event}</span> <span>{time}</span>
            </span>
        </div>
    );
};
