import { TSwipeDirection } from './TSwipeDirection';

export const getSwipeDirection = (
    prev: [number, number],
    current: [number, number],
): TSwipeDirection => {
    const [prevX, prevY] = prev;
    const [currentX, currentY] = current;
    const xDiff = prevX - currentX;
    const yDiff = prevY - currentY;

    if (Math.abs(xDiff) > Math.abs(yDiff)) {
        if (xDiff > 0) {
            return 'left';
        } else {
            return 'right';
        }
    } else {
        if (yDiff > 0) {
            return 'down';
        } else {
            return 'up';
        }
    }
};
