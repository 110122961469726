import { INetworkService } from 'types/api/INetworkService';

interface IPayload {
    conferenceId: string;
}

export const stopConferenceRecordApiCall = (
    networkService: INetworkService,
    { conferenceId }: IPayload,
) =>
    networkService.put({
        url: `/api/conferences/${conferenceId}/recorder/stop`,
    });
