import { createAsyncThunk } from '@reduxjs/toolkit';
import { EPaths } from 'constants/EPaths';
import { setCurrentConferenceDataThunkAction } from 'thunk/conferenceSession/setCurrentConferenceDataThunkAction';
import { showNotifyThunkAction } from 'thunk/notify/showNotifyThunkAction';
import { TAppState } from 'types/app/TAppStore';
import { IConferenceConnectInfo } from 'types/conferences/IConferenceConnectInfo';
import { getDefaultErrorNotification } from 'utils/getDefaultErrorNotification';
import { redirect } from 'utils/redirect';
import { checkCurrentProfileThunkAction } from './../auth/checkCurrentProfileThunkAction';
import { getConferenceConnectInfoThunkAction } from './getConferenceConnectInfoThunkAction';
import { getConferenceInfoThunkAction } from './getConferenceInfoThunkAction';

interface IPayload {
    conferenceId: string;
    pin?: string;
}

export const prepareToConnectToConferenceThunkAction = createAsyncThunk(
    'prepareToConnectToConference',
    async ({ conferenceId, pin }: IPayload, { getState, dispatch }) => {
        dispatch(checkCurrentProfileThunkAction());

        const { payload: result } = await dispatch(
            getConferenceConnectInfoThunkAction({ id: conferenceId }),
        );
        if (!result) {
            dispatch(showNotifyThunkAction(getDefaultErrorNotification()));
            return;
        }
        const {
            auth: { anonymous, anonymousUser, currentUser },
        } = getState() as TAppState;

        const connectInfo = result as IConferenceConnectInfo;
        let resultPin = pin || connectInfo?.pin;

        if (connectInfo.creator.userId === currentUser?.id && !resultPin) {
            const conferenceInfo = await dispatch(
                getConferenceInfoThunkAction({ id: conferenceId, forceUpdate: true }),
            ).unwrap();
            if (conferenceInfo) {
                resultPin = conferenceInfo?.pin;
            }
        }
        dispatch(
            setCurrentConferenceDataThunkAction({
                conferenceId,
                creator: connectInfo.creator,
                pin: resultPin,
            }),
        );
        if (connectInfo.registeredOnly && anonymous) {
            redirect('/loginForJoin', { from: 'connectViaLink' });
        } else if (anonymous && !anonymousUser?.name) {
            redirect(`${EPaths.INPUT_USER_NAME}/${conferenceId}`);
        } else if (!resultPin) {
            redirect(`${EPaths.ENTER_PIN}/${conferenceId}`);
        } else {
            redirect(`${EPaths.CONNECT_TO_CONFERENCE}/${conferenceId}`);
        }
    },
);
