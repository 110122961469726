import { createAsyncThunk } from '@reduxjs/toolkit';
import { allowedJoinToConferenceAction } from 'actions/lobby/allowedJoinToConferenceAction';
import { TAppState } from 'types/app/TAppStore';

export const checkPermissionToConnectToCurrentConferenceThunkAction = createAsyncThunk(
    'checkPermissionToConnectToCurrentConference',
    async (_: void, { getState, dispatch }) => {
        const {
            conferenceSession: { currentConferenceId, currentUserIsOwner },
            conferences: { conferencesConnectInfo },
        } = getState() as TAppState;
        const conference = conferencesConnectInfo[currentConferenceId];
        if (conference.lobby) {
            if (currentUserIsOwner) {
                dispatch(allowedJoinToConferenceAction(true));
            }
        } else {
            dispatch(allowedJoinToConferenceAction(true));
        }
    },
);
