import { MobileWrapperConnected } from 'components/common/MobileWrapper/MobileWrapperConnected';
import { LobbyPageConnected } from './LobbyPageConnected';
import { LobbyPageMobileConnected } from './LobbyPageMobileConnected';

export const LobbyPageResponsive = () => (
    <MobileWrapperConnected
        renderDesktop={() => <LobbyPageConnected />}
        renderMobile={() => <LobbyPageMobileConnected />}
    />
);
