import { TAppState } from 'types/app/TAppStore';

export const getForbiddenStreams = (state: TAppState) => {
    const { audio, video } = state.conferenceSession.currentConferencePermissions.forbiddenStreams;
    const { ownParticipantId, localStreamProps } = state.janus;
    const resultObj = { audio: false, video: false };

    if (audio.some((id) => id === ownParticipantId)) {
        resultObj.audio = true;
    } else {
        if (!localStreamProps.audioPermitted) {
            resultObj.audio = false;
        }
    }
    if (video.some((id) => id === ownParticipantId)) {
        resultObj.video = true;
    } else {
        if (!localStreamProps.videoPermitted) {
            resultObj.video = false;
        }
    }
    return resultObj;
};
export const getAllowedStreams = (state: TAppState) => {
    const { audio, video } = state.conferenceSession.currentConferencePermissions.allowedStreams;
    const ownParticipantId = state.janus.ownParticipantId;
    const resultObj = { audio: false, video: false };
    if (audio.some((id) => id === ownParticipantId)) {
        resultObj.audio = true;
    }
    if (video.some((id) => id === ownParticipantId)) {
        resultObj.video = true;
    }
    return resultObj;
};
export const getForbiddenStreamsByConferenceSettings = (state: TAppState) => {
    const currentConfId = state.conferenceSession.currentConferenceId;
    const confConnectInfo = state.conferences.conferencesConnectInfo[currentConfId];
    if (!confConnectInfo) {
        return {
            video: false,
            audio: false,
        };
    }
    return {
        video: confConnectInfo.forbidVideo,
        audio: confConnectInfo.forbidAudio,
    };
};