import { connect } from 'react-redux';
import { getCurrentConferenceConnectInfoSelector } from 'selectors/getCurrentConferenceConnectInfoSelector';
import { loadDataForConnectAndConferencePagesThunkAction } from 'thunk/conference/loadDataForConnectAndConferencePagesThunkAction';
import { TAppDispatch } from 'types/app/TAppDispatch';
import { TAppState } from 'types/app/TAppStore';
import { InputUsernamePage } from './InputUsernamePage';
import { IDispatchProps, IStateProps } from './types';

const mapStateToProps = (state: TAppState): IStateProps => ({
    conferenceConnectInfo: getCurrentConferenceConnectInfoSelector(state),
});

const mapDispatchToProps = (dispatch: TAppDispatch): IDispatchProps => ({
    loadData: (id) =>
        dispatch(loadDataForConnectAndConferencePagesThunkAction({ id, page: 'inputUsername' })),
});

export const InputUsernamePageConnected = connect(
    mapStateToProps,
    mapDispatchToProps,
)(InputUsernamePage);
