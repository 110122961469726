import { EPaths } from 'constants/EPaths';
import { getCurrentConferenceConnectInfoSelector } from 'selectors/getCurrentConferenceConnectInfoSelector';
import { leaveFromLobbyThunkAction } from 'thunk/conference/leaveFromLobbyThunkAction';
import { startJanusConferenceThunkAction } from 'thunk/conference/startJanusConferenceThunkAction';
import { TAppDispatch } from 'types/app/TAppDispatch';
import { TAppState } from 'types/app/TAppStore';
import { replace } from 'utils/redirect';
import { IDispatchProps, IStateProps } from './types';

export const mapStateToProps = (state: TAppState): IStateProps => ({
    conferenceConnectInfo: getCurrentConferenceConnectInfoSelector(state),
    confId: state.conferenceSession.currentConferenceId,
    responseToConferenceJoinRequest: state.lobby.allowedJoinConference,
    isAnonymous: state.auth.anonymous,
});

export const mapDispatchToProps = (dispatch: TAppDispatch): IDispatchProps => ({
    goToUpcomingConferences: () => replace(EPaths.UPCOMING_CONFERENCES),
    goToConferencePage: async (id) => {
        await dispatch(startJanusConferenceThunkAction());
        replace(`/conference/${id}`);
    },
    leaveFromLobby: () => dispatch(leaveFromLobbyThunkAction()),
});
