import { Trans } from '@lingui/react';
import CircleImg from 'assets/Circle.svg';
import { Button } from 'components/common/Button';
import { StatusLabel } from 'components/common/StatusLabel';
import { Form, FormRenderProps } from 'react-final-form';
import { IRecurringData } from 'types/conferences/IRecurringData';
import { ConferenceRepeatInfoConnected } from '../ConferenceRepeatInfo';
import styles from './ConferenceRepeatDialogMobile.module.scss';
import { HeaderMobile } from './Header/HeaderMobile';
import { RepeatDay } from './RepeatDay';
import { IOwnProps } from './types';
import { getInitialValues, getStatusLabelText, mainFormField, validateFormValues } from './utils';

export const ConferenceRepeatDialogMobile: React.FC<IOwnProps> = ({
    recurringData,
    selectedOption,
    specifiedDate,
    specifiedTime,
    closePopup,
    updateField,
}) => {
    const validation = (values: IRecurringData) => {
        return validateFormValues({
            values,
            recurringStartDate: recurringData?.startDate,
            recurringEndDate: recurringData?.endDate,
        });
    };

    const submitHandler = (values: IRecurringData) => {
        if (values.step === 'week') {
            updateField(mainFormField, { ...values, count: '1' });
        } else {
            updateField(mainFormField, values);
        }
        closePopup();
    };

    const render = ({
        handleSubmit,
        values,
        errors,
        pristine,
    }: FormRenderProps<IRecurringData>) => (
        <form onSubmit={handleSubmit}>
            <div className={styles.errorContainer}>
                <StatusLabel text={getStatusLabelText(errors)} />
            </div>
            <HeaderMobile
                startDate={values.startDate}
                endDate={values.startDate}
                time={values.time}
                errors={errors}
            />
            <div className={styles.body}>
                <img src={CircleImg} className={styles.img} alt='Repeat frequency' />
                {/* <RepeatFrequency step={values.step} /> */}
                {values.step === 'week' && <RepeatDay className={styles.days_mobile} />}
                <ConferenceRepeatInfoConnected
                    formData={values}
                    isEndDateValid={!errors?.endDate}
                    className={styles.resultTextInfo}
                />
            </div>
            <div className={styles.footer}>
                <Button
                    styleType='mobilePrimaryAction'
                    type='submit'
                    className={styles.actionButton}
                    disabled={
                        (pristine && recurringData?.step === values.step) ||
                        values.days.length === 0
                    }>
                    <Trans id='Save' message='Сохранить' />
                </Button>
            </div>
        </form>
    );
    return (
        <div className={styles.container}>
            <Form
                render={render}
                onSubmit={submitHandler}
                validate={validation}
                initialValues={getInitialValues(recurringData, selectedOption, {
                    specifiedDate,
                    specifiedTime,
                })}
            />
        </div>
    );
};
