import { storeCurrentConferencePermissionsAction } from 'actions/conferenceSession/storeCurrentConferencePermissionsAction';
import { setParticipantMuteStatusAction } from 'actions/janus/setParticipantMuteStatusAction';
import { batch } from 'react-redux';
import { TAppState } from 'types/app/TAppStore';
import { ISocketConferenceStreamPermissions } from 'types/socket/conferenceEvents/ISocketConferenceStreamPermissions';
import { getFilteredParticipants } from './getFilteredParticipants';
import { getNewPermissionsData } from './getNewPermissionsData';
import { getStreamsPermissionsFormattedData } from './getStreamsPermissionsFormattedData';
import { isNewList } from './isNewList';

export const setStreamsPermissions = ({
    forbiddenStreams,
    allowedStreams,
    getState,
    dispatch,
}: {
    forbiddenStreams: ISocketConferenceStreamPermissions | undefined;
    allowedStreams: ISocketConferenceStreamPermissions | undefined;
    getState: any;
    dispatch: any;
}) => {
    const {
        conferenceSession: { currentConferencePermissions },
    } = getState() as TAppState;
    const newParticipantsPermissions: { [key: string]: { audioPermitted?: boolean; videoPermitted?: boolean } } = {};
    const formattedData = getStreamsPermissionsFormattedData({ forbiddenStreams, allowedStreams });

    for (const permissionType in formattedData) {
        const permissionData = formattedData[permissionType as keyof typeof formattedData];
        if (permissionData) {
            for (const streamType in permissionData) {
                const participantsFromSocket: string[] =
                    permissionData[streamType as keyof typeof permissionData];
                const newPermissionProps = {
                    streamType: streamType as keyof typeof permissionData,
                    permissionType: permissionType as keyof typeof formattedData,
                };
                if (
                    !isNewList({
                        currentPermissions: currentConferencePermissions,
                        newParticipantsId: participantsFromSocket,
                        ...newPermissionProps,
                    })
                ) {
                    continue;
                }

                const filteredParticipants = getFilteredParticipants({
                    participantsFromSocket,
                    newPermissionProps,
                    currentConferencePermissions,
                });

                filteredParticipants.participants.forEach((id) => {
                    getNewPermissionsData({
                        resultObj:newParticipantsPermissions,
                        participantId: id,
                        isRemovedUser: filteredParticipants.isRemoved,
                        getState,
                        newPermissionProps,
                    });
                });
            }
        }
    }

    if (Object.keys(newParticipantsPermissions).length) {
        batch(() => {
            for (const participantId in newParticipantsPermissions) {
                dispatch(
                    setParticipantMuteStatusAction({
                        audioVideoProps: newParticipantsPermissions[participantId],
                        participantId,
                    }),
                );
            }
        });
    }
    dispatch(storeCurrentConferencePermissionsAction(formattedData));
};