import { setConferenceExitConfirmationDialogVisibilityAction } from 'actions/applicationView/setConferenceExitConfirmationDialogVisibilityAction';
import { connect } from 'react-redux';
import { setFramesToFullscreenThunkAction } from 'thunk/appView/setFramesToFullscreenThunkAction';
import { exitFromConferenceThunkAction } from 'thunk/conference/exitFromConferenceThunkAction';
import { showNotifyThunkAction } from 'thunk/notify/showNotifyThunkAction';
import { TAppDispatch } from 'types/app/TAppDispatch';
import { TAppState } from 'types/app/TAppStore';
import { ConferencePageTemplate } from './ConferencePageTemplate';
import { IDispatchProps, IStateProps } from './types';

const mapDispatchToProps = (dispatch: TAppDispatch): IDispatchProps => ({
    showExitConfirmation: () => dispatch(setConferenceExitConfirmationDialogVisibilityAction(true)),
    hideExitConfirmation: () => dispatch(setConferenceExitConfirmationDialogVisibilityAction(false)),
    exitFromConference: () => dispatch(exitFromConferenceThunkAction({})),
    setPriorityFrames: (args) => dispatch(setFramesToFullscreenThunkAction(args)),
    showNotification: (text) =>
        dispatch(
            showNotifyThunkAction({
                text,
                timeout: 5000,
                type: 'notify',
            }),
        ),
});

const mapStateToProps = (state: TAppState): IStateProps => ({
    chatState: state.applicationView.showChat,
    conferenceSessionId: state.conferenceSession.currentSessionId,
    participantsListOpen: state.applicationView.participantListOpen,
    priorityFrameId: state.applicationView.fullScreenFrames[0],
    showConferenceExitConfirmationDialog:
        state.applicationView.showConferenceExitConfirmationDialog,
});

export const ConferencePageTemplateConnected = connect(
    mapStateToProps,
    mapDispatchToProps,
)(ConferencePageTemplate);
