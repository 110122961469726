import { t } from '@lingui/macro';
import SettingsImg from 'assets/Settings.svg';
import { ConferenceStartedSettingsFormResponsive } from 'components/ConferenceStartedSettingsForm';
import styles from './ConferenceSettingsDialog.module.scss';
import { IOwnProps } from './types';

export const ConferenceSettingsDialog: React.FC<IOwnProps> = ({
    conferenceInfo,
    handleAfterSubmit,
    handleClose,
}) => (
    <div className={styles.dialog}>
        <div className={styles.header}>
            <div className={styles.left}>
                <img src={SettingsImg} alt='settings' />
                <span>{t({ id: 'Conference settings', message: 'Настройки конференции' })}</span>
            </div>
        </div>
        <div className={styles.form}>
            <ConferenceStartedSettingsFormResponsive
                conferenceInfo={conferenceInfo}
                handleCancel={handleClose}
                handleAfterSubmit={handleAfterSubmit}
            />
        </div>
    </div>
);
