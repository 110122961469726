import { connect } from 'react-redux';
import { prepareForConferenceConnectMobileThunkAction } from 'thunk/conference/prepareForConferenceConnectMobileThunkAction';
import { TAppDispatch } from 'types/app/TAppDispatch';
import { TAppState } from 'types/app/TAppStore';
import { getDisplayName } from 'utils/users/getDisplayName';
import { JoinConferencePageMobile } from './JoinConferencePageMobile';
import { IDispatchProps, IStateProps } from './types';

const mapStateToProps = (state: TAppState): IStateProps => ({
    displayName: getDisplayName(state.auth.currentUser || state.auth.anonymousUser),
});

const mapDispatchToProps = (dispatch: TAppDispatch): IDispatchProps => ({
    prepareToConferenceConnectMobile: (args) =>
        dispatch(prepareForConferenceConnectMobileThunkAction(args)).unwrap(),
});

export const JoinConferencePageMobileConnected = connect(
    mapStateToProps,
    mapDispatchToProps,
)(JoinConferencePageMobile);
