import { createAsyncThunk } from '@reduxjs/toolkit';
import { EUserThunkActions } from 'admin-app/constants/thunk/EUserThunkActions';
import { getUsersApiCall } from 'admin-app/api/getUsersApiCall';
import { getNetworkService } from 'api/getNetworkService';
import { TAppState } from 'types/app/TAppStore';
import { IAdminPanelUser } from 'admin-app/types/IAdminPanelUser';

const currentUserSelector = (users: IAdminPanelUser[] | null) => {
    const splittedPath = window.location.pathname.split('/');
    const userId = splittedPath[splittedPath.length - 1];
    const targetUser = users ? users.find(({ id }) => id === userId) : null;
    return { targetUser, userId };
};

export const getUserThunkAction = createAsyncThunk(
    EUserThunkActions.GET_USER,
    async (__, { getState }) => {
        const {
            admin: {
                users: { list },
            },
        } = getState() as TAppState;
        const userExistingData = currentUserSelector(list);
        if (userExistingData?.targetUser) {
            return userExistingData.targetUser;
        }
        const response = await getUsersApiCall(getNetworkService(), {
            search: userExistingData.userId,
        });
        if (response.success) {
            return response.data.array[0];
        }
        return null;
    },
);
