import { createAsyncThunk } from '@reduxjs/toolkit';
import { TAppState } from 'types/app/TAppStore';
import { loadJanus } from 'utils/janus/loadJanusScript';
import { replace } from 'utils/redirect';
import { joinToCurrentConferenceThunkAction } from '../conference/joinToCurrentConferenceThunkAction';
import { startJanusConferenceThunkAction } from 'thunk/conference/startJanusConferenceThunkAction';
import { ERecorderThunkAction } from 'constants/thunk/ERecorderThunkAction';

export const joinRecorderToConferenceThunkAction = createAsyncThunk(
    ERecorderThunkAction.JOIN_RECORDER_TO_CONFERENCE,
    async (_, { getState, dispatch }) => {
        await loadJanus();
        const params = new URLSearchParams(document.location.search);
        const recorderId = params.get('recorderId');
        const { payload: error } = await dispatch(
            joinToCurrentConferenceThunkAction(recorderId || undefined),
        );
        if (error) {
            return error;
        }
        const {
            conferenceSession: { currentConferenceId },
        } = getState() as TAppState;

        await dispatch(startJanusConferenceThunkAction());
        replace(`/simpleConference/${currentConferenceId}`);
    },
);
