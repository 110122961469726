import { createAsyncThunk } from '@reduxjs/toolkit';
import { setCurrentConferenceDataThunkAction } from 'thunk/conferenceSession/setCurrentConferenceDataThunkAction';
import { showNotifyThunkAction } from 'thunk/notify/showNotifyThunkAction';
import { TAppState } from 'types/app/TAppStore';
import { IConferenceConnectInfo } from 'types/conferences/IConferenceConnectInfo';
import { getDefaultErrorNotification } from 'utils/getDefaultErrorNotification';
import { replace } from 'utils/redirect';
import { getAndCheckConferenceForErrorsThunkAction } from './getAndCheckConferenceForErrorsThunkAction';
import { getConferenceInfoThunkAction } from './getConferenceInfoThunkAction';
import { joinToCurrentConferenceAndStartJanusThunkAction } from './joinToCurrentConferenceAndStartJanusThunkAction';
import { prepareToConnectToConferenceThunkAction } from './prepareToConnectToConferenceThunkAction';

interface IPayload {
    id: string;
    page: string;
}

type IResponse = Promise<IConferenceConnectInfo | undefined>

export const loadDataForConnectAndConferencePagesThunkAction = createAsyncThunk<IResponse, IPayload>(
    'loadDataForConnectAndConferencePages',
    async ({ id, page }, { getState, dispatch }) => {
        const {
            conferenceSession: { currentConferenceId },
            conferences: { conferencesConnectInfo },
            auth: { currentUser },
        } = getState() as TAppState;
        if (id === currentConferenceId && conferencesConnectInfo[id]) {
            if (conferencesConnectInfo[id].creator.userId === currentUser?.id) {
                // Owner
                await dispatch(getConferenceInfoThunkAction({ id }));
            }
            return conferencesConnectInfo[id];
        }
        const { payload: result } = await dispatch(getAndCheckConferenceForErrorsThunkAction(id));
        if ('error' in (result as { error: string })) {
            const { error } = result as { error: string };
            dispatch(
                showNotifyThunkAction({
                    ...getDefaultErrorNotification(),
                    text: error,
                }),
            );
            replace('/');
            return undefined;
        }
        const conferenceConnectInfo = result as IConferenceConnectInfo;
        if (conferenceConnectInfo.pin) {
            // Owner & invited users
            if (conferenceConnectInfo.creator.userId === currentUser?.id) {
                // Owner
                await dispatch(getConferenceInfoThunkAction({ id }));
            }
            dispatch(
                setCurrentConferenceDataThunkAction({
                    conferenceId: id,
                    creator: conferenceConnectInfo.creator,
                    pin: conferenceConnectInfo.pin,
                }),
            );
            if (page === 'conference') {
                const {
                    lobby: { allowedJoinConference },
                } = getState() as TAppState;
                if (conferenceConnectInfo.lobby && !allowedJoinConference) {
                    dispatch(prepareToConnectToConferenceThunkAction({ conferenceId: id }));
                } else {
                    dispatch(joinToCurrentConferenceAndStartJanusThunkAction(false));
                }
            }
        } else {
            dispatch(prepareToConnectToConferenceThunkAction({ conferenceId: id }));
        }
        return conferenceConnectInfo;
    },
);
