import { connect } from 'react-redux';
import { TAppState } from 'types/app/TAppStore';
import { ImageViewPopupHeader } from './ImageViewPopupHeader';
import { IStateProps } from './types';

const mapStateToProps = (state: TAppState): IStateProps => ({
    isMobile: state.applicationView.deviceProps.isTabletOrMobile,
});

export const ImageViewPopupHeaderConnected = connect(mapStateToProps)(ImageViewPopupHeader);
