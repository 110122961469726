import { t } from '@lingui/macro';
import { Trans } from '@lingui/react';
import { Button } from 'components/common/Button/Button';
import { Input } from 'components/common/Input/Input';
import React from 'react';
import { Form, FormRenderProps } from 'react-final-form';
import { Link } from 'react-router-dom';
import { isFormHasValidationErrors } from 'utils/isFormHasValidationErrors';
import styles from './InputUsernameFormMobile.module.scss';
import { IDispatchProps, IFields, IOwnProps, IStateProps } from './types';
import {
    getDefaultValuesFromLocalStorage,
    getPathToLoginWithRedirectToConnect,
    handleValidate,
} from './utils';

export const InputUsernameFormMobile: React.FC<IOwnProps & IDispatchProps & IStateProps> = ({
    successSubmitCallback,
    getSubmitHandler,
    currentConferenceId,
    currentConferencePin,
}) => {
    const defaultValues = getDefaultValuesFromLocalStorage(currentConferenceId);
    const render = ({ handleSubmit, submitting, errors }: FormRenderProps<IFields>) => (
        <form onSubmit={handleSubmit} className={styles.form}>
            <div className={styles.headerText}>
                <Trans
                    message='Для подключения к этой конференции введите имя'
                    id='To connect to this conference, enter the name'
                />
            </div>
            <div className={styles.block}>
                <div className={styles.text}>
                    <Trans id='Name' message='Имя' />
                </div>
                <Input
                    styleType='mobile'
                    placeholder={t({ id: 'Username', message: 'Имя пользователя' })}
                    name='username'
                    type='text'
                    className={styles.inputStyle}
                    touched={false}
                    autoFocus={true}
                    errorText={errors?.username}
                />
            </div>
            <Button
                styleType='mobilePrimaryAction'
                caption={t({ id: 'Log in as guest', message: 'Войти как гость' })}
                disabled={submitting || isFormHasValidationErrors(errors)}
                type='submit'
                className={styles.button}
            />
            <Link
                to={getPathToLoginWithRedirectToConnect({ pin: currentConferencePin, id: currentConferenceId })}
                className={styles.link}>
                <Trans message='У меня есть аккаунт' id='I have an account' />
            </Link>
        </form>
    );
    return (
        <Form
            initialValues={defaultValues}
            onSubmit={getSubmitHandler(successSubmitCallback)}
            validate={handleValidate}
            render={render}
        />
    );
};
