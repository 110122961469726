import { createReducer } from '@reduxjs/toolkit';
import { deleteConferenceRecordAction } from 'actions/records/deleteConferenceRecordAction';
import { deleteFromRecordDownloadListAction } from 'actions/records/deleteFromRecordDownloadListAction';
import { modifyToRecordDownloadListAction } from 'actions/records/modifyToRecordDownloadListAction';
import { setConferenceRecordNotifyStateAction } from 'actions/records/setConferenceRecordNotifyStateAction';
import { setConferencesRecordsAction } from 'actions/records/setConferencesRecordsAction';
import { resetStoreAction } from 'actions/resetStoreAction';
import { IConferenceRecord } from 'types/IConferenceRecord';
import { IRecordDownloadListItem } from 'types/IRecordDownloadListItem';

export interface IConferencesRecordsReduce {
    conferencesRecords: IConferenceRecord[];
    recordDownloadList: { [id: string]: IRecordDownloadListItem };
}

export const conferenceRecordsReducerInitialState: IConferencesRecordsReduce = {
    conferencesRecords: [],
    recordDownloadList: {},
};

export const conferencesRecordsReducer = createReducer(
    conferenceRecordsReducerInitialState,
    (builder) =>
        builder
            .addCase(setConferencesRecordsAction, (state, { payload: records }) => {
                state.conferencesRecords = records;
            })
            .addCase(
                setConferenceRecordNotifyStateAction,
                (state, { payload: { recordId, notifyState } }) => {
                    state.conferencesRecords = state.conferencesRecords.map((record) => {
                        if (record.id !== recordId) {
                            return record;
                        }
                        return { ...record, ...{ notifyAboutFinished: notifyState } };
                    });
                },
            )
            .addCase(deleteConferenceRecordAction, (state, { payload: recordId }) => {
                state.conferencesRecords = state.conferencesRecords.filter(
                    (record) => record.id !== recordId,
                );
            })
            .addCase(
                modifyToRecordDownloadListAction,
                (state, { payload: { id, total, downloaded } }) => {
                    state.recordDownloadList[id] = { total, downloaded };
                },
            )
            .addCase(deleteFromRecordDownloadListAction, (state, { payload: id }) => {
                delete state.recordDownloadList[id];
            })
            .addCase(resetStoreAction, () => conferenceRecordsReducerInitialState),
);
