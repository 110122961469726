import { MobileWrapperConnected } from 'components/common/MobileWrapper';
import { AccountConferenceDetailsPageConnected } from './AccountConferenceDetailsPageConnected';
import { AccountConferenceDetailsPageMobile } from './AccountConferenceDetailsPageMobile';
import { IOwnProps } from './types';

export const AccountConferenceDetailsPageResponsive: React.FC<IOwnProps> = (props) => (
    <MobileWrapperConnected
        renderDesktop={() => <AccountConferenceDetailsPageConnected {...props} />}
        renderMobile={() => <AccountConferenceDetailsPageMobile />}
    />
);
