import { createAsyncThunk } from '@reduxjs/toolkit';
import { loginApiCall } from 'api/authorization/loginApiCall';
import { getNetworkService } from 'api/getNetworkService';
import { EPaths } from 'constants/EPaths';
import { getCurrentUserProfileDataThunkAction } from 'thunk/auth/getCurrentUserProfileDataThunkAction';
import { createNewConferenceThunkAction } from 'thunk/conference/createNewConferenceThunkAction';
import { planNewConferenceThunkAction } from 'thunk/conference/planNewConferenceThunkAction';
import { IRequestError } from 'types/api/IRequestError';
import { TAppState } from 'types/app/TAppStore';
import { redirect } from 'utils/redirect';

interface IPayload {
    username: string;
    password: string;
    rememberMe?: boolean;
}

export const loginThunkAction = createAsyncThunk(
    'login',
    async (
        { username, password, rememberMe }: IPayload,
        { dispatch, getState },
    ): Promise<undefined | IRequestError> => {
        const response = await loginApiCall(getNetworkService(), {
            username,
            password,
            rememberMe,
        });
        if (response.success) {
            await dispatch(getCurrentUserProfileDataThunkAction());
            const search = location.search;
            const params = new URLSearchParams(search);
            const path = params.get('path');
            const pin = params.get('pin');
            const action = params.get('action');
            if (action !== null) {
                if (action === 'createConference') {
                    dispatch(createNewConferenceThunkAction());
                    return;
                }
                if (action === 'planNewConference') {
                    dispatch(planNewConferenceThunkAction());
                    return;
                }
            } else if (path !== null) {
                redirect(path + (pin ? '&pin=' + pin : ''));
            } else {
                const { auth: { currentUser } } = getState() as TAppState;
                if (!currentUser?.name?.first || !currentUser?.name?.last) {
                    redirect('/updateProfile');
                } else {
                    redirect(EPaths.UPCOMING_CONFERENCES);
                }
            }
        } else {
            return response.data;
        }
    },
);
