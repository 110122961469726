import { createReducer } from '@reduxjs/toolkit';
import { setCurrentUserDataAction } from 'actions/auth/setCurrentUserDataAction';
import { resetConferenceSessionStoreAction } from 'actions/conferenceSession/resetConferenceSessionStoreAction';
import { setCurrentConferenceCreatorAction } from 'actions/conferenceSession/setCurrentConferenceCreatorAction';
import { setCurrentConferenceIdAction } from 'actions/conferenceSession/setCurrentConferenceIdAction';
import { setCurrentConferenceOwnerAction } from 'actions/conferenceSession/setCurrentConferenceOwnerAction';
import { setCurrentConferencePinAction } from 'actions/conferenceSession/setCurrentConferencePinAction';
import { setCurrentConferenceRecordingAction } from 'actions/conferenceSession/setCurrentConferenceRecordingAction';
import { setCurrentSessionIdAction } from 'actions/conferenceSession/setCurrentSessionIdAction';
import { setSessionConferenceUsersAction } from 'actions/conferenceSession/setSessionConferenceUsersAction';
import { storeCurrentConferencePermissionsAction } from 'actions/conferenceSession/storeCurrentConferencePermissionsAction';
import { storeCurrentConferenceInvitedUsersAction } from 'actions/conferences/storeCurrentConferenceInvitedUsersAction';
import { storeCurrentConferenceParticipantsAction } from 'actions/conferences/storeCurrentConferenceParticipantsAction';
import { updateStoreCurrentConferenceParticipantsAction } from 'actions/conferences/updateStoreCurrentConferenceParticipantsAction';
import { resetStoreAction } from 'actions/resetStoreAction';
import {
    compareParticipantsByName,
    prepareParticipantsForStore,
} from 'reducers/utils/conferenceSessionReducerUtils';
import { IConferenceCreator } from 'types/IConferenceCreator';
import { IConferenceParticipant } from 'types/IConferenceParticipant';
import { IConferenceParticipants } from 'types/IConferenceParticipants';
import { IConferenceUser } from 'types/IConferenceUser';
import { ESocketConferenceParticipantActionType } from 'types/conferences/ESocketConferenceParticipantActionType';
import { TConferenceRecordingStatus } from 'types/conferences/TConferenceRecordingStatus';
import { getSessionNewParticipants } from 'utils/participants/getSessionNewParticipants';

export interface IConferenceSessionReducer {
    currentConferenceCreator: IConferenceCreator | null;
    currentConferenceId: string;
    currentConferenceInvitedUsers: IConferenceUser[];
    currentConferenceRecording: TConferenceRecordingStatus;
    currentConferenceParticipants: IConferenceParticipants;
    currentConferenceSortedParticipantsIds: string[];
    currentConferencePin: string;
    currentSessionId: string;
    currentUserIsOwner: boolean;
    currentConferencePermissions: {
        allowedStreams: {
            audio: string[];
            video: string[];
        };
        forbiddenStreams: {
            audio: string[];
            video: string[];
        };
    };
    existAndExitParticipants: IConferenceParticipant[];
}

const conferenceSessionInitialState: IConferenceSessionReducer = {
    currentConferenceCreator: null,
    currentConferenceId: '',
    currentConferenceInvitedUsers: [],
    currentConferenceRecording: 'off',
    currentConferenceParticipants: {},
    currentConferenceSortedParticipantsIds: [],
    currentConferencePin: '',
    currentSessionId: '',
    currentUserIsOwner: false,
    currentConferencePermissions: {
        allowedStreams: {
            audio: [],
            video: [],
        },
        forbiddenStreams: {
            audio: [],
            video: [],
        },
    },
    existAndExitParticipants: [],
};

export const conferenceSessionReducer = createReducer(conferenceSessionInitialState, (builder) =>
    builder
        .addCase(resetStoreAction, () => conferenceSessionInitialState)
        .addCase(resetConferenceSessionStoreAction, () => conferenceSessionInitialState)
        .addCase(setSessionConferenceUsersAction, (state, { payload: users }) => {
            state.existAndExitParticipants = users;
        })
        .addCase(setCurrentConferenceIdAction, (state, { payload: currentConferenceId }) => {
            state.currentConferenceId = currentConferenceId;
        })
        .addCase(setCurrentSessionIdAction, (state, { payload: currentSessionId }) => {
            state.currentSessionId = currentSessionId;
        })
        .addCase(setCurrentConferenceOwnerAction, (state, { payload: isOwner }) => {
            state.currentUserIsOwner = isOwner;
        })
        .addCase(setCurrentConferencePinAction, (state, { payload: currentConferencePin }) => {
            state.currentConferencePin = currentConferencePin;
        })
        .addCase(storeCurrentConferencePermissionsAction, (state, { payload: permissions }) => {
            if (permissions.allowedStreams) {
                state.currentConferencePermissions.allowedStreams = permissions.allowedStreams;
            }
            if (permissions.forbiddenStreams) {
                state.currentConferencePermissions.forbiddenStreams = permissions.forbiddenStreams;
            }
        })
        .addCase(storeCurrentConferenceParticipantsAction, (state, { payload: participants }) => {
            state.currentConferenceParticipants = prepareParticipantsForStore(participants);
            state.currentConferenceSortedParticipantsIds = [...participants]
                .sort(compareParticipantsByName)
                .map((el) => el.participantId);
            if (state.currentConferenceSortedParticipantsIds.length !== 0) {
                if (state.existAndExitParticipants.length === 0) {
                    state.existAndExitParticipants = participants;
                } else {
                    state.existAndExitParticipants = getSessionNewParticipants(
                        state.existAndExitParticipants,
                        participants,
                    );
                }
            }
        })
        .addCase(
            updateStoreCurrentConferenceParticipantsAction,
            (state, { payload: { actionType, participant } }) => {
                if (actionType === ESocketConferenceParticipantActionType.PARTICIPANT_LEAVE) {
                    state.currentConferenceSortedParticipantsIds =
                        state.currentConferenceSortedParticipantsIds.filter(
                            (id) => id !== participant.participantId,
                        );
                    delete state.currentConferenceParticipants[participant.participantId];
                }
            },
        )
        .addCase(setCurrentConferenceCreatorAction, (state, { payload: creator }) => {
            state.currentConferenceCreator = creator;
        })
        .addCase(setCurrentConferenceRecordingAction, (state, { payload: status }) => {
            state.currentConferenceRecording = status;
        })
        .addCase(storeCurrentConferenceInvitedUsersAction, (state, { payload: invitedUsers }) => {
            state.currentConferenceInvitedUsers = invitedUsers;
        })
        .addCase(setCurrentUserDataAction, (state, { payload: userData }) => {
            if (
                userData?.id &&
                state.currentConferenceId &&
                userData.id === state.currentConferenceCreator?.userId
            ) {
                state.currentUserIsOwner = true;
            }
        }),
);
