import { ITenantData } from 'admin-app/types/ITenantData';
import { INetworkService, IRequestResult } from 'types/api/INetworkService';

export const updatePublicKeyApiCall = (
    networkService: INetworkService,
    newKey: string,
): Promise<IRequestResult<ITenantData>> => {
    return networkService.put({
        url: '/api/admin/tenants',
        data: {
            publicKey: newKey,
        },
    });
};
