import { createAsyncThunk } from '@reduxjs/toolkit';
import { storeCurrentConferenceInvitedUsersAction } from 'actions/conferences/storeCurrentConferenceInvitedUsersAction';
import { getConferenceInvitedUsersApiCall } from 'api/conference/getConferenceInvitedUsersApiCall';
import { getNetworkService } from 'api/getNetworkService';
import { getCurrentConferenceConnectInfoSelector } from 'selectors/getCurrentConferenceConnectInfoSelector';
import { TAppState } from 'types/app/TAppStore';
import { convertInvitedUserFromServerToParticipant } from 'utils/convertInvitedUserFromServerToParticipant';
import { getCurrentConferenceParticipantsThunkAction } from './participants/getCurrentConferenceParticipantsThunkAction';

export const updateConferenceUsersListThunkAction = createAsyncThunk(
    'updateConferenceUsersList',
    async (invited: boolean, { dispatch, getState }) => {
        const state = getState() as TAppState;
        const {
            conferenceSession: { currentConferencePin, currentConferenceId, currentUserIsOwner },
        } = state;
        const conferenceConnectInfo = getCurrentConferenceConnectInfoSelector(state);
        let tPin = '';
        if (!currentUserIsOwner) {
            if (!currentConferencePin) {
                return;
            }
            tPin = currentConferencePin;
        }
        if (conferenceConnectInfo?.status === 'STARTED') {
            dispatch(getCurrentConferenceParticipantsThunkAction());
            return;
        }
        if (invited) {
            const response = await getConferenceInvitedUsersApiCall(
                getNetworkService(),
                tPin,
                currentConferenceId,
            );
            if (!response.success) {
                return;
            }
            dispatch(
                storeCurrentConferenceInvitedUsersAction(
                    convertInvitedUserFromServerToParticipant(response.data),
                ),
            );
        }
    },
);
