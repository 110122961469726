import { t } from '@lingui/macro';
import { IOwnProps } from '../types';

export const getTooltipText = ({
    canPublish,
    deviceList,
    deviceOn,
    devicePermittedByAdmin,
    readyToStream,
    mediaEnabled,
}: Pick<
    IOwnProps,
    | 'canPublish'
    | 'deviceOn'
    | 'devicePermittedByAdmin'
    | 'mediaEnabled'
    | 'deviceList'
    | 'readyToStream'
>) => {
    if (!devicePermittedByAdmin) {
        return t({
            id: 'video.device.disabled.by.admin',
            message: 'Камера не доступна в конференции',
        });
    }
    if (!mediaEnabled) {
        return t({
            id: 'video.device.media.disabled',
            message: 'Камера запрещена в браузере',
        });
    }
    if (deviceList.length === 0) {
        return t({
            id: 'no.available.video.devices',
            message: 'Нет доступных камер',
        });
    }
    if (!readyToStream) {
        return t({
            id: 'device.media.no.connection',
            message: 'Ждите подключения к конференции',
        });
    }
    if (!canPublish) {
        return t({
            id: 'device.media.no.maxPublishers',
            message: 'Нет свободных слотов для включения вашей трансляции',
        });
    }
    return deviceOn
        ? t({
            id: 'video.turnOff',
            message: 'Выключить видео',
        })
        : t({
            id: 'video.turnOn',
            message: 'Включить видео',
        });
};
