import { EPredefinedDatesRange } from 'types/dates/EPredefinedDatesRange';
import { IDatesRange } from 'types/dates/IDatesRange';
import { TLanguages } from 'types/TLanguages';

export interface IStateProps {
    lang?: TLanguages;
    isPast: boolean;
    filter: {
        from?: string;
        to?: string;
        range?: EPredefinedDatesRange;
    };
}

export interface IOwnProps {
    className?: string;
    maxDate?: Date;
    minDate?: Date;
}

export interface IDispatchProps {
    changeFilter: (
        dates: IDatesRange,
        predefinedRange: EPredefinedDatesRange | undefined,
        isPast: boolean,
    ) => void;
}

export interface IFilter {
    dates: IDatesRange;
    predefinedRange?: EPredefinedDatesRange;
}
export const dateTemplate = 'dd.MM.yyyy';
