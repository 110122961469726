import { ConferenceRecordPlayerConnected } from 'components/ConferenceRecordPlayer';
import { useState } from 'react';
import { IConferenceRecord } from 'types/IConferenceRecord';

export const useRenderRecordPlayer: (
    styles: any,
) => [() => JSX.Element | undefined, (record: IConferenceRecord) => (() => void) | undefined] = (
    styles: any,
) => {
    const [recordToShow, setRecordToShow] = useState<IConferenceRecord | undefined>(undefined);
    function renderRecordPlayer() {
        if (!recordToShow) {
            return;
        }
        const offset = window.scrollY;
        return (
            <div className={styles.recordPlayer} style={{ top: offset }}>
                <ConferenceRecordPlayerConnected
                    record={recordToShow}
                    closeHandle={() => {
                        setRecordToShow(undefined);
                    }}
                />
            </div>
        );
    }
    function getRecordClickHandler(record: IConferenceRecord) {
        const recordError = record.record?.success === false;
        const recordReady = record.recordReady;
        if (recordReady && !recordError) {
            return () => setRecordToShow(record);
        }
    }
    return [renderRecordPlayer, getRecordClickHandler];
};
