import { t } from '@lingui/macro';
import { Button } from 'admin-app/components/common/Button';
import { INavItem } from '../types';
import { IOwnProps } from './types';
import styles from '../NewUserModal.module.scss';
import { classNames } from 'utils/classNames';

export const NewUserModalNav: React.FC<IOwnProps> = ({ changeTab, activeTab }) => {
    const menuItems: INavItem[] = [
        // { value: 'create', displayValue: t({ id: 'admin.modalNav.create', message: 'Создать' }) },
        {
            value: 'invite',
            displayValue: t({ id: 'admin.modalNav.invite', message: 'Приглашение' }),
        },
    ];
    return (
        <ul className={styles.modal__nav}>
            {menuItems.map(({ value, displayValue }) => (
                <li key={value}>
                    <Button
                        className={classNames([
                            styles.modal__navButton,
                            activeTab === value ? styles.modal__navButton_active : '',
                        ])}
                        onClick={() => changeTab(value)}>
                        {displayValue}
                    </Button>
                </li>
            ))}
        </ul>
    );
};
