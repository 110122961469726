import { t } from '@lingui/macro';
import { Trans } from '@lingui/react';
import { Button } from 'components/common/Button/Button';
import { Input } from 'components/common/Input/Input';
import { StatusLabel } from 'components/common/StatusLabel/StatusLabel';
import { Form, FormRenderProps } from 'react-final-form';
import { getConferenceLinkParameters } from 'utils/conferences/getConferenceLinkParameters';
import { isConferenceLink } from 'utils/conferences/isConferenceLink';
import { isValidConferenceIdFormat } from 'utils/conferences/isValidConferenceIdFormat';
import { isFormHasValidationErrors } from 'utils/isFormHasValidationErrors';
import styles from './JoinConfForm.module.scss';
import { IDispatchProps, IOwnProps, IStateProps } from './types';
import React, {FC} from 'react';

interface IFormRenderProps {
    inputConfId: string;
}

export const JoinConfForm: FC<IOwnProps & IDispatchProps & IStateProps> = ({
    className,
    getConference,
    prepareToConnectToConference,
    goToConnectByLinkPage,
    isShortText,
}) => {
    const validate = (values: IFormRenderProps) => {
        const errors: { [key: string]: string } = {};
        if (!values.inputConfId) {
            errors.inputConfId = t({ id: 'RequiredField' });
        }
        return errors;
    };

    const onSubmit = async ({ inputConfId }: IFormRenderProps) => {
        if (!inputConfId.trim()) {
            return {
                inputConfId: t({ id: 'RequiredField' }),
            };
        }

        if (isConferenceLink(inputConfId)) {
            const { pin, id } = getConferenceLinkParameters(inputConfId.split('?')[1]);
            if (id && isValidConferenceIdFormat(id)) {
                goToConnectByLinkPage(`/link?id=${id}${pin ? `&pin=${pin}` : ''}`);
            } else {
                return {
                    submit: t({ id: 'conference.checkError.id.or.pin' }),
                };
            }
            return;
        }
        if (!isValidConferenceIdFormat(inputConfId)) {
            return {
                submit: t({ id: 'conference.checkError.id' }),
            };
        }
        const { payload: result } = await getConference(inputConfId);
        if (result.error) {
            return {
                submit: result.error,
            };
        }
        prepareToConnectToConference(inputConfId);
    };

    const formRender = ({
        handleSubmit,
        submitting,
        errors,
        submitErrors,
        submitFailed,
        dirtySinceLastSubmit,
    }: FormRenderProps<IFormRenderProps>) => {
        const isShowValidationError =
            (submitFailed || dirtySinceLastSubmit) && (!!errors?.inputConfId || !!submitErrors);
        const isShowStatusLabel = !dirtySinceLastSubmit && submitErrors;
        const inputErrorText = (isShowStatusLabel && isShortText) ? submitErrors?.submit : ''

        const getNameAndAnnotationBlock = () => (
            <>
                <div className={styles.name}>
                    <Trans
                        id='Connect to conference'
                        message='Присоединиться к конференции'></Trans>
                </div>
                {isShowStatusLabel && !isShortText && (
                    <StatusLabel text={submitErrors?.submit} className={styles.issueText} />
                )}
                <div className={styles.annotation}>
                    {isShortText ? (
                        <Trans
                            id='Join a conference by one click'
                            message='Присоединитесь к конференции в 1 клик'
                        />
                    ) : (
                        <Trans
                            id='Conference id or personal link'
                            message='Идентификатор конференции или персональная ссылка'
                        />
                    )}
                </div>
            </>
        );

        const inputPlaceholder = isShortText ?
            t({
                id: 'Conference id',
                message: 'Идентификатор конференции',
            }) : t({
                id: 'Enter conference id',
                message: 'Введите идентификатор конференции',
            })

        return (
            <form className={className} onSubmit={handleSubmit}>
                { isShortText ? (
                    <div>
                        { getNameAndAnnotationBlock() }
                    </div>
                ) : getNameAndAnnotationBlock() }
                <div className={`${styles.inputId}`}>
                    <Input
                        placeholder={inputPlaceholder}
                        name='inputConfId'
                        touched={false}
                        tabIndex={1}
                        autoFocus={true}
                        errorText={(isShowValidationError && errors?.inputConfId) || inputErrorText}
                        errorWithoutConditions={isShowValidationError}
                    />
                </div>
                <div>
                    <Button
                        styleType='primaryAction'
                        disabled={submitting || isFormHasValidationErrors(errors)}
                        type='submit'
                        tabIndex={2}
                        data-qa='joinConferenceFormButton'>
                        {isShortText ? (
                            <Trans id='Join' message='Присоединиться' />
                        ) : (
                            <Trans id='Join a conference' message='Присоединиться к конференции' />
                        )}
                    </Button>
                </div>
            </form>
        );
    };

    return <Form onSubmit={onSubmit} validate={validate} render={formRender} />;
};
