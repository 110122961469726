import { EDisplayVideoWithDifferentRatio } from 'constants/EDisplayVideoWithDifferentRatio';
import { IParticipantFrames } from './types';

const default16_9VideoWidth = 640;
const default16_9VideoHeight = 360;
const default4_3VideoWidth = 640;
const default4_4VideoHeight = 480;

type IFrameSize = { width: number; height: number; orientation: 'horizontal' | 'vertical' };
export const getOverallFrameSize = (
    frames: IParticipantFrames,
    ratioInSettings: EDisplayVideoWithDifferentRatio,
): IFrameSize => {
    if (!frames) {
        return {
            orientation: 'horizontal',
            width: default16_9VideoWidth,
            height: default16_9VideoHeight,
        };
    }
    const participantsList = Object.keys(frames);
    const getParticipantSize = (index: number): IFrameSize => {
        const frame = frames[participantsList[index]];
        const width = frame?.width ? frame.width : default16_9VideoWidth;
        const height = frame?.height ? frame.height : default16_9VideoHeight;
        return {
            width,
            height,
            orientation: width > height ? 'horizontal' : 'vertical',
        };
    };
    if (participantsList.length === 1) {
        return getParticipantSize(0);
    }
    const result = getParticipantSize(0);
    let horizontalVideo = result.orientation === 'horizontal';
    let verticalVideo = result.orientation === 'vertical';
    for (let index = 1; index < participantsList.length; index++) {
        const { width, height, orientation } = getParticipantSize(index);
        horizontalVideo = horizontalVideo || orientation === 'horizontal';
        verticalVideo = verticalVideo || orientation === 'vertical';
        if (horizontalVideo && verticalVideo) {
            switch (ratioInSettings) {
                case EDisplayVideoWithDifferentRatio.DEFAULT_16_9:
                    return {
                        width: default16_9VideoWidth,
                        height: default16_9VideoHeight,
                        orientation: 'horizontal',
                    };
                case EDisplayVideoWithDifferentRatio.DEFAULT_4_3:
                    return {
                        width: default4_3VideoWidth,
                        height: default4_4VideoHeight,
                        orientation: 'horizontal',
                    };
                case EDisplayVideoWithDifferentRatio.MAX_RATIO: {
                    break;
                }
                case EDisplayVideoWithDifferentRatio.MIN_RATIO: {
                    break;
                }
            }
        }
        if (result.width !== width) {
            switch (ratioInSettings) {
                case EDisplayVideoWithDifferentRatio.MAX_RATIO:
                    if (result.width / result.height < width / height) {
                        result.width = width;
                        result.height = height;
                        result.orientation = width > height ? 'horizontal' : 'vertical';
                    }
                    break;
                case EDisplayVideoWithDifferentRatio.MIN_RATIO:
                    if (result.width / result.height > width / height) {
                        result.width = width;
                        result.height = height;
                        result.orientation = width > height ? 'horizontal' : 'vertical';
                    }
                    break;
            }
        }
    }
    return result;
};
