import PeopleImg from 'assets/People.svg';
import VL1Img from 'assets/VerticalLine1.svg';
import VL2Img from 'assets/VerticalLine2.svg';
import { useEffect, useState } from 'react';
import { getEscCloseDropdown } from 'utils/escCloseDropdown';
import { redirect } from 'utils/redirect';
import { HomePageHeaderConnected } from './HomePageHeader';
import { JoinConfDialog } from './JoinConfDialog';
import styles from './PageTemplate.module.scss';
import { IOwnProps } from './types';

export const PageTemplate: React.FC<IOwnProps> = ({
    children,
    showBcgGradient = true,
    showBcgImg = true,
    showLoggedMenu = true,
    showLoginBtn = true,
    thisIsHomePage = false,
    showRegisterBtn = true,
    className,
}) => {
    const [dialogOn, setDialogOn] = useState(false);

    const onEscClick = (e: KeyboardEvent) => {
        if (e.code === 'Escape') {
            setDialogOn((prevState) => {
                if (!getEscCloseDropdown() && !prevState) {
                    setTimeout(() => redirect('/'), 0);
                }
                return false;
            });
        }
    };

    useEffect(() => {
        document.addEventListener('keydown', onEscClick);
        return () => {
            document.removeEventListener('keydown', onEscClick);
        };
    }, []);

    const pageStyle = dialogOn ? `${styles.page} ${styles.height100vh}` : styles.page;

    const homePageLinesRender = (
        <>
            <img className={styles.vlTop} src={VL1Img} alt='' />
            <img className={styles.vlBottom} src={VL2Img} alt='' />
        </>
    );

    const homePageBcgImgRender = (
        <div className={styles.peopleImg}>
            <img src={PeopleImg} alt='img' />
        </div>
    );
    const customClassName = className ? className : '';
    const showBgGradient = showBcgGradient ? '' : styles.bcgNone;
    return (
        <div className={`${pageStyle} ${showBgGradient} ${customClassName}`}>
            <div className={styles.wrapper}>
                <HomePageHeaderConnected
                    showLoggedMenu={showLoggedMenu}
                    showLoginBtn={showLoginBtn}
                    showRegisterBtn={showRegisterBtn}
                    handleJoinConference={() => {
                        setDialogOn(true);
                    }}
                />
                {children}
            </div>
            {thisIsHomePage && homePageLinesRender}

            {showBcgImg && homePageBcgImgRender}

            {dialogOn && (
                <JoinConfDialog
                    handleClose={() => {
                        setDialogOn(false);
                    }}
                />
            )}
        </div>
    );
};
