import { t } from '@lingui/macro';
import { Button } from 'components/common/Button';
import styles from './UpcomingConferenceMainButton.module.scss';
import { IOwnProps } from './types';

export const UpcomingConferenceMainButton: React.FC<IOwnProps> = ({
    conferenceId,
    isOwner,
    isConferenceScheduled,
    isConferenceStarted,
    goToConnectConference,
}) => {
    let text = '';
    if (isOwner && isConferenceScheduled) {
        text = t({ id: 'Start', message: 'Начать' });
    } else if (isConferenceStarted || isConferenceScheduled) {
        text = t({ id: 'Join', message: 'Присоединиться' });
    }

    if (isConferenceScheduled || isConferenceStarted) {
        return (
            <div className={styles.conferenceMainButtonWrapper}>
                <Button
                    styleType={isConferenceStarted ? 'primaryActionNew' : 'secondaryActionNew'}
                    caption={text}
                    clickHandler={() => goToConnectConference(conferenceId)}
                    className={styles.conferenceMainButton}
                />
            </div>
        );
    }

    return null;
};
