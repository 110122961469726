import { DynamicFormMobileConnected } from 'components/forms/DynamicForm/DynamicFormMobileConnected';
import { IStateMobileProps } from './types';

export const AccountSettingsPageMobile: React.FC<IStateMobileProps> = ({ settings, structure }) => (
    <DynamicFormMobileConnected
        isDefaultForm={true}
        structure={structure}
        initialValues={settings}
    />
);
