import { t } from '@lingui/macro';
import { appHistory } from 'appHistory';
import { showNotifyThunkAction } from 'thunk/notify/showNotifyThunkAction';
import { janusCtx } from 'utils/janus/janusCtx';
import { log } from 'utils/log';
import { janusLogger } from 'utils/logger';
import { clearJanusCtx } from './janusCtx';
import { janusInitializationErrorEvent } from './janusCustomEvents';
import { janusInstanceCreateSuccessHandler } from './janusInstanceCreateSuccessHandler';

const errorHandler = (error: any) => {
    log.error(`Janus error: ${error}`);
    document.dispatchEvent(janusInitializationErrorEvent);
    janusCtx.dispatch(
        showNotifyThunkAction({
            timeout: 5000,
            type: 'error',
            text: t({
                id: 'Janus initialization failed',
                message:
                    'Ошибка подсистемы Janus. Будет осуществлён возврат на домашнюю страничку. Подробнее: {0}',
                values: { 0: error },
            }),
            closeAction: () => {
                if (window.location.pathname !== '/') {
                    appHistory.push('/');
                }
            },
        }),
    );
};

export const janusInitializationCallback = () => {
    console.log('Sucess');
    if (!Janus.isWebrtcSupported()) {
        log.error('No WebRTC support... ');
        return;
    }
    janusCtx.browser = (Janus as any).webRTCAdapter.browserDetails.browser;
    janusCtx.safariVp8 = (Janus as any).safariVp8;

    // Create session
    janusCtx.janusInstance = new Janus({
        server: janusCtx.server,
        success: janusInstanceCreateSuccessHandler,
        error: errorHandler,
        token: janusCtx.token,
        destroyed: function () {
            janusLogger.debug(
                `Janus successful destroyed session: ${janusCtx.janusInstance.getSessionId()}`,
            );
            if (janusCtx.janusInstance) {
                clearJanusCtx();
            }
        },
        destroyOnUnload: false, // Destroyed ourselves in destroyJanusSession();
    });
};
