export let MAX_FEEDS = 20;

export const changeMaxFeeds = (feeds: number) => {
    MAX_FEEDS = feeds;
    return 'Maximum feeds: ' + feeds;
};

(window as any).changeMaxFeeds = changeMaxFeeds;

export const INSIDE_ATCHAT = !!(window as any).meevox;
