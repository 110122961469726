import { t } from '@lingui/macro';
import { Trans } from '@lingui/react';
import AccessDeniedImg from 'assets/AccessDenied.svg';
import { Button } from 'components/common/Button/Button';
import styles from './AccessDeniedDialog.module.scss';
import { IDispatchProps, IOwnProps } from './types';

export const AccessDeniedDialog: React.FC<IDispatchProps & IOwnProps> = ({
    retry,
    device = 'desktop',
}) => (
    <div className={`${styles.block} ${device === 'mobile' ? styles.block_mobile : ''}`}>
        <img src={AccessDeniedImg} alt='Denied' />
        <div className={`${styles.text} ${device === 'mobile' ? styles.text_mobile : ''}`}>
            <Trans
                message='Владелец отказал в доступе к конференции.'
                id='Access to conference was denied by owner.'
            />
        </div>
        <Button
            styleType='primaryAction'
            caption={t({ id: 'Request again', message: 'Запросить заново' })}
            className={`${styles.button} ${device === 'mobile' ? styles.button_mobile : ''}`}
            type='submit'
            clickHandler={retry}
        />
    </div>
);
