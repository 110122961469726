import { createAsyncThunk } from '@reduxjs/toolkit';
import { toggleConferenceChatAction } from 'actions/applicationView/toggleConferenceChatAction';
import { batch } from 'react-redux';
import { executePendingOperationThunkAction } from 'thunk/appView/executePendingOperationThunkAction';
import { redirect } from 'utils/redirect';
import { loadConferenceReportThunkAction } from './loadConferenceReportThunkAction';

interface IPayload {
    conferenceId: string;
    sessionId: string;
}

export const prepareConferenceReportThunkAction = createAsyncThunk(
    'prepareConferenceReport',
    async (data: IPayload, { dispatch }) => {
        batch(() => {
            dispatch(toggleConferenceChatAction(true));
            dispatch(
                executePendingOperationThunkAction({
                    operation: 'defaultOperation',
                    pendingFunction: async () => {
                        const { payload: report } = await dispatch(
                            loadConferenceReportThunkAction(data),
                        );
                        if (!report) {
                            redirect('/');
                        }
                    },
                }),
            );
        });
    },
);
