import styles from './MobilePopup.module.scss';
import ArrowBackImg from 'assets/ArrowBack.svg';
import { Button } from '../Button';
import { IOwnProps } from './types';
//TODO maybe this component is not needed
export const MobilePopup: React.FC<IOwnProps> = ({ headerData, onClick, children }) => {
    const clickHandler = onClick || (() => {});
    return (
        <div className={styles.container}>
            <div className={styles.header}>
                <Button styleType='transparent' clickHandler={clickHandler}>
                    <img src={ArrowBackImg} />
                </Button>
                {headerData && <div className={styles.header__title}>{headerData}</div>}
            </div>
            <div className={styles.body}>{children}</div>
        </div>
    );
};
