import { t } from '@lingui/macro';
import { createAsyncThunk } from '@reduxjs/toolkit';
import { getNetworkService } from 'api/getNetworkService';
import { confirmEmailChangeApiCall } from 'api/profile/confirmEmailChangeApiCall';
import { EErrorCodes } from 'constants/EErrorCodes';

interface IReturned {
    field: string;
    text: string;
}

export const confirmEmailChangeThunkAction = createAsyncThunk<
    Promise<IReturned | undefined>,
    string
>('confirmEmailChange', async (token: string) => {
    const response = await confirmEmailChangeApiCall(getNetworkService(), token);
    if (!response.success) {
        const errorCode = response.data.errorCode;
        if (errorCode === EErrorCodes.INVALID_PASSWORD) {
            return {
                text: t({ id: 'wrong.password', message: 'Неверный пароль' }),
                field: 'password',
            };
        } else if (errorCode === EErrorCodes.EMAIL_ALREADY_IN_USE) {
            return {
                text: t({ id: 'email.already.use', message: 'Данная почта уже используется' }),
                field: 'newEmail',
            };
        }
        return {
            text: t({
                id: 'notification.error',
                message: 'Произошла ошибка. Обратитесь в поддержку',
            }),
            field: '',
        };
    }
});
