import { useRef, useState } from 'react';

type IReturn = [
    successText: string,
    setSuccessText: (showText: string) => void,
    cleanTimer: () => void,
];

export const useSuccessNotification = (timer?: number): IReturn => {
    const [successText, setSuccessText] = useState('');
    const timerRef = useRef<NodeJS.Timeout | null>(null);

    const setCleanTimer = () => {
        timerRef.current = setTimeout(() => {
            setSuccessText('');
            timerRef.current = null;
        }, timer || 3000);
    };

    const cleanTimerBeforeExecution = () => {
        if (timerRef.current) {
            clearTimeout(timerRef.current);
            timerRef.current = null;
        }
    };

    const setText = (showText: string) => {
        setSuccessText(showText);
        cleanTimerBeforeExecution()
        setCleanTimer();
    };
    return [successText, setText, cleanTimerBeforeExecution];
};
