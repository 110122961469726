import { INetworkService } from 'types/api/INetworkService';

interface IPayload {
    email: string;
}

export const requestPasswordRecoveryApiCall = (
    networkService: INetworkService,
    data: IPayload,
) =>
    networkService.post({
        url: '/api/account/password/recovery',
        data,
    });
