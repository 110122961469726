import { Select } from 'components/common/Select';
import React from 'react';
import { handleSelectDevice } from 'utils/conferences/handleSelectDevice';
import styles from './DeviceSelect.module.scss';
import { IOwnProps } from './types';

export const DeviceSelect: React.FC<IOwnProps> = ({ devicesList, onChangeDevice, id, device }) => {
    const changeItemHandler = (currentValue: string) => {
        const newActiveDevice = devicesList.find((device) => {
            return device.label === currentValue;
        });
        if (!newActiveDevice || currentValue === device?.label) {
            return;
        }
        handleSelectDevice(newActiveDevice, true, onChangeDevice);
    };

    const getItemsForSelect = () => {
        if (devicesList.length === 0) {
            return devicesList;
        }
        return devicesList.map((device) => {
            return device.label;
        });
    };

    const getCurrentValue = () => {
        if (!device) {
            return '';
        }
        return device.label;
    };

    return (
        <div>
            <Select
                selectedValue={getCurrentValue()}
                items={getItemsForSelect()}
                theme='dark'
                tooltip={id ? { id } : undefined}
                className={styles.deviceSelectWrapper}
                changeHandler={changeItemHandler}
            />
        </div>
    );
};
