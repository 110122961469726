import { createAsyncThunk } from '@reduxjs/toolkit';
import { setRememberMeRequestStatusAction } from 'actions/auth/setRememberMeRequestStatusAction';
import { resetStoreAction } from 'actions/resetStoreAction';
import { getNetworkService } from 'api/getNetworkService';
import { getCurrentUserProfileDataApiCall } from 'api/profile/getCurrentUserProfileDataApiCall';
import { EPaths } from 'constants/EPaths';
import { batch } from 'react-redux';
import { getApplicationConfigThunkAction } from 'thunk/applicationConfiguration/getApplicationConfigThunkAction';
import { TAppState } from 'types/app/TAppStore';
import { convertUserDataFromServer } from 'utils/users/convertUserDataFromServer';
import { setCurrentUserDataAction } from './../../actions/auth/setCurrentUserDataAction';
import { redirect } from './../../utils/redirect';

export const checkCurrentProfileThunkAction = createAsyncThunk(
    'checkCurrentProfile',
    async (_: void, { dispatch, getState }) => {
        const {
            auth: { currentUser, anonymous, rememberMeRequestStatus },
        } = getState() as TAppState;
        if (rememberMeRequestStatus === 'requested' || rememberMeRequestStatus === 'initial') {
            return;
        }
        const response = await getCurrentUserProfileDataApiCall(getNetworkService());
        if (!response.success) {
            if (location.pathname.indexOf(EPaths.ERROR) === -1) {
                batch(() => {
                    dispatch(resetStoreAction());
                });
                dispatch(setRememberMeRequestStatusAction('initial'));
                dispatch(getApplicationConfigThunkAction());
                redirect(EPaths.ERROR);
            }
            return;
        }
        if (!response.data?.anonymous) {
            const userData = convertUserDataFromServer(response.data);
            if (currentUser === null) {
                batch(() => {
                    dispatch(resetStoreAction());
                    dispatch(setCurrentUserDataAction(userData));
                    dispatch(setRememberMeRequestStatusAction('success'));
                    dispatch(getApplicationConfigThunkAction());
                });
            } else {
                if (userData.id !== currentUser?.id) {
                    batch(() => {
                        dispatch(resetStoreAction());
                        dispatch(setRememberMeRequestStatusAction('error'));
                        dispatch(getApplicationConfigThunkAction());
                    });
                }
            }
            return;
        }
        if (anonymous) {
            // nothing to do
            return;
        }
        // clear store
        batch(() => {
            dispatch(resetStoreAction());
            dispatch(setRememberMeRequestStatusAction('error'));
            dispatch(getApplicationConfigThunkAction());
        });
    },
);
