import styles from './ConferenceChatBtn.module.scss';
import { IDispatchProps, IOwnProps, IStateProps } from './types';
import { ConferenceButton } from 'components/ConferenceButton/ConferenceButton';
import { useEffect, useState } from 'react';
import { t } from '@lingui/macro';
import { isMessageCounted } from 'pages/templates/ConferencePageTemplate/ConferenceChat/utils';
import ChatImg from 'assets/Chat.svg';
import { classNames } from 'utils/classNames';

export const ConferenceChatBtn: React.FC<IOwnProps & IStateProps & IDispatchProps> = ({
    chatMessages,
    chatState,
    toggleChat,
    isMessagesFromHistory,
    isParticipantsListOpen,
    closeParticipantsList,
    isHideText,
    isMobileCounter,
}) => {
    const [prevChatMessages, setPrevChatMessages] = useState(chatMessages);
    const [newMessagesCounter, setNewMessagesCounter] = useState<number>(0);

    const onCounterClick = (e: React.MouseEvent) => {
        const target = e.currentTarget;
        const foo = target.previousSibling;
        if (foo) {
            (foo as HTMLElement).click();
        }
    };
    const getNewMessagesCounter = (): number => {
        if (chatState || isMessagesFromHistory) {
            return 0;
        }
        if (isMessageCounted(chatMessages)) {
            return newMessagesCounter;
        }
        return newMessagesCounter + (chatMessages.length - prevChatMessages.length);
    };

    const handleClick = () => {
        if (isParticipantsListOpen) {
            closeParticipantsList();
        }
        toggleChat(!chatState);
    };

    useEffect(() => {
        chatState ? setNewMessagesCounter(0) : false;
    }, [chatState]);

    useEffect(() => {
        setNewMessagesCounter(getNewMessagesCounter);
        setPrevChatMessages(chatMessages);
    }, [chatMessages]);

    return (
        <div className={styles.wrapper}>
            <ConferenceButton
                img={ChatImg}
                text={!isHideText ? t({ message: 'Чат', id: 'Chat' }) : undefined}
                clickHandler={handleClick}
            />
            {newMessagesCounter > 0 && (
                <div
                    onClick={onCounterClick}
                    className={classNames([
                        styles.messagesCount,
                        isMobileCounter ? styles.messagesCount_mobile : '',
                    ])}>
                    {newMessagesCounter}
                </div>
            )}
        </div>
    );
};
