import { t } from '@lingui/macro';
import { EAudioVideoComboValues } from 'constants/appSettingsStructure';

export const getComboValues = () => [
    {
        value: EAudioVideoComboValues.ON,
        displayValue: t({ id: 'TurnOn', message: 'Вкл.' }),
    },
    {
        value: EAudioVideoComboValues.OFF,
        displayValue: t({ id: 'TurnOff', message: 'Выкл.' }),
    },
    {
        value: EAudioVideoComboValues.DISABLED,
        displayValue: t({ id: 'Disabled', message: 'Запрещено' }),
    },
];
