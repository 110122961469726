import { createAsyncThunk } from '@reduxjs/toolkit';
import { updateAppSettingsApiCall } from 'api/authorization/updateAppSettingsApiCall';
import { getNetworkService } from 'api/getNetworkService';
import { showNotifyThunkAction } from 'thunk/notify/showNotifyThunkAction';
import { TAppState } from 'types/app/TAppStore';
import { getDefaultErrorNotification } from 'utils/getDefaultErrorNotification';
import { getAppSettingsThunkAction } from './getAppSettingsThunkAction';

interface IPayload {
    [id: string]: string | boolean;
}

export const updateAppSettingsThunkAction = createAsyncThunk<boolean, IPayload>(
    'updateAppSettings',
    async (settings, { dispatch, getState }) => {
        const appSettings = (getState() as TAppState).auth.appSettings;
        if (appSettings) {
            settings = { ...appSettings, ...settings };
        }
        const response = await updateAppSettingsApiCall(getNetworkService(), settings);
        if (response.success) {
            dispatch(getAppSettingsThunkAction(settings));
        } else {
            dispatch(showNotifyThunkAction(getDefaultErrorNotification()));
        }
        return response.success;
    },
);
