import { t } from '@lingui/macro';
import { Input } from 'components/common/Input';
import { MobileFormBlock } from 'components/common/MobileFormBlock';
import stylesConference from 'components/ConferenceScheduleSettingsForm/ConferenceScheduleSettingsFormMobile.module.scss';
import styles from './Header.module.scss';
import { IOwnProps } from './types';
import { parseDate } from './utils';

export const HeaderMobile: React.FC<IOwnProps> = ({ startDate, endDate, errors }) => (
    <MobileFormBlock
        annotation={t({ id: 'Date', message: 'Дата' })}
        className={`${stylesConference.margin_top_24} ${stylesConference.multi_row} ${styles.block_mobile}`}>
        <div className={`${stylesConference.like_input} ${styles.input_mobile}`}>
            <div>{t({ id: 'repeatRange', message: 'Начиная с' })}</div>
            <Input
                styleType='mobile'
                highlightTextOnError={errors?.startDate}
                placeholder={t({
                    id: 'recurring.conference.dialog.start',
                    message: 'ДД.ММ.ГГ',
                })}
                name='startDate'
                type='text'
                autocomplete='off'
                parse={(value: string) => parseDate(value, startDate)}
            />
        </div>
        <div
            className={`${stylesConference.like_input} ${styles.input_mobile}`}
            style={{ marginTop: '0px', borderTop: 'none' }}>
            <div>{t({ id: 'Before', message: 'До' })}</div>
            <Input
                styleType='mobile'
                highlightTextOnError={errors?.endDate}
                placeholder={t({
                    id: 'recurring.conference.dialog.start',
                    message: 'ДД.ММ.ГГ',
                })}
                name='endDate'
                type='text'
                autocomplete='off'
                disabled={startDate ? false : true}
                parse={(value: string) => parseDate(value, endDate)}
            />
        </div>
        <div className={`${stylesConference.like_input} ${styles.input_mobile}`}>
            <div>{t({ id: 'Time', message: 'Время' })}</div>
            <Input
                styleType='mobile'
                highlightTextOnError={errors?.time}
                placeholder={t({ id: 'hh:mm', message: 'чч:мм' })}
                name='time'
                type='time'
                autocomplete='off'
                className={styles.dateField}
            />
        </div>
    </MobileFormBlock>
);
