import { t } from '@lingui/macro';
import { ReactComponent as MicrophoneSvg } from 'assets/Microphone.svg';
import styles from '../DeviceField.module.scss';
import { DeviceFieldConnected } from '../DeviceFieldConnected';

export const AudioDevice: React.FC<{ disabled: boolean }> = ({ disabled }) => {
    const deviceStatusText = {
        isOn: t({ id: 'microphone.on' }),
        isOff: t({ id: 'microphone.off' }),
    };
    return (
        <DeviceFieldConnected
            name='useAudio'
            deviceStatusText={deviceStatusText}
            isDeviceDisabled={disabled}
            Icon={MicrophoneSvg}
            imgWrapperOffStyles={styles.deviceCheckbox__imgWrapper_audioOff}
        />
    );
};
