import { IConferenceConnectInfo } from 'types/conferences/IConferenceConnectInfo';
import { IConferenceUser } from 'types/IConferenceUser';
import { IConferenceParticipant } from 'types/IConferenceParticipant';

export interface IOwnProps {
    conferenceInfo: IConferenceConnectInfo,
    invitedUsers: IConferenceUser[],
    participants: IConferenceParticipant[]
    currentUserIsCreator: boolean,
    isPastConference: boolean,
}

export enum ETabsValue {
    ALL_PARTICIPANTS = 'ALL_PARTICIPANTS',
    WERE_AT_CONFERENCE = 'WERE_AT_CONFERENCE',
    IS_AT_CONFERENCE = 'IS_AT_CONFERENCE'
}

export interface ITabsItem {
    value: ETabsValue;
    label: (num: number) => string
}
