import { createAsyncThunk } from '@reduxjs/toolkit';
import { sendUserInviteApiCall } from 'admin-app/api/sendUserInviteApiCall';
import { EUserThunkActions } from 'admin-app/constants/thunk/EUserThunkActions';
import { getNetworkService } from 'api/getNetworkService';

interface IPayload {
    email: string;
}

interface IReturn {
    errorCode: string | undefined;
    errorDescription: string | undefined;
}

export const sendUserInviteThunkAction = createAsyncThunk<IReturn | undefined, IPayload>(
    EUserThunkActions.SEND_USER_INVITE,
    async (data: IPayload) => {
        const { success, errorCode, errorDescription } = await sendUserInviteApiCall(
            getNetworkService(),
            data,
        );
        if (!success) {
            return { errorCode, errorDescription };
        }
    },
);
