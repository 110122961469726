import { closeRedirectConfirmationAction } from 'actions/applicationView/closeRedirectConfirmationAction';
import { redirectThunkAction } from 'thunk/appView/redirectThunkAction';
import { TAppDispatch } from 'types/app/TAppDispatch';
import { TAppState } from 'types/app/TAppStore';
import { IDispatchProps, IStateProps } from './types';

export const mapStateToProps = (state: TAppState): IStateProps => ({
    awaitUserConfirmation: state.applicationView.pendingConfirmation.awaitUserConfirmation,
});
export const mapDispatchToProps = (dispatch: TAppDispatch): IDispatchProps => ({
    redirect: () => dispatch(redirectThunkAction()),
    close: () => dispatch(closeRedirectConfirmationAction(false)),
});
