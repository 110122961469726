import { createAsyncThunk } from '@reduxjs/toolkit';
import { updateConferenceInfoApiCall } from 'api/conference/updateConferenceInfoApiCall';
import { getNetworkService } from 'api/getNetworkService';
import { IThunkResult } from 'types/IThunkResult';
import { resetConferenceInvitedUsersAction } from 'actions/conferences/resetConferenceInvitedUsersAction';

export interface IUpdateConferenceInvitedUsers {
    invitesData: { added: string[]; removed: string[] };
    conferenceId: string;
}

export const updateConferenceInvitedUsersThunkAction = createAsyncThunk<
    IThunkResult,
    IUpdateConferenceInvitedUsers
>('updateConferenceInvitedUsers', async ({ invitesData, conferenceId }, {dispatch}) => {
    const response = await updateConferenceInfoApiCall(getNetworkService(), {
        id: conferenceId,
        invitedUsers: invitesData as any,
    });
    if (!response.success) {
        return {
            isSuccess: false,
            errorCode: response?.data?.errorCode,
            errorDescription: response?.data?.errorDescription,
        };
    }
    dispatch(resetConferenceInvitedUsersAction({id: conferenceId}))
    return { isSuccess: true };
});
