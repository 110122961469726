import { t } from '@lingui/macro';
import { FORM_ERROR } from 'final-form';
import { IRequestError } from 'types/api/IRequestError';
import { isEmailValid } from 'utils/isEmailValid';
import { IFormRenderProps } from './types';

export const validate = (values: IFormRenderProps) => {
    const errors: { [key: string]: string } = {};
    const requiredError = t({ id: 'RequiredField' });
    if (!values.email) {
        errors.email = requiredError;
    } else if (!isEmailValid(values.email)) {
        errors.email = t({
            id: 'Your email address must be entered in the format name@domain.com',
            message: 'Ваш адрес электронной почты должен быть введен в формате name@domain.com',
        });
    }
    if (!values.password) {
        errors.password = requiredError;
    }
    if (!values.passwordRepeat) {
        errors.passwordRepeat = requiredError;
    }
    if (values.password !== values.passwordRepeat && values.password && values.passwordRepeat) {
        const errorPwText = t({
            id: 'Password mismatch',
            message: 'Пароли не совпадают',
        });
        errors.password = errorPwText;
        errors.passwordRepeat = errorPwText;
        errors.passwordsEqual = errorPwText;
    }
    return errors;
};

export const processSubmitError = (error: IRequestError, showNotificationError: () => void) => {
    if (error.errorCode === 'EMAIL_ALREADY_IN_USE') {
        return {
            [FORM_ERROR]: t({
                id: 'This email address is already in use',
                message: 'Такой адрес электронной почты уже используется',
            }),
        };
    } else {
        showNotificationError();
    }
};

export const getSubmitErrorText = (submitError: any, dirtySinceLastSubmit: boolean) =>
    submitError && !dirtySinceLastSubmit ? submitError : '';
