import { appHistory } from 'appHistory';
import { I18nWrapperConnected } from 'components/I18nWrapper';
import { LoginCheckerConnected } from 'components/LoginChecker';
import { NotificationPanelConnected } from 'components/NotificationPanel';
import { EPaths } from 'constants/EPaths';
import { ConferenceFinishedPageResponsive } from 'pages/ConferenceFinishedPage';
import { ConferenceReportPage } from 'pages/ConferenceReportPage';
import { ConfigErrorPageConnected } from 'pages/ConfigErrorPage/ConfigErrorPageConnected';
import { ConfirmAccountRegistrationPageConnected } from 'pages/ConfirmAccountRegistrationPage';
import { ConfirmEmailChangePageConnected } from 'pages/ConfirmEmailChangePage';
import { ConnectToConferencePageResponsive } from 'pages/ConnectToConferencePage';
import { HomePageResponsive } from 'pages/HomePage';
import { InputUsernamePageResponsive } from 'pages/InputUsernamePage';
import { JoinConferenceByLinkPageConnected } from 'pages/JoinConferenceByLinkPage';
import { LobbyPageResponsive } from 'pages/LobbyPage';
import { LoginForJoinPageResponsive } from 'pages/LoginForJoinPage';
import { LoginPageResponsive } from 'pages/LoginPage';
import { PersonalInformationPageResponsive } from 'pages/PersonalInformationPage';
import { PinEnterPageResponsive } from 'pages/PinEnterPage';
import { RegistrationPageResponsive } from 'pages/RegistrationPage';
import { RestorePasswordPage } from 'pages/RestorePasswordPage';
import { SetNewPasswordPage } from 'pages/SetNewPasswordPage';
import React from 'react';
import { Provider } from 'react-redux';
import { Route, Router, Switch } from 'react-router-dom';
import { applicationStore } from 'store/applicationStore';
import './App.css';
import { AccountConferencePageResponsive } from 'pages/AccountPage';
import { ConferencePageResponsive } from 'pages/ConferencePage';
import { UserConfirmationDialogConnected } from 'components/UserConfirmationDialog';
import { NoMatchPageConnected } from 'pages/NoMatchPage/NoMatchPageConnected';
import { AdminPageConnected } from 'admin-app/pages/Admin';
import { InviteRegistrationPage } from 'pages/InviteRegistrationPage';
import { SimpleConnectToConferencePageConnected } from 'pages/SimpleConnectToConferencePage';
import { SimpleConferencePageConnected } from 'pages/SimpleConferencePage';
import { AppUpdatedDialog } from 'pages/templates/PageTemplate/AppUpdatedDialog/AppUpdatedDialog';

const getRoutes = () => (
    <Switch>
        <Route exact path='/' component={HomePageResponsive} />
        <Route
            exact
            path='/conference/:id'
            render={({
                match: {
                    params: { id },
                },
            }) => <ConferencePageResponsive id={id} />}
        />
        <Route exact path='/simpleConference/:id' component={SimpleConferencePageConnected} />
        <Route exact path='/error' component={ConfigErrorPageConnected} />
        <Route exact path='/login' component={LoginPageResponsive} />
        <Route exact path='/register' component={RegistrationPageResponsive} />
        <Route exact path='/invite' component={InviteRegistrationPage} />
        <Route exact path='/restore' component={RestorePasswordPage} />
        <Route exact path='/change/password' component={SetNewPasswordPage} />
        <Route exact path='/change/email' component={ConfirmEmailChangePageConnected} />
        <Route exact path='/confirm' component={ConfirmAccountRegistrationPageConnected} />
        <Route
            exact
            path={`${EPaths.CONNECT_TO_CONFERENCE}/:id`}
            render={({
                match: {
                    params: { id },
                },
            }) => <ConnectToConferencePageResponsive id={id} />}
        />
        <Route
            exact
            path={`${EPaths.SIMPLE_CONNECT_TO_CONFERENCE}/:id`}
            component={SimpleConnectToConferencePageConnected}
        />
        <Route exact path='/loginForJoin' component={LoginForJoinPageResponsive} />
        <Route exact path='/updateProfile' component={PersonalInformationPageResponsive} />
        <Route path='/account' component={AccountConferencePageResponsive} />
        <Route path='/admin' component={AdminPageConnected} />
        <Route
            exact
            path='/enter/:id'
            render={({
                match: {
                    params: { id },
                },
            }) => <PinEnterPageResponsive id={id} />}
        />
        <Route exact path='/lobby/:id' component={LobbyPageResponsive} />
        <Route
            exact
            path='/inputUsername/:id'
            render={({
                match: {
                    params: { id },
                },
            }) => <InputUsernamePageResponsive id={id} />}
        />
        <Route
            exact
            path='/conference/:id/finished'
            render={({
                match: {
                    params: { id },
                },
            }) => <ConferenceFinishedPageResponsive id={id} />}
        />
        <Route path='/link' component={JoinConferenceByLinkPageConnected} />
        <Route
            exact
            path='/conference/:id/:session/report'
            render={({
                match: {
                    params: { id, session },
                },
            }) => <ConferenceReportPage conferenceId={id} sessionId={session} />}
        />
        <Route path='*' component={NoMatchPageConnected} />
    </Switch>
);

const App: React.FC = () => (
    <div className='App'>
        <Router history={appHistory}>
            <Provider store={applicationStore}>
                <LoginCheckerConnected>
                    <I18nWrapperConnected>
                        <NotificationPanelConnected />
                        <UserConfirmationDialogConnected />
                        <AppUpdatedDialog />
                        {getRoutes()}
                    </I18nWrapperConnected>
                </LoginCheckerConnected>
            </Provider>
        </Router>
    </div>
);

export default App;
