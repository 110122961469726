import { INetworkService } from 'types/api/INetworkService';

interface IPayload {
    conferenceId: string;
}
export const leaveConferenceApiCall = (
    networkService: INetworkService,
    { conferenceId }: IPayload,
) =>
    networkService.post({
        url: `/api/conferences/${conferenceId}/leave`,
        data: {
            id: conferenceId,
        },
    });
