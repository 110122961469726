import { IConferenceReport } from 'types/conferenceReport/IConferenceReport';
import { IConferenceConnectInfo } from 'types/conferences/IConferenceConnectInfo';
import { getUserInitials } from './users/getUserInitials';
import { getAvatarUrl } from './users/getAvatarUrl';

export const fillReportFromConferenceShortInfo = (
    conference: IConferenceConnectInfo,
    report: IConferenceReport,
) => {
    const creator = conference.creator;
    report.creator = {
        avatar: getAvatarUrl({
            id: creator.userId,
            size: '50',
        }),
        email: creator.email,
        fullName: `${creator.firstName} ${creator.lastName}`,
        initials: getUserInitials(creator),
    };
    report.name = conference.name;
    report.description = conference.description;
};
