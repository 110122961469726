interface IReturnValue {
    pin: string | undefined;
    id: string | undefined;
    recorderId: string | undefined;
    simpleConference: string | undefined;
}

export const getConferenceLinkParameters = (searchParams: string): IReturnValue => {
    const params = new URLSearchParams(searchParams);
    const pin = params.get('pin') ?? undefined;
    const id = params.get('id') ?? undefined;
    const recorderId = params.get('recorderId') ?? undefined;
    const simpleConference = params.get('simpleConference') ?? undefined;
    return {
        pin,
        id,
        recorderId,
        simpleConference,
    };
};
