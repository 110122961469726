import { INetworkService } from 'types/api/INetworkService';

export const getConferenceInvitedUsersApiCall = (
    networkService: INetworkService,
    pin: string,
    conferenceId: string,
) => {
    const formattedPin = pin ? `?pin=${pin}` : '';
    return networkService.get({
        url: `/api/conferences/${conferenceId}/invited-users${formattedPin}`,
    });
};
