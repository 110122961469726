import { connect } from 'react-redux';
import { setNewPasswordThunkAction } from 'thunk/auth/setNewPasswordThunkAction';
import { TAppDispatch } from 'types/app/TAppDispatch';
import { SetNewPasswordForm } from './SetNewPasswordForm';
import { IDispatchProps } from './types';

const mapStateToProps = () => ({});

const mapDispatchToProps = (dispatch: TAppDispatch): IDispatchProps => ({
    setNewPassword: (password, token) => dispatch(setNewPasswordThunkAction({ password, token })),
});

export const SetNewPasswordFormConnected = connect(
    mapStateToProps,
    mapDispatchToProps,
)(SetNewPasswordForm);
