import { Trans } from '@lingui/react';
import { Arrow } from 'components/common/Arrow/Arrow';
import { CircleLoader } from 'components/common/CircleLoader';
import { ConferenceScheduleSettingsFormResponsive } from 'components/ConferenceScheduleSettingsForm';
import { LinkWithPendingActionCheckConnected } from 'components/common/LinkWithPendingActionCheck';
import { EPaths } from 'constants/EPaths';
import { useEffect } from 'react';
import { clearPrevUsers } from 'thunk/schedulingConference/getInvitedUsersDataThunkAction';
import styles from './ConferenceSchedulingPage.module.scss';
import { IDispatchProps, IOwnProps, IStateProps } from './types';

export const ConferenceSchedulingPage: React.FC<IOwnProps & IStateProps & IDispatchProps> = ({
    id,
    conferencesInfo,
    defaultOperationPending,
    loadData,
}) => {
    const conferenceInfo = id ? conferencesInfo[id] : undefined;
    useEffect(() => {
        if (id) loadData(id);
        return () => {
            clearPrevUsers();
        };
    }, []);
    return (
        <div className={styles.schedule}>
            <div className={styles.header}>
                <LinkWithPendingActionCheckConnected to={EPaths.UPCOMING_CONFERENCES}>
                    <Trans message='Конференции' id='account.conferences' />
                </LinkWithPendingActionCheckConnected>
                <Arrow
                    className={styles.arrow}
                    orientation={'right'}
                    size={'medium'}
                    color={'gray'}
                />
                <span>
                    <Trans message='Запланировать конференцию' id='Schedule a conference' />
                </span>
            </div>
            {defaultOperationPending && id && <CircleLoader color='blue' />}
            <ConferenceScheduleSettingsFormResponsive conferenceInfo={conferenceInfo} />
        </div>
    );
};
