import { logoutThunkAction } from 'thunk/auth/logoutThunkAction';
import { TAppDispatch } from 'types/app/TAppDispatch';
import { TAppState } from 'types/app/TAppStore';
import { IUserData } from 'types/user/IUserData';
import { IDispatchProps, IStateProps } from './types';

export const mapStateToProps = (state: TAppState): IStateProps => ({
    currentUser: state.auth.currentUser as IUserData,
});
export const mapDispatchToProps = (dispatch: TAppDispatch): IDispatchProps => ({
    logout: () => dispatch(logoutThunkAction()),    
});