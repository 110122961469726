import { createAction } from '@reduxjs/toolkit';
import { EApplicationViewActions } from 'constants/actions/EApplicationViewActions';

interface IPayload {
    participantId: string;
    talking: boolean;
}

export const modifyParticipantTalkingAction = createAction<IPayload>(
    EApplicationViewActions.MODIFY_PARTICIPANT_TALKING,
);
