import { ProgressBar } from 'components/common/ProgressBar';
import { IOwnProps } from './types';
import styles from './RecordDownloadBar.module.scss';
import { t } from '@lingui/macro';

export const RecordDownloadBar: React.FC<IOwnProps> = ({ item }) => {
    let downloadedPercent = 0;
    const mbLingui = t({ id: 'mb', message: 'МБ' });
    downloadedPercent = item.downloaded / item.total * 100;

    return (
        <div className={styles.wrapper}>
            <ProgressBar curValue={downloadedPercent} maxValue={100} />
            <div className={styles.text}>{item.downloaded}{mbLingui} / {item.total}{mbLingui}</div>
        </div>
    );
};