const onCancelTabClose = (e: BeforeUnloadEvent) => {
    e.preventDefault();
    e.returnValue = '';
};
export function addCancelTabCloseEvent() {
    window.addEventListener('beforeunload', onCancelTabClose);
}
export function removeCancelTabCloseEvent() {
    window.removeEventListener('beforeunload', onCancelTabClose);
}
