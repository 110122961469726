import { t } from '@lingui/macro';
import format from 'date-fns/format';
import { enUS, ru } from 'date-fns/locale';
import { IConferenceParticipant } from 'types/IConferenceParticipant';
import { TLanguages } from 'types/TLanguages';
import { addUsersTimeZoneToDate } from 'utils/dates/addUsersTimeZoneToDate';
import { convertStringToDateByTemplate } from 'utils/dates/convertStringToDateByTemplate';

interface IParsedData {
    [key: string]: string;
}

export const parseNodeDataAttr = (node: HTMLElement) => {
    const contentInfo = node.dataset.contentInfo;
    if (!contentInfo) {
        return null;
    }
    try {
        const jsonData = JSON.parse(contentInfo) as unknown;
        if (typeof jsonData === 'object' && jsonData) {
            const { authorId, time, id, fileName } = jsonData as IParsedData;
            if (authorId && time && id) {
                return { authorId, time, id, fileName };
            }
        }
    } catch (e) {
        return null;
    }
    return null;
};

export const findParticipant = (id: string, participantLst: IConferenceParticipant[]) =>
    participantLst.find(
        (participant) => participant.userId === id || participant.participantId === id,
    );

export const getConvertedDateString = (date: string, locale?: TLanguages) => {
    try {
        const parsedDate = addUsersTimeZoneToDate(
            convertStringToDateByTemplate(date, 'yyyy-MM-dd HH:mm:ss.SSSSSS'),
        );
        const resultDate = format(parsedDate, 'dd MMM, yyyy', {
            locale: locale === 'ru' ? ru : enUS,
        });
        const resultTime = format(parsedDate, 'HH:mm', { locale: locale === 'ru' ? ru : enUS });
        const preposition = t({ id: 'image.popup.preposition', message: 'в' });
        return `${resultDate} ${preposition} ${resultTime}`;
    } catch (e) {
        return '';
    }
};
