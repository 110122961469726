import { t } from '@lingui/macro';
import { useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import { getDefaultErrorNotification } from 'utils/getDefaultErrorNotification';
import { IDispatchProps, IStateProps } from './types';

const getLiguiSuccessText = () =>
    t({ id: 'account.confirm.success', message: 'Электронная почта успешно подтверждена' });

export const ConfirmAccountRegistrationPage: React.FC<IStateProps & IDispatchProps> = ({
    sendRequestToConfirmAccount,
    goToLogin,
    loggedIn,
    showNotification,
    goToHome,
}) => {
    const location = useLocation();
    const params = new URLSearchParams(location.search);
    const token = params.get('token') ?? undefined;

    useEffect(() => {
        const sendRequest = async () => {
            if (loggedIn) {
                goToHome();
            } else if (token) {
                const { payload: success } = await sendRequestToConfirmAccount(token);
                if (success) {
                    showNotification({
                        text: getLiguiSuccessText(),
                        timeout: 3000,
                        type: 'success',
                    });
                    goToLogin();
                } else {
                    showNotification(getDefaultErrorNotification());
                    goToHome();
                }
            } else {
                showNotification(getDefaultErrorNotification());
                goToHome();
            }
        };
        sendRequest();
    }, []);

    return null;
};
