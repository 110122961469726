import { applicationStore } from 'store/applicationStore';
import { TAppState } from 'types/app/TAppStore';
import { getBrowserTimeZone } from './getBrowserTimeZone';

let mockUserTimeZone = '';
export function setMockUserTimeZone(val: string) {
    mockUserTimeZone = val;
}
export const getUserTimeZone =
    process.env.NODE_ENV === 'test'
        ? () => mockUserTimeZone || getUserTimeZoneWithStoreAndBrowser()
        : getUserTimeZoneWithStoreAndBrowser;

function getUserTimeZoneWithStoreAndBrowser() {
    const {
        auth: { currentUser },
    } = applicationStore.getState() as TAppState;
    return currentUser?.timeZone || getBrowserTimeZone();
}
