import { t, Trans } from '@lingui/macro';
import { format } from 'date-fns';
import { ReactElement } from 'react';
import { IUserData } from 'types/user/IUserData';
import { useConfirmDialog } from './useConfirmDialog';
import { getSeriesIdFromConferenceInfo } from 'utils/conferences/getSeriesIdFromConferenceInfo';
import { getStartDateFromConferenceInfo } from 'utils/conferences/getStartDateFromConferenceInfo';
import { IUpcomingConferenceInfo } from 'types/conferencesList/IUpcomingConferenceInfo';

export const useConferenceEditDialog = (
    currentUser: IUserData | null,
    goToEditConference: (id: string) => void,
): [(conference: IUpcomingConferenceInfo) => void, () => ReactElement | null] => {
    const [setDialogOptions, confirmDialogRender] = useConfirmDialog();
    return [
        (conference: IUpcomingConferenceInfo) => {
            const startDate = getStartDateFromConferenceInfo(
                conference.timeStart,
                currentUser?.timeZone,
            );
            const seriesId = getSeriesIdFromConferenceInfo(conference);
            if (!seriesId) {
                goToEditConference(conference.id);
                return;
            }
            const date = format(startDate, 'dd.MM.yyyy');
            setDialogOptions({
                handlePrimaryAction: () => goToEditConference(conference.id),
                handleSecondaryAction: () => goToEditConference(seriesId),
                bodyText: (
                    <Trans>
                        Редактировать все предстоящие конференции <b>{conference.name}</b> или
                        только запланированную на {date}?
                    </Trans>
                ),
                headerText: t({
                    id: 'dialog.editConference',
                    message: 'Редактировать конференцию',
                }),
                primaryText: t({
                    id: 'ConfirmDialog.action.only.specific',
                    message: 'Только {0}',
                    values: {
                        '0': date,
                    },
                }),
                secondaryText: t({ id: 'All' }),
            });
        },
        confirmDialogRender,
    ];
};
