import styles from './Spinner.module.scss';
import { IOwnProps } from './types';

export const Spinner: React.FC<IOwnProps> = ({ wrapperClass = '' }) => {
    return (
        <div className={`${styles.spinner} ${styles[wrapperClass] || ''}`}>
            <div></div>
        </div>
    );
};
