import { Trans } from '@lingui/react';
import CameraImg from 'assets/Camera.svg';
import MicrophoneImg from 'assets/Microphone.svg';
import { Toggle } from 'components/common/Toggle';
import { IWithDeviceProps } from 'components/HOCs/ComponentWithDevices/types';
import useIsMounted from 'hooks/useMounted';
import React, { useEffect, useRef, useState } from 'react';
import { IPreferredDevices } from 'types/IPreferredDevices';
import { IUserDevice } from 'types/IUserDevice';
import { IJanusLocalStreamProps } from 'types/janus/IJanusLocalStreamProps';
import { getPreferredDevicesByDevice } from 'utils/getPreferredDevicesByDevice';
import { checkDevice } from '../utils';
import { DeviceSelect } from './DeviceSelect';
import { IDispatchProps, IOwnProps, IStateProps } from './types';
import styles from './VideoCallSettings.module.scss';
import { t } from '@lingui/macro';

export const VideoCallSettings: React.FC<
    IOwnProps & IStateProps & IDispatchProps & IWithDeviceProps
> = ({
    changeStreamProps,
    getDeviceListByType: getDeviceList,
    handleDeviceLoadFail,
    handleDeviceLoadSuccessful,
    handleMount,
    handlePropsChange,
    handleReloadDevices,
    handleUnmount,
    loadPreviousCurrentConferenceDeviceValues,
    localStreamSettings: {
        useAudio,
        useVideo,
        audioDevice,
        audioPermitted,
        videoDevice,
        videoPermitted,
    },
    offAllDevices,
    reloadDevices,
    forbiddenDevices,
    isOwner,
    ownAudioOnConnect,
    ownVideoOnConnect,
}) => {
    const videoOutput = useRef<any>();

    const [devicesList, setDevicesList] = useState<IUserDevice[]>([]);
    const [devicesListIsLoading, setDevicesListIsLoading] = useState(true);
    const [disabledToggle, setDisabledToggle] = useState<boolean>(false);
    const timerId = useRef<NodeJS.Timeout>();
    const isMounted = useIsMounted();
    const wrapperHandlePropsChange: typeof handlePropsChange = async (...props) => {
        if (timerId.current) {
            clearTimeout(timerId.current)
            timerId.current = undefined;
        }
        timerId.current = setTimeout(() => {
            if (disabledToggle) {
                setDisabledToggle(false);
            }
            timerId.current = undefined;
        }, 5000)

        setDisabledToggle(true);
        const response = await handlePropsChange(...props);
        setDisabledToggle(false);
        return response
    }
    const mount = async () => {
        const useVideoDefaultSetting = isOwner
            ? ownVideoOnConnect
            : forbiddenDevices.video
                ? false
                : ownVideoOnConnect;
        const useAudioDefaultSetting = isOwner
            ? ownAudioOnConnect
            : forbiddenDevices.audio
                ? false
                : ownVideoOnConnect;
        await loadPreviousCurrentConferenceDeviceValues({
            useVideo: useVideoDefaultSetting,
            useAudio: useAudioDefaultSetting,
        });
        const success = await handleMount(
            (devices: IUserDevice[]) => {
                if (isMounted()) {
                    setDevicesList(devices);
                }
            },
            (state: boolean) => {
                if (isMounted()) {
                    setDevicesListIsLoading(state);
                }
            },
        );
        if (!success) {
            handleDeviceLoadFail();
        } else {
            handleDeviceLoadSuccessful();
        }
    };

    useEffect(() => {
        const localCurrentVideoOutput = videoOutput.current;
        mount();
        return () => {
            handleUnmount(localCurrentVideoOutput, videoOutput);
        };
    }, []);

    useEffect(() => {
        // If the user has only one type of device, it is necessary to update the device list again
        // when toggle is changed
        if (devicesList.length === 0 && !devicesListIsLoading && (useAudio || useVideo)) {
            mount();
        }
    }, [devicesList, useAudio, useVideo]);

    useEffect(() => {
        if (!devicesListIsLoading) {
            const props: Partial<IJanusLocalStreamProps> = {};
            if (audioDevicesList.length === 0 && useAudio) {
                props.useAudio = false;
            }
            if (videoDevicesList.length === 0 && useVideo) {
                props.useVideo = false;
            }
            if (Object.keys(props).length) changeStreamProps(props);
        }
        if (devicesListIsLoading || devicesList.length === 0) {
            handleDeviceLoadSuccessful();
            return;
        }

        const deviceChange = async () => {
            const preferredDevices: IPreferredDevices = {};
            if (audioDevice) {
                preferredDevices.audio = { id: audioDevice.deviceId };
            }
            if (videoDevice) {
                preferredDevices.video = { id: videoDevice.deviceId };
            }
            const result = await wrapperHandlePropsChange(
                { audio: useAudio, video: useVideo },
                videoOutput,
                preferredDevices,
            );
            if (!result) {
                handleDeviceLoadFail();
            } else {
                handleDeviceLoadSuccessful();
            }
        };
        deviceChange();
    }, [devicesListIsLoading, useAudio, useVideo]);

    useEffect(() => {
        if (offAllDevices) {
            changeStreamProps({ useAudio: false, useVideo: false });
        }
    }, [offAllDevices]);

    useEffect(() => {
        if (reloadDevices) {
            mount();
            handleReloadDevices();
        }
    }, [reloadDevices]);

    useEffect(() => {
        const checkDevices = () => {
            const newStreamProps: {
                audioDevice?: IUserDevice;
                videoDevice?: IUserDevice;
            } = {};
            checkDevice(
                'audioinput',
                audioDevice,
                getDeviceList('audioinput', devicesList),
                newStreamProps,
            );
            checkDevice(
                'videoinput',
                videoDevice,
                getDeviceList('videoinput', devicesList),
                newStreamProps,
            );
            if (Object.keys(newStreamProps).length) {
                changeStreamProps(newStreamProps);
            }
        };
        checkDevices();
    }, [devicesList, audioDevice, videoDevice]);

    const handleChangeItem = async (type: string, device: IUserDevice) => {
        if (device.kind === 'audioinput') {
            changeStreamProps({ audioDevice: device });
        } else if (device.kind === 'videoinput') {
            changeStreamProps({ videoDevice: device });
        }
        const result = await wrapperHandlePropsChange(
            { audio: useAudio, video: useVideo },
            videoOutput,
            getPreferredDevicesByDevice(device),
        );
        if (!result) {
            handleDeviceLoadFail();
        } else {
            handleDeviceLoadSuccessful();
        }
    };

    const handleVideoEnableChange = (useVideo: boolean) => {
        if (!isOwner) {
            if (forbiddenDevices.video) {
                changeStreamProps({ useVideo: false });
                return;
            }
        }
        if (videoDevicesList.length) {
            changeStreamProps({ useVideo });
        }
    };

    const handleAudioEnableChange = (useAudio: boolean) => {
        if (!isOwner) {
            if (forbiddenDevices.audio) {
                changeStreamProps({ useAudio: false });
                return;
            }
        }
        if (audioDevicesList.length) {
            changeStreamProps({ useAudio });
        }
    };

    const audioDevicesList = getDeviceList('audioinput', devicesList);
    useEffect(() => {
        if (!audioDevice && audioDevicesList.length && audioPermitted && useAudio) {
            changeStreamProps({ useAudio: true, audioDevice: audioDevicesList[0] });
        }
    }, [audioDevice, devicesList]);

    const videoDevicesList = getDeviceList('videoinput', devicesList);
    useEffect(() => {
        if (!videoDevice && videoDevicesList.length && videoPermitted && useVideo) {
            changeStreamProps({ videoDevice: videoDevicesList[0] });
        }
    }, [videoDevice, devicesList]);
    const disabledVideoToggle = !isOwner && forbiddenDevices.video;
    const disabledAudioToggle = !isOwner && forbiddenDevices.audio;

    return (
        <div className={styles.settings}>
            <div className={styles.itemSettings}>
                <div className={styles.itemSettings__text}>
                    <img src={CameraImg} alt='CameraPic' />
                    <span>
                        <Trans message='Камера' id='Camera' />
                    </span>
                </div>
                <Toggle
                    value={useVideo && videoPermitted}
                    disabled={disabledVideoToggle || disabledToggle}
                    onChangeValue={handleVideoEnableChange}
                    tooltip={disabledVideoToggle ? t({
                        id: 'video.device.disabled.by.admin',
                        message: 'Камера не доступна в конференции',
                    }) : undefined}
                />
            </div>
            <div className={styles.videoSettings}>
                <video ref={videoOutput} id='video' className={styles.screen} />
                <div className={styles.cameraSelect}>
                    <DeviceSelect
                        device={videoDevice}
                        id='video-devices-box'
                        devicesList={videoDevicesList}
                        onChangeDevice={handleChangeItem}
                    />
                </div>
            </div>
            <div className={styles.micSettings}>
                <div className={styles.itemSettings}>
                    <div className={styles.itemSettings__text}>
                        <img src={MicrophoneImg} alt='MicPic' />
                        <span>
                            <Trans message='Микрофон' id='Microphone' />
                        </span>
                    </div>
                    <Toggle
                        value={useAudio && audioPermitted}
                        disabled={disabledAudioToggle || disabledToggle}
                        onChangeValue={handleAudioEnableChange}
                        tooltip={disabledAudioToggle ? t({
                            id: 'audio.device.disabled.by.admin',
                            message: 'Микрофон не доступен в конференции',
                        }) : undefined}
                    />
                </div>
                <DeviceSelect
                    device={audioDevice}
                    id='audio-devices-box'
                    devicesList={audioDevicesList}
                    onChangeDevice={handleChangeItem}
                />
            </div>
        </div>
    );
};
