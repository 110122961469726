import OutsideClick from 'components/common/OutsideClick';
import { useState } from 'react';
import { IOwnProps } from './types';
import styles from './Select.module.scss';
import { classNames } from 'utils/classNames';
import { SelectActiveValue } from './SelectActiveValue/SelectActiveValue';
import { SelectOption } from './SelectOption';

export const Select: React.FC<IOwnProps> = ({
    currentValue,
    options,
    containerClassName,
    activeOptionImg,
    onChange,
}) => {
    const [isOpen, setIsOpen] = useState(false);

    const closeDropDown = () => setIsOpen(false);
    const onHeaderClick = () => setIsOpen((prev) => !prev);
    return (
        <OutsideClick onOutsideClick={closeDropDown}>
            <div className={classNames([styles.select, containerClassName])}>
                <SelectActiveValue
                    currentValue={currentValue}
                    options={options}
                    isOpen={isOpen}
                    className={isOpen ? styles.select__value_active : ''}
                    onClick={onHeaderClick}
                />
                {isOpen && (
                    <ul className={styles.select__options} data-select-options>
                        {options.map((optionData) => {
                            const onOptionClick = () => {
                                onChange(optionData.value);
                                closeDropDown();
                            };
                            return (
                                <SelectOption
                                    key={optionData.value}
                                    {...optionData}
                                    activeOptionImg={
                                        activeOptionImg && optionData.value === currentValue
                                            ? activeOptionImg
                                            : undefined
                                    }
                                    onClick={onOptionClick}
                                />
                            );
                        })}
                    </ul>
                )}
            </div>
        </OutsideClick>
    );
};
