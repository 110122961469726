import { getEmotionsAssetsTypeList } from 'utils/emotionsAssets';

export const getEmotionsList = () => {
    const emotionsAssetsTypeList = getEmotionsAssetsTypeList();
    if (emotionsAssetsTypeList) {
        return emotionsAssetsTypeList.keys().map((item) => {
            return {
                name: item.replace('./', '').replace('List.svg', ''),
                file: emotionsAssetsTypeList(item),
            };
        });
    }
    return [{ name: '', file: '' }];
};
