import { Trans } from '@lingui/react';
import { ReactComponent as LoginSvg } from 'assets/Login.svg';
import LogoImg from 'assets/LogoBlack.svg';
import { Button } from 'components/common/Button';
import { Cross } from 'components/common/Cross/Cross';
import { LinkWithPendingActionCheckConnected } from 'components/common/LinkWithPendingActionCheck';
import styles from './HomePageHeader.module.scss';
import { LanguageMenuConnected } from './LanguageMenu/LanguageMenuConnected';
import { LoggedInMenuConnected } from './LoggedInMenu';
import { IDispatchProps, IOwnProps, IStateProps } from './types';

export const HomePageHeader: React.FC<IStateProps & IDispatchProps & IOwnProps> = ({
    accountPageLocation,
    canUserRegister,
    goToAccount,
    goToHome,
    goToLogin,
    goToRegister,
    handleJoinConference,
    showLoggedMenu,
    showLoginBtn = true,
    showRegisterBtn = true,
    rememberMeRequestStatus,
}) => {
    const isLoggedIn = rememberMeRequestStatus === 'success';
    const handleLogBtnClick = () => {
        if (isLoggedIn) {
            goToAccount();
        } else {
            goToLogin();
        }
    };
    const loginMenuRender = (
        <div className={styles.buttonGroup}>
            <LanguageMenuConnected />
            {showLoginBtn && (
                <Button
                    className={styles.button_134}
                    styleType='login'
                    clickHandler={handleLogBtnClick}>
                    <div className={styles.exitImgContainer}>
                        <LoginSvg />
                    </div>
                    <span>
                        <Trans id='Enter' message='Войти' />
                    </span>
                </Button>
            )}
            {!canUserRegister && showRegisterBtn && (
                <Button
                    styleType='primaryAction'
                    className={styles.button_152}
                    clickHandler={goToRegister}>
                    <Trans id='Registration' message='Регистрация' />
                </Button>
            )}
        </div>
    );
    const exitCross = (
        <div className={styles.exitBtn}>
            <Cross onClick={goToHome} />
        </div>
    );

    const renderMenu = () => {
        if (rememberMeRequestStatus === 'initial' || rememberMeRequestStatus === 'requested') {
            return <div></div>;
        }

        return isLoggedIn ? (
            showLoggedMenu ? (
                <LoggedInMenuConnected
                    clickHandler={handleLogBtnClick}
                    handleJoinConference={handleJoinConference}
                />
            ) : (
                exitCross
            )
        ) : (
            loginMenuRender
        );
    };
    return (
        <div className={`${styles.header} ${accountPageLocation ? styles.withBorder : ''}`}>
            <div className={styles.logoWrapper}>
                <LinkWithPendingActionCheckConnected onClick={goToHome}>
                    <img className={styles.logo} src={LogoImg} alt='logo' />
                </LinkWithPendingActionCheckConnected>
            </div>
            {renderMenu()}
        </div>
    );
};
