import { t } from '@lingui/macro';
import { ESocketAppActions } from 'constants/ESocketAppActions';
import { TConferenceEvents } from 'types/socket/conferenceEvents/TConferenceEvents';

interface IGetConferenceNotificationTextsPayload {
    actionType: TConferenceEvents;
    conferenceName: string;
    isMobile?: boolean;
}

export const getConferenceNotificationTexts = ({
    actionType,
    conferenceName,
    isMobile,
}: IGetConferenceNotificationTextsPayload) => {
    const inviteText = t({
        id: 'socket.conferenceMobileNotification.scheduled',
        message: 'Приглашение на конференцию',
    });
    switch (actionType) {
        case ESocketAppActions.CONFERENCE_SCHEDULED:
            if (isMobile) {
                return {
                    title: conferenceName,
                    text: inviteText,
                };
            }
            return null;

        case ESocketAppActions.CONFERENCE_UPDATED: //If we weren't invited before
            if (isMobile) {
                return {
                    title: conferenceName,
                    text: inviteText,
                };
            }
            return null;

        case ESocketAppActions.CONFERENCE_STARTED:
            if (isMobile) {
                return {
                    title: conferenceName,
                    text: t({
                        id: 'socket.conferenceMobileNotification.started',
                        message: 'Конференция началась',
                    }),
                };
            }

            return {
                text: t({
                    id: 'socket.conference.started',
                    message: 'Конференция {0} уже началась',
                    values: { '0': conferenceName },
                }),
            };

        case ESocketAppActions.CONFERENCE_FINISHED:
            if (isMobile) {
                return {
                    title: conferenceName,
                    text: t({
                        id: 'socket.conferenceMobileNotification.finished',
                        message: 'Конференция завершена',
                    }),
                };
            }
            return {
                text: t({
                    id: 'socket.conference.finished',
                    message: 'Конференция {0} завершена',
                    values: { '0': conferenceName },
                }),
            };
        default:
            return null;
    }
};
