import { connect } from 'react-redux';
import { TAppState } from 'types/app/TAppStore';
import { ParticipantListItem } from './ParticipantListItem';
import { IStateProps } from './types';

const mapStateToProps = (state: TAppState): IStateProps => ({
    currentConferenceParticipants: state.conferenceSession.currentConferenceParticipants,
    currentUser: state.auth.currentUser,
    currentUserAnonym: state.auth.anonymousUser,
    currentUserParticipantId: state.janus.ownParticipantId,
});

export const ParticipantListItemConnected = connect(mapStateToProps)(ParticipantListItem);
