import { IOwnProps } from './types';
import styles from '../Select.module.scss';
import { classNames } from 'utils/classNames';

export const SelectOption: React.FC<IOwnProps> = ({
    displayValue,
    img,
    activeOptionImg: ActiveOptionImg,
    className,
    onClick,
}) => (
    <li
        className={classNames([styles.select__option, className])}
        onClick={onClick}
        data-select-item>
        {img && <img src={img} />}
        <span>{displayValue}</span>
        {ActiveOptionImg && <div className={styles.select__optionActiveImg}>{ActiveOptionImg}</div>}
    </li>
);
