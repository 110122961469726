import React, { useEffect } from 'react';
import { IDispatchProps } from './types';

export const SimpleConnectToConferencePage: React.FC<IDispatchProps> = ({
    prepareRecorderDataAndConnectToConference,
}) => {
    useEffect(() => {
        prepareRecorderDataAndConnectToConference();
    }, []);

    return <div style={{ height: '100%', width: '100%', backgroundColor: '#000000' }}></div>;
};
