import { InputUsernameFormConnected } from 'components/forms/InputUsernameForm';
import React, { useEffect } from 'react';
import { ConferencePageTemplateConnected } from '../templates/ConferencePageTemplate';
import { IDispatchProps, IOwnProps, IStateProps } from './types';

export const InputUsernamePage: React.FC<IOwnProps & IStateProps & IDispatchProps> = ({
    conferenceConnectInfo,
    id,
    loadData,
}) => {
    useEffect(() => {
        loadData(id);
    });
    return (
        <ConferencePageTemplateConnected
            conferenceConnectInfo={conferenceConnectInfo}
            showLogo={true}>
            {conferenceConnectInfo && (
                <InputUsernameFormConnected />
            )}
        </ConferencePageTemplateConnected>
    );
};
