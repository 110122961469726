import { addMinutes } from 'date-fns';
import { timeZonesByValue } from 'utils/timeZones/getTimeZonesArray';

export const getDateBasedOnUTC0 = (date: Date, timeZone: string) => {
    const timeZoneData = timeZonesByValue[timeZone];
    if (!timeZoneData) {
        return date;
    }
    return addMinutes(date, -timeZoneData.shiftInMinutes);
};
