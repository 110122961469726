import { INetworkService } from 'types/api/INetworkService';
import { TLanguages } from 'types/TLanguages';

interface IPayload {
    firstName?: string;
    lastName?: string;
    phone?: string;
    timeZone?: string;
    locale?: TLanguages;
}

export const updateProfileInfoApiCall = (
    networkService: INetworkService,
    { firstName, lastName, phone, timeZone, locale }: IPayload,
) =>
    networkService.put({
        url: '/api/account',
        data: { firstName, lastName, mobilePhone: phone, timeZone, locale },
    });
