import { t } from '@lingui/macro';
import { createAsyncThunk } from '@reduxjs/toolkit';
import { setDisabledStartConfButtonAction } from 'actions/applicationView/setDisabledStartConfButtonAction';
import { storeConferenceInfoAction } from 'actions/conferences/storeConferenceInfoAction';
import { createConferenceApiCall } from 'api/conference/createConferenceApiCall';
import { getNetworkService } from 'api/getNetworkService';
import { appHistory } from 'appHistory';
import { EAudioVideoComboValues } from 'constants/appSettingsStructure';
import { EConferenceThunkActions } from 'constants/thunk/EConferenceThunkActions';
import { batch } from 'react-redux';
import { applicationStore } from 'store/applicationStore';
import { setCurrentConferenceDataThunkAction } from 'thunk/conferenceSession/setCurrentConferenceDataThunkAction';
import { showNotifyThunkAction } from 'thunk/notify/showNotifyThunkAction';
import { IAppSettingsTyped } from 'types/app/IAppSettings';
import { TAppState } from 'types/app/TAppStore';
import { IConferenceInfo } from 'types/conferences/IConferenceInfo';
import { getDefaultErrorNotification } from 'utils/getDefaultErrorNotification';
import { getRandomPin } from 'utils/getRandomPin';
import { getDisplayName } from 'utils/users/getDisplayName';
import { joinToCurrentConferenceAndStartJanusThunkAction } from './joinToCurrentConferenceAndStartJanusThunkAction';

const getInitialParticipantsAudioVideoSettings = (
    audioParticipants: string,
    videoParticipants: string,
) => {
    const resultObj = {
        forbidVideo: false,
        forbidAudio: false,
        videoParticipants: true,
        muteUponEntry: false,
    };
    switch (videoParticipants) {
        case EAudioVideoComboValues.DISABLED:
            resultObj.forbidVideo = true;
            resultObj.videoParticipants = false;
            break;
        case EAudioVideoComboValues.OFF:
            resultObj.videoParticipants = false;
            break;
    }

    switch (audioParticipants) {
        case EAudioVideoComboValues.DISABLED:
            resultObj.forbidAudio = true;
            resultObj.muteUponEntry = true;
            break;
        case EAudioVideoComboValues.OFF:
            resultObj.muteUponEntry = true;
            break;
    }
    return resultObj;
};

export const createNewConferenceThunkAction = createAsyncThunk(
    EConferenceThunkActions.CREATE_NEW_CONFERENCE,
    async (_: void, { dispatch, getState }) => {
        const {
            auth: { currentUser, appSettings, anonymous },
        } = getState() as TAppState;
        if (anonymous) {
            appHistory.push('/login?action=createConference');
            return;
        } else if (currentUser && appSettings) {
            dispatch(setDisabledStartConfButtonAction(true));
            const typedAppSettings = appSettings as IAppSettingsTyped;
            const conferenceName = getDisplayName(currentUser);
            const { audioParticipants, ownVideoOnConnect, recordConference, videoParticipants } =
                typedAppSettings;
            const pin = getRandomPin();
            const linguiConference = t({ id: 'conference', message: 'Конференция' });
            const participantsAudioVideoSettings = getInitialParticipantsAudioVideoSettings(
                audioParticipants,
                videoParticipants,
            );
            const response = await createConferenceApiCall(getNetworkService(), {
                name: `${conferenceName}. ${linguiConference}`,
                type: 'ALL',
                participants: { added: [currentUser.id] },
                pin,
                videoCreator: ownVideoOnConnect,
                isRecording: recordConference,
                ...participantsAudioVideoSettings,
            });
            if (response.success) {
                const newConferenceInfo = response.data as IConferenceInfo;
                const unsubscribe = applicationStore.subscribe(async () => {
                    // We get this info from socket event displayConferenceNotification
                    const {
                        conferences: { conferencesConnectInfo },
                    } = getState() as TAppState;
                    const newConferenceConnectInfo = conferencesConnectInfo[newConferenceInfo.id];
                    if (newConferenceConnectInfo) {
                        unsubscribe();
                        batch(() => {
                            dispatch(storeConferenceInfoAction([newConferenceInfo]));
                            dispatch(
                                setCurrentConferenceDataThunkAction({
                                    conferenceId: newConferenceInfo.id,
                                    pin: newConferenceInfo.pin,
                                    creator: newConferenceConnectInfo?.creator,
                                }),
                            );
                        });
                        await dispatch(joinToCurrentConferenceAndStartJanusThunkAction(true));
                    }
                });
                // const newConferenceConnectInfo = await dispatch(
                //     getConferenceConnectInfoThunkAction({
                //         id: newConferenceInfo.id,
                //         forceUpdate: false,
                //     }),
                // ).unwrap();
                // batch(() => {
                //     dispatch(storeConferenceInfoAction([newConferenceInfo]));
                //     dispatch(
                //         setCurrentConferenceDataThunkAction({
                //             conferenceId: newConferenceInfo.id,
                //             pin: newConferenceInfo.pin,
                //             creator: newConferenceConnectInfo?.creator,
                //         }),
                //     );
                // });
                // await dispatch(joinToCurrentConferenceAndStartJanusThunkAction(true));
            } else {
                dispatch(setDisabledStartConfButtonAction(false));
                dispatch(showNotifyThunkAction(getDefaultErrorNotification()));
            }
        }
    },
);
