import { getEmotionsAnimations } from 'utils/emotionsAssets';

export const getEmotionAnimation = (name: string) => {
    const emotions = getEmotionsAnimations();
    let file;
    if (emotions) {
        const item = emotions.keys().find((item) => item === `./${name}.json`);
        if (item) {
            file = emotions(item);
        }
        if (file) return file;
    }
    return file;
};
