import { getPendingOperationStatusSelector } from 'selectors/getPendingOperationStatusSelector';
import { loadDataForConferenceSettingsPageThunkAction } from 'thunk/conference/loadDataForConferenceSettingsPageThunkAction';
import { TAppDispatch } from 'types/app/TAppDispatch';
import { TAppState } from 'types/app/TAppStore';
import { IDispatchProps, IStateProps } from './types';

export const mapStateToProps = (state: TAppState): IStateProps => ({
    conferencesInfo: state.conferences.conferencesInfo,
    defaultOperationPending: getPendingOperationStatusSelector(state, 'defaultOperation', true),
});
export const mapDispatchToProps = (dispatch: TAppDispatch): IDispatchProps => ({
    loadData: (id: string) => dispatch(loadDataForConferenceSettingsPageThunkAction(id)),
});
