import { t } from '@lingui/macro';
import { Input } from 'components/common/Input';
import styles from './ConferenceAudioVideoSettings.module.scss';

export const ConferenceAudioVideoSettings: React.FC<{ name: string }> = ({ name }) => (
    <div className={styles.container}>
        <div className={styles.text}>{t({ message: 'Участник', id: 'Participants' })}</div>
        <Input name={name} disabled={true} className={styles.input} />
    </div>
);
