import { t } from '@lingui/macro';
import Info from 'assets/Info.svg';
import DeleteImg from 'assets/Delete.svg';
import PenGrayImg from 'assets/PenGray.svg';
import ShareImg from 'assets/Share.svg';
import { Button } from 'components/common/Button';
import OutsideClick from 'components/common/OutsideClick';
import { useEffect, useRef, useState } from 'react';
import styles from './ConferenceContextMenu.module.scss';
import { IOwnProps } from './types';

export const ConferenceContextMenu: React.FC<IOwnProps> = ({
    isConferenceScheduled,
    isConferenceStarted,
    isOwner,
    closeMenu,
    shareConferenceHandler,
    editConferenceHandler,
    deleteConferenceHandler,
    conferenceDetailsHandler,
}) => {
    const [isOutsideWindow, setIsOutsideWindow] = useState(false);
    const containerRef = useRef<HTMLDivElement>(null);

    const menuItems = [
        {
            title: t({ id: 'conference details', message: 'Информация о конференции' }),
            img: Info,
            conditionToShow: true,
            handler: () => {
                conferenceDetailsHandler();
                closeMenu();
            },
        },
        {
            title: t({ id: 'edit', message: 'Редактировать' }),
            img: PenGrayImg,
            conditionToShow: isOwner && (isConferenceScheduled || isConferenceStarted),
            handler: () => {
                editConferenceHandler();
                closeMenu();
            },
        },
        {
            title: t({ id: 'share', message: 'Поделиться' }),
            conditionToShow: true,
            img: ShareImg,
            handler: () => {
                shareConferenceHandler();
                closeMenu();
            },
        },
        {
            title: t({ id: 'delete', message: 'Удалить' }),
            img: DeleteImg,
            color: 'red',
            conditionToShow: isOwner && isConferenceScheduled,
            handler: () => {
                deleteConferenceHandler();
                closeMenu();
            },
        },
    ];

    const checkMenuPosition = () => {
        const container = containerRef.current;
        if (!container) {
            return;
        }
        if (container.getBoundingClientRect().bottom > window.innerHeight && !isOutsideWindow) {
            setIsOutsideWindow(true);
        } else if (
            container.getBoundingClientRect().bottom < window.innerHeight &&
            isOutsideWindow
        ) {
            setIsOutsideWindow(false);
        }
    };

    useEffect(() => {
        checkMenuPosition();
    }, [containerRef]);

    return (
        <>
            <OutsideClick onOutsideClick={closeMenu}>
                <div
                    className={`${styles.contextMenu} ${
                        isOutsideWindow ? styles.contextMenu_top : ''
                    }`}
                    ref={containerRef}>
                    <ul className={styles.contextMenu__list}>
                        {menuItems.map(({ title, img, color, conditionToShow, handler }) => {
                            if (!conditionToShow) {
                                return null;
                            }

                            return (
                                <li className={styles.contextMenu__item} key={title}>
                                    <Button
                                        styleType='common'
                                        className={styles.contextMenu__button}
                                        clickHandler={handler}>
                                        <div
                                            className={`${styles.contextMenu__buttonContent} ${
                                                color
                                                    ? styles[`contextMenu__buttonContent_${color}`]
                                                    : ''
                                            }`}>
                                            <img src={img} />
                                            <span>{title}</span>
                                        </div>
                                    </Button>
                                </li>
                            );
                        })}
                    </ul>
                </div>
            </OutsideClick>
        </>
    );
};
