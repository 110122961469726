import { format } from 'date-fns';
import { convertStringToDateByTemplate } from 'utils/dates/convertStringToDateByTemplate';
import { getDateBasedOnTimezone } from 'utils/dates/getDateBasedOnTimezone';
import styles from '../../UpcomingConferenceMobile.module.scss';
import CircleGrayImg from 'assets/CircleGray.svg';
import { IOwnProps } from './types';

export const UpcomingConferenceInfoHeaderMobile: React.FC<IOwnProps> = ({
    conferenceName,
    conferenceTimeStart,
    isRecurringConference,
    userTimeZone,
}) => {
    const dateStart = getDateBasedOnTimezone(
        convertStringToDateByTemplate(conferenceTimeStart as string, 'yyyy-MM-dd HH:mm:ss.SSSSSS'),
        userTimeZone,
    );
    const timeRange = format(dateStart, 'HH:mm');

    return (
        <div className={styles.header}>
            <div>{conferenceName}</div>
            <div>
                {isRecurringConference && <img src={CircleGrayImg} alt='' />}
                <div>{timeRange}</div>
            </div>
        </div>
    );
};
