import { createAsyncThunk } from '@reduxjs/toolkit';
import { setInvitedUserAction } from 'actions/schedulingConference/setInvitedUserAction';
import { getLdapUsersDataThunkAction } from 'thunk/users/getLdapUsersDataThunkAction';
import { TAppState } from 'types/app/TAppStore';
import { IUserLdapData } from 'types/user/IUserLdapData';

let prevUsers: string[];

export const clearPrevUsers = () => {
    prevUsers = [];
}

export const getInvitedUsersDataThunkAction = createAsyncThunk(
    'getInvitedUsersData',
    async (users: string[], { dispatch, getState }) => {
        if (!users || JSON.stringify(prevUsers) === JSON.stringify(users)) {
            return;
        }
        prevUsers = users;
        const {
            schedulingConference: { invitedUsers },
        } = getState() as TAppState;
        const newUsers: string[] = [];
        users.forEach((user) => {
            if (invitedUsers.length === 0) {
                newUsers.push(user);
                return;
            }
            const isNewUser = invitedUsers.find((invUser) => {
                return invUser.email === user;
            });
            !isNewUser ? newUsers.push(user) : false;
        });
        if (newUsers.length === 0) {
            return;
        }
        const { payload: ldapUsers } = await dispatch(
            getLdapUsersDataThunkAction({
                value: newUsers.join(','),
                type: 'email',
            }),
        );
        if (ldapUsers) {
            const formattedNewUsersData = ldapUsers as IUserLdapData[];
            dispatch(setInvitedUserAction([...invitedUsers, ...formattedNewUsersData]));
        }
    },
);
