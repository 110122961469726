import { t } from '@lingui/macro';
import { appHistory } from 'appHistory';
import { showNotifyThunkAction } from 'thunk/notify/showNotifyThunkAction';
import { log } from 'utils/log';
import { janusTextRoomLogger } from 'utils/logger';
import { randomString } from 'utils/randomString';
import { createTextroom } from './createTextroom';
import { janusCtx } from './janusCtx';
import { janusInitializationErrorEvent } from './janusCustomEvents';
import { janusTextRoomMessagesHandler } from './janusTextRoomMessagesHandler';
import { joinTextroom } from './joinTextroom';
import { setupDataChannel } from './setupDataChannel';

const opaqueId = 'textroom-' + randomString(12);

export const getJanusTextRoomPluginAttachArgument = (
    resolve: () => void,
    reject: (reason: any) => void,
) => ({
    plugin: 'janus.plugin.textroom',
    opaqueId: opaqueId,
    success: (textroom: any) => {
        janusCtx.textRoomPluginHandler = textroom;
        janusTextRoomLogger.debug(
            'Plugin attached! (' + textroom.getPlugin() + ', id=' + textroom.getId() + ')',
        );
        setupDataChannel();
        resolve();
    },
    error: (error: any) => {
        janusTextRoomLogger.error('  -- Error attaching plugin...', error);
        reject({ reason: 'Error attaching Janus TextRoom plugin' });
        document.dispatchEvent(janusInitializationErrorEvent);
        janusCtx.dispatch(
            showNotifyThunkAction({
                timeout: 5000,
                type: 'error',
                text: t({
                    id: 'Janus initialization failed',
                    message:
                        'Ошибка при инициализации плагина Janus. Будет осуществлён возврат на домашнюю страничку',
                }),
                closeAction: () => {
                    if (window.location.pathname !== '/') {
                        appHistory.push('/');
                    }
                },
            }),
        );
    },
    iceState: (state: any) => {
        janusTextRoomLogger.debug(`ICE state changed to ${state}`);
    },
    mediaState: (medium: any, on: any) => {
        janusTextRoomLogger.debug(
            `Janus ${on ? 'started' : 'stopped'}  receiving our ${medium}`,
        );
    },
    webrtcState: (on: any) => {
        janusTextRoomLogger.debug(
            'Janus says our WebRTC PeerConnection is ' + (on ? 'up' : 'down') + ' now',
        );
    },
    onmessage: (msg: any, jsep: any) => {
        janusTextRoomLogger.debug(' ::: Got a message :::', msg);
        if (jsep) {
            // Answer
            janusCtx.textRoomPluginHandler.createAnswer({
                jsep,
                media: { audio: false, video: false, data: true }, // We only use datachannels
                success: function (jsep: any) {
                    janusTextRoomLogger.debug('Got SDP!', jsep);
                    const body = { request: 'ack' };
                    janusCtx.textRoomPluginHandler.send({ message: body, jsep });
                },
                error: (error: any) => {
                    janusTextRoomLogger.error('WebRTC error:', error);
                },
            });
        }
    },
    ondataopen: (data: any) => {
        janusTextRoomLogger.debug(`The DataChannel is available! ${data}`);
        const joinReject = (params: any) => {
            log.error(`Join rejected. ${params.error}`);
            params.createTextRoomFirst
                ? createTextroom().then(() => joinTextroom())
                : janusTextRoomLogger.error(params.error);
        };
        joinTextroom()
            .then(() => (janusCtx.chatIsReady = true), joinReject)
            .catch((e) => log.debug({ e }));
    },
    ondata: janusTextRoomMessagesHandler,
    oncleanup: () => {
        janusTextRoomLogger.debug(' ::: Got a cleanup notification :::');
        janusCtx.textRoomPluginHandler.send({ message: { request: 'leave' } });
    },
});
