import { FC } from 'react';
import { classNames } from 'utils/classNames';
import { IOwnProps } from './types';
import styles from './Avatar.module.scss';
import { getAvatarUrl } from 'utils/users/getAvatarUrl';
import { UserIcon } from 'admin-app/components/common/icons';

export const Avatar: FC<IOwnProps> = ({
    size = '40',
    className,
    alt,
    user,
    placeholder,
    ...props
}) => {
    let etag;
    if ('etag' in user) {
        etag = user.etag;
    }
    if ('avatar' in user) {
        etag = user.avatar;
    }
    const renderPlaceholder = () =>
        placeholder || (
            <div
                style={{ width: `${size}px`, height: `${size}px` }}
                className={classNames([styles.placeholder, className])}>
                <UserIcon />
            </div>
        );
    const avatarUrl = etag && getAvatarUrl({ id: user.id, etag, size });
    return (
        <>
            {etag && (
                <img
                    className={classNames([styles.img, className])}
                    style={{ width: `${size}px` }}
                    alt={alt}
                    {...props}
                    src={avatarUrl}
                />
            )}
            {!etag && renderPlaceholder()}
        </>
    );
};
