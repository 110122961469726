import { getTimeZonesArray } from './getTimeZonesArray';

const timeZones = getTimeZonesArray();
export const getTimeZoneName = (timeZone: string | undefined): string => {
    if (!timeZone) return '';
    const foundTimeZone = timeZones.filter((tz) => tz.value === timeZone)[0];
    if (foundTimeZone) {
        return foundTimeZone.label;
    }
    return '';
};
