import React, { useEffect, useState } from 'react';
import { useMediaQuery } from 'react-responsive';
import { useLocation } from 'react-router-dom';
import { executePendingOperationThunkAction } from 'thunk/appView/executePendingOperationThunkAction';
import { getCurrentUserProfileDataThunkAction } from 'thunk/auth/getCurrentUserProfileDataThunkAction';
import { useAppDispatch } from 'types/app/TAppDispatch';
import { UAParser } from 'ua-parser-js';
import styles from './LoginChecker.module.scss';
import { IDispatchProps, IStateProps } from './types';
import { checkForbiddenRoutes, shouldBeCheckedProfileOnRoute } from './utils';

export const loginCheckerId = 'loginChecker';
export const LoginChecker: React.FC<IStateProps & IDispatchProps> = ({
    checkCurrentProfile,
    children,
    deviceProps,
    isAuthUser,
    ldap,
    rememberMeRequestStatus,
    startup,
    updateDeviceProps,
}) => {
    const dispatch = useAppDispatch();
    const [currentDeviceHeight, setCurrentDeviceHeight] = useState<null | number>(null);

    // Determine current device
    const uaParser = UAParser(navigator.userAgent);
    const {
        device: { type: deviceType },
    } = uaParser;
    const isBigScreen = useMediaQuery({ query: '(min-width: 1824px)' });
    const isUltraSmallScreen =
        useMediaQuery({ query: '(max-width: 550px)' }) &&
        !(deviceType === 'mobile' || deviceType === 'tablet');
    const isSmallScreen =
        useMediaQuery({ query: '(max-width: 1024px)' }) &&
        !(deviceType === 'mobile' || deviceType === 'tablet');
    const isTabletOrMobile =
        useMediaQuery({ query: '(max-width: 1224px)' }) &&
        (deviceType === 'mobile' || deviceType === 'tablet');
    const isPortrait = useMediaQuery({ query: '(orientation: portrait)' });

    const updateDeviceHeight = () => {
        if (isTabletOrMobile) {
            setCurrentDeviceHeight((prevHeight) => {
                return window.innerHeight !== prevHeight ? window.innerHeight : prevHeight;
            });
        }
    };

    const { pathname } = useLocation();

    useEffect(() => {
        if (
            isBigScreen !== deviceProps.isBigScreen ||
            isTabletOrMobile !== deviceProps.isTabletOrMobile ||
            isPortrait !== deviceProps.isPortrait ||
            isSmallScreen !== deviceProps.isSmallScreen ||
            isUltraSmallScreen !== deviceProps.isUltraSmallScreen
        ) {
            updateDeviceProps({
                isBigScreen,
                isPortrait,
                isTabletOrMobile,
                isSmallScreen,
                isUltraSmallScreen,
            });
        }
    }, [
        isBigScreen,
        isTabletOrMobile,
        isPortrait,
        isSmallScreen,
        updateDeviceProps,
        isUltraSmallScreen,
    ]);

    useEffect(() => {
        window.addEventListener('resize', updateDeviceHeight);
        updateDeviceHeight();
        startup();
        return () => {
            window.removeEventListener('resize', updateDeviceHeight);
        };
    }, []);

    const requestGetUserResolved =
        rememberMeRequestStatus !== 'initial' && rememberMeRequestStatus !== 'requested';

    useEffect(() => {
        checkForbiddenRoutes({ ldap, isAuthUser, requestGetUserResolved });
        if (shouldBeCheckedProfileOnRoute(pathname) && requestGetUserResolved) {
            checkCurrentProfile();
        }
    }, [pathname, isAuthUser, ldap, requestGetUserResolved]);

    useEffect(() => {
        if (rememberMeRequestStatus === 'initial') {
            dispatch(
                executePendingOperationThunkAction({
                    operation: 'gettingUserProfileData',
                    pendingFunction: () => dispatch(getCurrentUserProfileDataThunkAction()),
                }),
            );
        }
    }, [rememberMeRequestStatus]);

    return (
        <div
            id={loginCheckerId}
            className={isTabletOrMobile || isUltraSmallScreen ? styles.mobile : styles.desktop}>
            {children}
        </div>
    );
};
