import { t } from '@lingui/macro';
import { DynamicFormConnected } from 'components/forms/DynamicForm/DynamicFormConnected';
import { videoSettings } from 'constants/appSettingsStructure';
import { AccountSettingsTemplate } from '../AccountSettingsTemplate/AccountSettingsTemplate';
import { ISettingsPartStateProps } from '../types';
import { getVideoSettingsFormInitialValues } from './utils';
//TODO FIX ANY

export const AccountVideoSettings: React.FC<ISettingsPartStateProps> = ({ settings }) => (
    <AccountSettingsTemplate
        settingsTitle={t({ id: 'accountSetting.video.title', message: 'Настройки видео' })}
        settingsDescription={t({
            id: 'accountSetting.video.description',
            message: 'Настройки видеопотока и камеры.',
        })}>
        <DynamicFormConnected
            structure={[videoSettings]}
            initialValues={getVideoSettingsFormInitialValues(settings as any)}
        />
    </AccountSettingsTemplate>
);
