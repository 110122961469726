import { setJanusStreamPropsAction } from 'actions/janus/setJanusStreamPropsAction';
import { setVideoRoomConnectedStatusAction } from 'actions/janus/setVideoRoomConnectedStatusAction';
import { IAppSettingsTyped } from 'types/app/IAppSettings';
import { TAppState } from 'types/app/TAppStore';
import { janusVideoRoomTracksLogger } from 'utils/logger';
import { checkOwnMediaBeforePublish } from './../conferences/checkOwnMediaBeforePublish';
import { calculateOfferData } from './calculateOfferData';
import { janusCtx } from './janusCtx';

let connectionTry = true;

export const publishOwnFeed = async () => {
    const {
        janus: {
            localStreamProps,
            localStreamProps: { useAudio, useVideo },
        },
        auth: { appSettings },
    } = janusCtx.getState() as TAppState;
    const checkMedia = await checkOwnMediaBeforePublish(useVideo, useAudio);
    const { tracks, message, streamActions } = calculateOfferData(
        checkMedia,
        { ...localStreamProps, useAudio: false, useVideo: false },
        appSettings as IAppSettingsTyped,
        janusCtx,
    );
    if (tracks.length === 0) {
        return;
    }
    if (streamActions.unpublish) {
        // Should not be
        return;
    }
    // Publish our stream
    janusCtx.videoRoomPublisherPluginHandler.createOffer({
        tracks,
        simulcast: false, //doSimulcast,
        simulcast2: false, //doSimulcast2,
        success: (jsep: any) => {
            janusVideoRoomTracksLogger.debug('Got publisher SDP!', jsep);
            janusCtx.published = true;
            janusCtx.videoRoomPublisherPluginHandler.send({ message, jsep });
            janusCtx.dispatch(setVideoRoomConnectedStatusAction(true));
            janusCtx.dispatch(
                setJanusStreamPropsAction({
                    mediaEnabled: true,
                }),
            );
            connectionTry = true;
        },
        error: function (error: any) {
            janusVideoRoomTracksLogger.error('WebRTC error:', error);
            // if error - unpublish own stream
            // Republish - changeStreamProps will be
            if (janusCtx?.dispatch) {
                janusCtx.dispatch(
                    setJanusStreamPropsAction({
                        useAudio: false,
                        useVideo: false,
                        mediaEnabled: error.name !== 'NotAllowedError',
                    }),
                );
                //Try to re-connect without media
                if (connectionTry) {
                    connectionTry = false;
                    publishOwnFeed();
                }
            }
        },
    });
};
