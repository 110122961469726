import { TAppState } from 'types/app/TAppStore';
import { IStateProps, IDispatchProps, TTableFilter } from 'admin-app/pages/Users/types';
import { TAppDispatch } from 'types/app/TAppDispatch';
import { getUsersPendingThunkAction } from 'admin-app/thunk/users/getUsersThunkAction';
import { ColumnDef } from '@tanstack/react-table';
import { IAdminPanelUser } from 'admin-app/types/IAdminPanelUser';
import { t } from '@lingui/macro';
import styles from './UsersPage.module.scss';
import { Avatar } from 'admin-app/components/common/Avatar';
import { addUsersTimeZoneToDate } from 'utils/dates/addUsersTimeZoneToDate';
import { convertDateToStringByTemplate } from 'utils/dates/convertDateToStringByTemplate';
import { classNames } from 'utils/classNames';
import { Trans } from '@lingui/react';
import { EUserRoles } from 'admin-app/constants/EUserRoles';
import { Dispatch, SetStateAction } from 'react';
import { EGetUsers } from 'admin-app/constants/EGetUsers';
import { redirect } from 'utils/redirect';
import { scrollTableToTopEvent } from 'admin-app/components/common/Table/utils';
import { EPendingOperations } from 'admin-app/constants/EPendingOperations';

export const setTableScrollToTop = () => window.dispatchEvent(new Event(scrollTableToTopEvent));

export const onItemClick = (userData: IAdminPanelUser | undefined) => {
    userData && redirect(`/admin/user/${userData.id}`);
};

export const getConvertedFilterValue = (filterVal: TTableFilter) => {
    switch (filterVal) {
        case 'inactive':
            return false;
        case 'active':
            return true;
        default:
            return undefined;
    }
};

export const filterTable = (
    filterVal: TTableFilter,
    getFilteredUsers: (activated: boolean) => void,
    getUsers: () => void,
) => {
    if (filterVal === 'active') {
        getFilteredUsers(true);
    } else if (filterVal === 'inactive') {
        getFilteredUsers(false);
    } else {
        getUsers();
    }
};

export const mapStateToProps = (state: TAppState): IStateProps => ({
    users: state.admin.users.list,
    totalUsersCount: state.admin.users.total,
    ldap: state.applicationConfiguration.config.ldap,
    lang: state.auth.currentUser?.language,
    usersSearch: state.admin.usersSearch,
    gettingUsers:
        !!state.applicationView.pendingOperations[`${EPendingOperations.GETTING_USERS_LIST}`],
});

export const dynamicLoading = async ({
    search,
    tableFilter,
    currentPage,
    totalUsersCount,
    getUsers,
    setTablePagination,
    setIsDownloadingUsers,
    setIsMaxUsersCount,
}: {
    search: string;
    tableFilter: boolean | undefined;
    currentPage: number;
    totalUsersCount: number;
    getUsers: IDispatchProps['getUsers'];
    setTablePagination: Dispatch<SetStateAction<number>>;
    setIsDownloadingUsers: Dispatch<SetStateAction<boolean>>;
    setIsMaxUsersCount: Dispatch<SetStateAction<boolean>>;
}) => {
    const pageSize = EGetUsers.DEFAULT_PAGE_SIZE;
    const isAllUsers = totalUsersCount - (currentPage + 1) * pageSize <= 0;
    if (isAllUsers) {
        setIsMaxUsersCount(true);
        return;
    }
    try {
        setIsDownloadingUsers(true);
        const result = await getUsers({
            page: currentPage + 1,
            search,
            activated: tableFilter,
            pageSize,
        });
        if (typeof result !== 'number') {
            setIsDownloadingUsers(false);
            return;
        }

        setTablePagination((prevVal) => prevVal + 1);
        setIsDownloadingUsers(false);
    } catch (e) {
        setIsDownloadingUsers(false);
    }
};

export const mapDispatchToProps = (dispatch: TAppDispatch): IDispatchProps => ({
    getUsers: (params) => dispatch(getUsersPendingThunkAction(params)).unwrap(),
});

type IRoles = Pick<IAdminPanelUser, 'roles'>;

const getRoleInfo = ({ roles }: IRoles) => {
    if (!roles) {
        return {
            value: 'participant',
            text: t({ id: 'admin.role.participant', message: 'Участник' }),
        };
    }
    const isAdmin = roles.find((role) => role === EUserRoles.ADMIN);
    if (isAdmin) {
        return {
            value: 'admin',
            text: t({ id: 'admin.role.admin', message: 'Администратор' }),
        };
    }

    const isOrganizer = roles.find((role) => role === EUserRoles.ORGANIZER);
    if (isOrganizer) {
        return {
            value: 'organizer',
            text: t({ id: 'admin.role.Organizer', message: 'Организатор' }),
        };
    }
};

export const columns: (locale: Locale) => ColumnDef<IAdminPanelUser>[] = (locale) => [
    {
        accessorFn: (user) => `${user.firstName} ${user.lastName}`,
        header: t({ id: 'admin.name' }),
        cell: ({ row: { original } }) => {
            return (
                <div className={styles.table__name}>
                    <Avatar user={original} size='56' />
                    {`${original.firstName} ${original.lastName}`}
                </div>
            );
        },
    },
    {
        accessorKey: 'email',
        header: t({ id: 'admin.email' }),
        cell: ({ renderValue }) => <div>{`${renderValue()}`}</div>,
    },
    {
        accessorKey: 'createdAt',
        header: t({ id: 'admin.creatAt' }),
        cell: ({ row: { original } }) => {
            const dateTimeStartConference = addUsersTimeZoneToDate(new Date(original.createdAt));
            return (
                <div className={styles.table__date}>
                    {convertDateToStringByTemplate(dateTimeStartConference, 'dd LLL yyyy', {
                        locale,
                    })}
                </div>
            );
        },
    },
    {
        accessorKey: 'roles',
        header: t({ id: 'admin.role' }),
        cell: ({
            row: {
                original: { roles },
            },
        }) => {
            const roleInfo = getRoleInfo({ roles });
            if (!roleInfo) {
                return <div></div>;
            }
            const { value, text } = roleInfo;
            return (
                <div className={classNames([styles.table__role, styles[`table__role_${value}`]])}>
                    {text}
                </div>
            );
        },
    },
    {
        accessorKey: 'activated',
        header: t({ id: 'admin.status' }),
        cell: ({
            row: {
                original: { activated },
            },
        }) => (
            <span
                className={classNames([
                    styles.table__active,
                    activated ? styles.table__active_green : styles.table__active_orange,
                ])}>
                {activated && <Trans id='admin.user.active' message='Активен' />}
                {!activated && <Trans id='admin.user.inactive' message='Неактивен' />}
            </span>
        ),
    },
];
