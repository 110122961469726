import { AnyAction, ThunkDispatch } from '@reduxjs/toolkit';
import { ESocketAppActions } from 'constants/ESocketAppActions';
import { showNotifyThunkAction } from 'thunk/notify/showNotifyThunkAction';
import { TAppState } from 'types/app/TAppStore';
import { IUpcomingConferenceInfo } from 'types/conferencesList/IUpcomingConferenceInfo';
import { INotification } from 'types/INotification';
import { TConferenceEvents } from 'types/socket/conferenceEvents/TConferenceEvents';
import { getConferenceNotificationMobileOptions } from './getConferenceNotificationMobileOptions';
import { getConferenceNotificationTexts } from './getConferenceNotificationTexts';

interface IUserConferenceNotification {
    actionType: TConferenceEvents;
    upcomingConferenceData: IUpcomingConferenceInfo | undefined;
    dispatch: ThunkDispatch<unknown, unknown, AnyAction>;
    dynamicCheck?: (
        notification: INotification,
        callback: (notification: INotification) => void,
    ) => void;
    checkForHiding?: () => boolean;
    getState: () => unknown;
    handleMobileClick?: () => void;
}

export const displayConferenceNotification = async ({
    actionType,
    upcomingConferenceData,
    dispatch,
    getState,
    dynamicCheck,
    checkForHiding,
    handleMobileClick,
}: IUserConferenceNotification) => {
    const {
        auth: { currentUser },
        applicationView: {
            deviceProps: { isTabletOrMobile },
        },
        conferencesList: { upcomingConferences },
    } = getState() as TAppState;
    const existConferenceData = upcomingConferences[upcomingConferenceData?.id || ''];
    const isOwnConference = upcomingConferenceData?.creator.userId === currentUser?.id;
    if (
        !upcomingConferenceData ||
        (actionType === ESocketAppActions.CONFERENCE_SCHEDULED && isOwnConference) ||
        (actionType === ESocketAppActions.CONFERENCE_UPDATED && existConferenceData)
    ) {
        return;
    }

    const notificationTexts = getConferenceNotificationTexts({
        actionType,
        conferenceName: upcomingConferenceData.name,
        isMobile: isTabletOrMobile,
    });

    if (!notificationTexts) {
        return;
    }
    dispatch(
        showNotifyThunkAction({
            ...notificationTexts,
            timeout: 5000,
            type: 'notify',
            dynamicCheck,
            checkForHiding,
            mobileThemeOptions: getConferenceNotificationMobileOptions({
                actionType,
                conferenceId: upcomingConferenceData.id,
                callback: handleMobileClick,
            }),
        }),
    );
};
