import { createAsyncThunk } from '@reduxjs/toolkit';
import { storeCurrentConferenceParticipantsAction } from 'actions/conferences/storeCurrentConferenceParticipantsAction';
import { TAppState } from 'types/app/TAppStore';
import { IConferenceParticipantFromServer } from 'types/server/IConferenceParticipantFromServer';
import { convertParticipantFromServerToParticipant } from 'utils/participants/convertParticipantFromServerToParticipant';

export const setParticipantsFromServerThunkAction = createAsyncThunk(
    'setParticipantsFromServer',
    async (participantsFromServer: IConferenceParticipantFromServer[], { dispatch, getState }) => {
        const {
            conferenceSession: { currentConferenceCreator },
        } = getState() as TAppState;
        const participants = participantsFromServer.map((elem: any) =>
            convertParticipantFromServerToParticipant(elem, currentConferenceCreator),
        );
        dispatch(storeCurrentConferenceParticipantsAction(participants));
    },
);
