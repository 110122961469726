import { TAppState } from 'types/app/TAppStore';
import { IStateProps } from './types';
import { connect } from 'react-redux';
import { License } from './License';

const mapStateToProps = (state: TAppState): IStateProps => ({
    expirationDate: state.auth.license.expirationDate,
});

export const LicenseConnected = connect(mapStateToProps)(License);
