import { Avatar } from 'admin-app/components/common/Avatar';
import { FC } from 'react';
import styles from './Header.module.scss';
import { UserSearchConnected } from './UserSearch';
import { IOwnProps } from './types';

export const Header: FC<IOwnProps> = ({ user }) => {
    return (
        <div className={styles.container}>
            <UserSearchConnected />
            <div>
                <Avatar user={user} alt='current user avatar' />
            </div>
        </div>
    );
};
