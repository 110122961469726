import { plural, t } from '@lingui/macro';
import { format, parse } from 'date-fns';
import { enUS, ru } from 'date-fns/locale';
import { getCurrentDate } from 'utils/dates/getCurrentDate';
import { dateFormat } from '../ConferenceRepeatDialog/utils';
import styles from './ConferenceRepeatInfo.module.scss';
import { IOwnProps, IStateProps } from './types';
import { getLocale } from 'utils/getLocale';

export const ConferenceRepeatInfo: React.FC<IOwnProps & IStateProps> = ({
    formData,
    className,
    isEndDateValid = true,
    lang,
    handleEdit,
}) => {
    const daysList = {
        monday: t({ id: 'monday', message: 'понедельник' }),
        tuesday: t({ id: 'tuesday', message: 'вторник' }),
        wednesday: t({ id: 'wednesday', message: 'среда' }),
        thursday: t({ id: 'thursday', message: 'четверг' }),
        friday: t({ id: 'friday', message: 'пятница' }),
        saturday: t({ id: 'saturday', message: 'суббота' }),
        sunday: t({ id: 'sunday', message: 'воскресенье' }),
    };

    const getCountPlurals = () => {
        if (formData.step === 'week') {
            return plural(formData.count, {
                one: 'каждую',
                few: 'каждую',
                other: '',
            });
        }
        return plural(formData.count, {
            one: 'каждый',
            few: 'каждые',
            other: '',
        });
    };

    const getStepPlurals = () => {
        if (formData.step === 'week') {
            return plural(formData.count, {
                one: 'неделю',
                few: 'неделю',
                other: '',
            });
        }
        if (formData.step === 'day') {
            return plural(formData.count, {
                one: 'день',
                few: 'дня',
                many: 'дней',
                other: '',
            });
        }
        return plural(formData.count, {
            one: 'месяц',
            few: 'месяца',
            other: '',
        });
    };

    const getEndDate = () => {
        if (formData.endDate && isEndDateValid) {
            let formatDate = '';
            const preposition = t({ id: 'before', message: 'до' });
            try {
                const parseDate = parse(formData.endDate, dateFormat, getCurrentDate());
                formatDate = format(parseDate, 'dd MMM, yyyy', { locale: getLocale(lang) });
            } catch (err) {
                return '';
            }
            return `${preposition} ${formatDate.replace('.', '')}`;
        }
        return '';
    };

    const getDays = () => {
        if (formData.days.length === 0) {
            return '';
        }
        const days = formData.days.map((day) => daysList[day]);
        const preposition = t({ id: 'on', message: 'в' });
        return `${preposition} ${days.join(', ')}`;
    };

    const getTime = () => {
        const time = formData.time;
        const preposition = t({ id: 'at', message: 'в' });
        return `${preposition} ${time}`;
    };

    const getCounter = () => {
        if (formData.step === 'week') {
            return '';
        }
        return Number(formData.count) === 1 ? '' : formData.count;
    };

    const getResultString = () => {
        if (formData.days.length === 0) {
            return t({ id: 'repeat.dialog.select.days', message: 'Пожалуйста, выберите дни' });
        }
        return `${t({
            id: 'repeat.dialog.repeat',
            message: 'Повторять ',
        })} ${getCountPlurals()} ${getCounter()} ${getStepPlurals()} ${getTime()} ${
            formData.step === 'week' ? getDays() : ''
        } ${getEndDate()}`;
    };

    return (
        <div className={`${styles.container} ${className || ''}`}>
            {getResultString()}
            {handleEdit && <span onClick={handleEdit}>{t({ id: 'Edit' })}</span>}
        </div>
    );
};
