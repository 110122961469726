import { Button } from 'components/common/Button';
import { TOwnProps } from './types';
import { ReactComponent as ArrowBackImg } from 'assets/ArrowBack.svg';
import styles from '../MobileNotification.module.scss';

export const MobileNotificationAction: React.FC<TOwnProps> = ({ options }) => {
    const getActionContent = () => {
        if (options.theme === 'primaryAction') {
            return <ArrowBackImg className={styles.actionArrow} />;
        } else {
            return (
                <Button
                    styleType='mobilePrimaryAction'
                    className={styles.actionButton}
                    clickHandler={options.action}>
                    {options.buttonText}
                </Button>
            );
        }
    };
    return <div className={styles.mobileNotification__action}>{getActionContent()}</div>;
};
