import { connect } from 'react-redux';
import { addMessageToConferenceChatThunkAction } from 'thunk/chat/addMessageToConferenceChatThunkAction';
import { TAppDispatch } from 'types/app/TAppDispatch';
import { TAppState } from 'types/app/TAppStore';
import { ConferenceEmotionsButton } from './ConferenceEmotionsButton';
import { IDispatchProps, IStateProps } from './types';

const mapStateToProps = (state: TAppState): IStateProps => ({
    ownUserId: state.auth.currentUser?.id || null,
    ownParticipantId: state.janus.ownParticipantId,
});

const mapDispatchToProps = (dispatch: TAppDispatch): IDispatchProps => ({
    addNewMessage: (messageData) => dispatch(addMessageToConferenceChatThunkAction(messageData)),
    // setOwnEmotionToShow: (emotion) => dispatch(setOwnEmotionToShowAction(emotion)),
});

export const ConferenceEmotionsButtonConnected = connect(
    mapStateToProps,
    mapDispatchToProps,
)(ConferenceEmotionsButton);
