import { EnhancedStore } from '@reduxjs/toolkit';
import { setDevFeaturesAction } from 'actions/featuresReducer/setDevFeaturesAction';
import { EFeatureStatus } from 'constants/EFeatureStatus';
import { TAppState } from 'types/app/TAppStore';

export const initFeaturesBrowserApi = (applicationStore: EnhancedStore) => {
    const win = window as any;
    const dev = win.dev || (win.dev = {});

    dev.getFeatureStatus = (name: EFeatureStatus) => {
        const features = applicationStore.getState().features.features;
        if (name in features) {
            return features[name];
        }
    };

    dev.setFeatureStatus = (name: EFeatureStatus, status = false) => {
        const {
            features: { features: devFeatures },
        } = applicationStore.getState() as TAppState;
        applicationStore.dispatch(
            setDevFeaturesAction({
                ...devFeatures,
                [name]: status,
            }),
        );
    };
};
