import { createAsyncThunk } from '@reduxjs/toolkit';
import { storePastConferencesAction } from 'actions/conferencesList/storePastConferencesAction';
import { storeUpcomingConferencesAction } from 'actions/conferencesList/storeUpcomingConferencesAction';
import { setConferenceIdsToShowAction } from 'actions/sessionSettings/setConferenceIdsToShowAction';
import { getCurrentUserConferencesNewApiCall } from 'api/conference/getCurrentUserConferencesNewApiCall';
import { getNetworkService } from 'api/getNetworkService';
import { batch } from 'react-redux';
import { showNotifyThunkAction } from 'thunk/notify/showNotifyThunkAction';
import { getLdapUsersDataThunkAction } from 'thunk/users/getLdapUsersDataThunkAction';
import { TAppState } from 'types/app/TAppStore';
import { IPastConferenceInfo } from 'types/conferencesList/IPastConferenceInfo';
import { IUpcomingConferenceInfo } from 'types/conferencesList/IUpcomingConferenceInfo';
import { getDefaultErrorNotification } from 'utils/getDefaultErrorNotification';
import { getUnknownEmailsFromConference } from 'utils/getUnknownEmailsFromConference';

interface IPayload {
    type: 'upcoming' | 'past';
    role?: 'OWNER' | 'ANY';
    from?: string;
    to?: string;
}

interface IDataFromServer {
    count: number; // Total conferences in request
    array: (IUpcomingConferenceInfo | IPastConferenceInfo)[]; // Conferences in page
}

export const getCurrentUserConferencesNewThunkAction = createAsyncThunk<void, IPayload>(
    'getCurrentUserConferencesNew',
    async ({ from, role, type, to }, { getState, dispatch }) => {
        const {
            users: { ldapUsers },
        } = getState() as TAppState;
        const response = await getCurrentUserConferencesNewApiCall(getNetworkService(), {
            from,
            to,
            role,
            type,
        });
        if (response.success) {
            const isUpcoming = type === 'upcoming';
            const { array: conferences } = response.data as IDataFromServer;
            const ids = conferences.map((conference) => conference.id);
            let emails: string[] = [];
            batch(() => {
                if (isUpcoming) {
                    const upcomingConferences = conferences as IUpcomingConferenceInfo[];
                    dispatch(storeUpcomingConferencesAction(upcomingConferences));
                } else {
                    const pastConferences = conferences as IPastConferenceInfo[];
                    dispatch(storePastConferencesAction(pastConferences));
                }
                dispatch(setConferenceIdsToShowAction(ids));
            });
            if (isUpcoming) {
                emails = getUnknownEmailsFromConference(
                    conferences as IUpcomingConferenceInfo[],
                    ldapUsers,
                );
                if (emails.length !== 0) {
                    await dispatch(
                        getLdapUsersDataThunkAction({ type: 'email', value: emails.join(',') }),
                    );
                }
            }
        } else {
            dispatch(showNotifyThunkAction(getDefaultErrorNotification()));
        }
    },
);
