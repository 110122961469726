import { IWebHook } from 'admin-app/types/IWebHook';
import { INetworkService, IRequestResult } from 'types/api/INetworkService';

interface IPayload {
    id: number;
    url: string;
    token: string;
}

export const editWebHookApiCall = (
    networkService: INetworkService,
    { id, url, token }: IPayload,
): Promise<IRequestResult<IWebHook>> => {
    return networkService.put({
        url: `/api/admin/webhooks/${id}`,
        data: {
            url,
            token,
        },
    });
};
