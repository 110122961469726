import { createReducer } from '@reduxjs/toolkit';
import { setCurrentUserDataAction } from 'actions/auth/setCurrentUserDataAction';
import { resetStoreAction } from 'actions/resetStoreAction';
import { storeLdapUsers } from 'actions/users/storeLdapUsers';
import { storeUsers } from 'actions/users/storeUsers';
import { IUserData } from 'types/user/IUserData';
import { IUserLdapData } from 'types/user/IUserLdapData';

export interface IUsersReducer {
    users: { [id: string]: IUserData };
    ldapUsers: { [email: string]: IUserLdapData };
}

export const usersReducerInitialState: IUsersReducer = {
    users: {},
    ldapUsers: {},
};

export const usersReducer = createReducer(usersReducerInitialState, (builder) =>
    builder
        .addCase(storeUsers, (state, { payload: users }) => {
            users.forEach((user) => (state.users[user.id] = user));
        })
        .addCase(storeLdapUsers, (state, { payload: users }) => {
            users.forEach((user) => (state.ldapUsers[user.email] = user));
        })
        .addCase(setCurrentUserDataAction, (state, { payload: user }) => {
            if (!user) return;
            state.users[user.id] = user;
        })
        .addCase(resetStoreAction, () => usersReducerInitialState),
);
