import { Trans } from '@lingui/react';
import { Button } from 'components/common/Button';
import { CopyInviteLink } from 'components/common/CopyInviteLink';
import { QrConferenceInviteLink } from 'components/common/QrConferenceInviteLink';
import { ConferenceTimer } from 'components/ConferenceTimer';
import { ConferenceJoinSettingsFormMobile } from 'components/forms/ConferenceJoinSettingsFormMobile';
import { MobilePageTemplate } from 'components/MobilePageTemplate';
import { ECopyToClipboardNotification } from 'constants/ECopyToClipboardNotification';
import { EPaths } from 'constants/EPaths';
import { useConferenceTimeCheck } from 'hooks/ConnectToConference/useConferenceTimeCheck';
import { useEffect } from 'react';
import { clearSubscription } from 'services/socketService/socketService';
import { createInviteLink } from 'utils/conferences/createInviteLink';
import { redirect } from 'utils/redirect';
import { ConferenceMembersMobileConnected } from './ConferenceMembersMobile';
import styles from './ConnectToConferencePageMobile.module.scss';
import { IMobileDispatchProps, IMobileStateProps, IOwnProps } from './types';
import { getConferenceTimeStartInfo, processFinishedConference } from './utils';

export const ConnectToConferencePageMobile: React.FC<
    IOwnProps & IMobileStateProps & IMobileDispatchProps
> = ({
    joinToConference,
    conferenceName,
    conferenceTimeStart,
    conferencePin,
    conferenceStatus,
    copyToClipboard,
    currentUserIsOwner,
    subscribeToConferenceChangeStatus,
    id,
    ownAudioOnConnect,
    ownVideoOnConnect,
    loadData,
    audioForbidden,
    videoForbidden,
    resetSessionStore,
}) => {
    useEffect(() => {
        loadData(id);
    });
    const conferenceId = id;
    const isConferenceAvailable = useConferenceTimeCheck(conferenceTimeStart);

    useEffect(() => {
        if (conferenceStatus === 'FINISHED') {
            processFinishedConference(conferenceId)
        }
    }, [conferenceStatus]);

    useEffect(() => {
        subscribeToConferenceChangeStatus();
        return () => {
            clearSubscription('subscribeToConferenceChangeStatus');
        };
    }, []);

    const handleClickInviteLink = () => {
        copyToClipboard({
            dataToCopy: createInviteLink(conferenceId, conferencePin),
            notifyTextKey: ECopyToClipboardNotification.INVITE_LINK,
        });
    };

    const onClickChangeSettings = () => redirect(`${EPaths.EDIT_CONFERENCE}/${conferenceId}`);

    const onClickBack = () => {
        resetSessionStore();
        redirect('/');
    };

    const isShowTimer = conferenceStatus === 'SCHEDULED';
    const isConferenceStarted = conferenceStatus === 'STARTED';
    return (
        <MobilePageTemplate
            headerClassName={styles.page__header_custom}
            rootClassName={styles.page}
            onClickBack={onClickBack}
            additionalControl={
                currentUserIsOwner ? (
                    <Button
                        styleType='mobileLink'
                        clickHandler={onClickChangeSettings}
                        className={styles.changeConferenceSettingsBtn}>
                        <Trans id='change.short' message='Изм.' />
                    </Button>
                ) : null
            }>
            <div className={styles.root}>
                <div>
                    <QrConferenceInviteLink
                        link={createInviteLink(conferenceId, conferencePin)}
                        size={120}
                        foregroundColor='#2493FB'
                    />
                    <span className={styles.conferenceName}>{conferenceName}</span>
                    {isShowTimer && isConferenceAvailable && (
                        <div className={styles.timerWrapper}>
                            <ConferenceTimer
                                conferenceTimeStart={conferenceTimeStart}
                                isMobile={true}
                            />
                        </div>
                    )}
                    {isShowTimer && !isConferenceAvailable && (
                        <div className={styles.timerWrapper}>
                            {getConferenceTimeStartInfo(conferenceTimeStart, true)}
                        </div>
                    )}

                    <div className={styles.linkWrapper}>
                        <CopyInviteLink clickHandler={handleClickInviteLink} />
                    </div>
                    <ConferenceJoinSettingsFormMobile
                        joinToConference={joinToConference}
                        defaultMediaValues={{
                            useVideo: ownVideoOnConnect,
                            useAudio: ownAudioOnConnect,
                        }}
                        isAudioForbid={audioForbidden}
                        isVideoForbid={videoForbidden}
                    />
                </div>
                <ConferenceMembersMobileConnected
                    isConferenceAvailable={isConferenceStarted || isShowTimer}
                />
            </div>
        </MobilePageTemplate>
    );
};
