import { showAudioDialogPermissionAction } from 'actions/applicationView/showAudioDialogPermissionAction';
import { MediaPermissionDialog } from 'pages/ConferencePage/MediaPermissionDialog/MediaPermissionDialog';
import { connect } from 'react-redux';
import { TAppDispatch } from 'types/app/TAppDispatch';
import { IDispatchProps } from './types';

const mapDispatchToProps = (dispatch: TAppDispatch): IDispatchProps => ({
    closeMediaPermissionDialog: () => dispatch(showAudioDialogPermissionAction(false)),
});

export const MediaPermissionDialogConnected = connect(
    null,
    mapDispatchToProps,
)(MediaPermissionDialog);
