import { t } from '@lingui/macro';
import { IErrors } from './types';

export const getErrorText = (errorDescription: string | undefined) => {
    if (!errorDescription) {
        return t({ id: 'Error', message: 'Ошибка!' });
    }
    const errors = {
        confEnd: t({ id: 'conference.join.error.end.id', message: 'Конференция завершена' }),
        idOrPin: t({
            id: 'conference.check.id.or.pin',
            message: 'Проверьте идентификатор и пинкод конференции',
        }),
        id: t({ id: 'conference.check.id', message: 'Проверьте идентификатор конференции' }),
    } as IErrors;

    return errors[errorDescription] ? errors[errorDescription] : errorDescription;
};
