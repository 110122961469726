import { Field } from 'react-final-form';
import { Toggle } from '../Toggle';
import { IOwnProps } from './types';

export const ToggleField: React.FC<IOwnProps> = ({ value, name, ...props }) => (
    <Field name={name} initialValue={value}>
        {({ input: { value, onChange } }) => {
            return <Toggle value={value} onChangeValue={onChange} {...props} />;
        }}
    </Field>
);
