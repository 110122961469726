import { connect } from 'react-redux';
import { rejoinToConferenceThunkAction } from 'thunk/conference/rejoinConferenceThunkAction';
import { TAppDispatch } from 'types/app/TAppDispatch';
import { AccessDeniedDialog } from './AccessDeniedDialog';
import { IDispatchProps } from './types';

const mapDispatchToProps = (dispatch: TAppDispatch): IDispatchProps => ({
    retry: () => {
        dispatch(rejoinToConferenceThunkAction());
    },
});

export const AccessDeniedDialogConnected = connect(null, mapDispatchToProps)(AccessDeniedDialog);
