import { INetworkService } from 'types/api/INetworkService';

interface IPayload {
    password: string;
    token: string;
}

export const recoverPasswordApiCall = (
    networkService: INetworkService,
    data: IPayload,
) =>
    networkService.post({
        url: '/api/account/password/recovery/confirm',
        data,
    });
