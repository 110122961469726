import { ConferenceChatMessageStatus } from './ConferenceChatMessageStatus';
import styles from './ConferenceChatMessageInfo.module.scss';
import { IOwnProps } from './types';
import { getFormattedDispatchTime } from './utils';

export const ConferenceChatMessageInfo: React.FC<IOwnProps> = ({
    time,
    isOwnMessage,
    status,
    isInsideContent,
}) => (
    <div
        className={`${styles.messageInfo} ${!isOwnMessage ? styles.messageInfo_opacity : ''} ${
            isInsideContent ? styles.messageInfo_insideContent : ''
        }`}>
        <span className={styles.time}>{getFormattedDispatchTime(time)}</span>
        {isOwnMessage && <ConferenceChatMessageStatus messageStatus={status} />}
    </div>
);
