import CircleGrayImg from 'assets/CircleGray.svg';
import RecordGray from 'assets/RecordGray.svg';
import RecordRed from 'assets/RecordRedMobile.svg';
import format from 'date-fns/format/index';
import { convertStringToDateByTemplate } from 'utils/dates/convertStringToDateByTemplate';
import { getDateBasedOnTimezone } from 'utils/dates/getDateBasedOnTimezone';
import styles from './PastConferencesMobile.module.scss';
import { ConferenceUsersConnected } from '../ConferenceUsers';
import { IOwnProps } from './types';

export const PastConferencesMobile: React.FC<IOwnProps> = ({ conferences, currentUser }) => {
    return (
        <>
            {conferences.map((conference) => {
                const dateStart = getDateBasedOnTimezone(
                    convertStringToDateByTemplate(
                        conference.timeStart as string,
                        'yyyy-MM-dd HH:mm:ss.SSSSSS',
                    ),
                    currentUser?.timeZone as string,
                );
                const recurring = conference.seriesId !== undefined;
                const timeRange = format(dateStart, 'HH:mm');
                const recordImg = conference.recordsCount ? RecordRed : '';
                const creatorString = `${conference.creator.firstName} ${conference.creator.lastName}`;

                return (
                    <div className={styles.conference} key={conference.id} id={conference.id}>
                        <div className={styles.info}>
                            <div className={styles.header}>
                                <div>
                                    <div>{conference.name}</div>
                                    {conference.recordsCount  && <img src={recordImg} alt='' />}
                                </div>
                                <div>
                                    {recurring && <img src={CircleGrayImg} alt='' />}
                                    <div>{timeRange}</div>
                                </div>
                            </div>
                            <div className={styles.footer}>
                                <div className={styles.footer__left}>
                                    <div>{creatorString}</div>
                                    {conference.participants && (
                                        <div className={styles.footer__invitedUsers}>
                                            <ConferenceUsersConnected
                                                usersData={{
                                                    participants: conference.participants,
                                                }}
                                            />
                                        </div>
                                    )}
                                </div>
                                <div className={styles.footer__right}></div>
                            </div>
                        </div>
                    </div>
                );
            })}
        </>
    );
};
