import { createAsyncThunk } from '@reduxjs/toolkit';
import { getNetworkService } from 'api/getNetworkService';
import { confirmAccountApiCall } from 'api/profile/confirmAccountApiCall';

export const confirmAccountThunkAction = createAsyncThunk<Promise<boolean | undefined>, string>(
    'confirmAccount',
    async (token: string) => {
        const response = await confirmAccountApiCall(getNetworkService(), token);
        return response.success;
    },
);
