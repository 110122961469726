export const getFilteredParticipants = ({
    participantsFromSocket,
    newPermissionProps: { streamType, permissionType },
    currentConferencePermissions,
}: {
    participantsFromSocket: string[];
    newPermissionProps: {
        streamType: 'audio' | 'video';
        permissionType: 'forbiddenStreams' | 'allowedStreams';
    };
    currentConferencePermissions: {
        allowedStreams: {
            audio: string[];
            video: string[];
        };
        forbiddenStreams: {
            audio: string[];
            video: string[];
        };
    };
}) => {
    const currentParticipantsId = currentConferencePermissions[permissionType][streamType];
    if (currentParticipantsId.length > participantsFromSocket.length) {
        const deletedParticipants = currentParticipantsId.filter(
            (currentParticipantId) =>
                !participantsFromSocket.some(
                    (newParticipantsId) => currentParticipantId === newParticipantsId,
                ),
        );
        return {
            participants: deletedParticipants,
            isRemoved: true,
        };
    } else {
        const newParticipants = participantsFromSocket.filter(
            (newParticipantsId) =>
                !currentParticipantsId.some(
                    (currentParticipantId) => currentParticipantId === newParticipantsId,
                ),
        );
        return {
            participants: newParticipants,
            isRemoved: false,
        };
    }
};
