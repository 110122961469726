import { t } from '@lingui/macro';
import { DynamicFormConnected } from 'components/forms/DynamicForm/DynamicFormConnected';
import { AccountSettingsTemplate } from '../AccountSettingsTemplate/AccountSettingsTemplate';
import { ISettingsPartStateProps } from '../types';
import { devSettingsStructure, getDevSettingsFormInitialValues } from './utils';
//TODO FIX "any"
export const AccountDevSettings: React.FC<ISettingsPartStateProps> = ({ settings }) => (
    <AccountSettingsTemplate
        settingsTitle={t({
            id: 'accountSetting.dev.title',
            message: 'Отладочные настройки',
        })}
        settingsDescription={t({
            id: 'accountSetting.dev.description',
            message: 'Настройки для разработчиков и тестировщиков.',
        })}>
        <DynamicFormConnected
            isDefaultForm={true}
            structure={devSettingsStructure}
            initialValues={getDevSettingsFormInitialValues(settings as any)}
        />
    </AccountSettingsTemplate>
);
