import { t } from '@lingui/macro';
import { AnyAction, ThunkDispatch } from '@reduxjs/toolkit';
import { ESocketAppActions } from 'constants/ESocketAppActions';
import { showNotifyThunkAction } from 'thunk/notify/showNotifyThunkAction';
import { INotification } from 'types/INotification';

export const recordNotifications = (
    actionType: string,
    dispatch: ThunkDispatch<unknown, unknown, AnyAction>,
    conferenceName: string,
) => {
    const notifyDefaultProps: INotification = {
        text: '',
        timeout: 5000,
        type: 'notify',
    };
    if (actionType === ESocketAppActions.RECORD_READY && conferenceName) {
        dispatch(
            showNotifyThunkAction({
                ...notifyDefaultProps,
                text: t({
                    id: 'socket.record.ready',
                    message: 'Запись {0} готова',
                    values: { '0': conferenceName },
                }),
            }),
        );
    }
};
