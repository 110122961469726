import DownloadArrowSimple from 'assets/DownloadArrowSimple.svg';
import { Button } from 'components/common/Button';
import { Cross } from 'components/common/Cross';
import styles from './ImageViewPopupHeader.module.scss';
import { IOwnProps, IStateProps } from './types';

export const ImageViewPopupHeader: React.FC<IOwnProps & IStateProps> = ({
    authorName,
    fileName,
    isMobile,
    isVisible,
    onClick,
    sendTime,
    src,
}) => {
    const clickCloseHandler = onClick || undefined;
    const headerDynamicStyles = `${!isVisible && !isMobile ? styles.header_hidden : ''}`;
    return (
        <div className={`${styles.header} ${headerDynamicStyles}`} data-image-view-header>
            <div className={styles.title}>
                <span className={styles.authorName}>{authorName}</span>
                <span className={styles.date}>{sendTime}</span>
            </div>
            <div className={styles.controls}>
                <a
                    href={src}
                    className={`${styles.download} ${!src ? styles.download_disabled : ''}`}
                    download>
                    <img src={DownloadArrowSimple} alt={fileName} />
                </a>

                <Button
                    type='button'
                    styleType='common'
                    className={styles.closeBtn}
                    clickHandler={clickCloseHandler}>
                    <Cross color='white' className={styles.cross} />
                </Button>
            </div>
        </div>
    );
};
