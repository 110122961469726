import { createAsyncThunk } from '@reduxjs/toolkit';
import { setAnonymousUserDataAction } from 'actions/auth/setAnonymousUserDataAction';
import { ERecorderThunkAction } from 'constants/thunk/ERecorderThunkAction';
import { storeUsernameToLocalStorage } from 'thunk/conference/submitInputUsernameFromThunkAction';
import { TAppState } from 'types/app/TAppStore';

export const setRecorderUserDataThunkAction = createAsyncThunk(
    ERecorderThunkAction.SET_RECORDER_USER_DATA,
    async (_, { getState, dispatch }) => {
        const state = getState() as TAppState;
        const {
            conferenceSession: { currentConferenceId },
        } = state;
        const recorderName = 'recorder';
        dispatch(setAnonymousUserDataAction({ name: recorderName }));
        storeUsernameToLocalStorage(recorderName, currentConferenceId);
    },
);
