import { EVideoResolution } from 'constants/EVideoResolution';
import { IAppSettingsTyped } from 'types/app/IAppSettings';
import { IUserDevice } from 'types/IUserDevice';
import { IChangeJanusStreamProps } from 'types/janus/IChangeJanusStreamProps';
import { IJanusContext } from 'types/janus/IJanusContext';
import { IJanusLocalStreamProps } from 'types/janus/IJanusLocalStreamProps';
import { IJanusConfigureMessage } from 'types/janus/IJanusMessage';
import { IJanusAudioTrack, IJanusTrack, IJanusVideoTrack } from 'types/janus/IJanusTrack';
import { IJanusTransceivers } from 'types/janus/IJanusTransceivers';
import { log } from 'utils/log';
import { janusVideoRoomTracksLogger } from 'utils/logger';
import {
    captureAudio,
    captureScreen,
    captureVideo,
    releaseScreen,
    releaseVideo,
} from './captureDevice';
import { calculateWidthAndHeight } from './helpers/calculateWidthAndHeight';
import { IStreamActions } from './IStreamOperations';
import { needToUnpublish } from './needToUnpublish';

const getAudioTrackAddOrReplace = async (
    transceivers: IJanusTransceivers,
    audioDevice?: IUserDevice,
): Promise<IJanusAudioTrack> => {
    const { audio_mid } = transceivers;
    const audioTrack: IJanusAudioTrack = { type: 'audio', recv: false };
    if (audio_mid) {
        audioTrack.mid = audio_mid;
        audioTrack.replace = true;
    } else {
        audioTrack.add = true;
    }
    const constraints: any = {};
    if (audioDevice) {
        constraints.deviceId = audioDevice.deviceId;
    }
    audioTrack.capture = (await captureAudio({ audio: constraints })) || true;
    return audioTrack;
};

const getVideoTrackAddOrReplace = async ({
    transceivers,
    type,
    sharingSourceId,
    appSettings,
    videoDevice,
    isTabletOrMobile,
}: {
    transceivers: IJanusTransceivers;
    type: 'video' | 'screen';
    sharingSourceId?: string;
    appSettings: Pick<IAppSettingsTyped, 'resolution'> | null;
    videoDevice?: IUserDevice;
    isTabletOrMobile: boolean;
}): Promise<IJanusVideoTrack> => {
    const { video_mid } = transceivers;
    const resolution =
        type === 'video'
            ? appSettings
                ? appSettings.resolution
                : EVideoResolution.STD_RES
            : EVideoResolution.FHD_RES;
    const { width, height } = calculateWidthAndHeight(resolution);
    const constraints: any = { width, height };
    if (type === 'screen') {
        constraints.frameRate = {
            max: 10,
        };
    }
    if (type === 'video') {
        if (videoDevice) {
            constraints.deviceId = videoDevice.deviceId;
        }
        if (isTabletOrMobile) {
            constraints.facingMode = 'user';
        }
    }
    const videoTrack: IJanusVideoTrack = { type, recv: false };
    if (type === 'video') {
        videoTrack.capture = (await captureVideo({ video: constraints })) || true;
    } else {
        videoTrack.capture = (await captureScreen({ video: constraints }, sharingSourceId)) || true;
    }
    if (video_mid) {
        videoTrack.mid = video_mid;
        videoTrack.replace = true;
    } else {
        videoTrack.add = true;
    }
    return videoTrack;
};

export const calculateOfferData2 = async (
    propsChanges: Partial<IChangeJanusStreamProps>,
    currentProps: IJanusLocalStreamProps,
    appSettings: Pick<IAppSettingsTyped, 'resolution'> | null,
    janusCtx: Pick<IJanusContext, 'published' | 'transceivers'>,
    isTabletOrMobile: boolean,
): Promise<{
    tracks: IJanusTrack[];
    message: IJanusConfigureMessage;
    streamActions: IStreamActions;
    replaceTracks: IJanusTrack[];
}> => {
    const tracks: IJanusTrack[] = [];
    const message: IJanusConfigureMessage = {};
    const streamActions: IStreamActions = {};
    const replaceTracks: IJanusTrack[] = [];
    const {
        transceivers,
        transceivers: { audio_mid, video_mid },
    } = janusCtx;
    janusVideoRoomTracksLogger.debug({ propsChange: { ...propsChanges }, currentProps });
    // No changes?
    if (
        !currentProps.useAudio &&
        !currentProps.useVideo &&
        !currentProps.screenShared &&
        propsChanges.useAudio === undefined &&
        propsChanges.useVideo === undefined &&
        propsChanges.screenShared === undefined
    ) {
        return { tracks, message, streamActions, replaceTracks };
    }

    // Check that should be unpublished
    const unpublish = needToUnpublish(propsChanges, currentProps);
    if (unpublish) {
        const streamActions: IStreamActions = janusCtx.published ? { unpublish: true } : {};
        return { tracks, message, streamActions, replaceTracks };
    }

    // Check that we need republish our tracks
    if (
        (propsChanges.useAudio && transceivers.audio_was_forbid) ||
        (propsChanges.useVideo && transceivers.video_was_forbid)
    ) {
        streamActions.republish = true;
        return { tracks, message, streamActions, replaceTracks };
    }

    if (propsChanges.audioDevice) {
        if (currentProps.useAudio) {
            if (audio_mid) {
                // replaceTracks.push({ type: 'audio', mid: audio_mid, remove: true });
                tracks.push(
                    await getAudioTrackAddOrReplace(transceivers, propsChanges.audioDevice),
                );
            }
        }
    }

    // Check audio
    if (propsChanges.useAudio) {
        if (currentProps.useAudio) {
            // Nothing to do
        } else {
            // Audio is switched on
            tracks.push(await getAudioTrackAddOrReplace(transceivers, currentProps.audioDevice));
            // if (audio_mid) {
            //     streamActions.unmuteAudio = true;
            // }
        }
    } else if (propsChanges.useAudio === false) {
        if (currentProps.useAudio && audio_mid) {
            // Audio is switched off
            tracks.push({ type: 'audio', mid: audio_mid, remove: true, recv: false });
            // streamActions.muteAudio = true;
        } else {
            // Nothing to do
        }
    }

    if (propsChanges.audioPermitted === false) {
        transceivers.audio_was_forbid = true;
        if (audio_mid) {
            if (currentProps.useAudio && propsChanges.useAudio === undefined) {
                tracks.push({ type: 'audio', mid: audio_mid, remove: true });
            }
            delete transceivers.audio_mid;
        }
    }

    if (propsChanges.videoPermitted === false) {
        transceivers.video_was_forbid = true;
        if (video_mid) {
            if (currentProps.useVideo && propsChanges.useVideo === undefined) {
                tracks.push({ type: 'video', mid: video_mid, remove: true });
            }
            delete transceivers.video_mid;
        }
    }

    if (propsChanges.videoDevice) {
        if (currentProps.useVideo && video_mid) {
            // replaceTracks.push({ type: 'video', mid: video_mid, remove: true });
            replaceTracks.push(
                await getVideoTrackAddOrReplace({
                    transceivers,
                    type: 'video',
                    appSettings,
                    videoDevice: propsChanges.videoDevice,
                    isTabletOrMobile,
                }),
            );
        }
    }

    // Check video
    if (propsChanges.useVideo) {
        if (propsChanges.screenShared) {
            // {useVideo:true, screenShared: true}
            log.error('Unsupported condition');
        } else if (propsChanges.screenShared === false) {
            // {useVideo:true, screenShared: false}
            if (currentProps.useVideo && currentProps.screenShared) {
                log.error('Unsupported condition');
            } else if (!currentProps.useVideo && currentProps.screenShared) {
                // tracks.push({ type: 'screen', remove: true });
                replaceTracks.push(
                    await getVideoTrackAddOrReplace({
                        transceivers,
                        type: 'video',
                        appSettings,
                        videoDevice: currentProps.videoDevice,
                        isTabletOrMobile,
                    }),
                );
            } else if (!currentProps.useVideo && !currentProps.screenShared) {
                tracks.push(
                    await getVideoTrackAddOrReplace({
                        transceivers,
                        type: 'video',
                        appSettings,
                        videoDevice: currentProps.videoDevice,
                        isTabletOrMobile,
                    }),
                );
                // if (video_mid) {
                //     streamActions.unmuteVideo = true;
                // }
            } else if (currentProps.useVideo && !currentProps.screenShared) {
                log.error('Video already send');
            }
        } else {
            // {useVideo:true}
            if (currentProps.useVideo && currentProps.screenShared) {
                log.error('Unsupported condition');
            } else if (!currentProps.useVideo && currentProps.screenShared) {
                replaceTracks.push(
                    await getVideoTrackAddOrReplace({
                        transceivers,
                        type: 'video',
                        appSettings,
                        videoDevice: currentProps.videoDevice,
                        isTabletOrMobile,
                    }),
                );
            } else if (!currentProps.useVideo && !currentProps.screenShared) {
                tracks.push(
                    await getVideoTrackAddOrReplace({
                        transceivers,
                        type: 'video',
                        appSettings,
                        videoDevice: currentProps.videoDevice,
                        isTabletOrMobile,
                    }),
                );
                // if (video_mid) {
                //     streamActions.unmuteVideo = true;
                // }
            } else if (currentProps.useVideo && !currentProps.screenShared) {
                log.error('Video already send');
            }
        }
    } else if (propsChanges.useVideo === false) {
        if (propsChanges.screenShared) {
            // {useVideo:false, screenShared: true}
            if (!currentProps.useVideo && !currentProps.screenShared) {
                tracks.push(
                    await getVideoTrackAddOrReplace({
                        transceivers,
                        type: 'screen',
                        sharingSourceId: propsChanges.sharingSourceId,
                        appSettings,
                        videoDevice: currentProps.videoDevice,
                        isTabletOrMobile,
                    }),
                );
                // if (video_mid) {
                //     streamActions.unmuteVideo = true;
                // }
            } else if (currentProps.useVideo && !currentProps.screenShared) {
                // tracks.push({ type: 'video', remove: true });
                const track = await getVideoTrackAddOrReplace({
                    transceivers,
                    type: 'screen',
                    sharingSourceId: propsChanges.sharingSourceId,
                    appSettings,
                    videoDevice: currentProps.videoDevice,
                    isTabletOrMobile,
                });
                replaceTracks.push(track);
            } else if (!currentProps.useVideo && currentProps.screenShared) {
                log.error('Screen already shared');
            } else if (currentProps.useVideo && currentProps.screenShared) {
                log.error('Unsupported condition');
            }
        } else if (propsChanges.screenShared === false) {
            // {useVideo:false, screenShared: false}
            if (currentProps.useVideo && currentProps.screenShared) {
                log.error('Unsupported condition');
            } else if (currentProps.useVideo && !currentProps.screenShared) {
                releaseVideo();
                // streamActions.muteVideo = true;
                tracks.push({ type: 'video', mid: video_mid, remove: true, recv: false });
            } else if (!currentProps.useVideo && currentProps.screenShared) {
                releaseScreen();
                // streamActions.muteVideo = true;
                tracks.push({ type: 'video', mid: video_mid, remove: true, recv: false });
            }
        } else {
            // {useVideo:false}
            if (currentProps.useVideo && currentProps.screenShared) {
                log.error('Unsupported condition');
            } else if (currentProps.useVideo && !currentProps.screenShared) {
                tracks.push({ type: 'video', mid: video_mid, remove: true, recv: false });
                releaseVideo();
                // streamActions.muteVideo = true;
            } else if (!currentProps.useVideo && currentProps.screenShared) {
                tracks.push({ type: 'screen', mid: video_mid, remove: true, recv: false });
                releaseScreen();
                // streamActions.muteVideo = true;
            } else if (!currentProps.useVideo && !currentProps.screenShared) {
                // FIXME
            }
        }
    } else {
        if (propsChanges.screenShared) {
            // {screenShared: true}
            if (currentProps.useVideo && currentProps.screenShared) {
                log.error('Unsupported condition');
            } else if (currentProps.useVideo && !currentProps.screenShared) {
                // tracks.push(/{ type: 'video', remove: true });
                const track = await getVideoTrackAddOrReplace({
                    transceivers,
                    type: 'screen',
                    appSettings,
                    videoDevice: currentProps.videoDevice,
                    isTabletOrMobile,
                });
                replaceTracks.push(track);
            } else if (!currentProps.useVideo && currentProps.screenShared) {
                log.error('Screen already shared');
            } else if (!currentProps.useVideo && !currentProps.screenShared) {
                tracks.push(
                    await getVideoTrackAddOrReplace({
                        transceivers,
                        type: 'screen',
                        appSettings,
                        videoDevice: currentProps.videoDevice,
                        isTabletOrMobile,
                    }),
                );
                // if (video_mid) {
                //     streamActions.unmuteVideo = true;
                // }
            }
        } else if (propsChanges.screenShared === false) {
            if (currentProps.useVideo && currentProps.screenShared) {
                log.error('Unsupported condition');
            } else if (currentProps.useVideo && !currentProps.screenShared) {
                log.error('Screen was not shared');
            } else if (!currentProps.useVideo && currentProps.screenShared) {
                tracks.push({ type: 'screen', mid: video_mid, remove: true, recv: false });
                releaseScreen();
                // streamActions.muteVideo = true;
            } else if (!currentProps.useVideo && !currentProps.screenShared) {
                log.error('Screen was not shared');
            }
        } else {
            // {}
            // Nothing to do?
        }
    }

    // calculate message
    message.request = 'configure';
    const sendAudio =
        propsChanges.useAudio === undefined ? currentProps.useAudio : propsChanges.useAudio;
    const sendVideo =
        propsChanges.useVideo === undefined && propsChanges.screenShared === undefined
            ? currentProps.useVideo || (currentProps.screenShared ?? false)
            : propsChanges.useVideo || (propsChanges.screenShared ?? false);

    // if (janusCtx.acodec && message.audio) {
    //     message.audiocodec = janusCtx.acodec;
    // }
    // if (janusCtx.vcodec && message.video) {
    //     message.videocodec = janusCtx.vcodec;
    // }
    if (audio_mid) {
        message.streams = [{ mid: audio_mid, send: sendAudio }];
    }
    if (video_mid) {
        message.streams = [{ mid: video_mid, send: sendVideo }];
    }
    return { tracks, streamActions, message, replaceTracks };
};
