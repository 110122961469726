import { enUS, ru } from 'date-fns/locale';

export const getLocale = (lang: string | undefined) => {
    if (!lang) {
        return enUS;
    }
    if (lang === 'ru') {
        return ru;
    }
    return enUS;
};
