import { connect } from 'react-redux';
import { TAppState } from 'types/app/TAppStore';
import { AccountPageMobileHeader } from './AccountPageMobileHeader';
import { IStateProps } from './types';

const mapStateToProps = (state: TAppState): IStateProps => ({
    version: state.applicationConfiguration.config.version,
});

export const AccountPageMobileHeaderConnected = connect(mapStateToProps)(AccountPageMobileHeader);
