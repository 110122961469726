import { t } from '@lingui/macro';
import { setNotificationForDisplayAction } from 'actions/applicationView/setNotificationForDisplayAction';
import { EPaths } from 'constants/EPaths';
import { applicationStore } from 'store/applicationStore';
import { isTargetPath } from 'utils/isTargetPath';
import { redirect } from 'utils/redirect';

const redirectFromLoginPage = (isAuthUser: boolean) => {
    if (isAuthUser) {
        const search = window.location.search;
        const params = new URLSearchParams(search);
        const path = params.get('path');
        if (path) {
            redirect(path);
        } else {
            redirect('/');
        }
    }
};

const showNotificationRouteIsForbidden = (text: string) => {
    const dispatch = applicationStore.dispatch;
    dispatch(
        setNotificationForDisplayAction({
            text,
            timeout: 3000,
            type: 'error',
            closeAction: () => dispatch(setNotificationForDisplayAction(null)),
        }),
    );
};

export const checkForbiddenRoutes = ({
    ldap,
    isAuthUser,
    requestGetUserResolved,
}: {
    ldap: boolean;
    isAuthUser: boolean;
    requestGetUserResolved: boolean;
}) => {
    if (isTargetPath(EPaths.REGISTRATION)) {
        if (isAuthUser) {
            showNotificationRouteIsForbidden(
                t({
                    id: 'You are authorized',
                    message: 'Вы авторизованы',
                }),
            );
            redirect('/');
            return;
        }
        if (ldap) {
            redirect('/login');
        }
    }
    if (isTargetPath(EPaths.LOGIN)) {
        redirectFromLoginPage(isAuthUser);
        return;
    }
    if (isTargetPath(EPaths.UPDATE_PROFILE)) {
        if (ldap) {
            redirect('/');
            return;
        }
    }

    if (
        (isTargetPath(EPaths.ACCOUNT) || isTargetPath(EPaths.CONFERENCE_REPORT)) &&
        requestGetUserResolved
    ) {
        if (!isAuthUser) {
            showNotificationRouteIsForbidden(
                t({
                    id: 'You are not authorized',
                    message: 'Вы не авторизованы',
                }),
            );
            redirect(`/login?path=${window.location.pathname}`);
            return;
        }
    }
};

export const shouldBeCheckedProfileOnRoute = (url: string): boolean => {
    return url !== '';
};
