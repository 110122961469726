import { IAudioVideoProps } from 'types/IAudioVideoProps';

export const calculateTalkingStatus = (
    currentAudioVideoProps: IAudioVideoProps,
    newAudioVideoProps: IAudioVideoProps,
    talking: boolean,
    isLocalMuted: boolean | undefined,
) => {
    let result = talking ?? false;
    const audioPermitted =
        newAudioVideoProps?.audioPermitted ?? currentAudioVideoProps.audioPermitted;
    const useAudio = newAudioVideoProps?.useAudio ?? currentAudioVideoProps.useAudio;

    if (!useAudio || !audioPermitted || isLocalMuted) {
        result = false;
    }

    return result;
};
