import { createAsyncThunk } from '@reduxjs/toolkit';
import { setSessionConferenceUsersAction } from 'actions/conferenceSession/setSessionConferenceUsersAction';
import { storeConferenceReportAction } from 'actions/conferencesReports/storeConferenceReportAction';
import { getConferenceReportApiCall } from 'api/conference/getConferenceReportApiCall';
import { getNetworkService } from 'api/getNetworkService';
import { getConferenceReportSelector } from 'selectors/getConferenceReportSelector';
import { getConferenceConnectInfoSelector } from 'selectors/getConferenceConnectInfoSelector';
import { getConferenceConnectInfoThunkAction } from 'thunk/conference/getConferenceConnectInfoThunkAction';
import { showNotifyThunkAction } from 'thunk/notify/showNotifyThunkAction';
import { getConferenceRecordsByIdThunkAction } from 'thunk/records/getConferenceRecordsByIdThunkAction';
import { IConferenceReport } from 'types/conferenceReport/IConferenceReport';
import { IConferenceReportParticipant } from 'types/conferenceReport/IConferenceReportParticipant';
import { IConferenceParticipant } from 'types/IConferenceParticipant';
import { IConferenceConnectInfo } from 'types/conferences/IConferenceConnectInfo';
import { TAppState } from 'types/app/TAppStore';
import { convertConferenceReportFromServer } from 'utils/conferenceReport/convertConferenceReportFromServer';
import { fillReportFromConferenceShortInfo } from 'utils/fillReportFromConferenceShortInfo';
import { getDefaultErrorNotification } from 'utils/getDefaultErrorNotification';
import { getMessagesListForChatHistoryThunkAction } from './getMessagesListForChatHistoryThunkAction';
import { EErrorCodes } from 'constants/EErrorCodes';
import { getErrorNotification } from 'utils/getErrorNotification';
import { t } from '@lingui/macro';

interface IPayload {
    conferenceId: string;
    sessionId: string;
}

export const loadConferenceReportThunkAction = createAsyncThunk(
    'loadConferenceReport',
    async (
        { conferenceId, sessionId }: IPayload,
        { dispatch, getState },
    ): Promise<undefined | IConferenceReport> => {
        const report = getConferenceReportSelector(getState() as TAppState, sessionId);
        if (report) {
            dispatch(
                getMessagesListForChatHistoryThunkAction({
                    conferenceReport: report,
                    conferenceId,
                }),
            );
            dispatch(
                setSessionConferenceUsersAction(getParticipantFromReport(report.participants)),
            );
            return report;
        }
        const response = await getConferenceReportApiCall(getNetworkService(), {
            conferenceId,
            sessionId,
        });

        if (response.success) {
            const report = convertConferenceReportFromServer(response.data);
            let conferenceConnectInfo = getConferenceConnectInfoSelector(
                getState() as TAppState,
                conferenceId,
            );
            dispatch(
                setSessionConferenceUsersAction(getParticipantFromReport(report.participants)),
            );
            if (!conferenceConnectInfo) {
                const response = await dispatch(
                    getConferenceConnectInfoThunkAction({ id: conferenceId }),
                );
                if (!response) {
                    dispatch(showNotifyThunkAction(getDefaultErrorNotification()));
                    return;
                }
                conferenceConnectInfo = response.payload as IConferenceConnectInfo;
            }
            await dispatch(getConferenceRecordsByIdThunkAction({ conferenceId, sessionId }));
            fillReportFromConferenceShortInfo(conferenceConnectInfo, report);

            dispatch(storeConferenceReportAction(report));
            dispatch(
                getMessagesListForChatHistoryThunkAction({
                    conferenceReport: report,
                    conferenceId,
                }),
            );
            return report;
        }
        if (response.errorCode === EErrorCodes.NOT_PERMITTED) {
            dispatch(
                showNotifyThunkAction(
                    getErrorNotification(
                        t({
                            id: 'notification.error.permission',
                            message: 'У вас нет доступа к данной конференции.',
                        }),
                    ),
                ),
            );
        } else {
            dispatch(showNotifyThunkAction(getDefaultErrorNotification()));
        }
    },
);

const getParticipantFromReport = (
    participants: IConferenceReportParticipant[],
): IConferenceParticipant[] => {
    return participants.map(({ name, etag, participantId, userId, sessions }) => {
        const resultParticipantId = !participantId
            ? !userId && sessions.length === 1
                ? sessions[0].participantId
                : ''
            : participantId;

        return {
            name,
            participantId: resultParticipantId,
            userId,
            etag,
            creator: undefined,
        };
    });
};
