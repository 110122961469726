import { setSessionConferenceUsersAction } from 'actions/conferenceSession/setSessionConferenceUsersAction';
import { connect } from 'react-redux';
import { getCurrentConferenceConnectInfoSelector } from 'selectors/getCurrentConferenceConnectInfoSelector';
import { getCurrentConferenceInfoSelector } from 'selectors/getCurrentConferenceInfoSelector';
import { exitFromConferenceThunkAction } from 'thunk/conference/exitFromConferenceThunkAction';
import { loadDataForConnectAndConferencePagesThunkAction } from 'thunk/conference/loadDataForConnectAndConferencePagesThunkAction';
import { allowedJoinConferenceThunkAction } from 'thunk/lobby/allowedJoinConferenceThunkAction';
import { subscribeToConferenceEventsActionThunk } from 'thunk/socket/subscribeToConferenceEventsThunkAction';
import { subscribeToConferencePermissionJoinThunkAction } from 'thunk/socket/subscribeToConferencePermissionJoinThunkAction';
import { IAppSettingsTyped } from 'types/app/IAppSettings';
import { TAppDispatch } from 'types/app/TAppDispatch';
import { TAppState } from 'types/app/TAppStore';
import { ConferencePage } from './ConferencePage';
import { IDispatchProps, IStateProps } from './types';
import { showNotifyThunkAction } from 'thunk/notify/showNotifyThunkAction';

const mapStateToProps = (state: TAppState): IStateProps => ({
    anonymous: state.auth.anonymous,
    conferenceConnectInfo: getCurrentConferenceConnectInfoSelector(state),
    conferenceInfo: getCurrentConferenceInfoSelector(state),
    currentConferenceId: state.conferenceSession.currentConferenceId,
    footerVisibility: true,
    isAudioNotification: (state.auth.appSettings as IAppSettingsTyped).audioNotification,
    isConferenceOwner: state.conferenceSession.currentUserIsOwner,
    janusIsReady: state.janus.videoRoomConnected,
    joinResponseReceived: state.lobby.allowedJoinConference === true,
    lobbyQueueToEnter: state.lobby.waitingList,
    rememberMeRequestStatus: state.auth.rememberMeRequestStatus,
    showAudioPermissionDialog: state.applicationView.showAudioPermissionDialog,
});

const mapDispatchToProps = (dispatch: TAppDispatch): IDispatchProps => ({
    subscribeToLobbyJoinPermission: (id) =>
        dispatch(subscribeToConferencePermissionJoinThunkAction(id)),
    sendPermissionToJoinConference: (data) => dispatch(allowedJoinConferenceThunkAction(data)),
    subscribeToConferenceEvents: (conferenceId) =>
        dispatch(subscribeToConferenceEventsActionThunk(conferenceId)),
    loadData: (id) =>
        dispatch(loadDataForConnectAndConferencePagesThunkAction({ id, page: 'conference' })),
    resetSessionParticipants: () => dispatch(setSessionConferenceUsersAction([])),
    closeJanusSession: () =>
        dispatch(exitFromConferenceThunkAction({ stopJanus: true, redirect: false })),
    exitFromConference: ({ withRedirect }) =>
        dispatch(exitFromConferenceThunkAction({redirect: withRedirect})),
    showNotification: (text) =>
        dispatch(
            showNotifyThunkAction({
                text,
                timeout: 5000,
                type: 'error',
            }),
        ),
});

export const ConferencePageConnected = connect(mapStateToProps, mapDispatchToProps)(ConferencePage);
