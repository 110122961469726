import { showAudioDialogPermissionAction } from 'actions/applicationView/showAudioDialogPermissionAction';
import { IMediaStreamCallback } from 'types/IMediaStreamCallback';
import { logger } from 'utils/logger';
import { dispatchAction, getApplicationState } from './../applicationStoreProvider';

const callbacksStorage: Record<'audio' | 'video', Record<string, () => void>> = {
    audio: {},
    video: {},
};

export const registerMediaStreamStartCallback = (streamCallback: IMediaStreamCallback) => {
    logger.debug(`Added callback for ${streamCallback.type} of ${streamCallback.participantId}`);
    callbacksStorage[streamCallback.type][streamCallback.participantId] = streamCallback.callback;
    if (!getApplicationState().applicationView.showAudioPermissionDialog) {
        dispatchAction(showAudioDialogPermissionAction(true));
    }
};

export const runMediaStreamStartCallbacks = () => {
    logger.debug('Run media callbacks');
    const failedAudio: Record<string, () => void> = {};
    const failedVideo: Record<string, () => void> = {};
    for (const key in callbacksStorage.audio) {
        const callback = callbacksStorage.audio[key];
        try {
            callback();
        } catch (e) {
            logger.debug(`Failed to start audio for ${key}`);
            failedAudio[key] = callback;
        }
    }
    for (const key in callbacksStorage.video) {
        const callback = callbacksStorage.video[key];
        try {
            callback();
        } catch (e) {
            logger.debug(`Failed to start video for ${key}`);
            failedVideo[key] = callback;
        }
    }
    callbacksStorage.audio = failedAudio;
    callbacksStorage.video = failedVideo;
    const allCleared =
        Object.keys(failedAudio).length === 0 && Object.keys(failedVideo).length === 0;
    const dialogIsVisible = getApplicationState().applicationView.showAudioPermissionDialog;
    if (dialogIsVisible && allCleared) {
        dispatchAction(showAudioDialogPermissionAction(false));
    }
    if (!dialogIsVisible && !allCleared) {
        dispatchAction(showAudioDialogPermissionAction(true));
    }
};

export const clearMediaStreamStartCallbacks = () => {
    logger.debug('Clear media callbacks');
    callbacksStorage.audio = {};
    callbacksStorage.video = {};
};
