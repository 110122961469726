import { FC } from 'react';
import { Link, useLocation } from 'react-router-dom';
import { Trans } from '@lingui/react';
import { redirect } from 'utils/redirect';
import { ExitIcon, SettingsIcon, UserIcon } from 'admin-app/components/common/icons';
import { classNames } from 'utils/classNames';
import Logo from 'assets/LogoBlack.svg';
import styles from './NavigationBar.module.scss';
import { EAdminPaths } from 'admin-app/constants/EAdminPaths';

export const NavigationBar: FC = () => {
    const location = useLocation();
    return (
        <nav className={styles.nav}>
            <div className={styles.nav__logo}>
                <Link to='/'>
                    <img src={Logo} alt='Logo' />
                </Link>
            </div>
            <div className={styles.nav__items}>
                <div
                    className={classNames([
                        styles.item,
                        location.pathname.includes('users') && styles.active,
                    ])}
                    onClick={() => redirect('/admin/users')}>
                    <UserIcon />
                    <Trans id='admin.users' />
                </div>
                <div
                    className={classNames([
                        styles.item,
                        location.pathname.includes('settings') && styles.active,
                    ])}
                    onClick={() => redirect(EAdminPaths.SETTINGS_LICENSE)}>
                    <SettingsIcon />
                    <Trans id='admin.settings' />
                </div>
            </div>

            <div className={classNames([styles.item, styles.exit])} onClick={() => redirect('/')}>
                <ExitIcon />
                <Trans id='admin.exit' />
            </div>
        </nav>
    );
};
