import { setDisplayLayoutAction } from 'actions/applicationView/setDisplayLayoutAction';
import { batch, connect } from 'react-redux';
import { TAppDispatch } from 'types/app/TAppDispatch';
import { TAppState } from 'types/app/TAppStore';
import { MobileLayoutSelect } from './MobileLayoutSelect';
import { IDispatchProps, IStateProps } from './types';
import { setParticipantsFullScreenAction } from 'actions/applicationView/setParticipantsFullScreenAction';

const mapStateToProps = (state: TAppState): IStateProps => ({
    layoutType: state.applicationView.displayLayout,
    participants: Object.values(state.conferenceSession.currentConferenceParticipants),
    selectedParticipantId: state.applicationView.fullScreenFrames[0],
});

const mapDispatchToProps = (dispatch: TAppDispatch): IDispatchProps => ({
    setLayoutType: ({ layoutType, participantId }) =>
        batch(() => {
            dispatch(setDisplayLayoutAction(layoutType));
            if (participantId) {
                dispatch(setParticipantsFullScreenAction({ local: [participantId] }));
            } else if (layoutType === 'default') {
                dispatch(setParticipantsFullScreenAction({ local: [] }));
            }
        }),
});

export const MobileLayoutsSelectConnected = connect(
    mapStateToProps,
    mapDispatchToProps,
)(MobileLayoutSelect);
