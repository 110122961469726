import { t, Trans } from '@lingui/macro';
import { Button } from 'components/common/Button';
import { Cross } from 'components/common/Cross';
import { Input } from 'components/common/Input';
import OutsideClick from 'components/common/OutsideClick';
import { useEffect } from 'react';
import { Form, FormRenderProps } from 'react-final-form';
import { getDefaultErrorNotification } from 'utils/getDefaultErrorNotification';
import styles from './EmailChangeDialog.module.scss';
import { IDispatchProps, IFormRenderProps, IOwnProps, IStateProps } from './types';

const noteLingui = (email: string) => (
    <Trans id={'email.change.note'}>
        Для смены адреса электронной почты привязанной к учетной записи MeeVox необходимо ввести
        новый адрес и пароль, после этого на почту <strong>{email}</strong> придет письмо со ссылкой
        на подтверждение смены эл. почты.
    </Trans>
);

const getLiguiSuccessText = () => t({ id: 'email.sent.success', message: 'Письмо отправлено' });

export const EmailChangeDialog: React.FC<IOwnProps & IDispatchProps & IStateProps> = ({
    changeEmail,
    showNotification,
    handleClose,
    currentEmail,
}) => {
    const onEscClick = (e: KeyboardEvent) => {
        if (e.code === 'Escape') {
            handleClose();
        }
    };

    useEffect(() => {
        document.addEventListener('keydown', onEscClick);
        return () => {
            document.removeEventListener('keydown', onEscClick);
        };
    });

    const onSubmit = async ({ newEmail, password }: IFormRenderProps) => {
        if (!password || !newEmail) {
            const errors: { [key: string]: string } = {};
            if (!password) errors.password = 'Required';
            if (!newEmail) errors.newEmail = 'Required';
            return errors;
        }

        const { payload: error } = await changeEmail({ email: newEmail, password });
        if (!error) {
            showNotification({ text: getLiguiSuccessText(), timeout: 3000, type: 'success' });
            handleClose();
        } else {
            showNotification({ ...getDefaultErrorNotification(), text: error.text });
            return {
                [error.field]: 'Error',
            };
        }
    };

    const render = ({ handleSubmit }: FormRenderProps<IFormRenderProps>) => (
        <div className={styles.wrapper}>
            <div className={styles.background} />
            <form onSubmit={handleSubmit} className={styles.form}>
                <OutsideClick onOutsideClick={handleClose}>
                    <Cross className={styles.cross} onClick={handleClose} />
                    <div className={styles.note}>{noteLingui(currentEmail)}</div>
                    <div className={styles.block}>
                        <div className={styles.label}>
                            <Trans id='new.email'>Новый адрес эл. почты</Trans>
                        </div>
                        <Input
                            name='newEmail'
                            type='email'
                            className={styles.input}
                            placeholder={t({
                                id: 'enter.new.email',
                                message: 'Введите новый адрес эл. почты',
                            })}
                        />
                    </div>
                    <div className={`${styles.block} ${styles.block_last}`}>
                        <div className={styles.label}>
                            <Trans id='Password' />
                        </div>
                        <Input
                            name='password'
                            type='password'
                            className={styles.input}
                            placeholder={t({
                                id: 'enter.password',
                                message: 'Введите пароль',
                            })}
                        />
                    </div>
                    <div className={styles.buttons}>
                        <Button
                            styleType='secondaryAction'
                            type='button'
                            caption={t({ id: 'Cancel', message: 'Отмена' })}
                            clickHandler={handleClose}
                            className={styles.buttons_cancel}
                        />
                        <Button
                            styleType='primaryAction'
                            caption={t({
                                id: 'email.change.button',
                                message: 'Изменить адрес эл. почты',
                            })}
                            type='submit'
                            className={styles.buttons_save}
                        />
                    </div>
                </OutsideClick>
            </form>
        </div>
    );

    return <Form onSubmit={onSubmit} render={render} />;
};
