import { t } from '@lingui/macro';
import { Trans } from '@lingui/react';
import { Checkbox } from 'components/common/Checkbox/Checkbox';
import { DatePickerConnected } from 'components/common/DatePicker';
import { EmailListEditorWrapper } from 'components/common/EmailListEditor/EmailListEditorWrapper';
import { Input } from 'components/common/Input/Input';
import { RadioButton } from 'components/common/RadioButton/RadioButton';
import { SelectField } from 'components/common/SelectField';
import { StatusLabel } from 'components/common/StatusLabel';
import { EPaths } from 'constants/EPaths';
import React, { useEffect, useRef } from 'react';
import { Field, Form, FormRenderProps } from 'react-final-form';
import { classNames } from 'utils/classNames';
import { getCurrentDate } from 'utils/dates/getCurrentDate';
import { getRadioFormatProps } from 'utils/forms/getRadioFormatProps';
import { redirect } from 'utils/redirect';
import { getTimeZonesNamesList } from 'utils/timeZones/getTimeZonesNamesList';
import { ConferenceAudioVideoSettings } from './ConferenceAudioVideoSettings/ConferenceAudioVideoSettings';
import { ConferenceRepeatBlock } from './ConferenceRepeatBlock';
import styles from './ConferenceScheduleSettingsForm.module.scss';
import { ConferenceSettingsBlock } from './ConferenceSettingsBlock/ConferenceSettingsBlock';
import { ConferenceSettingsBlockIndent } from './ConferenceSettingsBlockIndent/ConferenceSettingsBlockIndent';
import { ConferenceSettingsFooter } from './ConferenceSettingsFooter';
import { DropDownInput } from './DropDownInput/DropDownInput';
import { InputWithLength } from './InputWithLength/InputWithLength';
import { IDispatchProps, IFormProps, IOwnProps, IStateProps } from './types';
import {
    checkDurationHours,
    checkDurationMinutes,
    formatOnStartDateBlur,
    formatOnStartTimeBlur,
    getConferenceValues,
    getDateFormatProps,
    getDefaultFormProps,
    handleSubmitClick,
    tryConvertToDate,
    validate,
    validateOnChange,
    validateOnStartDateBlur,
    validateOnStartTimeBlur,
} from './utils';

export const ConferenceScheduleSettingsForm: React.FC<
    IStateProps & IDispatchProps & Partial<IOwnProps>
> = ({
    conferenceInfo,
    themeMaxLength,
    saveConference,
    goToConferencesList,
    updateConference,
    handleAfterSubmit,
    onModified,
    resetModified,
    showNotification,
    handleCancel,
}) => {
    const initialValues = conferenceInfo
        ? getConferenceValues(conferenceInfo)
        : getDefaultFormProps();
    const formRef = useRef(null);
    const conferenceIsStarted = conferenceInfo?.status === 'STARTED';

    const onSubmitClick = async (values: IFormProps) => {
        if (values.operation === 'save') {
            delete values.operation;
            if (values.description === undefined) {
                values.description = '';
            }
            const errors = await handleSubmitClick(
                values,
                { goToConferencesList, saveConference, showNotification, updateConference },
                conferenceInfo,
                handleAfterSubmit,
            );
            if (!errors) {
                resetModified();
            }
            return errors;
        }
        resetModified();
    };

    useEffect(() => {
        return () => {
            resetModified();
        };
    }, []);

    const timeForList = Array(48)
        .fill(null)
        .map((elem, index) => {
            const hour = Math.floor(index / 2).toString();
            const mins = index % 2 ? '30' : '00';
            return hour.length === 1 ? `0${hour}:${mins}` : `${hour}:${mins}`;
        });

    const render = ({
        handleSubmit,
        submitError,
        dirtySinceLastSubmit,
        values,
        dirty,
        errors,
        form,
        submitting,
        pristine,
    }: FormRenderProps<IFormProps>) => {
        setTimeout(() => onModified(dirty), 0);
        let warnings: { [id: string]: string } = {};
        const handleCancelClick = () => {
            if (!pristine) {
                form.restart();
            } else {
                resetModified();
                redirect(EPaths.UPCOMING_CONFERENCES);

                if (handleCancel) {
                    handleCancel();
                    return;
                }
            }
        };
        form.subscribe(
            ({ values }) => {
                warnings = validateOnChange(values);
            },
            { values: true },
        );
        return (
            <form onSubmit={handleSubmit} className={styles.form} ref={formRef}>
                <div className={styles.submitErrorWrapper}>
                    <StatusLabel text={submitError && !dirtySinceLastSubmit ? submitError : ''} />
                </div>
                <div>
                    <ConferenceSettingsBlock text={t({ id: 'Theme', message: 'Тема' })}>
                        <InputWithLength
                            name='topic'
                            placeholder={t({
                                id: 'Enter the conference theme',
                                message: 'Введите тему конференции',
                            })}
                            className={styles.inputStyle}
                            autoFocus={true}
                            errorText={errors?.topic}
                            maxLength={themeMaxLength}
                        />
                    </ConferenceSettingsBlock>
                    <ConferenceSettingsBlock text={t({ id: 'Description', message: 'Описание' })}>
                        <Input
                            name='description'
                            placeholder={t({
                                id: 'Enter the conference description',
                                message: 'Введите описание конференции',
                            })}
                            className={styles.inputStyle}
                            errorText={errors?.description}
                        />
                    </ConferenceSettingsBlock>
                </div>
                <ConferenceSettingsBlockIndent />
                <ConferenceSettingsBlock
                    text={t({ id: 'When', message: 'Когда' })}
                    isComplexField={true}
                    textClassName='text_start'>
                    {!values.recurringData && (
                        <>
                            <Input
                                name='startDate'
                                placeholder={t({
                                    id: 'dd.mm.yyyy',
                                    message: 'дд.мм.гггг',
                                })}
                                statusClassName={styles.inputStyle__statusText}
                                className={classNames([styles.inputStyle, styles.inputStyle_date])}
                                errorWithoutConditions={!!errors?.startDate}
                                errorText={warnings.startDate}
                                errorType={warnings.startDate ? 'warning' : 'error'}
                                type='text'
                                onBlurHandlers={{
                                    validateOnBlur: validateOnStartDateBlur,
                                    formatOnBlur: formatOnStartDateBlur,
                                }}
                                disabled={conferenceIsStarted}
                            />
                            <DatePickerConnected
                                name='startDate'
                                {...getDateFormatProps()}
                                currentValue={tryConvertToDate(values.startDate)}
                                minDate={getCurrentDate()}
                                disabled={conferenceIsStarted}
                            />
                            <DropDownInput
                                name='startTime'
                                placeholder={t({ id: 'hh:mm', message: 'чч:мм' })}
                                className={classNames([styles.inputStyle, styles.inputStyle_time])}
                                errors={!!errors?.startTime}
                                type='text'
                                disabled={conferenceIsStarted}
                                listItems={timeForList}
                                onChanged={(name: any, value: any) => form.change(name, value)}
                                getValue={(name: any) => form.getFieldState(name)}
                                validateOnBlur={validateOnStartTimeBlur}
                                formatting={formatOnStartTimeBlur}
                                initialValue={initialValues.startTime as string}
                            />
                        </>
                    )}
                    <div className={styles.form__desktopField}>
                        {conferenceInfo?.seriesId === undefined && (
                            <Field name='recurringData'>
                                {() => (
                                    <ConferenceRepeatBlock
                                        updateField={form.change}
                                        recurringData={values.recurringData}
                                        specifiedDate={values.startDate}
                                        specifiedTime={values.startTime}
                                        popupContainer={formRef.current}
                                    />
                                )}
                            </Field>
                        )}
                    </div>
                </ConferenceSettingsBlock>
                <ConferenceSettingsBlock
                    text={t({ id: 'Repeat' })}
                    isOnSmallScreen={true}
                    textClassName='text_start'>
                    {conferenceInfo?.seriesId === undefined && (
                        <Field name='recurringData'>
                            {() => (
                                <ConferenceRepeatBlock
                                    updateField={form.change}
                                    recurringData={values.recurringData}
                                    specifiedDate={values.startDate}
                                    specifiedTime={values.startTime}
                                    popupContainer={formRef.current}
                                />
                            )}
                        </Field>
                    )}
                </ConferenceSettingsBlock>
                <ConferenceSettingsBlock
                    isComplexField={true}
                    text={t({ id: 'Duration', message: 'Продолжительность' })}>
                    <Input
                        name='durationHours'
                        parse={checkDurationHours}
                        className={classNames([styles.inputStyle, styles.inputStyle_duration])}
                        errorWithoutConditions={errors?.durationHours}
                    />
                    <span>{t({ id: 'h', message: 'ч' })}</span>
                    <Input
                        name='durationMinutes'
                        className={classNames([styles.inputStyle, styles.inputStyle_duration])}
                        parse={checkDurationMinutes}
                        errorWithoutConditions={errors?.durationMinutes}
                    />
                    <span>{t({ id: 'min', message: 'мин' })}</span>
                </ConferenceSettingsBlock>
                <ConferenceSettingsBlock
                    text={t({ id: 'Time zone', message: 'Часовой пояс' })}
                    classNameFields={styles.column}>
                    <div className={styles.timeZone}>
                        <SelectField
                            className={styles.select}
                            items={getTimeZonesNamesList()}
                            name='timeZone'
                        />
                    </div>
                </ConferenceSettingsBlock>
                <ConferenceSettingsBlock
                    text={t({ id: 'scheduling.invites.id', message: 'Приглашения' })}
                    textClassName='text_start'
                    classNameFields={styles.column}>
                    <EmailListEditorWrapper
                        name='invitedUsers'
                        tip={t({
                            id: 'invites.info.text.id',
                        })}
                    />
                </ConferenceSettingsBlock>

                <ConferenceSettingsBlockIndent />
                <ConferenceSettingsBlock text={t({ message: 'Безопасность', id: 'Security' })}>
                    <Checkbox
                        name='waitCreator'
                        text={t({
                            message: 'Ожидание организатора',
                            id: 'Waiting creator',
                        })}
                    />
                </ConferenceSettingsBlock>
                <ConferenceSettingsBlock>
                    <div className={styles.form__notes}>
                        <Trans
                            message='Участники смогут подключиться к конференции только после того как к ней подключится ее организатор'
                            id='Participants will be able to connect to the conference only after the organizer connects to it'
                        />
                    </div>
                </ConferenceSettingsBlock>
                <ConferenceSettingsBlock>
                    <Checkbox
                        name='lobby'
                        text={t({ message: 'Зал ожидания', id: 'Waiting room' })}
                    />
                </ConferenceSettingsBlock>
                <ConferenceSettingsBlock>
                    <div className={styles.form__notes}>
                        <Trans
                            message='В конференцию могут входить только пользователи, утвержденные организатором'
                            id='Only users approved by the organizer can enter the conference'
                        />
                    </div>
                </ConferenceSettingsBlock>
                <ConferenceSettingsBlock>
                    <Checkbox
                        name='registeredOnly'
                        text={t({
                            message: 'Только для зарегистрированных пользователей',
                            id: 'Only for registered users',
                        })}
                    />
                </ConferenceSettingsBlock>
                <ConferenceSettingsBlock>
                    <div className={styles.form__notes}>
                        <Trans
                            message='В конференцию могут входить только зарегистрированные пользователи'
                            id='Only registered users can join the conference'
                        />
                    </div>
                </ConferenceSettingsBlock>
                <ConferenceSettingsBlockIndent />
                <ConferenceSettingsBlock
                    text={t({
                        message: 'Видео',
                        id: 'Video',
                    })}>
                    <div className={styles.text}>
                        {t({
                            message: 'Организатор',
                            id: 'Organizer',
                        })}
                    </div>
                    <div className={styles.radioButton}>
                        <RadioButton
                            name='videoOwner'
                            value='true'
                            id='videoOwner1'
                            {...getRadioFormatProps()}
                            text={t({ message: 'Вкл.', id: 'On' })}
                        />
                    </div>
                    <div className={styles.radioButton}>
                        <RadioButton
                            name='videoOwner'
                            value='false'
                            id='videoOwner0'
                            {...getRadioFormatProps()}
                            text={t({ message: 'Выкл.', id: 'Off' })}
                        />
                    </div>
                </ConferenceSettingsBlock>
                <ConferenceSettingsBlock>
                    <ConferenceAudioVideoSettings name='participantsVideoPermission' />
                </ConferenceSettingsBlock>
                <ConferenceSettingsBlock
                    text={t({
                        message: 'Микрофон',
                        id: 'Audio',
                    })}>
                    <ConferenceAudioVideoSettings name='participantsAudioPermission' />
                </ConferenceSettingsBlock>
                <ConferenceSettingsBlockIndent />
                <ConferenceSettingsBlock>
                    <Checkbox
                        name='recording'
                        text={t({
                            message: 'Записывать конференцию',
                            id: 'Record a conference',
                        })}
                    />
                </ConferenceSettingsBlock>
                <ConferenceSettingsBlockIndent />
                <ConferenceSettingsFooter
                    submitting={submitting}
                    pristine={pristine}
                    errors={errors}
                    form={form}
                    handleCancelClick={handleCancelClick}
                />
            </form>
        );
    };

    return (
        <Form
            onSubmit={onSubmitClick}
            validate={(values) => validate(values, conferenceInfo)}
            render={render}
            initialValues={initialValues}
        />
    );
};
