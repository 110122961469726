import { t } from '@lingui/macro';
import { appHistory } from 'appHistory';
import { ConferenceFooterConnectedWithDevices } from 'components/ConferenceFooter';
import { LobbyAdminDialog } from 'pages/ConferencePage/LobbyAdminDialog/LobbyAdminDialog';
import React, { useEffect, useState } from 'react';
import { clearSubscription } from 'services/socketService/socketService';
import { IConferenceInfo } from 'types/conferences/IConferenceInfo';
import {
    getBrowserBtnBackWasClicked,
    setBrowserBtnBackWasClicked,
} from 'utils/browserBtnBackWasClicked';
import { addCancelTabCloseEvent, removeCancelTabCloseEvent } from 'utils/cancelTabCloseEventManage';
import { setEscCloseDropdown } from 'utils/escCloseDropdown';
import { ConferencePageTemplateConnected } from '../templates/ConferencePageTemplate';
import styles from './ConferencePage.module.scss';
import { ConferenceSettingsDialog } from './ConferenceSettingsDialog';
import { MediaPermissionDialogConnected } from './MediaPermissionDialog';
import { ParticipantsLayoutConnected } from './ParticipantsLayout';
import { ShowLogConnected } from './ShowLog';
import { IDispatchProps, IOwnProps, IStateProps } from './types';

export const ConferencePage: React.FC<IOwnProps & IStateProps & IDispatchProps> = ({
    closeJanusSession,
    conferenceConnectInfo,
    conferenceInfo,
    exitFromConference,
    footerVisibility,
    id,
    isAudioNotification,
    isConferenceOwner,
    janusIsReady,
    joinResponseReceived,
    loadData,
    lobbyQueueToEnter,
    rememberMeRequestStatus,
    resetSessionParticipants,
    sendPermissionToJoinConference,
    showAudioPermissionDialog,
    showNotification,
    subscribeToConferenceEvents,
    subscribeToLobbyJoinPermission,
}) => {
    const [settingsOpen, setSettingsOpen] = useState(false);

    const closeDropDown = () => {
        setSettingsOpen(false);
    };

    useEffect(() => {
        if (rememberMeRequestStatus === 'success' || rememberMeRequestStatus === 'error') {
            loadData(id);
        }
    }, [rememberMeRequestStatus]);

    useEffect(() => {
        setEscCloseDropdown(settingsOpen);
    }, [settingsOpen]);

    useEffect(() => {
        const historyListenerClear = appHistory.listen(() => {
            if (getBrowserBtnBackWasClicked()) {
                exitFromConference({ withRedirect: false });
                historyListenerClear();
            } else {
                setBrowserBtnBackWasClicked(true);
            }
        });
        const unloadHandler = () => closeJanusSession();
        window.addEventListener('unload', unloadHandler);
        return () => {
            window.removeEventListener('unload', unloadHandler);
            setTimeout(() => {
                historyListenerClear();
            }, 200);
            resetSessionParticipants();
        };
    }, []);

    useEffect(() => {
        if (conferenceConnectInfo && isConferenceOwner && conferenceConnectInfo.lobby) {
            subscribeToLobbyJoinPermission(id);
        }
        if (conferenceConnectInfo?.status === 'DELETED') {
            showNotification(t({ id: 'pastConference.buttonAgain.disabled' }));
            exitFromConference({ withRedirect: true });
        }
        return () => clearSubscription('subscribeToConferencePermissionJoin');
    }, [conferenceConnectInfo]);

    useEffect(() => {
        if (joinResponseReceived && janusIsReady) {
            subscribeToConferenceEvents(id);
        }
    }, [joinResponseReceived, janusIsReady]);

    useEffect(() => {
        addCancelTabCloseEvent();
        return removeCancelTabCloseEvent;
    }, []);

    const lobbyRequestsRender = () =>
        lobbyQueueToEnter.map((participant) => (
            <LobbyAdminDialog
                username={participant.name}
                key={participant.participantId}
                isAudioNotification={isAudioNotification}
                handleAccept={() =>
                    sendPermissionToJoinConference({
                        conferenceId: id,
                        participantId: participant.participantId,
                        name: participant.name,
                        status: 'allow',
                    })
                }
                handleDenied={() =>
                    sendPermissionToJoinConference({
                        conferenceId: id,
                        participantId: participant.participantId,
                        name: participant.name,
                        status: 'forbid',
                    })
                }
            />
        ));
    return (
        <ConferencePageTemplateConnected
            conferenceConnectInfo={conferenceConnectInfo}
            handleSettingsClick={() => setSettingsOpen(!settingsOpen)}
            showViewBtn={true}
            enableConferenceInfoPanel={true}>
            {settingsOpen && (
                <ConferenceSettingsDialog
                    conferenceInfo={conferenceInfo as IConferenceInfo}
                    handleAfterSubmit={closeDropDown}
                    handleClose={closeDropDown}
                />
            )}
            <div className={styles.lobbyNotifications}>
                {showAudioPermissionDialog && <MediaPermissionDialogConnected />}
                {lobbyRequestsRender()}
            </div>
            <ParticipantsLayoutConnected key={id} />
            <ShowLogConnected />
            {footerVisibility && (
                <div className={styles.footer}>
                    <ConferenceFooterConnectedWithDevices />
                </div>
            )}
        </ConferencePageTemplateConnected>
    );
};
