import { INetworkService } from 'types/api/INetworkService';
import { IScheduledConferenceData } from 'types/IScheduledConferenceData';

export const updateConferenceInfoApiCall = (
    networkService: INetworkService,
    { id, ...data }: IScheduledConferenceData,
) =>
    networkService.put({
        url: `/api/conferences/${id}`,
        data,
    });
