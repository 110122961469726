import { Trans } from '@lingui/react';
import { PageTemplate } from '../templates/PageTemplate';
import { PersonalInformationFormConnected } from './PersonalInformationForm';
import styles from './PersonalInformationPage.module.scss';

export const PersonalInformationPage: React.FC = () => (
    <PageTemplate showLoggedMenu={false}>
        <div className={styles.title}>
            <Trans id='Personal information' message='Личные данные' />
        </div>
        <div className={styles.form}>
            <PersonalInformationFormConnected />
        </div>
    </PageTemplate>
);
