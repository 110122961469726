import { Trans } from '@lingui/react';
import CalendarImg from 'assets/Calendar.svg';
import { IDispatchProps, IOwnProps } from './types';
import styles from './PlanConferenceButton.module.scss';

export const PlanConferenceButton: React.FC<IOwnProps & IDispatchProps> = ({ planNewConference, isShortText }) => (
    <a className={styles.planeConfGroup}
        onClick={planNewConference}
        data-qa="scheduleConferenceButton">
        <img src={CalendarImg} alt="calendar" />
        <span>
            { isShortText ? (
                <Trans message='Запланировать' id='Schedule'/>
            ) : (
                <Trans message='Запланировать конференцию' id='Schedule a conference'/>
            )}
        </span>
    </a>
);
