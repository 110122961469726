import { connect } from 'react-redux';
import { requestPasswordRecoveryThunkAction } from 'thunk/auth/requestPasswordRecoveryThunkAction';
import { TAppDispatch } from 'types/app/TAppDispatch';
import { TAppState } from 'types/app/TAppStore';
import { RestorePasswordForm } from './RestorePasswordForm';
import { IDispatchProps, IStateProps } from './types';

const mapStateToProps = (state: TAppState): IStateProps => ({
    ldap: state.applicationConfiguration.config.ldap, 
});

const mapDispatchToProps = (dispatch: TAppDispatch): IDispatchProps => ({
    restorePassword: (email) => dispatch(requestPasswordRecoveryThunkAction({ email })),
});

export const RestorePasswordFormConnected = connect(
    mapStateToProps,
    mapDispatchToProps,
)(RestorePasswordForm);
