import { useLocation } from 'react-router';

export const showRecordByDefault = (): boolean => {
    const location = useLocation();
    const search = location.search;
    const params = new URLSearchParams(search);
    const showRecordPlayer = params.get('showRecordPlayer');
    return !showRecordPlayer ? false : showRecordPlayer == 'true' ? true : false;
};

export const getRecordPreviewUrl = (conferenceId: string, sessionId: string, recordId: string) =>
    `/api/conferences/${conferenceId}/sessions/${sessionId}/records/${recordId}/preview`;
