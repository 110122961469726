import { connect } from 'react-redux';
import { AdminPage } from 'admin-app/pages/Admin/AdminPage';
import { TAppState } from 'types/app/TAppStore';
import { IStateProps } from './types';

export const mapStateToProps = (state: TAppState): IStateProps => ({
    user: state.auth.currentUser,
    gettingUserPending: !!state.applicationView.pendingOperations['gettingUserProfileData'],
});

export const AdminPageConnected = connect(mapStateToProps)(AdminPage);
