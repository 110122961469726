import { Input } from 'components/common/Input';
import { IOwnProps as InputProps } from 'components/common/Input/types';
import { useEffect, useRef, useState } from 'react';
import styles from './InputWithLength.module.scss';

type IOwnProps = InputProps & Required<Pick<InputProps, 'maxLength'>>;

const blinkTime = 1500;

export const InputWithLength: React.FC<IOwnProps> = (props) => {
    const inputRef = useRef<HTMLInputElement | null>(null);
    const [isFocused, setFocused] = useState(false);
    const [isBlinked, setBlinked] = useState(false);
    const blinkTimer = useRef<NodeJS.Timeout>();
    const len = inputRef.current ? inputRef.current.value.length : 0;
    useEffect(() => {
        if (len < props.maxLength) {
            setBlinked(false);
            return;
        }
        if (blinkTimer.current) {
            clearTimeout(blinkTimer.current);
        }
        setBlinked(true);
        blinkTimer.current = setTimeout(() => {
            setBlinked(false);
            blinkTimer.current = undefined;
        }, blinkTime);
    }, [len]);
    return (
        <div
            data-input-wrapper
            className={getClassName(isFocused, isBlinked)}
            onFocus={() => setFocused(true)}
            onBlur={() => setFocused(false)}>
            <div className={styles.count}>
                {inputRef.current?.value.length || 0}/{props.maxLength}
            </div>
            <Input {...props} ref={inputRef} />
        </div>
    );
};

const getClassName = (isFocused: boolean, isBlinked: boolean) => {
    const cName = [styles.wrap];
    if (isFocused) {
        cName.push(styles.isFocused);
    }
    if (isBlinked) {
        cName.push(styles.isBlinked);
    }
    return cName.join(' ');
};
