import { IUserDevice } from 'types/IUserDevice';

let inputDevices: IUserDevice[];

export const storeInitialDevices = (devices: IUserDevice[]) => {
    inputDevices = devices.filter(device => (device.kind === 'audioinput' || device.kind === 'videoinput') && device.deviceId !== 'default');
}

export const getDeviceChanges = (newDevicesList: IUserDevice[]) => {
    const newInputDevices = newDevicesList.filter(device => {
        return (device.kind === 'audioinput' || device.kind === 'videoinput') && device.deviceId !== 'default'
    });

    const getDiffDevices = (
        type: 'disconnected' | 'connected',
        deviceArr1: IUserDevice[],
        deviceArr2: IUserDevice[],
    ) => {
        const device = deviceArr1.find(device1 => !deviceArr2.find((device2) => device2.deviceId === device1.deviceId))
        return  device ? { type, device } : undefined
    }
    const diffDevice = getDiffDevices('connected', newInputDevices, inputDevices) ||
        getDiffDevices('disconnected', inputDevices, newInputDevices)

    inputDevices = newInputDevices;

    if (diffDevice) {
        return diffDevice
    }
}
