import { DesktopNotification } from './DesktopNotification';
import React, { useEffect, useLayoutEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import { MobileNotification } from './MobileNotification';
import styles from './NotificationPanel.module.scss';
import { IDispatchProps, IStateProps } from './types';
import { INotification } from 'types/INotification';

export const NotificationPanel: React.FC<IStateProps & IDispatchProps> = ({
    notification,
    mobile,
    removeNotification,
}) => {
    const { pathname } = useLocation();
    const [resultNotification, setResultNotification] = useState(notification);
    useLayoutEffect(() => {
        if (notification !== resultNotification) {
            setResultNotification(notification);
        }
    }, [notification]);
    useEffect(() => {
        if (!resultNotification) {
            return;
        }
        if (resultNotification.checkForHiding && resultNotification.checkForHiding()) {
            removeNotification();
            return;
        }
        if (resultNotification.dynamicCheck) {
            resultNotification.dynamicCheck(resultNotification, setResultNotification);
        }
    }, [resultNotification]);
    if (!resultNotification) {
        return null;
    }
    if (mobile) {
        return (
            <div className={styles.root_mobile}>
                <MobileNotification notification={resultNotification} />
            </div>
        );
    }
    return (
        <div className={styles.root}>
            <div>
                <DesktopNotification
                    notification={getDesktopNotification(resultNotification, pathname)}
                />
            </div>
        </div>
    );
};

const getDesktopNotification = (notification: INotification, pathname: string): INotification => {
    if (notification.type === 'notify' && (pathname === '/' || pathname.indexOf('/account') > -1)) {
        // On home page show notification with green color - not white
        return { ...notification, type: 'notify_green' };
    }
    return notification;
};
