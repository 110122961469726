import { appHistory } from 'appHistory';
import { connect } from 'react-redux';
import { updateProfileInfoThunkAction } from 'thunk/profile/updateProfileInfoThunkAction';
import { TAppDispatch } from 'types/app/TAppDispatch';
import { TAppState } from 'types/app/TAppStore';
import { PersonalInformationForm } from './PersonalInformationForm';
import { IDispatchProps, IStateProps } from './types';

const mapStateToProps = (state: TAppState): IStateProps => ({
    user: state.auth.currentUser,
    notLoggedIn: state.auth.rememberMeRequestStatus === 'error',
});

const mapDispatchToProps = (dispatch: TAppDispatch): IDispatchProps => ({
    goToHomePage: () => appHistory.push('/'),
    save: (data) => dispatch(updateProfileInfoThunkAction(data)).unwrap(),
});

export const PersonalInformationFormConnected = connect(
    mapStateToProps,
    mapDispatchToProps,
)(PersonalInformationForm);
