import { AnyAction, EnhancedStore, Middleware } from '@reduxjs/toolkit';
import { TAppState } from 'types/app/TAppStore';

type IStoreType = EnhancedStore<
    TAppState,
    AnyAction,
    ReadonlyArray<Middleware<unknown, TAppState>>
>;

let registeredStore: IStoreType | undefined = undefined;

export const registerApplicationStore = (store: IStoreType) => {
    registeredStore = store;
};

export const getApplicationState = (): TAppState => {
    if (registeredStore) {
        return registeredStore.getState();
    }
    throw new Error('Store was not initialized');
};

export const dispatchAction = (action: any) => {
    if (registeredStore) {
        return registeredStore.dispatch(action);
    }
    throw new Error('Store was not initialized');
};
