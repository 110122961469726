import { createAsyncThunk } from '@reduxjs/toolkit';
import { setJanusStreamPropsAction } from 'actions/janus/setJanusStreamPropsAction';
import { getCurrentConferenceConnectInfoSelector } from 'selectors/getCurrentConferenceConnectInfoSelector';
import { getCurrentConferenceInfoSelector } from 'selectors/getCurrentConferenceInfoSelector';
import { showNotifyThunkAction } from 'thunk/notify/showNotifyThunkAction';
import { TAppState } from 'types/app/TAppStore';
import { getDefaultErrorNotification } from 'utils/getDefaultErrorNotification';
import { calculateAudioVideoProps } from 'utils/janus/calculateAudioVideoProps';
import { startConferenceJanus } from 'utils/janus/startConferenceJanus';
import { setDocumentTitle } from 'utils/setDocumentTitle';
import { TAppDispatch } from 'types/app/TAppDispatch';
import { getConferenceConnectInfoThunkAction } from './getConferenceConnectInfoThunkAction';
import { getPreviousCurrentConferenceDeviceValuesThunkAction } from './previousConferenceDeviceValues/getPreviousCurrentConferenceDeviceValuesThunkAction';
import { pingSocketConnectionThunkAction } from 'thunk/socket/pingSocketConnectionThunkAction';

export const startJanusConferenceThunkAction = createAsyncThunk(
    'startJanusConference',
    async (_: void, { dispatch, getState }) => {
        const {
            conferenceSession: { currentUserIsOwner, currentSessionId, currentConferenceId },
            conferences: { conferencesConnectInfo },
            auth: { appSettings },
        } = getState() as TAppState;
        await dispatch(
            getPreviousCurrentConferenceDeviceValuesThunkAction(
                appSettings
                    ? {
                        useVideo: appSettings.ownVideoOnConnect,
                        useAudio: appSettings.ownAudioOnConnect,
                    }
                    : null,
            ),
        );
        let conferenceConnectInfo = conferencesConnectInfo[currentConferenceId];
        if (!conferenceConnectInfo.janusUrl) {
            const newConnectInfo = await dispatch(
                getConferenceConnectInfoThunkAction({ id: currentConferenceId, forceUpdate: true }),
            ).unwrap();
            if (!newConnectInfo) {
                dispatch(showNotifyThunkAction(getDefaultErrorNotification()));
                return;
            }
            conferenceConnectInfo = newConnectInfo;
        }

        if (currentSessionId) {
            await startConferenceJanus(currentSessionId, dispatch as TAppDispatch, getState as () => TAppState);
            dispatch(pingSocketConnectionThunkAction(currentConferenceId));
        }
        const {
            janus: { localStreamProps: audioVideoProps },
        } = getState() as TAppState;
        if (conferenceConnectInfo) {
            dispatch(
                setJanusStreamPropsAction(
                    calculateAudioVideoProps(
                        conferenceConnectInfo,
                        currentUserIsOwner,
                        audioVideoProps,
                    ),
                ),
            );
        }
        const state = getState() as TAppState;
        const title =
            getCurrentConferenceConnectInfoSelector(state)?.name ||
            getCurrentConferenceInfoSelector(state)?.name;
        if (title) {
            setDocumentTitle(title);
        }
    },
);
