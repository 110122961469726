interface IArgs {
    id?: string;
    etag?: string;
    size: string;
}
export const getAvatarUrl = ({ id: userId, etag, size }: IArgs) => {
    if (!userId) {
        return '';
    }
    const avatarPathName = `api/avatar/${userId}`;
    const url = new window.URL(avatarPathName, window.location.origin);
    if (etag !== undefined) {
        url.searchParams.append('eTag', etag);
    }
    url.searchParams.append('s', size);
    return url.toString();
};
