import { MobileWrapperConnected } from 'components/common/MobileWrapper';
import { ConferenceScheduleSettingsFormConnected } from './ConferenceScheduleSettingsFormConnected';
import { ConferenceScheduleSettingsFormMobileConnected } from './ConferenceScheduleSettingsFormMobileConnected';
import { IOwnProps } from './types';

export const ConferenceScheduleSettingsFormResponsive: React.FC<Partial<IOwnProps>> = (props) => (
    <MobileWrapperConnected
        renderDesktop={() => <ConferenceScheduleSettingsFormConnected {...props} />}
        renderMobile={() => <ConferenceScheduleSettingsFormMobileConnected {...props} />}
    />
);
