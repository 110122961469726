import { getBrowserTimeZone } from './getBrowserTimeZone';
import { getTimeZonesArray } from './getTimeZonesArray';

const timeZones = getTimeZonesArray();
export const getTimeZoneByName = (timeZoneName: string): string => {
    const foundTimeZone = timeZones.filter((tz) => tz.label === timeZoneName)[0];
    if (foundTimeZone) {
        return foundTimeZone.value;
    }
    return getBrowserTimeZone();
};
