import { ESocketRequestStatus } from 'constants/ESocketRequestStatus';
import { getSocketServiceState, setSocketConnectionStatus } from './socketService';
import { unsubscribeFromAllTopics } from './unsubscribeFromAllTopics';

export const disconnectFromSocket = () => {
    const { stompClient, socket } = getSocketServiceState();
    unsubscribeFromAllTopics();
    if (stompClient) {
        stompClient.disconnect(() => {
            if (socket) {
                socket.close();
            }
            setSocketConnectionStatus(ESocketRequestStatus.FAILURE);
        });
    }
};
