import { PropsWithChildren } from 'react';
import { IOwnProps } from './types';
import { classNames } from 'utils/classNames';
import styles from './Tabs.module.scss'

export const Tabs = <T,>({
    items,
    propsValue = items[0].value,
    onChange,
    className,
}: PropsWithChildren<IOwnProps<T>>) => (
        <ul className={classNames([className, styles.tabs])}>
            {items.map(({ value, label }) => (
                <li
                    className={classNames([
                        propsValue === value ? styles.tabs__item_active : '',
                        styles.tabs__item,
                    ])}
                    key={`tab-${value}`}
                    onClick={() => onChange(value)}
                >
                    {label}
                </li>
            ),
            )}
        </ul>
    )
