import { IOwnProps } from './types';
import { Button } from 'components/common/Button';
import styles from './Screen.module.scss';
import { classNames } from 'utils/classNames';

export const Screen: React.FC<IOwnProps> = ({
    preview,
    label,
    isActive,
    handleClick,
}) => {
    return (
        <div
            className={classNames([
                styles.container,
                styles.container_hover,
                isActive ? styles.container_active : '',
            ])}>
            <Button
                className={styles.container__button}
                styleType='common'
                clickHandler={handleClick}>
                <img src={preview} />
                <span className={styles.container__label}>{label}</span>
            </Button>
        </div>
    );
};
