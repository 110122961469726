import { t } from '@lingui/macro';
import { useEffect, useState, CSSProperties } from 'react';
import styles from './Avatar.module.scss';
import { IOwnProps } from './types';

export const Avatar: React.FC<IOwnProps> = ({
    style,
    imageStyle = {},
    initials = '',
    clickHandler,
    mouseEnterHandler,
    mouseLeaveHandler,
    image,
    guest,
    active = false,
    userBgColor,
    userColor,
}) => {
    const [imageSrc, setImageSrc] = useState<string | undefined>(image);
    useEffect(() => {
        setImageSrc(image);
    }, [image]);
    const avatarStyle = styles.avatar + (active ? ' ' + styles.avatar_active : '');
    if (imageSrc) {
        return (
            <div
                className={avatarStyle}
                style={style}
                onClick={clickHandler}
                onMouseEnter={mouseEnterHandler}
                onMouseLeave={mouseLeaveHandler}>
                <div
                    className={styles.image}
                    style={{ backgroundImage: `url("${image}")`, ...imageStyle }}></div>
                <img
                    src={imageSrc}
                    style={{ display: 'none' }}
                    onError={() => setImageSrc(undefined)}
                />
            </div>
        );
    }
    const initialsStyle: CSSProperties = {};
    if (userBgColor) {
        initialsStyle.backgroundColor = userBgColor;
    }
    if (userColor) {
        initialsStyle.color = userColor;
    }
    return (
        <div
            className={avatarStyle}
            style={style}
            onClick={clickHandler}
            onMouseEnter={mouseEnterHandler}
            onMouseLeave={mouseLeaveHandler}>
            <div className={styles.initials} style={initialsStyle}>
                {initials}
                {guest && (
                    <span className={styles.guest}>
                        &nbsp;{t({ id: 'Guest', message: 'Гость' })}
                    </span>
                )}
            </div>
        </div>
    );
};
