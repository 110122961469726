import { connect } from 'react-redux';
import { getCurrentConferenceConnectInfoSelector } from 'selectors/getCurrentConferenceConnectInfoSelector';
import { copyToClipboardThunkAction } from 'thunk/navigator/copyToClipboardThunkAction';
import { TAppDispatch } from 'types/app/TAppDispatch';
import { TAppState } from 'types/app/TAppStore';
import { ConferenceLinkDialog } from './ConferenceLinkDialog';
import { IDispatchProps, IStateProps } from './types';

const mapStateToProps = (state: TAppState): IStateProps => ({
    conferenceConnectInfo: getCurrentConferenceConnectInfoSelector(state),
});

const mapDispatchToProps = (dispatch: TAppDispatch): IDispatchProps => ({
    copyToClipboard: (payload) => dispatch(copyToClipboardThunkAction(payload)),
});

export const ConferenceLinkDialogConnected = connect(
    mapStateToProps,
    mapDispatchToProps,
)(ConferenceLinkDialog);
