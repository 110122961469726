import { createAsyncThunk } from '@reduxjs/toolkit';
import { updatePublicKeyApiCall } from 'admin-app/api/publicKey/updatePublicKeyApiCall';
import { EUserThunkActions } from 'admin-app/constants/thunk/EUserThunkActions';
import { IApiCallErrorData } from 'admin-app/types/IApiCallErrorData';
import { getNetworkService } from 'api/getNetworkService';

export const editPublicKeyThunkAction = createAsyncThunk<
    IApiCallErrorData | string,
    string
>(EUserThunkActions.EDIT_PUBLIC_KEY, async (newKey) => {
    const { success, data, errorCode, errorDescription } = await updatePublicKeyApiCall(
        getNetworkService(),
        newKey,
    );
    if (!success) {
        return { errorCode, errorDescription };
    }
    return data.publicKey || '';
});
