import { Trans } from '@lingui/react';
import DownloadArrowWhiteImg from 'assets/DownloadArrowWhite.svg';
import { Cross } from 'components/common/Cross';
import OutsideClick from 'components/common/OutsideClick';
import { useEffect, useRef, useState } from 'react';
import { addUsersTimeZoneToDate } from 'utils/dates/addUsersTimeZoneToDate';
import { convertDateToStringByTemplate } from 'utils/dates/convertDateToStringByTemplate';
import { convertStringToDateByTemplate } from 'utils/dates/convertStringToDateByTemplate';
import { setEscCloseDropdown } from 'utils/escCloseDropdown';
import { convertRecordSize } from 'utils/records/convertRecordSize';
import { downloadRecord } from 'utils/records/downloadRecord';
import styles from './ConferenceRecordPlayer.module.scss';
import { IDispatchProps, IOwnProps } from './types';

export const ConferenceRecordPlayer: React.FC<IOwnProps & IDispatchProps> = ({
    record,
    closeHandle,
    getConferenceRecordData,
}) => {
    const [videoURL, setVideoURL] = useState(undefined);

    /* const [layoutHeight, setLayoutHeight] = useState<number>(0);
    const [layoutWidth, setLayoutWidth] = useState<number>(0); */
    const pageRef = useRef(null);

    /* const updateSize = () => {
        setLayoutHeight((pageRef?.current as any)?.offsetHeight);
        setLayoutWidth((pageRef?.current as any)?.offsetWidth);
    }; */

    useEffect(() => {
        const getUrl = async () => {
            const url = (
                await getConferenceRecordData({
                    recordId:record.id,
                    sessionId: record.sessionId,
                    conferenceId: record.conferenceId,
                })
            ).payload;
            setVideoURL(url);
        };

        getUrl();
        setEscCloseDropdown(true);
        return () => {
            setEscCloseDropdown(false);
        };
    }, []);

    const onEscClick = (e: KeyboardEvent) => {
        if (e.code === 'Escape') {
            closeHandle();
        }
    };

    const disableScrolling = (e: any) => {
        e.preventDefault();
    };

    useEffect(() => {
        document.addEventListener('keydown', onEscClick);
        //window.addEventListener('resize', updateSize);
        document.addEventListener('wheel', disableScrolling, { passive: false });
        return () => {
            //window.removeEventListener('resize', updateSize);
            document.removeEventListener('keydown', onEscClick);
            document.removeEventListener('wheel', disableScrolling);
        };
    });

    /* useEffect(() => {
        if (pageRef) {
            setLayoutHeight((pageRef?.current as any)?.offsetHeight);
            setLayoutWidth((pageRef?.current as any)?.offsetWidth);
        }
    }, [pageRef]); */

    /*const { width, height } = calculateParticipantFrameSize({
        viewWidth: layoutWidth,
        viewHeight: layoutHeight,
        framesCount: 1,
        participantFrames: { [1]: { width: layoutWidth, height: layoutHeight } },
        ratioInSettings: EDisplayVideoWithDifferentRatio.MIN_RATIO,
        hasFullScreenFrames: false,
    });*/
    const recordDateStart = convertStringToDateByTemplate(
        record.timeEnd,
        'yyyy-MM-dd HH:mm:ss.SSSSSS',
        true,
    );

    return (
        <div className={styles.root} ref={pageRef}>
            <Cross color='white' className={styles.cross} onClick={closeHandle} />
            <OutsideClick onOutsideClick={closeHandle}>
                <div className={styles.wrapper}>
                    <div className={styles.player}>
                        {videoURL && <video typeof='video/mp4' controls src={videoURL} />}
                        {/*
                                width={width}
                                height={height - 66} */}
                    </div>
                    <div className={styles.footer}>
                        <div className={styles.footer__left}>
                            <span className={styles.footer__info}>
                                {' '}
                                {convertDateToStringByTemplate(
                                    addUsersTimeZoneToDate(recordDateStart),
                                    'dd/MM/yyyy, HH:mm',
                                )}{' '}
                                - {record.name}
                            </span>
                            <span className={styles.footer__size}>
                                {convertRecordSize(record.record?.size)}
                            </span>
                        </div>
                        <div className={styles.footer__right}>
                            <span
                                className={styles.footer__download}
                                onClick={() =>
                                    downloadRecord({
                                        sessionId: record.sessionId,
                                        conferenceId: record.conferenceId,
                                        recordId: record.id,
                                        getRecord: getConferenceRecordData,
                                    })
                                }>
                                <img src={DownloadArrowWhiteImg} alt='download' />
                                <Trans id='Download' message='Скачать' />
                            </span>
                        </div>
                    </div>
                </div>
            </OutsideClick>
        </div>
    );
};
