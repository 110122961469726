import { setSessionConferenceUsersAction } from 'actions/conferenceSession/setSessionConferenceUsersAction';
import { appHistory } from 'appHistory';
import { connect } from 'react-redux';
import { getPendingOperationStatusSelector } from 'selectors/getPendingOperationStatusSelector';
import { closeConferenceReportThunkAction } from 'thunk/conferenceReport/closeConferenceReportThunkAction';
import { prepareConferenceReportThunkAction } from 'thunk/conferenceReport/prepareConferenceReportThunkAction';
import { TAppDispatch } from 'types/app/TAppDispatch';
import { TAppState } from 'types/app/TAppStore';
import { ConferenceReport } from './ConferenceReport';
import { IDispatchProps, IStateProps } from './types';

const mapStateToProps = (state: TAppState): IStateProps => ({
    reports: state.conferencesReports.reports,
    records: state.conferencesRecords.conferencesRecords,
    defaultOperationPending: getPendingOperationStatusSelector(state, 'defaultOperation'),
});

const mapDispatchToProps = (dispatch: TAppDispatch): IDispatchProps => ({
    prepareReport: (id) => dispatch(prepareConferenceReportThunkAction(id)),
    closeReport: () => dispatch(closeConferenceReportThunkAction()),
    goToConferenceListPage: () => appHistory.push('/account/conferences/past'),
    goToHomePage: () => appHistory.push('/'),
    resetExistAndExitParticipants: () => dispatch(setSessionConferenceUsersAction([])),
});

export const ConferenceReportConnected = connect(
    mapStateToProps,
    mapDispatchToProps,
)(ConferenceReport);
