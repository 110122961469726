export enum ESocketAppActions {
    CONFERENCE_STARTED = 'STARTED',
    CONFERENCE_FINISHED = 'FINISHED',
    CONFERENCE_SCHEDULED = 'SCHEDULED',
    CONFERENCE_UPDATED = 'UPDATED',
    CONFERENCE_DELETED = 'DELETED',
    RECORD_START_PROCESSING = 'START_PROCESSING',
    RECORD_READY = 'READY',
    RECORD_DELETED = 'DELETED',
}
