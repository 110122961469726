import { createAsyncThunk } from '@reduxjs/toolkit';
import { TAppState } from 'types/app/TAppStore';
import { IJanusLocalStreamProps } from 'types/janus/IJanusLocalStreamProps';
import { getFromLocalStorage } from 'utils/localStorage/getFromLocalStorage';
import { setToLocalStorage } from 'utils/localStorage/setToLocalStorage';

export const setPreviousCurrentConferenceDeviceValuesThunkAction = createAsyncThunk<
    void,
    Partial<IJanusLocalStreamProps>
>('setPreviousCurrentConferenceDeviceValues', async (deviceValues, { getState }) => {
    const {
        conferenceSession: { currentConferenceId },
    } = getState() as TAppState;
    const conferenceDataFromStorage = getFromLocalStorage(currentConferenceId);
    const oldValues = conferenceDataFromStorage?.conferenceDeviceValues || {};
    delete deviceValues.audioPermitted;
    delete deviceValues.videoPermitted;
    delete deviceValues.mediaEnabled;
    delete deviceValues.screenShared;
    setToLocalStorage(currentConferenceId, {
        ...conferenceDataFromStorage,
        conferenceDeviceValues: { ...oldValues, ...deviceValues },
    });
});
