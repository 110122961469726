import { connect } from 'react-redux';
import { ConferenceChatBtn } from './ConferenceChatBtn';
import { TAppState } from 'types/app/TAppStore';
import { IDispatchProps, IStateProps } from './types';
import { TAppDispatch } from 'types/app/TAppDispatch';
import { setParticipantsListOpenAction } from 'actions/applicationView/setParticipantsListOpenAction';
import { toggleConferenceChatAction } from 'actions/applicationView/toggleConferenceChatAction';

const mapStateToProps = (state: TAppState): IStateProps => ({
    chatMessages: state.conferenceChat.chatMessages,
    chatState: state.applicationView.showChat,
    isMessagesFromHistory: state.conferenceChat.isMessagesFromHistory,
    isParticipantsListOpen: state.applicationView.participantListOpen,
});

const mapDispatchToProps = (dispatch: TAppDispatch): IDispatchProps => ({
    closeParticipantsList: () => dispatch(setParticipantsListOpenAction(false)),
    toggleChat: (chatState) => dispatch(toggleConferenceChatAction(chatState)),
});

export const ConferenceChatBtnConnected = connect(
    mapStateToProps,
    mapDispatchToProps,
)(ConferenceChatBtn);
