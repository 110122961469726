import { connect } from 'react-redux';
import { TAppState } from 'types/app/TAppStore';
import { LoginPageMobile } from './LoginPageMobile';
import { IStateProps } from './types';

const mapStateToProps = (state: TAppState): IStateProps => ({
    rememberMeRequestStatus: state.auth.rememberMeRequestStatus,
});

export const LoginPageMobileConnected = connect(mapStateToProps)(LoginPageMobile);
