import { MobileWrapperConnected } from 'components/common/MobileWrapper/MobileWrapperConnected';
import { LoginForJoinPageConnected } from './LoginForJoinPageConnected';
import { LoginForJoinPageMobileConnected } from './LoginForJoinPageMobileConnected';

export const LoginForJoinPageResponsive = () => (
    <MobileWrapperConnected
        renderDesktop={() => <LoginForJoinPageConnected />}
        renderMobile={() => <LoginForJoinPageMobileConnected />}
    />
);
