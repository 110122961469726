import { t } from '@lingui/macro';
import { CircleLoader } from 'components/common/CircleLoader';
import { useEffect } from 'react';
import { ConferencePageTemplateConnected } from '../templates/ConferencePageTemplate';
import { ConferenceFinishedDialog } from './ConferenceFinishedDialog';
import styles from './ConferenceFinishedPage.module.scss';
import { IDispatchProps, IOwnProps, IStateProps } from './types';

export const ConferenceFinishedPage: React.FC<IStateProps & IOwnProps & IDispatchProps> = ({
    conferencesConnectInfo,
    id,
    goToHome,
    getConferenceConnectInfo,
    defaultOperationPending,
}) => {
    const conferenceConnectInfo = conferencesConnectInfo[id];

    useEffect(() => {
        const checkConference = async () => {
            const { payload: conference } = await getConferenceConnectInfo(id);
            if (!conference) {
                goToHome();
            }
        };
        if (!conferenceConnectInfo) {
            checkConference();
        }
    }, []);

    return (
        <ConferencePageTemplateConnected
            conferenceConnectInfo={conferenceConnectInfo}
            showLogo={true}>
            <div className={styles.wrapper}>
                {defaultOperationPending && <CircleLoader color='white' />}
                {!defaultOperationPending && (
                    <ConferenceFinishedDialog
                        text={t({
                            message: 'Конференция завершилась',
                            id: 'The conference has ended',
                        })}
                        goToHome={goToHome}
                    />
                )}
            </div>
        </ConferencePageTemplateConnected>
    );
};
