import { useEffect, useRef } from 'react';

export const useGetCurrentParticipants = (
    updateParticipants: () => void,
    isGettingCurrentParticipants: boolean,
): [startTimerToUpdate: () => void, stropTimerToUpdate: () => void] => {
    const timerToUpdateParticipants = useRef<NodeJS.Timeout | null>(null);
    const isGettingUsersRef = useRef(isGettingCurrentParticipants);

    useEffect(() => {
        isGettingUsersRef.current = isGettingCurrentParticipants;
    }, [isGettingCurrentParticipants]);

    const stopPeriodicUserUpdate = () => {
        timerToUpdateParticipants.current && clearInterval(timerToUpdateParticipants.current);
    };

    const startPeriodicUserUpdate = () => {
        if (timerToUpdateParticipants.current) {
            return;
        }
        updateParticipants()
        timerToUpdateParticipants.current = setInterval(() => {
            if (isGettingUsersRef.current) {
                return;
            }
            updateParticipants();
        }, 5000);
    };

    return [startPeriodicUserUpdate, stopPeriodicUserUpdate];
};
