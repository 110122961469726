import { log } from 'utils/log';
import { getExtensionInLowCase } from './getExtensionInLowCase';

export const getMimeTypeForImageFile = (fileName: string): string => {
    switch (getExtensionInLowCase(fileName)) {
        case 'jpg':
        case 'jpeg':
            return 'image/jpeg';
        case 'png':
            return 'image/png';
        case 'gif':
            return 'image/gif';
        case 'svg':
            return 'image/svg+xml';
    }
    log.error(`Not an image ${fileName}`);
    return '';
};
