import React from 'react';
import { MobileWrapperConnected } from '../MobileWrapper';
import { ConfirmDialog } from './ConfirmDialog';
import { ConfirmDialogMobile } from './ConfirmDialogMobile';
import { IOwnProps, TOwnMobileProps } from './types';

export const ConfirmDialogResponsive: React.FC<IOwnProps | TOwnMobileProps> = (props) => (
    <MobileWrapperConnected
        renderDesktop={() => <ConfirmDialog {...props} />}
        renderMobile={() => <ConfirmDialogMobile {...props} />}
    />
);
