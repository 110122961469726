import { connect } from 'react-redux';
import { Authorization } from './Authorization';
import { TAppDispatch } from 'types/app/TAppDispatch';
import { IDispatchProps } from './types';
import { getPublicKeyThunkAction } from 'admin-app/thunk/publicKey/getPublicKeyThunkAction';
import { editPublicKeyThunkAction } from 'admin-app/thunk/publicKey/editPublicKeyThunkAction';

const mapDispatchToProps = (dispatch: TAppDispatch): IDispatchProps => ({
    getPublicKey: () => dispatch(getPublicKeyThunkAction()).unwrap(),
    editPublicKey: (newVal) => dispatch(editPublicKeyThunkAction(newVal)).unwrap(),
});

export const AuthorizationConnected = connect(null, mapDispatchToProps)(Authorization);
