import { Trans } from '@lingui/react';
import { Button } from 'admin-app/components/common/Button';
import { IWebHook } from 'admin-app/types/IWebHook';
import { useEffect, useState } from 'react';
import { CreateWebHookDialogConnected } from './CreateWebHookDialog';
import { EditWebHookDialogConnected } from './EditWebHookDialog';
import { WebHookList } from './WebHookList';
import styles from './WebHooks.module.scss';
import { IDispatchProps, IStateProps } from './types';
import { DellWebHookDialogConnected } from './DellWebHookDialog';

export const WebHooks: React.FC<IStateProps & IDispatchProps> = ({ hookList, getHookList }) => {
    const [showCreateDialog, setShowCreateDialog] = useState(false);
    const [editDialogData, setEditDialogData] = useState<IWebHook | null>(null);
    const [deleteDialogData, setDeleteDialogData] = useState<IWebHook | null>(null);

    useEffect(() => {
        getHookList();
    }, []);

    const editHook = (hook: IWebHook) => setEditDialogData(hook);
    const dellHook = (hook: IWebHook) => setDeleteDialogData(hook);
    return (
        <div>
            {showCreateDialog && (
                <CreateWebHookDialogConnected closeDialog={() => setShowCreateDialog(false)} />
            )}
            {editDialogData && (
                <EditWebHookDialogConnected
                    hookData={editDialogData}
                    closeDialog={() => setEditDialogData(null)}
                />
            )}
            {deleteDialogData && (
                <DellWebHookDialogConnected
                    id={deleteDialogData.id}
                    url={deleteDialogData.url}
                    closeDialog={() => setDeleteDialogData(null)}
                />
            )}
            <h3>
                <Trans id='admin.hooks.title' message='Веб-хуки' />
            </h3>
            <WebHookList hookList={hookList} editHook={editHook} dellHook={dellHook} />
            <Button
                unset
                className={styles.container__submitBtn}
                onClick={() => setShowCreateDialog(true)}>
                <div className={styles.container__plus}></div>
                <Trans id='admin.hook.addHook' message='Добавить веб-хук' />
            </Button>
        </div>
    );
};
