import { t } from '@lingui/macro';
import { Trans } from '@lingui/react';
import { Button } from 'components/common/Button/Button';
import { Checkbox } from 'components/common/Checkbox/Checkbox';
import { FullScreenPopup } from 'components/common/FullScreenPopup';
import { Input } from 'components/common/Input/Input';
import { PopupDialog } from 'components/common/PopupDialog';
import { StatusLabel } from 'components/common/StatusLabel/StatusLabel';
import { StrToHtml } from 'components/common/StrToHtml';
import { ValidationErrors } from 'final-form';
import React, { useState } from 'react';
import { Form, FormRenderProps } from 'react-final-form';
import { isEmailValid } from 'utils/isEmailValid';
import { isFormHasValidationErrors } from 'utils/isFormHasValidationErrors';
import styles from './LoginForm.module.scss';
import { IDispatchProps, IFormRenderProps, IOwnProps, IStateProps } from './types';
import { handleSubmit } from './utils';
import { EPaths } from 'constants/EPaths';
import { Link } from 'react-router-dom';

export const LoginForm: React.FC<IStateProps & IDispatchProps & IOwnProps> = ({
    login,
    ldap,
    showPrivacyPolice = true,
    showAdditionalControls = true,
    successCallback,
}) => {
    const onSubmit = async (values: IFormRenderProps) =>
        await handleSubmit(values, { login }, successCallback);
    const getFormFirstField = ({
        errors, dirtySinceLastSubmit, modified, dirtyFields,
    }:{
        errors: ValidationErrors,
        dirtySinceLastSubmit: boolean,
        modified?: Record<string, boolean>,
        dirtyFields: Record<string, boolean>,
    }) =>
        ldap ? (
            <>
                <label className={styles.label} htmlFor='ldap'>
                    <Trans id='User name' message='Имя пользователя'/>
                </label>
                <Input
                    placeholder={t({id: 'User name', message: 'Имя пользователя'})}
                    name='ldap'
                    type='text'
                    className={styles.inputStyle}
                    touched={false}
                    errorText={errors?.ldap}
                    errorWithoutConditions={modified?.ldap && errors?.ldap}
                    tabIndex={1}
                    autoFocus={true}
                />
            </>
        ) : (
            <>
                <label className={styles.label} htmlFor='email'>
                    <Trans id='Email' message='Электронная почта'/>
                </label>
                <Input
                    placeholder={t({id: 'Email', message: 'Электронная почта'})}
                    name='email'
                    type='text'
                    className={styles.inputStyle}
                    touched={false}
                    errorText={errors?.email}
                    errorWithoutConditions={(!dirtyFields.email && modified?.email || dirtySinceLastSubmit) && errors?.email}
                    tabIndex={1}
                    autoFocus={true}
                />
            </>
        );

    const validate = (values: IFormRenderProps) => {
        const errors: { [key: string]: string } = {};
        const requiredError = t({ id: 'RequiredField' });
        if (ldap) {
            if (!values.ldap) {
                errors.ldap = requiredError;
            }
        } else {
            if (!values.email) {
                errors.email = requiredError;
            } else if (!isEmailValid(values.email)) {
                errors.email = t({
                    id: 'Your email address must be entered in the format name@domain.com',
                    message:
                        'Ваш адрес электронной почты должен быть введен в формате name@domain.com',
                });
            }
        }
        if (!values.password) {
            errors.password = requiredError;
        }
        return errors;
    };
    const [dialog, setDialog] = useState<{ isOpen: boolean; id?: string }>({
        isOpen: false,
        id: 'userAgreement',
    });
    const showPopupHandle = (id: string) => (evt: any) => {
        evt.nativeEvent.preventDefault();
        setDialog({ isOpen: true, id });
    };
    const closePopup = () => setDialog({ isOpen: false });
    const render = ({
        handleSubmit,
        submitting,
        errors,
        dirtySinceLastSubmit,
        submitError,
        modified,
        dirtyFields,
    }: FormRenderProps<IFormRenderProps>) => (
        <>
            <form onSubmit={handleSubmit} className={styles.form}>
                <StatusLabel
                    text={submitError && !dirtySinceLastSubmit ? submitError : ''}
                    className={styles.fail}
                />
                <div className={styles.block}>{getFormFirstField({ errors, dirtySinceLastSubmit, modified, dirtyFields })}</div>
                <div className={styles.block}>
                    <div className={styles.passwordBlockHeader}>
                        <label className={styles.label} htmlFor='password'>
                            <Trans id='Password' message='Пароль' />
                        </label>
                        {showAdditionalControls && (
                            <Link
                                to={EPaths.RESTORE}
                                className={styles.forgotPassword}>
                                <Trans message='Забыли пароль?' id='Forgot password?' />
                            </Link>
                        )}
                    </div>
                    <Input
                        placeholder={t({ id: 'Password', message: 'Пароль' })}
                        name='password'
                        type='password'
                        className={styles.inputStyle}
                        touched={false}
                        errorText={errors?.password}
                        errorWithoutConditions={modified?.password && errors?.password}
                        tabIndex={2}
                    />
                </div>
                {showPrivacyPolice && (
                    <div className={styles.agreement}>
                        <Trans
                            id='enter.and.accept'
                            message='Входя в аккаунт, вы принимаете <0>Правила приватности</0> и разрешаете <1>Обработку личных данных</1>'
                            components={{
                                0: (
                                    <a
                                        href='/#'
                                        className={styles.rules}
                                        onClick={showPopupHandle('userAgreement')}
                                    />
                                ),
                                1: (
                                    <a
                                        href='/#'
                                        className={styles.persInfo}
                                        onClick={showPopupHandle('userRules')}
                                    />
                                ),
                            }}
                        />
                    </div>
                )}
                <Button
                    styleType='primaryAction'
                    caption={t({ id: 'FormLoginButton', message: 'Войти' })}
                    disabled={submitting || isFormHasValidationErrors(errors)}
                    type='submit'
                    tabIndex={3}
                />
                {showAdditionalControls && (
                    <Checkbox
                        name='rememberMe'
                        className={styles.rememberMe}
                        text={t({
                            id: 'Remember me',
                            message: 'Не выходить из системы',
                        })}
                    />
                )}
            </form>
            {dialog.isOpen && (
                <FullScreenPopup closePopup={closePopup}>
                    <PopupDialog
                        crossPosition='inside'
                        onClose={closePopup}
                        title={
                            dialog.id === 'userAgreement'
                                ? t({ id: 'userAgreement.title' })
                                : t({ id: 'userRules.title' })
                        }
                        isScrollContent={true}
                        contentClassName={styles.agreement}
                        dialogClassName={styles.popup}>
                        <StrToHtml
                            str={
                                dialog.id === 'userAgreement'
                                    ? t({ id: 'userAgreement.text' })
                                    : t({ id: 'userRules.text' })
                            }
                        />
                    </PopupDialog>
                </FullScreenPopup>
            )}
        </>
    );
    const initialValues = showAdditionalControls ? { rememberMe: true } : {};
    return (
        <Form
            onSubmit={onSubmit}
            validate={validate}
            render={render}
            initialValues={initialValues}
        />
    );
};
