import { createReducer } from '@reduxjs/toolkit';
import { setApplicationConfigAction } from 'actions/applicationConfiguration/setApplicationConfigAction';
import { setApplicationLicenseAction } from 'actions/auth/setApplicationLicenseAction';
import { setAppSettingsAction } from 'actions/auth/setAppSettingsAction';
import { setDevFeaturesAction } from 'actions/featuresReducer/setDevFeaturesAction';
import { resetStoreAction } from 'actions/resetStoreAction';
import { IFeatures } from 'types/feature/IFeatures';
import { log } from 'utils/log';
import { appConfigurationReducerInitialState } from './applicationConfigurationReducer';
import { authReducerInitialState } from './authReducer';

export interface IFeaturesReducer {
    appConfigurationConfigFeatures: IFeatures;
    authAppSettingsFeatures: IFeatures;
    authLicenseFeatures: IFeatures;
    devFeatures: IFeatures;
    features: IFeatures;
}

export const featuresReducerInitialState: IFeaturesReducer = {
    appConfigurationConfigFeatures: parseFeatures(
        appConfigurationReducerInitialState.config.features,
    ),
    authAppSettingsFeatures: parseFeatures(authReducerInitialState.appSettings?.features),
    authLicenseFeatures: parseFeatures(authReducerInitialState.license.features),
    devFeatures: {},
    features: {},
};

setFeatures(featuresReducerInitialState);

export const featuresReducer = createReducer(featuresReducerInitialState, (builder) =>
    builder
        .addCase(setApplicationConfigAction, (state, { payload: config }) => {
            state.appConfigurationConfigFeatures = parseFeatures(config.features);
            setFeatures(state);
        })
        .addCase(setAppSettingsAction, (state, { payload: appSettings }) => {
            state.authAppSettingsFeatures = parseFeatures(appSettings.features);
            setFeatures(state);
        })
        .addCase(setApplicationLicenseAction, (state, { payload: license }) => {
            state.authLicenseFeatures = parseFeatures(license.features);
            setFeatures(state);
        })
        .addCase(setDevFeaturesAction, (state, { payload: devFeatures }) => {
            state.devFeatures = devFeatures;
            setFeatures(state);
        })
        .addCase(resetStoreAction, () => featuresReducerInitialState),
);

function setFeatures(state: IFeaturesReducer) {
    state.features = {
        ...state.appConfigurationConfigFeatures,
        ...state.authAppSettingsFeatures,
        ...state.authLicenseFeatures,
        ...state.devFeatures,
    };
}

function parseFeatures(str?: string): IFeatures {
    if (str) {
        try {
            return JSON.parse(str);
        } catch (err) {
            log.error('Cant parse features:', str, err);
        }
    }
    return {};
}
