import { InputUsernameFormMobileConnected } from 'components/forms/InputUsernameForm/InputUsernameFormMobileConnected';
import { MobilePageTemplate } from 'components/MobilePageTemplate';
import React, { useEffect } from 'react';
import { redirect } from 'utils/redirect';
import { IDispatchProps, IOwnProps } from './types';

export const InputUsernamePageMobile: React.FC<IOwnProps & IDispatchProps> = ({
    id,
    loadData,
}) => {
    useEffect(() => {
        loadData(id);
    });
    return (
        <MobilePageTemplate showIcon={true} onClickBack={() => redirect('/')}>
            <InputUsernameFormMobileConnected />
        </MobilePageTemplate>

    );
};
