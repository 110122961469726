import { createAsyncThunk } from '@reduxjs/toolkit';
import { EConferenceThunkActions } from 'constants/thunk/EConferenceThunkActions';
import {
    loadDataForConnectAndConferencePagesThunkAction,
} from 'thunk/conference/loadDataForConnectAndConferencePagesThunkAction';
import {
    subscribeToConferenceChangeStatusThunkAction,
} from 'thunk/socket/subscribeToConferenceChangeStatusThunkAction';
import { redirect } from 'utils/redirect';
import { EPaths } from 'constants/EPaths';
import { IJanusLocalStreamProps } from 'types/janus/IJanusLocalStreamProps';
import { TAppState } from 'types/app/TAppStore';
import { setJanusStreamPropsAction } from 'actions/janus/setJanusStreamPropsAction';
import { showNotifyThunkAction } from 'thunk/notify/showNotifyThunkAction';
import { t } from '@lingui/macro';

interface IPayload {
    id: string
}

export const prepareConferenceForStartThunkAction = createAsyncThunk<void, IPayload>(
    EConferenceThunkActions.PREPARE_CONFERENCE_FOR_START,
    async ({ id}, { dispatch, getState }) => {
        const {
            auth: { currentUser },
        } = getState() as TAppState;
        const info = await dispatch(loadDataForConnectAndConferencePagesThunkAction({ id, page: 'conference' })).unwrap()
        if (info) {
            if (info.status === 'DELETED') {
                dispatch(showNotifyThunkAction({
                    text: t({id: 'pastConference.buttonAgain.disabled'}),
                    timeout: 5000,
                    type: 'error',
                }))
                redirect(EPaths.UPCOMING_CONFERENCES)
                return;
            }

            const userIsConferenceOwner = currentUser?.id === info.creator.userId
            const props: Partial<IJanusLocalStreamProps> = {};
            if (info.muteUponEntry && !userIsConferenceOwner) {
                props.useAudio = false;
            }
            if (userIsConferenceOwner) {
                if (!info.videoCreator) {
                    props.useVideo = info.videoCreator;
                }
            } else {
                if (!info.videoParticipants) {
                    props.useVideo = false;
                }
            }
            if (Object.keys(props).length) {
                dispatch(setJanusStreamPropsAction(props));
            }

            dispatch(subscribeToConferenceChangeStatusThunkAction());
        }
    })
