import { createReducer } from '@reduxjs/toolkit';
import { addDisconnectPublisherToLogAction } from 'actions/janus/addDisconnectPublisherToLogAction';
import { addJanusPublisherAction } from 'actions/janus/addJanusPublisherAction';
import { addModeratedMediaToLogAction } from 'actions/janus/addModeratedMediaToLogAction';
import { clearJanusLogAction } from 'actions/janus/clearJanusLogAction';
import { modifyJanusPublisherAction } from 'actions/janus/modifyJanusPublisherAction';
import { removeFromJanusLogAction } from 'actions/janus/removeFromJanusLogAction';
import { removeJanusPublisherAction } from 'actions/janus/removeJanusPublisherAction';
import { removeRemoteStreamAction } from 'actions/janus/removeRemoteStreamAction';
import { resetJanusStoreAction } from 'actions/janus/resetJanusStoreAction';
import { resetOwnStreamAction } from 'actions/janus/resetOwnStreamAction';
import { resetPublisherStreamAction } from 'actions/janus/resetPublisherStreamAction';
import { saveJanusTokenAction } from 'actions/janus/saveJanusTokenAction';
import { saveOwnParticipantIdAction } from 'actions/janus/saveOwnParticipantIdAction';
import { setActivePublishersAction } from 'actions/janus/setActivePublishersAction';
import { setAudioStreamChangeProgressStatusAction } from 'actions/janus/setAudioStreamChangeProgressStatusAction';
import { setCanPublishAction } from 'actions/janus/setCanPublishAction';
import { setJanusStreamPropsAction } from 'actions/janus/setJanusStreamPropsAction';
import { setLocalStreamForJanusAction } from 'actions/janus/setLocalStreamForJanusAction';
import { setOwnEmotionToShowAction } from 'actions/janus/setOwnEmotionToShowAction';
import { setOwnTalkingStatusAction } from 'actions/janus/setOwnTalkingStatusAction';
import { setParticipantMuteStatusAction } from 'actions/janus/setParticipantMuteStatusAction';
import { setVideoRoomConnectedStatusAction } from 'actions/janus/setVideoRoomConnectedStatusAction';
import { setVideoStreamChangeProgressStatusAction } from 'actions/janus/setVideoStreamChangeProgressStatusAction';
import { startJanusPublisherStreamAction } from 'actions/janus/startJanusPublisherStreamAction';
import { resetStoreAction } from 'actions/resetStoreAction';
import { IEmotion } from 'types/IEmotion';
import { IPublisher } from 'types/IPublisher';
import { IJanusLocalStreamProps } from 'types/janus/IJanusLocalStreamProps';
import { IJanusStreams } from 'types/janus/IJanusStreams';
import { TLogEvents } from 'types/janus/TModerateMediaEvents';
import { janusCtx } from 'utils/janus/janusCtx';

export interface IJanusReducer {
    activePublishers: number;
    canPublish: boolean;
    localStreamProps: IJanusLocalStreamProps;
    log: { event: TLogEvents; name?: string }[];
    ownParticipantId: string;
    ownStream: IJanusStreams;
    ownTalkingStatus: boolean;
    publishers: Record<string, IPublisher>;
    streams: Record<string, IJanusStreams>;
    token: string;
    videoRoomConnected: boolean;
    ownEmotionToShow: IEmotion | null;
    videoStreamChangeInProgress: boolean;
    audioStreamChangeInProgress: boolean;
}

export const janusReducerInitialState = (): IJanusReducer => ({
    activePublishers: 0,
    canPublish: true,
    localStreamProps: {
        audioPermitted: true,
        mediaEnabled: true,
        screenShared: false,
        useAudio: true,
        useVideo: true,
        videoPermitted: true,
    },
    log: [],
    ownParticipantId: '',
    ownStream: {},
    ownTalkingStatus: false,
    publishers: {},
    streams: {},
    token: '',
    videoRoomConnected: false,
    ownEmotionToShow: null,
    videoStreamChangeInProgress: false,
    audioStreamChangeInProgress: false,
});

export const janusReducer = createReducer(janusReducerInitialState(), (builder) =>
    builder
        .addCase(addJanusPublisherAction, (state, { payload: publisher }) => {
            state.publishers[publisher.participantId] = publisher;
            state.log.push({ event: 'join', name: publisher.displayName });
        })
        .addCase(modifyJanusPublisherAction, (state, { payload: { participantId, ...data } }) => {
            const publisher = state.publishers[participantId];
            if (publisher) {
                state.publishers[participantId] = {
                    ...publisher,
                    ...data,
                };
            }
        })
        .addCase(resetJanusStoreAction, (state) => {
            clearEmotionTimer(state);
            Object.assign(state, janusReducerInitialState());
        })
        .addCase(saveOwnParticipantIdAction, (state, { payload: participantId }) => {
            state.ownParticipantId = participantId;
        })
        .addCase(setLocalStreamForJanusAction, (state, { payload: { type, ...streamProps } }) => {
            state.ownStream[type] = streamProps;
        })
        .addCase(
            startJanusPublisherStreamAction,
            (state, { payload: { id, stream, type, mid } }) => {
                if (!state.streams[id]) {
                    state.streams[id] = {};
                }
                state.streams[id][type] = { stream, mid };
            },
        )
        .addCase(removeJanusPublisherAction, (state, { payload: participantId }) => {
            delete state.publishers[participantId];
            delete state.streams[participantId];
        })
        .addCase(removeRemoteStreamAction, (state, { payload: { participantId, mid } }) => {
            const streams = state.streams[participantId];
            if (streams) {
                if (streams.audio?.mid === mid) {
                    delete streams.audio;
                } else if (streams.video?.mid) {
                    delete streams.video;
                }
            }
        })
        .addCase(addDisconnectPublisherToLogAction, (state, { payload: publisher }) => {
            state.log.push({ event: 'exit', name: publisher.displayName });
        })
        .addCase(addModeratedMediaToLogAction, (state, { payload: mediaEvent }) => {
            state.log.push({ event: mediaEvent });
        })
        .addCase(removeFromJanusLogAction, (state) => {
            state.log.shift();
        })
        .addCase(clearJanusLogAction, (state) => {
            state.log = janusReducerInitialState().log;
        })
        .addCase(setJanusStreamPropsAction, (state, { payload }) => {
            const newProps = { ...payload };
            //This field can come from IChangeJanusStreamProps in the ConferenceFooter.
            // This is necessary because of the janusMiddleware
            delete (newProps as typeof payload & { removeMedia?: any })?.removeMedia;
            state.localStreamProps = { ...state.localStreamProps, ...newProps };
            if (payload.useAudio === false || payload.audioPermitted == false) {
                state.ownTalkingStatus = false;
            }
            if (payload.videoPermitted === false && state.localStreamProps.screenShared) {
                state.localStreamProps.screenShared = false;
            }
        })
        .addCase(
            setParticipantMuteStatusAction,
            (state, { payload: { participantId, audioVideoProps } }) => {
                const publisher = state.publishers[participantId];
                if (publisher) {
                    publisher.audioVideoProps = {
                        ...publisher.audioVideoProps,
                        ...audioVideoProps,
                    };
                }
            },
        )
        .addCase(saveJanusTokenAction, (state, { payload: token }) => {
            state.token = token;
        })
        .addCase(setOwnTalkingStatusAction, (state, { payload: status }) => {
            if (state.localStreamProps.audioPermitted && state.localStreamProps.useAudio) {
                state.ownTalkingStatus = status;
            } else if (state.ownTalkingStatus) {
                state.ownTalkingStatus = false;
            }
        })
        .addCase(setOwnEmotionToShowAction, (state, { payload: emotionToShow }) => {
            state.ownEmotionToShow = emotionToShow;
        })
        .addCase(resetStoreAction, (state) => {
            clearEmotionTimer(state);
            janusReducerInitialState();
        })
        .addCase(setVideoRoomConnectedStatusAction, (state, { payload: videoRoomConnected }) => {
            state.videoRoomConnected = videoRoomConnected;
        })
        .addCase(setVideoStreamChangeProgressStatusAction, (state, { payload: progress }) => {
            state.videoStreamChangeInProgress = progress;
        })
        .addCase(setAudioStreamChangeProgressStatusAction, (state, { payload: progress }) => {
            state.audioStreamChangeInProgress = progress;
        })
        .addCase(resetOwnStreamAction, (state) => {
            state.ownStream = {};
            state.localStreamProps.useAudio = false;
            state.localStreamProps.useVideo = false;
            state.localStreamProps.screenShared = false;
            state.ownTalkingStatus = false;
        })
        .addCase(resetPublisherStreamAction, (state, { payload: publisherId }) => {
            const streams: Record<string, IJanusStreams> = {};
            const ids = Object.keys(state.streams);
            ids.forEach((id) => {
                if (id !== publisherId) {
                    streams[id] = state.streams[id];
                }
            });
            state.streams = streams;
        })
        .addCase(setActivePublishersAction, (state, { payload: activePublishes }) => {
            state.activePublishers = activePublishes;
        })
        .addCase(setCanPublishAction, (state, { payload: canPublish }) => {
            state.canPublish = canPublish;
            if (!canPublish && !janusCtx.published) {
                // No free slots, so should remove useAudio or useVideo if exists
                if (state.localStreamProps.useAudio) {
                    state.localStreamProps.useAudio = false;
                }
                if (state.localStreamProps.useVideo) {
                    state.localStreamProps.useVideo = false;
                }
            }
        }),
);

const clearEmotionTimer = (state: IJanusReducer) => {
    if (state.ownEmotionToShow) {
        clearTimeout(state.ownEmotionToShow.emotionTimer);
    }
};
