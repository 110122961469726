import { Trans } from '@lingui/react';
import React, { useRef, useState } from 'react';
import { getUserColor } from 'utils/users/getUserColor';
import { getUserInitials } from 'utils/users/getUserInitials';
import { getAvatarUrl } from 'utils/users/getAvatarUrl';
import { Avatar } from '../Avatar';
import styles from './EmailListEditorMobile.module.scss';
import { IDispatchProps, IOwnProps, IStateProps } from './types';
import { getAmountTitle } from './utils';
import { t } from '@lingui/macro';
import { Input } from '../Input';
import { isEmailValid } from 'utils/isEmailValid';

export const EmailListEditorMobile: React.FC<IOwnProps & IStateProps & IDispatchProps> = ({
    inputValues,
    ldap,
    setFormField,
    users,
    updateUsersList,
    resetUsersList,
}) => {
    const [isShowAddFrom, setIsShowAddForm] = useState<boolean>(false);
    const [isNotChanged, setIsNotChanged] = useState<boolean>(true);
    const [emails, setEmails] = useState<Set<string>>(new Set(inputValues));
    const [emailValue, setEmailValue] = useState<string>('');
    const [emailError, setEmailError] = useState<string>('');
    const $inputWrap = useRef<HTMLDivElement>(null);
    if (!isShowAddFrom) {
        if (!inputValues?.length) {
            return (
                <div
                    className={styles.row + ' ' + styles.rowAdd}
                    onClick={() => setIsShowAddForm(true)}>
                    <div>{t({ id: 'EmailList.add', message: 'Добавить' })}</div>
                </div>
            );
        }
        return (
            <div
                className={styles.row + ' ' + styles.rowChange}
                onClick={() => setIsShowAddForm(true)}>
                <div>
                    {inputValues.length}{' '}
                    {getAmountTitle(inputValues.length, [
                        t({ id: 'EmailList.one', message: 'Участник' }),
                        t({ id: 'EmailList.two', message: 'Участника' }),
                        t({ id: 'EmailList.five', message: 'Участников' }),
                    ])}
                </div>
            </div>
        );
    }
    const getInput = () => $inputWrap?.current?.querySelector('input');
    const searchEmail = () => {
        const val = getInput()?.value || '';
        setEmailValue(val);
        validate(val);
        if (val && updateUsersList) {
            updateUsersList(val);
            return;
        }
        if (resetUsersList) {
            resetUsersList();
        }
    };
    const handleKeyDown = (evt: React.KeyboardEvent<HTMLInputElement>) => {
        if (evt.nativeEvent.key === 'Enter') {
            evt.nativeEvent.preventDefault();
            addEmail();
            return;
        }
    };
    const setEmail = (email: string) => {
        setEmailValue(email);
        validate(email);
        if (resetUsersList) {
            resetUsersList();
        }
        getInput()?.focus();
    };
    const addEmail = () => {
        if (resetUsersList) {
            resetUsersList();
        }
        getInput()?.focus();
        if (!validate(emailValue)) {
            return;
        }
        emails.add(emailValue);
        setEmails(new Set(emails));
        setEmailValue('');
        if (isNotChanged) {
            setIsNotChanged(false);
        }
    };
    const removeEmail = (email: string) => {
        emails.delete(email);
        setEmails(new Set(emails));
        if (isNotChanged) {
            setIsNotChanged(false);
        }
    };
    const close = (isCancel?: boolean) => {
        setIsShowAddForm(false);
        setIsNotChanged(true);
        if (resetUsersList) {
            resetUsersList();
        }
        if (isCancel) {
            setEmails(new Set(inputValues));
        }
    };
    const saveEmails = () => {
        if (isNotChanged) {
            return;
        }
        setFormField(Array.from(emails));
        close();
    };
    const closeTipClick = (evt: any) => {
        for (let i = evt.target; i !== null; i = i.parentNode) {
            if (i.dataset?.isSearch) {
                return;
            }
        }
        if (users?.length !== 0 && resetUsersList) {
            resetUsersList();
        }
    };
    const validate = (email: string) => {
        if (email === '') {
            setEmailError(t({ id: 'RequiredField' }));
            return false;
        }
        if (isEmailValid(email)) {
            setEmailError('');
            return true;
        }
        setEmailError(
            t({
                id: 'Your email address must be entered in the format name@domain.com',
                message: 'Ваш адрес электронной почты должен быть введен в формате name@domain.com',
            }),
        );
        return false;
    };
    return (
        <div
            className={isNotChanged ? styles.win + ' ' + styles.winNotChanged : styles.win}
            onMouseDown={closeTipClick}>
            <div className={styles.winHeader}>
                <a className={styles.winHeaderCancel} onClick={() => close(true)}>
                    <Trans id='Cancel' />
                </a>
                <a className={styles.winHeaderReady} onClick={saveEmails}>
                    <Trans id='Ready' />
                </a>
            </div>
            <div className={styles.fields}>
                <div className={styles.field + ' ' + styles.tipWrap} ref={$inputWrap}>
                    <Input
                        styleType='mobile'
                        name='email'
                        type='email'
                        placeholder={t({
                            id: 'EmailList.addInputPlaceholer',
                            message: 'Введите эл. почту или имя участника',
                        })}
                        touched={false}
                        errorText={emailError}
                        handleKeyDown={handleKeyDown}
                        handleInput={searchEmail}
                        autocomplete='off'
                        value={emailValue}
                    />
                    {ldap && users?.length !== 0 ? (
                        <div className={styles.tip}>
                            {users?.map(({ firstName, lastName, email, etag, id }) => (
                                <div
                                    key={email}
                                    className={styles.tipItem}
                                    data-is-search='yes'
                                    onClick={() => setEmail(email)}>
                                    <Avatar
                                        style={{
                                            cursor: 'pointer',
                                            width: '23px',
                                            height: '23px',
                                            fontSize: '10px',
                                        }}
                                        initials={getUserInitials({
                                            lastName,
                                            firstName,
                                            email,
                                        })}
                                        image={etag ? getAvatarUrl({ id, etag, size: '23' }) : ''}
                                        userBgColor={getUserColor(
                                            `${firstName}${lastName}`,
                                        )}
                                    />
                                    <div>{email}</div>
                                </div>
                            ))}
                        </div>
                    ) : null}
                </div>
                <div className={styles.field}>
                    <a className={styles.btn} onClick={addEmail}>
                        {t({
                            id: 'EmailList.addButton',
                            message: 'Добавить участника',
                        })}
                    </a>
                </div>
                {emails.size ? (
                    <div className={styles.list}>
                        <div className={styles.listTitle}>
                            {t({
                                id: 'EmailList.listTitle',
                                message: 'Участники',
                            })}
                        </div>
                        {Array.from(emails).map((email) => (
                            <div className={styles.listItem} key={email}>
                                <div>{email}</div>
                                <div
                                    className={styles.listItemRemove}
                                    onClick={() => removeEmail(email)}></div>
                            </div>
                        ))}
                    </div>
                ) : null}
            </div>
        </div>
    );
};
