import { checkActiveParticipantsAndCanPublishThunkAction } from 'thunk/janus/checkActiveParticipantsAndCanPublishThunkAction';
import { TAppState } from 'types/app/TAppStore';
import { janusCtx } from 'utils/janus/janusCtx';
import { janusVideoRoomMessagesLogger } from 'utils/logger';
import { multiStreamSubscribe } from './multiStreamSubscribe';
import { setParticipantTalkingStatus } from './setParticipantTalkingStatus';
import { singleStreamSubscribe } from './singleStreamSubscriber';

export const videoRoomHandleParticipants = (msg: any) => {
    const {
        janus: { streams: alreadyExistStreams },
    } = janusCtx.getState() as TAppState;
    const participantTypes = ['publishers', 'attendees'];
    let sources = null;
    for (const type of participantTypes) {
        const list = msg[type];
        if (list) {
            janusVideoRoomMessagesLogger.debug(
                `Got a list of available ${type}/feeds in joined event:`,
                {
                    list,
                },
            );
            for (const f in list) {
                let isTalking;
                const pub = list[f];
                const id = pub['id'];
                const display = pub['display'];
                const streams = pub['streams'];
                let needToSubscribe = false;
                if (Array.isArray(streams)) {
                    for (const stream of streams) {
                        if (stream.disabled) {
                            needToSubscribe = true;
                        }
                        stream['id'] = id;
                        stream['display'] = display;
                        const { type, mid, talking } = stream;
                        if (type === 'data') continue;
                        if (alreadyExistStreams[id]) {
                            const foundStream = alreadyExistStreams[id][type as 'audio' | 'video'];
                            janusVideoRoomMessagesLogger.debug({ foundStream });
                            if (foundStream?.mid !== mid) {
                                needToSubscribe = true;
                            }
                            if (!foundStream?.stream.active) {
                                needToSubscribe = true;
                            }
                        } else {
                            needToSubscribe = true;
                        }
                        if (type === 'audio' && talking !== undefined) {
                            isTalking = talking;
                        }
                    }
                }
                janusVideoRoomMessagesLogger.debug(`  >> [${id}] ${display} :`, streams);
                if (isTalking) {
                    setParticipantTalkingStatus(id, isTalking);
                }
                if (!needToSubscribe) {
                    janusVideoRoomMessagesLogger.debug(
                        'Already subscribed to stream, skipping:',
                        id,
                    );
                    continue;
                }
                if (janusCtx.subscribeType === 'multi') {
                    const remoteStreams = janusCtx.multiStreamFeedStreams[id]
                        ? janusCtx.multiStreamFeedStreams[id].remoteStreams
                        : 0;
                    janusCtx.multiStreamFeedStreams[id] = {
                        id,
                        display,
                        streams,
                        remoteStreams,
                    };
                    if (!sources) {
                        sources = [];
                    }
                    sources.push(streams);
                } else {
                    singleStreamSubscribe(id, display, streams);
                }
            }
        }
    }
    if (sources) {
        console.log({ sources });
        
        multiStreamSubscribe(sources);
    }
    janusCtx.dispatch(checkActiveParticipantsAndCanPublishThunkAction());
};
