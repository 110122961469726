import { MobileWrapperConnected } from 'components/common/MobileWrapper';
import { AccountPageConnected } from './AccountPageConnected';
import { AccountPageMobileConnected } from './AccountPageMobileConnected';

export const AccountConferencePageResponsive: React.FC = () => (
    <MobileWrapperConnected
        renderDesktop={() => <AccountPageConnected />}
        renderMobile={() => <AccountPageMobileConnected />}
    />
);
