import { ConferenceScheduleSettingsFormMobileConnected } from 'components/ConferenceScheduleSettingsForm/ConferenceScheduleSettingsFormMobileConnected';
import { MainMobileMenuConnected } from 'components/MainMobileMenu';
import { EPaths } from 'constants/EPaths';
import { JoinConferencePageMobileConnected } from 'pages/JoinConferencePage';
import { useRef, useState } from 'react';
import { Route, useLocation } from 'react-router-dom';
import { AccountConferencePageResponsive } from './AccountConferencesPage';
import { AccountInformationPageResponsive } from './AccountInformationPage';
import styles from './AccountPageMobile.module.scss';
import { AccountPageMobileFooterConnected } from './AccountPageMobileFooter';
import { AccountPageMobileHeaderConnected } from './AccountPageMobileHeader';
import { AccountSettingsPageResponsive } from './AccountSettingsPage';
import { ConferenceSchedulingPageMobileConnected } from './ConferenceSchedulingPage/ConferenceSchedulingPageMobileConnected';
import { ConferencesRecordsPageMobileConnected } from './ConferencesRecordsPage/ConferencesRecordsPageMobileConnected';
import { AccountConferenceDetailsPageResponsive } from './AccountConferencesPage/AccountConferenceDetailsPage';
import { IStatePropsMobile } from './types';
import { HorizontalLoader } from 'components/common/HorizontalLoader';

export const AccountPageMobile: React.FC<IStatePropsMobile> = ({ rememberMeRequestStatus }) => {
    const contentRef = useRef<HTMLDivElement>(null);
    const { pathname } = useLocation();
    const hideHeaderFooter = pathname.includes('edit') || pathname.includes('scheduling');
    const hideHeader = pathname.includes('profile');

    const [showMenu, setShowMenu] = useState(false);
    const [showJoinForm, setShowJoinForm] = useState<boolean>(false);

    const render = () => {
        if (rememberMeRequestStatus === 'initial' || rememberMeRequestStatus === 'requested') {
            return (
                <div className={styles.loaderWrapper}>
                    <HorizontalLoader color='blue' className={styles.loader} />
                </div>
            );
        }
        return showJoinForm ? (
            <JoinConferencePageMobileConnected onClickBack={() => setShowJoinForm(false)} />
        ) : (
            <div className={styles.wrapper} id='wrapper'>
                {showMenu && (
                    <MainMobileMenuConnected
                        closeCallback={() => setShowMenu(false)}
                        onJoin={() => setShowJoinForm(true)}
                    />
                )}
                {!hideHeaderFooter && !hideHeader && <AccountPageMobileHeaderConnected />}
                <div className={styles.content} ref={contentRef} data-page-content>
                    <Route
                        exact
                        path={[
                            '/account/conferences',
                            EPaths.UPCOMING_CONFERENCES,
                            '/account/conferences/past',
                        ]}
                        render={() => {
                            return <AccountConferencePageResponsive containerRef={contentRef} />;
                        }}
                    />
                    <Route
                        exact
                        path={`${EPaths.UPCOMING_CONFERENCES}/:conferenceId`}
                        render={({
                            match: {
                                params: { conferenceId },
                            },
                        }) => {
                            return (
                                <AccountConferenceDetailsPageResponsive
                                    conferenceId={conferenceId}
                                />
                            );
                        }}
                    />
                    <Route
                        exact
                        path={`${EPaths.PAST_CONFERENCES}/:conferenceId/sessions/:sessionId`}
                        render={({
                            match: {
                                params: { conferenceId, sessionId },
                            },
                        }) => {
                            return (
                                <AccountConferenceDetailsPageResponsive
                                    conferenceId={conferenceId}
                                    sessionId={sessionId}
                                />
                            );
                        }}
                    />
                    <Route
                        exact
                        path='/account/conferences/scheduling'
                        component={ConferenceScheduleSettingsFormMobileConnected}
                    />
                    <Route
                        exact
                        path={`${EPaths.EDIT_CONFERENCE}/:id`}
                        render={({
                            match: {
                                params: { id },
                            },
                        }) => <ConferenceSchedulingPageMobileConnected id={id} />}
                    />
                    <Route
                        exact
                        path='/account/settings'
                        component={AccountSettingsPageResponsive}
                    />
                    <Route
                        exact
                        path='/account/records'
                        component={ConferencesRecordsPageMobileConnected}
                    />
                    <Route
                        exact
                        path='/account/profile'
                        component={AccountInformationPageResponsive}
                    />
                </div>
                {!hideHeaderFooter && (
                    <AccountPageMobileFooterConnected onMenuClick={() => setShowMenu(true)} />
                )}
            </div>
        );
    };

    return render();
};
