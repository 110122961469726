import { IFormRenderProps } from 'components/forms/DynamicForm/types';
import { audioSettings } from 'constants/appSettingsStructure';
import { IAppSettings, IAppSettingsTyped } from 'types/app/IAppSettings';
import { TAppState } from 'types/app/TAppStore';
import { IDynamicSectionStructure } from 'types/dynamicForm/IDynamicSectionStructure';
import { getAppSettingsDefaultValues } from 'utils/getAppSettingsDefaultValues';

export interface IStateProps {
    structure: IDynamicSectionStructure;
    initialValues: IFormRenderProps;
}

export const mapStateToProps = (state: TAppState): IStateProps => ({
    structure: state.features.features.use3dAudio
        ? audioSettings
        : {
            ...audioSettings,
            fields: audioSettings.fields.filter((field) => field.name !== 'use3dAudio'),
        },
    initialValues: getAudioSettingsFormInitialValues(state.auth.appSettings),
});

const getAudioSettingsFormInitialValues = (appSettings: IAppSettings | null) => {
    if (appSettings) {
        const { ownAudioOnConnect, use3dAudio } = appSettings as IAppSettingsTyped;
        return {
            ownAudioOnConnect,
            use3dAudio: !!use3dAudio,
        };
    }
    return getAppSettingsDefaultValues([audioSettings]);
};
