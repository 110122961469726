import { connect } from 'react-redux';
import { TAppState } from 'types/app/TAppStore';
import { DatePicker } from './DatePicker';
import { IStateProps } from './types';

const mapStateToProps = (state: TAppState): IStateProps => ({
    lang: state.auth.currentUser?.language,
});

export const DatePickerConnected = connect(mapStateToProps)(DatePicker);
