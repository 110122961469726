import { HoverTooltip } from 'components/common/HoverTooltip/HoverTooltip';
import { useState } from 'react';
import { displayDeviceTooltip, hideDeviceTooltip } from 'utils/conferences/deviceTooltipUtils';
import { handleSelectDevice } from 'utils/conferences/handleSelectDevice';
import styles from './ConferenceDeviceItem.module.scss';
import { IOwnProps } from './types';

export const ConferenceDeviceItem: React.FC<IOwnProps> = ({
    device,
    className,
    idx,
    enabled,
    onSelectItem,
    closeDropDown,
}) => {
    const [showTooltip, setShowTooltip] = useState<boolean>(false);

    let timerId: any = undefined;

    const mouseEnterHandler = ({ currentTarget }: { currentTarget: HTMLDivElement }) => {
        timerId = displayDeviceTooltip(currentTarget, showTooltip, setShowTooltip);
    };
    const mouseLeaveHandler = () => {
        hideDeviceTooltip(timerId, showTooltip, setShowTooltip);
    };
    const clickHandler = () => {
        closeDropDown();
        handleSelectDevice(device, enabled, onSelectItem);
    };

    return (
        <div
            key={device.deviceId}
            className={className}
            onMouseEnter={mouseEnterHandler}
            onMouseLeave={mouseLeaveHandler}
            onClick={clickHandler}>
            {showTooltip && (
                <div className={styles.hoverWrapper}>
                    <HoverTooltip
                        name={
                            device.label === '' ? `Nameless device ${device.index}` : device.label
                        }
                        color='blue'
                    />
                </div>
            )}
            <span className={styles.deviceLabel} data-js-tooltip>
                {device.label === '' ? `Nameless device ${idx}` : device.label}
            </span>
        </div>
    );
};
