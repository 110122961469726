import { createAsyncThunk } from '@reduxjs/toolkit';
import { changeConferencesListFilterAction } from 'actions/sessionSettings/changeConferencesListFilterAction';
import { appHistory } from 'appHistory';
import { executePendingOperationThunkAction } from 'thunk/appView/executePendingOperationThunkAction';
import { getCurrentUserConferencesNewThunkAction } from 'thunk/conference/getCurrentUserConferencesNewThunkAction';
import { getConferencesRecordsThunkAction } from 'thunk/records/getConferencesRecordsThunkAction';
import { TAppState } from 'types/app/TAppStore';
import { IDateFilters } from 'types/IDateFilters';
import { getStartEndTimeUtc0 } from 'utils/timeZones/getStartEndTimeUtc0';

export const changeConferencesListFilterThunkAction = createAsyncThunk(
    'changeConferencesListFilter',
    async (filtersChanges: Partial<IDateFilters>, { dispatch, getState }) => {
        dispatch(changeConferencesListFilterAction(filtersChanges));
        const {
            sessionSettings: { conferencesFilter: filters },
        } = getState() as TAppState;
        await dispatch(
            executePendingOperationThunkAction({
                operation: `get${filters.finished ? 'Past' : 'Upcoming'}ConferencesListOperation`,
                pendingFunction: async () => {
                    if (filtersChanges.finished !== undefined) {
                        appHistory.push(
                            `/account/conferences/${filtersChanges.finished ? 'past' : 'upcoming'}`,
                        );
                    }
                    const { finished, past, upcoming } = filters;
                    const start = finished ? past.from : upcoming.from;
                    const end = finished ? past.to : upcoming.to;
                    const [from, to] = getStartEndTimeUtc0(start, end);
                    await dispatch(
                        getCurrentUserConferencesNewThunkAction({
                            from,
                            to,
                            type: finished ? 'past' : 'upcoming',
                            role: 'ANY',
                        }),
                    );
                    if (finished) {
                        await dispatch(getConferencesRecordsThunkAction({ from, to }));
                    }
                },
            }),
        );
    },
);
