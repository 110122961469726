import { SimpleEmailsEditor } from 'admin-app/components/SimpleEmailsEditor';
import { Field } from 'react-final-form';

export const EmailsField = ({
    name,
    containerClassName,
}: {
    name: string;
    containerClassName?: string;
}) => {
    return (
        <Field name={name}>
            {({ input }) => (
                <>
                    <input
                        {...input}
                        type='textarea'
                        style={{
                            position: 'absolute',
                            top: '0',
                            right: '0',
                            width: '1px',
                            height: '1px',
                            visibility: 'hidden',
                        }}
                    />
                    <SimpleEmailsEditor
                        setFormField={input.onChange}
                        inputValues={input.value}
                        containerClassName={containerClassName}
                    />
                </>
            )}
        </Field>
    );
};
