import { EVideoResolution } from 'constants/EVideoResolution';

export const calculateWidthAndHeight = (resolution: string): { width: number; height: number } => {
    if (resolution === EVideoResolution.LOW_RES) {
        return { height: 180, width: 320 };
    } else if (resolution === EVideoResolution.HI_RES) {
        return { height: 720, width: 1280 };
    } else if (resolution === EVideoResolution.FHD_RES) {
        return { height: 1080, width: 1920 };
    } else if (resolution === EVideoResolution.ULTRA_RES) {
        return { height: 2160, width: 3840 };
    }
    return { height: 360, width: 640 };
};
