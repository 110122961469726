import { INetworkService } from 'types/api/INetworkService';

interface IPayload {
    id: string;
    pin: string;
}

export const getConferenceParticipantsApiCall = (
    networkService: INetworkService,
    { id, pin }: IPayload,
) => {
    const formattedPin = pin ? `?pin=${pin}` : '';
    return networkService.get({ url: `/api/conferences/${id}/participants${formattedPin}` });
};
