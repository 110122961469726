import { AnyAction, createAsyncThunk, ThunkDispatch } from '@reduxjs/toolkit';
import { deleteConferenceInfoAction } from 'actions/conferences/deleteConferenceInfoAction';
import { deleteConferenceRecordAction } from 'actions/records/deleteConferenceRecordAction';
import { EPaths } from 'constants/EPaths';
import { ESocketAppActions } from 'constants/ESocketAppActions';
import { batch } from 'react-redux';
import { getAppEventsTopic } from 'services/socketService/getAppEventsTopic';
import { addSubscription, getSocketServiceState } from 'services/socketService/socketService';
import { deleteExistingUpcomingConferenceThunkAction } from 'thunk/conference/deleteExistingUpcomingConferenceThunkAction';
import { getConferenceConnectInfoThunkAction } from 'thunk/conference/getConferenceConnectInfoThunkAction';
import { getUpcomingConferenceDataThunkAction } from 'thunk/conference/getUpcomingConferenceDataThunkAction';
import { moveStartedConferenceThunkAction } from 'thunk/conference/moveStartedConferenceThunkAction';
import { storeNewPastConferenceThunkAction } from 'thunk/conference/storeNewPastConferenceThunkAction';
import { storeNewUpcomingConferenceThunkAction } from 'thunk/conference/storeNewUpcomingConferenceThunkAction';
import { updateExistingUpcomingConferenceStatusThunkAction } from 'thunk/conference/updateExistingUpcomingConferenceStatusThunkAction';
import { updateExistingUpcomingConferenceThunkAction } from 'thunk/conference/updateExistingUpcomingConferenceThunkAction';
import { setConferenceRecordThunkAction } from 'thunk/records/setConferenceRecordThunkAction';
import { TAppState } from 'types/app/TAppStore';
import { IUpcomingConferenceInfo } from 'types/conferencesList/IUpcomingConferenceInfo';
import { ISocketAppEvents } from 'types/socket/ISocketAppEvents';
import { getConnectToSocket } from 'utils/connectToSocketProxy';
import { isTargetPath } from 'utils/isTargetPath';
import { log } from 'utils/log';
import { redirect } from 'utils/redirect';
import { createConferenceEventsNotificationChecker } from 'utils/socket/Notifications/createConferenceEventsNotificationChecker';
import { createConferenceEventsNotificationDynamicDataChecker } from 'utils/socket/Notifications/createConferenceEventsNotificationDynamicDataChecker';
import { displayConferenceNotification } from 'utils/socket/Notifications/displayConferenceNotification';
import { recordNotifications } from 'utils/socket/Notifications/recordNotifications';

export const subscribeToAppEventsThunkAction = createAsyncThunk(
    'subscribeToAppEventsThunk',
    async (userId: string, { dispatch, getState }) => {
        getConnectToSocket()(() => {
            const { stompClient } = getSocketServiceState();
            if (!stompClient) {
                log.error('Stomp client does not init.');
                return;
            }
            addSubscription(
                'subscribeToAppEventsThunk',
                stompClient.subscribe(getAppEventsTopic(userId), async (message) => {
                    let data: ISocketAppEvents | undefined;
                    try {
                        data = JSON.parse(message.body);
                    } catch (e) {
                        log.error('Wrong message format');
                    }

                    if (!data) {
                        return;
                    }
                    if (data.type === 'CONFERENCE') {
                        processConference(data, dispatch, getState);
                    } else if (data.type === 'RECORD') {
                        processRecord(data, dispatch);
                    }
                }),
            );
        }, 'subscribeToAppEventsThunk');
    },
);

const processConference = async (
    //conferenceId === seriesId (when conference is recurring)
    {
        actionType,
        conferenceId,
        sessionId,
        recurring,
        typeChanged: isListUpdateRequired,
        activeConferenceId,
    }: ISocketAppEvents,
    dispatch: ThunkDispatch<unknown, unknown, AnyAction>,
    getState: any,
) => {
    let newUpcomingConferenceData: IUpcomingConferenceInfo | undefined;
    if (actionType === ESocketAppActions.CONFERENCE_STARTED) {
        // We need this so connect info would be updated and user would be redirected to conference page
        await dispatch(
            getConferenceConnectInfoThunkAction({ id: conferenceId, forceUpdate: true }),
        ).unwrap();
    }
    if (
        actionType === ESocketAppActions.CONFERENCE_STARTED ||
        actionType === ESocketAppActions.CONFERENCE_SCHEDULED ||
        actionType === ESocketAppActions.CONFERENCE_UPDATED
    ) {
        newUpcomingConferenceData = await dispatch(
            getUpcomingConferenceDataThunkAction({ conferenceId }),
        ).unwrap();
    }

    if (actionType === ESocketAppActions.CONFERENCE_DELETED) {
        const {
            conferencesList: { upcomingConferences },
        } = getState() as TAppState;
        batch(() => {
            for (const confId in upcomingConferences) {
                const conference = upcomingConferences[confId];
                if (conference.id === conferenceId || conference.seriesId === conferenceId) {
                    dispatch(deleteConferenceInfoAction(conference.id));
                }
            }
        });
    } else if (
        actionType === ESocketAppActions.CONFERENCE_STARTED ||
        actionType === ESocketAppActions.CONFERENCE_SCHEDULED ||
        actionType === ESocketAppActions.CONFERENCE_UPDATED ||
        actionType === ESocketAppActions.CONFERENCE_FINISHED
    ) {
        const getCurrentConferenceId = () => {
            const {
                conferenceSession: { currentConferenceId },
            } = getState() as TAppState;
            return currentConferenceId;
        };
        displayConferenceNotification({
            actionType,
            upcomingConferenceData: newUpcomingConferenceData,
            dispatch,
            getState,
            dynamicCheck: createConferenceEventsNotificationDynamicDataChecker(
                actionType,
                conferenceId,
                getCurrentConferenceId,
            ),
            checkForHiding: createConferenceEventsNotificationChecker(
                actionType,
                conferenceId,
                getCurrentConferenceId,
            ),
            handleMobileClick: () => redirect(`${EPaths.CONNECT_TO_CONFERENCE}/${conferenceId}`),
        });
    }
    if (isTargetPath(EPaths.UPCOMING_CONFERENCES)) {
        if (actionType === ESocketAppActions.CONFERENCE_UPDATED) {
            const {
                conferencesList: { upcomingConferences },
            } = getState() as TAppState;

            if (upcomingConferences[conferenceId]) {
                dispatch(
                    updateExistingUpcomingConferenceThunkAction({
                        newConferenceData: newUpcomingConferenceData,
                        isRecurring: recurring || isListUpdateRequired,
                    }),
                );
            } else {
                dispatch(
                    storeNewUpcomingConferenceThunkAction({
                        newConferenceData: newUpcomingConferenceData,
                        isRecurring: recurring || isListUpdateRequired,
                    }),
                );
            }
        } else if (actionType === ESocketAppActions.CONFERENCE_SCHEDULED) {
            dispatch(
                storeNewUpcomingConferenceThunkAction({
                    newConferenceData: newUpcomingConferenceData,
                    isRecurring: recurring,
                }),
            );
        } else if (actionType === ESocketAppActions.CONFERENCE_STARTED) {
            const {
                conferencesList: { upcomingConferences },
            } = getState() as TAppState;
            const id = recurring && activeConferenceId ? activeConferenceId : conferenceId;
            if (upcomingConferences[id]) {
                dispatch(
                    updateExistingUpcomingConferenceStatusThunkAction({
                        conferenceId: id,
                        newConferenceStatus: 'STARTED',
                    }),
                );
            } else {
                dispatch(
                    storeNewUpcomingConferenceThunkAction({
                        newConferenceData: newUpcomingConferenceData,
                        isRecurring: recurring,
                    }),
                );
            }
        } else if (actionType === ESocketAppActions.CONFERENCE_FINISHED) {
            dispatch(
                moveStartedConferenceThunkAction(
                    recurring && activeConferenceId ? activeConferenceId : conferenceId,
                ),
            );
        } else if (actionType === ESocketAppActions.CONFERENCE_DELETED) {
            dispatch(
                deleteExistingUpcomingConferenceThunkAction({
                    conferenceId: conferenceId,
                    isRecurring: recurring,
                }),
            );
        }
    }
    if (isTargetPath(EPaths.PAST_CONFERENCES)) {
        if (actionType === ESocketAppActions.CONFERENCE_FINISHED) {
            if (sessionId) {
                dispatch(storeNewPastConferenceThunkAction({ conferenceId, sessionId }));
            }
        }
    }
};

const processRecord = async (
    { actionType, conferenceId, sessionId, recordId }: ISocketAppEvents,
    dispatch: ThunkDispatch<unknown, unknown, AnyAction>,
) => {
    if (actionType === ESocketAppActions.RECORD_DELETED) {
        recordId && dispatch(deleteConferenceRecordAction(recordId));
    } else if (
        actionType === ESocketAppActions.RECORD_READY ||
        actionType === ESocketAppActions.RECORD_START_PROCESSING
    ) {
        const conference = await dispatch(
            getConferenceConnectInfoThunkAction({ id: conferenceId }),
        ).unwrap();
        if (conference) {
            recordNotifications(actionType, dispatch, conference.name);
            dispatch(
                setConferenceRecordThunkAction({
                    conferenceId,
                    sessionId,
                    timeStart: conference.timeStart,
                }),
            );
        }
    }
};
