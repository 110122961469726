import { connect } from 'react-redux';
import { getCurrentLanguageSelector } from 'selectors/getCurrentLanguageSelector';
import { TAppState } from 'types/app/TAppStore';
import { I18nWrapper } from './I18nWrapper';
import { IStateProps } from './types';

const mapStateToProps = (state: TAppState): IStateProps => ({
    currentLanguage: getCurrentLanguageSelector(state),
});
export const I18nWrapperConnected = connect(mapStateToProps)(I18nWrapper);
