import { connect } from 'react-redux';
import { loginThunkAction } from 'thunk/auth/loginThunkAction';
import { TAppDispatch } from 'types/app/TAppDispatch';
import { TAppState } from 'types/app/TAppStore';
import { LoginFormMobile } from './LoginFormMobile';
import { IDispatchProps, IStateProps } from './types';

const mapStateToProps = (state: TAppState): IStateProps => ({
    ldap: state.applicationConfiguration.config.ldap,
});

const mapDispatchToProps = (dispatch: TAppDispatch): IDispatchProps => ({
    login: (data) => dispatch(loginThunkAction({ ...data, rememberMe: true })),
});

export const LoginFormMobileConnected = connect(
    mapStateToProps,
    mapDispatchToProps,
)(LoginFormMobile);
