import { Trans } from '@lingui/react';
import { MobilePopup } from 'components/common/MobilePopup';
import { PinEnterFormMobileConnected } from 'components/forms/PinEnterForm/PinEnterFormMobile';
import React, { useEffect } from 'react';
import styles from './PinEnterPage.module.scss';
import { IOwnProps, IDispatchProps, IStateProps } from './types';

export const PinEnterPageMobile: React.FC<IOwnProps & IDispatchProps & IStateProps> = ({
    conferenceConnectInfo,    
    //currentConferenceId,
    id,
    loadData,
    goToHomePage,    
}) => {
    useEffect(() => {
        loadData(id);
    });
    return (
        <MobilePopup onClick={goToHomePage}>
            <div>
                <span className={styles.title}>
                    <Trans
                        message='Для подключения к этой конференции нужен код доступа'
                        id='To connect to this conference, you need an access code'
                    />
                </span>
                {conferenceConnectInfo && <PinEnterFormMobileConnected />}
            </div>
        </MobilePopup>
    );
};
