import { MobileWrapperConnected } from 'components/common/MobileWrapper';
import React from 'react';
import { ConferenceFinishedPageConnected } from './ConferenceFinishedPageConnected';
import { ConferenceFinishedPageMobileConnected } from './ConferenceFinishedPageMobileConnected';

export const ConferenceFinishedPageResponsive: React.FC<{ id: string }> = ({ id }) => (
    <MobileWrapperConnected
        renderDesktop={() => <ConferenceFinishedPageConnected id={id} />}
        renderMobile={() => <ConferenceFinishedPageMobileConnected id={id} />}
    />
);
