import { setDevicePropsAction } from 'actions/applicationView/setDevicePropsAction';
import { connect } from 'react-redux';
import { getApplicationConfigThunkAction } from 'thunk/applicationConfiguration/getApplicationConfigThunkAction';
import { checkCurrentProfileThunkAction } from 'thunk/auth/checkCurrentProfileThunkAction';
import { TAppDispatch } from 'types/app/TAppDispatch';
import { TAppState } from 'types/app/TAppStore';
import { redirect } from 'utils/redirect';
import { setDefaultDocumentTitle } from 'utils/setDefaultDocumentTitle';
import { LoginChecker } from './LoginChecker';
import { IDispatchProps, IStateProps } from './types';

const mapStateToProps = (state: TAppState): IStateProps => ({
    rememberMeRequestStatus: state.auth.rememberMeRequestStatus,
    deviceProps: state.applicationView.deviceProps,
    ldap: state.applicationConfiguration.config.ldap,
    isAuthUser: !!state.auth.currentUser,
});

const mapDispatchToProps = (dispatch: TAppDispatch): IDispatchProps => ({
    checkCurrentProfile: () => dispatch(checkCurrentProfileThunkAction()),
    updateDeviceProps: (props) => dispatch(setDevicePropsAction(props)),
    startup: async () => {
        setDefaultDocumentTitle();
        const currentPath = location.pathname;
        if (!currentPath.match('/error')) {
            const response = await dispatch(getApplicationConfigThunkAction());
            if (!response.payload) {
                redirect('/error');
            }
        }
    },
});

export const LoginCheckerConnected = connect(mapStateToProps, mapDispatchToProps)(LoginChecker);
