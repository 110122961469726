import { EChatMessageStatus } from 'constants/EChatMessageStatus';
import React from 'react';
import check from 'assets/Check.svg';
import styles from './ConferenceChatMessageStatus.module.scss';
import { IOwnProps } from './types';

export const ConferenceChatMessageStatus: React.FC<IOwnProps> = ({ messageStatus }) => (
    <div
        className={`${styles.chatMessage__infoStatus}  
            ${messageStatus === EChatMessageStatus.DELIVERED ? styles['chatMessage__infoStatus_delivered'] : ''}
            ${messageStatus === EChatMessageStatus.IN_PROGRESS ? styles['chatMessage__infoStatus_inProgress'] : ''}`}>
        <img src={check} alt='check' />
    </div>
);
