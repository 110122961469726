import { FC } from 'react';

interface IProps {
    className?: string
}

export const UserIcon: FC<IProps> = ({ className }) => (
    <svg
        width="24"
        height="21"
        viewBox="0 0 20 25"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        className={className}
    >
        <path
            d="M0.666668 24.6667C0.666668 22.1913 1.65 19.8174 3.40034 18.067C5.15068 16.3167 7.52465 15.3334 10 15.3334C12.4754 15.3334 14.8493 16.3167 16.5997 18.067C18.35 19.8174 19.3333 22.1913 19.3333 24.6667H17C17 22.8102 16.2625 21.0297 14.9497 19.7169C13.637 18.4042 11.8565 17.6667 10 17.6667C8.14348 17.6667 6.36301 18.4042 5.05025 19.7169C3.7375 21.0297 3 22.8102 3 24.6667H0.666668ZM10 14.1667C6.1325 14.1667 3 11.0342 3 7.16669C3 3.29919 6.1325 0.166687 10 0.166687C13.8675 0.166687 17 3.29919 17 7.16669C17 11.0342 13.8675 14.1667 10 14.1667ZM10 11.8334C12.5783 11.8334 14.6667 9.74502 14.6667 7.16669C14.6667 4.58835 12.5783 2.50002 10 2.50002C7.42167 2.50002 5.33333 4.58835 5.33333 7.16669C5.33333 9.74502 7.42167 11.8334 10 11.8334Z"
            fill="currentColor"
        />
    </svg>
)
