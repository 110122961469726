import { Select } from 'components/common/Select';
import { IStateProps, IDispatchProps, IItemType } from './types';
import { getItems } from './utils';
import styles from './MobileLayoutSelect.module.scss';

export const MobileLayoutSelect: React.FC<IStateProps & IDispatchProps> = ({
    layoutType,
    participants,
    selectedParticipantId,
    setLayoutType,
}) => {
    const items = getItems(participants);
    let selectedItem: IItemType = items[0];
    if (layoutType === 'talking') {
        selectedItem = items[1];
    } else if (layoutType === 'default' && selectedParticipantId) {
        const item = items.find((item) => item.value.participantId === selectedParticipantId);
        if (item) {
            selectedItem = item;
        }
    }

    return (
        <Select
            className={styles.layoutSelect}
            selectedValue={selectedItem}
            items={items}
            theme='dark'
            changeHandler={setLayoutType}
        />
    );
};
