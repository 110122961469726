import { createAsyncThunk } from '@reduxjs/toolkit';
import { setWaitingListAction } from 'actions/lobby/setWaitingListAction';
import { getNetworkService } from 'api/getNetworkService';
import { getWaitingListApiCall } from 'api/lobby/getWaitingListApiCall';
import { getCurrentConferenceConnectInfoSelector } from 'selectors/getCurrentConferenceConnectInfoSelector';
import { TAppState } from 'types/app/TAppStore';

export const setWaitingListThunkAction = createAsyncThunk(
    'setWaitingList',
    async (conferenceId: string, { dispatch, getState }) => {
        const {
            conferenceSession: { currentUserIsOwner },
        } = getState() as TAppState;
        const conference = getCurrentConferenceConnectInfoSelector(getState() as TAppState);
        if (conference && currentUserIsOwner) {
            const response = await getWaitingListApiCall(getNetworkService(), conferenceId);
            if (response.success) {
                dispatch(setWaitingListAction(response.data));
            }
        }
    },
);
