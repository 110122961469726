import { INetworkService } from 'types/api/INetworkService';

interface IPayload {
    email?: string;
    timeZone?: string;
    password: string;
    token: string;
}

export const inviteRegisterApiCall = (networkService: INetworkService, data: IPayload) =>
    networkService.post({
        url: '/api/account/invite/confirm',
        data,
    });
