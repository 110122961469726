import { IOwnProps } from './types';
import styles from './ConferenceChatMessage.module.scss';
import { ConferenceChatMessageContent } from './ConferenceChatMessageContent';
import { ConferenceChatMessageInfo } from './ConferenceChatMessageInfo';
import { ConferenceChatMessageAuthor } from './ConferenceChatMessageAuthor/ConferenceChatMessageAuthor';
import { EChatMessageType } from 'constants/EChatMessageType';
import { isImageFile } from 'utils/files/isImageFile';

export const ConferenceChatMessage: React.FC<IOwnProps> = ({
    isOwnMessage,
    name,
    text,
    contentType: type,
    status,
    conferenceId,
    sessionId,
    time,
    files,
    authorId,
}) => {
    const isEmotionMessage = type === EChatMessageType.EMOTION;
    const emotionMessageStyle = styles.messageContainer_emotion;

    const getOwnerComputedContainerStyles = () => {
        if (isOwnMessage) {
            if (files?.length && isImageFile(files[0].name)) {
                return styles.messageContainer_ownImageMessage;
            }
            return styles.messageContainer_ownMessage;
        }
        return ''
    };

    const getMessageComputedStyles = () => {
        if (files?.length && isImageFile(files[0].name)) {
            return styles.chatMessage_img
        }
        return ''
    }

    const messageContainerComputedStyles = `${getOwnerComputedContainerStyles()} ${
        isEmotionMessage ? emotionMessageStyle : ''
    }`;

    return (
        <div className={`${styles.messageContainer} ${messageContainerComputedStyles}`}>
            {!isOwnMessage && !isEmotionMessage && <ConferenceChatMessageAuthor name={name} />}
            <div className={`${styles.chatMessage} ${getMessageComputedStyles()}`}>
                <ConferenceChatMessageContent
                    type={type}
                    text={text}
                    conferenceId={conferenceId}
                    sessionId={sessionId}
                    authorId={authorId}
                    time={time}
                    files={files}
                    isOwnMessage={isOwnMessage}
                />
                {type !== EChatMessageType.EMOTION && (
                    <ConferenceChatMessageInfo
                        time={time}
                        isOwnMessage={isOwnMessage}
                        status={status}
                        isInsideContent={!!(files?.length && isImageFile(files[0].name))}
                    />
                )}
            </div>
        </div>
    );
};
