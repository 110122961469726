import { t } from '@lingui/macro';
import { Trans } from '@lingui/react';
import { Button } from 'components/common/Button';
import { EmailListEditorWrapper } from 'components/common/EmailListEditor/EmailListEditorWrapper';
import { Input } from 'components/common/Input';
import { MobileFormBlock } from 'components/common/MobileFormBlock';
import { SelectField } from 'components/common/SelectField';
import { Spinner } from 'components/common/Spinner';
import { StatusLabel } from 'components/common/StatusLabel';
import { ToggleField } from 'components/common/ToggleField';
import { useConferenceDeleteDialog } from 'hooks/useConferenceDeleteDialog';
import { useRef } from 'react';
import { Form, FormRenderProps } from 'react-final-form';
import { classNames } from 'utils/classNames';
import { isFormHasValidationErrors } from 'utils/isFormHasValidationErrors';
import { getTimeZonesNamesList } from 'utils/timeZones/getTimeZonesNamesList';
import { getComboValues } from './ConferenceAudioVideoSettings/utils';
import { ConferenceRepeatBlockMobile } from './ConferenceRepeatBlock/ConferenceRepeatBlockMobile';
import styles from './ConferenceScheduleSettingsFormMobile.module.scss';
import { IDispatchProps, IFormProps, IOwnProps, IStateProps } from './types';
import {
    checkDurationHours,
    checkDurationMinutes,
    formatOnStartDateBlur,
    formatOnStartTimeBlur,
    getConferenceValues,
    getDefaultFormProps,
    handleSubmitClick,
    onAccountPage,
    validate,
    validateOnStartDateBlur,
    validateOnStartTimeBlur,
} from './utils';

const timeZonesNameList = getTimeZonesNamesList();

export const ConferenceScheduleSettingsFormMobile: React.FC<
    IStateProps & IDispatchProps & Partial<IOwnProps>
> = ({
    conferenceInfo,
    currentUser,
    saveConference,
    goToConferencesList,
    updateConference,
    handleAfterSubmit,
    onModified,
    resetModified,
    showNotification,
    deleteConference,
    handleCancel,
}) => {
    // Ref is used here because after the exit dialog is shown, the form is rerender and the entered values are reset to default
    const initialValues = useRef(
        conferenceInfo ? getConferenceValues(conferenceInfo) : getDefaultFormProps(),
    );
    const [deleteConferenceHandler, deleteConferenceConfirmDialogRender] =
        useConferenceDeleteDialog(currentUser, deleteConference);
    const onSubmitClick = async (values: IFormProps) => {
        if (values.operation === 'save') {
            delete values.operation;
            const errors = await handleSubmitClick(
                values,
                {
                    goToConferencesList,
                    saveConference,
                    showNotification,
                    updateConference,
                },
                conferenceInfo,
                handleAfterSubmit,
            );
            if (!errors) {
                resetModified();
            }
            return errors;
        }
        if (values.operation === 'cancel') {
            if (handleCancel) {
                handleCancel();
            }
            if (handleAfterSubmit) {
                handleAfterSubmit();
            }
            goToConferencesList();
        } else if (values.operation === 'delete' && conferenceInfo) {
            deleteConferenceHandler(conferenceInfo);
        }
        resetModified();
    };

    const inputStyleType = 'mobile';
    const likeInputStyle = styles.likeInput;
    const noteStyle = styles.note;

    const render = ({
        handleSubmit,
        values,
        dirty,
        form,
        submitting,
        submitError,
        errors,
        dirtySinceLastSubmit,
    }: FormRenderProps<IFormProps>) => {
        setTimeout(() => onModified(dirty), 0);
        return (
            <form onSubmit={handleSubmit} className={styles.form}>
                {deleteConferenceConfirmDialogRender()}
                <div
                    className={classNames([
                        styles.header,
                        submitting ? styles.header_disabled : '',
                    ])}>
                    <button
                        onClick={() => form.change('operation', 'cancel')}
                        disabled={submitting}>
                        <Trans id='Cancel' />
                    </button>
                    {submitting && <Spinner wrapperClass='spinner_formMobileLoader' />}
                    <button
                        onClick={() => form.change('operation', 'save')}
                        disabled={submitting || isFormHasValidationErrors(errors)}>
                        <Trans id='Ready' />
                    </button>
                </div>
                <div className={styles.submitErrorWrapper}>
                    <StatusLabel text={submitError && !dirtySinceLastSubmit ? submitError : ''} />
                </div>
                <MobileFormBlock
                    annotation={t({ id: 'Theme', message: 'Тема' })}
                    className={styles.margin_top_29}>
                    <Input
                        name='topic'
                        placeholder={t({
                            id: 'Enter the conference theme',
                            message: 'Введите тему конференции',
                        })}
                        styleType={inputStyleType}
                        autoFocus={true}
                        highlightTextOnError={!!errors?.topic}
                    />
                </MobileFormBlock>
                <MobileFormBlock
                    annotation={t({ id: 'Description', message: 'Описание' })}
                    className={styles.margin_top_14}>
                    <Input
                        name='description'
                        placeholder={t({
                            id: 'Enter a description',
                            message: 'Введите описание',
                        })}
                        styleType={inputStyleType}
                    />
                </MobileFormBlock>

                <MobileFormBlock
                    annotation={t({ id: 'When', message: 'Когда' })}
                    className={classNames([styles.multi_row, styles.margin_top_24])}>
                    {!values.recurringData && (
                        <>
                            <div className={likeInputStyle}>
                                <div>
                                    <Trans id='Date' message='Дата' />
                                </div>
                                <Input
                                    name='startDate'
                                    placeholder={t({
                                        id: 'dd.mm.yyyy',
                                        message: 'дд.мм.гггг',
                                    })}
                                    styleType={inputStyleType}
                                    highlightTextOnError={!!errors?.startDate}
                                    type='text'
                                    onBlurHandlers={{
                                        validateOnBlur: validateOnStartDateBlur,
                                        formatOnBlur: formatOnStartDateBlur,
                                    }}
                                />
                            </div>
                            <div className={likeInputStyle}>
                                <div>
                                    <Trans id='Time' message='Время' />
                                </div>
                                <Input
                                    name='startTime'
                                    placeholder={t({ id: 'hh:mm', message: 'чч:мм' })}
                                    type='text'
                                    styleType={inputStyleType}
                                    highlightTextOnError={!!errors?.startTime}
                                    onBlurHandlers={{
                                        validateOnBlur: validateOnStartTimeBlur,
                                        formatOnBlur: formatOnStartTimeBlur,
                                    }}
                                />
                            </div>
                        </>
                    )}
                    <div className={likeInputStyle}>
                        <div>
                            <Trans id='Duration' message='Продолжительность' />
                        </div>
                        <div className={styles.duration}>
                            <Input
                                name='durationHours'
                                placeholder={t({ id: 'hh:mm', message: 'чч:мм' })}
                                styleType={inputStyleType}
                                parse={checkDurationHours}
                                highlightTextOnError={!!errors?.durationHours}
                            />
                            <span>
                                <Trans id='h' message='ч' />
                            </span>
                            <Input
                                name='durationMinutes'
                                parse={checkDurationMinutes}
                                placeholder={t({ id: 'hh:mm', message: 'чч:мм' })}
                                styleType={inputStyleType}
                                highlightTextOnError={!!errors?.durationMinutes}
                            />
                            <span>
                                <Trans id='m' message='м' />
                            </span>
                        </div>
                    </div>
                    {conferenceInfo?.seriesId === undefined && (
                        <ConferenceRepeatBlockMobile
                            updateField={form.change}
                            recurringData={values.recurringData}
                            specifiedDate={values.startDate}
                            specifiedTime={values.startTime}
                        />
                    )}
                </MobileFormBlock>
                <MobileFormBlock
                    annotation={t({ id: 'Time zone', message: 'Часовой пояс' })}
                    className={styles.margin_top_24}>
                    <div className={styles.timeZone}>
                        <SelectField
                            className={styles.select}
                            items={timeZonesNameList}
                            name='timeZone'
                            styleType='mobile'
                            theme='light'
                        />
                    </div>
                </MobileFormBlock>
                <MobileFormBlock
                    annotation={t({ id: 'scheduling.invites.id', message: 'Приглашения' })}
                    className={styles.margin_top_24}>
                    <EmailListEditorWrapper name='invitedUsers' />
                </MobileFormBlock>

                <MobileFormBlock
                    annotation={t({ message: 'Безопасность', id: 'Security' })}
                    className={styles.margin_top_24}>
                    <div className={likeInputStyle}>
                        <div>
                            <Trans
                                id='Only for registered users'
                                message='Только для зарегистрированных пользователей'
                            />
                        </div>
                        <ToggleField
                            styleType='mobile'
                            value={values.lobby}
                            name='registeredOnly'
                        />
                    </div>
                    <div
                        className={classNames([likeInputStyle, styles.likeInput_disabled])}
                        style={{ borderBottom: 'none' }}>
                        <div>
                            <Trans id='Pin' message='Код доступа' />
                        </div>
                        <ToggleField
                            styleType='mobile'
                            value={values.pinUse}
                            name='pinUse'
                            disabled={true}
                        />
                    </div>
                    <Input
                        name='pin'
                        placeholder={t({ message: 'Код доступа', id: 'Pin' })}
                        styleType={inputStyleType}
                        highlightTextOnError={!!errors?.pin}
                    />
                    <div className={noteStyle}>
                        <Trans id='Only users with an invitation link or an access code can enter the conference' />
                    </div>
                    <div className={likeInputStyle} style={{ marginTop: '19px' }}>
                        <div>
                            <Trans id='Waiting room' message='Зал ожидания' />
                        </div>
                        <ToggleField styleType='mobile' value={values.lobby} name='lobby' />
                    </div>
                    <div className={noteStyle}>
                        <Trans id='Only users approved by the organizer can enter the conference' />
                    </div>
                </MobileFormBlock>
                <MobileFormBlock
                    annotation={t({
                        message: 'Видео',
                        id: 'Video',
                    })}
                    className={styles.margin_top_31}>
                    <div className={likeInputStyle}>
                        <div>
                            <Trans id='Organizer' message='Организатор' />
                        </div>
                        <ToggleField
                            styleType='mobile'
                            value={values.videoOwner}
                            name='videoOwner'
                        />
                    </div>
                    <div className={likeInputStyle} style={{ marginTop: 0, borderTop: 'none' }}>
                        <div>
                            <Trans id='Participants' message='Участник' />
                        </div>
                        <SelectField
                            className={styles.form__select}
                            items={getComboValues()}
                            name='participantsVideoPermission'
                            styleType='mobile'
                            theme='light'
                            displayFilteredHeader={true}
                        />
                    </div>
                </MobileFormBlock>
                <MobileFormBlock
                    annotation={t({
                        id: 'Audio',
                    })}
                    className={classNames([styles.margin_top_31])}>
                    <div className={likeInputStyle}>
                        <div>
                            <Trans id='Participants' message='Участник' />
                        </div>
                        <SelectField
                            className={styles.form__select}
                            items={getComboValues()}
                            name='participantsAudioPermission'
                            styleType='mobile'
                            theme='light'
                            displayFilteredHeader={true}
                        />
                    </div>
                </MobileFormBlock>
                <MobileFormBlock
                    annotation={t({
                        message: 'Параметры конференции',
                        id: 'Conference parameters',
                    })}
                    className={`${styles.margin_top_31} ${styles.margin_bottom_40}`}>
                    <div className={likeInputStyle} style={{ marginTop: 0, borderTop: 'none' }}>
                        <div>
                            <Trans id='Record a conference' message='Записывать конференцию' />
                        </div>
                        <ToggleField styleType='mobile' value={values.recording} name='recording' />
                    </div>
                </MobileFormBlock>
                {conferenceInfo && (
                    <div className={styles.button}>
                        <Button
                            styleType='deleteMobile'
                            caption={t({ id: 'Delete conference', message: 'Удалить конференцию' })}
                            clickHandler={() => form.change('operation', 'delete')}
                            disabled={submitting}
                        />
                    </div>
                )}
            </form>
        );
    };
    return (
        <Form
            onSubmit={onSubmitClick}
            validate={(values) => validate(values, conferenceInfo)}
            render={render}
            initialValues={initialValues.current}
        />
    );
};
