import { Field } from 'react-final-form';
import { EmailListEditorResponsive } from '..';
import styles from './EmailListEditorWrapper.module.scss';

export const EmailListEditorWrapper = ({
    name,
    tip,
    containerClassName,
}: {
    name: string;
    tip?: string;
    containerClassName?: string;
}) => {
    return (
        <Field name={name}>
            {({ input }) => (
                <>
                    <input {...input} type='textarea' className={styles.hide} />
                    <EmailListEditorResponsive
                        setFormField={input.onChange}
                        inputValues={input.value}
                        containerClassName={containerClassName}
                        tip={tip}
                    />
                </>
            )}
        </Field>
    );
};
