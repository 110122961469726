import { INetworkService } from 'types/api/INetworkService';

interface IPayload {
    conferenceId: string;
    participantId: string;
    streamType: 'AUDIO' | 'VIDEO';
    mute: boolean;
}

export const moderateParticipantApiCall = (
    networkService: INetworkService,
    { conferenceId, participantId, mute, streamType }: IPayload,
) =>
    networkService.put({
        url: `/api/conferences/${conferenceId}/participants/${participantId}/moderate`,
        data: {
            streamType,
            mute,
        },
    });
