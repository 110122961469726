import { t } from '@lingui/macro';
import { Trans } from '@lingui/react';
import { Button } from 'components/common/Button';
import { Input } from 'components/common/Input';
import { StatusLabel } from 'components/common/StatusLabel';
import { Form, FormRenderProps } from 'react-final-form';
import { isEmailValid } from 'utils/isEmailValid';
import { isFormHasValidationErrors } from 'utils/isFormHasValidationErrors';
import styles from './LoginFormMobile.module.scss';
import { IDispatchProps, IFormRenderProps, IOwnProps, IStateProps } from './types';
import { handleSubmit } from './utils';

export const LoginFormMobile: React.FC<
    IStateProps & IDispatchProps & Pick<IOwnProps, 'successCallback'>
> = ({ ldap, login, successCallback }) => {
    const onSubmit = async (values: IFormRenderProps) => {
        return await handleSubmit(values, { login }, successCallback);
    };
    const validate = (values: IFormRenderProps) => {
        const errors: { [key: string]: string } = {};
        const requiredError = t({ id: 'RequiredField' });
        if (ldap) {
            if (!values.ldap) {
                errors.ldap = requiredError;
            }
        } else {
            if (!values.email) {
                errors.email = requiredError;
            } else if (!isEmailValid(values.email)) {
                errors.email = t({
                    id: 'Your email address must be entered in the format name@domain.com',
                    message: 'Ваш адрес электронной почты должен быть введен в формате name@domain.com',
                });
            }
        }
        if (!values.password) {
            errors.password = requiredError;
        }
        return errors;
    };

    const render = ({
        handleSubmit,
        submitting,
        errors,
        dirtySinceLastSubmit,
        submitErrors,
    }: FormRenderProps<IFormRenderProps>) => (
        <form onSubmit={handleSubmit} className={styles.form}>
            <StatusLabel
                text={
                    submitErrors?.emailPassword && !dirtySinceLastSubmit
                        ? t({ id: 'Wrong login/password' })
                        : ''
                }
                className={styles.fail}
            />
            <div className={styles.block}>
                <label className={styles.label} htmlFor={ldap ? 'ldap' : 'email'}>
                    {ldap ? (
                        <Trans id='User name' message='Имя пользователя' />
                    ) : (
                        <Trans id='Email' message='Электронная почта' />
                    )}
                </label>
                <Input
                    placeholder={
                        ldap
                            ? t({ id: 'User name', message: 'Имя пользователя' })
                            : t({ id: 'Email', message: 'Электронная почта' })
                    }
                    name={ldap ? 'ldap' : 'email'}
                    type='text'
                    touched={false}
                    errorText={ldap ? errors?.ldap : errors?.email}
                    errorWithoutConditions={errors?.emailPassword}
                    tabIndex={1}
                    autoFocus={true}
                    styleType='mobile'
                />
            </div>
            <div className={styles.block}>
                <label className={styles.label} htmlFor='password'>
                    <Trans id='Password' message='Пароль' />
                </label>
                <Input
                    placeholder={t({ id: 'Password', message: 'Пароль' })}
                    name='password'
                    type='password'
                    className={styles.inputStyle}
                    touched={false}
                    errorText={errors?.password}
                    errorWithoutConditions={errors?.emailPassword}
                    tabIndex={2}
                    styleType='mobile'
                />
            </div>

            <div className={styles.buttons}>
                <Button
                    styleType='mobilePrimaryAction'
                    caption={t({ id: 'FormLoginButton', message: 'Войти' })}
                    disabled={submitting}
                    type='submit'
                    tabIndex={3}
                />
            </div>
            <Button
                styleType='mobileLink'
                caption={t({ id: 'Forgot password', message: 'Забыли пароль?' })}
                disabled={submitting || isFormHasValidationErrors(errors)}
                type='submit'
                tabIndex={3}
                clickHandler={() => {}}
            />
        </form>
    );

    return <Form onSubmit={onSubmit} validate={validate} render={render} />;
};
