import {
    flexRender,
    getCoreRowModel,
    getSortedRowModel,
    useReactTable,
} from '@tanstack/react-table';
import { IOwnProps } from 'admin-app/components/common/Table/types';
import { PropsWithChildren, useCallback, useEffect, useRef, useState } from 'react';
import { useVirtual } from 'react-virtual';
import styles from './Table.module.scss';
import { CircleLoader } from 'components/common/CircleLoader';
import { classNames } from 'utils/classNames';
import { scrollTableToTopEvent } from './utils';

export const Table = <T,>({
    columns,
    data,
    gridStyles,
    showLoader,
    onScroll,
    onItemClick = () => {},
}: PropsWithChildren<IOwnProps<T>>) => {
    const tableContainerRef = useRef<HTMLDivElement>(null);
    const table = useReactTable({
        data: data,
        columns,
        getCoreRowModel: getCoreRowModel(),
        getSortedRowModel: getSortedRowModel(),
        debugTable: true,
    });

    const [isNeedSetTableScroll, setIsNeedSetTableScroll] = useState(false);

    const { rows } = table.getRowModel();
    const rowVirtualizer = useVirtual({
        parentRef: tableContainerRef,
        size: rows.length,
        estimateSize: useCallback(() => 86, []),
        overscan: 5,
    });
    const { virtualItems: virtualRows, totalSize } = rowVirtualizer;

    const onScrollToTop = () => setIsNeedSetTableScroll(true);

    const scrollTable = () => {
        const foo = tableContainerRef.current;
        foo?.scrollTo({
            top: 0,
        });
        setIsNeedSetTableScroll(false);
    };

    useEffect(() => {
        if (isNeedSetTableScroll) {
            scrollTable();
        }
    }, [data]);

    useEffect(() => {
        window.addEventListener(scrollTableToTopEvent, onScrollToTop);
        return () => {
            window.removeEventListener(scrollTableToTopEvent, onScrollToTop);
        };
    }, []);

    return (
        <div
            className={classNames([
                styles.container,
                showLoader ? styles.container_withLoader : '',
            ])}
            ref={tableContainerRef}
            onScroll={onScroll}>
            <table>
                <thead>
                    {table.getHeaderGroups().map((headerGroup) => (
                        <tr
                            key={headerGroup.id}
                            className={styles.containerHead}
                            style={gridStyles}>
                            {headerGroup.headers.map((header) => (
                                <th
                                    key={header.id}
                                    colSpan={header.colSpan}
                                    style={{
                                        width: gridStyles ? 'inherit' : header.getSize(),
                                    }}>
                                    {flexRender(
                                        header.column.columnDef.header,
                                        header.getContext(),
                                    )}
                                </th>
                            ))}
                        </tr>
                    ))}
                </thead>
                <tbody
                    style={{
                        height: `${totalSize}px`,
                        width: '100%',
                        position: 'relative',
                    }}>
                    {virtualRows.map((virtualRow) => {
                        const row = rows[virtualRow.index];
                        // const pos = virtualRow.start > 0 ? virtualRow.start / 2 : virtualRow.start
                        const pos = virtualRow.start;
                        return (
                            <tr
                                key={virtualRow.index}
                                ref={virtualRow.measureRef}
                                style={{
                                    ...gridStyles,
                                    ...{
                                        top: 0,
                                        left: 0,
                                        position: 'absolute',
                                        width: '100%',
                                        transform: `translateY(${pos}px)`,
                                    },
                                }}
                                onClick={() => onItemClick(data[row.index])}>
                                {row.getVisibleCells().map((cell) => {
                                    return (
                                        <td key={cell.id}>
                                            {flexRender(
                                                cell.column.columnDef.cell,
                                                cell.getContext(),
                                            )}
                                        </td>
                                    );
                                })}
                            </tr>
                        );
                    })}
                    {showLoader && (
                        <tr className={styles.loaderContainer}>
                            <CircleLoader color='blue' />
                        </tr>
                    )}
                </tbody>
            </table>
        </div>
    );
};
