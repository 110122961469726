import { t } from '@lingui/macro';
import { DynamicFormConnected } from 'components/forms/DynamicForm/DynamicFormConnected';
import { AccountSettingsTemplate } from '../AccountSettingsTemplate/AccountSettingsTemplate';
import { IStateProps } from './utils';

export const AccountAudioSettings: React.FC<IStateProps> = ({ structure, initialValues }) => (
    <AccountSettingsTemplate
        settingsTitle={t({
            id: 'accountSetting.audio.title',
            message: 'Настройки аудио',
        })}
        settingsDescription={t({
            id: 'accountSetting.audio.description',
            message: 'Настройки аудиоустройств.',
        })}>
        <DynamicFormConnected
            structure={[structure]}
            initialValues={initialValues}
        />
    </AccountSettingsTemplate>
);
