import { resetConferenceSessionStoreAction } from 'actions/conferenceSession/resetConferenceSessionStoreAction';
import { connect } from 'react-redux';
import { getCurrentConferenceConnectInfoSelector } from 'selectors/getCurrentConferenceConnectInfoSelector';
import { joinCurrentConferenceMobileThunkAction } from 'thunk/conference/joinCurrentConferenceMobileThunkAction';
import { loadDataForConnectAndConferencePagesThunkAction } from 'thunk/conference/loadDataForConnectAndConferencePagesThunkAction';
import { copyToClipboardThunkAction } from 'thunk/navigator/copyToClipboardThunkAction';
import { subscribeToConferenceChangeStatusThunkAction } from 'thunk/socket/subscribeToConferenceChangeStatusThunkAction';
import { TAppDispatch } from 'types/app/TAppDispatch';
import { TAppState } from 'types/app/TAppStore';
import { ConnectToConferencePageMobile } from './ConnectToConferencePageMobile';
import { IMobileDispatchProps, IMobileStateProps } from './types';

const mapStateToProps = (state: TAppState): IMobileStateProps => {
    const conferenceData = getCurrentConferenceConnectInfoSelector(state);
    const {
        auth: { appSettings },
        conferenceSession: { currentUserIsOwner },
    } = state;
    let ownVideoOnConnect = appSettings ? appSettings.ownVideoOnConnect : true;
    let ownAudioOnConnect = appSettings ? appSettings.ownAudioOnConnect : true;
    let audioForbidden = false;
    let videoForbidden = false;
    if (conferenceData) {
        if (!currentUserIsOwner) {
            if (conferenceData.muteUponEntry) {
                ownAudioOnConnect = false;
            }
            if (!conferenceData.videoParticipants) {
                ownVideoOnConnect = false;
            }
        } else {
            if (!conferenceData.videoCreator) {
                ownVideoOnConnect = false;
            }
        }
        audioForbidden = conferenceData.forbidAudio;
        videoForbidden = conferenceData.forbidVideo;
    }
    return {
        conferenceName: conferenceData?.name || '',
        conferenceTimeStart: conferenceData?.timeStart || '',
        conferenceConnectInfo: conferenceData,
        conferencePin: state.conferenceSession.currentConferencePin || '',
        conferenceStatus: conferenceData?.status || null,
        currentUserIsOwner,
        isPortrait: state.applicationView.deviceProps.isPortrait,
        ownVideoOnConnect,
        ownAudioOnConnect,
        audioForbidden,
        videoForbidden,
    };
};

const mapDispatchToProps = (dispatch: TAppDispatch): IMobileDispatchProps => ({
    loadData: (id) =>
        dispatch(loadDataForConnectAndConferencePagesThunkAction({ id, page: 'connect' })),
    joinToConference: (args) => dispatch(joinCurrentConferenceMobileThunkAction(args)).unwrap(),
    subscribeToConferenceChangeStatus: () =>
        dispatch(subscribeToConferenceChangeStatusThunkAction()),
    copyToClipboard: (payload) => dispatch(copyToClipboardThunkAction(payload)),
    resetSessionStore: () => dispatch(resetConferenceSessionStoreAction()),
});

export const ConnectToConferencePageMobileConnected = connect(
    mapStateToProps,
    mapDispatchToProps,
)(ConnectToConferencePageMobile);
