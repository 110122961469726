import { Trans } from '@lingui/react';
import { compareAsc } from 'date-fns';
import { classNames } from 'utils/classNames';
import { convertDateToStringByTemplate } from 'utils/dates/convertDateToStringByTemplate';
import { convertStringToDateByTemplate } from 'utils/dates/convertStringToDateByTemplate';
import { getCurrentDateWithTimezone } from 'utils/dates/getCurrentDateWithTimezone';
import styles from './License.module.scss';
import { IStateProps } from './types';

export const License: React.FC<IStateProps> = ({ expirationDate }) => {
    if (!expirationDate) {
        return <div></div>;
    }
    const expireDate = convertStringToDateByTemplate(
        expirationDate,
        'yyyy-MM-dd HH:mm:ss.SSSSSS',
    );
    const resultDateString = convertDateToStringByTemplate(expireDate, 'dd/MM/yyyy');
    const currentDate = getCurrentDateWithTimezone();
    currentDate.setHours(0, 0, 0, 0);
    const compareResult = compareAsc(expireDate, currentDate);
    const isInactiveLicense = compareResult < 0;

    return (
        <div className={styles.license}>
            {/* <Button className={styles.license__download}>
                <Trans id='admin.downloadLicense' message='Загрузить лицензию' />
            </Button>
            <div className={styles.line}></div> */}
            <div className={styles.license__info}>
                {/* <div className={styles.license__infoBlock}>
                    <span className={styles.license__infoLabel}>
                        <Trans id='admin.tariff' message='Тариф' />
                    </span>
                    <span className={styles.license__infoText}>Lite</span>
                </div> */}
                <div className={styles.license__infoBlock}>
                    <span className={styles.license__infoLabel}>
                        <Trans id='admin.activeUntil' message='Действует до' />
                    </span>
                    <span className={styles.license__infoText}>{resultDateString}</span>
                </div>
                <div className={styles.license__infoBlock}>
                    <span className={styles.license__infoLabel}>
                        <Trans id='admin.status' />
                    </span>
                    <span
                        className={classNames([
                            styles.license__status,
                            isInactiveLicense ? styles.license__status_inactive : '',
                        ])}>
                        {isInactiveLicense ? (
                            <Trans id='admin.license.expired' message='Истекла' />
                        ) : (
                            <Trans id='admin.license.active' message='Активна' />
                        )}
                    </span>
                </div>
            </div>
        </div>
    );
};
