import { t } from '@lingui/macro';
import { FullScreenPopup } from 'components/common/FullScreenPopup';
import { PopupDialog } from 'components/common/PopupDialog';
import { ECustomWindowEvents } from 'constants/ECustomWindowEvents';
import { useEffect, useState } from 'react';
import styles from './AppUpdatedDialog.module.scss';

export const AppUpdatedDialog: React.FC = () => {
    const [showInfo, setShowInfo] = useState(false);

    const onNewVersionUpdate = () => {
        setShowInfo(true);
    };

    const closePopup = () => setShowInfo(false);

    useEffect(() => {
        window.addEventListener(
            ECustomWindowEvents.SHOW_UPDATE_APPLICATION_DIALOG,
            onNewVersionUpdate,
        );

        return () => {
            window.addEventListener(
                ECustomWindowEvents.SHOW_UPDATE_APPLICATION_DIALOG,
                onNewVersionUpdate,
            );
        };
    }, []);

    if (!showInfo) {
        return null;
    }

    return (
        <FullScreenPopup closePopup={closePopup}>
            <PopupDialog
                crossPosition='inside'
                dialogClassName={styles.dialog}
                titleClassName={styles.dialog__title}
                contentClassName={styles.dialog__body}
                onClose={closePopup}
                title={t({
                    id: 'appUpdate.newVersionAvailable',
                    message: 'Доступна новая версия приложения MeeVox!',
                })}>
                <span>
                    {t({
                        id: 'appUpdate.newVersionAvailable.description',
                        message: 'Закройте все вкладки с приложением, чтобы применить обновление.',
                    })}
                </span>
            </PopupDialog>
        </FullScreenPopup>
    );
};
