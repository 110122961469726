import { Field } from 'react-final-form';
import { Select } from '../Select';
import styles from './SelectField.module.scss';
import { IOwnProps } from './types';

export const SelectField: React.FC<IOwnProps> = ({
    initialValue,
    items,
    className,
    name,
    theme,
    displayFilteredHeader,
    styleType,
}) => {
    return (
        <Field name={name} initialValue={initialValue}>
            {({ input: { onChange, value }, meta }) => {
                const errorStyles = meta?.error ? styles.error : '';
                return (
                    <Select
                        className={className}
                        items={items}
                        errorStyles={errorStyles}
                        selectedValue={value}
                        changeHandler={onChange}
                        theme={theme || 'light'}
                        displayFilteredHeader={displayFilteredHeader}
                        styleType={styleType}
                    />
                );
            }}
        </Field>
    );
};
