import { INetworkService } from 'types/api/INetworkService';

interface IPayload {
    conferenceId: string;
    sessionId: string;
    text: string;
}
export const saveChatMessageApiCall = (
    networkService: INetworkService,
    { text, conferenceId, sessionId }: IPayload,
) =>
    networkService.post({
        url: ` /api/conferences/${conferenceId}/sessions/${sessionId}/messages`,
        data: {
            text,
        },
    });
