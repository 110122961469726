import { createAsyncThunk } from '@reduxjs/toolkit';
import { resetConferenceChatStoreAction } from 'actions/chat/resetConferenceChatStoreAction';
import { toggleConferenceChatAction } from 'actions/applicationView/toggleConferenceChatAction';
import { batch } from 'react-redux';

export const closeConferenceReportThunkAction = createAsyncThunk(
    'closeConferenceReport',
    (_: void, { dispatch }) => {
        batch(() => {
            dispatch(toggleConferenceChatAction(false));
            dispatch(resetConferenceChatStoreAction());
        });
    },
);
