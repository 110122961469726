import { t } from '@lingui/macro';
import { ReactElement } from 'react';
import { useConfirmDialog } from '../useConfirmDialog';

export const useConferenceRecordDeleteDialog = (
    deleteConferenceRecord: () => void,
): [() => void, () => ReactElement | null] => {
    const [setDialogOptions, confirmDialogRender] = useConfirmDialog();
    return [
        () => {
            setDialogOptions({
                handlePrimaryAction: () => deleteConferenceRecord(),
                handleSecondaryAction: () => setDialogOptions(null),
                headerText: t({
                    id: 'conferenceDeleteRecordDialog.header',
                    message: 'Удалить запись',
                }),
                bodyText: t({
                    id: 'conferenceDeleteRecordDialog.confirm',
                    message: 'Вы уверены что хотите удалить запись конференции?',
                }),
                primaryText: t({ id: 'Delete' }),
                secondaryText: t({ id: 'Cancel' }),
                theme: 'red',
            });
        },
        confirmDialogRender,
    ];
};
