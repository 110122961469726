import { createAsyncThunk } from '@reduxjs/toolkit';
import {
    ERecorderThunkAction,
} from 'constants/thunk/ERecorderThunkAction';
import { TAppState } from 'types/app/TAppStore';
import { getConferenceConnectInfoThunkAction } from 'thunk/conference/getConferenceConnectInfoThunkAction';
import { joinRecorderToConferenceThunkAction } from './joinRecorderToConferenceThunkAction';

export const checkConferenceAndJoinRecorderToConferenceThunkAction = createAsyncThunk(
    ERecorderThunkAction.CHECK_CONFERENCE_AND_JOIN_TO_CONFERENCE,
    async (_, { dispatch, getState }) => {
        const {
            conferenceSession: { currentConferenceId },
        } = getState() as TAppState;
        const conferenceConnectInfo = await dispatch(
            getConferenceConnectInfoThunkAction({ id: currentConferenceId }),
        ).unwrap();

        if (!conferenceConnectInfo) {
            return;
        }
        dispatch(joinRecorderToConferenceThunkAction());
        return;
    },
);
