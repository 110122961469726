import { MobileWrapperConnected } from 'components/common/MobileWrapper';
import { PinEnterPageDesktopConnected } from './PinEnterPageDesktopConnected';
import { PinEnterPageMobileConnected } from './PinEnterPageMobileConnected';
import { IOwnProps } from './types';

export const PinEnterPageResponsive: React.FC<IOwnProps> = ({ id }) => (
    <MobileWrapperConnected
        renderDesktop={() => <PinEnterPageDesktopConnected id={id} />}
        renderMobile={() => <PinEnterPageMobileConnected id={id} />}
    />
);
