import { EPaths } from 'constants/EPaths';
import { connect } from 'react-redux';
import { getConferenceConnectInfoThunkAction } from 'thunk/conference/getConferenceConnectInfoThunkAction';
import { setCurrentConferenceDataThunkAction } from 'thunk/conferenceSession/setCurrentConferenceDataThunkAction';
import { showNotifyThunkAction } from 'thunk/notify/showNotifyThunkAction';
import { TAppDispatch } from 'types/app/TAppDispatch';
import { TAppState } from 'types/app/TAppStore';
import { getDefaultErrorNotification } from 'utils/getDefaultErrorNotification';
import { redirect } from 'utils/redirect';
import { LoginForJoinForm } from './LoginForJoinForm';
import { IDispatchProps, IStateProps } from './types';

const mapStateToProps = (state: TAppState): IStateProps => ({
    currentPin: state.conferenceSession.currentConferencePin,
    ldap: state.applicationConfiguration.config.ldap,
});

const mapDispatchToProps = (dispatch: TAppDispatch): IDispatchProps => ({
    showNotificationError: () => dispatch(showNotifyThunkAction(getDefaultErrorNotification())),
    updateConferenceShortInfo: (id) =>
        dispatch(getConferenceConnectInfoThunkAction({ id, forceUpdate: true })),
    setCurrentConferenceData: (data) => dispatch(setCurrentConferenceDataThunkAction(data)),
    goToPinInputPage: (id) => redirect(`/enter/${id}`),
    goToConnectPage: (id) => redirect(`${EPaths.CONNECT_TO_CONFERENCE}/${id}`),
});

export const LoginForJoinFormConnected = connect(
    mapStateToProps,
    mapDispatchToProps,
)(LoginForJoinForm);
