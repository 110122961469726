import { t } from '@lingui/macro';
import { ConfirmDialogMobile } from 'components/common/ConfirmDialog/ConfirmDialogMobile';
import { useLocation } from 'react-router-dom';
import { IDispatchProps, IOwnProps, IStateProps } from './types';
import { getDialogText, exitAndFinishConference, exitConference } from './utils';

export const ExitConfirmationDialogMobile: React.FC<IOwnProps & IStateProps & IDispatchProps> = ({
    exitFromConference,
    finishConference,
    cancel,
    isConferenceOwner,
    showNotificationError,
}) => {
    const { pathname } = useLocation();

    const handleExitClick = () => exitConference({ exitFromConference, pathname });
    const handleExitAndFinishClick = () =>
        exitAndFinishConference({
            finishConference,
            showNotificationError,
            exitFromConference,
            pathname,
        });
    return (
        <ConfirmDialogMobile
            textHeader={getDialogText(isConferenceOwner)}
            textPrimaryAction={t({ id: 'Exit', message: 'Выйти' })}
            textSecondaryAction={
                isConferenceOwner
                    ? t({
                        id: 'conference.page.finish.text.id',
                        message: 'Завершить и выйти',
                    })
                    : ''
            }
            handlePrimaryAction={handleExitClick}
            handleSecondaryAction={handleExitAndFinishClick}
            handleClose={cancel}
            theme='red'
        />
    );
};
