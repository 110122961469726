import { t } from '@lingui/macro';
import LogoImg from 'assets/LogoBlack.svg';
import QRIconImg from 'assets/QRMobileIcon.svg';
import { Button } from 'components/common/Button';
import { JoinConferencePageMobileConnected } from 'pages/JoinConferencePage';
import React, { useEffect } from 'react';
import styles from './HomePageMobile.module.scss';
import { IMobileDispatchProps, IMobileStateProps } from './types';

export const HomePageMobile: React.FC<IMobileDispatchProps & IMobileStateProps> = ({
    logged,
    login,
    logout,
    ldap,
    register,
    goToConferences,
}) => {
    const [showJoinForm, setShowJoinForm] = React.useState<boolean>(false);

    useEffect(() => {
        if (logged) {
            goToConferences();
        }
    }, [logged]);

    return showJoinForm ? (
        <JoinConferencePageMobileConnected onClickBack={() => setShowJoinForm(false)} />
    ) : (
        <div className={styles.root}>
            <div className={styles.block1}>
                <div className={styles.logoWrapper}>
                    <img className={styles.logo} src={LogoImg} alt='logo' />
                    <img className={styles.qr} src={QRIconImg} alt='qr' />
                </div>
                <div className={styles.text}>
                    <h3 className={styles.title}>
                        {t({
                            id: 'A CONVENIENT SOLUTION FOR BUSINESS',
                            message: 'УДОБНОЕ РЕШЕНИЕ ДЛЯ БИЗНЕСА',
                        })}
                    </h3>
                    <h2>
                        {t({
                            id: 'Make communication more accessible',
                            message: 'Сделай общение доступнее',
                        })}
                    </h2>
                </div>
            </div>
            <div className={styles.block2}>
                <div className={styles.bgImage}></div>
                <div className={styles.buttons}>
                    <Button
                        caption={t({ id: 'btn.join', message: 'Присоединиться к конференции' })}
                        styleType='mobilePrimaryAction'
                        clickHandler={() => setShowJoinForm(true)}
                    />
                    {logged ? (
                        <div>
                            <Button
                                caption={t({ id: 'btn.logout', message: 'Выйти' })}
                                styleType='mobileSecondaryAction'
                                clickHandler={logout}
                            />
                        </div>
                    ) : (
                        <div>
                            <Button
                                caption={t({ id: 'btn.enter', message: 'Войти' })}
                                styleType='mobileSecondaryAction'
                                clickHandler={login}
                            />
                            {!ldap && (
                                <Button
                                    caption={t({ id: 'btn.register', message: 'Регистрация' })}
                                    styleType='mobileSecondaryAction'
                                    clickHandler={register}
                                />
                            )}
                        </div>
                    )}
                </div>
            </div>
        </div>
    );
};
