import { t } from '@lingui/macro';
import { Route } from 'react-router-dom';
import { AccountAudioSettingsConnected } from './AccountAudioSettings';
import { AccountConferenceSettingsConnected } from './AccountConferenceSettings';
import { AccountDevSettingsConnected } from './AccountDevSettings';
import styles from './AccountSettingsPage.module.scss';
import { AccountSettingsPageNavigation } from './AccountSettingsPageNavigation';
import { AccountVideoSettingsConnected } from './AccountVideoSettings';
import { IStateProps } from './types';

export const AccountSettingsPage: React.FC<IStateProps> = ({ showDebugTab }) => {
    const routes: Array<{ path: string; title: string }> = [
        {
            path: '/account/settings/conference',
            title: t({ id: 'Conference', message: 'Конференция' }),
        },
        { path: '/account/settings/video', title: t({ id: 'Video', message: 'Видео' }) },
        { path: '/account/settings/audio', title: t({ id: 'Audio', message: 'Аудио' }) },
    ];
    if (showDebugTab) {
        routes.push({
            path: '/account/settings/dev',
            title: t({ id: 'Debug', message: 'Отладочные' }),
        });
    }

    return (
        <>
            <nav className={styles.header}>
                <AccountSettingsPageNavigation routes={routes} />
            </nav>
            <div>
                <Route
                    exact
                    path='/account/settings/conference'
                    component={AccountConferenceSettingsConnected}
                />
                <Route
                    exact
                    path='/account/settings/video'
                    component={AccountVideoSettingsConnected}
                />
                <Route
                    exact
                    path='/account/settings/audio'
                    component={AccountAudioSettingsConnected}
                />
                <Route exact path='/account/settings/dev' component={AccountDevSettingsConnected} />
            </div>
        </>
    );
};
