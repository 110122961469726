import CameraOffWhiteImg from 'assets/CameraOffWhite.svg';
import MicrophoneOffAvatarImg from 'assets/MicrophoneOffAvatar.svg';
import MicrophoneOffImg from 'assets/MicrophoneOff.svg';
import CameraOffImg from 'assets/CameraOff.svg';
import React from 'react';
import styles from './ParticipantIcons.module.scss';
import { IStateProps } from './types';

export const ParticipantIcons: React.FC<IStateProps> = ({
    useVideo,
    useAudio,
    videoPermitted,
    audioPermitted,
}) => {
    const renderAudioImg = () => {
        if (!audioPermitted) {
            return (
                <img
                    src={MicrophoneOffImg}
                    alt='microphone forbidden'
                    className={styles.iconMicrophone}
                />
            );
        }
        if (!useAudio) {
            return (
                <img
                    src={MicrophoneOffAvatarImg}
                    alt='microphone off'
                    className={styles.iconMicrophone}
                />
            );
        }
        return null;
    };

    const renderVideoImg = () => {
        if (!videoPermitted) {
            return <img src={CameraOffImg} alt='camera forbidden' className={styles.iconCamera} />;
        }
        if (!useVideo) {
            return <img src={CameraOffWhiteImg} alt='camera off' className={styles.iconCamera} />;
        }
        return null;
    };

    return (
        <div className={styles.icons}>
            {renderAudioImg()}
            {renderVideoImg()}
        </div>
    );
};
