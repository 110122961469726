export enum EPaths {
    ACCOUNT = '/account',
    CONFERENCE_REPORT = '/report',
    CONNECT_TO_CONFERENCE = '/connect',
    EDIT_CONFERENCE = '/account/conferences/edit',
    ENTER_PIN = '/enter',
    ERROR='/error',
    INPUT_USER_NAME = '/inputUsername',
    LOBBY = '/lobby',
    LOGIN = '/login',
    PAST_CONFERENCES = '/account/conferences/past',
    RECORDS = '/account/records',
    REGISTRATION = '/register',
    RESTORE = '/restore',
    SCHEDULE_CONFERENCE = '/account/conferences/scheduling',
    SIMPLE_CONNECT_TO_CONFERENCE = '/simpleConnect',
    UPCOMING_CONFERENCES = '/account/conferences/upcoming',
    UPDATE_PROFILE = '/updateProfile',
}
