import { ELogCategories } from 'constants/ELogCategories';
import { ELogLevel } from 'constants/ELogLevel';
import { janusErrorInterceptor } from '../types/janus/janusErrorInterceptor';
import { log } from './log';

interface ILogEntry {
    category: ELogCategories;
    level: ELogLevel;
    parent: ILogEntry | null;
    children: ILogEntry[] | null;
}

const defaultLog: ILogEntry = {
    category: ELogCategories.ROOT,
    level: ELogLevel.Error,
    parent: null,
    children: null,
};
const websocketLog: ILogEntry = {
    category: ELogCategories.WEBSOCKET,
    level: ELogLevel.Error,
    parent: defaultLog,
    children: null,
};
const janusLog: ILogEntry = {
    category: ELogCategories.JANUS,
    level: ELogLevel.Error,
    parent: defaultLog,
    children: null,
};
const janusInternal: ILogEntry = {
    category: ELogCategories.JANUS_INTERNAL,
    level: ELogLevel.Error,
    parent: janusLog,
    children: null,
};
const janusMessages: ILogEntry = {
    category: ELogCategories.JANUS_MESSAGES,
    level: ELogLevel.Error,
    parent: janusLog,
    children: null,
};
const janusTextRoom: ILogEntry = {
    category: ELogCategories.JANUS_TEXT_ROOM,
    level: ELogLevel.Error,
    parent: janusLog,
    children: null,
};
const janusVideoRoom: ILogEntry = {
    category: ELogCategories.JANUS_VIDEO_ROOM,
    level: ELogLevel.Error,
    parent: janusLog,
    children: null,
};
const janusVideoRoomTracks: ILogEntry = {
    category: ELogCategories.JANUS_VIDEO_ROOM_TRACKS,
    level: ELogLevel.Error,
    parent: janusVideoRoom,
    children: null,
};

const janusVideoRoomMessages: ILogEntry = {
    category: ELogCategories.JANUS_VIDEO_ROOM_MESSAGES,
    level: ELogLevel.Error,
    parent: janusVideoRoom,
    children: null,
};

const janusVideoRoomTalks: ILogEntry = {
    category: ELogCategories.JANUS_VIDEO_ROOM_TALKS,
    level: ELogLevel.Error,
    parent: janusVideoRoom,
    children: null,
};

defaultLog.children = [janusLog, websocketLog];
janusLog.children = [janusInternal, janusMessages, janusVideoRoom, janusTextRoom];
janusVideoRoom.children = [janusVideoRoomTracks, janusVideoRoomMessages, janusVideoRoomTalks];

const LOG_ENTRIES_INDEX: Record<ELogCategories, ILogEntry> = {
    [ELogCategories.JANUS]: janusLog,
    [ELogCategories.JANUS_INTERNAL]: janusInternal,
    [ELogCategories.JANUS_MESSAGES]: janusMessages,
    [ELogCategories.JANUS_TEXT_ROOM]: janusTextRoom,
    [ELogCategories.JANUS_VIDEO_ROOM]: janusVideoRoom,
    [ELogCategories.JANUS_VIDEO_ROOM_MESSAGES]: janusVideoRoomMessages,
    [ELogCategories.JANUS_VIDEO_ROOM_TALKS]: janusVideoRoomTalks,
    [ELogCategories.JANUS_VIDEO_ROOM_TRACKS]: janusVideoRoomTracks,
    [ELogCategories.ROOT]: defaultLog,
    [ELogCategories.WEBSOCKET]: websocketLog,
};

export const writeToLog = (category: ELogCategories, level: ELogLevel, ...args: any[]) => {
    const logEntry = LOG_ENTRIES_INDEX[category];
    if (logEntry.level > level) {
        return;
    }
    const error: any = new Error();
    const lines = error.stack.split('\n');
    let fileName = '';
    try {
        fileName = lines[3].split('at ')[1].split(' (')[0];
    } catch (e) {
        fileName = '>';
    }
    const category_title = category || '>';
    if (typeof args[0] === 'string') {
        args[0] = `${category_title}:${fileName}: ${args[0]}`;
    } else {
        args = [`${category_title}:${fileName}: `, ...args];
    }
    switch (level) {
        case ELogLevel.Error:
            log.error(...args);
            return;
        case ELogLevel.Info:
            log.info(...args);
            return;
        case ELogLevel.Log:
            log.log(...args);
            return;
        case ELogLevel.Debug:
            log.debug(...args);
            return;
        case ELogLevel.Warning:
            log.warning(...args);
            return;
        default:
            break;
    }
};

type IErrorInterceptor = (...args: any[]) => boolean;

export const getLogger = (category: ELogCategories, errorInterceptor?: IErrorInterceptor) => ({
    debug: (...args: any) => writeToLog(category, ELogLevel.Debug, ...args),
    error: (...args: any) => {
        if (errorInterceptor && errorInterceptor(...args)) {
            return;
        }
        writeToLog(category, ELogLevel.Error, ...args);
    },
    info: (...args: any) => writeToLog(category, ELogLevel.Info, ...args),
    log: (...args: any) => writeToLog(category, ELogLevel.Log, ...args),
    warn: (args: any) => writeToLog(category, ELogLevel.Warning, ...args),
});

/**
 * Configures logger
 * @param config new config
 * config format is this:
 *  {
 *     level: 'error',
 *     janus: {
 *        level: 'debug'
 *     }
 *  }
 */
export const configureLogger = (config: any) => {
    // Reset logger for show only errors
    Object.keys(LOG_ENTRIES_INDEX).forEach(
        (category) => (LOG_ENTRIES_INDEX[category as ELogCategories].level = ELogLevel.Error),
    );
    if (config.level) {
        configureInternal(config, '');
    }
};

const getLevelEnum = (str: string): ELogLevel => {
    switch (str.toLowerCase()) {
        case 'error':
            return ELogLevel.Error;
        case 'warning':
            return ELogLevel.Warning;
        case 'log':
            return ELogLevel.Log;
        case 'info':
            return ELogLevel.Info;
        case 'debug':
            return ELogLevel.Debug;
        case 'none':
            return ELogLevel.None;
        default:
            log.error(`Unknown log level enum ${str}`);
            return ELogLevel.None;
    }
};

const getLogCategory = (str: string): ELogCategories => {
    switch (str.toLowerCase()) {
        case '':
            return ELogCategories.ROOT;
        case 'janus':
            return ELogCategories.JANUS;
        case 'janus.internal':
            return ELogCategories.JANUS_INTERNAL;
        case 'janus.messages':
            return ELogCategories.JANUS_MESSAGES;
        case 'janus.text_room':
            return ELogCategories.JANUS_TEXT_ROOM;
        case 'janus.video_room':
            return ELogCategories.JANUS_VIDEO_ROOM;
        case 'janus.video_room.tracks':
            return ELogCategories.JANUS_VIDEO_ROOM_TRACKS;
        case 'janus.video_room.talks':
            return ELogCategories.JANUS_VIDEO_ROOM_TALKS;
        case 'websocket':
            return ELogCategories.WEBSOCKET;
        default:
            throw new Error(`Unknown category ${str}`);
    }
};

const changeLevel = (entry: ILogEntry, level: ELogLevel) => {
    entry.level = level;
    if (entry.children) {
        entry.children.forEach((child) => changeLevel(child, level));
    }
};

const configureInternal = (node: any, path: string) => {
    const levelStr = node.level;
    if (levelStr) {
        const level = getLevelEnum(levelStr);
        const category = getLogCategory(path);
        const logEntry = LOG_ENTRIES_INDEX[category];
        changeLevel(logEntry, level);
    }
    for (const key of Object.keys(node)) {
        if (key === 'level') {
            continue;
        }
        configureInternal(node[key], path ? `${path}.${key}` : key);
    }
};

export const logger = getLogger(ELogCategories.ROOT);
export const janusLogger = getLogger(ELogCategories.JANUS);
export const janusInternalLogger = getLogger(ELogCategories.JANUS_INTERNAL, janusErrorInterceptor);
export const janusMessagesLogger = getLogger(ELogCategories.JANUS_MESSAGES);
export const janusTextRoomLogger = getLogger(ELogCategories.JANUS_TEXT_ROOM);
export const janusVideoRoomLogger = getLogger(ELogCategories.JANUS_VIDEO_ROOM);
export const janusVideoRoomMessagesLogger = getLogger(ELogCategories.JANUS_VIDEO_ROOM_MESSAGES);
export const janusVideoRoomTalksLogger = getLogger(ELogCategories.JANUS_VIDEO_ROOM_TALKS);
export const janusVideoRoomTracksLogger = getLogger(ELogCategories.JANUS_VIDEO_ROOM_TRACKS);
export const websocketLogger = getLogger(ELogCategories.WEBSOCKET);

(window as any).showLoggerConfig = () => {
    const result: Array<any> = [];
    Object.keys(LOG_ENTRIES_INDEX).forEach((key) => {
        const logEntry = LOG_ENTRIES_INDEX[key as ELogCategories];
        result.push({
            category: logEntry.category,
            level: logEntry.level,
        });
    });

    return result;
};

(window as any).configureLogger = (config: any) => configureLogger(config);

window.addEventListener('unhandledrejection', function (promiseRejectionEvent) {
    log.trace();
    log.debug({ promiseRejectionEvent });
    // log.debug(promiseRejectionEvent.reason.getStack())
});
