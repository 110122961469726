import lottie, { AnimationItem } from 'lottie-web';
import { useEffect, useRef } from 'react';
import styles from './LottieAnimation.module.scss';
import { IOwnProps } from './types';
import { addEventListeners, removeEventListeners } from './utils';

export const LottieAnimation: React.FC<IOwnProps> = ({
    className,
    style,
    animationData,
    autoplay = true,
    loop = true,
    eventListeners,
}) => {
    const animationRef = useRef<AnimationItem | null>(null);
    const componentRef = useRef(null);

    useEffect(() => {
        if (componentRef.current) {
            const newAnimation = lottie.loadAnimation({
                autoplay,
                animationData,
                loop,
                container: componentRef.current,
            });
            animationRef.current = newAnimation;
            addEventListeners(eventListeners, newAnimation);
        }
        return () => {
            if (animationRef.current) {
                removeEventListeners(eventListeners, animationRef.current);
                animationRef.current.destroy();
            }
        };
    }, []);

    useEffect(() => {
        if (componentRef.current && animationRef.current) {
            const prevAnimation = animationRef.current;
            removeEventListeners(eventListeners, prevAnimation);
            animationRef.current.destroy();

            const newAnimation = lottie.loadAnimation({
                autoplay,
                animationData,
                loop,
                container: componentRef.current,
                rendererSettings: {
                    preserveAspectRatio: 'xMidYMid meet',
                },
            });
            animationRef.current = newAnimation;
            addEventListeners(eventListeners, newAnimation);
        }
    }, [animationData]);

    const rootStyle = [styles.root, className].join(' ');
    return <div className={rootStyle} style={style} ref={componentRef} />;
};
