import { createAsyncThunk } from '@reduxjs/toolkit';
import { modifyParticipantTalkingAction } from 'actions/applicationView/modifyParticipantTalkingAction';
import { setParticipantLocalMuteStatusAction } from 'actions/applicationView/setParticipantLocalMuteStatusAction';
import { TAppState } from 'types/app/TAppStore';

interface IPayload {
    participantId: string;
    type: 'audio' | 'video';
}

export const changeParticipantMuteThunkAction = createAsyncThunk(
    'changeParticipantMute',
    async ({ participantId, type }: IPayload, { dispatch, getState }) => {
        const {
            applicationView: { participantsLocalMuteStatus },
            janus: { publishers },
        } = getState() as TAppState;
        const participantData = participantsLocalMuteStatus[participantId];
        const participantLocalMuteData = participantData ? participantData[type] : undefined;
        const muteStatus = !participantLocalMuteData;
        let { useAudio, useVideo } = publishers[participantId].audioVideoProps;
        if (type === 'audio' && muteStatus) {
            useAudio = false;
        }
        if (type === 'video' && muteStatus) {
            useVideo = false;
        }
        dispatch(
            setParticipantLocalMuteStatusAction({
                participantId,
                muteStatus,
                type,
                publisherAudioVideoProps: { useAudio, useVideo },
            }),
        );

        !participantLocalMuteData &&
            type === 'audio' &&
            dispatch(modifyParticipantTalkingAction({ participantId, talking: false }));
    },
);
