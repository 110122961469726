import styles from './EmotionContainer.module.scss';
import { IOwnProps } from './types';

export const EmotionContainer: React.FC<IOwnProps> = ({ children, style, direction }) => (
    <div
        className={`${styles.list} ${styles['list__' + style]} ${
            direction ? styles['list__' + direction] : ''
        }`}>
        {children}
    </div>
);
