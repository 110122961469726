import { Trans } from '@lingui/react';
import { Button } from 'components/common/Button';
import React from 'react';
import styles from './ConferenceParticipantsListMobile.module.scss';
import { ParticipantListItemConnected } from './ParticipantListItem/index';
import { IDispatchProps, IStatePropsMobile } from './types';

export const ConferenceParticipantsListMobile: React.FC<IDispatchProps & IStatePropsMobile> = ({
    conferenceConnectInfo,
    currentConferenceParticipants,
    currentConferenceParticipantsIdsSorted,
    ownParticipantId,
    participantsListOpen,
    publishers,
    setParticipantsListOpen,
}) => (
    <aside className={styles.conferenceParticipantsList}>
        <div className={styles.conferenceParticipantsList__header}>
            <Button
                styleType='text'
                className={styles.conferenceParticipantsList__backBtn}
                clickHandler={() => setParticipantsListOpen(!participantsListOpen)}>
                <Trans id='Back' message='Назад' />
            </Button>
        </div>
        <span className={styles.conferenceParticipantsList__title}>
            <Trans id='Participants' message='Участники' />
        </span>
        <ul className={styles.conferenceParticipantsList__body}>
            {conferenceConnectInfo &&
                currentConferenceParticipantsIdsSorted.map((participantId) => {
                    const participant = currentConferenceParticipants[participantId];
                    const key = participant?.userId || participant.participantId;
                    return (
                        <ParticipantListItemConnected
                            publisher={publishers[participant.participantId]}
                            isMobile={true}
                            itIsCurrentUserItem={participant.participantId === ownParticipantId}
                            key={key}
                        />
                    );
                })}
        </ul>
    </aside>
);
