import { connect } from 'react-redux';
import { SelectSharingScreen } from './SelectSharingScreen';
import { IDispatchProps } from './types';
import { TAppDispatch } from 'types/app/TAppDispatch';
import { showNotifyThunkAction } from 'thunk/notify/showNotifyThunkAction';

const mapDispatchToProps = (dispatch: TAppDispatch): IDispatchProps => ({
    showNotification: (text) =>
        dispatch(
            showNotifyThunkAction({
                text,
                timeout: 5000,
                type: 'notify_green' as const,
            }),
        ),
});

export const SelectSharingScreenConnected = connect(null, mapDispatchToProps)(SelectSharingScreen);
