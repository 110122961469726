import { Trans } from '@lingui/react';
import { ConferenceTimer } from 'components/ConferenceTimer';
import { useConferenceTimeCheck } from 'hooks/ConnectToConference/useConferenceTimeCheck';
import React, { useEffect, useState } from 'react';
import { clearSubscription } from 'services/socketService/socketService';
import { getDateInfoFromStartedConference } from 'utils/conferences/getDateInfoFromStartedConference';
import { getBrowserTimeZone } from 'utils/timeZones/getBrowserTimeZone';
import { getUserTimeZone } from 'utils/timeZones/getUserTimeZone';
import { ConferencePageTemplateConnected } from '../templates/ConferencePageTemplate';
import styles from './ConnectToConferencePage.module.scss';
import { DevicesErrorDialog } from './DevicesErrorDialog';
import { IDispatchProps, IOwnProps, IStateProps } from './types';
import { getConferenceTimeStartInfo, processFinishedConference } from './utils';
import { VideoCallMembersConnected } from './VideoCallMembers';
import { VideoCallSettingsWithDevicesConnected } from './VideoCallSettings';

export const ConnectToConferencePage: React.FC<IOwnProps & IStateProps & IDispatchProps> = ({
    changeStreamProps,
    conferenceConnectInfo,
    id,
    userIsConferenceOwner,
    prepareConferenceForStart,
}) => {
    const [devicesLoadFail, setDevicesLoadFail] = useState(false);
    const [offAllDevices, setOffAllDevices] = useState(false);
    const [reloadDevices, setReloadDevices] = useState(false);
    const isConferenceAvailable = useConferenceTimeCheck(conferenceConnectInfo?.timeStart);

    useEffect(() => {
        setOffAllDevices(false);
        if (devicesLoadFail) {
            changeStreamProps({
                useAudio: false,
                useVideo: false,
            });
        }
    }, [devicesLoadFail]);

    useEffect(() => {
        if (conferenceConnectInfo?.status === 'FINISHED') {
            processFinishedConference(id)
        }
    }, [conferenceConnectInfo]);

    useEffect(() => {
        prepareConferenceForStart(id);
        return () => {
            clearSubscription('subscribeToConferenceChangeStatus');
        };
    }, []);

    if (!conferenceConnectInfo) {
        return null;
    }

    const browserTimeZone = getBrowserTimeZone();
    const userTimeZone = getUserTimeZone();
    const { compareResult } = getDateInfoFromStartedConference(
        conferenceConnectInfo.timeStart ?? '',
        browserTimeZone,
        userTimeZone,
    );

    const conferenceShouldAlreadyStart = compareResult >= 0;
    const conferenceStarted = conferenceConnectInfo.status === 'STARTED';
    const isShowTimer = conferenceConnectInfo.status === 'SCHEDULED';
    const isShowWaitOwnerText =
        conferenceShouldAlreadyStart &&
        conferenceConnectInfo.waitCreator &&
        !userIsConferenceOwner &&
        conferenceStarted;
    return (
        <ConferencePageTemplateConnected
            conferenceConnectInfo={conferenceConnectInfo}
            showLogo={true}>
            {devicesLoadFail && (
                <DevicesErrorDialog
                    handleClickReload={() => setReloadDevices(true)}
                    handleClickOff={() => {
                        setOffAllDevices(true);
                        setDevicesLoadFail(false);
                    }}
                    className={styles.error}
                />
            )}
            <div className={styles.conferenceSettings}>
                <div className={styles.conferenceSettings__notifications}>
                    {isShowTimer && isConferenceAvailable && (
                        <span className={styles.timerWrapper}>
                            <ConferenceTimer
                                conferenceTimeStart={conferenceConnectInfo.timeStart}
                            />
                        </span>
                    )}
                    {isShowTimer && !isConferenceAvailable && (
                        <>
                            <div className={styles.timerWrapper}>
                                {getConferenceTimeStartInfo(conferenceConnectInfo?.timeStart)}
                            </div>
                        </>
                    )}
                    {isShowWaitOwnerText && (
                        <div className={styles.timerWrapper}>
                            <Trans
                                id='conference.wait.creator.start.conference'
                                message='Ожидание создателя конференции'
                            />
                        </div>
                    )}
                </div>
                <div className={styles.conferenceSettings__main}>
                    <div className={styles.settings}>
                        <VideoCallSettingsWithDevicesConnected
                            handleDeviceLoadFail={() => setDevicesLoadFail(true)}
                            handleDeviceLoadSuccessful={() => setDevicesLoadFail(false)}
                            offAllDevices={offAllDevices}
                            reloadDevices={reloadDevices}
                            isOwner={userIsConferenceOwner}
                            forbiddenDevices={{
                                audio: conferenceConnectInfo.forbidAudio,
                                video: conferenceConnectInfo.forbidVideo,
                            }}
                            handleReloadDevices={() => setReloadDevices(false)}
                        />
                    </div>
                    <div className={styles.members}>
                        <VideoCallMembersConnected />
                    </div>
                </div>
            </div>
        </ConferencePageTemplateConnected>
    );
};
