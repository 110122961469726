import { t } from '@lingui/macro';
import { ICustomMaxSizes } from './types';

export const checkFiles = (
    fileList: FileList | null | undefined,
    maxMediaFileSizeByte: number,
    showNotification: (text: string) => void,
) => {
    if (!fileList || fileList.length === 0) {
        return;
    }
    const maxSize = 3;
    //const errors: string[] = [];
    const validFiles: File[] = [];
    if (fileList.length > maxSize) {
        showNotification(
            t({
                id: 'filesValidation.maxLength',
                message: 'Максимальное количество файлов {0}',
                values: {
                    0: maxSize,
                },
            }),
        );
        return;
    }
    for (let i = 0; i <= fileList.length - 1; i++) {
        const file = fileList[i];
        if (file.size > maxMediaFileSizeByte) {
            const mb = maxMediaFileSizeByte / 1024 / 1024;
            showNotification(
                t({
                    id: 'filesValidation.maxMediaFileSizeByte',
                    message: 'Файл {0} прeвышает максимальный размер {1} МБ',
                    values: {
                        0: file.name,
                        1: Math.floor(mb * 1000) / 1000,
                    },
                }),
            );
            return;
        }
        validFiles.push(file);
    }
    return validFiles;
    // if (errors.length === 0) {
    //     return validFiles;
    // } else {
    //     showNotification(
    //         t({
    //             id: 'filesValidation.invalidFormat',
    //             message: 'Некорректные файлы: {0}',
    //             values: {
    //                 0: errors.join(', '),
    //             },
    //         }),
    //     );
    // }
};

export const getFileType = ({ type }: File): string => {
    return type.split('/')[0];
};

export const getResizeData = (width: number, height: number, customMaxSizes?: ICustomMaxSizes) => {
    const MAX_WIDTH = customMaxSizes?.maxWidth ? customMaxSizes?.maxWidth : 300;
    const MAX_HEIGHT = customMaxSizes?.maxHeight ? customMaxSizes?.maxHeight : 300;
    const resultSize = {
        width,
        height,
    };

    if (width > height) {
        if (width > MAX_WIDTH) {
            resultSize.height = Math.floor(height * (MAX_WIDTH / width));
            resultSize.width = MAX_WIDTH;
        }
    } else {
        if (height > MAX_HEIGHT) {
            resultSize.width = Math.floor(width * (MAX_HEIGHT / height));
            resultSize.height = MAX_HEIGHT;
        }
    }

    return resultSize;
};

export const getCanvasResultUrl = (
    uploadImg: File,
    imgNode: HTMLImageElement,
    customMaxSizes?: ICustomMaxSizes,
): string | undefined => {
    const { width, height } = getResizeData(imgNode.width, imgNode.height, customMaxSizes);
    const canvas = document.createElement('canvas');
    canvas.width = width;
    canvas.height = height;
    const ctx = canvas.getContext('2d');
    if (!ctx) {
        return;
    }
    ctx.drawImage(imgNode, 0, 0, width, height);
    return canvas.toDataURL(uploadImg.type);
};

export const getResizeImg = (
    uploadImg: File,
    customMaxSizes?: ICustomMaxSizes,
): Promise<string | undefined> => {
    return new Promise((resolve, reject) => {
        const reader = new FileReader();
        reader.addEventListener('load', () => {
            const img = document.createElement('img');
            img.onload = function () {
                const result = getCanvasResultUrl(uploadImg, img, customMaxSizes);
                result ? resolve(result) : reject(undefined);
            };
            img.src = `${reader.result}`;
        });
        reader.readAsDataURL(uploadImg);
    });
};
