import { connect } from 'react-redux';
import { logoutThunkAction } from 'thunk/auth/logoutThunkAction';
import { TAppDispatch } from 'types/app/TAppDispatch';
import { TAppState } from 'types/app/TAppStore';
import { AccountPage } from './AccountPage';
import { IDispatchProps, IStateProps } from './types';

const mapStateToProps = (state: TAppState): IStateProps => ({
    conferenceListRouteToPast: state.sessionSettings.conferencesFilter.finished,
    version: state.applicationConfiguration.config.version,
    licenseName: state.auth.license.licenseName,
    validDate: state.auth.license.expirationDate,
    rememberMeRequestStatus: state.auth.rememberMeRequestStatus,
});

const mapDispatchToProps = (dispatch: TAppDispatch): IDispatchProps => ({
    logout: () => dispatch(logoutThunkAction()),
});

export const AccountPageConnected = connect(mapStateToProps, mapDispatchToProps)(AccountPage);
