import { appHistory } from 'appHistory';

import { ConferenceStartedSettingsFormMobileConnected } from 'components/ConferenceStartedSettingsForm/ConferenceStartedSettingsFormMobileConnected';
import { MediaPermissionDialogMobileConnected } from 'pages/ConferencePage/MediaPermissionDialog/MediaPermissionDialogMobileConnected';
import { ConferenceChatConnected } from 'pages/templates/ConferencePageTemplate/ConferenceChat';
import { ConferenceInfoFrameMobileConnected } from 'pages/templates/ConferencePageTemplate/ConferenceInfoFrame';
import { ConferenceParticipantsListMobileConnected } from 'pages/templates/ConferencePageTemplate/ConferenceParticipantsList/ConferenceParticipantsListMobileConnected';
import { ExitConfirmationDialogMobileConnected } from 'pages/templates/ConferencePageTemplate/ExitConfirmationDialog/ExitConfirmationDialogMobileConnected';
import React, { useEffect, useState } from 'react';
import {
    getBrowserBtnBackWasClicked,
    setBrowserBtnBackWasClicked,
} from 'utils/browserBtnBackWasClicked';
import { setOwnStreamsPermissions } from 'utils/conferences/setOwnStreamsPermissions';
import styles from './ConferencePageMobile.module.scss';
import { ConferencePageMobileFooterConnected } from './ConferencePageMobileFooter';
import { ConferencePageMobileHeaderConnected } from './ConferencePageMobileHeader';
import { LobbyAdminDialogMobile } from './LobbyAdminDialogMobile';
import { ParticipantsLayoutConnected } from './ParticipantsLayout';
import { IDispatchPropsMobile, IOwnProps, IStatePropsMobile } from './types';

export const ConferencePageMobile: React.FC<IOwnProps & IStatePropsMobile & IDispatchPropsMobile> =
    ({
        conferencesInfo,
        exitFromConferenceWithoutRedirect,
        hideConferenceInfoFrame,
        id,
        isAudioNotification,
        isShowExitDialog,
        loadData,
        lobbyQueueToEnter,
        participantsListOpen,
        sendPermissionToJoinConference,
        showChat,
        showConferenceInfoFrame,
        showAudioPermissionDialog,
        ownAudioVideoPermissions: { audioPermitted, videoPermitted },
        allowedStreams,
        forbiddenStreams,
        forbiddenStreamsByConferenceSettings,
        setStreamProps,
        currentUserIsOwner,
        rememberMeRequestStatus,
    }) => {
        const [settingsOpen, setSettingsOpen] = useState(false);
        const conferenceInfo = id ? conferencesInfo[id] : undefined;

        React.useEffect(() => {
            return appHistory.listen(() => {
                if (getBrowserBtnBackWasClicked()) {
                    exitFromConferenceWithoutRedirect();
                } else {
                    setBrowserBtnBackWasClicked(true);
                }
            });
        }, []);

        useEffect(() => {
            loadData(id);
        }, [rememberMeRequestStatus]);
        useEffect(() => {
            setOwnStreamsPermissions({
                forbiddenStreamsByConferenceSettings,
                allowedStreams,
                forbiddenStreams,
                currentPermissions: { audioPermitted, videoPermitted },
                setStreamProps,
                currentUserIsOwner,
            });
        }, [forbiddenStreams, allowedStreams, forbiddenStreamsByConferenceSettings]);

        const lobbyRequestsRender = () =>
            lobbyQueueToEnter.map((participant) => (
                <LobbyAdminDialogMobile
                    displayName={participant.name}
                    key={participant.participantId}
                    isAudioNotification={isAudioNotification}
                    onAllow={() =>
                        sendPermissionToJoinConference({
                            conferenceId: id,
                            participantId: participant.participantId,
                            name: participant.name,
                            status: 'allow',
                        })
                    }
                    onDeny={() =>
                        sendPermissionToJoinConference({
                            conferenceId: id,
                            participantId: participant.participantId,
                            name: participant.name,
                            status: 'forbid',
                        })
                    }
                />
            ));
        const handleClickSettings = () => setSettingsOpen(true);
        const handleSubmitSettingsForm = () => setSettingsOpen(false);

        return (
            <div className={styles.root}>
                <div className={styles.content}>
                    <div className={styles.main}>
                        {showAudioPermissionDialog && <MediaPermissionDialogMobileConnected />}
                        {lobbyRequestsRender()}
                        <ConferencePageMobileHeaderConnected />
                        <ParticipantsLayoutConnected />
                        <ConferencePageMobileFooterConnected />
                        {settingsOpen && (
                            <ConferenceStartedSettingsFormMobileConnected
                                conferenceInfo={conferenceInfo}
                                handleAfterSubmit={handleSubmitSettingsForm}
                            />
                        )}
                    </div>
                    {showChat && <ConferenceChatConnected />}
                </div>
                {isShowExitDialog && <ExitConfirmationDialogMobileConnected />}
                <ConferenceInfoFrameMobileConnected
                    isShow={showConferenceInfoFrame}
                    hideInfoFrame={hideConferenceInfoFrame}
                    handleClickSettings={handleClickSettings}
                />
                {participantsListOpen && <ConferenceParticipantsListMobileConnected />}
            </div>
        );
    };
