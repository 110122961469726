import { Trans } from '@lingui/react';
import copyImg from 'assets/CopyFile.svg';
import { Button } from 'components/common/Button';
import { ECopyToClipboardNotification } from 'constants/ECopyToClipboardNotification';
import { useEffect, useState } from 'react';
import { createInviteLink } from 'utils/conferences/createInviteLink';
import styles from './ConferenceShareDialogInviteLink.module.scss';
import { IDispatchProps, IOwnProps } from './types';

export const ConferenceShareDialogInviteLink: React.FC<IOwnProps & IDispatchProps> = ({
    id,
    pin,
    copyToClipboard,
}) => {
    const [inviteLink, setInviteLink] = useState('');

    const copyShareLink = () => {
        copyToClipboard({
            dataToCopy: createInviteLink(id, pin),
            notifyTextKey: ECopyToClipboardNotification.INVITE_LINK,
        });
    };

    useEffect(() => {
        setInviteLink(createInviteLink(id, pin));
    }, [id, pin]);

    return (
        <div className={styles.inviteLink}>
            <div className={styles.inviteLink__link}>{inviteLink}</div>
            <Button
                styleType='common'
                className={styles.inviteLink__copyButton}
                clickHandler={copyShareLink}>
                <img src={copyImg} alt='copy' />
                <span>
                    <Trans id='Copy' message='Копировать' />
                </span>
            </Button>
        </div>
    );
};
