import { connect } from 'react-redux';
import { UserSearch } from './UserSearch';
import { TAppDispatch } from 'types/app/TAppDispatch';
import { IDispatchProps, IStateProps } from './types';
import { getUsersPendingThunkAction } from 'admin-app/thunk/users/getUsersThunkAction';
import { TAppState } from 'types/app/TAppStore';
import { EPendingOperations } from 'admin-app/constants/EPendingOperations';

const mapStateToProps = (state: TAppState): IStateProps => ({
    gettingUsers:
        !!state.applicationView.pendingOperations[`${EPendingOperations.GETTING_USERS_LIST}`],
});

const mapDispatchToProps = (dispatch: TAppDispatch): IDispatchProps => ({
    searchUser: (target) => dispatch(getUsersPendingThunkAction({ search: target })),
    getUsers: () => dispatch(getUsersPendingThunkAction()),
});

export const UserSearchConnected = connect(mapStateToProps, mapDispatchToProps)(UserSearch);
