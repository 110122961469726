import { t } from '@lingui/macro';
import { EModerateMediaDeviceStatus } from 'constants/EModerateMediaDeviceStatus';
import { TLogEvents } from 'types/janus/TModerateMediaEvents';

export const getLogText = (eventName: TLogEvents) => {
    let resultText = '';

    switch (eventName) {
        case 'exit':
            resultText = t({ id: 'left.conference', message: 'вышел(-а)' });
            break;
        case 'join':
            resultText = t({ id: 'joined.conference', message: 'присоединился(-ась)' });
            break;
        case EModerateMediaDeviceStatus.AUDIO_DISABLED:
            resultText = t({
                id: 'conference.log.audio.disabled',
                message: 'Микрофон заблокирован',
            });
            break;
        case EModerateMediaDeviceStatus.AUDIO_ENABLED:
            resultText = t({
                id: 'conference.log.audio.enabled',
                message: 'Микрофон разблокирован',
            });
            break;
        case EModerateMediaDeviceStatus.VIDEO_DISABLED:
            resultText = t({ id: 'conference.log.video.disabled', message: 'Видео заблокировано' });
            break;
        case EModerateMediaDeviceStatus.VIDEO_ENABLED:
            resultText = t({ id: 'conference.log.video.enabled', message: 'Видео разблокировано' });
            break;
        default:
            break;
    }
    return resultText;
};