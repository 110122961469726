import navArrow from 'assets/CalendarArrow.svg';
import DatePickerImg from 'assets/DatePicker.svg';
import { default as ReactDatePicker } from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import { Field } from 'react-final-form';
import { getLocaleByLanguage } from 'utils/getLocaleByLanguage';
import styles from './DatePicker.module.scss';
import { IOwnProps, IStateProps } from './types';

export const DatePicker: React.FC<IOwnProps & IStateProps> = ({
    name,
    initialValue,
    format,
    parse,
    currentValue,
    maxDate,
    minDate,
    selectsEnd,
    selectsStart,
    lang,
    disabled,
}) => (
    <div className={styles.datePicker}>
        <Field name={name} initialValue={initialValue} format={format} parse={parse}>
            {({ input }) => {
                const customHeader = ({
                    monthDate,
                    decreaseMonth,
                    increaseMonth,
                }: {
                    monthDate: Date;
                    decreaseMonth: () => void;
                    increaseMonth: () => void;
                }) => (
                    <div className='react-datepicker__headerTop'>
                        <button
                            aria-label='Previous Month'
                            className='react-datepicker__arrow'
                            onClick={decreaseMonth}
                            type='button'>
                            <img src={navArrow} />
                        </button>
                        <span className='react-datepicker__current-month react-datepicker__current-month_center'>
                            {monthDate.toLocaleString(lang || 'en', {
                                month: 'long',
                                year: 'numeric',
                            })}
                        </span>
                        <button
                            aria-label='Next Month'
                            className='react-datepicker__navigation react-datepicker__navigation--next'
                            onClick={increaseMonth}
                            type='button'>
                            <img className='react-datepicker__arrow_rotate' src={navArrow} />
                        </button>
                    </div>
                );
                const renderDayContents = (day: number) => {
                    return <span className='react-datepicker__dayNumber'>{day}</span>;
                };
                return (
                    <ReactDatePicker
                        {...input}
                        renderCustomHeader={({ monthDate, decreaseMonth, increaseMonth }) =>
                            customHeader({
                                monthDate,
                                decreaseMonth,
                                increaseMonth,
                            })
                        }
                        renderDayContents={renderDayContents}
                        selected={currentValue}
                        locale={getLocaleByLanguage(lang)}
                        onChange={(date) => {
                            input.onChange(date);
                        }}
                        disabled={disabled}
                        selectsEnd={selectsEnd}
                        selectsStart={selectsStart}
                        minDate={minDate}
                        maxDate={maxDate}
                    />
                );
            }}
        </Field>
        <img src={DatePickerImg} alt='Date picker' />
    </div>
);
