//import { resetPendingConfirmationAction } from 'actions/applicationView/resetPendingConfirmationAction';
import { setFormEditFlagAction } from 'actions/applicationView/setFormEditFlagAction';
import { getAppSettingsThunkAction } from 'thunk/auth/getAppSettingsThunkAction';
import { updateAppSettingsThunkAction } from 'thunk/auth/updateAppSettingsThunkAction';
import { showNotifyThunkAction } from 'thunk/notify/showNotifyThunkAction';
import { TAppDispatch } from 'types/app/TAppDispatch';
import { IDispatchProps } from './types';

export const mapDispatchToProps = (dispatch: TAppDispatch): IDispatchProps => ({
    showNotification: ({ text, type }) =>
        dispatch(showNotifyThunkAction({ text, timeout: 3000, type })),
    onModified: (isEdit: boolean) => dispatch(setFormEditFlagAction(isEdit)),
    saveSettings: (values) => dispatch(updateAppSettingsThunkAction(values)).unwrap(),
    updateAppSettings: (settings) => dispatch(getAppSettingsThunkAction({ newSettings: settings })),
    //resetModified: () => dispatch(resetPendingConfirmationAction()),
});
