export enum EAdminReducerActions {
    ADD_USERS = 'adminReducer/addUsers',
    SET_USERS_SEARCH = 'adminReducer/setUsersSearch',
    SET_PUBLIC_KEY = 'adminReducer/setPublicKey',
    STORE_USERS = 'adminReducer/storeUsers',
    SET_WEB_HOOKS = 'adminReducer/setWebHooks',
    REMOVE_WEB_HOOK = 'adminReducer/removeWebHook',
    EDIT_WEB_HOOK = 'adminReducer/editWebHook',
    ADD_WEB_HOOK = 'adminReducer/addWebHook',
}
