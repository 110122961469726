import { t } from '@lingui/macro';
import { useEffect } from 'react';
import { getDefaultErrorNotification } from 'utils/getDefaultErrorNotification';
import { IDispatchProps, IStateProps } from './types';

const getLiguiSuccessText = () =>
    t({ id: 'email.change.success', message: 'Электронная почта успешно изменена' });

export const ConfirmEmailChangePage: React.FC<IStateProps & IDispatchProps> = ({
    sendRequestToConfirmEmailChange,
    goToProfile,
    goToLogin,
    updateProfile,
    loggedIn,
    showNotification,
    goToHome,
}) => {
    const search = location.search;
    const params = new URLSearchParams(search);
    const token = params.get('token') ?? undefined;

    useEffect(() => {
        const sendRequest = async () => {
            if (token) {
                const { payload: error } = await sendRequestToConfirmEmailChange(token);
                if (!error) {
                    if (loggedIn) {
                        updateProfile();
                        showNotification({
                            text: getLiguiSuccessText(),
                            timeout: 3000,
                            type: 'success_email',
                        });
                        goToProfile();
                    } else {
                        goToLogin();
                    }
                } else {
                    showNotification(getDefaultErrorNotification());
                    goToHome();
                }
            } else {
                showNotification(getDefaultErrorNotification());
                goToHome();
            }
        };
        sendRequest();
    }, []);

    return null;
};
