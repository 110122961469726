import { Link, useLocation } from 'react-router-dom';
import styles from './SettingsNavigation.module.scss';
import { EAdminPaths } from 'admin-app/constants/EAdminPaths';
import { Trans } from '@lingui/react';
import { classNames } from 'utils/classNames';

export const SettingsNavigation: React.FC = () => {
    const { pathname } = useLocation();

    const activeLinkStyles = (targetPath: string) => {
        return pathname.match(targetPath) ? styles.settingsNavigation__link_active : '';
    };
    
    return (
        <ul className={styles.settingsNavigation}>
            <li className={styles.settingsNavigation__item}>
                <Link
                    to={EAdminPaths.SETTINGS_LICENSE}
                    className={classNames([
                        styles.settingsNavigation__link,
                        activeLinkStyles(EAdminPaths.SETTINGS_LICENSE),
                    ])}>
                    <Trans id='admin.settings.license' message='Лицензия' />
                </Link>
            </li>
            <li className={styles.settingsNavigation__item}>
                <Link
                    to={EAdminPaths.SETTINGS_HOOKS}
                    className={classNames([
                        styles.settingsNavigation__link,
                        activeLinkStyles(EAdminPaths.SETTINGS_HOOKS),
                    ])}>
                    <Trans id='admin.settings.hooks' message='Веб-хуки' />
                </Link>
            </li>
            <li className={styles.settingsNavigation__item}>
                <Link
                    to={EAdminPaths.SETTINGS_AUTHORIZATION}
                    className={classNames([
                        styles.settingsNavigation__link,
                        activeLinkStyles(EAdminPaths.SETTINGS_AUTHORIZATION),
                    ])}>
                    <Trans id='admin.settings.authorization' message='Авторизация' />
                </Link>
            </li>
        </ul>
    );
};
