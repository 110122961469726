import { EChatMessageStatus } from 'constants/EChatMessageStatus';
import { EChatMessageType } from 'constants/EChatMessageType';
import { IChatFile } from 'types/chat/IChatFile';
import { IChatHistoryMessage } from 'types/chat/IChatHistoryMessage';
import { IConferenceChatMessage } from 'types/chat/IConferenceChatMessage';
import { createRandomId } from './createRandomId';
import { log } from './log';

export const getFormattedMessageDataForStore = ({
    sentTime,
    senderId,
    userId,
    text,
    name,
}: IChatHistoryMessage): IConferenceChatMessage => {
    if (text.indexOf('<') === 0) {
        return getOldFormat({
            sentTime,
            senderId,
            userId,
            name,
            text,
        });
    }
    const message = JSON.parse(text);
    return {
        id: createRandomId(),
        contentType: message.contentType as EChatMessageType,
        text: message.text,
        files: message.files,        
        time: sentTime,
        status: EChatMessageStatus.DELIVERED,
        confUserId: senderId,
        ownUserId: userId,
    };
};
function getOldFormat({
    sentTime,
    senderId,
    userId,
    text,
}: IChatHistoryMessage): IConferenceChatMessage {
    const divIdx = text.indexOf('>');
    let messageType = text.substr(1, divIdx - 1) as EChatMessageType || EChatMessageType.TEXT;
    let messageText = text.substr(divIdx + 1);
    let files: IChatFile[] | undefined;
    switch (messageType) {
        case EChatMessageType.TEXT:
            break;
        case EChatMessageType.IMG:
            try {
                const parsedValue = JSON.parse(messageText);
                if (parsedValue && Array.isArray(parsedValue)) {
                    files = parsedValue.map(id => ({ id, name: 'old.jpg', size: null }));
                }
                messageText = '';
                messageType = EChatMessageType.TEXT;
            } catch (err) {
                messageText = '';
                log.error('Wrong message format: ', err);
            }
            break;
        // case EChatMessageType.EMOTION:
        //     messageValue = formattedText;
        //     break;
    }

    return {
        id: createRandomId(),
        contentType: messageType,
        text: messageText,
        files,        
        time: sentTime,
        status: EChatMessageStatus.DELIVERED,
        confUserId: senderId,
        ownUserId: userId,
    };
}
