import { setConferenceExitConfirmationDialogVisibilityAction } from 'actions/applicationView/setConferenceExitConfirmationDialogVisibilityAction';
import { connect } from 'react-redux';
import { finishConferenceThunkAction } from 'thunk/conference/finishConferenceThunkAction';
import { exitFromConferenceThunkAction } from 'thunk/conference/exitFromConferenceThunkAction';
import { showNotifyThunkAction } from 'thunk/notify/showNotifyThunkAction';
import { TAppDispatch } from 'types/app/TAppDispatch';
import { getDefaultErrorNotification } from 'utils/getDefaultErrorNotification';
import { IDispatchProps, IStateProps } from './types';
import { ExitConfirmationDialogMobile } from './ExitConfirmationDialogMobile';
import { TAppState } from 'types/app/TAppStore';

const mapDispatchToProps = (dispatch: TAppDispatch): IDispatchProps => ({
    exitFromConference: () => dispatch(exitFromConferenceThunkAction({})),
    finishConference: () => dispatch(finishConferenceThunkAction()).unwrap(),
    cancel: () => dispatch(setConferenceExitConfirmationDialogVisibilityAction(false)),
    showNotificationError: () => dispatch(showNotifyThunkAction(getDefaultErrorNotification())),
});

const mapStateToProps = (state: TAppState): IStateProps => ({
    isConferenceOwner: state.conferenceSession.currentUserIsOwner,
});

export const ExitConfirmationDialogMobileConnected = connect(
    mapStateToProps,
    mapDispatchToProps,
)(ExitConfirmationDialogMobile);
