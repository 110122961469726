import { t } from '@lingui/macro';
import { resetOwnStreamAction } from 'actions/janus/resetOwnStreamAction';
import { setJanusStreamPropsAction } from 'actions/janus/setJanusStreamPropsAction';
import { setVideoRoomConnectedStatusAction } from 'actions/janus/setVideoRoomConnectedStatusAction';
import { exitFromConferenceThunkAction } from 'thunk/conference/exitFromConferenceThunkAction';
import { showNotifyThunkAction } from 'thunk/notify/showNotifyThunkAction';
import { TAppState } from 'types/app/TAppStore';
import { getBrowserBtnBackWasClicked } from 'utils/browserBtnBackWasClicked';
import { getErrorNotification } from 'utils/getErrorNotification';
import {
    janusVideoRoomLogger,
    janusVideoRoomMessagesLogger,
    janusVideoRoomTalksLogger,
} from 'utils/logger';
import { redirect } from 'utils/redirect';
import { applyAudioVideoPermissionFromModerator } from './applyAudioVideoPermissionFromModerator';
import { detachPublisher } from './detachPublisher';
import { handleOwnStreams } from './handleOwnStreams';
import { janusCtx } from './janusCtx';
import { publishOwnFeed } from './publishOwnFeed';
import { setParticipantTalkingStatus } from './setParticipantTalkingStatus';
import { videoRoomHandleParticipants } from './videoRoomHandleParticipants';

export const janusVideoRoomMessagesHandler = async (msg: any, jsep: any) => {
    const event: string = msg['videoroom'];
    if (event.indexOf('talking') > -1) {
        janusVideoRoomTalksLogger.debug(' ::: Got a message (publisher) :::', msg);
        janusVideoRoomTalksLogger.debug('Event: ' + event);
    } else {
        janusVideoRoomMessagesLogger.debug(' ::: Got a message (publisher) :::', msg);
        janusVideoRoomMessagesLogger.debug('Event: ' + event);
    }
    if (event) {
        const { janus } = janusCtx.getState() as TAppState;
        if (!janus) {
            // Conference was terminated, nothing to do
            return;
        }
        const {
            localStreamProps: { useAudio, useVideo },
        } = janus;
        if (event === 'joined') {
            // We have joined
            janusCtx.myid = msg['id'];
            janusCtx.mypvtid = msg['private_id'];
            janusVideoRoomLogger.debug(
                'Successfully joined room ' + msg['room'] + ' with ID ' + janusCtx.myid,
            );
            if (useAudio || useVideo) {
                publishOwnFeed();
            } else {
                janusCtx.dispatch(setVideoRoomConnectedStatusAction(true));
            }
            videoRoomHandleParticipants(msg);
        } else if (event === 'talking' || event === 'stopped-talking') {
            const { id: publisherId, 'audio-level-dBov-avg': levelValue } = msg;
            const audioLevel = levelValue ? `${levelValue.toFixed(2)}` : '';
            const isTalking = event === 'talking';
            setParticipantTalkingStatus(publisherId, isTalking, audioLevel);
        } else if (event === 'destroyed') {
            const {
                conferenceSession: { currentConferenceId },
            } = janusCtx.getState() as TAppState;
            if (getBrowserBtnBackWasClicked()) {
                // Not the current session initialized termination
                redirect(`/conference/${currentConferenceId}/finished?finishedByOwner=true`);
            }
        } else if (event === 'event') {
            // Any new feed to attach to?
            if (msg['streams']) {
                // Only own streams
                const streams = msg['streams'];
                handleOwnStreams(streams);
            } else if (msg['publishers'] || msg['attendees']) {
                videoRoomHandleParticipants(msg);
            } else if (msg['joining']) {
                // Moved to own socket
            } else if (msg['kicked']) {
                // One of the publishers has been kicked?
                const kicked = msg['kicked'];
                detachFeed(kicked, 'kick');
            } else if (msg['leaving']) {
                // One of the publishers has gone away?
                const reason = msg['reason'];
                const leaving = msg['leaving'];
                detachFeed(leaving, 'leave', reason);
            } else if (msg['unpublished']) {
                // One of the publishers has unpublished?
                const unpublished = msg['unpublished'];
                detachFeed(unpublished, 'leave');
            } else if (msg['error']) {
                // Some error handle
                const error_code = msg['error_code'];
                if (error_code === 432) {
                    // Max publishers are reached, we need to disable audio/video
                    const {
                        applicationConfiguration: {
                            config: { maxPublishers },
                        },
                    } = janusCtx.getState();
                    janusCtx.dispatch(
                        showNotifyThunkAction(
                            getErrorNotification(
                                t({
                                    message:
                                        'Максимальное количество активных участников достигнуто ({maxPublishers})',
                                    id: 'error.maxPublishers',
                                    values: { maxPublishers: maxPublishers || 25 },
                                }),
                            ),
                        ),
                    );
                    janusCtx.published = false;
                    janusCtx.dispatch(
                        setJanusStreamPropsAction({ useAudio: false, useVideo: false }),
                    );
                    janusCtx.dispatch(resetOwnStreamAction());
                    Janus.stopAllTracks(
                        janusCtx.videoRoomPublisherPluginHandler.webrtcStuff.myStream,
                    );
                    janusCtx.videoRoomPublisherPluginHandler.webrtcStuff.myStream = null;
                    janusCtx.videoRoomPublisherPluginHandler.webrtcStuff.pc.close();
                    janusCtx.videoRoomPublisherPluginHandler.webrtcStuff.pc = null;
                    janusCtx.videoRoomPublisherPluginHandler.webrtcStuff.mySdp = null;
                    // janusCtx.videoRoomPlugin.hangup();
                    // leaveFromConferenceJanus();
                    // setTimeout(() => attachToVideoRoom(), 1000);
                }
            } else if (msg['moderation']) {
                applyAudioVideoPermissionFromModerator(msg);
            } else {
                janusVideoRoomMessagesLogger.debug('Unprocessed message', msg);
            }
        }
    }
    if (jsep) {
        janusVideoRoomMessagesLogger.debug('Handling SDP as well...', JSON.stringify(jsep));
        janusCtx.videoRoomPublisherPluginHandler.handleRemoteJsep({ jsep });
    }
};

export const detachFeed = async (feedId: string, action: 'leave' | 'kick', reason?: string) => {
    if (feedId === 'ok') {
        // That's us
        janusVideoRoomLogger.debug('Hangup');
        janusCtx.videoRoomPublisherPluginHandler?.hangup();
        if (reason === 'kicked') {
            janusCtx?.dispatch(exitFromConferenceThunkAction({ stopJanus: false }));
        }
        return;
    }

    detachPublisher(feedId, action);
};
