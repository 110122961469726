import { useState } from 'react';
import { getLanguages, getTextByLanguage, LanguageItem } from 'utils/languagesOperations';
import { IDispatchProps, IStateProps } from './types';
import OutsideClick from 'components/common/OutsideClick';
import styles from './LanguageMenu.module.scss';
import { TLanguages } from 'types/TLanguages';

export const LanguageMenu: React.FC<IStateProps & IDispatchProps> = ({
    currentLanguage,
    setLanguage,
}) => {
    const [isShowList, setIshowList] = useState<boolean>(false);
    function showList() {
        setIshowList(true);
    }
    function closeList() {
        setIshowList(false);
    }
    function chooseLang(lang: TLanguages) {
        if (lang !== currentLanguage) {
            setLanguage(lang);
        }
        closeList();
    }
    function renderList() {
        return getLanguages()
            .filter((i: LanguageItem) => i.language !== currentLanguage && i.language)
            .map((i: LanguageItem) => {
                const lang = i.language;
                return (
                    <li>
                        <a
                            key={lang}
                            className={styles.item}
                            onClick={() => chooseLang(lang)}
                            data-lang={lang}>
                            {getTextByLanguage(lang)}
                        </a>
                    </li>
                );
            });
    }
    return (
        <nav className={styles.menu}>
            <a
                className={styles.field}
                onClick={showList}
                data-lang={currentLanguage}
                data-qa='languageSwitchHeader'>
                <div className={styles.text}>{getTextByLanguage(currentLanguage)}</div>
            </a>
            {isShowList && (
                <OutsideClick onOutsideClick={closeList}>
                    <ul className={styles.list}>{renderList()}</ul>
                </OutsideClick>
            )}
        </nav>
    );
};
