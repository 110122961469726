import { MobilePageTemplate } from 'components/MobilePageTemplate';
import { EPaths } from 'constants/EPaths';
import { useEffect } from 'react';
import { redirect } from 'utils/redirect';
import { LoginForJoinFormMobileConnected } from './LoginForJoinForm/LoginForJoinFormMobileConnected';
import { IDispatchProps, IStateProps } from './types';

export const LoginForJoinPageMobile: React.FC<IStateProps & IDispatchProps> = ({
    conferenceConnectInfo,
    currentConferenceId,
    isAuthUser,
    goToHomePage,
}) => {
    useEffect(() => {
        if (!currentConferenceId || isAuthUser) {
            goToHomePage();
        }

    }, []);

    return (
        <MobilePageTemplate title={conferenceConnectInfo?.name} onClickBack={() => redirect('/')}>
            <LoginForJoinFormMobileConnected id={currentConferenceId} />
        </MobilePageTemplate>
    );
};
