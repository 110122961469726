import React from 'react';
import { setEscCloseDropdown } from 'utils/escCloseDropdown';
import styles from './ParticipantContextMenu.module.scss';
import { ParticipantContextMenuDropdownConnected } from './ParticipantContextMenuDropdown';
import { IOwnProps } from './types';

export const ParticipantContextMenu: React.FC<IOwnProps> = ({
    title,
    orientation = 'up-left',
    participantId,
}) => {
    const [isOpen, setIsOpen] = React.useState(false);
    const [mouseHandlersContainer, setMouseHandlersContainer] = React.useState<Element | null>(
        null,
    );
    const contextMenuContainer = React.useRef<HTMLDivElement>(null);

    React.useEffect(() => {
        setEscCloseDropdown(isOpen);
    }, [isOpen]);

    const handleClick = () => setIsOpen(!isOpen);
    const getContainerForMouseHandlers = () => {
        if (contextMenuContainer.current) {
            const targetContainer = contextMenuContainer.current.parentElement?.parentElement;
            targetContainer ? setMouseHandlersContainer(targetContainer) : false;
        }
    };

    const addMouseHandlers = () => {
        if (mouseHandlersContainer) {
            mouseHandlersContainer.addEventListener('mouseleave', handleMouseOut);
        }
    };

    const delMouseHandlers = () => {
        if (mouseHandlersContainer) {
            mouseHandlersContainer.removeEventListener('mouseleave', handleMouseOut);
        }
    };

    const handleMouseOut = () => {
        isOpen ? setIsOpen(false) : false;
    };

    React.useEffect(() => {
        getContainerForMouseHandlers();
        addMouseHandlers();
        return () => {
            delMouseHandlers();
        };
    });
    return (
        <div className={styles.menu} onClick={handleClick} ref={contextMenuContainer}>
            <div className={styles.dots}>
                <div />
            </div>
            {isOpen && (
                <ParticipantContextMenuDropdownConnected
                    orientation={orientation}
                    participantId={participantId}
                    setIsOpen={setIsOpen}
                    title={title}
                />
            )}
        </div>
    );
};
