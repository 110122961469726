import { format } from 'date-fns';
import { getUserTimeZone } from 'utils/timeZones/getUserTimeZone';
import { convertStringToDateByTemplate } from './convertStringToDateByTemplate';
import { getCurrentDate } from './getCurrentDate';
import { getDateBasedOnTimezone } from './getDateBasedOnTimezone';

export const getCurrentTimeByTemplate = (template: string): string => {
    const userTimeZone = getUserTimeZone();
    const currentDateUTC0 = convertStringToDateByTemplate(
        getCurrentDate().toISOString(),
        'yyyy-MM-dd HH:mm:ss.SSS',
        true,
    );
    const currentDateWithTimeZone = getDateBasedOnTimezone(currentDateUTC0, userTimeZone);
    return format(currentDateWithTimeZone, template);
};
