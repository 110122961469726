export const formatPhoneNumber = (value: string) => {
    if (!value) return '';
    let input = value.replace(/\D/g, '');

    input = input.substring(0, 10);
    const size = value.length;
    if (size < 4) {
        input = '+7 (' + input;
    } else if (size < 7) {
        input = '+7 (' + input.substring(0, 3) + ') ' + input.substring(3, 6);
    } else {
        input =
            '+7 (' +
            input.substring(0, 3) +
            ') ' +
            input.substring(3, 6) +
            ' - ' +
            input.substring(6, 10);
    }
    return input;
};

export const formatPhoneNumberToAPI = (value: string) => {
    if (value) {
        const valueString = value.slice(0, 19);
        return valueString.replace(/\+7|^\s*\+|\s|\(|\)|-/g, '');
    }
    return value;
};
