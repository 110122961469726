import { IConferenceParticipant } from 'types/IConferenceParticipant';

export const getSessionNewParticipants = (
    sessionParticipants: IConferenceParticipant[],
    conferenceParticipants: IConferenceParticipant[] | undefined,
): IConferenceParticipant[] => {
    if (!conferenceParticipants) {
        return [];
    }
    const copySession = [...sessionParticipants];
    const resultArr = conferenceParticipants.map((participant) => {
        let oldUserIndex: number | null = null;
        const isOldUser = copySession.find((oldParticipant, index) => {
            const isAnonymUser = !participant?.userId;
            const anonymUserCondition =
                isAnonymUser && oldParticipant.participantId === participant.participantId;
            const authUserCondition =
                oldParticipant.userId === participant.userId && participant.userId;
            if (anonymUserCondition) {
                oldUserIndex = index;
                return oldParticipant;
            }
            if (authUserCondition) {
                oldUserIndex = index;
                return { ...oldParticipant, ...{ participantId: participant.participantId } };
            }
            if (isAnonymUser) {
                oldUserIndex = null;
                return participant;
            }
        });
        if (isOldUser && oldUserIndex !== null) {
            copySession.splice(oldUserIndex, 1);
        }
        return participant;
    });
    return [...resultArr, ...copySession];
};
