import { createAsyncThunk } from '@reduxjs/toolkit';
import { EPaths } from 'constants/EPaths';
import { executePendingOperationThunkAction } from 'thunk/appView/executePendingOperationThunkAction';
import { TAppState } from 'types/app/TAppStore';
import { redirect } from 'utils/redirect';
import { getConferenceInfoThunkAction } from './getConferenceInfoThunkAction';

export const loadDataForConferenceSettingsPageThunkAction = createAsyncThunk<void, string>(
    'loadDataForConferenceSettingsPage',
    async (id, { getState, dispatch }) => {
        dispatch(
            executePendingOperationThunkAction({
                operation: 'defaultOperation',
                pendingFunction: async () => {
                    const {
                        auth: { rememberMeRequestStatus },
                    } = getState() as TAppState;
                    if (rememberMeRequestStatus === 'error') {
                        redirect('/');
                        return;
                    }
                    const conferenceInfo = await dispatch(
                        getConferenceInfoThunkAction({ id }),
                    ).unwrap();
                    if (!conferenceInfo) {
                        redirect(EPaths.UPCOMING_CONFERENCES);
                        return;
                    }
                    return conferenceInfo;
                },
            }),
        );
    },
);
