import { CircleLoader } from 'components/common/CircleLoader';
import { DateFilterMobile } from 'components/common/DateFilter/DateFilterMobile';
import { useConferenceDeleteDialog } from 'hooks/useConferenceDeleteDialog';
import { useConferenceEditDialog } from 'hooks/useConferenceEditDialog';
import { useEffect, useRef, useState } from 'react';
import { useLocation } from 'react-router-dom';
import { IConferenceRecordsInfo } from 'types/conferencesList/IConferenceRecordsInfo';
import { IPastConferenceInfo } from 'types/conferencesList/IPastConferenceInfo';
import { IUpcomingConferenceInfo } from 'types/conferencesList/IUpcomingConferenceInfo';
import { getSwipeDirection } from 'types/getSwipeDirection';
import styles from './AccountConferencesPageMobile.module.scss';
import { PastConferencesResponsive } from './ConferencesList/PastConferences';
import { UpcomingConferencesEmpty } from './ConferencesList/UpcomingConferences/UpcomingConferencesEmpty';
import { UpcomingConferencesMobile } from './ConferencesList/UpcomingConferences/UpcomingConferencesMobile';
import { IDispatchProps, IOwnProps, IStateProps, TGroupContainerItem } from './types';
import { getGroupedConferences, getGroupTitle, isFilterSet } from './utils';

export const AccountConferencesPageMobile: React.FC<IOwnProps & IStateProps & IDispatchProps> = ({
    containerRef,
    conferenceIdsToShow,
    currentUser,
    filters,
    pastConferences,
    pastConferencesListOperationPending,
    recordsList,
    upcomingConferences,
    upcomingConferencesListOperationPending,
    changeFilter,
    goToConferenceHistory,
    prepareToConnectToConference,
    reset,
    deleteConference,
    goToEditConference,
}) => {
    const location = useLocation();
    const splittedPath = location.pathname.split('/');
    const pathLastPart = splittedPath[splittedPath.length - 1];
    useEffect(() => {
        changeFilter({
            past: filters.past,
            upcoming: filters.upcoming,
            finished: pathLastPart == 'past' ? true : false,
        });
        return () => reset();
    }, []);
    const [editConferenceHandler, editConferenceConfirmDialogRender] = useConferenceEditDialog(
        currentUser,
        goToEditConference,
    );
    const [deleteConferenceHandler, deleteConferenceConfirmDialogRender] =
        useConferenceDeleteDialog(currentUser, deleteConference);
    const [swipedConferenceId, setSwipedConferenceId] = useState<string | null>(null);
    const touchCoordinates = useRef<[number, number] | null>(null);
    const handleTouchStart = (e: any) => {
        const { clientX, clientY } = e.touches[0];
        touchCoordinates.current = [clientX, clientY];
    };
    const handleTouchMove = (e: any, id: string, owner: boolean) => {
        if (touchCoordinates.current) {
            const direction = getSwipeDirection(touchCoordinates.current, [
                e.touches[0].clientX,
                e.touches[0].clientY,
            ]);
            if (direction === 'left' && swipedConferenceId !== id && owner) {
                setSwipedConferenceId(id);
            } else {
                setSwipedConferenceId(null);
            }
            touchCoordinates.current = null;
        }
    };
    const filterSet = isFilterSet(filters);
    let groupRender: (group: any) => JSX.Element;
    if (filters.finished) {
        groupRender = (group: TGroupContainerItem) => (
            <PastConferencesResponsive
                conferences={group.conferences as (IPastConferenceInfo & IConferenceRecordsInfo)[]}
                currentUser={currentUser}
                scrollableWrapper={containerRef}
                goToConferenceHistory={goToConferenceHistory}
                goToConnectConference={prepareToConnectToConference}
            />
        );
    } else {
        groupRender = (group: TGroupContainerItem) => (
            <UpcomingConferencesMobile
                conferences={group.conferences as IUpcomingConferenceInfo[]}
                currentUser={currentUser}
                handleTouchMove={handleTouchMove}
                handleTouchStart={handleTouchStart}
                goToConnectConference={prepareToConnectToConference}
                swipedConferenceId={swipedConferenceId}
                closeActionButtons={() => setSwipedConferenceId(null)}
                editConferenceHandler={editConferenceHandler}
                deleteConferenceHandler={deleteConferenceHandler}
            />
        );
    }
    const renderContent = () => {
        if (
            (filters.finished && pastConferencesListOperationPending) ||
            (!filters.finished && upcomingConferencesListOperationPending)
        ) {
            return <CircleLoader color='blue' />;
        }
        if (!filters.finished && !Object.keys(upcomingConferences).length) {
            return <UpcomingConferencesEmpty />;
        }
        return (
            <div className={styles.conferences}>
                {getGroupedConferences(
                    pastConferences,
                    upcomingConferences,
                    filters.finished,
                    recordsList,
                    conferenceIdsToShow,
                ).map(
                    (group: TGroupContainerItem) =>
                        group.conferences.length !== 0 && (
                            <div className={styles.group} key={group.key}>
                                <div className={styles.group__date}>
                                    {getGroupTitle(group)}
                                </div>
                                {groupRender(group)}
                            </div>
                        ),
                )}
            </div>
        );
    };
    return (
        <>
            {editConferenceConfirmDialogRender() || deleteConferenceConfirmDialogRender()}
            <div className={styles.wrapper}>
                <DateFilterMobile
                    filters={filters}
                    filtersSet={filterSet}
                    handleChangeFilter={changeFilter}
                    type='conferences'
                />
                {renderContent()}
            </div>
        </>
    );
};
