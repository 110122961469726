import { createAsyncThunk } from '@reduxjs/toolkit';
import { removeWebHookAction } from 'admin-app/actions/webHooks/removeWebHookAction';
import { deleteWebHookApiCall } from 'admin-app/api/webHooks/deleteWebHookApiCall copy';
import { EWebHooksActions } from 'admin-app/constants/thunk/EWebHooksActions';
import { IApiCallErrorData } from 'admin-app/types/IApiCallErrorData';
import { getNetworkService } from 'api/getNetworkService';

interface IPayload {
    id: number;
}

export const deleteWebHookThunkAction = createAsyncThunk<IApiCallErrorData | undefined, IPayload>(
    EWebHooksActions.DELETE_HOOK,
    async (data, { dispatch }) => {
        const { success, errorCode, errorDescription } = await deleteWebHookApiCall(
            getNetworkService(),
            data,
        );
        if (!success) {
            return { errorCode, errorDescription };
        }
        dispatch(removeWebHookAction(data.id));
    },
);
