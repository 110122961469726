import { setFoundUsersAction } from 'actions/schedulingConference/setFoundUsersAction';
import { connect } from 'react-redux';
import { getUsersSchedulingConfThunkAction } from 'thunk/schedulingConference/getUsersSchedulingConfThunkAction';
import { TAppDispatch } from 'types/app/TAppDispatch';
import { TAppState } from 'types/app/TAppStore';
import { FloatUsersList } from './FloatUsersList';
import { IDispatchProps, IStateProps } from './types';

const mapStateToProps = (state: TAppState): IStateProps => ({
    users: state.schedulingConference.ldSearchUsers,
});

const mapDispatchToProps = (dispatch: TAppDispatch): IDispatchProps => ({
    updateUsersList: (inputValue: string) =>
        dispatch(getUsersSchedulingConfThunkAction(inputValue)),
    resetUsersList: () => dispatch(setFoundUsersAction([])),
});

export const FloatUsersListConnected = connect(mapStateToProps, mapDispatchToProps)(FloatUsersList);
