import { TConferenceRecordingStatus } from 'types/conferences/TConferenceRecordingStatus';
import { TSocketRecorderStatus } from 'types/socket/TSocketRecorderStatus';

export const convertSocketRecordStatus = (
    socketRecordStatus: TSocketRecorderStatus,
): TConferenceRecordingStatus => {
    switch (socketRecordStatus) {
        case 'ACTIVE':
            return 'on';
        case 'INACTIVE':
            return 'off';
        case 'LAUNCHING':
            return 'launch';
        case 'TERMINATING':
            return 'terminate';
        default:
            return 'off';
    }
};
