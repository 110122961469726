import { connect } from 'react-redux';
import { planNewConferenceThunkAction } from 'thunk/conference/planNewConferenceThunkAction';
import { TAppDispatch } from 'types/app/TAppDispatch';
import { PlanConferenceButton } from './PlanConferenceButton';
import { IDispatchProps } from './types';

const mapDispatchToProps = (dispatch: TAppDispatch): IDispatchProps => ({
    planNewConference: () => dispatch(planNewConferenceThunkAction()),
});

export const PlanConferenceButtonConnected = connect(null, mapDispatchToProps)(PlanConferenceButton);
