import { CircleLoader } from 'components/common/CircleLoader';
import { ConferenceScheduleSettingsFormMobileConnected } from 'components/ConferenceScheduleSettingsForm/ConferenceScheduleSettingsFormMobileConnected';
import { useEffect } from 'react';
import styles from './ConferenceSchedulingPage.module.scss';
import { IDispatchProps, IOwnProps, IStateProps } from './types';

export const ConferenceSchedulingPageMobile: React.FC<IOwnProps & IStateProps & IDispatchProps> = ({
    id,
    conferencesInfo,
    defaultOperationPending,
    loadData,
}) => {
    const conferenceInfo = id ? conferencesInfo[id] : undefined;
    useEffect(() => {
        if (id) loadData(id);
    }, []);
    return (
        <div className={`${styles.schedule} ${styles.schedule_mobile}`}>
            {defaultOperationPending && id && <CircleLoader color='blue' />}
            {((!defaultOperationPending && id && conferenceInfo) || !id) && (
                <ConferenceScheduleSettingsFormMobileConnected conferenceInfo={conferenceInfo} />
            )}
        </div>
    );
};
