import { createReducer } from '@reduxjs/toolkit';
import { setAnonymousUserDataAction } from 'actions/auth/setAnonymousUserDataAction';
import { setApplicationLicenseAction } from 'actions/auth/setApplicationLicenseAction';
import { setAppSettingsAction } from 'actions/auth/setAppSettingsAction';
import { setCurrentUserDataAction } from 'actions/auth/setCurrentUserDataAction';
import { setRememberMeRequestStatusAction } from 'actions/auth/setRememberMeRequestStatusAction';
import { setUserIsAnonymousAction } from 'actions/auth/setUserIsAnonymousAction';
import { resetStoreAction } from 'actions/resetStoreAction';
import { appSettingsStructure } from 'constants/appSettingsStructure';
import { TRequestStatus } from 'types/api/TRequestStatus';
import { IApplicationLicense } from 'types/app/IApplicationLicense';
import { IAppSettings } from 'types/app/IAppSettings';
import { IAnonymousUserData } from 'types/user/IAnonymousUserData';
import { IUserData } from 'types/user/IUserData';
import { getDefaultSettings } from 'utils/getAppSettings';
import { log } from 'utils/log';
import { configureLogger } from 'utils/logger';

export interface IAuthReducer {
    anonymous: boolean;
    anonymousUser: IAnonymousUserData | null;
    appSettings: IAppSettings | null;
    currentUser: IUserData | null;
    license: IApplicationLicense;
    rememberMeRequestStatus: TRequestStatus;
}

export const authReducerInitialState: IAuthReducer = {
    anonymous: true,
    anonymousUser: null,
    appSettings: { ...getDefaultSettings(appSettingsStructure) },
    currentUser: null,
    license: {
        activationId: '',
        expirationDate: '',
        features: '',
        licenseId: '',
        licenseName: '',
        numberUsers: 0,
        productId: '',
        serverId: '',
        startDate: '',
        trial: true,
        version: '',
    },
    rememberMeRequestStatus: 'initial',
};

export const authReducer = createReducer(authReducerInitialState, (builder) =>
    builder
        .addCase(setRememberMeRequestStatusAction, (state, { payload: status }) => {
            state.rememberMeRequestStatus = status;
        })
        .addCase(setCurrentUserDataAction, (state, { payload: userData }) => {
            state.currentUser = userData;
            if (userData) {
                state.anonymous = false;
                state.anonymousUser = null;
            } else {
                state.anonymous = true;
                state.appSettings = getDefaultSettings(appSettingsStructure);
            }
        })
        .addCase(setUserIsAnonymousAction, (state, { payload: anonymous }) => {
            state.anonymous = anonymous;
        })
        .addCase(setAnonymousUserDataAction, (state, { payload: anonymousUserData }) => {
            state.anonymousUser = anonymousUserData;
        })
        .addCase(setAppSettingsAction, (state, { payload: appSettings }) => {
            if ('use3dAudioSettings' in appSettings) {
                if (appSettings.use3dAudioSettings) {
                    try {
                        appSettings.use3dAudioSettingsValue = JSON.parse(
                            appSettings.use3dAudioSettings,
                        );
                    } catch (err) {
                        log.error(
                            'Cant parse audio settings:',
                            appSettings.use3dAudioSettings,
                            err,
                        );
                    }
                } else {
                    appSettings.use3dAudioSettingsValue = undefined;
                }
            }
            state.appSettings = {
                ...state.appSettings,
                ...appSettings,
            };
            configureLogger(JSON.parse(appSettings?.debugConfig));
        })
        .addCase(resetStoreAction, () => {
            configureLogger({});
            return authReducerInitialState;
        })
        .addCase(setApplicationLicenseAction, (state, { payload: license }) => {
            state.license = license;
        }),
);
