const colors = [
    '#85B66F',
    '#B6846F',
    '#6FADB6',
    '#4F83D2',
    '#8951D0',
    '#8D2182',
    '#B7A444',
    '#3CB6A0',
    '#A0403A',
    '#00B74A',
    '#9FEE00',
    '#33CCCC',
    '#0B61A4',
    '#9D3ED5',
];

export const getUserColor = (displayName: string):string => {
    const editedDisplayName = displayName.trim().replace(/ /g, '').split('');
    const charCodeSum = editedDisplayName.reduce((previousValue, currentValue) => {
        return currentValue.charCodeAt(0) + previousValue;
    }, 0);
    const colorIndex = (charCodeSum % displayName.length) + 1;
    return colorIndex > colors.length ? colors[colors.length - 1] : colors[colorIndex];
};
