import { createAsyncThunk } from '@reduxjs/toolkit';
import { inviteRegisterApiCall } from 'api/authorization/inviteRegisterApiCall';
import { getNetworkService } from 'api/getNetworkService';
import { loginThunkAction } from 'thunk/auth/loginThunkAction';
import { IRequestError } from 'types/api/IRequestError';
import { getBrowserTimeZone } from 'utils/timeZones/getBrowserTimeZone';

interface IPayload {
    email: string;
    password: string;
}

export const inviteRegistrationThunkAction = createAsyncThunk(
    'inviteRegistration',
    async ({ email, password }: IPayload, { dispatch }): Promise<IRequestError | undefined> => {
        const timeZone = getBrowserTimeZone();
        const search = location.search;
        const params = new URLSearchParams(search);
        const token = params.get('token');
        if (!token) {
            // TODO:notification?
            return;
        }
        const response = await inviteRegisterApiCall(getNetworkService(), {
            token,
            email,
            password,
            timeZone,
        });
        if (response.success) {
            dispatch(loginThunkAction({ username: email, password }));
        } else {
            return response.data;
        }
    },
);
