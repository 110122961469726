import { createAction } from '@reduxjs/toolkit';
import { EApplicationViewActions } from 'constants/actions/EApplicationViewActions';

interface IPayload {
    operation: string;
    pending: boolean;
}

export const setPendingOperationAction = createAction<IPayload>(
    EApplicationViewActions.SET_PENDING_OPERATION,
);
