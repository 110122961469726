import { t } from '@lingui/macro';
import { Trans } from '@lingui/react';
import { Button } from 'components/common/Button';
import { Input } from 'components/common/Input';
import { StatusLabel } from 'components/common/StatusLabel';
import { Form, FormRenderProps } from 'react-final-form';
import commonStyles from '../PinEnterForm.module.scss';
import { IDispatchProps, IOwnProps, IValues } from '../types';
import { getSubmitHandler, handleValidate } from '../utils';
import styles from './PinEnterFormMobile.module.scss';

export const PinEnterFormMobile: React.FC<IOwnProps & IDispatchProps> = ({
    submitFormData,
    successSubmitCallback,
}) => {
    const handleSubmit = getSubmitHandler({ submitFormData, successSubmitCallback });
    const render = ({
        handleSubmit,
        submitting,
        submitErrors,
        errors,
    }: FormRenderProps<IValues>) => (
        <form onSubmit={handleSubmit} className={styles.form}>
            <div className={commonStyles.block}>
                <StatusLabel
                    text={
                        submitErrors?.pin && t({ id: 'Pin error', message: 'Введён неверный код' })
                    }
                    className={styles.statusLabel}
                />
                <div className={styles.text}>
                    <Trans message='Код доступа' id='Access code' />
                </div>
                <Input
                    placeholder={t({ id: 'Enter pin', message: 'Введите код доступа' })}
                    name='pin'
                    type='password'
                    autoFocus={true}
                    styleType='mobile'
                    touched={false}
                    errorText={errors?.pin}
                />
            </div>
            <Button
                styleType='mobilePrimaryAction'
                caption={t({ id: 'Join', message: 'Присоединиться' })}
                disabled={submitting}
                type='submit'
                className={styles.button}
            />
        </form>
    );
    return <Form onSubmit={handleSubmit} validate={handleValidate} render={render} />;
};
