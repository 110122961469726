import { t } from '@lingui/macro';
import { createAsyncThunk } from '@reduxjs/toolkit';
import { ECopyToClipboardNotification } from 'constants/ECopyToClipboardNotification';
import { showNotifyThunkAction } from 'thunk/notify/showNotifyThunkAction';
import { TNotificationType } from 'types/TNotificationType';

type TCopyToClipboardNotificationKey = keyof typeof ECopyToClipboardNotification;

const getCopyToClipboardNotification = (key: TCopyToClipboardNotificationKey) => {
    switch (key) {
        case 'INVITE_LINK':
            return t({
                message: 'Ссылка приглашения скопирована в буфер обмена',
                id: 'conf.dialog.link',
            });
        case 'CONF_ID':
            return t({
                message: 'Идентификатор скопирован в буфер обмена',
                id: 'conf.dialog.confId',
            });
        case 'CONF_PIN':
            return t({
                message: 'Код доступа скопирован в буфер обмена',
                id: 'conf.dialog.pin',
            });
    }
};

type TDataToCopy = string;
interface INotificationPayload {
    text: string;
    timeout?: number;
    type?: TNotificationType;
}

export interface ICopyToClipboardThunkPayload {
    notificationOptions?: Omit<INotificationPayload, 'text'>;
    notifyTextKey?: TCopyToClipboardNotificationKey;
    dataToCopy: TDataToCopy;
}

export const copyToClipboardThunkAction = createAsyncThunk<void, ICopyToClipboardThunkPayload>(
    'copyToClipboard',
    async ({ notificationOptions = {}, dataToCopy, notifyTextKey }, { dispatch }) => {
        await navigator.clipboard.writeText(dataToCopy);
        if (notifyTextKey) {
            const text = getCopyToClipboardNotification(notifyTextKey);
            const defaultNotificationSettings = {
                timeout: 5000,
                type: 'notify_green' as const,
            };
            const resultNotificationSettings = {
                ...defaultNotificationSettings,
                ...notificationOptions,
                text,
            };

            dispatch(showNotifyThunkAction(resultNotificationSettings));
        }
    },
);
