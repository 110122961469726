import { MobileWrapperConnected } from 'components/common/MobileWrapper/MobileWrapperConnected';
import { PersonalInformationPage } from './PersonalInformationPage';
import { PersonalInformationPageMobile } from './PersonalInformationPageMobile';

export const PersonalInformationPageResponsive = () => (
    <MobileWrapperConnected
        renderDesktop={() => <PersonalInformationPage />}
        renderMobile={() => <PersonalInformationPageMobile />}
    />
);
