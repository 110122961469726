import { createAsyncThunk } from '@reduxjs/toolkit';
import { storeConferenceConnectInfoAction } from 'actions/conferences/storeConferenceConnectInfoAction';
import { getConferenceConnectInfoApiCall } from 'api/conference/getConferenceConnectInfoApiCall';
import { getNetworkService } from 'api/getNetworkService';
import { getConferenceConnectInfoSelector } from 'selectors/getConferenceConnectInfoSelector';
import { TAppState } from 'types/app/TAppStore';
import { IConferenceConnectInfo } from 'types/conferences/IConferenceConnectInfo';

interface IPayload {
    id: string;
    forceUpdate?: boolean;
}

export const getConferenceConnectInfoThunkAction = createAsyncThunk<
    IConferenceConnectInfo | undefined,
    IPayload
>('getConferenceConnectInfo', async ({ id, forceUpdate = false }, { dispatch, getState }) => {
    let conference;
    if (!forceUpdate) {
        conference = getConferenceConnectInfoSelector(getState() as TAppState, id);
        if (conference) {
            return conference;
        }
    }
    const response = await getConferenceConnectInfoApiCall(getNetworkService(), id);
    if (response.success) {
        conference = response.data;
        if (conference) {
            dispatch(storeConferenceConnectInfoAction(conference));
        }
        return conference;
    }
    return undefined;
});
