import { devSettingsAudio, devSettingsDev, devSettingsVideo } from 'constants/appSettingsStructure';
import { IAppSettingsTyped } from 'types/app/IAppSettings';
import { IDynamicSectionStructure } from 'types/dynamicForm/IDynamicSectionStructure';
import { getAppSettingsDefaultValues } from 'utils/getAppSettingsDefaultValues';

export const getDevSettingsFormInitialValues = (appSettings: IAppSettingsTyped | null) => {
    if (appSettings) {
        const {
            videoCodec,
            displayedVideoLayout,
            janusAddress,
            audioCodec,
            use3dAudioSettings,
            features,
            debugConfig,
        } = appSettings;

        return {
            videoCodec,
            displayedVideoLayout,
            janusAddress,
            audioCodec,
            use3dAudioSettings: use3dAudioSettings || '',
            features: features || '',
            debugConfig,
        };
    }
    return getAppSettingsDefaultValues([devSettingsAudio, devSettingsDev, devSettingsVideo]);
};

export const devSettingsStructure: IDynamicSectionStructure[] = [
    devSettingsVideo,
    devSettingsAudio,
    devSettingsDev,
];
