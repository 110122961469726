import { useEffect, useRef, useState } from 'react';
import { getMimeTypeForImageFile } from 'utils/files/getMimeTypeForImageFile';
import { FullScreenPopup } from '../FullScreenPopup';
import { Spinner } from '../Spinner';
import styles from './ImageViewPopup.module.scss';
import { ImageViewPopupHeaderConnected } from './ImageViewPopupHeader/ImageViewPopupHeaderConnected';
import { IDispatchProps, IOwnProps } from './types';

export const ImageViewPopup: React.FC<IOwnProps & IDispatchProps> = ({
    authorName,
    conferenceId,
    fileName,
    getImage,
    id,
    onClick,
    sendTime,
    sessionId,
}) => {
    const [loaded, setLoaded] = useState(false);
    const [showHeader, setShowHeader] = useState<boolean>(false);
    const [imageDataUrl, setImageDataUrl] = useState('');

    const timerRef = useRef<null | NodeJS.Timeout>(null);

    const keyDownEscHandler = (e: KeyboardEvent) => {
        if (e.code === 'Escape') {
            e.stopImmediatePropagation();
            onClick();
        }
    };

    const createDataUrl = async () => {
        const result = await getImage({
            mediaFileId: id,
            sessionId,
            conferenceId,
        });
        if (!result) {
            return;
        }
        const blob = new Blob([result], { type: getMimeTypeForImageFile(fileName) });
        const reader = new FileReader();
        reader.addEventListener('loadend', () => setImageDataUrl(`${reader.result}`));
        reader.readAsDataURL(blob);
    };

    useEffect(() => {
        document.body.addEventListener('keydown', keyDownEscHandler);
        createDataUrl();
        return () => {
            document.body.removeEventListener('keydown', keyDownEscHandler);
        };
    }, []);

    const loadHandler = () => {
        setLoaded(true);
    };
    const mouseMoveHandler = (e: React.MouseEvent<HTMLElement>) => {
        if (timerRef?.current) {
            clearTimeout(timerRef.current);
            timerRef.current = null;
        }
        !showHeader && setShowHeader(true);
        if (e?.target && (e.target as HTMLElement).closest('[data-image-view-header]')) {
            return;
        }
        timerRef.current = setTimeout(() => {
            setShowHeader(false);
        }, 3000);
    };
    const popupListeners = [{ name: 'mousemove' as const, handler: mouseMoveHandler }];
    return (
        <FullScreenPopup theme='dark1' listeners={popupListeners}>
            <ImageViewPopupHeaderConnected
                id={id}
                authorName={authorName}
                sendTime={sendTime}
                onClick={onClick}
                isVisible={showHeader}
                src={imageDataUrl}
                fileName={fileName}
            />
            <img
                className={`${styles.img} ${!loaded ? styles.img_hidden : ''}`}
                src={imageDataUrl}
                alt='img'
                onLoad={loadHandler}
            />
            {!loaded && (
                <div className={styles.spinner}>
                    <Spinner />
                </div>
            )}
        </FullScreenPopup>
    );
};
