import { createAsyncThunk } from '@reduxjs/toolkit';
import { storeConferenceConnectInfoAction } from 'actions/conferences/storeConferenceConnectInfoAction';
import { batch } from 'react-redux';
import { getConferenceChangeStatusTopic } from 'services/socketService/getConferenceChangeStatusTopic';
import { addSubscription, getSocketServiceState } from 'services/socketService/socketService';
import { getConferenceConnectInfoThunkAction } from 'thunk/conference/getConferenceConnectInfoThunkAction';
import { TAppState } from 'types/app/TAppStore';
import { ISocketConferenceChangeStatusEvent } from 'types/socket/ISocketConferenceChangeStatusEvent';
import { getConnectToSocket } from 'utils/connectToSocketProxy';
import { log } from 'utils/log';

export const subscribeToConferenceChangeStatusThunkAction = createAsyncThunk(
    'subscribeToConferenceChangeStatus',
    (_: void, { dispatch, getState }) => {
        getConnectToSocket()(() => {
            const { stompClient } = getSocketServiceState();
            if (!stompClient) {
                log.error('Stomp client does not init.');
                return;
            }
            const {
                conferenceSession: { currentConferenceId, currentConferencePin },
                conferences: { conferencesConnectInfo },
            } = getState() as TAppState;
            addSubscription(
                'subscribeToConferenceChangeStatus',
                stompClient.subscribe(
                    getConferenceChangeStatusTopic(currentConferenceId),
                    (message) => {
                        const {
                            actionType: status,
                            conferenceId,
                        }: ISocketConferenceChangeStatusEvent = JSON.parse(message.body);
                        if (conferenceId === currentConferenceId) {
                            if (status === 'UPDATED') {
                                dispatch(
                                    getConferenceConnectInfoThunkAction({
                                        id: conferenceId,
                                        forceUpdate: true,
                                    }),
                                );
                                return;
                            }
                            batch(() => {
                                dispatch(
                                    storeConferenceConnectInfoAction({
                                        ...conferencesConnectInfo[currentConferenceId],
                                        status,
                                    }),
                                );
                            });
                        }
                    },
                    { pin: currentConferencePin },
                ),
            );
        }, 'subscribeToConferenceChangeStatus');
    },
);
