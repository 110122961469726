import { IOwnProps } from './types';
import styles from './DynamicFormInput.module.scss';
import { Input } from 'components/common/Input';

export const DynamicFormInput: React.FC<IOwnProps> = ({ name, title, placeholder, autoFocus, description }) => (
    <div className={styles.field}>
        <div className={styles.field__text}>
            <label className={styles.field__title}>{title}</label>
            {description && <span className={styles.field__description}>{description}</span>}
        </div>
        <div data-form-control>
            <Input
                name={name}
                placeholder={placeholder}
                className={styles.field__input}
                autoFocus={autoFocus}
                styleType='new'
            />
        </div>
    </div>
);
