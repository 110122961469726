import { Trans } from '@lingui/react';
import { ConferenceChatBtnConnected } from 'components/ConferenceChatBtn';
import { Button } from 'components/common/Button';
import { useEffect } from 'react';
import { AudioFramesConnected } from '../AudioFrames';
import styles from './ConferencePageMobileHeader.module.scss';
import { MobileLayoutsSelectConnected } from './MobileLayoutSelect';
import { IDispatchProps, IStateProps } from './types';
import { mobileConferenceMenuBtnId } from './utils';

export const ConferencePageMobileHeader: React.FC<IDispatchProps & IStateProps> = ({
    displayExitDialog,
    setConferenceInfoVisibility,
    showConferenceInfoFrame,
}) => {
    useEffect(() => {
        setConferenceInfoVisibility(false);
    }, []);

    const handleClickKebab = () => setConferenceInfoVisibility(!showConferenceInfoFrame);
    return (
        <>
            <div className={styles.root} data-header-mobile>
                <Button
                    styleType='kebab'
                    clickHandler={handleClickKebab}
                    id={mobileConferenceMenuBtnId}
                />
                <ConferenceChatBtnConnected isHideText={true} isMobileCounter={true} />
                <div>
                    <MobileLayoutsSelectConnected />
                </div>
                <Button styleType='red' clickHandler={displayExitDialog}>
                    <Trans id='Exit.conference' message='Выйти' />
                </Button>
            </div>
            <AudioFramesConnected />
        </>
    );
};
