import styles from './ConferenceSettingsBlock.module.scss';
import { IOwnProps } from './types';

export const ConferenceSettingsBlock: React.FC<IOwnProps> = ({
    children,
    text,
    className,
    classNameFields,
    textClassName,
}) => (
    <div className={`${styles.block} ${className ? className : ''}`}>
        <div className={`${styles.text} ${textClassName ? styles[textClassName] : ''}`}>{text}</div>
        <div className={`${classNameFields ? classNameFields : ''} ${styles.fields}`}>
            {children}
        </div>
    </div>
);
