import { useEffect, useRef, useState } from 'react';
import { logger } from 'utils/logger';
import { registerMediaStreamStartCallback } from 'utils/media/mediaStreamStartCallbacks';
import { IOwnProps } from './types';

export const ParticipantAudioContent: React.FC<IOwnProps> = ({
    audioStream,
    participantId,
    muted,
}) => {
    const audioRef = useRef<HTMLAudioElement | null>(null);
    const [audioMetadataLoaded, setAudioMetadataLoaded] = useState(false);
    useEffect(() => {
        if (audioMetadataLoaded) {
            const audioElement = audioRef.current;
            if (audioElement) {
                audioElement.muted = !!muted;
            }
        }
    }, [audioMetadataLoaded, muted]);
    useEffect(() => {
        const audioElement = audioRef.current;
        if (!audioElement) {
            return;
        }
        setAudioMetadataLoaded(false);
        audioElement.srcObject = audioStream || null;
        audioElement.onloadedmetadata = () => {
            audioElement.play().catch((error) => {
                logger.debug({ error });
                registerMediaStreamStartCallback({
                    type: 'audio',
                    participantId,
                    callback: () => audioElement.play(),
                });
            });
            setAudioMetadataLoaded(true);
        };
    }, [audioStream]);
    return audioStream ? <audio playsInline ref={audioRef} /> : null;
};
