import { resetPublisherStreamAction } from 'actions/janus/resetPublisherStreamAction';
import { MAX_FEEDS } from 'constants/janusConstants';
import { checkActiveParticipantsAndCanPublishThunkAction } from 'thunk/janus/checkActiveParticipantsAndCanPublishThunkAction';
import { janusCtx } from 'utils/janus/janusCtx';
import { janusVideoRoomLogger, janusVideoRoomMessagesLogger } from 'utils/logger';

export const detachPublisher = async (feedId: string, action: 'leave' | 'kick') => {
    janusVideoRoomLogger.debug('Publisher left: ' + feedId);
    if (janusCtx.subscribeType === 'single') {
        let subscribeHandler = null;
        for (let i = 1; i < MAX_FEEDS; i++) {
            if (
                janusCtx.singleStreamSubscribeHandlers[i] &&
                janusCtx.singleStreamSubscribeHandlers[i].rfid === feedId
            ) {
                subscribeHandler = janusCtx.singleStreamSubscribeHandlers[i];
                break;
            }
        }
        if (subscribeHandler !== null) {
            switch (action) {
                case 'kick':
                    janusVideoRoomLogger.debug(
                        `Feed ${subscribeHandler.rfid} (${subscribeHandler.rfdisplay}) has been kicked from the room, detaching`,
                    );
                    break;
                case 'leave':
                    janusVideoRoomLogger.debug(
                        `Feed ${subscribeHandler.rfid} (${subscribeHandler.rfdisplay}) has left the room, detaching`,
                    );
                    break;
            }
            janusCtx.singleStreamSubscribeHandlers[subscribeHandler.rfindex] = null;
            janusVideoRoomMessagesLogger.log({ subscribeHandler });

            subscribeHandler.hangup(false);
            for (let i = 1; i < MAX_FEEDS; i++) {
                if (
                    janusCtx.singleStreamSubscribeHandlers[i] &&
                    janusCtx.singleStreamSubscribeHandlers[i].rfid === feedId
                ) {
                    janusCtx.singleStreamSubscribeHandlers[i] = null;
                    break;
                }
            }
            // subscribeHandler.detach();
        }
    } else {
        const feedStreams = janusCtx.multiStreamFeedStreams[feedId];
        if (feedStreams) {
            feedStreams.streams.forEach(({ mid: feed_mid }) => {
                const mid = janusCtx.multiStreamSubscriptions[feedId][feed_mid];
                // const track = janusCtx.multiStreamRemoteTracks[mid];
                // if (track) {
                //     try {
                //         track.stop();
                //     } catch (e) {
                //         janusVideoRoomTracksLogger.error(e);
                //     }
                // }
                delete janusCtx.multiStreamRemoteTracks[mid];
                delete janusCtx.multiStreamSubStreams[mid];
            });
            delete janusCtx.multiStreamFeedStreams[feedId];
            delete janusCtx.multiStreamSubscriptions[feedId];
        }
    }
    janusCtx.dispatch(resetPublisherStreamAction(feedId));
    await janusCtx.dispatch(checkActiveParticipantsAndCanPublishThunkAction());
};
