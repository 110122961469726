import { IUpcomingConferenceInfo } from 'types/conferencesList/IUpcomingConferenceInfo';
import { IUserData } from 'types/user/IUserData';

export const isUserIsConferenceOwner = (
    currentUser: IUserData | null,
    conference: IUpcomingConferenceInfo,
): boolean => {
    if (currentUser) {
        return conference.creator.userId === currentUser.id;
    }
    return false;
};
