import { Button } from 'components/common/Button';
import { IOwnProps } from './types';
import styles from './ConferenceRecordControls.module.scss';
import { t } from '@lingui/macro';

export const ConferenceRecordControls: React.FC<IOwnProps> = ({
    isReady,
    isError,
    isRecordDownloadItem,
    isRecordOwner,
    notifyBtnText,
    isDisabled,
    handleDownloadRecord,
    handleDeleteRecord,
    handleRecordReady,
}) => {
    return (
        <>
            {isReady && (
                <div className={styles.buttons}>
                    {!isError && !isRecordDownloadItem && (
                        <Button
                            styleType='primaryAction'
                            caption={t({ id: 'Download', message: 'Скачать' })}
                            className={styles.button}
                            clickHandler={handleDownloadRecord}
                        />
                    )}
                    {isRecordOwner && !isRecordDownloadItem && (
                        <Button
                            styleType='secondaryAction'
                            caption={t({ id: 'Delete', message: 'Удалить' })}
                            className={`${styles.button} ${styles.button_delete}`}
                            clickHandler={handleDeleteRecord}
                        />
                    )}
                </div>
            )}
            {!isReady && !isError && (
                <div className={styles.buttons}>
                    <Button
                        styleType='primaryAction'
                        caption={notifyBtnText}
                        disabled={isDisabled}
                        className={styles.actionBtn}
                        clickHandler={handleRecordReady}></Button>
                </div>
            )}
        </>
    );
};
