import { Trans } from '@lingui/react';
import { FC } from 'react';
import styles from './SettingsPage.module.scss';
import { SettingsNavigation } from './SettingsNavigation';
import { Route } from 'react-router-dom';
import { EAdminPaths } from 'admin-app/constants/EAdminPaths';
import { LicenseConnected } from './Sections/License';
import { WebHooksConnected } from './Sections/WebHooks';
import { AuthorizationConnected } from './Sections/Authorization';

export const SettingsPage: FC = () => {
    return (
        <div className={styles.settings}>
            <h1 className={styles.settings__header}>
                <Trans id='admin.settings' message='Настройки' />
            </h1>
            <SettingsNavigation />
            <div className={styles.settings__body}>
                <Route exact path={EAdminPaths.SETTINGS_LICENSE} component={LicenseConnected} />
                <Route exact path={EAdminPaths.SETTINGS_HOOKS} component={WebHooksConnected} />
                <Route
                    exact
                    path={EAdminPaths.SETTINGS_AUTHORIZATION}
                    component={AuthorizationConnected}
                />
            </div>
        </div>
    );
};
