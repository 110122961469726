import { createAsyncThunk } from '@reduxjs/toolkit';
import { setCurrentConferenceRecordingAction } from 'actions/conferenceSession/setCurrentConferenceRecordingAction';
import { startConferenceRecordApiCall } from 'api/conference/startConferenceRecordApiCall';
import { stopConferenceRecordApiCall } from 'api/conference/stopConferenceRecordApiCall';
import { getNetworkService } from 'api/getNetworkService';
import { showNotifyThunkAction } from 'thunk/notify/showNotifyThunkAction';
import { IRequestResult } from 'types/api/INetworkService';
import { TAppState } from 'types/app/TAppStore';
import { getDefaultErrorNotification } from 'utils/getDefaultErrorNotification';

export const changeConferenceRecordStateThunkAction = createAsyncThunk(
    'changeConferenceRecordStateThunk',
    async (_: void, { getState, dispatch }) => {
        const {
            conferenceSession: { currentConferenceId, currentConferenceRecording },
        } = getState() as TAppState;
        if (!currentConferenceId) {
            return 500;
        }

        let response: IRequestResult<void> = { success: false, data: undefined };

        if (currentConferenceRecording === 'off') {
            dispatch(setCurrentConferenceRecordingAction('launch'));
            response = await startConferenceRecordApiCall(getNetworkService(), {
                conferenceId: currentConferenceId,
            });
        } else if (currentConferenceRecording === 'on') {
            dispatch(setCurrentConferenceRecordingAction('terminate'));
            response = await stopConferenceRecordApiCall(getNetworkService(), {
                conferenceId: currentConferenceId,
            });
        }

        if (!response.success) {
            dispatch(setCurrentConferenceRecordingAction(currentConferenceRecording));
            dispatch(showNotifyThunkAction(getDefaultErrorNotification()));
        }
    },
);
