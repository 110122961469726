import { setConferenceExitConfirmationDialogVisibilityAction } from 'actions/applicationView/setConferenceExitConfirmationDialogVisibilityAction';
import { connect } from 'react-redux';
import { getCurrentConferenceConnectInfoSelector } from 'selectors/getCurrentConferenceConnectInfoSelector';
import { exitFromConferenceThunkAction } from 'thunk/conference/exitFromConferenceThunkAction';
import { TAppDispatch } from 'types/app/TAppDispatch';
import { TAppState } from 'types/app/TAppStore';
import { ConferenceHeader } from './ConferenceHeader';
import { IDispatchProps, IStateProps } from './types';

const mapStateToProps = (state: TAppState): IStateProps => ({
    audioFramesExist: state.applicationView.displayedAudioFrames.length > 0,
    fullScreenFrames: state.applicationView.fullScreenFrames.length > 0,
    conferenceName: getCurrentConferenceConnectInfoSelector(state)?.name,
    conferenceStatus: getCurrentConferenceConnectInfoSelector(state)?.status,
    isAuthorizedUser: !!state.auth.currentUser,
});

const mapDispatchToProps = (dispatch: TAppDispatch): IDispatchProps => ({
    exitFromConference: () => dispatch(exitFromConferenceThunkAction({})),
    showExitConfirmation: () => dispatch(setConferenceExitConfirmationDialogVisibilityAction(true)),
});

export const ConferenceHeaderConnected = connect(
    mapStateToProps,
    mapDispatchToProps,
)(ConferenceHeader);
