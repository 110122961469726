import { createAsyncThunk } from '@reduxjs/toolkit';
import { deleteRecordReadyNotificationApiCall } from 'api/records/deleteRecordReadyNotificationApiCall';
import { getNetworkService } from 'api/getNetworkService';
import { setConferenceRecordNotifyStateAction } from 'actions/records/setConferenceRecordNotifyStateAction';

export const deleteRecordReadyNotificationThunkAction = createAsyncThunk(
    'deleteRecordReadyNotification',
    async (conferenceId: string, { dispatch }) => {
        const request = await deleteRecordReadyNotificationApiCall(
            getNetworkService(),
            conferenceId,
        );
        if (request.status === 200) {
            dispatch(
                setConferenceRecordNotifyStateAction({
                    recordId: conferenceId,
                    notifyState: false,
                }),
            );
        }
    },
);
