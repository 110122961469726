import styles from './CopyInviteLink.module.scss';
import LinkImg from 'assets/Link.svg';
import { Trans } from '@lingui/react';
import { IOwnProps } from './types';

export const CopyInviteLink: React.FC<IOwnProps> = ({ clickHandler }) => {
    return (
        <div className={styles.link} onClick={clickHandler}>
            <img src={LinkImg} alt='link' />
            <Trans id='copy.invite.link.text.id' message='Копировать ссылку-приглашение' />
        </div>
    );
};
