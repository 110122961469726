import { connect } from 'react-redux';
import { TAppState } from 'types/app/TAppStore';
import { LoginPage } from './LoginPage';
import { IStateProps } from './types';

const mapStateToProps = (state: TAppState): IStateProps => ({
    rememberMeRequestStatus: state.auth.rememberMeRequestStatus,
});

export const LoginPageConnected = connect(mapStateToProps)(LoginPage);
