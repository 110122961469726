interface ITimeZone {
    label: string;
    value: string;
    shiftInMinutes: number;
}

export const getTimeZonesArray = (): ITimeZone[] => [
    {
        label: '(GMT-12:00) International Date Line West',
        value: 'Etc/GMT+12',
        shiftInMinutes: -720,
    },
    { label: '(GMT-11:00) Midway Island, Samoa', value: 'Pacific/Midway', shiftInMinutes: -660 },
    { label: '(GMT-10:00) Hawaii', value: 'Pacific/Honolulu', shiftInMinutes: -600 },
    { label: '(GMT-09:00) Alaska', value: 'US/Alaska', shiftInMinutes: -540 },
    {
        label: '(GMT-08:00) Pacific Time (US & Canada)',
        value: 'America/Los_Angeles',
        shiftInMinutes: -480,
    },
    {
        label: '(GMT-08:00) Tijuana, Baja California',
        value: 'America/Tijuana',
        shiftInMinutes: -480,
    },
    { label: '(GMT-07:00) Arizona', value: 'US/Arizona', shiftInMinutes: -420 },
    {
        label: '(GMT-07:00) Chihuahua, La Paz, Mazatlan',
        value: 'America/Chihuahua',
        shiftInMinutes: -420,
    },
    {
        label: '(GMT-07:00) Mountain Time (US & Canada)',
        value: 'US/Mountain',
        shiftInMinutes: -420,
    },
    { label: '(GMT-06:00) Central America', value: 'America/Managua', shiftInMinutes: -360 },
    { label: '(GMT-06:00) Central Time (US & Canada)', value: 'US/Central', shiftInMinutes: -360 },
    {
        label: '(GMT-06:00) Guadalajara, Mexico City, Monterrey',
        value: 'America/Mexico_City',
        shiftInMinutes: -360,
    },
    { label: '(GMT-06:00) Saskatchewan', value: 'Canada/Saskatchewan', shiftInMinutes: -360 },
    {
        label: '(GMT-05:00) Bogota, Lima, Quito, Rio Branco',
        value: 'America/Bogota',
        shiftInMinutes: -300,
    },
    { label: '(GMT-05:00) Eastern Time (US & Canada)', value: 'US/Eastern', shiftInMinutes: -300 },
    { label: '(GMT-05:00) Indiana (East)', value: 'US/East-Indiana', shiftInMinutes: -300 },
    { label: '(GMT-04:00) Atlantic Time (Canada)', value: 'Canada/Atlantic', shiftInMinutes: -240 },
    { label: '(GMT-04:00) Caracas, La Paz', value: 'America/Caracas', shiftInMinutes: -240 },
    { label: '(GMT-04:00) Manaus', value: 'America/Manaus', shiftInMinutes: -240 },
    { label: '(GMT-04:00) Santiago', value: 'America/Santiago', shiftInMinutes: -240 },
    { label: '(GMT-03:30) Newfoundland', value: 'Canada/Newfoundland', shiftInMinutes: -210 },
    { label: '(GMT-03:00) Brasilia', value: 'America/Sao_Paulo', shiftInMinutes: -180 },
    {
        label: '(GMT-03:00) Buenos Aires, Georgetown',
        value: 'America/Argentina/Buenos_Aires',
        shiftInMinutes: -180,
    },
    { label: '(GMT-03:00) Greenland', value: 'America/Godthab', shiftInMinutes: -180 },
    { label: '(GMT-03:00) Montevideo', value: 'America/Montevideo', shiftInMinutes: -180 },
    { label: '(GMT-02:00) Mid-Atlantic', value: 'America/Noronha', shiftInMinutes: -120 },
    { label: '(GMT-01:00) Cape Verde Is.', value: 'Atlantic/Cape_Verde', shiftInMinutes: -60 },
    { label: '(GMT-01:00) Azores', value: 'Atlantic/Azores', shiftInMinutes: -60 },
    {
        label: '(GMT+00:00) Casablanca, Monrovia, Reykjavik',
        value: 'Africa/Casablanca',
        shiftInMinutes: 0,
    },
    {
        label: '(GMT+00:00) Greenwich Mean Time : Dublin, Edinburgh, Lisbon, London',
        value: 'Etc/Greenwich',
        shiftInMinutes: 0,
    },
    {
        label: '(GMT+01:00) Amsterdam, Berlin, Bern, Rome, Stockholm, Vienna',
        value: 'Europe/Amsterdam',
        shiftInMinutes: 60,
    },
    {
        label: '(GMT+01:00) Belgrade, Bratislava, Budapest, Ljubljana, Prague',
        value: 'Europe/Belgrade',
        shiftInMinutes: 60,
    },
    {
        label: '(GMT+01:00) Brussels, Copenhagen, Madrid, Paris',
        value: 'Europe/Brussels',
        shiftInMinutes: 60,
    },
    {
        label: '(GMT+01:00) Sarajevo, Skopje, Warsaw, Zagreb',
        value: 'Europe/Sarajevo',
        shiftInMinutes: 60,
    },
    { label: '(GMT+01:00) West Central Africa', value: 'Africa/Lagos', shiftInMinutes: 60 },
    { label: '(GMT+02:00) Amman', value: 'Asia/Amman', shiftInMinutes: 120 },
    {
        label: '(GMT+02:00) Athens, Bucharest, Istanbul',
        value: 'Europe/Athens',
        shiftInMinutes: 120,
    },
    { label: '(GMT+02:00) Beirut', value: 'Asia/Beirut', shiftInMinutes: 120 },
    { label: '(GMT+02:00) Cairo', value: 'Africa/Cairo', shiftInMinutes: 120 },
    { label: '(GMT+02:00) Harare, Pretoria', value: 'Africa/Harare', shiftInMinutes: 120 },
    {
        label: '(GMT+02:00) Helsinki, Kyiv, Riga, Sofia, Tallinn, Vilnius',
        value: 'Europe/Helsinki',
        shiftInMinutes: 120,
    },
    { label: '(GMT+02:00) Jerusalem', value: 'Asia/Jerusalem', shiftInMinutes: 120 },
    { label: '(GMT+02:00) Minsk', value: 'Europe/Minsk', shiftInMinutes: 120 },
    { label: '(GMT+02:00) Windhoek', value: 'Africa/Windhoek', shiftInMinutes: 120 },
    { label: '(GMT+03:00) Kuwait, Riyadh, Baghdad', value: 'Asia/Kuwait', shiftInMinutes: 180 },
    {
        label: '(GMT+03:00) Moscow, St. Petersburg, Volgograd',
        value: 'Europe/Moscow',
        shiftInMinutes: 180,
    },
    { label: '(GMT+03:00) Nairobi', value: 'Africa/Nairobi', shiftInMinutes: 180 },
    { label: '(GMT+03:00) Tbilisi', value: 'Asia/Tbilisi', shiftInMinutes: 180 },
    { label: '(GMT+03:30) Tehran', value: 'Asia/Tehran', shiftInMinutes: 210 },
    { label: '(GMT+04:00) Abu Dhabi, Muscat', value: 'Asia/Muscat', shiftInMinutes: 240 },
    { label: '(GMT+04:00) Baku', value: 'Asia/Baku', shiftInMinutes: 240 },
    { label: '(GMT+04:00) Yerevan', value: 'Asia/Yerevan', shiftInMinutes: 240 },
    { label: '(GMT+04:30) Kabul', value: 'Asia/Kabul', shiftInMinutes: 270 },
    { label: '(GMT+05:00) Yekaterinburg', value: 'Asia/Yekaterinburg', shiftInMinutes: 300 },
    {
        label: '(GMT+05:00) Islamabad, Karachi, Tashkent',
        value: 'Asia/Karachi',
        shiftInMinutes: 300,
    },
    {
        label: '(GMT+05:30) Chennai, Kolkata, Mumbai, New Delhi',
        value: 'Asia/Calcutta',
        shiftInMinutes: 330,
    },
    { label: '(GMT+05:30) Sri Jayawardenapura', value: 'Asia/Calcutta', shiftInMinutes: 330 },
    { label: '(GMT+05:45) Kathmandu', value: 'Asia/Katmandu', shiftInMinutes: 345 },
    { label: '(GMT+06:00) Almaty, Novosibirsk', value: 'Asia/Almaty', shiftInMinutes: 360 },
    { label: '(GMT+06:00) Astana, Dhaka', value: 'Asia/Dhaka', shiftInMinutes: 360 },
    { label: '(GMT+06:30) Yangon (Rangoon)', value: 'Asia/Rangoon', shiftInMinutes: 390 },
    { label: '(GMT+07:00) Bangkok, Hanoi, Jakarta', value: 'Asia/Bangkok', shiftInMinutes: 420 },
    { label: '(GMT+07:00) Krasnoyarsk', value: 'Asia/Krasnoyarsk', shiftInMinutes: 420 },
    {
        label: '(GMT+08:00) Beijing, Chongqing, Hong Kong, Urumqi',
        value: 'Asia/Hong_Kong',
        shiftInMinutes: 480,
    },
    {
        label: '(GMT+08:00) Kuala Lumpur, Singapore',
        value: 'Asia/Kuala_Lumpur',
        shiftInMinutes: 480,
    },
    { label: '(GMT+08:00) Irkutsk, Ulaan Bataar', value: 'Asia/Irkutsk', shiftInMinutes: 480 },
    { label: '(GMT+08:00) Perth', value: 'Australia/Perth', shiftInMinutes: 480 },
    { label: '(GMT+08:00) Taipei', value: 'Asia/Taipei', shiftInMinutes: 480 },
    { label: '(GMT+09:00) Osaka, Sapporo, Tokyo', value: 'Asia/Tokyo', shiftInMinutes: 540 },
    { label: '(GMT+09:00) Seoul', value: 'Asia/Seoul', shiftInMinutes: 540 },
    { label: '(GMT+09:00) Yakutsk', value: 'Asia/Yakutsk', shiftInMinutes: 540 },
    { label: '(GMT+09:30) Adelaide', value: 'Australia/Adelaide', shiftInMinutes: 570 },
    { label: '(GMT+09:30) Darwin', value: 'Australia/Darwin', shiftInMinutes: 570 },
    { label: '(GMT+10:00) Brisbane', value: 'Australia/Brisbane', shiftInMinutes: 600 },
    {
        label: '(GMT+10:00) Canberra, Melbourne, Sydney',
        value: 'Australia/Canberra',
        shiftInMinutes: 600,
    },
    { label: '(GMT+10:00) Hobart', value: 'Australia/Hobart', shiftInMinutes: 600 },
    { label: '(GMT+10:00) Guam, Port Moresby', value: 'Pacific/Guam', shiftInMinutes: 600 },
    { label: '(GMT+10:00) Vladivostok', value: 'Asia/Vladivostok', shiftInMinutes: 600 },
    {
        label: '(GMT+11:00) Magadan, Solomon Is., New Caledonia',
        value: 'Asia/Magadan',
        shiftInMinutes: 660,
    },
    { label: '(GMT+12:00) Auckland, Wellington', value: 'Pacific/Auckland', shiftInMinutes: 720 },
    {
        label: '(GMT+12:00) Fiji, Kamchatka, Marshall Is.',
        value: 'Pacific/Fiji',
        shiftInMinutes: 720,
    },
    { label: '(GMT+13:00) Nukualofa', value: 'Pacific/Tongatapu', shiftInMinutes: 780 },
];

export const timeZonesByValue: { [value: string]: ITimeZone } = {};

// Fill timeZonesByValue
getTimeZonesArray().forEach((timeZone) => (timeZonesByValue[timeZone.value] = timeZone));
