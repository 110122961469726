import { t } from '@lingui/macro';
import { IApplicationViewReducer } from 'reducers/applicationViewReducer.types';
import { IAudioVideoProps } from 'types/IAudioVideoProps';
import { IMenuItem } from 'types/IMenuItem';

export interface IGetParticipantContextMenuItemsPayload {
    audioVideoProps: Pick<
        IAudioVideoProps,
        'audioPermitted' | 'videoPermitted' | 'useAudio' | 'useVideo'
    >;
    changeAudioMute?: () => void;
    changeAudioPermission?: () => void;
    changeVideoMute?: () => void;
    changeVideoPermission?: () => void;
    currentUserIsOwner: boolean;
    isCurrentUser?: boolean;
    isUserIsOwner: boolean;
    isUserWithPriorityFrame?: boolean;
    kickParticipant?: () => void;
    participantId: string;
    participantLocalMuteStatus?: { video?: boolean; audio?: boolean };
    setPriorityFrames: (args: { participantIds: string[]; global: boolean }) => void;
    participantsFullScreen?: IApplicationViewReducer['participantsFullScreen'];
    participantsFullScreenGlobal?: IApplicationViewReducer['participantsFullScreenGlobal'];
}

const isMediaMuted = (
    isCurrentUser: boolean | undefined,
    useParticipantMedia: boolean,
    participantLocalMuteStatus: boolean | undefined,
) => (!isCurrentUser ? participantLocalMuteStatus : !useParticipantMedia);

export const getParticipantContextMenuItems = ({
    audioVideoProps: { audioPermitted, useAudio, useVideo, videoPermitted },
    participantLocalMuteStatus,
    changeAudioMute,
    changeAudioPermission,
    changeVideoMute,
    changeVideoPermission,
    currentUserIsOwner,
    isCurrentUser,
    isUserIsOwner,
    isUserWithPriorityFrame,
    kickParticipant,
    participantId,
    setPriorityFrames,
    participantsFullScreen,
    participantsFullScreenGlobal,
}: IGetParticipantContextMenuItemsPayload) => {
    const menuArray: IMenuItem[] = [
        {
            text: (currentUserIsOwner ? participantsFullScreen?.length : isUserWithPriorityFrame)
                ? t({
                    id: 'Exit full screen (local)',
                    message: 'Отключить полноэкранный режим',
                })
                : t({ id: 'On full screen (local)', message: 'На весь экран' }),
            onClick: () =>
                setPriorityFrames({
                    global: false,
                    participantIds: (
                        currentUserIsOwner
                            ? participantsFullScreen?.length
                            : isUserWithPriorityFrame
                    )
                        ? []
                        : [participantId],
                }),
            disabled: !videoPermitted || !useVideo,
            id: 'fullScreenLocal',
        },
    ];
    menuArray.push(
        {
            text: isMediaMuted(isCurrentUser, useAudio, participantLocalMuteStatus?.audio)
                ? t({ id: 'Unmute audio', message: 'Включить аудио' })
                : t({ id: 'Mute audio', message: 'Отключить аудио' }),
            onClick: changeAudioMute,
            id: 'audio',
        },
        {
            text: isMediaMuted(isCurrentUser, useVideo, participantLocalMuteStatus?.video)
                ? t({ id: 'Unmute video', message: 'Включить видео' })
                : t({ id: 'Mute video', message: 'Отключить видео' }),
            onClick: changeVideoMute,
            id: 'video',
        },
    );
    if (currentUserIsOwner) {
        menuArray.push({
            text:
                participantsFullScreenGlobal && participantsFullScreenGlobal[0] === participantId
                    ? t({
                        id: 'Exit full screen',
                        message: 'Отключить полноэкранный режим для всех',
                    })
                    : t({ id: 'On full screen', message: 'На весь экран у всех' }),
            onClick: () =>
                setPriorityFrames({
                    global: true,
                    participantIds:
                        participantsFullScreenGlobal &&
                        participantsFullScreenGlobal[0] === participantId
                            ? []
                            : [participantId],
                }),
            disabled: !videoPermitted || !useVideo,
            id: 'fullScreenModerate',
        });
        if (!isCurrentUser && !isUserIsOwner) {
            menuArray.push(
                {
                    text: audioPermitted
                        ? t({ id: 'Disable audio', message: 'Запретить микрофон' })
                        : t({ id: 'Enable audio', message: 'Разрешить микрофон' }),
                    onClick: changeAudioPermission,
                    id: 'audioModerate',
                },
                {
                    text: videoPermitted
                        ? t({ id: 'Disable video', message: 'Запретить видео' })
                        : t({ id: 'Enable video', message: 'Разрешить видео' }),
                    onClick: changeVideoPermission,
                    id: 'videoModerate',
                },
                {
                    text: t({ id: 'Kick from conference', message: 'Удалить из конференции' }),
                    danger: true,
                    onClick: kickParticipant,
                    id: 'kick',
                },
            );
        }
    }
    return menuArray;
};
