import { t } from '@lingui/macro';
import { Trans } from '@lingui/react';
import { ReactComponent as ScreenSvg } from 'assets/Screen.svg';
import { HoverLabel } from 'components/common/HoverLabel';
import { ConferenceButton } from 'components/ConferenceButton/ConferenceButton';
import styles from './ConferenceScreenShareBtn.module.scss';
import { IOwnProps } from './types';
import { SelectSharingScreenConnected } from 'components/Desktop/SelectSharingScreen';
import { IDesktopWindow } from 'types/desktop/IDesktopWindow';
import { useState } from 'react';

export const ConferenceScreenShareBtn: React.FC<IOwnProps> = ({
    handleChangeStatus,
    status,
    mobile,
    videoPermittedByAdmin,
}) => {
    const [showSelectSharingScreen, setShowSelectSharingScreen] = useState(false);

    if (navigator.mediaDevices?.getDisplayMedia === undefined) {
        return null;
    }
    const text = t({ id: 'screen', message: 'Экран' });

    const imgWrapperDynamicStyles = styles[`imgWrapper_${status}`] || '';
    const wrapperDynamicStyles = styles[`wrapper_${status}`] || '';

    const getTooltipText = () => {
        if (status === 'on') {
            return <Trans id='screenShare.tooltip.turnOff' message='Выключить трансляцию экрана' />;
        }
        if (status === 'off') {
            return <Trans id='screenShare.tooltip.turnOn' message='Включить трансляцию экрана' />;
        }
        return <Trans id='screenShare.tooltip.disabled' message='Трансляция экрана недоступна' />;
    };

    const handleDesktopClick = () => {
        if (status === 'on') {
            handleChangeStatus();
            return;
        }
        setShowSelectSharingScreen(true);
    };

    const handleClick = (window as typeof window & IDesktopWindow)?.meevox
        ? handleDesktopClick
        : handleChangeStatus;

    return (
        <div className={`${styles.wrapper} ${wrapperDynamicStyles}`}>
            <div className={styles.hoverWrapper}>
                <HoverLabel>{getTooltipText()}</HoverLabel>
            </div>
            <ConferenceButton
                text={text}
                clickHandler={videoPermittedByAdmin ? handleClick : undefined}
                mobile={mobile}>
                <div className={`${styles.imgWrapper} ${imgWrapperDynamicStyles}`}>
                    <ScreenSvg className={`${styles.screenImg}`} />
                </div>
            </ConferenceButton>
            {showSelectSharingScreen && (
                <SelectSharingScreenConnected
                    isMobile={!!mobile}
                    changeScreenShareStatus={handleChangeStatus}
                    closeDialog={() => setShowSelectSharingScreen(false)}
                />
            )}
        </div>
    );
};
