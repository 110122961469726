import { t } from '@lingui/macro';
import { createAsyncThunk } from '@reduxjs/toolkit';
import { allowedJoinToConferenceAction } from 'actions/lobby/allowedJoinToConferenceAction';
import { showNotifyThunkAction } from 'thunk/notify/showNotifyThunkAction';
import { subscribeToConferenceJoinRequestActionThunk } from 'thunk/socket/subscribeToConferenceJoinRequestThunkAction';
import { IRequestError } from 'types/api/IRequestError';
import { TAppState } from 'types/app/TAppStore';
import { getDefaultErrorNotification } from 'utils/getDefaultErrorNotification';
import { getErrorNotification } from 'utils/getErrorNotification';
import { loadJanus } from 'utils/janus/loadJanusScript';
import { redirect, replace } from 'utils/redirect';
import { checkPermissionToConnectToCurrentConferenceThunkAction } from './checkPermissionToConnectToCurrentConferenceThunkAction';
import { joinToCurrentConferenceThunkAction } from './joinToCurrentConferenceThunkAction';
import { startJanusConferenceThunkAction } from './startJanusConferenceThunkAction';

export const joinToCurrentConferenceAndStartJanusThunkAction = createAsyncThunk(
    'joinToCurrentConferenceAndStartJanus',
    async (redirectToConferencePage: boolean, { getState, dispatch }) => {
        await loadJanus();
        dispatch(allowedJoinToConferenceAction(null));

        const { payload: error } = await dispatch(joinToCurrentConferenceThunkAction());
        if (error) {
            dispatch(showNotifyThunkAction(getErrorMessage(error as IRequestError)));
            return error;
        }
        dispatch(checkPermissionToConnectToCurrentConferenceThunkAction());
        const {
            conferenceSession: { currentConferenceId },
            lobby: { allowedJoinConference },
            janus: { ownParticipantId },
        } = getState() as TAppState;
        if (allowedJoinConference) {
            await dispatch(startJanusConferenceThunkAction());
            if (redirectToConferencePage) {
                const params = new URLSearchParams(window.location.search);
                params.get('simpleConference')
                    ? replace(`/simpleConference/${currentConferenceId}`)
                    : replace(`/conference/${currentConferenceId}`);
            }
        } else {
            dispatch(subscribeToConferenceJoinRequestActionThunk(ownParticipantId));
            redirect(`/lobby/${currentConferenceId}`);
        }
    },
);
function getErrorMessage(error: IRequestError) {
    switch (error.errorCode) {
        case 'NEED_WAIT_CREATOR':
            return getErrorNotification(
                t({
                    id: 'notification.conferenceNeedWaitCreator',
                    message: 'Эта конференция не может быть начата без ее организатора',
                }),
            );
        case 'NOT_PERMITTED':
            return getErrorNotification(
                t({
                    id: 'notification.conferenceNootPermitted',
                    message: 'Доступ к конференции в данный момент невозможен',
                }),
            );
    }
    return getDefaultErrorNotification();
}
