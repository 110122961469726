import { Select } from 'admin-app/components/common/Select';
import { IOwnProps } from './types';
import { tableFilterOptions } from './utils';
import CheckImg from '../../../../assets/Check.svg';
import styles from './TableFilter.module.scss';

export const TableFilter: React.FC<IOwnProps> = ({ tableFilterValue, handleTableFilterChange }) => {
    const activeOptionImg = <img src={CheckImg} width={17} />;
    return (
        <Select
            containerClassName={styles.container}
            currentValue={tableFilterValue}
            options={tableFilterOptions()}
            onChange={handleTableFilterChange}
            activeOptionImg={activeOptionImg}
        />
    );
};
