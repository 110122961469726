export const parseDate = (value = '', currentDate: string): string => {
    if (value === '') return value;
    if (value.length > 10) {
        return currentDate;
    }
    if (value.match(/^[0-9]{3}$/)) {
        return value.slice(0, 2) + '.' + value[2];
    }
    if (value.match(/^[0-9]{2}\.[0-9]{3}$/)) {
        return value.slice(0, 5) + '.' + value[5];
    }
    if (value.match(/[^0-9]/)) {
        const withoutDots = value.replace(/\./g, '');
        if (withoutDots.match(/[^0-9]/)) {
            return currentDate;
        }
        return value;
    }

    return value;
};
