import { EDisplayVideoWithDifferentRatio } from 'constants/EDisplayVideoWithDifferentRatio';
import { IApplicationViewReducer } from 'reducers/applicationViewReducer.types';
import { getDefaultLanguage } from 'utils/languagesOperations';

export const APPLICATION_VIEW_REDUCER_INITIAL_STATE: IApplicationViewReducer = {
    alwaysShowOwnStream: true,
    currentLanguage: getDefaultLanguage(),
    deviceProps: {
        isBigScreen: true,
        isTabletOrMobile: false,
        isPortrait: false,
        isSmallScreen: false,
        isUltraSmallScreen: false,
    },
    disabledStartConfButton: false,
    displayLayout: 'default',
    displayedAudioFrames: [],
    displayedFrameSize: { width: 0, height: 0 },
    displayedVideoFrames: [],
    fullScreenFrames: [],
    participantsFullScreen: [],
    participantsFullScreenGlobal: [],
    lastActivity: {},
    maxVideoFrames: 20,
    draftChatMessage: '',
    notificationToShow: null,
    ownParticipantId: '',
    participantFrames: [],
    participantListOpen: false,
    participantsAudio: {},
    participantsEmotion: {},
    participantsFrameSize: {},
    participantsLayoutProperties: {
        width: 0,
        height: 0,
        ratio: EDisplayVideoWithDifferentRatio.DEFAULT_16_9,
    },
    participantsLocalMuteStatus: {},
    participantsScreenShared: {},
    participantsTalking: {},
    participantsVideo: {},
    pendingConfirmation: { isFormEditing: false, awaitUserConfirmation: false },
    pendingOperations: { defaultOperation: false },
    showAudioPermissionDialog: false,
    showChat: false,
    showConferenceExitConfirmationDialog: false,
    showConferenceInfoFrame: true,
    showLoginDialog: false,
    supposedFullScreen: '',
    videoStreamsSubscriptionsUpdate: { subscribe: [], unsubscribe: [] },
};
