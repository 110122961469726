import { setConferenceChatAutoScroll } from 'actions/chat/setConferenceChatAutoScroll';
import { toggleConferenceChatAction } from 'actions/applicationView/toggleConferenceChatAction';
import { connect } from 'react-redux';
import { addMessageToConferenceChatThunkAction } from 'thunk/chat/addMessageToConferenceChatThunkAction';
import { TAppDispatch } from 'types/app/TAppDispatch';
import { TAppState } from 'types/app/TAppStore';
import { ConferenceChatQuickMessage } from './ConferenceChatQuickMessage';
import { IDispatchProps, IStateProps } from './types';
import {saveChatDraftMessageAction} from '../../../actions/chat/saveChatDraftMessageAction';
import { setParticipantsListOpenAction } from 'actions/applicationView/setParticipantsListOpenAction';

const mapStateToProps = (state: TAppState): IStateProps => ({
    chatState: state.applicationView.showChat,
    ownUserId: state.auth.currentUser?.id || null,
    confUserId: state.janus.ownParticipantId,
    priorityFrameEnable: !!state.applicationView.fullScreenFrames.length,
    maxMessageLength: state.applicationConfiguration.config.maxMessageLength,
    draftChatMessage: state.applicationView.draftChatMessage,
    participantsListOpen: state.applicationView.participantListOpen,
});

const mapDispatchToProps = (dispatch: TAppDispatch): IDispatchProps => ({
    toggleChat: (chatState) => dispatch(toggleConferenceChatAction(chatState)),
    addNewMessage: (messageData) => dispatch(addMessageToConferenceChatThunkAction(messageData)),
    setChatAutoScroll: (chatAutoScrollState) =>
        dispatch(setConferenceChatAutoScroll(chatAutoScrollState)),
    setDraftChatMessage: (messageData) => dispatch(saveChatDraftMessageAction(messageData)),
    setParticipantsListOpen: (participantsListState) =>
        dispatch(setParticipantsListOpenAction(participantsListState)),
});

export const ConferenceChatQuickMessageConnected = connect(
    mapStateToProps,
    mapDispatchToProps,
)(ConferenceChatQuickMessage);
