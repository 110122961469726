import { plural, t } from '@lingui/macro';
import CalendarImg from 'assets/CalendarNew.svg';
import ClocksImg from 'assets/Clocks.svg';
import RecordReadyImg from 'assets/RecordReady.svg';
import TimerGrayImg from 'assets/TimerGray.svg';
import { format } from 'date-fns';
import { convertStringToDateByTemplate } from 'utils/dates/convertStringToDateByTemplate';
import { getDateBasedOnTimezone } from 'utils/dates/getDateBasedOnTimezone';
import { getUserTimeZone } from 'utils/timeZones/getUserTimeZone';
import styles from './PastConferenceInfo.module.scss';
import { IOwnProps } from './types';
import { getConferenceDurationString } from './utils';

export const PastConferenceInfo: React.FC<IOwnProps> = ({
    conferenceName,
    timeStart,
    timeEnd,
    recordsCount,
    conferenceDetailsHandler,
}) => {
    const dateStart = getDateBasedOnTimezone(
        convertStringToDateByTemplate(timeStart, 'yyyy-MM-dd HH:mm:ss.SSSSSS'),
        getUserTimeZone(),
    );
    const durationStr = getConferenceDurationString(timeEnd, timeStart);
    const recordsPlural = recordsCount
        ? plural(recordsCount, {
            one: 'запись',
            few: 'записи',
            many: 'записей',
            other: '',
        })
        : '';
    const dataParts = [
        { value: format(dateStart, 'dd.MM.yyyy'), img: CalendarImg },
        { value: format(dateStart, 'HH:mm'), img: ClocksImg },
        { value: durationStr, img: TimerGrayImg },
        { value: recordsCount ? `${recordsCount} ${recordsPlural}` : null, img: RecordReadyImg },
    ];
    return (
        <div className={styles.conference__info}>
            <span className={styles.conference__infoName} onClick={conferenceDetailsHandler}>
                {conferenceName}
            </span>
            <ul className={styles.conference__infoList}>
                {dataParts.map(({ value, img }, idx) => {
                    if (!value) {
                        return null;
                    }
                    return (
                        <li className={styles.conference__infoItem} key={idx}>
                            <img src={img} />
                            <span>{value}</span>
                        </li>
                    );
                })}
            </ul>
        </div>
    );
};
