import { INetworkService } from 'types/api/INetworkService';

interface IPayload {
    conferenceId: string;
    sessionId: string;
    mediaFileId: string;
}
export const getChatMediaApiCall = (
    networkService: INetworkService,
    { mediaFileId, conferenceId, sessionId }: IPayload,
) =>
    networkService.get({
        url: `/api/conferences/${conferenceId}/sessions/${sessionId}/media_files/${mediaFileId}`,
        responseType:'arraybuffer',
    });
