import { Trans } from '@lingui/react';
import { IOwnProps } from './types';
import styles from './ConferenceRecordStatusRender.module.scss';

export const ConferenceRecordStatusRender: React.FC<IOwnProps> = ({ isError, isReady }) => {
    if (!isReady) {
        return (
            <div className={styles.processing}>
                <div className={styles.spinner}>
                    <div />
                </div>
                <span>
                    <Trans id='Processing' message='Обрабатывается' />
                    ...
                </span>
            </div>
        );
    }
    if (isError) {
        return <Trans id='notification.error' />;
    }
    return null;
};
