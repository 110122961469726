import { t } from '@lingui/macro';
import { Trans } from '@lingui/react';
import { Button } from 'components/common/Button';
import { Input } from 'components/common/Input';
import { StatusLabel } from 'components/common/StatusLabel';
import React from 'react';
import { Form, FormRenderProps } from 'react-final-form';
import styles from './SetNewPasswordForm.module.scss';
import { IDispatchProps, IFormProps } from './types';

const getToken = (): string => {
    const searchParams = new URLSearchParams(window.location.search);
    return searchParams.get('token') || '';
};

export const SetNewPasswordForm: React.FC<IDispatchProps> = ({ setNewPassword }) => {
    const handleSubmit = async (values: IFormProps) => {
        const response = await setNewPassword(values.password, getToken());
        if (response?.payload) {
            return {
                password: t({
                    id: 'SetPasswordError',
                    message: 'Ошибка установки пароля',
                }),
            };
        }
    };
    const validate = (values: IFormProps) => {
        const errors: { [key: string]: string } = {};
        if (!values.password) {
            errors.password = t({ id: 'RequiredField' });
        }
        return errors;
    };
    const render = ({
        handleSubmit,
        submitting,
        errors,
        dirtySinceLastSubmit,
        submitErrors,
    }: FormRenderProps<IFormProps>) => (
        <form onSubmit={handleSubmit} className={styles.form}>
            <StatusLabel
                text={submitErrors?.password && !dirtySinceLastSubmit
                    ? submitErrors?.password
                    : ''                    
                }
                className={styles.fail}
            />
            <div className={styles.block}>
                <div className={styles.passwordBlockHeader}>
                    <label className={styles.label} htmlFor='password'>
                        <Trans id='Password' message='Пароль' />
                    </label>
                </div>
                <Input
                    placeholder={t({ id: 'Password', message: 'Пароль' })}
                    name='password'
                    type='password'
                    className={styles.inputStyle}
                    touched={false}
                    errorText={errors?.password}
                    errorWithoutConditions={submitErrors?.password}
                    tabIndex={2}
                />
            </div>
            <Button
                styleType='primaryAction'
                caption={t({ id: 'Set new password', message: 'Установить новый пароль' })}
                disabled={submitting}
                type='submit'
            />
        </form>
    );
    return <Form onSubmit={handleSubmit} validate={validate} render={render} />;
};
