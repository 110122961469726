export enum EConferenceSessionActions {
    SET_USERS = 'ConferenceSession/setUsersAction',
    SET_CURRENT_CONFERENCE_ID = 'ConferenceSession/setCurrentConferenceId',
    SET_CURRENT_CONFERENCE_PIN = 'ConferenceSession/setCurrentConferencePin',
    SET_CURRENT_CONFERENCE_CREATOR = 'ConferenceSession/setCurrentConferenceCreator',
    SET_CURRENT_CONFERENCE_RECORDING = 'ConferenceSession/setCurrentConferenceRecording',
    SET_CURRENT_USER_IS_OWNER = 'ConferenceSession/setCurrentUserIsOwner',
    SET_CURRENT_SESSION_ID = 'ConferenceSession/setCurrentSessionId',
    SET_CURRENT_CONFERENCE_PERMISSIONS = 'ConferenceSession/setCurrentConferencePermissions',
    STORE_CURRENT_CONFERENCE_PERMISSIONS = 'ConferenceSession/storeCurrentConferencePermissions',
    RESET_CONFERENCE_SESSION_STORE = 'ConferenceSession/resetStore',
}
