export const getInvitedUsersToSend = (
    invitedUsersFromForm: string[],
    invitedUsersFromConferenceInfo?: string[],
): { added: string[]; removed: string[] } => {
    const resultInvitedUsers: { added: string[]; removed: string[] } = {
        added: [],
        removed: [],
    };
    if (invitedUsersFromConferenceInfo) {
        invitedUsersFromForm.forEach((user) => {
            invitedUsersFromConferenceInfo.indexOf(user) < 0
                ? resultInvitedUsers.added.push(user)
                : false;
        });
        invitedUsersFromConferenceInfo.forEach((initialUser: any) => {
            invitedUsersFromForm.indexOf(initialUser) < 0
                ? resultInvitedUsers.removed.push(initialUser)
                : false;
        });
    } else {
        resultInvitedUsers.added = invitedUsersFromForm;
    }
    return resultInvitedUsers;
};