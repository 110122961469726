import { Trans } from '@lingui/react';
import { LoginFormConnected } from 'components/forms/LoginForm';
import { PageTemplate } from '../templates/PageTemplate';
import styles from './LoginPage.module.scss';
import { IStateProps } from './types';

export const LoginPage: React.FC<IStateProps> = ({ rememberMeRequestStatus }) =>
    rememberMeRequestStatus === 'error' ? (
        <PageTemplate showLoginBtn={false}>
            <div className={styles.title}>
                <Trans id='Enter to the system' message='Войти в систему' />
            </div>
            <div className={styles.form}>
                <LoginFormConnected />
            </div>
        </PageTemplate>
    ) : null;
