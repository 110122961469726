import { Trans } from '@lingui/react';
import AnonymousImg from 'assets/User.svg';
import { Avatar } from 'components/common/Avatar/Avatar';
import { Spinner } from 'components/common/Spinner';
import { ParticipantContextMenu } from 'components/ParticipantContextMenu';
import React from 'react';
import { getParticipantAvatar } from 'utils/participants/getParticipantAvatar';
import { getPublisherInitials } from 'utils/participants/getPublisherInitials';
import { getAvatarUrl } from 'utils/users/getAvatarUrl';
import { getDisplayName } from 'utils/users/getDisplayName';
import { getInitials } from 'utils/users/getInitials';
import { getUserColor } from 'utils/users/getUserColor';
import styles from './ParticipantListItem.module.scss';
import { IOwnProps, IStateProps } from './types';
import { getAvatarStyle } from './utils';
import {ParticipantIconsConnected} from './ParticipantIcons/ParticipantIconsConnected';

const renderParticipantDisplayName = (displayName: string, isAnonym: boolean) => (
    <span className={styles.conferenceParticipantsList__nameBlock}>
        <span className={styles.conferenceParticipantsList__participantName}>{displayName}</span>
        {isAnonym && (
            <>
                <span>&nbsp;</span>
                <span className={styles.conferenceParticipantsList__guestLabel}>
                    <Trans id='Guest' message='Гость' />
                </span>
            </>
        )}
    </span>
);

const getLoadingParticipant = (mobileParticipantStyle: string, isMobile: boolean) => (
    <li
        className={`${styles.conferenceParticipantsList__participant} ${mobileParticipantStyle}`}
        data-participant>
        <Avatar
            image={AnonymousImg}
            style={{
                ...getAvatarStyle(isMobile),
            }}
            userBgColor='#5C5C5C'
        />
        {
            <div className={styles.spinner}>
                <Spinner />
            </div>
        }
    </li>
);

export const ParticipantListItem: React.FC<IOwnProps & IStateProps> = ({
    currentConferenceParticipants,
    currentUser,
    currentUserAnonym,
    currentUserParticipantId,
    publisher,
    isMobile,
    itIsCurrentUserItem,
}) => {
    const mobileParticipantStyle = isMobile
        ? styles.conferenceParticipantsList__participant_mobile
        : '';
    const mobileKebabStyle = isMobile
        ? styles.conferenceParticipantsList__participantKebab_mobile
        : '';
    const mobileStatusStyle = isMobile ? styles.conferenceParticipantsList__participantStatus_mobile : '';
    if (itIsCurrentUserItem || publisher) {
        let participantIsAnonym, avatar, initials, participantId: string, displayName;

        if (publisher) {
            const participant = currentConferenceParticipants[publisher.participantId];
            if (!participant) {
                return null;
            }
            ({ participantId } = publisher);
            participantIsAnonym = !participant.userId;
            avatar = getParticipantAvatar(participant);
            initials = getPublisherInitials(publisher);
            displayName = publisher?.displayName;
        } else {
            participantId = currentUserParticipantId;
            participantIsAnonym = !currentUser;
            avatar = currentUser
                ? getAvatarUrl({ id: currentUser.id, etag: currentUser.etag, size: '50' })
                : AnonymousImg;
            initials = getInitials({ user: currentUser, anonymousUser: currentUserAnonym });
            displayName = getDisplayName(currentUser || currentUserAnonym);
        }
        return (
            <li
                className={`${styles.conferenceParticipantsList__participant} ${mobileParticipantStyle}`}
                data-participant>
                <Avatar
                    image={avatar}
                    initials={initials}
                    style={{
                        ...getAvatarStyle(isMobile),
                    }}
                    userBgColor={participantIsAnonym ? '#5C5C5C' : getUserColor(displayName)}
                />
                {renderParticipantDisplayName(displayName, participantIsAnonym)}
                <div className={`${styles.conferenceParticipantsList__participantStatus} ${mobileStatusStyle}`}>
                    <ParticipantIconsConnected participantId={participantId}/>
                </div>
                <div
                    className={`${styles.conferenceParticipantsList__participantKebab} ${mobileKebabStyle}`}>
                    <ParticipantContextMenu orientation='down-left' participantId={participantId} />
                </div>
            </li>
        );
    }

    // Publisher === undefined. But this publisher is already in the list of participants
    // Waiting for him
    return getLoadingParticipant(mobileParticipantStyle, isMobile);
};
