import { INetworkService } from 'types/api/INetworkService';

interface IPayload {
    conferenceId: string;
    participantId: string;
    status: 'allow' | 'forbid';
}

export const sendPermissionToJoinConferenceApiCall = (
    networkService: INetworkService,
    { conferenceId, participantId, status }: IPayload,
) =>
    networkService.put({
        url: `/api/conferences/${conferenceId}/lobby/users/${participantId}/${status}`,
    });
