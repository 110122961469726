import { t } from '@lingui/macro';
import { TLanguages } from 'types/TLanguages';

export type LanguageItem = { text: string; language: TLanguages };

export const getLanguages = (): LanguageItem[] => [
    {
        language: 'ru',
        text: t({ id: 'language.russian', message: 'Русский' }),
    },
    {
        language: 'en',
        text: t({ id: 'language.english', message: 'Английский' }),
    },
];

export const getDefaultLanguage = (): TLanguages => navigator.language.substring(0, 2) as TLanguages;

export const getLanguageList = () => {
    const list: string[] = [];
    const languages = getLanguages();
    for (const elem of languages) {
        list.push(elem.text);
    }
    return list;
};

export const getLanguageByText = (text: string): TLanguages => {
    const defaultLanguage: TLanguages = 'en';
    let foundLanguage;
    const languages = getLanguages();
    for (const elem of languages) {
        if (elem.text === text) {
            foundLanguage = elem.language as TLanguages;
            break;
        }
    }
    return foundLanguage || defaultLanguage;
};

export const getTextByLanguage = (language: TLanguages): string => {
    const languages = getLanguages();
    const elem = languages.find((e) => e.language === language);
    if (elem) {
        return elem.text;
    }
    // If did not found language - set english
    return t({ id: 'language.english', message: 'Английский' });
};
