import { MobileWrapperConnected } from 'components/common/MobileWrapper';
import { HomePageDesktopConnected } from './HomePageDesktopConnected';
import { HomePageMobileConnected } from './HomePageMobileConnected';
import { HomePageSmallScreenConnected } from './HomePageSmallScreenConnected';

export const HomePageResponsive = () => (
    <MobileWrapperConnected
        renderDesktop={() => <HomePageDesktopConnected />}
        renderMobile={() => <HomePageMobileConnected />}
        renderSmallScreen={() => <HomePageSmallScreenConnected />}
    />
);
