import { IDynamicSectionStructure } from 'types/dynamicForm/IDynamicSectionStructure';
import { EAppSettingsFieldsName } from './EAppSettingsFieldsName';
import { EDisplayVideoWithDifferentRatio } from './EDisplayVideoWithDifferentRatio';
import { EFormattedVideoResolution, EVideoResolution } from './EVideoResolution';

export enum EAudioVideoComboValues {
    ON = 'on',
    OFF = 'off',
    DISABLED = 'disabled',
}

export const conferenceSettings: IDynamicSectionStructure = {
    fields: [
        {
            name: EAppSettingsFieldsName.CONFERENCE_RECORD,
            title: /*i18n*/ {
                id: 'accountConferenceSetting.record',
                message: 'Запись конференции',
            },
            description: /*i18n*/ {
                id: 'accountConferenceSetting.record.description',
                message: 'Автоматически включать запись конференции с первой секунды.',
            },
            type: 'checkbox',
            defaultValue: false,
        },
        {
            name: EAppSettingsFieldsName.CONFERENCE_AUDIO_NOTIFICATION,
            title: /*i18n*/ {
                id: 'accountConferenceSetting.audioNotification',
                message: 'Аудио оповещение',
            },
            description: /*i18n*/ {
                id: 'accountConferenceSetting.audioNotification.description',
                message: 'Оповещает владельца конференции о новом запросе, если включено лобби.',
            },
            type: 'checkbox',
            defaultValue: true,
        },
        {
            name: EAppSettingsFieldsName.CONFERENCE_PARTICIPANTS_VIDEO,
            title: /*i18n*/ {
                id: 'accountConferenceSetting.participantsVideo',
                message: 'Видео участников в конференции',
            },
            description: /*i18n*/ {
                id: 'accountConferenceSetting.participantsVideo.description',
                message: 'Доступность видео в конференции для всех участников, кроме владельца.',
            },
            values: [
                EAudioVideoComboValues.ON,
                EAudioVideoComboValues.OFF,
                EAudioVideoComboValues.DISABLED,
            ],
            displayValues: [
                /*i18n*/ {
                    id: 'On',
                },
                /*i18n*/ {
                    id: 'Off',
                },
                /*i18n*/ {
                    id: 'Disabled',
                },
            ],
            type: 'combo',
            defaultValue: EAudioVideoComboValues.ON,
        },
        {
            name: EAppSettingsFieldsName.CONFERENCE_PARTICIPANTS_AUDIO,
            title: /*i18n*/ {
                id: 'accountConferenceSetting.participantsAudio',
                message: 'Микрофон участников в конференции',
            },
            description: /*i18n*/ {
                id: 'accountConferenceSetting.participantsAudio.description',
                message:
                    'Доступность микрофона в конференции для всех участников, кроме владельца.',
            },
            values: [
                EAudioVideoComboValues.ON,
                EAudioVideoComboValues.OFF,
                EAudioVideoComboValues.DISABLED,
            ],
            displayValues: [
                /*i18n*/ {
                    id: 'On',
                },
                /*i18n*/ {
                    id: 'Off',
                },
                /*i18n*/ {
                    id: 'Disabled',
                },
            ],
            type: 'combo',
            defaultValue: EAudioVideoComboValues.ON,
        },
    ],
};
export const videoSettings: IDynamicSectionStructure = {
    fields: [
        {
            name: EAppSettingsFieldsName.VIDEO_RESOLUTION,
            title: /*i18n*/ {
                id: 'accountDevSettings.resolution',
                message: 'Разрешение',
            },
            values: [
                EVideoResolution.LOW_RES,
                EVideoResolution.STD_RES,
                EVideoResolution.HI_RES,
                EVideoResolution.FHD_RES,
                EVideoResolution.ULTRA_RES,
            ],
            displayValues: [
                EFormattedVideoResolution.LOW_RES,
                EFormattedVideoResolution.STD_RES,
                EFormattedVideoResolution.HI_RES,
                EFormattedVideoResolution.FHD_RES,
                EFormattedVideoResolution.ULTRA_RES,
            ],
            defaultValue: EVideoResolution.STD_RES,
            type: 'combo',
        },
        {
            name: EAppSettingsFieldsName.VIDEO_OWN_VIDEO,
            title: /*i18n*/ {
                id: 'accountConferenceSetting.ownVideoOnConnect',
                message: 'Включить камеру по умолчанию при подключении к конференции',
            },
            description: /*i18n*/ {
                id: 'accountConferenceSetting.ownVideoOnConnect.description',
                message: 'Автоматически включать свое видео при подключении к конференции.',
            },
            type: 'checkbox',
            defaultValue: true,
        },
        {
            name: EAppSettingsFieldsName.VIDEO_ALWAYS_SHOW_OWN_STREAM,
            title: /*i18n*/ {
                id: 'accountVideoSettings.ownStream',
                message: 'Своё видео будет показано всегда первым',
            },
            description: /*i18n*/ {
                id: 'accountVideoSettings.ownStream.description',
                message: 'Отображать мое видео на первом месте на основном экране конференции.',
            },
            type: 'checkbox',
            defaultValue: true,
        },
        {
            name: EAppSettingsFieldsName.VIDEO_DISPLAY_USER_FRAME_INFO,
            title: /*i18n*/ {
                id: 'accountConferenceSettings.frameInfo',
                message: 'Показывать данные о видеопотоке участника',
            },
            description: /*i18n*/ {
                id: 'accountConferenceSettings.frameInfo.description',
                message: 'Отображать дополнительную информацию: разрешение и пропорции видео.',
            },
            type: 'checkbox',
            defaultValue: false,
        },
    ],
};
export const audioSettings: IDynamicSectionStructure = {
    fields: [
        {
            name: EAppSettingsFieldsName.AUDIO_OWN_AUDIO,
            title: /*i18n*/ {
                id: 'accountConferenceSetting.ownAudioOnConnect',
                message: 'Включать микрофон при подключения к конференции',
            },
            description: /*i18n*/ {
                id: 'accountConferenceSetting.ownAudioOnConnect.description',
                message: 'Автоматически включать свой микрофон при подключения к конференции.',
            },
            type: 'checkbox',
            defaultValue: true,
        },
        {
            name: EAppSettingsFieldsName.AUDIO_3D_AUDIO,
            title: /*i18n*/ {
                id: 'appSett.use3dAudio.title',
                message: 'Включить объемный звук',
            },
            description: /*i18n*/ {
                id: 'appSett.use3dAudio.description',
                message: 'Звук от участников слева или справа будет отличаться',
            },
            type: 'checkbox',
            defaultValue: false,
        },
    ],
};
export const devSettingsVideo: IDynamicSectionStructure = {
    sectionTitle: /*i18n*/ { id: 'appSettings.sectionTitles.video', message: 'Видео' },
    fields: [
        {
            name: EAppSettingsFieldsName.DEV_VIDEO_CODEC,
            title: /*i18n*/ {
                id: 'accountDevSettings.codec',
                message: 'Кодек',
            },
            values: ['vp8'],
            type: 'combo',
            defaultValue: 'vp8',
        },
        {
            name: EAppSettingsFieldsName.DEV_VIDEO_LAYOUT,
            title: /*i18n*/ {
                id: 'accountDevSettings.displayedVideoLayout',
                message: 'Соотношение сторон',
            },
            values: [
                EDisplayVideoWithDifferentRatio.DEFAULT_4_3,
                EDisplayVideoWithDifferentRatio.DEFAULT_16_9,
                EDisplayVideoWithDifferentRatio.MAX_RATIO,
                EDisplayVideoWithDifferentRatio.MIN_RATIO,
            ],
            defaultValue: EDisplayVideoWithDifferentRatio.MAX_RATIO,
            type: 'combo',
        },
    ],
};
export const devSettingsAudio: IDynamicSectionStructure = {
    sectionTitle: /*i18n*/ { id: 'appSettings.sectionTitles.audio', message: 'Аудио' },
    fields: [
        {
            name: EAppSettingsFieldsName.DEV_AUDIO_CODEC,
            title: /*i18n*/ {
                id: 'accountDevSettings.codec',
                message: 'Кодек',
            },
            values: ['opus'],
            defaultValue: 'opus',
            type: 'combo',
        },
        {
            name: EAppSettingsFieldsName.DEV_3D_AUDIO_SETTINGS,
            defaultValue:
                '{ "debug": false, "addCompressor": false, "v1_listenerPositionZ": 2, "v1_sourcePositionZ": 0, "v1_maxDistance": 3, "v2_maxDelay": 0.01 }',
            description: /*i18n*/ {
                id: 'appSett.use3dAudioSettings.description',
                message: 'Настройки',
            },
            title: /*i18n*/ {
                id: 'appSett.use3dAudioSettings.title',
                message: 'Настройки объёмного звука',
            },
            type: 'text',
        },
    ],
};
export const devSettingsDev: IDynamicSectionStructure = {
    sectionTitle: /*i18n*/ {
        id: 'appSettings.sectionTitles.dev',
        message: 'Опции для разработчиков',
    },
    fields: [
        {
            name: EAppSettingsFieldsName.DEV_JANUS_ADDRESS,
            title: /*i18n*/ {
                id: 'accountDevSettings.janusAddress',
                message: 'Janus сервер',
            },
            defaultValue: '',
            placeholder: /*i18n*/ {
                id: 'accountDevSettings.janusAddress.placeholder',
                message: 'Введите адрес сервера для тестирования',
            },
            type: 'text',
        },
        {
            name: EAppSettingsFieldsName.DEV_DEBUG_CONFIG,
            title: /*i18n*/ {
                id: 'accountDevSettings.debugConfig',
                message: 'Настройки логов',
            },
            description: /*i18n*/ {
                id: 'accountDevSettings.debugConfig.description',
                message: 'JSON файл с настройками отображения логов',
            },
            defaultValue: '{"level":"error"}',
            type: 'text',
        },
        {
            name: EAppSettingsFieldsName.DEV_FEATURES,
            title: /*i18n*/ {
                id: 'accountDevSettings.features',
                message: 'Дополнительные возможности',
            },
            placeholder: /*i18n*/ {
                id: 'accountDevSettings.features.placeholder',
                message: 'Управление дополнительными возможностями',
            },
            defaultValue: '{}',
            type: 'text',
        },
    ],
};

export const appSettingsStructure: IDynamicSectionStructure[] = [
    conferenceSettings,
    videoSettings,
    audioSettings,
    devSettingsVideo,
    devSettingsAudio,
    devSettingsDev,
];

export const appSettingsMobileStructure: IDynamicSectionStructure[] = [
    conferenceSettings,
    videoSettings,
    audioSettings,
    // devSettingsVideo,
    // devSettingsAudio,
    // devSettingsDev,
];
