export const getItemData = (item:any) => {
    let actualValue: any;
    let displayValue: any;
    if (typeof item === 'object') {
        actualValue = item?.value;
        displayValue = item?.displayValue || item?.value;
    } else {
        actualValue = item;
        displayValue = item;
    }
    return {actualValue, displayValue};
}