import { setJanusStreamPropsAction } from 'actions/janus/setJanusStreamPropsAction';
import { connect } from 'react-redux';
import { getCurrentConferenceConnectInfoSelector } from 'selectors/getCurrentConferenceConnectInfoSelector';
import { TAppDispatch } from 'types/app/TAppDispatch';
import { TAppState } from 'types/app/TAppStore';
import { ConnectToConferencePage } from './ConnectToConferencePage';
import { IDispatchProps, IStateProps } from './types';
import { prepareConferenceForStartThunkAction } from 'thunk/conference/prepareConferenceForStartThunkAction';

const mapStateToProps = (state: TAppState): IStateProps => ({
    currentConferenceParticipants: Object.values(state.conferenceSession.currentConferenceParticipants),
    conferenceConnectInfo: getCurrentConferenceConnectInfoSelector(state),
    //conferenceId: state.conferenceSession.currentConferenceId,
    currentPin: state.conferenceSession.currentConferencePin,
    userIsConferenceOwner: state.conferenceSession.currentUserIsOwner,
});

const mapDispatchToProps = (dispatch: TAppDispatch): IDispatchProps => ({
    changeStreamProps: (props) => dispatch(setJanusStreamPropsAction(props)),
    prepareConferenceForStart: (id) => dispatch(prepareConferenceForStartThunkAction({ id })),
});

export const ConnectToConferencePageConnected = connect(
    mapStateToProps,
    mapDispatchToProps,
)(ConnectToConferencePage);
