import { createAsyncThunk } from '@reduxjs/toolkit';
import { setShowRedirectConfirmationAction } from 'actions/applicationView/setShowRedirectConfirmationAction';
import { appHistory } from 'appHistory';
import { TAppState } from 'types/app/TAppStore';

interface IPayload {
    url?: string;
    method?: string;
    onClick?: () => void
}
export const tryToRedirectThunkAction = createAsyncThunk(
    'tryToRedirect',
    async ({ url, method, onClick }: IPayload, { dispatch, getState }) => {
        const {
            applicationView: { pendingConfirmation },
        } = getState() as TAppState;
        if (pendingConfirmation.isFormEditing) {
            if (!pendingConfirmation.awaitUserConfirmation) {
                dispatch(
                    setShowRedirectConfirmationAction({
                        awaitUserConfirmation: true,
                        redirectData: { url, method, onClick },
                    }),
                );
            }
            return;
        }
        if (onClick) {
            onClick();
        }
        if (url) {
            if (method === 'push') {
                appHistory.push(url);
            } else {
                appHistory.replace(url);
            }
        }
    },
);
