import { EDisplayVideoWithDifferentRatio } from 'constants/EDisplayVideoWithDifferentRatio';
import { TAppState } from 'types/app/TAppStore';

export const getActualRatioForDisplayVideo = (
    state: TAppState,
): EDisplayVideoWithDifferentRatio => {
    const settings = state.auth.appSettings;
    if (!settings) {
        return EDisplayVideoWithDifferentRatio.DEFAULT_16_9;
    }
    switch (settings['displayedVideoLayout']) {
        case EDisplayVideoWithDifferentRatio.MAX_RATIO:
            return EDisplayVideoWithDifferentRatio.MAX_RATIO;
        case EDisplayVideoWithDifferentRatio.MIN_RATIO:
            return EDisplayVideoWithDifferentRatio.MIN_RATIO;
        case EDisplayVideoWithDifferentRatio.DEFAULT_4_3:
            return EDisplayVideoWithDifferentRatio.DEFAULT_4_3;
        default:
            return EDisplayVideoWithDifferentRatio.DEFAULT_16_9;
    }
};
