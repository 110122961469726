import { connect } from 'react-redux';
import { CreateWebHookDialog } from './CreateWebHookDialog';
import { TAppDispatch } from 'types/app/TAppDispatch';
import { IDispatchProps } from './types';
import { createWebHookThunkAction } from 'admin-app/thunk/webHooks/createWebHookThunkAction';

const mapDispatchToProps = (dispatch: TAppDispatch): IDispatchProps => ({
    createWebHook: (data) => dispatch(createWebHookThunkAction(data)).unwrap(),
});

export const CreateWebHookDialogConnected = connect(null, mapDispatchToProps)(CreateWebHookDialog);
