import { Header } from 'admin-app/components/Header';
import { NavigationBar } from 'admin-app/components/NavigationBar';
import 'admin-app/pages/Admin/color.scss';
import { SettingsPage } from 'admin-app/pages/Settings';
import { UsersPageConnected } from 'admin-app/pages/Users';
import { EPaths } from 'constants/EPaths';
import { FC, useEffect, useState } from 'react';
import { Redirect, Route } from 'react-router-dom';
import { redirect } from 'utils/redirect';
import { UserPageConnected } from '../User';
import styles from './AdminPage.module.scss';
import { IStateProps } from './types';
import { EAdminPaths } from 'admin-app/constants/EAdminPaths';
import { HorizontalLoader } from 'components/common/HorizontalLoader';
import { checkUserAccess } from './utils';

export const AdminPage: FC<IStateProps> = ({ user, gettingUserPending }) => {
    const [isUserChecking, setIsUserChecking] = useState(true);

    useEffect(() => {
        if (user) {
            checkUserAccess(user.id, setIsUserChecking);
        }
    }, [user]);

    if (!user && !gettingUserPending) {
        redirect(EPaths.LOGIN);
    }

    if (!user || gettingUserPending || isUserChecking) {
        return (
            <div className={styles.loaderWrapper}>
                <HorizontalLoader color='blue' />
            </div>
        );
    }

    return (
        <div className={styles.layout}>
            <NavigationBar />
            <div className={styles.content}>
                <Header user={user} />
                <Route exact path='/admin' render={() => <Redirect to={EAdminPaths.USERS} />} />
                <Route exact path={EAdminPaths.USERS} component={UsersPageConnected} />
                <Route exact path='/admin/user/:id' component={UserPageConnected} />
                <Route path={EAdminPaths.SETTINGS} component={SettingsPage} />
            </div>
        </div>
    );
};
