import styles from './ConferenceViewButton.module.scss';
import SettingsImg from 'assets/Settings.svg';
import { ViewSelector } from '../ViewSelector';
import { t } from '@lingui/macro';
import { IDispatchProps, IOwnProps, IStateProps } from './types';
import { Button } from 'components/common/Button';

export const ConferenceViewButton: React.FC<IOwnProps & IDispatchProps & IStateProps> = ({
    isConferenceOwner,
    handleSettingsClick,
    changeDisplayView,
    maxVideoFrames,
}) => {
    return (
        <div className={styles.buttonsGroup}>
            {isConferenceOwner && (
                <Button styleType='common' className={styles.settings} clickHandler={handleSettingsClick}>
                    <img src={SettingsImg} alt='settings' />
                    <span>{t({ message: 'Настройки', id: 'Settings' })}</span>
                </Button>
            )}
            <ViewSelector changeDisplayView={changeDisplayView} maxVideoFrames={maxVideoFrames}/>
        </div>
    );
};
