import { createAsyncThunk } from '@reduxjs/toolkit';
import { storeUpcomingConferencesAction } from 'actions/conferencesList/storeUpcomingConferencesAction';
import { setConferenceIdsToShowAction } from 'actions/sessionSettings/setConferenceIdsToShowAction';
import { compareAsc, parseISO } from 'date-fns';
import { batch } from 'react-redux';
import { TAppState } from 'types/app/TAppStore';
import { IUpcomingConferenceInfo } from 'types/conferencesList/IUpcomingConferenceInfo';
import { convertStringToDateByTemplate } from 'utils/dates/convertStringToDateByTemplate';
import { getCurrentDate } from 'utils/dates/getCurrentDate';
import { removeUserTimeZoneFromDate } from 'utils/dates/removeUserTimeZoneFromDate';
import { updateExistingUpcomingConferenceStatusThunkAction } from './updateExistingUpcomingConferenceStatusThunkAction';

export const moveStartedConferenceThunkAction = createAsyncThunk<void, string>(
    'deleteFinishedConferenceFromUpcoming',
    async (conferenceId, { dispatch, getState }) => {
        const {
            conferencesList: { upcomingConferences },
        } = getState() as TAppState;
        const idsToShow: string[] = [];
        const upcomingConferencesNew: IUpcomingConferenceInfo[] = [];
        const targetConference = upcomingConferences[conferenceId];

        if (targetConferenceIsStillScheduled(targetConference.timeStart)) {
            dispatch(
                updateExistingUpcomingConferenceStatusThunkAction({
                    conferenceId,
                    newConferenceStatus: 'SCHEDULED',
                }),
            );
        } else {
            for (const confId in upcomingConferences) {
                const conference = upcomingConferences[confId];
                if (conference.id !== conferenceId) {
                    idsToShow.push(confId);
                    upcomingConferencesNew.push(conference);
                }
            }
            batch(() => {
                dispatch(storeUpcomingConferencesAction(upcomingConferencesNew));
                dispatch(setConferenceIdsToShowAction(idsToShow));
            });
        }
    },
);

const targetConferenceIsStillScheduled = (conferenceTimeStart: string) => {
    const parsedConfTimeStartUTC0 = convertStringToDateByTemplate(conferenceTimeStart, 'yyyy-MM-dd HH:mm:ss.SSSSSS');
    const currentTimeUTC0 = removeUserTimeZoneFromDate(getCurrentDate());
    return compareAsc(currentTimeUTC0, parsedConfTimeStartUTC0) < 0 ? true : false;
};
