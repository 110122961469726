import { connect } from 'react-redux';
import { TAppState } from 'types/app/TAppStore';
import { ConferenceDeviceNotification } from './ConferenceDeviceNotification';
import { IStateProps } from './types';

const mapStateToProps = (state: TAppState): IStateProps => ({
    deviceNotification: state.conferences.deviceNotification,
});

export const ConferenceDeviceNotificationConnected = connect(mapStateToProps)(
    ConferenceDeviceNotification,
);
