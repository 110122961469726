import { connect } from 'react-redux';
import { createNewConferenceThunkAction } from 'thunk/conference/createNewConferenceThunkAction';
import { TAppDispatch } from 'types/app/TAppDispatch';
import { CreateConfBlock } from './CreateConfBlock';
import { IDispatchProps, IStateProps } from './types';
import { TAppState } from 'types/app/TAppStore';

const mapDispatchToProps = (dispatch: TAppDispatch): IDispatchProps => ({
    createNewConference: () => dispatch(createNewConferenceThunkAction()),
});

const mapStateToProps = (state: TAppState): IStateProps => ({
    disabled: state.applicationView.disabledStartConfButton,
});

export const CreateConfBlockConnected = connect(mapStateToProps, mapDispatchToProps)(CreateConfBlock);
