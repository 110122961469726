import { Plural, Trans } from '@lingui/macro';
import triangleDown from 'assets/TriangleDown.svg';
import React from 'react';
import styles from './ConferenceChatScrollAlertButton.module.scss';
import { IOwnProps } from './types';

export const ConferenceChatScrollAlertButton: React.FC<IOwnProps> = ({ clickHandler, newMessagesCounter }) => {
    return (
        <button className={styles.conferenceChat__alertButton} onClick={clickHandler}>
            <span>
                <Plural
                    id='New messages plural'
                    value={newMessagesCounter}
                    one={<Trans ><strong>#</strong> новое сообщение </Trans>}
                    few={<Trans ><strong>#</strong> новых сообщения </Trans>}
                    other={<Trans ><strong>#</strong> новых сообщений</Trans>}
                />
            </span>
            <img src={triangleDown} alt="triangle icon" />
        </button>
    );
};