import { t } from '@lingui/macro';
import { createAsyncThunk } from '@reduxjs/toolkit';
import { registerApiCall } from 'api/authorization/registerApiCall';
import { getNetworkService } from 'api/getNetworkService';
import { loginThunkAction } from 'thunk/auth/loginThunkAction';
import { showNotifyThunkAction } from 'thunk/notify/showNotifyThunkAction';
import { IRequestError } from 'types/api/IRequestError';
import { TAppState } from 'types/app/TAppStore';
import { redirect } from 'utils/redirect';
import { getBrowserTimeZone } from 'utils/timeZones/getBrowserTimeZone';

interface IPayload {
    email: string;
    password: string;
}

export const registrationThunkAction = createAsyncThunk(
    'registration',
    async (
        { email, password }: IPayload,
        { dispatch, getState },
    ): Promise<IRequestError | undefined> => {
        const {
            applicationConfiguration: {
                config: { emailConfirmation },
            },
        } = getState() as TAppState;
        const timeZone = getBrowserTimeZone();
        const response = await registerApiCall(getNetworkService(), { email, password, timeZone });
        if (response.success) {
            if (emailConfirmation) {
                const notifyText = t({
                    id: 'registration.confirm.email.text.id',
                    message:
                        'На указанный адрес было отправлено письмо с ссылкой для подтверждения',
                });
                dispatch(
                    showNotifyThunkAction({
                        text: notifyText,
                        timeout: 8000,
                        type: 'notify_green',
                    }),
                );
                redirect('/');
            } else {
                dispatch(loginThunkAction({ username: email, password }));
            }
        } else {
            return response.data;
        }
    },
);
