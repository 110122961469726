import { t } from '@lingui/macro';
import ChatImg from 'assets/Chat.svg';
import { Button } from 'components/common/Button/Button';
import { ConferenceDeviceNotificationConnected } from 'components/ConferenceFooter/ConferenceDeviceNotification';
import { LAST_USED_AUDIO_DEVICE, LAST_USED_VIDEO_DEVICE } from 'constants/localStorageKeys';
import useIsMounted from 'hooks/useMounted';
import {
    addContainerPointerHandlers,
    ConferencePageTemplateContext,
} from 'pages/templates/ConferencePageTemplate/utils';
import React, { useContext, useEffect, useRef, useState } from 'react';
import { IUserDevice } from 'types/IUserDevice';
import { IChangeJanusStreamProps } from 'types/janus/IChangeJanusStreamProps';
import { IJanusLocalStreamProps } from 'types/janus/IJanusLocalStreamProps';
import { classNames } from 'utils/classNames';
import { setOwnStreamsPermissions } from 'utils/conferences/setOwnStreamsPermissions';
import { getPreferredDevicesByDevice } from 'utils/getPreferredDevicesByDevice';
import { isElementInContainer } from 'utils/isElementInContainer';
import { getFromLocalStorage } from 'utils/localStorage/getFromLocalStorage';
import { ConferenceChatBtnConnected } from '../ConferenceChatBtn';
import { ConferenceChatQuickMessageConnected } from './ConferenceChatQuickMessage';
import { ConferenceAudioDevice } from './ConferenceDevice/ConferenceAudioDevice';
import { ConferenceVideoDevice } from './ConferenceDevice/ConferenceVideoDevice';
import { ConferenceEmotionsButtonConnected } from './ConferenceEmotionsButton';
import styles from './ConferenceFooter.module.scss';
import { ConferenceParticipantsButton } from './ConferenceParticipantsButton';
import { ConferenceRecordBtnConnected } from './ConferenceRecordBtn';
import { ConferenceScreenShareBtn } from './ConferenceScreenShareBtn';
import { IDispatchProps, IOwnProps, IStateProps } from './types';

export const ConferenceFooter: React.FC<IOwnProps & IDispatchProps & IStateProps> = ({
    audioDevice,
    audioPermitted,
    audioStreamChangeInProgress,
    chatState,
    getDeviceListByType,
    handleMount,
    handlePropsChange,
    handleUnmount,
    localStream,
    mediaEnabled,
    participantsCount,
    participantsListOpen,
    priorityFrameEnable,
    screenShared,
    setParticipantsListOpen,
    setStreamProps,
    showDeviceNotification,
    showExitConfirmation,
    toggleChat,
    useAudio,
    useVideo,
    videoDevice,
    videoPermitted,
    videoRoomConnected,
    videoStreamChangeInProgress,
    use3dAudioFeature,
    use3dAudio,
    setUse3dAudio,
    currentUserIsOwner,
    allowedStreams,
    forbiddenStreams,
    forbiddenStreamsByConferenceSettings,
    canPublish,
}) => {
    const isMounted = useIsMounted();
    const { isUserInactive } = useContext(ConferencePageTemplateContext);
    const [devicesList, setDevicesList] = useState<IUserDevice[]>([]);
    const [devicesListIsLoading, setDevicesListIsLoading] = useState(true);
    const [isPointerInContainer, setIsPointerInContainer] = useState(false);

    const currentComponentRef = useRef<HTMLDivElement>(null);

    const audioDevicesList = getDeviceListByType('audioinput', devicesList);
    const videoDevicesList = getDeviceListByType('videoinput', devicesList);
    useEffect(() => {
        let localCurrentVideoOutput: any;
        if (localStream) {
            localCurrentVideoOutput = localStream.current;
        }
        handleMount(
            (devices: IUserDevice[]) => {
                if (isMounted()) {
                    setDevicesList(devices);
                }
            },
            (state: boolean) => {
                if (isMounted()) {
                    setDevicesListIsLoading(state);
                }
            },
            showDeviceNotification,
        );
        return () => {
            if (localCurrentVideoOutput) {
                handleUnmount(localCurrentVideoOutput);
                localCurrentVideoOutput = undefined;
            }
        };
    }, []);

    useEffect(() => {
        // If the user has only one type of device, it is necessary to update the device list again
        // when toggle is changed
        if (devicesList.length === 0 && !devicesListIsLoading) {
            handleMount(setDevicesList, setDevicesListIsLoading, showDeviceNotification);
        }
    }, [useVideo, useAudio]);

    useEffect(() => {
        setOwnStreamsPermissions({
            forbiddenStreamsByConferenceSettings,
            allowedStreams,
            forbiddenStreams,
            currentPermissions: { audioPermitted, videoPermitted },
            setStreamProps,
            currentUserIsOwner,
        });
    }, [forbiddenStreams, allowedStreams, forbiddenStreamsByConferenceSettings]);

    useEffect(() => {
        const newStreamProps: Partial<IJanusLocalStreamProps> = {};
        if (audioDevicesList.length === 0 && !devicesListIsLoading && useAudio) {
            newStreamProps.useAudio = false;
        }
        if (videoDevicesList.length === 0 && !devicesListIsLoading && useVideo) {
            newStreamProps.useVideo = false;
        }
        if (newStreamProps.useAudio === false || newStreamProps.useVideo === false) {
            setStreamProps(newStreamProps);
        }
        if (devicesListIsLoading || devicesList.length === 0) {
            return;
        }
        if (localStream) {
            handlePropsChange({ video: useVideo, audio: useAudio }, localStream);
        }
    }, [localStream, useVideo, useAudio, devicesListIsLoading]);

    useEffect(() => {
        const newStreamProps: Partial<IJanusLocalStreamProps> = {};
        if (!videoDevice && useVideo) {
            const storedDeviceId = getFromLocalStorage(LAST_USED_VIDEO_DEVICE);
            const newVideoDevice =
                videoDevicesList.find((device) => device.deviceId === storedDeviceId) ||
                videoDevicesList[0];
            if (newVideoDevice) {
                newStreamProps.videoDevice = newVideoDevice;
            }
        }
        if (!audioDevice && useAudio) {
            const storedDeviceId = getFromLocalStorage(LAST_USED_AUDIO_DEVICE);
            const newAudioDevice =
                audioDevicesList.find((device) => device.deviceId === storedDeviceId) ||
                audioDevicesList[0];
            if (newAudioDevice) {
                newStreamProps.audioDevice = newAudioDevice;
            }
        }
        if (newStreamProps.audioDevice || newStreamProps.videoDevice) {
            setStreamProps(newStreamProps);
        }
    }, [audioDevice, useAudio, audioDevicesList, videoDevice, useVideo, videoDevicesList]);

    useEffect(() => {
        const container = currentComponentRef.current;
        let clearCallback: (() => void) | undefined;

        if (container && priorityFrameEnable) {
            clearCallback = addContainerPointerHandlers(container, setIsPointerInContainer);
        }
        return () => {
            clearCallback && clearCallback();
        };
    }, [currentComponentRef, priorityFrameEnable]);

    const handleChangeVideoDeviceStatus = () => {
        if (!videoStreamChangeInProgress && videoPermitted) {
            setStreamProps({
                useVideo: !useVideo,
                screenShared: false,
            });
        }
    };

    const handleChangeAudioDeviceStatus = (removeAudio?: boolean) => {
        if (!audioStreamChangeInProgress) {
            const newUseAudio = !useAudio;
            const newAudioProps: Partial<IChangeJanusStreamProps> = {
                useAudio: newUseAudio,
                removeMedia: removeAudio ? { audio: true } : undefined,
            };
            if (newUseAudio) {
                if (!audioDevice && audioDevicesList.length > 0) {
                    newAudioProps.audioDevice = audioDevicesList[0];
                }
            }
            setStreamProps(newAudioProps);
        }
    };

    const onChangeScreenShareStatus = (sharingSourceId?: string) => {
        const newProps = { screenShared: !screenShared, useVideo, sharingSourceId };
        setStreamProps(newProps);
    };

    const handleChangeDevice = (type: string, device: any) => {
        if (videoDevice?.deviceId === device.deviceId) {
            return;
        }
        handlePropsChange(
            { video: useVideo, audio: useAudio },
            localStream,
            getPreferredDevicesByDevice(device),
        );
        const props: Partial<IJanusLocalStreamProps> = {};
        if (type === 'audio') {
            props.audioDevice = device;
        } else if (type === 'video') {
            props.videoDevice = device;
        }
        if (Object.keys(props).length) {
            setStreamProps(props);
        }
    };

    const hideFooterCondition =
        isUserInactive &&
        priorityFrameEnable &&
        !isPointerInContainer &&
        currentComponentRef.current &&
        !isElementInContainer(currentComponentRef.current, document.activeElement);

    const screenShareBtnStatus =
        !videoPermitted || !videoRoomConnected ? 'disabled' : screenShared ? 'on' : 'off';

    return (
        <div
            ref={currentComponentRef}
            className={classNames([
                styles.footer,
                priorityFrameEnable ? styles.footer_withPriorityFrame : '',
                hideFooterCondition ? styles.footer_hidden : '',
            ])}>
            <div className={styles.left}>
                <ConferenceDeviceNotificationConnected />
                <ConferenceVideoDevice
                    deviceList={videoDevicesList}
                    deviceListIsLoading={devicesListIsLoading}
                    deviceOn={useVideo}
                    devicePermittedByAdmin={videoPermitted}
                    mediaEnabled={mediaEnabled}
                    onChangeItem={handleChangeDevice}
                    onChangeValue={handleChangeVideoDeviceStatus}
                    readyToStream={videoRoomConnected}
                    selectedDeviceLabel={videoDevice?.label}
                    selectedDeviceId={videoDevice?.deviceId}
                    className={styles.footer__videoDevice}
                    canPublish={canPublish}
                />
                <ConferenceAudioDevice
                    deviceList={audioDevicesList}
                    deviceListIsLoading={devicesListIsLoading}
                    deviceOn={useAudio}
                    devicePermittedByAdmin={audioPermitted}
                    mediaEnabled={mediaEnabled}
                    onChangeItem={handleChangeDevice}
                    onChangeValue={handleChangeAudioDeviceStatus}
                    readyToStream={videoRoomConnected}
                    selectedDeviceLabel={audioDevice?.label}
                    selectedDeviceId={audioDevice?.deviceId}
                    use3dAudioFeature={use3dAudioFeature}
                    use3dAudio={use3dAudio}
                    setUse3dAudio={setUse3dAudio}
                    canPublish={canPublish}
                />
                <ConferenceScreenShareBtn
                    handleChangeStatus={onChangeScreenShareStatus}
                    videoPermittedByAdmin={videoPermitted}
                    status={screenShareBtnStatus}
                />
                <ConferenceRecordBtnConnected />
            </div>
            <div
                className={classNames([
                    styles.center,
                    chatState ? styles.center_withChatOpen : '',
                    participantsListOpen ? styles.center_withLeftBarOpen : '',
                ])}>
                {!chatState && <ConferenceChatQuickMessageConnected />}
                <ConferenceChatBtnConnected />
                <ConferenceEmotionsButtonConnected />
            </div>
            <div className={styles.right}>
                <div
                    className={classNames([
                        styles.footer__button,
                        chatState || participantsListOpen ? styles.withoutMargin : '',
                    ])}>
                    <ConferenceParticipantsButton
                        setParticipantsListOpen={setParticipantsListOpen}
                        participantsListOpen={participantsListOpen}
                        participantsCount={participantsCount}
                        chatState={chatState}
                        toggleChat={toggleChat}
                    />
                </div>
                <Button
                    styleType='primaryAction'
                    className={styles.button}
                    caption={t({ id: 'Exit', message: 'Выйти' })}
                    clickHandler={showExitConfirmation}
                />
            </div>
        </div>
    );
};
