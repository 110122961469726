import { t } from '@lingui/macro';
import { Trans } from '@lingui/react';
import { Button } from 'components/common/Button';
import { Input } from 'components/common/Input';
import { StatusLabel } from 'components/common/StatusLabel';
import React from 'react';
import { Form, FormRenderProps } from 'react-final-form';
import { isEmailValid } from 'utils/isEmailValid';
import styles from './RestorePasswordForm.module.scss';
import { IStateProps, IDispatchProps, IFormProps } from './types';

export const RestorePasswordForm: React.FC<IStateProps & IDispatchProps> = ({
    ldap,
    restorePassword,
}) => {
    const handleSubmit = async (values: IFormProps) => {
        const response = await restorePassword(values.email);
        if (!response) {
            return;
        }
        switch (response.payload) {
            case 'EMAIL_ADDRESS_NOT_REGISTERED':
                return {
                    email: t({
                        id: 'email.incorrect',
                        message: 'Неверный адрес электронной почты',
                    }),
                };
            case 'INVALID_PARAMETER':
                return {
                    email: t({
                        id: 'email.invalid',
                        message: 'Должен быть указан правильный адрес электронной почты',
                    }),
                };
            default:
                return {
                    isInfo: true,
                    email: t({
                        id: 'email.sendMail',
                        message: 'На почту выслано письмо с инструкцией по восттановлению пароля',
                    }),
                };
        }
    };
    const handleValidate = (values: IFormProps) => {
        const errors: { [key: string]: string } = {};
        if (!values.email) {
            errors.email = t({ id: 'RequiredField' });
        } else if (!isEmailValid(values.email)) {
            errors.email = t({
                id: 'Your email address must be entered in the format name@domain.com',
                message: 'Ваш адрес электронной почты должен быть введен в формате name@domain.com',
            });
        }
        return errors;
    };
    if (ldap) {
        return <p className={styles.info}>
            <Trans id='restorePassword.ldapText' message='Обратитесь к Администратору, чтобы изменить пароль' />
        </p>;
    }
    const render = ({
        handleSubmit,
        submitting,
        errors,
        submitErrors,
        dirtySinceLastSubmit,
    }: FormRenderProps<IFormProps>) => (
        <form onSubmit={handleSubmit} className={styles.form}>
            <StatusLabel
                text={submitErrors?.email && !dirtySinceLastSubmit ? submitErrors.email : ''}
                className={submitErrors?.isInfo ? styles.info : styles.fail}
            />
            <div className={styles.block}>
                <label className={styles.label} htmlFor='email'>
                    <Trans id='Email' message='Электронная почта' />
                </label>
                <Input
                    placeholder={t({ id: 'Email', message: 'Электронная почта' })}
                    name='email'
                    type='text'
                    className={styles.inputStyle}
                    errorText={errors?.email}
                    touched={false}
                />
            </div>
            <Button
                styleType='primaryAction'
                caption={t({ id: 'Reset password', message: 'Сбросить пароль' })}
                disabled={submitting}
                type='submit'
            />
        </form>
    );
    return <Form onSubmit={handleSubmit} validate={handleValidate} render={render} />;
};
