import { Avatar } from 'components/common/Avatar';
import { LottieAnimation } from 'components/common/LottieAnimation';
import { ParticipantContextMenu } from 'components/ParticipantContextMenu';
import { classNames } from 'utils/classNames';
import { getEmotionAnimation } from 'utils/emotions/getEmotionAnimation';
import { getPublisherInitials } from 'utils/participants/getPublisherInitials';
import { getAvatarUrl } from 'utils/users/getAvatarUrl';
import styles from './ParticipantAvatarOnly.module.scss';
import { IOwnProps } from './types';

export const ParticipantAvatarOnly: React.FC<IOwnProps> = ({
    children,
    emotion,
    isShowDisabledAudio,
    isShowMutedIcon,
    participant,
    participantId,
    publisher,
    talking,
}) => {
    const avatarUrl =
        participant && participant?.userId
            ? getAvatarUrl({ id: participant.userId, etag: participant.etag, size: '150' })
            : '';
    const displayNameClass = talking ? styles.activeName : '';
    return (
        <div className={styles.item}>
            <div className={styles.hoverMenu}>
                <ParticipantContextMenu participantId={participantId} orientation='down-left' />
            </div>
            <Avatar
                initials={getPublisherInitials(publisher)}
                image={avatarUrl}
                guest={!participant?.userId}
                active={talking}
                style={{
                    width: '100px',
                    height: '100px',
                }}
            />
            <div className={styles.nameBlock}>
                {(isShowMutedIcon || isShowDisabledAudio) && (
                    <div
                        className={classNames([
                            styles.microMutedIcon,
                            isShowDisabledAudio ? styles.microDisabledIcon : '',
                        ])}></div>
                )}
                <div className={displayNameClass}>{publisher.displayName}</div>
            </div>
            {getEmotion(emotion?.emotionName)}
            {children}
        </div>
    );
};
const getEmotion = (emotionName?: string) =>
    emotionName ? (
        <div className={styles.emotion}>
            <LottieAnimation
                animationData={getEmotionAnimation(emotionName)}
                style={{
                    width: '100px',
                    height: '100px',
                }}
            />
        </div>
    ) : null;
