import { createAsyncThunk } from '@reduxjs/toolkit';
import { getNetworkService } from 'api/getNetworkService';
import { getTokenForRecordApiCall } from 'api/records/getTokenForRecordApiCall';
import { IProgressParams } from 'types/api/INetworkService';
import { TAppState } from 'types/app/TAppStore';

interface IPayload extends IProgressParams {
    conferenceId: string;
    sessionId: string;
    recordId: string;
    operation?: 'stream' | 'download';
}

export const getConferenceRecordUrlThunkAction = createAsyncThunk(
    'getConferenceRecord',
    async (
        { conferenceId, sessionId,recordId, operation = 'download' }: IPayload,
        { getState },
    ): Promise<undefined | string> => {
        const {
            applicationConfiguration: { config },
        } = getState() as TAppState;
        const url = config.videoStreamingUrl;
        const response = await getTokenForRecordApiCall(getNetworkService(), {
            sessionId,
            conferenceId,
            recordId,
        });
        if (response.success) {
            const token = response.data;
            return `${url}/api/v1/video/${operation}?videoId=${recordId}&token=${token}`;
        }
    },
);
