import { UserCardFormConnected } from 'admin-app/components/UserCardForm';
import { IOwnProps as IUserCardFormProps } from 'admin-app/components/UserCardForm/types';
import { IOwnProps } from './types';
import { ControlsWithContainer } from './UserEditFormControls';

export const UserEditForm: React.FC<IOwnProps & Omit<IUserCardFormProps, 'controls'>> = ({
    container,
    ...props
}) => {
    const FormControls = ControlsWithContainer(container);
    return <UserCardFormConnected controls={FormControls} {...props} />;
};
