import { t } from '@lingui/macro';
import { Trans } from '@lingui/react';
import { HoverTooltip } from 'components/common/HoverTooltip';
import { ECopyToClipboardNotification } from 'constants/ECopyToClipboardNotification';
import { useRef } from 'react';
import { createInviteLink } from 'utils/conferences/createInviteLink';
import styles from './ConferenceLinkDialog.module.scss';
import { IDispatchProps, IOwnProps, IStateProps } from './types';

export const ConferenceLinkDialog: React.FC<IOwnProps & IStateProps & IDispatchProps> = ({
    className,
    handlerClick,
    closeDialog,
    copyToClipboard,
    conferenceConnectInfo,
}) => {
    const currentDialogRef = useRef(null);
    if (!conferenceConnectInfo) {
        return null;
    }
    const { id, pin } = conferenceConnectInfo;
    const tooltipText = t({ message: 'Нажмите, чтобы скопировать', id: 'conf.dialog.tooltip' });
    const onClick = (e: any) => {
        e.stopPropagation();
        const htmlElem = e.target as HTMLElement;
        let notifyTextKey;
        switch (htmlElem.dataset?.fieldType) {
            case 'link':
                notifyTextKey = ECopyToClipboardNotification.INVITE_LINK;
                break;
            case 'confId':
                notifyTextKey = ECopyToClipboardNotification.CONF_ID;
                break;
            case 'pin':
                notifyTextKey = ECopyToClipboardNotification.CONF_PIN;
                break;
        }
        if (!notifyTextKey) {
            return;
        }
        copyToClipboard({
            dataToCopy: htmlElem.textContent || '',
            notifyTextKey,
            notificationOptions: { type: 'notify' },
        });
        if (handlerClick) handlerClick();
        closeDialog();
    };
    return (
        <div className={`${styles.dialog} ${className || ''}`} ref={currentDialogRef}>
            <div className={styles.header}>
                <Trans message='Ссылка на конференцию' id='Link to the conference' />
            </div>
            <div className={styles.field}>
                <div className={styles.label}>
                    <Trans
                        message='Поделитесь ссылкой, для доступа к конференции:'
                        id='Share the link to access the conference:'
                    />
                </div>
                <div className={styles.value}>
                    <div
                        className={`${styles.link} ${styles.link_hover}`}
                        onClick={onClick}
                        data-field-type='link'>
                        {createInviteLink(id, pin)}
                    </div>
                    <div className={styles.tooltipWrapper}>
                        <HoverTooltip name={tooltipText} color='blue' />
                    </div>
                </div>
            </div>
            <div className={styles.field}>
                <div className={styles.label}>
                    <Trans message='Идентификатор конференции:' id='Conference id' />
                </div>
                <div className={styles.value}>
                    <div
                        className={`${styles.link} ${styles.link_hover}`}
                        onClick={onClick}
                        data-field-type='confId'>
                        {id}
                    </div>
                    <div className={styles.tooltipWrapper}>
                        <HoverTooltip name={tooltipText} color='blue' />
                    </div>
                </div>
            </div>
            {pin && (
                <div className={styles.field}>
                    <div className={styles.text}>
                        <Trans message='Код доступа:' id='conference.pin' />
                    </div>
                    <div className={styles.value}>
                        <div
                            className={`${styles.link} ${styles.link_hover}`}
                            onClick={onClick}
                            data-field-type='pin'>
                            {pin}
                        </div>
                        <div className={styles.tooltipWrapper}>
                            <HoverTooltip name={tooltipText} color='blue' />
                        </div>
                    </div>
                </div>
            )}
        </div>
    );
};
