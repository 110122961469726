interface IPayload {
    conferenceId: string;
    sessionId: string;
    imgId: string;
    name: string;
}

export const getImagePreviewPath = ({ conferenceId, imgId, sessionId, name }: IPayload) => {
    const previewPathName = `/api/conferences/${conferenceId}/sessions/${sessionId}/media_files/${imgId}${
        name.endsWith('svg') ? '' : '/preview'
    }`;
    return new window.URL(previewPathName, window.location.origin);
};
