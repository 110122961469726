import PropTypes from 'prop-types';
import React, { useEffect, useRef } from 'react';
import { setEscCloseDropdown } from 'utils/escCloseDropdown';

export interface IOutsideClickProps {
    onOutsideClick(event: MouseEvent | KeyboardEvent): void;
    children: any;
    className?: string;
    updateNode?: (node: React.RefObject<HTMLDivElement>) => void;
}

const OutsideClick: React.FunctionComponent<IOutsideClickProps> = ({
    onOutsideClick,
    children,
    className,
    updateNode,
}: IOutsideClickProps) => {
    const wrapperRef = useRef<HTMLDivElement>(null);

    useEffect(() => {
        updateNode && updateNode(wrapperRef);
    }, [wrapperRef, updateNode]);

    const handleClickOutside = (event: MouseEvent) => {
        const target = event.target as Element;
        if (wrapperRef.current && !wrapperRef.current.contains(target)) {
            onOutsideClick(event);
            setEscCloseDropdown(false);
        }
    };

    const handleKeyDownOutside = (event: KeyboardEvent) => {
        if (event.key === 'Escape') {
            event.preventDefault();
            event.stopPropagation();
            onOutsideClick(event);
            setEscCloseDropdown(false);
        }
    };

    useEffect(() => {
        document.addEventListener('mousedown', handleClickOutside);
        document.addEventListener('keydown', handleKeyDownOutside);
        return () => {
            document.removeEventListener('mousedown', handleClickOutside);
            document.removeEventListener('keydown', handleKeyDownOutside);
        };
    });

    return (
        <div className={className} ref={wrapperRef}>
            {children}
        </div>
    );
};

OutsideClick.propTypes = {
    children: PropTypes.oneOfType([
        PropTypes.arrayOf(PropTypes.element),
        PropTypes.element,
    ]).isRequired,
};

export default OutsideClick;
