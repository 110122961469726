import { IDesktopWindow } from 'types/desktop/IDesktopWindow';
import { log } from 'utils/log';
import { janusVideoRoomTracksLogger } from 'utils/logger';
let audioTrack: MediaStreamTrack | undefined;
let videoTrack: MediaStreamTrack | undefined;
let screenTrack: MediaStreamTrack | undefined;

export const captureVideo = async (constraints: any) => {
    janusVideoRoomTracksLogger.log({ constraints });
    if (videoTrack) {
        janusVideoRoomTracksLogger.debug('Stop video');
        videoTrack.stop();
    }
    try {
        const stream = await navigator.mediaDevices.getUserMedia(constraints);
        janusVideoRoomTracksLogger.debug('Capture video');
        videoTrack = stream.getVideoTracks()[0];
        if (videoTrack) {
            screenTrack?.stop();
        }
        return videoTrack;
    } catch (e) {
        janusVideoRoomTracksLogger.error('Failed to video capture', e);
    }
};

export const captureAudio = async (constraints: any) => {
    if (audioTrack) {
        janusVideoRoomTracksLogger.debug('Stop audio');
        audioTrack.stop();
    }
    try {
        const stream = await navigator.mediaDevices.getUserMedia(constraints);
        janusVideoRoomTracksLogger.debug('Capture audio');
        audioTrack = stream.getAudioTracks()[0];
        return audioTrack;
    } catch (e) {
        janusVideoRoomTracksLogger.error('Failed to audio capture', e);
    }
};

export const captureScreen = async (constraints: any, sharingSourceId: string | undefined) => {
    if (screenTrack) {
        janusVideoRoomTracksLogger.debug('Stop screen');
        screenTrack.stop();
    }
    let stream: MediaStream;
    const desktopWindow = window as typeof window & IDesktopWindow;

    if (desktopWindow.meevox) {
        if (!sharingSourceId) {
            log.debug('Missing sharing source id');
            return;
        }
        const desktopStreamConstraints = {
            audio: false,
            video: {
                mandatory: {
                    chromeMediaSource: 'desktop',
                    chromeMediaSourceId: sharingSourceId,
                    maxWidth: constraints.video.width,
                    maxHeight: constraints.video.height,
                },
            },
        };
        stream = await navigator.mediaDevices.getUserMedia(desktopStreamConstraints as any);
    } else {
        stream = await navigator.mediaDevices.getDisplayMedia(constraints);
    }
    screenTrack = stream.getVideoTracks()[0];
    if (screenTrack) {
        janusVideoRoomTracksLogger.debug('Stop video');
        videoTrack?.stop();
    }
    return screenTrack;
};

export const releaseAudio = () => {
    janusVideoRoomTracksLogger.debug('Release audio');
    audioTrack?.stop();
    audioTrack = undefined;
};

export const releaseVideo = () => {
    janusVideoRoomTracksLogger.debug('Release video');
    videoTrack?.stop();
    videoTrack = undefined;
};

export const releaseScreen = () => {
    janusVideoRoomTracksLogger.debug('Release screen');
    screenTrack?.stop();
    screenTrack = undefined;
};
