import { TLanguages } from 'types/TLanguages';
import { i18n } from '@lingui/core';

export const activateLanguage = async (language: TLanguages, cb?: () => void) => {
    const {
        default: { messages },
    } = await import(`./${language}/messages.js`);
    const plurals = await import(`./${language}/plurals.js`);
    i18n.loadLocaleData(language, { plurals: plurals[language] });
    i18n.load(language, messages);
    i18n.activate(language);
    if (cb) {
        cb();
    }
};
