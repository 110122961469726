import { EVideoResolution } from 'constants/EVideoResolution';
import { applicationStore } from 'store/applicationStore';
import { TAppState } from 'types/app/TAppStore';
import { IUserDevice } from 'types/IUserDevice';
import { calculateWidthAndHeight } from './janus/helpers/calculateWidthAndHeight';
import * as streamOperations from './streamOperations'; //for testing

interface IEnabledDevices {
    video?: boolean;
    audio?: boolean;
}

let stream: MediaStream;

export let endAction: any = null;
let requestMedia = false;

const getResultVideoData = (videoPreferred?: boolean | { deviceId: string }) => {
    if (!videoPreferred) {
        return false;
    }
    const {
        auth: { appSettings },
    } = applicationStore.getState() as TAppState;
    const { width, height } = calculateWidthAndHeight(
        appSettings ? appSettings['resolution'] : EVideoResolution.STD_RES,
    );
    if (typeof videoPreferred === 'boolean') {
        return { width, height };
    }
    return { ...videoPreferred, ...{ width, height } };
};

export async function playStream(
    enabledDevices: IEnabledDevices,
    streamSrc: any,
    preferredDevices?: {
        video?: { id: string };
        audio?: { id: string };
    },
) {
    const videoPreferred =
        !preferredDevices?.video?.id || !enabledDevices.video
            ? enabledDevices.video
            : { deviceId: preferredDevices.video.id };

    const audioPreferred =
        !preferredDevices?.audio?.id || !enabledDevices.audio
            ? enabledDevices.audio
            : { deviceId: preferredDevices.audio.id };

    streamOperations.stopStream(streamSrc);

    requestMedia = true;
    endAction = false;
    stream = await navigator.mediaDevices.getUserMedia({
        video: getResultVideoData(videoPreferred),
        audio: audioPreferred,
    });

    if (streamSrc && streamSrc.current) {
        streamSrc.current.srcObject = stream;
        streamSrc.current.muted = true;
        await streamSrc.current.play();
    }
    requestMedia = false;
    if (endAction) {
        endAction();
    }
    return stream;
}

export const stopStream = (streamSrc: any, streamLocalCurrentSrc?: any) => {
    if (requestMedia) {
        endAction = () => stopStream(streamSrc, streamLocalCurrentSrc);
    }

    if (!stream) {
        return;
    }

    const tracks = stream.getTracks();

    tracks.forEach((track: any) => {
        track.stop();
    });

    if (!streamLocalCurrentSrc) {
        if (streamSrc && streamSrc.current) {
            streamSrc.current.srcObject = null;
        }
    } else {
        streamLocalCurrentSrc.srcObject = null;
    }
};

export async function getUserDevicesList(): Promise<IUserDevice[]> {
    // The first line is required because without it the devices will have empty labels
    // await navigator.mediaDevices.getUserMedia({ audio: true, video: true });
    // But it breaks another things, so need to change
    return await navigator.mediaDevices.enumerateDevices();
}

export const getCurrentStream = () => stream;
