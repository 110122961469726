import { IOwnProps } from './types'
import styles from './HoverLabel.module.scss';

export const HoverLabel: React.FC<IOwnProps> = ({ children, color, type = 'default' }) => {
    const classNames = [styles.label];
    if (type && type !== 'default') {
        classNames.push(styles[`label_${type}`]);
    }
    if (color) {
        classNames.push(styles[`label_${color}`]);
    }
    const resultClassName = classNames.join(' ');
    return (
        <div className={resultClassName}>
            {children}
            <div className={styles.rectangle} />
        </div>
    )};
