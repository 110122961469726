import { IConferenceRecord } from 'types/IConferenceRecord';

interface ICreateProcessingRecordPayload {
    id: string;
    sessionId: string;
    conferenceId: string;
    name: string;
    timeStart: string;
    currentUserIsOwner: boolean;
}

export const createProcessingRecord = ({
    id,
    sessionId,
    conferenceId,
    name,
    timeStart,
    currentUserIsOwner,
}: ICreateProcessingRecordPayload): IConferenceRecord => {
    // const parseRecordDate = parseISO(timeStart);
    // const recordDate = format(parseRecordDate, 'dd/MM/yyyy');
    // const recordTime = format(parseRecordDate, 'HH:mm');

    return {
        id,
        sessionId,
        conferenceId,
        name,
        timeStart,
        timeEnd: timeStart,
        notifyAboutFinished: false,
        recordReady: false,
        record: { duration: 0, size: 0, success: false },
        currentUserIsOwner,
    };
};
