import { conferenceSettings } from 'constants/appSettingsStructure';
import { IAppSettingsTyped } from 'types/app/IAppSettings';
import { getAppSettingsDefaultValues } from 'utils/getAppSettingsDefaultValues';

export const getConferenceSettingsFormInitialValues = (appSettings: IAppSettingsTyped | null) => {
    if (appSettings) {
        const { videoParticipants, audioParticipants, recordConference, audioNotification } =
            appSettings;
        return {
            videoParticipants,
            recordConference,
            audioParticipants,
            audioNotification,
        };
    }
    return getAppSettingsDefaultValues([conferenceSettings]);
};
