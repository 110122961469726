import { setConferenceChatMessageStatus } from 'actions/chat/setConferenceChatMessageStatus';
import { EChatMessageStatus } from 'constants/EChatMessageStatus';
import { applicationStore } from 'store/applicationStore';
import { IConferenceChatMessage } from 'types/chat/IConferenceChatMessage';
import { janusCtx } from 'utils/janus/janusCtx';
import { janusTextRoomLogger } from 'utils/logger';
import { randomString } from 'utils/randomString';

export const sendMessageToConferenceJanus = ({
    id,
    contentType,
    text,
    ownUserId,
    time,
    files,
}: IConferenceChatMessage) => {
    const message = {
        textroom: 'message',
        transaction: randomString(12),
        text: JSON.stringify({ text, contentType, files, id, ownUserId, time }),
        room: `${janusCtx.roomId}`,
    };

    janusCtx.textRoomPluginHandler.data({
        text: JSON.stringify(message),
        error: (e: any) => {
            janusTextRoomLogger.error(e);
        },
        success: () => {
            applicationStore.dispatch(
                setConferenceChatMessageStatus({
                    messageId: id,
                    messageStatus: EChatMessageStatus.SENT,
                }),
            );
            janusTextRoomLogger.debug('Message was sent success');
        },
    });
};
