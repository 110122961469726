import { Trans } from '@lingui/react';
import styles from './DevicesErrorDialog.module.scss';
import { IOwnProps } from './types';

export const DevicesErrorDialog: React.FC<IOwnProps> = ({ handleClickOff, handleClickReload, className }) => (
    <div className={`${styles.notifications} ${className || ''}`}>
        <div className={styles.notification}>
            <div className={styles.header}>
                <Trans message='Проблема с подключением к оборудованию' id='Problem with connecting to the hardware' />
            </div>
            <div className={styles.footer}>
                <div className={styles.button} onClick={handleClickReload}>
                    <Trans message='Переподключиться' id='Reconnect' />
                </div>
                <div className={styles.button} onClick={handleClickOff}>
                    <Trans message='Выключить все оборудование' id='Turn off all devices' />
                </div>
            </div>
        </div >
    </div>
);
