import { t } from '@lingui/macro';
import { createAsyncThunk } from '@reduxjs/toolkit';
import { recoverPasswordApiCall } from 'api/authorization/recoverPasswordApiCall';
import { getNetworkService } from 'api/getNetworkService';
import { appHistory } from 'appHistory';
import { showNotifyThunkAction } from 'thunk/notify/showNotifyThunkAction';

interface IPayload {
    password: string;
    token: string;
}

export const setNewPasswordThunkAction = createAsyncThunk(
    'setNewPassword',
    async ({ password, token }: IPayload, { dispatch }) => {
        const response = await recoverPasswordApiCall(getNetworkService(), { password, token });
        if (response.success) {
            dispatch(
                showNotifyThunkAction({
                    text: t({ message: 'Пароль успешно установлен', id: 'password.was.set' }),
                    timeout: 10000,
                    type: 'success',
                }),
            );
            appHistory.push('/login');
        } else {
            return response.data;
        }
    },
);
