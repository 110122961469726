import { t } from '@lingui/macro';
import { RecordDownloadBar } from 'components/RecordDownloadBar';
import { useConferenceRecordDeleteDialog } from 'hooks/conference/useConferenceRecordDeleteDialog';
import { convertRecordDuration } from 'utils/records/convertRecordDuration';
import { convertRecordSize } from 'utils/records/convertRecordSize';
import styles from './ConferenceRecord.module.scss';
import { ConferenceRecordControls } from './ConferenceRecordControls';
import { ConferenceRecordStatusRender } from './ConferenceRecordStatusRender';
import { IOwnProps } from './types';

export const ConferenceRecord: React.FC<IOwnProps> = ({
    recordData,
    isDisabled,
    recordDownloadItem,
    getRecordHandleClick,
    downloadRecord,
    deleteRecord,
    recordReadyBtnHandler,
}) => {
    const isRecordError = recordData.record?.success === false;
    const isRecordReady = recordData.recordReady;
    const recordDuration = isRecordReady ? convertRecordDuration(recordData.record?.duration) : '';
    const notifyBtnText =
        (!recordData.notifyAboutFinished &&
            t({
                id: 'finished.conference.record.addNotify',
                message: 'Сообщить о завершении',
            })) ||
        t({
            id: 'finished.conference.record.deleteNotify',
            message: 'Не сообщать о завершении',
        });

    const handleDownloadRecord = () =>
        downloadRecord({ conferenceId: recordData.conferenceId, sessionId: recordData.sessionId, recordId: recordData.id});

    const [handleDeleteRecord, deleteConfirmDialogRender] = useConferenceRecordDeleteDialog(() =>
        deleteRecord({ conferenceId: recordData.conferenceId, sessionId: recordData.sessionId, recordId:recordData.id }),
    );

    const handleRecordReady = () =>
        recordReadyBtnHandler(recordData.conferenceId, recordData.notifyAboutFinished);
    return (
        <>
            {deleteConfirmDialogRender()}
            <div
                className={`${styles.recordItem} ${isRecordError ? styles.recordItem_noHover : ''}`}
                key={recordData.id}>
                <div className={styles.time}>{recordDuration}</div>
                <div className={styles.info} onClick={getRecordHandleClick(recordData)}>
                    <div className={styles.name}>{recordData.name}</div>
                    {!recordDownloadItem && (
                        <div className={styles.id}>
                            {isRecordReady && !isRecordError ? (
                                convertRecordSize(recordData.record?.size)
                            ) : (
                                <ConferenceRecordStatusRender
                                    isReady={isRecordReady}
                                    isError={isRecordError}
                                />
                            )}
                        </div>
                    )}
                    {recordDownloadItem && <RecordDownloadBar item={recordDownloadItem} />}
                </div>
                <ConferenceRecordControls
                    isRecordOwner={recordData.currentUserIsOwner}
                    isRecordDownloadItem={!!recordDownloadItem}
                    isReady={isRecordReady}
                    isError={isRecordError}
                    isDisabled={isDisabled}
                    notifyBtnText={notifyBtnText}
                    handleDownloadRecord={handleDownloadRecord}
                    handleDeleteRecord={handleDeleteRecord}
                    handleRecordReady={handleRecordReady}
                />
            </div>
        </>
    );
};

// const handleOnProgress = (event: ProgressEvent) => {
//     const totalMB = Math.round(event.total / 1000000);
//     const downloadedMB = Math.round(event.loaded / 1000000);
//     if (totalMB != downloadedMB) {
//         modifyRecordDownloadList({ id: conference.id, total: totalMB, downloaded: downloadedMB });
//     } else {
//         deleteRecordDownloadList(conference.id);
//     }
// };
