import { INetworkService } from 'types/api/INetworkService';

interface IPayload {
    conferenceId: string;
    sessionId: string;
    recordId: string;
}

export const getTokenForRecordApiCall = (
    networkService: INetworkService,
    { conferenceId, sessionId, recordId }: IPayload,
) =>
    networkService.post({
        url: `/api/video-streaming/token/conferences/${conferenceId}/sessions/${sessionId}/records/${recordId}`,
    });
