import { createAsyncThunk } from '@reduxjs/toolkit';
import { storeConferenceInfoAction } from 'actions/conferences/storeConferenceInfoAction';
import { getConferenceInfoApiCall } from 'api/conference/getConferenceInfoApiCall';
import { getNetworkService } from 'api/getNetworkService';
import { getConferenceInfoSelector } from 'selectors/getConferenceInfoSelector';
import { TAppState } from 'types/app/TAppStore';
import { IConferenceInfo } from 'types/conferences/IConferenceInfo';

export const getConferenceInfoThunkAction = createAsyncThunk<
    IConferenceInfo | null,
    { id: string; forceUpdate?: boolean }
>('getConferenceInfo', async ({ id, forceUpdate = false }, { dispatch, getState }) => {
    let conference;
    if (!forceUpdate) {
        conference = getConferenceInfoSelector(getState() as TAppState, id);
        if (conference) {
            return conference;
        }
    }
    const response = await getConferenceInfoApiCall(getNetworkService(), id);
    if (response.success) {
        conference = response.data;
        if (conference) {
            dispatch(storeConferenceInfoAction([conference]));
        }
        return conference;
    } else {
        return null;
    }
});
