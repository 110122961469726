import { createAsyncThunk } from '@reduxjs/toolkit';
import { getConferenceInvitedUsersApiCall } from 'api/conference/getConferenceInvitedUsersApiCall';
import { getNetworkService } from 'api/getNetworkService';
import { IConferenceInvitedUserFromServer } from 'types/server/IConferenceInvitedUserFromServer';
import { setConferencesInvitedUsersAction } from 'actions/conferences/setConferencesInvitedUsersAction';
import { convertInvitedUserFromServerToParticipant } from 'utils/convertInvitedUserFromServerToParticipant';

interface IPayload {
    id: string;
}

type IResponse = IConferenceInvitedUserFromServer[] | undefined;

export const getConferenceInvitedUsersThunkAction = createAsyncThunk<IResponse, IPayload>(
    'getConferenceInvitedUsers',
    async ({id}, {dispatch}) => {
        const response = await getConferenceInvitedUsersApiCall(
            getNetworkService(),
            '',
            id,
        );
        if (!response.success) {
            return undefined;
        }
        dispatch(setConferencesInvitedUsersAction({
            id,
            users: convertInvitedUserFromServerToParticipant(response.data),
        }))
        return response.data
    },
);
