import { Field } from 'react-final-form';
import styles from './Input.module.scss';
import { IOwnProps } from './types';
import { makeDate, makeTime, makeTel, telMask, getMaskString } from './formats';
import { StatusText } from '../StatusText';
import React, { useState } from 'react';

export const Input = React.forwardRef<HTMLInputElement, IOwnProps>((props, ref) => {
    const{
        autoFocus = false,
        autocomplete = 'on',
        className = '',
        statusClassName,
        disabled,
        errorType = 'error',
        errorText,
        errorWithoutConditions,
        format,
        handleFocus,
        handleInput,
        handlePaste,
        handleKeyDown,
        initialValue,
        name,
        parse,
        pattern,
        placeholder,
        tabIndex,
        touched = true,
        type = 'text',
        styleType = 'default',
        highlightTextOnError,
        value,
        defaultValue,
        maxLength,
        required,
        onBlurHandlers,
        handleBlur,
        handleClick,
    } = props;
    const [blurFormatIssue, setBlurFormatIssue] = useState(false);
    const onBlur = (currentValue: any, setFieldValue: (event: any) => void) => {
        if (onBlurHandlers) {
            const { validateOnBlur, formatOnBlur } = onBlurHandlers;
            const result = validateOnBlur(currentValue);
            if (result) {
                blurFormatIssue && setBlurFormatIssue(false);
                formatOnBlur(currentValue, setFieldValue);
            } else if (result !== null) {
                setBlurFormatIssue(true);
            }
        }
        if (handleBlur) {
            handleBlur(currentValue);
        }
    };
    return (
        <Field
            name={name}
            initialValue={initialValue}
            format={format}
            parse={parse}
            defaultValue={defaultValue}>
            {({ input, meta }) => {
                const resultStyle = [styles.input];
                if (styleType !== 'default') {
                    resultStyle.push(styles[`input_${styleType}`]);
                }
                const isShowError =
                    errorWithoutConditions ||
                    blurFormatIssue ||
                    (errorText && ((touched || meta.touched || meta.submitFailed) as boolean)); // && !meta.dirtySinceLastSubmit;
                if (isShowError) {
                    resultStyle.push(styles[errorType]);
                }
                if (highlightTextOnError) {
                    resultStyle.push(styles.error_text);
                }
                if (disabled) {
                    resultStyle.push(styles.input_disabled);
                }
                const style = resultStyle.join(' ');
                const inputBlockStyle =
                    styleType === 'default' || styleType === 'new'
                        ? styles.inputBlock
                        : styles.mobileInputBlock;
                let val =
                    value !== undefined ? value : input.value !== undefined ? input.value : '';
                let inputType: string;
                let inputHandler = handleInput;
                if (type === 'time') {
                    inputType = 'text';
                    inputHandler = makeTime(handleInput);
                } else if (type === 'date') {
                    inputType = 'text';
                    inputHandler = makeDate(handleInput);
                } else if (type === 'tel') {
                    inputType = 'tel';
                    inputHandler = makeTel(handleInput);
                    if (val) {
                        val = getMaskString(val, telMask);
                    }
                } else {
                    inputType = type;
                }
                if (!inputHandler) {
                    inputHandler = handleInput ? handleInput : () => {};
                }
                const focusHandler = handleFocus ? handleFocus : (e: any) => input.onFocus(e);
                const clickHandler = handleClick ? handleClick : () => {};
                const pasteHandler = handlePaste ? handlePaste : () => {};
                const keyDownHandler = handleKeyDown ? handleKeyDown : () => {};
                return (
                    <div className={inputBlockStyle} data-input-wrapper>
                        <input
                            ref={ref}
                            {...input}
                            value={val}
                            type={inputType}
                            placeholder={placeholder}
                            className={`${style} ${className}`}
                            pattern={pattern}
                            disabled={disabled}
                            onInput={inputHandler}
                            onClick={clickHandler}
                            onFocus={focusHandler}
                            onPaste={pasteHandler}
                            onBlur={() => onBlur(input.value, input.onChange)}
                            onKeyDown={keyDownHandler}
                            autoComplete={autocomplete}
                            tabIndex={tabIndex}
                            autoFocus={autoFocus}
                            maxLength={maxLength}
                            required={required}
                        />
                        {isShowError && (
                            <StatusText
                                errorType={errorType}
                                errorText={errorText}
                                className={`${styles.statusText} ${statusClassName || ''}`}
                            />
                        )}
                    </div>
                );
            }}
        </Field>
    );
});
