import { IDynamicSectionStructure } from 'types/dynamicForm/IDynamicSectionStructure';

export const getAppSettingsDefaultValues = (settings: IDynamicSectionStructure[]) => {
    const resultObj: { [key: string]: string | boolean } = {};
    settings.forEach(({ fields }) => {
        for (const { defaultValue, name } of fields) {
            resultObj[name] = defaultValue;
        }
    });
    return resultObj;
};
