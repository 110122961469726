import { useLocation } from 'react-router-dom';
import { getMobileSections } from '../utils';
import styles from './AccountPageMobileFooter.module.scss';
import { IDispatchProps, IOwnProps, IStateProps } from './types';

export const AccountPageMobileFooter: React.FC<IStateProps & IDispatchProps & IOwnProps> = ({
    className = '',
    conferenceListRouteToPast,
    onMenuClick,
    goToRoute,
}) => {
    const location = useLocation();
    const splittedPath = location.pathname.split('/');
    const pathLastPart = splittedPath[splittedPath.length - 1];

    const itemRender = getMobileSections().map(({ img: itemImg, name, route: itemRoute }) => {
        let thisIsActive = false;
        if (itemRoute instanceof Array) {
            itemRoute.forEach((route) => {
                const splittedRoute = route.split('/');
                if (pathLastPart === splittedRoute[splittedRoute.length - 1]) {
                    thisIsActive = true;
                }
            });
        } else {
            if (pathLastPart === itemRoute) {
                thisIsActive = true;
            }
        }
        const img = thisIsActive ? itemImg.active : itemImg.inactive;
        const nameRender = () => (name != '' ? <div className={styles.name}>{name}</div> : '');

        return (
            <button
                key={name}
                onClick={() => {
                    if (name === '') {
                        onMenuClick();
                        return;
                    }

                    const route =
                        itemRoute instanceof Array
                            ? itemRoute[conferenceListRouteToPast ? 1 : 0]
                            : itemRoute;
                    goToRoute(`/account/${route}`);
                }}>
                <img src={img} alt={name} />
                {nameRender()}
            </button>
        );
    });

    return (
        <div className={`${styles.footer} ${className}`}>
            <div className={styles.buttons}>{itemRender}</div>
        </div>
    );
};
