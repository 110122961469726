import { Trans } from '@lingui/react';
import { Button } from 'components/common/Button';
import { useRefState } from 'hooks/useRefState';
import { useEffect } from 'react';
import styles from './ClipboardImagePreview.module.scss';
import { IOwnProps } from './types';

export const ClipboardImagePreview: React.FC<IOwnProps> = ({ image, clickHandler }) => {
    const [isSending, isSendingRef, setIsSending ] = useRefState(false)
    const sentImage = () => {
        if (isSendingRef.current) {
            return;
        }
        setIsSending(true);
        clickHandler();
    };

    const keyboardHandler = (e: KeyboardEvent) => {
        e.code === 'Enter' && sentImage();
    };

    const addKeyboardListener = () => {
        document.addEventListener('keydown', keyboardHandler);
    };

    const removeKeyboardListener = () => {
        document.removeEventListener('keydown', keyboardHandler);
    };

    useEffect(() => {
        addKeyboardListener();
        return () => {
            removeKeyboardListener();
        };
    }, []);

    return (
        <div className={styles.container}>
            <img src={`${image}`} alt='chat image preview' />
            <Button
                styleType='primaryAction'
                className={styles.button}
                clickHandler={sentImage}
                disabled={isSending}>
                <Trans id='send' message='Отправить' />
            </Button>
        </div>
    );
};
