export const displayDeviceTooltip = (
    currentTarget: HTMLDivElement,
    showTooltip: boolean,
    setShowTooltip: (newState: boolean) => void,
) => {
    const labelFullWidth =
        currentTarget.querySelector('[data-js-tooltip="true"]')?.scrollWidth || 0;
    const labelMaxWidth = currentTarget.querySelector('[data-js-tooltip="true"]')?.clientWidth || 0;
    let timerId: any = undefined;
    return (timerId = setTimeout(() => {
        !showTooltip && labelFullWidth > labelMaxWidth ? setShowTooltip(true) : false;
        clearTimeout(timerId);
    }, 1000));
};

export const hideDeviceTooltip = (
    timerId: any,
    showTooltip: boolean,
    setShowTooltip: (newState: boolean) => void,
) => {
    showTooltip ? setShowTooltip(false) : false;
    if (timerId) clearTimeout(timerId);
};
