import { log } from 'utils/log';
import { getTimeZonesArray, timeZonesByValue } from './getTimeZonesArray';

const timeZones = getTimeZonesArray();

let mockTimeZone = '';
export const setMockBrowserTimeZone = (timeZone: string) => (mockTimeZone = timeZone);

export const getBrowserTimeZone = () => {
    const currentTimeZoneValue = mockTimeZone || Intl.DateTimeFormat().resolvedOptions().timeZone;
    const timeZone = timeZonesByValue[currentTimeZoneValue];
    if (timeZone) {
        return timeZone.value;
    }
    const offset = new Date().getTimezoneOffset();
    const suitableTimeZone = timeZones.find((tz) => {
        if (tz.shiftInMinutes === -offset) {
            return true;
        }
    });
    if (suitableTimeZone) {
        return suitableTimeZone.value;
    }
    log.error(`Timezone ${currentTimeZoneValue} was not found. Use UTC0`);
    return timeZones.filter((tz) => {
        tz.shiftInMinutes === 0;
    })[0].value;
};
