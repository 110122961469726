interface IPayload {
    lastName?: string;
    firstName?: string;
    email?: string;
}

export const getUserInitials = (userData: IPayload | undefined): string => {
    if (!userData) {
        return '';
    }

    const localUserData: Required<IPayload> = {
        lastName: '',
        firstName: '',
        email: '',
    };
    Object.assign(localUserData, userData);
    for (const key in localUserData) {
        const f = key as keyof IPayload;
        if (!localUserData[f]) {
            localUserData[f] = '';
        }
    }

    if (
        (localUserData.firstName === '' || localUserData.lastName === '') &&
        localUserData.email !== ''
    ) {
        const splitEmail = localUserData.email.split('@');
        return `${splitEmail[0][0]}${splitEmail[1][0]}`;
    }

    if (localUserData.firstName !== '' && localUserData.lastName !== '') {
        return `${localUserData.firstName[0]}${localUserData.lastName[0]}`;
    } else if (localUserData.firstName !== '') {
        return `${localUserData.firstName[0]}`;
    } else if (localUserData.lastName !== '') {
        return `${localUserData.lastName[0]}`;
    }
    return '';
};
