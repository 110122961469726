import { t } from '@lingui/macro';
import AudioNotification from 'assets/audio/Notification.mp3';

let isAudioPlaying = false;

const playAudioNotification = () => {
    const audio = new Audio(AudioNotification);
    audio.addEventListener('ended', () => {
        isAudioPlaying = false; 
    })
    audio.volume = 0.2;
    audio.play();
    isAudioPlaying = true;
};

let timerId: undefined | NodeJS.Timeout;
let currentTitle = '';
const newTitle = t({ id: 'joinRequest.titleNotification', message: 'Новый запрос' });

const addTitleNotification = () => {
    if (document.title !== newTitle) {
        currentTitle = document.title
    }

    timerId = setInterval(() => {
        document.title = document.title === currentTitle ? newTitle : currentTitle;
    }, 1500);

    return addTitleNotificationHandlers();
};

const events = ['mousemove', 'mousedown', 'keydown', 'touchstart'];

const handlerCallback = () => {
    timerId && clearInterval(timerId);
    document.title !== currentTitle ? (document.title = currentTitle) : false;
    removeTitleNotificationHandlers();
};
const removeTitleNotificationHandlers = () => {
    events.forEach((event) => {
        document.removeEventListener(event, handlerCallback);
    });
};
const addTitleNotificationHandlers = () => {
    events.forEach((event) => {
        document.addEventListener(event, handlerCallback);
    });
    return removeTitleNotificationHandlers;
};

export const showNotifies = (isAudioNotification:boolean) => {
    let removeTitleNotification: (() => void) | undefined;
        
    if (isAudioNotification && !isAudioPlaying) {
        playAudioNotification();
        removeTitleNotification = addTitleNotification();
    }

    return removeTitleNotification
}
