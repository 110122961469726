import { EChatMessageType } from 'constants/EChatMessageType';
import React, { useEffect, useRef, useState } from 'react';
import { useParams } from 'react-router-dom';
import styles from './ConferenceChat.module.scss';
import { ConferenceChatFormConnected } from './ConferenceChatForm';
import { ConferenceChatHeader } from './ConferenceChatHeader/ConferenceChatHeader';
import { ConferenceChatMessagesConnected } from './ConferenceChatMessages';
import { ConferenceChatScrollAlertButton } from './ConferenceChatScrollAlertButton';
import { IConferenceChatContext, IDispatchProps, IOwnProps, IStateProps } from './types';
import { ConferenceChatContext, isMessageCounted } from './utils';
import { classNames } from 'utils/classNames';

export const ConferenceChat: React.FC<IStateProps & IDispatchProps & IOwnProps> = ({
    chatMessages,
    toggleChat,
    chatAutoScroll,
    setChatAutoScroll,
    historyMode,
    isMobile,
    isUltraSmallScreen,
    showChat,
    exitConference,
}) => {
    const chatMessagesContainerWrapper = useRef<HTMLDivElement>(null);
    const [scrollBarVisible, setScrollBarVisible] = useState<boolean>(false);
    const [prevChatMessages, setPrevChatMessages] = useState(chatMessages);
    const [newMessagesCounter, setNewMessagesCounter] = useState<number>(0);

    const showScrollAlertButton = newMessagesCounter && scrollBarVisible && !chatAutoScroll;
    let sessionId;
    let conferenceId;

    if (historyMode) {
        const { session, id } = useParams<{ session?: string; id?: string }>();
        sessionId = session;
        conferenceId = id;
    }

    const isScrollAtBottom = (container: HTMLElement) => {
        const formattedScrollTop = Number(container.scrollTop.toFixed(0));
        return container.scrollHeight - formattedScrollTop === container.clientHeight;
    };

    const scrollChat = (): void => {
        const wrapperScrollHeight: number | undefined =
            chatMessagesContainerWrapper.current?.scrollHeight;
        if (wrapperScrollHeight) {
            chatMessagesContainerWrapper.current?.scrollTo(0, wrapperScrollHeight);
        }
    };

    const getNewMessagesCounter = (): number => {
        if (chatAutoScroll) {
            return 0;
        }
        if (isMessageCounted(chatMessages)) {
            return newMessagesCounter;
        }
        return newMessagesCounter + (chatMessages.length - prevChatMessages.length);
    };

    const checkScrollBar = (): void => {
        if (chatMessagesContainerWrapper.current) {
            chatMessagesContainerWrapper.current?.scrollHeight <=
            chatMessagesContainerWrapper.current?.clientHeight
                ? setScrollBarVisible(false)
                : setScrollBarVisible(true);
        }
    };

    const checkForDynamicContent = () => {
        const imgRegexList = [/\.jpg$/, /\.png$/];
        const files = chatMessages[chatMessages.length - 1]?.files;
        if (!files) {
            return true;
        }
        const isImage = files.some((file) => {
            return imgRegexList.find((regex) => {
                return file.name.match(regex);
            });
        });
        return !isImage;
    };

    const handleScroll = () => {
        if (chatMessagesContainerWrapper.current?.scrollTop) {
            if (!isScrollAtBottom(chatMessagesContainerWrapper.current)) {
                chatAutoScroll && setChatAutoScroll(false);
                return;
            }
            !chatAutoScroll && setChatAutoScroll(true);
            setNewMessagesCounter(0);
        }
    };

    const handleCrossClick = () => {
        toggleChat(false);
    };

    const handleBackClick = () => {
        toggleChat(false);
    };

    const handleAlertButtonClick = () => {
        scrollChat();
        setChatAutoScroll(true);
    };

    useEffect(() => {
        // Scroll down to the latest messages
        scrollChat();
    }, []);

    useEffect(() => {
        setNewMessagesCounter(getNewMessagesCounter);
        checkScrollBar();
        setPrevChatMessages(chatMessages);
        if (chatAutoScroll && checkForDynamicContent()) {
            scrollChat();
        }
    }, [chatMessages]);

    const chatMobileStyles =
        isMobile || isUltraSmallScreen
            ? classNames([styles.conferenceChat_mobile, showChat ? styles.conferenceChat_show : ''])
            : '';

    const contextValue: IConferenceChatContext = {
        handleImageLoad: () => {
            if (chatAutoScroll) {
                scrollChat();
            }
        },
    };
    return (
        <ConferenceChatContext.Provider value={contextValue}>
            <aside
                className={classNames([
                    styles.conferenceChat,
                    chatMobileStyles,
                ])}>
                <ConferenceChatHeader
                    historyMode={historyMode}
                    isMobile={isMobile}
                    isUltraSmallScreen={isUltraSmallScreen}
                    exitConference={exitConference}
                    onClickCross={handleCrossClick}
                    onClickBack={handleBackClick}
                />
                <div className={styles.conferenceChat__messagesWrapper}>
                    <div
                        className={styles.conferenceChat__messagesContainerWrapper}
                        ref={chatMessagesContainerWrapper}
                        onScroll={handleScroll}>
                        <ConferenceChatMessagesConnected
                            chatMessages={chatMessages}
                            sessionId={sessionId}
                            conferenceId={conferenceId}
                        />
                    </div>
                    {showScrollAlertButton && !historyMode && (
                        <ConferenceChatScrollAlertButton
                            newMessagesCounter={newMessagesCounter}
                            clickHandler={handleAlertButtonClick}
                        />
                    )}
                </div>
                {!historyMode && (
                    <ConferenceChatFormConnected
                        setChatAutoScroll={(chatAutoScrollState) =>
                            setChatAutoScroll(chatAutoScrollState)
                        }
                        chatAutoScrollState={chatAutoScroll}
                    />
                )}
            </aside>
        </ConferenceChatContext.Provider>
    );
};
