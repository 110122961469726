import { appHistory } from 'appHistory';
import { connect } from 'react-redux';
import { logoutThunkAction } from 'thunk/auth/logoutThunkAction';
import { TAppDispatch } from 'types/app/TAppDispatch';
import { TAppState } from 'types/app/TAppStore';
import { isReleaseVersion } from 'utils/isReleaseVersion';
import { HomePageHeader } from './HomePageHeader';
import { IDispatchProps, IStateProps } from './types';

const mapStateToProps = (state: TAppState): IStateProps => ({
    accountPageLocation: appHistory.location.pathname.match(/account/g) !== null,
    canUserRegister: state.applicationConfiguration.config.ldap,
    rememberMeRequestStatus: state.auth.rememberMeRequestStatus,
});

const mapDispatchToProps = (dispatch: TAppDispatch): IDispatchProps => ({
    logout: () => dispatch(logoutThunkAction()),
    goToAccount: () => appHistory.push('/account/profile'),
    goToHome: () => appHistory.push('/'),
    goToRegister: () => appHistory.push('/register'),
    goToLogin: () => appHistory.push('/login'),
});

export const HomePageHeaderConnected = connect(mapStateToProps, mapDispatchToProps)(HomePageHeader);
