import { IConferenceUser } from 'types/IConferenceUser';
import { IConferenceInvitedUserFromServer } from 'types/server/IConferenceInvitedUserFromServer';

export const convertInvitedUserFromServerToParticipant = (invitedUsers: IConferenceInvitedUserFromServer[]): IConferenceUser[] =>
    invitedUsers.map(({ email = '', avatar, firstName = '', id: userId, lastName = '' }) => ({
        name: `${firstName} ${lastName}`,
        email,
        participantId: '',
        userId,
        etag: avatar ? avatar.replace('etag=', '') : '',
    }));
