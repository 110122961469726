import { setConferenceExitConfirmationDialogVisibilityAction } from 'actions/applicationView/setConferenceExitConfirmationDialogVisibilityAction';
import { setConferenceInfoFrameVisibility } from 'actions/applicationView/setConferenceInfoFrameVisibility';
import { connect } from 'react-redux';
import { TAppDispatch } from 'types/app/TAppDispatch';
import { TAppState } from 'types/app/TAppStore';
import { ConferencePageMobileHeader } from './ConferencePageMobileHeader';
import { IDispatchProps } from './types';

const mapStateToProps = (state: TAppState) => ({
    showConferenceInfoFrame: state.applicationView.showConferenceInfoFrame,
});

const mapDispatchToProps = (dispatch: TAppDispatch): IDispatchProps => ({
    setConferenceInfoVisibility: (newState) => dispatch(setConferenceInfoFrameVisibility(newState)),
    displayExitDialog: () => dispatch(setConferenceExitConfirmationDialogVisibilityAction(true)),
});

export const ConferencePageMobileHeaderConnected = connect(
    mapStateToProps,
    mapDispatchToProps,
)(ConferencePageMobileHeader);
