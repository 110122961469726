import { FC, useRef, useState } from 'react';
import { classNames } from 'utils/classNames';
import { IOwnProps } from './types';
import styles from './Input.module.scss'

export const Input: FC<IOwnProps> = ({
    icon,
    className,
    autoFocus,
    wrapperClassName,
    ...props
}) => {
    const [isFocus, setFocus] = useState(autoFocus)
    const input = useRef<HTMLInputElement>(null)
    return (
        <div
            className={classNames([isFocus && styles.active, styles.wrapper, wrapperClassName])}
            onClick={() => !isFocus && input.current && input.current.focus()}
        >
            {icon}
            <input
                className={classNames([styles.input, className])}
                onFocus={() => setFocus(true)}
                onBlur={() => setFocus(false)}
                autoFocus={autoFocus}
                ref={input}
                {...props}
            />
        </div>
    )
}
