import { createAction } from '@reduxjs/toolkit';
import { EConferencesActions } from 'constants/actions/EConferencesActions';
import { IConferenceParticipantFromServer } from 'types/server/IConferenceParticipantFromServer';

interface IPayload {
    users:  IConferenceParticipantFromServer[]
    id: string
}

export const setConferencesParticipantsAction = createAction<IPayload>(
    EConferencesActions.SET_CONFERENCES_PARTICIPANTS,
);
