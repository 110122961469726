import { appHistory } from 'appHistory';
import { connect } from 'react-redux';
import { getCurrentUserProfileDataThunkAction } from 'thunk/auth/getCurrentUserProfileDataThunkAction';
import { showNotifyThunkAction } from 'thunk/notify/showNotifyThunkAction';
import { confirmEmailChangeThunkAction } from 'thunk/profile/confirmEmailChangeThunkAction';
import { TAppDispatch } from 'types/app/TAppDispatch';
import { TAppState } from 'types/app/TAppStore';
import { ConfirmEmailChangePage } from './ConfirmEmailChangePage';
import { IDispatchProps, IStateProps } from './types';

const mapStateToProps = (state: TAppState): IStateProps => ({
    loggedIn: state.auth.currentUser !== null,
});

const mapDispatchToProps = (dispatch: TAppDispatch): IDispatchProps => ({
    sendRequestToConfirmEmailChange: (token) => dispatch(confirmEmailChangeThunkAction(token)),
    showNotification: (params) => dispatch(showNotifyThunkAction(params)),
    goToProfile: () => appHistory.replace('/account/profile'),
    goToLogin: () => appHistory.replace('/login?path=/account/profile'),
    updateProfile: () => dispatch(getCurrentUserProfileDataThunkAction()),
    goToHome: () => appHistory.replace('/'),
});

export const ConfirmEmailChangePageConnected = connect(
    mapStateToProps,
    mapDispatchToProps,
)(ConfirmEmailChangePage);
