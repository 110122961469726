import { Trans } from '@lingui/react';
import { Button } from 'components/common/Button';
import { EPaths } from 'constants/EPaths';
import { redirect } from 'utils/redirect';
import styles from './UpcomingConferencesEmpty.module.scss';

export const UpcomingConferencesEmpty: React.FC = () => {
    return (
        <div className={styles.upcomingConferencesEmpty}>
            <span><Trans id='conferenceList.emptyUpcomingConferences' message='Нет предстоящих конференций'/></span>
            <Button
                styleType='primaryActionNew'
                className={styles.upcomingConferencesEmpty__btn}
                clickHandler={() => redirect(`${EPaths.SCHEDULE_CONFERENCE}`)}>
                <Trans id='Schedule a conference'/>
            </Button>
        </div>
    );
};
