import { createAsyncThunk } from '@reduxjs/toolkit';
import { setCurrentLanguageAction } from 'actions/applicationView/setCurrentLanguageAction';
import { setCurrentUserDataAction } from 'actions/auth/setCurrentUserDataAction';
import { setRememberMeRequestStatusAction } from 'actions/auth/setRememberMeRequestStatusAction';
import { getNetworkService } from 'api/getNetworkService';
import { getCurrentUserProfileDataApiCall } from 'api/profile/getCurrentUserProfileDataApiCall';
import { updateProfileAvatarApiCall } from 'api/profile/updateProfileAvatarApiCall';
import { updateProfileInfoApiCall } from 'api/profile/updateProfileInfoApiCall';
import { batch } from 'react-redux';
import { IRequestStatus } from 'types/api/INetworkService';
import { TAppState } from 'types/app/TAppStore';
import { TLanguages } from 'types/TLanguages';
import { convertUserDataFromServer } from 'utils/users/convertUserDataFromServer';

export interface IUpdateProfilePayload {
    firstName: string;
    lastName: string;
    phone: string;
    avatar: FormData | null;
    timeZone: string;
    locale: TLanguages;
}

export const updateProfileInfoThunkAction = createAsyncThunk<
    IRequestStatus,
    Partial<IUpdateProfilePayload>
>('updateProfileInfo', async ({ avatar, ...data }, { dispatch, getState }) => {
    const {
        auth: { currentUser },
    } = getState() as TAppState;
    interface data {
        [key: string]: string;
    }
    const dataToSend: data = {};
    const oldLanguageInProfile = currentUser?.language;

    const userData: Partial<IUpdateProfilePayload> = {
        firstName: currentUser?.name?.first,
        lastName: currentUser?.name?.last,
        phone: currentUser?.contact.phone,
        timeZone: currentUser?.timeZone,
        locale: currentUser?.language,
    };

    for (const field in data) {
        const f = field as keyof IUpdateProfilePayload;
        if (f === 'avatar') {
            continue;
        }
        const fieldData = data[f];
        if (fieldData !== userData[f] && f !== 'phone' ? fieldData : f in data) {
            dataToSend[f] = fieldData || '';
        }
    }
    if (Object.keys(dataToSend).length !== 0) {
        const updateProfileResponse = await updateProfileInfoApiCall(
            getNetworkService(),
            dataToSend,
        );
        if (!updateProfileResponse.success) {
            return updateProfileResponse;
        }
    }

    if (typeof avatar !== 'undefined') {
        const updateAvatarResponse = await updateProfileAvatarApiCall(getNetworkService(), {
            avatar,
        });
        if (!updateAvatarResponse.success) {
            return updateAvatarResponse;
        }
    }
    const response = await getCurrentUserProfileDataApiCall(getNetworkService());
    if (response.success && !response.data?.anonymous) {
        const userData = convertUserDataFromServer(response.data);
        batch(() => {
            dispatch(setCurrentUserDataAction(userData));
            if (userData.language && userData.language !== oldLanguageInProfile) {
                dispatch(setCurrentLanguageAction(userData.language));
            }
        });
    } else {
        dispatch(setRememberMeRequestStatusAction('error'));
    }
    return { success: true };
});
