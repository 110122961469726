import { TAppState } from 'types/app/TAppStore';

export const getNewPermissionsData = ({
    resultObj,
    getState,
    participantId,
    isRemovedUser,
    newPermissionProps: { streamType, permissionType },
}: {
    resultObj: any;
    getState: any;
    participantId: string;
    isRemovedUser: boolean;
    newPermissionProps: {
        streamType: 'audio' | 'video';
        permissionType: 'forbiddenStreams' | 'allowedStreams';
    };
}) => {
    const {
        janus: { localStreamProps, publishers, ownParticipantId },
    } = getState() as TAppState;
    let audioPermitted = false;
    let videoPermitted = false;

    if (ownParticipantId === participantId) {
        audioPermitted = localStreamProps.audioPermitted;
        videoPermitted = localStreamProps.videoPermitted;
    } else {
        const publisher = publishers[participantId];
        if (!publisher) {
            return;
        }
        audioPermitted = publisher.audioVideoProps.audioPermitted;
        videoPermitted = publisher.audioVideoProps.videoPermitted;
    }
    if (permissionType === 'forbiddenStreams') {
        if (isRemovedUser) {
            if (streamType === 'audio' && !audioPermitted) {
                resultObj[participantId] = {
                    ...resultObj[participantId],
                    ...{ audioPermitted: true },
                };
            }
            if (streamType === 'video' && !videoPermitted) {
                resultObj[participantId] = {
                    ...resultObj[participantId],
                    ...{ videoPermitted: true },
                };
            }
        } else {
            if (streamType === 'audio' && audioPermitted) {
                resultObj[participantId] = {
                    ...resultObj[participantId],
                    ...{ audioPermitted: false },
                };
            }
            if (streamType === 'video' && videoPermitted) {
                resultObj[participantId] = {
                    ...resultObj[participantId],
                    ...{ videoPermitted: false },
                };
            }
        }
    } else {
        if (isRemovedUser) {
            if (streamType === 'audio' && audioPermitted) {
                resultObj[participantId] = {
                    ...resultObj[participantId],
                    ...{ audioPermitted: false },
                };
            }
            if (streamType === 'video' && videoPermitted) {
                resultObj[participantId] = {
                    ...resultObj[participantId],
                    ...{ videoPermitted: false },
                };
            }
        } else {
            if (streamType === 'audio' && !audioPermitted) {
                resultObj[participantId] = {
                    ...resultObj[participantId],
                    ...{ audioPermitted: true },
                };
            }
            if (streamType === 'video' && !videoPermitted) {
                resultObj[participantId] = {
                    ...resultObj[participantId],
                    ...{ videoPermitted: true },
                };
            }
        }
    }
};
