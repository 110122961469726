import { connect } from 'react-redux';
import { getCurrentUserEmailSelector } from 'selectors/getCurrentUserEmailSelector';
import { showNotifyThunkAction } from 'thunk/notify/showNotifyThunkAction';
import { changeEmailThunkAction } from 'thunk/profile/changeEmailThunkAction';
import { TAppDispatch } from 'types/app/TAppDispatch';
import { TAppState } from 'types/app/TAppStore';
import { EmailChangeDialogMobile } from './EmailChangeDialogMobile';
import { IDispatchProps, IStateProps } from './types';

const mapStateToProps = (state: TAppState): IStateProps => ({
    currentEmail: getCurrentUserEmailSelector(state),
});

const mapDispatchToProps = (dispatch: TAppDispatch): IDispatchProps => ({
    changeEmail: (data) => dispatch(changeEmailThunkAction(data)),
    showNotification: (params) => dispatch(showNotifyThunkAction(params)),
});

export const EmailChangeDialogMobileConnected = connect(
    mapStateToProps,
    mapDispatchToProps,
)(EmailChangeDialogMobile);
