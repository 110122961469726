import { t, Trans } from '@lingui/macro';
import { format } from 'date-fns';
import { ReactElement } from 'react';
import { IUserData } from 'types/user/IUserData';
import { getSeriesIdFromConferenceInfo } from 'utils/conferences/getSeriesIdFromConferenceInfo';
import { getStartDateFromConferenceInfo } from 'utils/conferences/getStartDateFromConferenceInfo';
import { useConfirmDialog } from './useConfirmDialog';

interface IConferenceDeleteDialogCallbackPayload {
    timeStart: string;
    id: string;
    seriesId?: string;
    startDateOfRecurrence?: string;
    name: string;
}

export const useConferenceDeleteDialog = (
    currentUser: IUserData | null,
    deleteConference: (id: string) => void,
): [
    (conferenceData: IConferenceDeleteDialogCallbackPayload) => void,
    () => ReactElement | null,
] => {
    const [setDialogOptions, confirmDialogRender] = useConfirmDialog();
    return [
        ({ timeStart, id, seriesId, startDateOfRecurrence, name }: IConferenceDeleteDialogCallbackPayload) => {
            const startDate = getStartDateFromConferenceInfo(timeStart || startDateOfRecurrence, currentUser?.timeZone);
            const recurringConferenceId = getSeriesIdFromConferenceInfo({id, seriesId, startDateOfRecurrence})
            if (!recurringConferenceId) {
                setDialogOptions({
                    handlePrimaryAction: () => deleteConference(id),
                    handleSecondaryAction: () => setDialogOptions(null),
                    headerText: t({
                        id: 'conferenceDialog.header',
                        message: 'Удалить конференцию',
                    }),
                    bodyText: t({
                        id: 'delete.confirm',
                        message: 'Вы уверены что хотите удалить конференцию?',
                    }),
                    primaryText: t({ id: 'Delete' }),
                    secondaryText: t({ id: 'Cancel' }),
                    theme: 'red',
                });
                return;
            }
            setDialogOptions({
                handlePrimaryAction: () => deleteConference(id),
                handleSecondaryAction: () => deleteConference(recurringConferenceId),
                headerText: t({
                    id: 'conferenceDialog.header',
                    message: 'Удалить конференцию',
                }),
                bodyText: (
                    <Trans>
                        Удалить все предстоящие конференции <b>{name}</b> или только запланированную
                        на {format(startDate, 'dd.MM.yyyy')}?
                    </Trans>
                ),
                primaryText: t({
                    id: 'ConfirmDialog.action.only.specific',
                    message: 'Только {0}',
                    values: {
                        '0': format(startDate, 'dd.MM.yyyy'),
                    },
                }),
                secondaryText: t({ id: 'Delete all' }),
                theme: 'red',
            });
        },
        confirmDialogRender,
    ];
};
