import { IOwnProps, IFormProps } from './types';
import styles from './PublicKeyForm.module.scss';
import { Form, FormRenderProps } from 'react-final-form';
import { Trans } from '@lingui/react';
import { InputField } from 'admin-app/components/common/InputField';
import { Button } from 'admin-app/components/common/Button';
import { FORM_ERROR } from 'final-form';
import { getErrorMessage } from './utils';

export const PublicKeyForm: React.FC<IOwnProps> = ({ currentValue, handleSubmit }) => {
    const renderForm = ({
        handleSubmit,
        submitError,
        submitting,
        dirtySinceLastSubmit,
        pristine,
    }: FormRenderProps<IFormProps>) => (
        <form onSubmit={handleSubmit}>
            <label className={styles.form__inputLabel} htmlFor='key'>
                <Trans id='admin.publicKey' message='Публичный ключ' />
            </label>
            <InputField
                id='key'
                name='key'
                className={styles.form__input}
                wrapperClassName={styles.form__inputWrapper}
            />
            {!dirtySinceLastSubmit && submitError && (
                <span className={styles.form__submitError}>{submitError}</span>
            )}
            <Button
                type='submit'
                className={styles.form__submit}
                disabled={submitting || (!dirtySinceLastSubmit && submitError) || pristine}>
                <Trans id='admin.publicKey.save' message='Сохранить ключ' />
            </Button>
        </form>
    );

    const getInitial = () => {
        return {
            key: currentValue || undefined,
        };
    };

    const onSubmit = async (values: IFormProps) => {
        const error = await handleSubmit(values.key || '');
        if (error) {
            return { [FORM_ERROR]: getErrorMessage(error) };
        }
    };
    return <Form render={renderForm} onSubmit={onSubmit} initialValues={getInitial()} />;
};
