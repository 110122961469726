import { deleteFromRecordDownloadListAction } from 'actions/records/deleteFromRecordDownloadListAction';
import { modifyToRecordDownloadListAction } from 'actions/records/modifyToRecordDownloadListAction';
import { setConferencesRecordsAction } from 'actions/records/setConferencesRecordsAction';
import { getPendingOperationStatusSelector } from 'selectors/getPendingOperationStatusSelector';
import { addRecordReadyNotificationThunkAction } from 'thunk/records/addRecordReadyNotificationThunkAction';
import { changeRecordsListFilterThunkAction } from 'thunk/records/changeRecordsListFilterThunkAction';
import { deleteConferenceRecordThunkAction } from 'thunk/records/deleteConferenceRecordThunkAction';
import { deleteRecordReadyNotificationThunkAction } from 'thunk/records/deleteRecordReadyNotificationThunkAction';
import { getConferenceRecordUrlThunkAction } from 'thunk/records/getConferenceRecordUrlThunkAction';
import { TAppDispatch } from 'types/app/TAppDispatch';
import { TAppState } from 'types/app/TAppStore';
import { IConferenceRecord } from 'types/IConferenceRecord';
import { addUsersTimeZoneToDate } from 'utils/dates/addUsersTimeZoneToDate';
import { convertDateToStringByTemplate } from 'utils/dates/convertDateToStringByTemplate';
import { convertStringToDateByTemplate } from 'utils/dates/convertStringToDateByTemplate';
import { IDispatchProps, IStateProps } from './types';
interface IGroupRecords {
    day: string;
    records: IConferenceRecord[];
}

export const getGroupedRecords = (conferencesRecords: IConferenceRecord[] | undefined) => {
    if (!conferencesRecords) return [];
    const filteredRecords = [...conferencesRecords].sort((a, b) => {
        const firstDateTime = addUsersTimeZoneToDate(
            convertStringToDateByTemplate(a.timeStart as string, 'yyyy-MM-dd HH:mm:ss.SSSSSS'),
        ).getTime();
        const secondDateTime = addUsersTimeZoneToDate(
            convertStringToDateByTemplate(b.timeStart as string, 'yyyy-MM-dd HH:mm:ss.SSSSSS'),
        ).getTime();
        return secondDateTime - firstDateTime;
    });

    const groupConferences: IGroupRecords[] = [];
    filteredRecords.forEach((record) => {
        const dayStart = convertDateToStringByTemplate(
            addUsersTimeZoneToDate(
                convertStringToDateByTemplate(
                    record.timeStart as string,
                    'yyyy-MM-dd HH:mm:ss.SSSSSS',
                ),
            ),
            'dd.MM.yyyy',
        );
        const targetIndex = groupConferences.findIndex((item) => item.day === dayStart);
        if (targetIndex < 0) {
            groupConferences.push({ day: dayStart, records: [record] });
        } else {
            groupConferences[targetIndex].records.push(record);
        }
    });
    return groupConferences;
};

interface IGroupedRecordsByConference {
    day: string;
    records: { [key: string]: IConferenceRecord[] };
}
export const getGroupedRecordsByConference = (
    conferencesRecords: IConferenceRecord[] | undefined,
) => {
    const groupedRecordsByDate = getGroupedRecords(conferencesRecords);
    const gropedRecordsByConference: IGroupedRecordsByConference[] = [];

    groupedRecordsByDate.forEach(({ day, records }) => {
        const filteredRecords: { [key: string]: IConferenceRecord[] } = {};
        records.forEach((record) => {
            filteredRecords[record.sessionId]
                ? filteredRecords[record.sessionId].push(record)
                : (filteredRecords[record.sessionId] = [record]);
        });
        gropedRecordsByConference.push({ day, records: filteredRecords });
    });
    return gropedRecordsByConference;
};

export const mapStateToProps = (state: TAppState): IStateProps => ({
    recordsList: state.conferencesRecords.conferencesRecords,
    filters: state.sessionSettings.recordsFilter,
    recordDownloadList: state.conferencesRecords.recordDownloadList,
    defaultOperationPending: getPendingOperationStatusSelector(state, 'defaultOperation'),
    currentUser: state.auth.currentUser,
});

export const mapDispatchToProps = (dispatch: TAppDispatch): IDispatchProps => ({
    reset: () => {
        dispatch(setConferencesRecordsAction([]));
    },
    changeFilter: (filters) => dispatch(changeRecordsListFilterThunkAction(filters)),
    getConferenceRecordData: (data) => dispatch(getConferenceRecordUrlThunkAction(data)),
    deleteRecord: (param) => dispatch(deleteConferenceRecordThunkAction(param)),
    modifyRecordDownloadList: (data) => dispatch(modifyToRecordDownloadListAction(data)),
    deleteRecordDownloadList: (id) => dispatch(deleteFromRecordDownloadListAction(id)),
    addRecordReadyNotification: (recordId) =>
        dispatch(addRecordReadyNotificationThunkAction(recordId)),
    deleteRecordReadyNotification: (recordId) =>
        dispatch(deleteRecordReadyNotificationThunkAction(recordId)),
});
