import { createAsyncThunk } from '@reduxjs/toolkit';
import { storeLdapUsers } from 'actions/users/storeLdapUsers';
import { getNetworkService } from 'api/getNetworkService';
import { getLdapUsersDataByEmailApiCall } from 'api/users/getLdapUsersDataByEmailApiCall';
import { getLdapUsersDataByNameApiCall } from 'api/users/getLdapUsersDataByNameApiCall';
import { IRequestResult } from 'types/api/INetworkService';
import { IUserLdapDataFromServer } from 'types/server/IUserLdapDataFromServer';
import { IUserLdapData } from 'types/user/IUserLdapData';
import { convertLdapUserDataFromServer } from 'utils/users/convertLdapUserDataFromServer';

interface IPayload {
    type: 'name' | 'email';
    value: string;
}

export const getLdapUsersDataThunkAction = createAsyncThunk(
    'getLdapUsersData',
    async ({ type, value }: IPayload, { dispatch }): Promise<IUserLdapData[] | undefined> => {
        let response: IRequestResult<any>;
        if (type === 'email') {
            response = await getLdapUsersDataByEmailApiCall(getNetworkService(), value);
        } else {
            response = await getLdapUsersDataByNameApiCall(getNetworkService(), value);
        }
        if (response.success) {
            if (response.data) {
                const ldapUsersFromServer = response.data as IUserLdapDataFromServer[];
                const ldapUsers = ldapUsersFromServer.map((user) =>
                    convertLdapUserDataFromServer(user),
                );
                dispatch(storeLdapUsers(ldapUsers));
                return ldapUsers;
            }
        }
    },
);
