import { showAudioDialogPermissionAction } from 'actions/applicationView/showAudioDialogPermissionAction';
import { updateParticipantsFrameAction } from 'actions/applicationView/updateParticipantsFrameAction';
import { setJanusStreamPropsAction } from 'actions/janus/setJanusStreamPropsAction';
import { connect } from 'react-redux';
import { setFramesToFullscreenThunkAction } from 'thunk/appView/setFramesToFullscreenThunkAction';
import { updateSubscriptionsVideoStreamsAction } from 'thunk/appView/updateSubscriptionsVideoStreamsAction';
import { TAppDispatch } from 'types/app/TAppDispatch';
import { TAppState } from 'types/app/TAppStore';
import { ParticipantsView } from './ParticipantsView';
import { IDispatchProps, IStateProps } from './types';

const mapStateToProps = (state: TAppState): IStateProps => ({
    anonymousUser: state.auth.anonymousUser,
    appSettings: state.auth.appSettings,
    currentConferenceParticipants: state.conferenceSession.currentConferenceParticipants,
    currentParticipantId: state.janus.ownParticipantId,
    currentUser: state.auth.currentUser,
    currentUserIsOwner: state.conferenceSession.currentUserIsOwner,
    displayedAudioFrames: state.applicationView.displayedAudioFrames,
    displayedVideoFrames: state.applicationView.displayedVideoFrames,
    fullScreenFrames: state.applicationView.fullScreenFrames,
    isMobile: state.applicationView.deviceProps.isTabletOrMobile,
    isOwnVideoStream:
        state.janus.localStreamProps.screenShared || state.janus.localStreamProps.useVideo,
    ownAudioVideoProps: state.janus.localStreamProps,
    ownEmotionToShow: state.janus.ownEmotionToShow,
    ownParticipantId: state.janus.ownParticipantId,
    ownStream: state.janus.ownStream,
    ownTalkingStatus: state.janus.ownTalkingStatus,
    participantsAudio: state.applicationView.participantsAudio,
    participantsEmotion: state.applicationView.participantsEmotion,
    participantsTalking: state.applicationView.participantsTalking,
    participantsVideo: state.applicationView.participantsVideo,
    priorityFrame: state.applicationView.fullScreenFrames[0],
    publishers: state.janus.publishers,
    showChat: state.applicationView.showChat,
    streams: state.janus.streams,
    videoStreamsSubscriptionsUpdate: state.applicationView.videoStreamsSubscriptionsUpdate,
    participantsLocalMuteStatus: state.applicationView.participantsLocalMuteStatus,
});

const mapDispatchToProps = (dispatch: TAppDispatch): IDispatchProps => ({
    setLocalStreamProps: (props) => dispatch(setJanusStreamPropsAction(props)),
    setPriorityFrames: (data) => dispatch(setFramesToFullscreenThunkAction(data)),
    updateParticipantsFrame: (payload) => dispatch(updateParticipantsFrameAction(payload)),
    updateSubscriptionsVideoStreams: () => dispatch(updateSubscriptionsVideoStreamsAction()),
});

export const ParticipantsViewConnected = connect(
    mapStateToProps,
    mapDispatchToProps,
)(ParticipantsView);
