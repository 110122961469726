import { t } from '@lingui/macro';
import { Trans } from '@lingui/react';
import LogoImg from 'assets/LogoWhite.svg';
import { Button } from 'components/common/Button';
import { CircleLoader } from 'components/common/CircleLoader';
import { ConferenceOwnerInfo } from 'components/common/ConferenceOwnerInfo';
import { ConferenceRecordPlayerConnected } from 'components/ConferenceRecordPlayer';
import { ConferenceChatConnected } from 'pages/templates/ConferencePageTemplate/ConferenceChat';
import { useEffect, useState } from 'react';
import { IConferenceRecord } from 'types/IConferenceRecord';
import { addUsersTimeZoneToDate } from 'utils/dates/addUsersTimeZoneToDate';
import { convertDateToStringByTemplate } from 'utils/dates/convertDateToStringByTemplate';
import { getEscCloseDropdown } from 'utils/escCloseDropdown';
import { convertRecordDuration } from 'utils/records/convertRecordDuration';
import styles from './ConferenceReport.module.scss';
import { ParticipantsList } from './ParticipantsList';
import { RecordLoader } from './RecordLoader';
import { IDispatchProps, IOwnProps, IStateProps } from './types';
import { getRecordPreviewUrl, showRecordByDefault } from './utils';
import { ReportRecord } from './ReportRecord';
import { convertFromSecondsToHoursMinutesSeconds } from 'utils/convertFromSecondsToHoursMinutesSeconds';

export const ConferenceReport: React.FC<IOwnProps & IDispatchProps & IStateProps> = ({
    closeReport,
    goToConferenceListPage,
    resetExistAndExitParticipants,
    prepareReport,
    goToHomePage,
    conferenceId,
    sessionId,
    reports,
    records,
    defaultOperationPending,
}) => {
    const [showRecordPlayer, setShowRecordPlayer] = useState<{
        showPlayer: boolean;
        record: IConferenceRecord | null;
    }>({ showPlayer: showRecordByDefault(), record: null });

    const report = reports.find((report) => report.id === sessionId);
    const currentRecords = records.filter((record) => record.sessionId === sessionId);

    useEffect(() => {
        prepareReport({ conferenceId, sessionId });
        return () => {
            resetExistAndExitParticipants();
            closeReport();
        };
    }, []);

    const onEscClick = (e: KeyboardEvent) => {
        if (e.code === 'Escape') {
            if (!getEscCloseDropdown()) {
                goToConferenceListPage();
            }
        }
    };

    useEffect(() => {
        document.addEventListener('keydown', onEscClick);
        return () => {
            document.removeEventListener('keydown', onEscClick);
        };
    });

    const recordPreviewRender = (record: IConferenceRecord) => {
        const recordMetaData = record.record;
        const formattedDuration = recordMetaData
            ? convertFromSecondsToHoursMinutesSeconds(recordMetaData.duration)
            : null;
        return (
            <div
                className={styles.preview}
                onClick={() => setShowRecordPlayer({ showPlayer: true, record })}>
                <img src={getRecordPreviewUrl(conferenceId, sessionId, record.id)} alt='preview' />
                {formattedDuration && (
                    <div className={styles.duration}>
                        {`${formattedDuration.hours}:${formattedDuration.minutes < 10 ? 0 : ''}${
                            formattedDuration.minutes
                        }:${formattedDuration.seconds < 10 ? 0 : ''}${formattedDuration.seconds}`}
                    </div>
                )}
            </div>
        );
    };

    return (
        <div className={styles.wrapper}>
            <div
                className={`${styles.background} ${
                    showRecordPlayer.showPlayer && showRecordPlayer.record
                        ? styles.background_player
                        : ''
                }`}
            />
            {showRecordPlayer.showPlayer && showRecordPlayer.record && (
                <div>
                    <ConferenceRecordPlayerConnected
                        record={showRecordPlayer.record}
                        closeHandle={() => setShowRecordPlayer({ showPlayer: false, record: null })}
                    />
                </div>
            )}
            {!defaultOperationPending && report && (
                <div className={styles.report}>
                    <div className={styles.header}>
                        <img src={LogoImg} alt='logo' onClick={goToHomePage} />
                    </div>
                    <div className={styles.content}>
                        <div className={styles.info}>
                            <div className={styles.name}>
                                {report.name} (
                                {convertDateToStringByTemplate(
                                    addUsersTimeZoneToDate(report.timeEnd),
                                    'dd/MM/yyyy',
                                )}
                                )
                            </div>
                            <div className={styles.owner}>
                                <div className={styles.content__header}>
                                    <Trans id='Conference owner' message='Создатель конференции' />
                                </div>
                                <div className={styles.owner__component}>
                                    <ConferenceOwnerInfo owner={report.creator} anonymous={false} />
                                </div>
                            </div>
                            <div>
                                <div className={styles.content__header}>
                                    <Trans id='report.records' message='Записи конференции' />
                                </div>
                                {currentRecords.map((record) => (
                                    <ReportRecord
                                        key={record.id}
                                        record={record}
                                        reportData={report}
                                        recordPreviewRender={recordPreviewRender}
                                    />
                                ))}
                            </div>

                            <div className={styles.participants}>
                                <div className={styles.content__header}>
                                    <Trans id='Participants' message='Участники' />
                                </div>
                                <div className={styles.participants__component}>
                                    <ParticipantsList participants={report.participants} />
                                </div>
                            </div>
                        </div>
                        <div className={styles.history}>
                            <ConferenceChatConnected historyMode={true} />
                        </div>
                    </div>
                    <div className={styles.footer}>
                        <Button
                            styleType='primaryAction'
                            className={styles.button}
                            caption={t({ id: 'Exit', message: 'Выйти' })}
                            clickHandler={goToConferenceListPage}
                        />
                    </div>
                </div>
            )}
            {defaultOperationPending && <CircleLoader color='white' className={styles.loader} />}
        </div>
    );
};
