import { createAsyncThunk } from '@reduxjs/toolkit';
import { addToWaitingListAction } from 'actions/lobby/addToWaitingListAction';
import { deleteFromWaitingListAction } from 'actions/lobby/deleteFromWaitingListAction';
import { getConferencePermissionJoinTopic } from 'services/socketService/getConferencePermissionJoinTopic';
import { addSubscription, getSocketServiceState } from 'services/socketService/socketService';
import { setWaitingListThunkAction } from 'thunk/lobby/setWaitingListThunkAction';
import { ISocketJoinToConferenceResponse } from 'types/socket/ISocketJoinToConferenceResponse';
import { getConnectToSocket } from 'utils/connectToSocketProxy';
import { log } from 'utils/log';

export const subscribeToConferencePermissionJoinThunkAction = createAsyncThunk(
    'subscribeToConferencePermissionJoin',
    (conferenceId: string, { dispatch }) => {
        getConnectToSocket()(() => {
            dispatch(setWaitingListThunkAction(conferenceId));
            const { stompClient } = getSocketServiceState();
            if (!stompClient) {
                log.error('Stomp client does not init.');
                return;
            }
            addSubscription(
                'subscribeToConferencePermissionJoin',
                stompClient.subscribe(getConferencePermissionJoinTopic(conferenceId), (message) => {
                    const data: ISocketJoinToConferenceResponse = JSON.parse(message.body);
                    if (data.actionType === 'JOIN') {
                        dispatch(
                            addToWaitingListAction({
                                name: data.userName,
                                participantId: data.participantId,
                            }),
                        );
                    } else if (['LEAVE', 'ALLOW', 'FORBID'].includes(data.actionType)) {
                        dispatch(deleteFromWaitingListAction(data.participantId));
                    }
                }),
            );
        }, 'subscribeToConferencePermissionJoin');
    },
);
