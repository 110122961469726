import { createAsyncThunk } from '@reduxjs/toolkit';
import { editWebHookAction } from 'admin-app/actions/webHooks/editWebHookAction';
import { editWebHookApiCall } from 'admin-app/api/webHooks/editWebHookApiCall';
import { EWebHooksActions } from 'admin-app/constants/thunk/EWebHooksActions';
import { IApiCallErrorData } from 'admin-app/types/IApiCallErrorData';
import { getNetworkService } from 'api/getNetworkService';

interface IPayload {
    id: number;
    url: string;
    token: string;
}

export const editWebHookThunkAction = createAsyncThunk<IApiCallErrorData | undefined, IPayload>(
    EWebHooksActions.EDIT_HOOK,
    async (data, { dispatch }) => {
        const {
            success,
            data: editedHook,
            errorCode,
            errorDescription,
        } = await editWebHookApiCall(getNetworkService(), data);
        if (!success) {
            return { errorCode, errorDescription };
        }
        dispatch(editWebHookAction(editedHook));
    },
);
