import { createAction } from '@reduxjs/toolkit';
import { EConferencesActions } from 'constants/actions/EConferencesActions';
import { IConferenceChatMessage } from 'types/chat/IConferenceChatMessage';

interface IPayload {
    messages: IConferenceChatMessage[];
    history?: boolean;
}

export const addConferenceChatMessagesAction = createAction<IPayload>(
    EConferencesActions.ADD_CONFERENCE_CHAT_MESSAGES,
);
