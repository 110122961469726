import { ConferenceShareDialog } from 'components/ConferenceShareDialog';
import { ReactElement, useState } from 'react';
import { useSelector } from 'react-redux';
import { TAppState } from 'types/app/TAppStore';
import { IUpcomingConferenceInfo } from 'types/conferencesList/IUpcomingConferenceInfo';

export const useConferenceShareDialog = (): [
    (newState: IUpcomingConferenceInfo | null) => void,
    ReactElement | null,
] => {
    const [conferenceInfo, setConferenceInfo] = useState<IUpcomingConferenceInfo | null>(null);
    const currentUserId = useSelector((state: TAppState) => state.auth.currentUser?.id);
    const isOwner = conferenceInfo?.creator.userId === currentUserId;
    return [
        (newState: IUpcomingConferenceInfo | null) => {
            setConferenceInfo(newState);
        },
        conferenceInfo ? (
            <ConferenceShareDialog
                conferenceInfo={conferenceInfo}
                isOwner={isOwner}
                closeDialog={() => setConferenceInfo(null)}
            />
        ) : null,
    ];
};
