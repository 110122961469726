import { t } from '@lingui/macro';
import { Trans } from '@lingui/react';
import AccessDeniedImg from 'assets/AccessDenied.svg';
import { Button } from 'components/common/Button';
import { CircleLoader } from 'components/common/CircleLoader';
import { MobilePageTemplate } from 'components/MobilePageTemplate';
import { useEffect } from 'react';
import styles from './ConferenceFinishedPageMobile.module.scss';
import { IDispatchProps, IOwnProps, IStateProps } from './types';

export const ConferenceFinishedPageMobile: React.FC<IStateProps & IOwnProps & IDispatchProps> = ({
    conferencesConnectInfo,
    id,
    goToHome,
    getConferenceConnectInfo,
    defaultOperationPending,
}) => {
    const conferenceConnectInfo = conferencesConnectInfo[id];

    useEffect(() => {
        const checkConference = async () => {
            const { payload: conference } = await getConferenceConnectInfo(id);
            if (!conference) {
                goToHome();
            }
        };
        if (!conferenceConnectInfo) {
            checkConference();
        }
    }, []);

    return (
        <MobilePageTemplate
            title={conferenceConnectInfo?.name || ''}
            rootClassName={styles.pageWrapper}
            headerClassName={styles.pageWrapper__header}
            titleClassName={styles.pageWrapper__title}>
            <section className={styles.finishPage}>
                <div className={styles.finishPage__information}>
                    {defaultOperationPending && <CircleLoader color='white' />}
                    {!defaultOperationPending && (
                        <>
                            <img src={AccessDeniedImg} alt='End' />
                            <span>
                                <Trans
                                    id='The conference has ended'
                                    message='Конференция завершилась'
                                />
                            </span>
                        </>
                    )}
                </div>
                <Button
                    styleType='primaryAction'
                    caption={t({ message: 'Выход', id: 'Exit' })}
                    className={styles.finishPage__exitBtn}
                    type='submit'
                    clickHandler={goToHome}
                />
            </section>
        </MobilePageTemplate>
    );
};
