import { createAsyncThunk } from '@reduxjs/toolkit';
import { getNetworkService } from 'api/getNetworkService';
import { getChatMediaApiCall } from 'api/conference/getChatMediaApiCall';

interface IGetConferenceChatMediaFileThunkPayload {
    mediaFileId: string;
    conferenceId: string;
    sessionId: string;
}

export const getConferenceChatMediaFileThunkAction = createAsyncThunk(
    'getConferenceChatMediaFile',
    async (
        { mediaFileId, conferenceId, sessionId }: IGetConferenceChatMediaFileThunkPayload,
    ): Promise<ArrayBuffer | undefined> => {
        if (!mediaFileId || !conferenceId || !sessionId) {
            return;
        }

        const response = await getChatMediaApiCall(getNetworkService(), {
            mediaFileId,
            conferenceId,
            sessionId,
        });
        if (!response?.success) {
            return;
        }
        return response.data;
    },
);
