import { EUserRoles } from 'admin-app/constants/EUserRoles';
import { INetworkService, IRequestResult } from 'types/api/INetworkService';

export const getUserPrivilegesApiCall = (
    networkService: INetworkService,
    userId: string,
): Promise<IRequestResult<[EUserRoles.ADMIN | EUserRoles.ORGANIZER]>> => {
    return networkService.get({
        url: `/api/admin/users/${userId}/privileges`,
    });
};
