import { t } from '@lingui/macro';
import { isEmailValid } from 'utils/isEmailValid';
import { IPasteHandlerArgs } from './types';

export const fakeFieldPasteHandler = async({
    event,
    inputValues,
    setInvalidEmail,
    setFormField,
    setFakeFieldValue,
    setMessageAboutPasted,
}: IPasteHandlerArgs) => {
    event.preventDefault();
    setInvalidEmail({ isInvalid: false, value: '' });

    const pasteText = event.clipboardData.getData('text');
    const invalidEmails: string[] = [];
    const extractedEmails = pasteText.split(/\s|;|,/g);
    const validEmails = extractedEmails.filter((email) => {
        email.trim();
        const isValid = isEmailValid(email);
        if (!isValid && email.length) {
            invalidEmails.push(email);
        } else {
            return email;
        }
    });
    validEmails.length > 0 ? setFormField([...inputValues, ...validEmails]) : false;
    if (invalidEmails.length > 0) {
        // showNotification
        // ? showNotification(t({ id: 'Invalid emails', message: 'Недействительные email-адреса - {emails}', values: {'emails': invalidEmails.join(', ')}})): false;
        if (invalidEmails.length > 1) {
            setMessageAboutPasted(
                t({
                    id: 'messageAboutPastErrorEmails',
                    message:
                        '{number} успешно распознанных было применено, оставшаяся информация была сокпирована в буфер обмена',
                    values: { number: validEmails.length },
                }),
            );
            await navigator.clipboard.writeText(invalidEmails.join(', '));
        } else {
            setInvalidEmail({ isInvalid: true, value: invalidEmails[0] });
            setFakeFieldValue(invalidEmails[0]);
            setMessageAboutPasted('');
        }
    } else {
        setMessageAboutPasted('');
    }
};
