import { INetworkService } from 'types/api/INetworkService';

interface IPayload {
    id: number;
}

export const deleteWebHookApiCall = (networkService: INetworkService, { id }: IPayload) => {
    return networkService.delete({
        url: `/api/admin/webhooks/${id}`,
    });
};
