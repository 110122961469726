import { MobileWrapperConnected } from 'components/common/MobileWrapper';
import { EmailListEditorConnected } from './EmailListEditorConnected';
import { EmailListEditorMobileConnected } from './EmailListEditorMobileConnected';
import { IOwnProps } from './types';

export const EmailListEditorResponsive: React.FC<IOwnProps> = (props) => (
    <MobileWrapperConnected
        renderDesktop={() => <EmailListEditorConnected {...props} />}
        renderMobile={() => <EmailListEditorMobileConnected {...props} />}
    />
);