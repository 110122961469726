import { getUserPrivilegesApiCall } from 'admin-app/api/user/getUserPrivilegesApiCall';
import { EUserRoles } from 'admin-app/constants/EUserRoles';
import { getNetworkService } from 'api/getNetworkService';
import { Dispatch, SetStateAction } from 'react';
import { log } from 'utils/log';
import { redirect } from 'utils/redirect';

export const checkUserAccess = async (
    userId: string,
    setCheckingStatus: Dispatch<SetStateAction<boolean>>,
) => {
    const { success, data } = await getUserPrivilegesApiCall(getNetworkService(), userId);

    if (!success) {
        log.log('Something went wrong, please try again');
        redirect('/');
        return;
    }
    const isAdmin = data.some((role) => role === EUserRoles.ADMIN);
    if (!isAdmin) {
        log.log('You dont have access');
        redirect('/');
        return;
    }

    setCheckingStatus(false);
};
