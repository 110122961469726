import { t } from '@lingui/macro';
import { createAsyncThunk } from '@reduxjs/toolkit';
import { setAnonymousUserDataAction } from 'actions/auth/setAnonymousUserDataAction';
import { setCurrentConferenceIdAction } from 'actions/conferenceSession/setCurrentConferenceIdAction';
import { setCurrentConferencePinAction } from 'actions/conferenceSession/setCurrentConferencePinAction';
import { getAndCheckConferenceForErrorsThunkAction } from 'thunk/conference/getAndCheckConferenceForErrorsThunkAction';
import { prepareToConnectToConferenceThunkAction } from 'thunk/conference/prepareToConnectToConferenceThunkAction';
import { getConferenceLinkParameters } from 'utils/conferences/getConferenceLinkParameters';
import { isConferenceLink } from 'utils/conferences/isConferenceLink';
import { checkPinConferenceThunkAction } from './checkPinConferenceThunkAction';

export interface IPrepareForConferenceConnectMobilePayload {
    conferenceIdOrUrl: string;
    displayName: string;
    pin: string;
}

export const prepareForConferenceConnectMobileThunkAction = createAsyncThunk(
    'prepareForConferenceConnectMobile',
    async (
        { conferenceIdOrUrl, displayName, pin }: IPrepareForConferenceConnectMobilePayload,
        { dispatch },
    ) => {
        let conferenceId = '';
        let pinForCheck = pin;
        if (isConferenceLink(conferenceIdOrUrl)) {
            const { pin, id } = getConferenceLinkParameters(conferenceIdOrUrl.split('?')[1]);
            if (!id) {
                return 'wrong conference id or pin';
            }
            if (!pin) {
                return 'wrong conference id or pin';
            }
            conferenceId = id;
            pinForCheck = pin;
            dispatch(setCurrentConferencePinAction(pin));
        } else {
            conferenceId = conferenceIdOrUrl;
        }
        if (conferenceId) {
            dispatch(setCurrentConferenceIdAction(conferenceId));
        }
        const { isSuccess } = await dispatch(
            checkPinConferenceThunkAction({
                id: conferenceId,
                pin: pinForCheck,
            }),
        ).unwrap();
        if (!isSuccess) {
            return t({
                id: 'conference.checkError.id.or.pin',
                message: 'Неверный идентификатор конференции или код доступа',
            });
        }
        if (displayName) {
            dispatch(setAnonymousUserDataAction({ name: displayName }));
        }
        const { payload: result } = await dispatch(
            getAndCheckConferenceForErrorsThunkAction(conferenceId),
        );
        if (typeof result === 'string') {
            return result;
        } else if (result && typeof result === 'object' && 'error' in result) {
            return (result as { error: string }).error;
        }
        // const conference: IConferenceConnectInfo = result as IConferenceConnectInfo;
        // dispatch(
        //     setJanusStreamPropsAction({
        //         useAudio: useAudio && !conference.muteUponEntry,
        //         useVideo: useVideo && conference.videoParticipants,
        //     }),
        // );
        await dispatch(prepareToConnectToConferenceThunkAction({ conferenceId, pin }));
        // await dispatch(connectToCurrentConferenceThunkAction(true));
    },
);
