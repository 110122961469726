import { IOwnProps } from './types';
import styles from './ConferenceChatMessageAuthor.module.scss';
import { getUserColor } from 'utils/users/getUserColor';

export const ConferenceChatMessageAuthor: React.FC<IOwnProps> = ({ name }) => {
    const nameColor = name ? getUserColor(name) : '';
    return (
        <span className={styles.authorName} style={{ color: nameColor }}>
            {name || ''}
        </span>
    );
};
