import styles from './InviteRegistrationPage.module.scss';
import { PageTemplate } from 'pages/templates/PageTemplate';
import { Trans } from '@lingui/react';
import { InviteRegistrationFormConnected } from './InviteRegistrationForm';

export const InviteRegistrationPage: React.FC = () => (
    <PageTemplate showLoginBtn={false} showRegisterBtn={false}>
        <div className={styles.title}>
            <Trans id='Registration' message='Регистрация' />
        </div>
        <div className={styles.form}>
            <InviteRegistrationFormConnected />
        </div>
    </PageTemplate>
);
