import { PinEnterFormDesktopConnected } from 'components/forms/PinEnterForm/PinEnterFormDesktop';
import React, { useEffect } from 'react';
import { ConferencePageTemplateConnected } from '../templates/ConferencePageTemplate';
import styles from './PinEnterPage.module.scss';
import { IOwnProps, IDispatchProps, IStateProps } from './types';

export const PinEnterPageDesktop: React.FC<IOwnProps & IDispatchProps & IStateProps> = ({
    conferenceConnectInfo,
    id,
    loadData,
}) => {
    useEffect(() => {
        loadData(id);
    });
    return (
        <ConferencePageTemplateConnected
            conferenceConnectInfo={conferenceConnectInfo}
            showLogo={true}>
            {conferenceConnectInfo && (<div className={styles.form}>
                <PinEnterFormDesktopConnected />
            </div>)}
        </ConferencePageTemplateConnected>
    );
};
