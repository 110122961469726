import { Button } from 'admin-app/components/common/Button';
import { Input } from 'admin-app/components/common/Input';
import { FullScreenPopup } from 'components/common/FullScreenPopup';
import { PopupDialog } from 'components/common/PopupDialog';
import { Form, FormRenderProps } from 'react-final-form';
import styles from './WebHookDialog.module.scss';
import { IFormProps, IOwnProps } from './types';
import { FORM_ERROR } from 'final-form';
import { InputField } from 'admin-app/components/common/InputField';
import { classNames } from 'utils/classNames';
import { Trans } from '@lingui/react';
import { useEffect, useRef } from 'react';
import { isTokenUrl, isValidUrl } from './utils';

export const WebHookDialog: React.FC<IOwnProps> = ({
    title,
    buttonText,
    withoutTimer,
    currentHook,
    handleSubmit,
    closeDialog,
}) => {
    const closeDialogTimerRef = useRef<NodeJS.Timeout | null>(null);

    useEffect(() => {
        return () => {
            closeDialogTimerRef.current && clearTimeout(closeDialogTimerRef.current);
        };
    }, []);

    const renderForm = ({
        handleSubmit,
        pristine,
        submitting,
        dirtySinceLastSubmit,
        errors,
        submitError,
        submitSucceeded,
    }: FormRenderProps<IFormProps>) => (
        <form className={styles.form} onSubmit={handleSubmit}>
            <div className={styles.form__field}>
                <label htmlFor='url'>Url</label>
                <InputField
                    name='url'
                    errorClassName={errors?.url ? styles.form__input_error : ''}
                    wrapperClassName={classNames([styles.form__input])}
                    placeholder='http://example/'
                />
            </div>
            <div className={styles.form__field}>
                <label htmlFor='token'>Токен</label>
                <InputField
                    name='token'
                    errorClassName={errors?.token ? styles.form__input_error : ''}
                    wrapperClassName={classNames([styles.form__input])}
                />
            </div>
            {submitSucceeded && (
                <span className={styles.form__submitSuccess}>
                    <Trans id='admin.hook.dialog.success' message='Изменения применены' />
                </span>
            )}
            {submitError && !dirtySinceLastSubmit && (
                <div className={styles.form__submitError}>{submitError || ''}</div>
            )}
            <Button
                className={styles.form__submit}
                type='submit'
                disabled={
                    pristine ||
                    submitting ||
                    !!Object.keys(errors || {}).length ||
                    (!dirtySinceLastSubmit && submitError)
                }>
                {buttonText}
            </Button>
        </form>
    );

    const getInitialValues = () => {
        const resData = { url: currentHook?.url || '', token: currentHook?.token || '' };
        return resData;
    };

    const validate = (values: IFormProps) => {
        const errors: { [key: string]: string } = {};
        if (!values.url) {
            errors.url = 'Required';
        } else if (!isValidUrl(values.url)) {
            errors.url = 'Incorrect';
        }
        if (!values.token) {
            errors.token = 'Required';
        } else if (!isTokenUrl(values.token)) {
            errors.token = 'Incorrect';
        }
        return errors;
    };

    const onSubmit = async (values: IFormProps) => {
        const error = await handleSubmit(values);
        if (!error && !withoutTimer) {
            closeDialogTimerRef.current = setTimeout(() => closeDialog(), 1000);
        }
        return { [FORM_ERROR]: error?.errorDescription };
    };

    return (
        <FullScreenPopup closePopup={closeDialog}>
            <PopupDialog
                crossPosition='inside'
                onClose={closeDialog}
                dialogClassName={styles.dialog}
                titleClassName={styles.dialog__header}
                title={title}>
                <div>
                    {currentHook?.url && (
                        <span className={styles.dialog__description}>{currentHook.url}</span>
                    )}
                    <div className={styles.formWrapper}>
                        <Form
                            render={renderForm}
                            validate={validate}
                            onSubmit={onSubmit}
                            initialValues={getInitialValues()}
                        />
                    </div>
                </div>
            </PopupDialog>
        </FullScreenPopup>
    );
};
