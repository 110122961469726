import { IConferenceReport } from 'types/conferenceReport/IConferenceReport';
import { IConferenceReportDataFromServer } from 'types/server/IConferenceReportDataFromServer';
import { convertStringToDateByTemplate } from 'utils/dates/convertStringToDateByTemplate';
//TODO:Need tests for this 
export const convertConferenceReportFromServer = (
    conferenceReportFromSever: IConferenceReportDataFromServer,
): IConferenceReport => {
    const dateTemplate = 'yyyy-MM-dd HH:mm:ss.SSSSSS';
    const convertedParticipants = conferenceReportFromSever.participants.map(
        ({ name, sessions, userId, avatar }) => {
            const convertedSessions = sessions.map((session) => ({
                ...session,
                timeJoin: convertStringToDateByTemplate(session.timeJoin, dateTemplate),
                timeLeave: convertStringToDateByTemplate(
                    session.timeLeave ?? conferenceReportFromSever.timeEnd,
                    dateTemplate,
                ),
            }));
            return {
                name,
                userId,
                etag: avatar ? avatar.substring(5) : '',
                sessions: convertedSessions,
            };
        },
    );
    const convertedConference: IConferenceReport = {
        ...conferenceReportFromSever,
        timeStart: convertStringToDateByTemplate(conferenceReportFromSever.timeStart, dateTemplate),
        timeEnd: convertStringToDateByTemplate(conferenceReportFromSever.timeEnd, dateTemplate),
        participants: convertedParticipants,
        creator: {
            email: '',
            avatar: '',
            fullName: '',
            initials: '',
        },
    };
    return convertedConference;
};
