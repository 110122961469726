import { createAsyncThunk } from '@reduxjs/toolkit';
import { setConferencesRecordsAction } from 'actions/records/setConferencesRecordsAction';
import { getNetworkService } from 'api/getNetworkService';
import { getConferenceRecordsByIdApiCall } from 'api/records/getConferenceRecordsByIdApiCall';
import { getConferenceRecordSelector } from 'selectors/getConferenceRecordSelector';
import { TAppState } from 'types/app/TAppStore';
import { IConferenceRecord } from 'types/IConferenceRecord';
import { covertServerRecords } from 'utils/records/covertServerRecords';

export const getConferenceRecordsByIdThunkAction = createAsyncThunk<
    IConferenceRecord | undefined,
    { conferenceId: string; sessionId: string; forceUpdate?: boolean }
>(
    'getConferenceRecordsById',
    async ({ conferenceId, sessionId, forceUpdate = false }, { dispatch, getState }) => {
        if (!forceUpdate) {
            const record = getConferenceRecordSelector(getState() as TAppState, sessionId);
            if (record) {
                return record;
            }
        }
        const response = await getConferenceRecordsByIdApiCall(getNetworkService(), {
            conferenceId,
            sessionId,
        });
        if (response.success) {
            dispatch(setConferencesRecordsAction(covertServerRecords([response.data])));
        }
    },
);
