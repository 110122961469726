import { MutableRefObject } from 'react';
import { IKeyboardHandlers, TScrollDirection } from './types';

export const getContainerPosition = (targetInput: HTMLInputElement | null) => {
    const resultObj = { x: 0, y: 0, outOfViewport: false };

    if (!targetInput) {
        return resultObj;
    }
    const targetInputNodeData = targetInput.getBoundingClientRect();
    if (targetInputNodeData.right + 300 > window.innerWidth) {
        resultObj.outOfViewport = true;
    }
    (resultObj.x = targetInput.offsetLeft + targetInput.clientWidth + 5),
    (resultObj.y = targetInput.offsetTop);

    return resultObj;
};

export const scrollToPoint = (
    containerNode: HTMLElement | null,
    activeId: string | null,
    scrollDirection: TScrollDirection,
    currentScrollPositionRef: MutableRefObject<number>,
) => {
    if (!containerNode || containerNode?.children.length <= 4) {
        return false;
    }
    const targetNode = containerNode.querySelector(`[id='${activeId}']`) as HTMLElement;
    const allChildren = containerNode.children;
    const isTopBoundaryElements = [allChildren[0], allChildren[1]].find(
        (element) => element === targetNode,
    );
    const isBottomBoundaryElements = [
        allChildren[allChildren.length - 1],
        allChildren[allChildren.length - 2],
    ].find((element) => element === targetNode);

    if (isTopBoundaryElements) {
        currentScrollPositionRef.current = 0;
        containerNode.scrollTo({
            top: currentScrollPositionRef.current,
            behavior: 'smooth',
        });
        return;
    }

    if (isBottomBoundaryElements) {
        const newScrollPosition = containerNode.scrollHeight;
        containerNode.scrollTo({
            top: newScrollPosition,
            behavior: 'smooth',
        });
        currentScrollPositionRef.current = containerNode.scrollTop;
        return;
    }

    const targetNodeHeight = targetNode.clientHeight;
    if (scrollDirection === 'down') {
        currentScrollPositionRef.current += targetNodeHeight;
    } else {
        currentScrollPositionRef.current -= targetNodeHeight;
    }

    containerNode.scrollTo({ top: currentScrollPositionRef.current, behavior: 'smooth' });
};

export const setDataActive = (
    state: boolean,
    id: string | null,
    containerNode: HTMLElement | null,
) => {
    if (!id) {
        return;
    }
    const target = containerNode?.querySelector(`[id='${id}']`) as any;
    target ? (target.dataset.active = state) : false;
};

const keyDownHandler = (
    event: KeyboardEvent,
    { pressDownHandler, pressUpHandler, pressEnterHandler, pressEscHandler }: IKeyboardHandlers,
) => {
    const buttons = [
        { code: 'ArrowDown', handler: pressDownHandler },
        { code: 'ArrowUp', handler: pressUpHandler },
        { code: 'Escape', handler: pressEscHandler },
        { code: 'Enter', handler: pressEnterHandler },
    ];

    const targetBtn = buttons.find((button) => button.code === event.code);

    if (!targetBtn) {
        return;
    }
    event.preventDefault();
    event.stopPropagation();
    targetBtn.handler();
};

let keyDownHandlerWrapper: (event: KeyboardEvent) => void;

export const addKeyboardNavigation = (keyboardHandlers: IKeyboardHandlers) => {
    keyDownHandlerWrapper = (event) => {
        keyDownHandler(event, keyboardHandlers);
    };
    document.querySelector('body')?.addEventListener('keydown', keyDownHandlerWrapper, {capture: true});
};

export const deleteKeyboardNavigation = () => {
    document.querySelector('body')?.removeEventListener('keydown', keyDownHandlerWrapper,{capture: true});
};
