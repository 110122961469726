import { IOwnProps } from './types';
import styles from '../ConferenceChatMessageContent.module.scss';
import ownStyles from './ConferenceChatMessageMedia.module.scss';
import DownloadImg from 'assets/ChatDownloadMedia.svg';
import InvertDownloadImg from 'assets/ChatDownloadMediaInvert.svg';

export const ConferenceChatMessageMedia: React.FC<IOwnProps> = ({
    id,
    conferenceId,
    sessionId,
    name,
    size,
    isOwnMessage,
}) => {
    const formatBytes = (size: number) => {
        const decimals = 2;
        const k = 1024;
        const dm = decimals < 0 ? 0 : decimals;
        const sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB'];

        const i = Math.floor(Math.log(size) / Math.log(k));

        return `${parseFloat((size / Math.pow(k, i)).toFixed(dm))} ${sizes[i]}`;
    };

    const getFileFormat = () => {
        const fileNamePaths = name.split('.');
        return fileNamePaths[fileNamePaths.length - 1];
    };

    return (
        <span key={id} className={`${styles.file} ${ownStyles.container}`}>
            <a
                className={ownStyles.link}
                href={`/api/conferences/${conferenceId}/sessions/${sessionId}/media_files/${id}`}
                target='_blank'>
                <img src={isOwnMessage ? InvertDownloadImg : DownloadImg} />
            </a>
            <div className={ownStyles.link__fileInfo}>
                <span className={ownStyles.link__fileName}> {name}</span>
                <div
                    className={`${ownStyles.link__fileMetaInfo} ${
                        isOwnMessage ? ownStyles.link__fileMetaInfo_white : ''
                    }`}>
                    <span className={ownStyles.link__fileFormat}>{getFileFormat()}</span>
                    {size !== null && (
                        <span className={ownStyles.link__fileSize}>{formatBytes(size)}</span>
                    )}
                </div>
            </div>
        </span>
    );
};
