import { AnyAction } from '@reduxjs/toolkit';
import { addDisconnectPublisherToLogAction } from 'actions/janus/addDisconnectPublisherToLogAction';
import { addJanusPublisherAction } from 'actions/janus/addJanusPublisherAction';
import { removeFromJanusLogAction } from 'actions/janus/removeFromJanusLogAction';
import { removeJanusPublisherAction } from 'actions/janus/removeJanusPublisherAction';
import { setJanusStreamPropsAction } from 'actions/janus/setJanusStreamPropsAction';
import { setFramesToFullscreenThunkAction } from 'thunk/appView/setFramesToFullscreenThunkAction';
import { setPreviousCurrentConferenceDeviceValuesThunkAction } from 'thunk/conference/previousConferenceDeviceValues/setPreviousCurrentConferenceDeviceValuesThunkAction';
import { updateConferenceScreenSharingStateThunkAction } from 'thunk/conference/updateConferenceScreenSharingStateThunkAction';
import { TAppState } from 'types/app/TAppStore';
import { IJanusLocalStreamProps } from 'types/janus/IJanusLocalStreamProps';
import { changeJanusStreamProps } from 'utils/janus/changeJanusStreamProps';

export const janusMiddleware = (store: any) => (next: any) => (action: AnyAction) => {
    if (action.type === setJanusStreamPropsAction.type) {
        const { dispatch, getState } = store;
        const {
            janus: {
                localStreamProps: { screenShared },
            },
        } = getState() as TAppState;
        changeJanusStreamProps(action['payload'], async () => {
            const actionData = action['payload'] as Partial<IJanusLocalStreamProps>;
            const sharingChanged =
                typeof actionData?.screenShared === 'boolean' ||
                (screenShared && actionData.videoPermitted === false);
            if (sharingChanged) {
                let isSharingStarted: undefined | boolean;
                if (actionData.screenShared && !screenShared) {
                    isSharingStarted = true;
                } else if (!actionData.screenShared && screenShared) {
                    isSharingStarted = false;
                }
                if (screenShared && actionData.videoPermitted) {
                    isSharingStarted = false;
                }
                if (isSharingStarted !== undefined) {
                    await dispatch(updateConferenceScreenSharingStateThunkAction(isSharingStarted));
                }
            }

            next(action);
            dispatch(setPreviousCurrentConferenceDeviceValuesThunkAction(action['payload']));
        });
        return;
    }
    if (action.type === removeJanusPublisherAction.type) {
        const id = action['payload'];
        const { dispatch, getState } = store;
        const {
            janus: { publishers, log },
            applicationView: { participantsFullScreenGlobal },
            conferenceSession: { currentConferenceId, currentUserIsOwner },
        } = getState() as TAppState;
        if (!currentConferenceId) {
            return;
        }
        const exitPublisher = publishers[id];
        if (!exitPublisher) {
            return;
        }
        if (log.length === 5) {
            dispatch(removeFromJanusLogAction());
        }
        dispatch(addDisconnectPublisherToLogAction(exitPublisher));
        // Only the owner can change priority frame. If the priority participant was leaved - restore frame for the remaining participants
        if (
            participantsFullScreenGlobal.some((id) => id === exitPublisher.participantId) &&
            currentUserIsOwner
        ) {
            const participantIds = participantsFullScreenGlobal.filter(
                (id) => id !== exitPublisher.participantId,
            );
            dispatch(
                setFramesToFullscreenThunkAction({
                    global: true,
                    participantIds,
                }),
            );
        }
        // const participantFromWaitingList = waitingList.find((elem) => elem.participantId === id);
        // if (participantFromWaitingList) {
        //     dispatch(deleteFromWaitingListAction(id));
        // }
    }
    if (action.type === addJanusPublisherAction.type) {
        const { dispatch, getState } = store;
        const {
            janus: { log },
        } = getState() as TAppState;
        if (log.length === 10) {
            dispatch(removeFromJanusLogAction());
        }
    }
    next(action);
};
