import { Trans } from '@lingui/react';
import { Avatar } from 'components/common/Avatar';
import { getParticipantAvatar } from 'utils/participants/getParticipantAvatar';
import { getUserColor } from 'utils/users/getUserColor';
import { getUserInitials } from 'utils/users/getUserInitials';
import styles from './ParticipantsList.module.scss';
import { IOwnProps } from './types';

export const ParticipantsList: React.FC<IOwnProps> = ({ participants }) => {
    const renderParticipantsList = () =>
        participants.map(({ etag, name, userId, participantId, sessions }, index) => {
            const [firstName, lastName] = name.split(' ');
            
            let key;

            if (participantId) {
                key = participantId;
            } else if (!participantId && sessions.length === 1) {
                key = sessions[0].participantId;
            } else {
                key = index;
            }

            return (
                <li className={styles.participantsList__item} key={key}>
                    <Avatar
                        initials={getUserInitials({ firstName, lastName })}
                        image={getParticipantAvatar({
                            etag,
                            userId,
                        })}
                        style={{
                            width: '50px',
                            height: '50px',
                            marginRight: '10px',
                            fontSize: '12px',
                            flexShrink: 0,
                        }}
                        userBgColor={getUserColor(name)}
                    />
                    <span>
                        {name}
                        {!userId && (
                            <>
                                <span>&nbsp;</span>
                                <span className={styles.guest}>
                                    <Trans id='Guest' message='Гость' />
                                </span>
                            </>
                        )}
                    </span>
                </li>
            );
        });
    return <ul className={styles.participantsList}>{renderParticipantsList()}</ul>;
};
