import { connect } from 'react-redux';
import { DellWebHookDialog } from './DellWebHookDialog';
import { deleteWebHookThunkAction } from 'admin-app/thunk/webHooks/deleteWebHookThunkAction';
import { IDispatchProps } from './types';
import { TAppDispatch } from 'types/app/TAppDispatch';

const mapDispatchToProps = (dispatch: TAppDispatch): IDispatchProps => ({
    dellWebHook: (id) => dispatch(deleteWebHookThunkAction({ id })).unwrap(),
});

export const DellWebHookDialogConnected = connect(null, mapDispatchToProps)(DellWebHookDialog);
