import { createAsyncThunk } from '@reduxjs/toolkit';
import { EConferenceThunkActions } from 'constants/thunk/EConferenceThunkActions';
import { TAppState } from 'types/app/TAppStore';
import { getPastConferenceInfoThunkAction } from 'thunk/conference/getPastConferenceInfoThunkAction';
import { IConferenceConnectInfo } from 'types/conferences/IConferenceConnectInfo';
import { getConferenceConnectInfoThunkAction } from 'thunk/conference/getConferenceConnectInfoThunkAction';
import { IConferenceUser } from 'types/IConferenceUser';
import { getConferenceInvitedUsersThunkAction } from 'thunk/conference/getConferenceInvitedUsersThunkAction';
import {
    getConferenceParticipantsThunkAction,
} from 'thunk/conference/participants/getConferenceParticipantsThunkAction';

interface IPayload {
    sessionId?: string;
    conferenceId: string;
}

export const getDataForDetailsPageThunkAction = createAsyncThunk<void, IPayload>(
    EConferenceThunkActions.GET_DATA_FOR_CONFERENCE_DETAILS_PAGE,
    async ({ sessionId, conferenceId}, { dispatch, getState }) => {
        const {
            conferences: { conferencesConnectInfo, conferencesInvitedUsers },
        } = getState() as TAppState;
        const isPastConference = !!sessionId;
        const conferenceInfo: IConferenceConnectInfo | undefined = conferencesConnectInfo[conferenceId];
        const invitedUsers: IConferenceUser[] | undefined = conferencesInvitedUsers[conferenceId];
        if (!invitedUsers) {
            dispatch(getConferenceInvitedUsersThunkAction({ id: conferenceId }))
        }
        if (!conferenceInfo) {
            dispatch(getConferenceConnectInfoThunkAction({ id: conferenceId })).unwrap().then(info => {
                if (info && info.status === 'STARTED' && !isPastConference) {
                    dispatch(getConferenceParticipantsThunkAction({id: conferenceId, pin: info.pin ?? ''}))
                }
            })
        } else {
            dispatch(getConferenceParticipantsThunkAction({id: conferenceId, pin: conferenceInfo.pin ?? ''}))
        }
        if (isPastConference) {
            dispatch(getPastConferenceInfoThunkAction({ conferenceId, sessionId }))
        }
    },
)
