import { useEffect, useRef, useState } from 'react';
import { IOwnProps } from './types';

const blinkTime = 1500;

export const ConferenceChatMsgLengthWarning: React.FC<IOwnProps> = ({
    currentLength,
    maxLength,
    className,
    blinkedClassName,
}) => {
    const [isBlinked, setBlinked] = useState(false);
    const blinkTimer = useRef<NodeJS.Timeout>();
    useEffect(() => {
        if (!blinkedClassName) {
            return;
        }
        if (currentLength < maxLength) {
            setBlinked(false);
            return;
        }
        if (blinkTimer.current) {
            clearTimeout(blinkTimer.current);
        }
        setBlinked(true);
        blinkTimer.current = setTimeout(() => {
            setBlinked(false);
            blinkTimer.current = undefined;
        }, blinkTime);
        return () => {
            if (blinkTimer.current) {
                clearTimeout(blinkTimer.current);
                blinkTimer.current = undefined;
            }
        };
    }, [blinkedClassName && currentLength]);
    if (maxLength - currentLength >= 100 || currentLength === 0) {
        return null;
    }
    return (
        <div className={getClassName(className, isBlinked ? blinkedClassName : undefined)}>
            {currentLength}/{maxLength}
        </div>
    );
};

const getClassName = (className?: string, blinkedClassName?: string) => {
    const cName = [];
    if (className) {
        cName.push(className);
    }
    if (blinkedClassName) {
        cName.push(blinkedClassName);
    }
    return cName.join(' ');
};
