import { AnyAction, createAsyncThunk, ThunkDispatch } from '@reduxjs/toolkit';
import { setNotificationForDisplayAction } from 'actions/applicationView/setNotificationForDisplayAction';
import { INotification } from 'types/INotification';
import { log } from 'utils/log';

const notifications: INotification[] = [];

const showNotification = (dispatch: ThunkDispatch<unknown, unknown, AnyAction>) => {
    const notification = notifications[0];
    if (notification && !notification.timerHandle) {
        dispatch(setNotificationForDisplayAction({ ...notification }));
        notification.timerHandle = setTimeout(() => {
            const shownNotification = notifications.shift();
            if (shownNotification?.closeAction) {
                shownNotification.closeAction();
            }
            dispatch(setNotificationForDisplayAction(null));            
            showNotification(dispatch);
        }, notifications[0].timeout);
    }
};
type IShowNotifyThunkPayload = Omit<INotification, 'timerHandle'>;

export const showNotifyThunkAction = createAsyncThunk<void, IShowNotifyThunkPayload>(
    'showNotify',
    async (notification, { dispatch }) => {
        if (notifications.length > 0) {
            if (notifications[notifications.length - 1].text === notification.text) {
                // Same error is show, so skip it
                log.debug('Same error message. Skipped.');
                return;
            }
        }
        notifications.push(notification);
        showNotification(dispatch);
    },
);

export const hideCurrentNotifyThunkAction = createAsyncThunk(
    'hideNotify',
    async (_, { dispatch }) => {
        const notification = notifications.shift();
        if (notification) {
            if (notification.timerHandle) {
                clearTimeout(notification.timerHandle);
            }            
            if (notification.closeAction) {
                notification.closeAction();
            }
            dispatch(setNotificationForDisplayAction(null));
        }
        showNotification(dispatch);
    },
);
