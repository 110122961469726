import { IOwnProps } from './types';
import styles from './UserEditFormControls.module.scss';
import { Portal } from 'components/common/Portal';
import { classNames } from 'utils/classNames';
import { Button } from 'components/common/Button';
import { Spinner } from 'components/common/Spinner';
import { t } from '@lingui/macro';

export const UserEditFormControls: React.FC<IOwnProps> = ({ container,disableSubmit, submitting, submit }) => (
    <Portal container={container}>
        <div className={styles.controls}>
            <Button
                styleType='common'
                type='button'
                caption={t({ id: 'Delete' })}
                clickHandler={() => {
                    // form.restart();
                }}
                // disabled={disableButton}
                className={classNames([styles.controlsButton, styles.controlsButton_delete])}
            />
            <Button
                styleType={submitting ? 'loading' : 'primaryAction'}
                // disabled={disableButton || isFormHasValidationErrors(errors)}
                type='submit'
                clickHandler={submit}
                disabled={disableSubmit || submitting}
                className={classNames([styles.controlsButton, styles.controlsButton_save])}>
                {submitting ? <Spinner /> : t({ id: 'Save', message: 'Сохранить' })}
            </Button>
        </div>
    </Portal>
);
