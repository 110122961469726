import { connect } from 'react-redux';
import { AccountSettingsPage } from './AccountSettingsPage';
import { TAppState } from 'types/app/TAppStore';
import { IStateProps } from './types';
import { isReleaseVersion } from 'utils/isReleaseVersion';

const mapStateToProps = (state: TAppState): IStateProps => ({
    showDebugTab:
        !isReleaseVersion(state.applicationConfiguration.config.version) ||
        process.env.NODE_ENV === 'development',
});

export const AccountSettingsPageConnected = connect(mapStateToProps)(AccountSettingsPage);
