import { t } from '@lingui/macro';
import { ESocketAppActions } from 'constants/ESocketAppActions';
import { TMobileNotificationOptions } from 'types/INotification';
import { TConferenceEvents } from 'types/socket/conferenceEvents/TConferenceEvents';

export const getConferenceNotificationMobileOptions = ({
    actionType,
    conferenceId,
    callback,
}: {
    actionType: TConferenceEvents;
    conferenceId: string;
    callback?: () => void;
}):TMobileNotificationOptions | undefined => {
    if (!callback) {
        return;
    }

    if (actionType === ESocketAppActions.CONFERENCE_STARTED) {
        if (window.location.pathname.match('/connect/') ){
            if (window.location.pathname.split('/')[2] === conferenceId) {
                return;
            }
        }
        return {
            theme: 'buttonAction' as const,
            buttonText: t({
                id: 'connect',
                message: 'Присоединиться',
            }),
            action: callback,
        };
    }

    if (actionType === ESocketAppActions.CONFERENCE_SCHEDULED || actionType === ESocketAppActions.CONFERENCE_UPDATED) {
        return {
            theme: 'primaryAction' as const,
            action: callback,
        };
    }
    return;
};
