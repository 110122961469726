import { INetworkService } from 'types/api/INetworkService';

interface IPayload {
    conferenceId: string;
    sessionId: string;
}

export const getConferenceReportApiCall = (
    networkService: INetworkService,
    { conferenceId, sessionId }: IPayload,
) =>
    networkService.get({
        url: `/api/conferences/${conferenceId}/sessions/${sessionId}/report`,
    });
