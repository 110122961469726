import { createAsyncThunk } from '@reduxjs/toolkit';
import { saveChatMediaApiCall } from 'api/conference/saveChatMediaApiCall';
import { getNetworkService } from 'api/getNetworkService';
import { TAppState } from 'types/app/TAppStore';
import { IChatFile } from 'types/chat/IChatFile';

export const saveConferenceChatMediaFileThunkAction = createAsyncThunk(
    'saveConferenceChatMediaFile',
    async (mediaFiles: File[], { getState }): Promise<IChatFile[] | null> => {
        const {
            conferenceSession: { currentConferenceId, currentSessionId },
        } = getState() as TAppState;

        if (!currentConferenceId) {
            return null;
        }

        const formData = new FormData();
        mediaFiles.forEach((file) => {
            formData.append('mediaFile', file);
        });

        const response = await saveChatMediaApiCall(getNetworkService(), {
            media: formData,
            conferenceId: currentConferenceId,
            sessionId: currentSessionId,
        });
        if (!response?.success) {
            return null;
        }
        const ids = await response.data;
        const chatFiles: IChatFile[] = [];
        for (let i = 0; i < mediaFiles.length; i++) {
            const file = mediaFiles[i];
            chatFiles.push({
                id: ids[i],
                name: file.name,
                size: file.size,
            });
        }
        return chatFiles;
    },
);