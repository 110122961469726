import { connect } from 'react-redux';
import { prepareRecorderToConnectThunkAction } from 'thunk/recorder/prepareRecorderToConnectThunkAction';
import { TAppDispatch } from 'types/app/TAppDispatch';
import { SimpleConnectToConferencePage } from './SimpleConnectToConferencePage';
import { IDispatchProps } from './types';

const mapDispatchToProps = (dispatch: TAppDispatch): IDispatchProps => ({
    prepareRecorderDataAndConnectToConference: () =>
        dispatch(prepareRecorderToConnectThunkAction()),
});

export const SimpleConnectToConferencePageConnected = connect(
    null,
    mapDispatchToProps,
)(SimpleConnectToConferencePage);
