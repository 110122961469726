import { compareAsc } from 'date-fns';
import { getStartEndTimeUtc0 } from 'utils/timeZones/getStartEndTimeUtc0';
import { addUsersTimeZoneToDate } from './addUsersTimeZoneToDate';
import { convertStringToDateByTemplate } from './convertStringToDateByTemplate';

interface IFilter {
    from?: string;
    to?: string;
}

export const isDateInFilterRange = (filter: IFilter, date?: string) => {
    const [from, to] = getStartEndTimeUtc0(filter?.from, filter?.to);
    let fromDate,
        toDate = null;
    if (from) {
        fromDate = convertStringToDateByTemplate(from, 'yyyy-MM-dd HH:mm:ss');
    }
    if (to) {
        toDate = convertStringToDateByTemplate(to, 'yyyy-MM-dd HH:mm:ss');
    }
    if ((!fromDate && !toDate) || !date) {
        return true;
    }
    const conferenceTimeStart = addUsersTimeZoneToDate(
        convertStringToDateByTemplate(date, 'yyyy-MM-dd HH:mm:ss.SSSSSS'),
    );

    if (fromDate && toDate) {
        return (
            compareAsc(conferenceTimeStart, fromDate) >= 0 &&
            compareAsc(conferenceTimeStart, toDate) < 0
        );
    }
    if (fromDate && !toDate) {
        return compareAsc(conferenceTimeStart, fromDate) >= 0;
    }
    if (!fromDate && toDate) {
        return compareAsc(conferenceTimeStart, toDate) < 0;
    }
    return false;
};