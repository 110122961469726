import { ISocketConferenceStreamPermissions } from 'types/socket/conferenceEvents/ISocketConferenceStreamPermissions';

export const getStreamsPermissionsFormattedData = ({
    forbiddenStreams,
    allowedStreams,
}: {
    forbiddenStreams: ISocketConferenceStreamPermissions | undefined;
    allowedStreams: ISocketConferenceStreamPermissions | undefined;
}) => {
    const resultObj: {
        forbiddenStreams?: {
            audio: string[];
            video: string[];
        };
        allowedStreams?: {
            audio: string[];
            video: string[];
        };
    } = {};
    resultObj.forbiddenStreams = {
        audio: forbiddenStreams?.AUDIO ? forbiddenStreams.AUDIO : [],
        video: forbiddenStreams?.VIDEO ? forbiddenStreams.VIDEO : [],
    };
    resultObj.allowedStreams = {
        video: allowedStreams?.VIDEO ? allowedStreams.VIDEO : [],
        audio: allowedStreams?.AUDIO ? allowedStreams.AUDIO : [],
    };
    return resultObj;
};
