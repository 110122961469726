import { setJanusStreamPropsAction } from 'actions/janus/setJanusStreamPropsAction';
import { ComponentWithDevices } from 'components/HOCs/ComponentWithDevices';
import { connect } from 'react-redux';
import { getPreviousCurrentConferenceDeviceValuesThunkAction } from 'thunk/conference/previousConferenceDeviceValues/getPreviousCurrentConferenceDeviceValuesThunkAction';
import { IAppSettingsTyped } from 'types/app/IAppSettings';
import { TAppDispatch } from 'types/app/TAppDispatch';
import { TAppState } from 'types/app/TAppStore';
import { IDispatchProps, IStateProps } from './types';
import { VideoCallSettings } from './VideoCallSettings';

const mapStateToProps = (state: TAppState): IStateProps => {
    const appSettings = state.auth?.appSettings as IAppSettingsTyped;
    return {
        localStreamSettings: state.janus.localStreamProps,
        ownAudioOnConnect: appSettings ? appSettings.ownAudioOnConnect : true,
        ownVideoOnConnect: appSettings ? appSettings.ownVideoOnConnect : true,
    };
};

const mapDispatchToProps = (dispatch: TAppDispatch): IDispatchProps => ({
    changeStreamProps: (props) => dispatch(setJanusStreamPropsAction(props)),
    loadPreviousCurrentConferenceDeviceValues: (data) =>
        dispatch(getPreviousCurrentConferenceDeviceValuesThunkAction(data)).unwrap(),
});

export const VideoCallSettingsWithDevices = ComponentWithDevices(VideoCallSettings);
export const VideoCallSettingsWithDevicesConnected = connect(
    mapStateToProps,
    mapDispatchToProps,
)(VideoCallSettingsWithDevices);
