import { createAsyncThunk } from '@reduxjs/toolkit';
import { setApplicationConfigAction } from 'actions/applicationConfiguration/setApplicationConfigAction';
import { getConfigApiCall } from 'api/application/getConfigApiCall';
import { getNetworkService } from 'api/getNetworkService';
import { changeMaxFeeds } from 'constants/janusConstants';

export const getApplicationConfigThunkAction = createAsyncThunk(
    'getApplicationConfigFromServer',
    async (_: void, { dispatch }) => {
        const response = await getConfigApiCall(getNetworkService());
        if (response.success) {
            const settings = response.data;
            settings.maxMessageLength = settings.maxMessageLength - '<text>'.length; // Service message
            changeMaxFeeds((settings.maxPublishers || 25) - 2);
            dispatch(setApplicationConfigAction(settings));
            return true;
        }
    },
);
