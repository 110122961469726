import { Trans } from '@lingui/react';
import { IAdminPanelUser, TUserRole } from 'admin-app/types/IAdminPanelUser';
import { useEffect, useRef, useState } from 'react';
import { IDispatchProps } from './types';
import { UserEditForm } from './UserEditForm';
import styles from './UserPage.module.scss';
import { IOwnProps as IUserCardForm } from '../../components/UserCardForm/types';

export const UserPage: React.FC<IDispatchProps> = ({ getUserData, editUserRoles }) => {
    const [user, setUser] = useState<IAdminPanelUser | null>(null);

    const getUser = async () => {
        const data = await getUserData();
        data && setUser(data);
    };

    useEffect(() => {
        getUser();
    }, []);

    const formButtonsContainerRef = useRef<HTMLDivElement>(null);
    const fullName = `${user?.firstName || ''} ${user?.lastName || ''}`;

    const handleSubmit: IUserCardForm['handleSubmit'] = async (value) => {
        const roleToSubmit: TUserRole[] = [];

        if (value.role !== 'participant') {
            roleToSubmit.push(value.role);
        }
        if (!user) {
            return;
        }
        const error = await editUserRoles({ userId: user.id, roles: roleToSubmit });
        if (!error) {
            getUser();
            return;
        }
        return error;
    };
    return (
        <div className={styles.page}>
            <div className={styles.page__header}>
                <h1>
                    <Trans id='admin.user' message='Пользователь' />
                </h1>
                <span className={styles.page__userName}>{fullName}</span>
                <div className={styles.page__formControls} ref={formButtonsContainerRef}></div>
            </div>
            <div className={styles.page__body}>
                <UserEditForm
                    container={formButtonsContainerRef.current}
                    user={user}
                    handleSubmit={handleSubmit}
                />
            </div>
        </div>
    );
};
