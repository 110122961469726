import { useEffect, useRef, useState } from 'react';
import { IDispatchProps, IStateProps, IFormRenderProps } from './types';
import { Form, FormRenderProps } from 'react-final-form';
import { t } from '@lingui/macro';
import { Trans } from '@lingui/react';
import styles from './PersonalInformationForm.module.scss';
import { Button } from 'components/common/Button';
import { Input } from 'components/common/Input';
import { appHistory } from 'appHistory';
import { formatPhoneNumber, formatPhoneNumberToAPI } from 'utils/formatPhoneNumber';
import { Avatar } from 'components/common/Avatar/Avatar';
import HumanImg from 'assets/Human.svg';
import { getAvatarUrl } from 'utils/users/getAvatarUrl';

export const PersonalInformationFormMobile: React.FC<IDispatchProps & IStateProps> = ({
    notLoggedIn,
    goToHomePage,
    user,
    save,
}) => {
    useEffect(() => {
        if (notLoggedIn) {
            goToHomePage();
        }
    }, [notLoggedIn]);

    const avatarRef = useRef<HTMLInputElement>(null);
    const [avatar, setAvatar] = useState<string | null>();

    const setAvatarFromServer = () => {
        if (user && user.etag) {
            const { id, etag } = user;
            setAvatar(getAvatarUrl({ id, etag, size: '160' }));
        }
    };

    useEffect(() => {
        setAvatarFromServer();
    }, [user]);

    const onChangeAvatar = () => {
        const files = avatarRef.current?.files;
        if (files && files.length !== 0) {
            setAvatar(URL.createObjectURL(files[0]));
        }
    };

    const handleSubmit = async (values: IFormRenderProps) => {
        let avatarFormData;
        if (avatar === null) {
            avatarFormData = null;
        } else {
            const files = avatarRef.current?.files;
            if (files && files?.length !== 0) {
                avatarFormData = new FormData();
                const a = avatarRef?.current?.files as FileList;
                avatarFormData.append('avatar', a[0]);
            }
        }
        const { success } = await save({ ...values, avatar: avatarFormData });
        if (success) {
            appHistory.push('/');
        }
    };

    const validate = (values: IFormRenderProps) => {
        const errors: { [key: string]: string } = {};

        const requiredFieldList = ['firstName', 'lastName'];

        for (const field of requiredFieldList) {
            const f = field as keyof IFormRenderProps;
            if (!values[f] || values[f] === '') {
                errors[f] = 'Required';
            }
        }
        return errors;
    };

    const handleClick = () => goToHomePage();

    const initialValues = {
        firstName: user?.name?.first,
        lastName: user?.name?.last,
        phone: user?.contact.phone,
    };

    const render = ({ handleSubmit, submitting }: FormRenderProps<IFormRenderProps>) => (
        <form onSubmit={handleSubmit} className={`${styles.form} ${styles.form_mobile}`}>
            <div className={styles.avatarBlock}>
                <input
                    type='file'
                    className={styles.fileAvatar}
                    ref={avatarRef}
                    onChange={onChangeAvatar}
                    accept='image/png, image/jpeg'
                />
                <div className={styles.avatar}>
                    <Avatar
                        image={avatar ? avatar : HumanImg}
                        style={{
                            cursor: 'pointer',
                            width: '100px',
                            height: '100px',
                        }}
                        clickHandler={() => {
                            avatarRef.current?.click();
                        }}></Avatar>
                </div>
                <a
                    href='#'
                    className={styles.upload}
                    onClick={() => {
                        avatarRef.current?.click();
                    }}>
                    <Trans id='Upload photo' message='Загрузить фотографию' />
                </a>
            </div>
            <div className={styles.block}>
                <label className={`${styles.label} ${styles.label_mobile}`} htmlFor='firstName'>
                    <Trans id='First name (required)' message='Имя (обязательно)' />
                </label>
                <Input
                    placeholder={t({ id: 'First name', message: 'Имя' })}
                    name='firstName'
                    type='text'
                    styleType='mobile'
                    className={styles.inputStyle}
                />
            </div>
            <div className={styles.block}>
                <label className={`${styles.label} ${styles.label_mobile}`} htmlFor='lastName'>
                    <Trans id='Last name (required)' message='Фамилия (обязательно)' />
                </label>
                <Input
                    placeholder={t({ id: 'Last name', message: 'Фамилия' })}
                    name='lastName'
                    type='text'
                    styleType='mobile'
                    className={styles.inputStyle}
                />
            </div>
            <div className={styles.block}>
                <label className={`${styles.label} ${styles.label_mobile}`} htmlFor='phone'>
                    <Trans id='Phone' message='Телефон' />
                </label>
                <Input
                    placeholder='+7 (xxx) xxx - xxxx'
                    name='phone'
                    type='phone'
                    pattern='\+7\s\([0-9]{3}\)\s[0-9]{3}\s-\s[0-9]{4}'
                    className={styles.inputStyle}
                    format={formatPhoneNumber}
                    parse={formatPhoneNumberToAPI}
                    styleType='mobile'
                />
            </div>
            <Button
                styleType='mobilePrimaryAction'
                caption={t({ id: 'Save', message: 'Сохранить' })}
                disabled={submitting}
                type='submit'
            />
            <Button
                styleType='mobileLink'
                type='button'
                clickHandler={handleClick}
                caption={t({ id: 'Skip', message: 'Пропустить' })}
            />
        </form>
    );

    return (
        <Form
            onSubmit={handleSubmit}
            validate={validate}
            render={render}
            initialValues={initialValues}
        />
    );
};
