import { t } from '@lingui/macro';
import { Trans } from '@lingui/react';
import { Input } from 'components/common/Input';
import { MobileFormBlock } from 'components/common/MobileFormBlock';
import { Spinner } from 'components/common/Spinner';
import { StatusLabel } from 'components/common/StatusLabel';
import { ToggleField } from 'components/common/ToggleField';
import { useRef } from 'react';
import { Form, FormRenderProps } from 'react-final-form';
import { isFormHasValidationErrors } from 'utils/isFormHasValidationErrors';
import styles from './ConferenceStartedSettingsFormMobile.module.scss';
import { IDispatchProps, IFormProps, IOwnProps, IStateProps } from './types';
import { getConferenceStartedSettingsFormInitialValues, handleSubmitClick, validateConferenceStartedSettingsForm } from './utils';

export const ConferenceStartedSettingsFormMobile: React.FC<
    IStateProps & IDispatchProps & Partial<IOwnProps>
> = ({
    conferenceInfo,
    updateConference,
    handleAfterSubmit,
    onModified,
    resetModified,
    showNotification,
    handleCancel,
}) => {
    // Ref is used here because after the exit dialog is shown, the form is rerender and the entered values are reset to default
    const initialValues = useRef(getConferenceStartedSettingsFormInitialValues(conferenceInfo));

    const onSubmitClick = async (values: IFormProps) => {
        if (values.operation === 'save') {
            delete values.operation;
            const errors = await handleSubmitClick(
                values,
                {
                    showNotification,
                    updateConference,
                },
                conferenceInfo,
                handleAfterSubmit,
            );
            if (!errors) {
                resetModified();
            }
            return errors;
        }
        if (values.operation === 'cancel') {
            if (handleCancel) {
                handleCancel();
            }
            if (handleAfterSubmit) {
                handleAfterSubmit();
            }
        }
        resetModified();
    };

    const inputStyleType = 'mobileDark';
    const likeInputStyle = styles.likeInput;
    const noteStyle = styles.note;

    const render = ({
        handleSubmit,
        values,
        dirty,
        form,
        submitting,
        submitError,
        errors,
        dirtySinceLastSubmit,
    }: FormRenderProps<IFormProps>) => {
        setTimeout(() => onModified(dirty), 0);
        return (
            <form
                onSubmit={handleSubmit}
                className={styles.form}>
                <div className={`${styles.header} ${submitting ? styles.header_disabled : ''}`}>
                    <button
                        onClick={() => form.change('operation', 'cancel')}
                        disabled={submitting}>
                        <Trans id='Cancel' />
                    </button>
                    {submitting && <Spinner wrapperClass='spinner_formMobileLoader' />}
                    <button
                        onClick={() => form.change('operation', 'save')}
                        disabled={submitting || isFormHasValidationErrors(errors)}>
                        <Trans id='Ready' />
                    </button>
                </div>
                <div className={`${styles.submitErrorWrapper}`}>
                    <StatusLabel text={submitError && !dirtySinceLastSubmit ? submitError : ''} />
                </div>
                <MobileFormBlock
                    annotation={t({ id: 'Theme', message: 'Тема' })}
                    className={styles.margin_top_29}>
                    <Input
                        name='topic'
                        placeholder={t({
                            id: 'Enter the conference theme',
                            message: 'Введите тему конференции',
                        })}
                        styleType={inputStyleType}
                        autoFocus={true}
                        highlightTextOnError={!!errors?.topic}
                    />
                </MobileFormBlock>
                <MobileFormBlock
                    annotation={t({ id: 'Description', message: 'Описание' })}
                    className={styles.margin_top_14}>
                    <Input
                        name='description'
                        placeholder={t({
                            id: 'Enter a description',
                            message: 'Введите описание',
                        })}
                        styleType={inputStyleType}
                    />
                </MobileFormBlock>
                <MobileFormBlock
                    annotation={t({ message: 'Безопасность', id: 'Security' })}
                    className={`${styles.margin_top_24}`}>
                    <div className={likeInputStyle}>
                        <div>
                            <Trans
                                id='Only for registered users'
                                message='Только для зарегистрированных пользователей'
                            />
                        </div>
                        <ToggleField
                            styleType='mobile'
                            value={values.lobby}
                            name='registeredOnly'
                        />
                    </div>
                    <div
                        className={`${likeInputStyle} ${styles.likeInput_disabled}`}
                        style={{ borderBottom: 'none' }}>
                        <div>
                            <Trans id='Pin' message='Код доступа' />
                        </div>
                        <ToggleField
                            styleType='mobile'
                            value={values.pinUse}
                            name='pinUse'
                            disabled={true}
                        />
                    </div>
                    <Input
                        name='pin'
                        placeholder={t({ message: 'Код доступа', id: 'Pin' })}
                        disabled={true}
                        styleType={inputStyleType}
                        highlightTextOnError={!!errors?.pin}
                    />
                    <div className={noteStyle}>
                        <Trans id='Only users with an invitation link or an access code can enter the conference' />
                    </div>
                    <div className={likeInputStyle} style={{ marginTop: '19px' }}>
                        <div>
                            <Trans id='Waiting room' message='Зал ожидания' />
                        </div>
                        <ToggleField styleType='mobile' value={values.lobby} name='lobby' />
                    </div>
                    <div className={noteStyle}>
                        <Trans id='Only users approved by the organizer can enter the conference' />
                    </div>
                </MobileFormBlock>
                <MobileFormBlock
                    annotation={t({
                        message: 'Видео',
                        id: 'Video',
                    })}
                    className={`${styles.margin_top_31}`}>
                    <div className={likeInputStyle}>
                        <div>
                            <Trans id='Organizer' message='Организатор' />
                        </div>
                        <ToggleField
                            styleType='mobile'
                            value={values.videoOwner}
                            name='videoOwner'
                        />
                    </div>
                    <div className={likeInputStyle} style={{ marginTop: 0, borderTop: 'none' }}>
                        <div>
                            <Trans id='Participants' message='Участник' />
                        </div>
                    </div>
                </MobileFormBlock>
                <MobileFormBlock
                    annotation={t({
                        message: 'Параметры конференции',
                        id: 'Conference parameters',
                    })}
                    className={`${styles.margin_top_31} ${styles.margin_bottom_40}`}>
                    <div className={likeInputStyle}>
                        <div>
                            <Trans
                                id='Turn off the sound of participants when entering'
                                message='Выключать звук участников при входе'
                            />
                        </div>
                    </div>
                </MobileFormBlock>
            </form>
        );
    };
    return (
        <Form
            onSubmit={onSubmitClick}
            validate={validateConferenceStartedSettingsForm}
            render={render}
            initialValues={initialValues.current}
        />
    );
};
