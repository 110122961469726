import { parse } from 'date-fns';
import { getCurrentDate } from './getCurrentDate';
import { getMsByTemplate } from './getMsByTemplate';
import { removeTAndZFromISOFormat } from './removeTAndZFromISOFormat';

export const convertStringToDateByTemplate = (
    date: string,
    template: string,
    removeISOSpecialSymbols = true,
) => {
    const restOfDate = date.split('.')[0];
    const ms = getMsByTemplate(date, template);
    const newDate = ms ? restOfDate + '.' + ms : date;
    return parse(
        removeISOSpecialSymbols ? removeTAndZFromISOFormat(newDate) : newDate,
        template,
        getCurrentDate(),
    );
};
