import { IAdminPanelUser } from 'admin-app/types/IAdminPanelUser';
import { IUserCardFormFields } from './types';
import { EUserRoles } from 'admin-app/constants/EUserRoles';
import { TFormUserRoles } from 'admin-app/types/TFormUserRoles';

export const getInitialValues = (user: IAdminPanelUser | undefined | null): IUserCardFormFields => {
    if (!user) {
        return {
            firstName: '',
            lastName: '',
            email: '',
            role: 'participant' as const,
            activated: false,
            avatarWasChanged: false,
        };
    }
    const { firstName, lastName, email, roles, activated } = user;
    let resultRole: TFormUserRoles | 'participant' = 'participant';

    if (roles) {
        const specialRoles = roles.find(
            (role) => role === EUserRoles.ADMIN || role === EUserRoles.ORGANIZER,
        );
        if (specialRoles) {
            resultRole = specialRoles;
        }
    }

    return {
        firstName,
        lastName,
        email,
        role: resultRole,
        activated,
        avatarWasChanged: false,
    };
};
