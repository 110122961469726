import { createAsyncThunk } from '@reduxjs/toolkit';
import { deleteFromWaitingListAction } from 'actions/lobby/deleteFromWaitingListAction';
import { getNetworkService } from 'api/getNetworkService';
import { sendPermissionToJoinConferenceApiCall } from 'api/lobby/sendPermissionToJoinConferenceApiCall';
import { showNotifyThunkAction } from 'thunk/notify/showNotifyThunkAction';
import { getDefaultErrorNotification } from 'utils/getDefaultErrorNotification';
import { log } from 'utils/log';

interface IPayload {
    conferenceId: string;
    participantId: string;
    status: 'allow' | 'forbid';
    name: string;
}

export const allowedJoinConferenceThunkAction = createAsyncThunk(
    'allowedJoinConference',
    async (permission: IPayload, { dispatch }) => {
        const response = await sendPermissionToJoinConferenceApiCall(
            getNetworkService(),
            permission,
        );
        if (response.success) {
            dispatch(deleteFromWaitingListAction(permission.participantId));
        } else {
            log.error('Failed sending permission for join');
            dispatch(showNotifyThunkAction(getDefaultErrorNotification()));
        }
    },
);
