import { setParticipantsListOpenAction } from 'actions/applicationView/setParticipantsListOpenAction';
import { connect } from 'react-redux';
import { getCurrentConferenceConnectInfoSelector } from 'selectors/getCurrentConferenceConnectInfoSelector';
import { TAppDispatch } from 'types/app/TAppDispatch';
import { TAppState } from 'types/app/TAppStore';
import { ConferenceParticipantsListMobile } from './ConferenceParticipantsListMobile';
import { IDispatchProps, IStatePropsMobile } from './types';

const mapStateToProps = (state: TAppState): IStatePropsMobile => ({
    conferenceConnectInfo: getCurrentConferenceConnectInfoSelector(state),
    currentConferenceParticipants: state.conferenceSession.currentConferenceParticipants,
    currentConferenceParticipantsIdsSorted: state.conferenceSession.currentConferenceSortedParticipantsIds,
    currentUserIsAnonym: state.auth.anonymous,
    ownParticipantId: state.janus.ownParticipantId,
    participantsListOpen: state.applicationView.participantListOpen,
    publishers: state.janus.publishers,
});

const mapDispatchToProps = (dispatch: TAppDispatch): IDispatchProps => ({
    setParticipantsListOpen: (newState: boolean) =>
        dispatch(setParticipantsListOpenAction(newState)),
});

export const ConferenceParticipantsListMobileConnected = connect(
    mapStateToProps,
    mapDispatchToProps,
)(ConferenceParticipantsListMobile);
