import { t } from '@lingui/macro';
import { Button } from 'components/common/Button';
import styles from './DynamicFormFooter.module.scss';
import { IOwnProps } from './types';

export const DynamicFormFooter: React.FC<IOwnProps> = ({ pristine, submitting, onResetClick }) => {
    const disableButton = pristine || submitting;
    return (
        <div className={styles.dynamicFormFooter}>
            <Button
                styleType='secondaryActionNew'
                caption={t({ message: 'Отмена', id: 'Cancel' })}
                clickHandler={onResetClick}
                type='button'
                disabled={disableButton}
            />
            <Button
                styleType='primaryActionNew'
                caption={t({ message: 'Сохранить', id: 'save' })}
                type='submit'
                disabled={disableButton}
            />
        </div>
    );
};
