import { createAction } from '@reduxjs/toolkit';
import { EConferenceSessionActions } from 'constants/actions/EConferenceSessionActions';

interface IStoreCurrentConferencePermissionsActionPayload {
    allowedStreams?: {
        audio: string[];
        video: string[];
    };
    forbiddenStreams?: {
        audio: string[];
        video: string[];
    };
}

export const storeCurrentConferencePermissionsAction =
    createAction<IStoreCurrentConferencePermissionsActionPayload>(
        EConferenceSessionActions.STORE_CURRENT_CONFERENCE_PERMISSIONS,
    );
