export enum EFormattedVideoResolution {
    LOW_RES = 'lowres (320x180)',
    STD_RES = 'stdres (640x360)',
    HI_RES = 'hires (1280x720)',
    FHD_RES = 'fhdres (1920x1080)',
    ULTRA_RES = '4kres (3840x2160)',
}

export enum EVideoResolution {
    LOW_RES = 'lowres-16:9',
    STD_RES = 'stdres-16:9',
    HI_RES = 'hires-16:9',
    FHD_RES = 'fhdres',
    ULTRA_RES = '4kres',
}
