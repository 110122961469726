import { EPaths } from 'constants/EPaths';
import { connect } from 'react-redux';
import { getConferenceInfoThunkAction } from 'thunk/conference/getConferenceInfoThunkAction';
import { TAppDispatch } from 'types/app/TAppDispatch';
import { redirect } from 'utils/redirect';
import { HomePageDesktop } from './HomePageDesktop';
import { IDesktopDispatchProps } from './types';

const mapDispatchToProps = (dispatch: TAppDispatch): IDesktopDispatchProps => ({
    getConferenceData: (id: string) => dispatch(getConferenceInfoThunkAction({ id })),
    goToConnect: (id) => {
        redirect(`${EPaths.CONNECT_TO_CONFERENCE}/${id}`);
    },
});

export const HomePageDesktopConnected = connect(null, mapDispatchToProps)(HomePageDesktop);
