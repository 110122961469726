import { compareAsc, parseISO } from 'date-fns';
import { IPastConferenceInfo } from 'types/conferencesList/IPastConferenceInfo';

/**
 * @param sortedConferencesId Must be sorted from larger to smaller
 */

export const getConferencePositionInList = (
    sortedConferencesId: string[],
    conferences: { [key: string]: IPastConferenceInfo },
    targetConference: IPastConferenceInfo,
): { targetId: string; isAfterTarget?: boolean } | undefined => {

    if (sortedConferencesId.length === 0) {
        return;
    }

    if (sortedConferencesId.length === 1) {
        return compareAsc(
            parseISO(targetConference.timeStart),
            parseISO(conferences[sortedConferencesId[0]].timeStart),
        ) < 0
            ? {targetId: sortedConferencesId[0], isAfterTarget: true}
            : {targetId: sortedConferencesId[0]};
    }

    const middlePoint =
        sortedConferencesId.length > 2 ? Math.floor((sortedConferencesId.length - 1) / 2) : 1;
    const middlePointConferenceId = sortedConferencesId[middlePoint];
    const middlePointConference = conferences[middlePointConferenceId];

    const targetConferenceStartDate = parseISO(targetConference.timeStart);
    const middlePointConferenceStartDate = parseISO(middlePointConference.timeStart);
    const comparisonResult = compareAsc(targetConferenceStartDate, middlePointConferenceStartDate);
    const isTargetDataBefore = comparisonResult < 0;
    const isTargetDataAfter = comparisonResult > 0;

    if (isTargetDataAfter) {
        return getConferencePositionInList(
            sortedConferencesId.slice(0, middlePoint),
            conferences,
            targetConference,
        );
    }
    if (isTargetDataBefore) {
        return getConferencePositionInList(
            sortedConferencesId.slice(-middlePoint),
            conferences,
            targetConference,
        );
    }

    if (comparisonResult === 0) {
        return {targetId: middlePointConferenceId};
    }
};
