import { getEmotionsAssetsTypeAll } from 'utils/emotionsAssets';

type TTypes = 'List' | 'Frame' | 'Avatar' | 'Chat';

export const getEmotionImg = (name: string, type: TTypes) => {
    const emotionsAssetsTypeAll = getEmotionsAssetsTypeAll();
    let file;
    if (emotionsAssetsTypeAll) {
        const item = emotionsAssetsTypeAll.keys().find((item) => item === `./${name}${type}.svg`);
        if (item) {
            file = emotionsAssetsTypeAll(item);
        }
        if (file) return file.default;
    }
    return file;
};
