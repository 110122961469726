import { createContext } from 'react';
import { applicationStore } from 'store/applicationStore';
import { TAppState } from 'types/app/TAppStore';
import { IConferenceChatMessage } from 'types/chat/IConferenceChatMessage';
import { IConferenceChatContext } from './types';

export const isMessageCounted = (chatMessages: IConferenceChatMessage[]) => {
    const {
        janus: { ownParticipantId },
    } = applicationStore.getState() as TAppState;
    const lastMessage = chatMessages[chatMessages.length - 1];
    const skipMessage =
        chatMessages.length !== 0 &&
        (!!lastMessage.contentType?.match('system') || lastMessage.confUserId === ownParticipantId);
    return skipMessage;
};

const conferenceChatContextInitial: IConferenceChatContext = {
    handleImageLoad: () => {},
};

export const ConferenceChatContext = createContext<IConferenceChatContext>(
    conferenceChatContextInitial,
);
