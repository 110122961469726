import { setParticipantsListOpenAction } from 'actions/applicationView/setParticipantsListOpenAction';
import { setJanusStreamPropsAction } from 'actions/janus/setJanusStreamPropsAction';
import { connect } from 'react-redux';
import { TAppDispatch } from 'types/app/TAppDispatch';
import { TAppState } from 'types/app/TAppStore';
import { ConferencePageMobileFooter } from './ConferencePageMobileFooter';
import { IDispatchProps, IStateProps } from './types';

const mapStateToProps = (state: TAppState): IStateProps => ({
    localAudioVideoProps: state.janus.localStreamProps,
    participantsCount: state.conferenceSession.currentConferenceSortedParticipantsIds.length,
    participantsListOpen: state.applicationView.participantListOpen,
    videoRoomConnected: state.janus.videoRoomConnected,
    canPublish: state.janus.canPublish,
});

const mapDispatchToProps = (dispatch: TAppDispatch): IDispatchProps => ({
    setParticipantsListOpen: (newState) => dispatch(setParticipantsListOpenAction(newState)),
    changeAudioVideoProps: (args) => {
        dispatch(setJanusStreamPropsAction(args));
    },
});

export const ConferencePageMobileFooterConnected = connect(
    mapStateToProps,
    mapDispatchToProps,
)(ConferencePageMobileFooter);
