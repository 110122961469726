import { FullScreenPopup } from 'components/common/FullScreenPopup';
import { IOwnProps, IDispatchProps } from './types';
import { PopupDialog } from 'components/common/PopupDialog';
import { Trans } from '@lingui/react';
import { Button } from 'admin-app/components/common/Button';
import styles from './DellWebHookDialog.module.scss';
import { t } from '@lingui/macro';
import { classNames } from 'utils/classNames';

export const DellWebHookDialog: React.FC<IOwnProps & IDispatchProps> = ({
    id,
    url,
    dellWebHook,
    closeDialog,
}) => {
    const title = t({ id: 'admin.hook.deleteDialog.title', message: 'Удалить веб-хук?' });
    const onDeleteClick = async () => {
        const error = await dellWebHook(id);
        if (!error) {
            closeDialog();
        }
    };
    return (
        <FullScreenPopup closePopup={closeDialog}>
            <PopupDialog
                crossPosition='inside'
                onClose={closeDialog}
                dialogClassName={styles.dialog}
                titleClassName={styles.dialog__header}
                title={title}>
                <div className={styles.dialog__content}>
                    <span className={styles.dialog__description}>{url}</span>
                    <div className={styles.dialog__controls}>
                        <Button
                            className={classNames([styles.dialog__btn, styles.dialog__btn_cancel])}
                            onClick={closeDialog}>
                            <Trans id='cancel' message='Отмена' />
                        </Button>
                        <Button
                            className={classNames([styles.dialog__btn, styles.dialog__btn_delete])}
                            onClick={onDeleteClick}>
                            <Trans id='dell' message='Удалить' />
                        </Button>
                    </div>
                </div>
            </PopupDialog>
        </FullScreenPopup>
    );
};
