import { t } from '@lingui/macro';
import { WebHookDialog } from '../WebHookDialog';
import { IDispatchProps, IOwnProps } from './types';

export const CreateWebHookDialog: React.FC<IOwnProps & IDispatchProps> = ({
    closeDialog,
    createWebHook,
}) => {
    const dialogTitle = t({ id: 'admin.hook.createDialog.title', message: 'Новый веб-хук' });
    const buttonText = t({ id: 'admin.hook.add', message: 'Добавить' });

    return (
        <WebHookDialog
            title={dialogTitle}
            closeDialog={closeDialog}
            buttonText={buttonText}
            handleSubmit={createWebHook}
        />
    );
};
