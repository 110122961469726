import { t } from '@lingui/macro';
import React from 'react';
import { IDispatchProps, IStateProps } from './types';
import { ConfirmDialogResponsive } from 'components/common/ConfirmDialog';

export const UserConfirmationDialog: React.FC<IStateProps & IDispatchProps> = ({
    awaitUserConfirmation,
    redirect,
    close,
}) => {
    if (!awaitUserConfirmation) {
        return null;
    }
    return (
        <ConfirmDialogResponsive
            handlePrimaryAction={close}
            handleSecondaryAction={redirect}
            handleClose={close}
            textPrimaryAction={t({ id: 'Continue editing', message: 'Continue editing' })}
            textHeader={t({
                id: 'Exit without saving text',
                message: 'Изменения не сохранены',
            })}
            textBody={t({
                id: 'dialog.description',
                message:
                    'Вы можете выйти без сохранения — тогда изменения будут потеряны, или продолжить редактирование',
            })}
            textSecondaryAction={t({ id: 'Exit without saving', message: 'Exit without saving' })}
        />
    );
};
