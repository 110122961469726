import { t } from '@lingui/macro';
import { EErrorCodes } from 'constants/EErrorCodes';

export const getResultErrorString = ({
    errorCode,
    errorDescription,
}: {
    errorDescription: string;
    errorCode: string;
}) => {
    if (errorCode === EErrorCodes.INVALID_PARAMETER) {
        if (errorDescription.match('name size')) {
            return t({
                id: 'scheduleConferenceErrors.conferenceName',
                message: 'Слишком длинная тема',
            });
        }
        if (errorDescription.match('pin size')) {
            return t({
                id: 'scheduleConferenceErrors.conferencePin',
                message: 'Слишком длинный код',
            });
        }
        if (errorDescription.match('description size')) {
            return t({
                id: 'scheduleConferenceErrors.conferenceDescription',
                message: 'Слишком длинное описание',
            });
        }
    }
};