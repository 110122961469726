import { TAppDispatch } from 'types/app/TAppDispatch';
import { TAppState } from 'types/app/TAppStore';
import { IDispatchProps, IStateProps } from './types';
import { IConferenceConnectInfo } from 'types/conferences/IConferenceConnectInfo';
import { addUsersTimeZoneToDate } from 'utils/dates/addUsersTimeZoneToDate';
import { convertStringToDateByTemplate } from 'utils/dates/convertStringToDateByTemplate';
import { convertDateToStringByTemplate } from 'utils/dates/convertDateToStringByTemplate';
import { ru } from 'date-fns/locale';
import { TLanguages } from 'types/TLanguages';
import { t } from '@lingui/macro';
import { EPaths } from 'constants/EPaths';
import { IBreadcrumb } from 'components/common/Breadcrumbs/types';
import { prepareToConnectToConferenceThunkAction } from 'thunk/conference/prepareToConnectToConferenceThunkAction';
import { setScrollOffsetAndRedirectToReportThunkAction } from 'thunk/conferenceReport/setScrollOffsetAndRedirectToReportThunkAction';
import { getDataForDetailsPageThunkAction } from 'thunk/conference/getDataForDetailsPageThunkAction';

export const mapStateToProps = (state: TAppState): IStateProps => ({
    conferencesConnectInfo: state.conferences.conferencesConnectInfo,
    currentUserId: state.auth.currentUser?.id as string,
    lang: state.auth.currentUser?.language,
    conferencesInvitedUsers: state.conferences.conferencesInvitedUsers,
    conferencesParticipants: state.conferences.conferencesParticipants,
});
export const mapDispatchToProps = (dispatch: TAppDispatch): IDispatchProps => ({
    prepareToConnectToConference: (conferenceId) =>
        dispatch(prepareToConnectToConferenceThunkAction({ conferenceId })),
    goToConferenceHistory: (data) => dispatch(setScrollOffsetAndRedirectToReportThunkAction(data)),
    getConferenceData: (data) => dispatch(getDataForDetailsPageThunkAction(data)),
});

export const getFormattedDate = (conference: IConferenceConnectInfo, lang?: TLanguages): string => {
    if (!conference?.timeStart) {
        return '';
    }
    const options: { locale?: Locale } = {};
    if (lang === 'ru') {
        options.locale = ru;
    }
    const dateTimeStartConference = addUsersTimeZoneToDate(
        convertStringToDateByTemplate(conference.timeStart, 'yyyy-MM-dd HH:mm:ss.SSSSSS'),
    );
    const formattedDateTimeStartConference = convertDateToStringByTemplate(
        dateTimeStartConference,
        'eeee, dd MMMM yyyy, HH:mm',
        options,
    );
    dateTimeStartConference.setMinutes(dateTimeStartConference.getMinutes() + conference.duration);
    const timeEndConference = convertDateToStringByTemplate(dateTimeStartConference, 'HH:mm');
    return `${formattedDateTimeStartConference[0].toUpperCase()}${formattedDateTimeStartConference.slice(
        1,
    )}-${timeEndConference}`;
};

export const getBreadcrumbsItems = (
    conference: IConferenceConnectInfo,
    isPastConference: boolean,
): IBreadcrumb[] => {
    const items: IBreadcrumb[] = [
        { name: t({ id: 'account.conferences' }), link: EPaths.UPCOMING_CONFERENCES },
    ];
    if (isPastConference) {
        items.push({ name: t({ id: 'Past' }), link: EPaths.PAST_CONFERENCES });
    } else {
        items.push({ name: t({ id: 'Upcoming' }), link: EPaths.UPCOMING_CONFERENCES });
    }
    items.push({ name: conference.name });
    return items;
};
