import { t } from '@lingui/macro';
import { Avatar } from 'components/common/Avatar';
import { ImageViewPopupConnected } from 'components/common/ImageViewPopup/ImageViewPopupConnected';
import { EChatMessageType } from 'constants/EChatMessageType';
import React, { useState } from 'react';
import { IConferenceChatMessage } from 'types/chat/IConferenceChatMessage';
import { isImageFile } from 'utils/files/isImageFile';
import { getParticipantAvatar } from 'utils/participants/getParticipantAvatar';
import { getParticipantInitials } from 'utils/participants/getParticipantInitials';
import { getUserColor } from 'utils/users/getUserColor';
import { ConferenceChatMessage } from './ConferenceChatMessage/ConferenceChatMessage';
import styles from './ConferenceChatMessages.module.scss';
import { ConferenceChatSystemMessage } from './ConferenceChatSystemMessage';
import { IOwnProps, IStateProps } from './types';
import { findParticipant, getConvertedDateString, parseNodeDataAttr } from './utils';

export const ConferenceChatMessages: React.FC<IOwnProps & IStateProps> = ({
    chatMessages,
    isAnonymous,
    existAndExitParticipants,
    authUserId,
    anonymId,
    conferenceId,
    sessionId,
    locale,
}) => {
    const [originalImage, setOriginalImage] = useState<{
        name: string;
        time: string;
        id: string;
        fileName: string;
    } | null>(null);
    const clickHandler = (e: React.MouseEvent) => {
        const target = e.target;
        if (!target) {
            return;
        }
        const tagName = (target as HTMLElement)?.tagName;
        if (tagName !== 'IMG') {
            return;
        }
        const parsedData = parseNodeDataAttr(target as HTMLElement);
        if (!parsedData) {
            return;
        }
        const targetParticipant = parsedData.authorId
            ? findParticipant(parsedData.authorId, existAndExitParticipants)
            : null;
        setOriginalImage(() => {
            return {
                name: targetParticipant?.name || '',
                time: getConvertedDateString(parsedData.time, locale),
                id: parsedData.id,
                fileName: parsedData.fileName,
            };
        });
    };

    const renderChatMessage = ({
        text,
        time,
        id,
        status,
        confUserId,
        ownUserId,
        contentType,
        files,
    }: IConferenceChatMessage) => {
        if (
            contentType === EChatMessageType.SYSTEM_JOIN ||
            contentType === EChatMessageType.SYSTEM_LEAVE
        ) {
            const messageText = () => {
                return contentType === EChatMessageType.SYSTEM_LEAVE ? (
                    <ConferenceChatSystemMessage
                        event={t({
                            id: 'left.conference',
                            message: 'покинул(-а)',
                        })}
                        text={text}
                        time={time}
                        key={id}
                    />
                ) : (
                    <ConferenceChatSystemMessage
                        event={t({
                            id: 'joined.conference',
                            message: 'присоединился(-ась)',
                        })}
                        text={text}
                        time={time}
                        key={id}
                    />
                );
            };
            return messageText();
        }

        let avatar: string | undefined;
        let initials = '';
        let userColor = '';
        const isOwnMessage = isAnonymous ? confUserId === anonymId : authUserId === ownUserId;
        const authorData = !isOwnMessage
            ? existAndExitParticipants.find((user) => {
                if (!user?.userId) {
                    return user.participantId === confUserId;
                }
                return user.userId === ownUserId;
            })
            : undefined;
        if (existAndExitParticipants && authorData) {
            avatar = isAnonymous ? undefined : getParticipantAvatar(authorData);
            initials = getParticipantInitials(authorData.name);
            userColor = getUserColor(authorData.name);
        }
        return (
            <div className={styles.wrapper} key={id}>
                {!isOwnMessage && (
                    <Avatar
                        image={avatar}
                        initials={initials}
                        style={{
                            marginRight: '5px',
                            textTransform: 'uppercase',
                            flexGrow: 0,
                            flexShrink: 0,
                        }}
                        userBgColor={userColor}
                    />
                )}
                <ConferenceChatMessage
                    isOwnMessage={isOwnMessage}
                    name={authorData?.name}
                    text={text}
                    contentType={contentType}
                    status={status}
                    authorId={ownUserId || confUserId}
                    conferenceId={conferenceId}
                    sessionId={sessionId}
                    time={time}
                    files={files}
                />
            </div>
        );
    };

    const closeImagePopup = () => setOriginalImage(null);
    return (
        <div className={styles.messagesContainer} onClick={clickHandler}>
            {chatMessages.map((message: IConferenceChatMessage) => {
                //Display always only one image
                if (
                    message?.files &&
                    message.files.length > 1 &&
                    message.files.some((file) => isImageFile(file.name))
                ) {
                    return message.files.map((mediaFile) => {
                        return renderChatMessage({
                            ...message,
                            ...{ files: [mediaFile], id: mediaFile.id },
                        });
                    });
                }
                return renderChatMessage(message);
            })}
            {originalImage && (
                <ImageViewPopupConnected
                    id={originalImage.id}
                    sessionId={sessionId}
                    conferenceId={conferenceId}
                    sendTime={originalImage.time}
                    authorName={originalImage.name}
                    onClick={closeImagePopup}
                    fileName={originalImage.fileName}
                />
            )}
        </div>
    );
};
