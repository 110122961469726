import { t } from '@lingui/macro';
import { Trans } from '@lingui/react';
import { Button } from 'components/common/Button/Button';
import { Input } from 'components/common/Input/Input';
import { Spinner } from 'components/common/Spinner';
import { StatusLabel } from 'components/common/StatusLabel/StatusLabel';
import { useEffect } from 'react';
import { Form, FormRenderProps } from 'react-final-form';
import { IRequestError } from 'types/api/IRequestError';
import styles from './RegistrationForm.module.scss';
import { IDispatchProps, IFormRenderProps, IStateProps } from './types';
import { getSubmitErrorText, processSubmitError, validate } from './utils';

export const RegistrationFormMobile: React.FC<IStateProps & IDispatchProps> = ({
    loggedIn,
    goToPersonalInfoPage,
    register,
    showNotificationError,
}) => {
    useEffect(() => {
        if (loggedIn) {
            goToPersonalInfoPage();
        }
    }, [loggedIn]);

    const handleSubmit = async (values: IFormRenderProps) => {
        const { payload: result } = await register({
            email: values.email,
            password: values.password,
        });
        if (result) {
            return processSubmitError(result as IRequestError, showNotificationError);
        }
    };

    const render = ({
        handleSubmit,
        submitting,
        errors,
        submitError,
        dirtySinceLastSubmit,
        active,
        dirty,
    }: FormRenderProps<IFormRenderProps>) => (
        <form onSubmit={handleSubmit} className={`${styles.form} ${styles.form_mobile}`}>
            <StatusLabel
                text={getSubmitErrorText(submitError,dirtySinceLastSubmit)}
                className={styles.fail}
            />
            <div className={styles.block}>
                <label className={`${styles.label} ${styles.label_mobile}`} htmlFor='email'>
                    <Trans id='Email' message='Электронная почта' />
                </label>
                <Input
                    placeholder={t({ id: 'Email', message: 'Электронная почта' })}
                    name='email'
                    type='text'
                    className={styles.inputStyle_mobile}
                    touched={false}
                    errorText={errors?.email}
                    styleType='mobile'
                    errorWithoutConditions={!active && dirty && !!errors?.email}
                />
            </div>
            <div className={styles.block}>
                <label className={`${styles.label} ${styles.label_mobile}`} htmlFor='password'>
                    <Trans id='Password' message='Пароль' />
                </label>
                <Input
                    placeholder={t({ id: 'Password', message: 'Пароль' })}
                    name='password'
                    type='password'
                    className={styles.inputStyle_mobile}
                    styleType='mobile'
                    errorText={errors?.password}
                    errorWithoutConditions={!active && dirty && !!errors?.password}
                />
            </div>
            <div className={styles.block}>
                <label
                    className={`${styles.label} ${styles.label_mobile}`}
                    htmlFor='passwordRepeat'>
                    <Trans id='Repeat password' message='Повторите пароль' />
                </label>
                <Input
                    placeholder={t({ id: 'Repeat password', message: 'Повторите пароль' })}
                    name='passwordRepeat'
                    type='password'
                    className={styles.inputStyle_mobile}
                    styleType='mobile'
                    errorText={errors?.passwordRepeat}
                    errorWithoutConditions={!active && dirty && !!errors?.passwordRepeat}
                />
            </div>
            <div className={`${styles.agreement} ${styles.agreement_mobile}`}>
                <Trans
                    id='create.and.accept'
                    message='Создавая аккаунт, вы принимаете <0>Правила приватности</0> и разрешаете <1>Обработку личных данных</1>'
                    components={{
                        0: <a href='/#' className={styles.rules} />,
                        1: <a href='/#' className={styles.persInfo} />,
                    }}
                />
            </div>
            <Button styleType='mobilePrimaryAction' disabled={submitting} type='submit'>
                {submitting ? <Spinner /> : t({ id: 'Registration', message: 'Регистрация' })}
            </Button>
        </form>
    );

    return <Form onSubmit={handleSubmit} validate={validate} render={render} />;
};
