import { INetworkService } from 'types/api/INetworkService';

interface IPayload {
    conferenceId: string;
    sessionId: string;
    media: FormData;
}
export const saveChatMediaApiCall = (
    networkService: INetworkService,
    { media, conferenceId, sessionId }: IPayload,
) =>
    networkService.post({
        url: `/api/conferences/${conferenceId}/sessions/${sessionId}/media_files`,
        data: media,
    });
