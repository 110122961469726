import { setParticipantsListOpenAction } from 'actions/applicationView/setParticipantsListOpenAction';
import { connect } from 'react-redux';
import { TAppDispatch } from 'types/app/TAppDispatch';
import { TAppState } from 'types/app/TAppStore';
import { AudioFrames } from './AudioFrames';
import { IDispatchProps, IStateProps } from './types';

const mapStateToProps = (state: TAppState): IStateProps => ({
    anonymousUser: state.auth.anonymousUser,
    currentConferenceParticipants: state.conferenceSession.currentConferenceParticipants,
    currentParticipantId: state.janus.ownParticipantId,
    currentUser: state.auth.currentUser,
    displayedAudioFrames: state.applicationView.displayedAudioFrames,
    displayedVideoFrames: state.applicationView.displayedVideoFrames,
    fullScreenFrames: state.applicationView.fullScreenFrames,
    isMobile: state.applicationView.deviceProps.isTabletOrMobile,
    isParticipantsListOpen: state.applicationView.participantListOpen,
    ownEmotionToShow: state.janus.ownEmotionToShow,
    // ownStream: state.janus.ownStream,
    ownTalkingStatus: state.janus.ownTalkingStatus,
    participants: state.applicationView.participantFrames,
    participantsAudio: state.applicationView.participantsAudio,
    participantsEmotion: state.applicationView.participantsEmotion,
    participantsTalking: state.applicationView.participantsTalking,
    publishers: state.janus.publishers,
    streams: state.janus.streams,
});

const mapDispatchToProps = (dispatch: TAppDispatch): IDispatchProps => ({
    toggleParticipantsListState: (currentState) =>
        dispatch(setParticipantsListOpenAction(!currentState)),
});

export const AudioFramesConnected = connect(mapStateToProps, mapDispatchToProps)(AudioFrames);
