import { t } from '@lingui/macro';
import { DynamicFormConnected } from 'components/forms/DynamicForm/DynamicFormConnected';
import { conferenceSettings } from 'constants/appSettingsStructure';
import { AccountSettingsTemplate } from '../AccountSettingsTemplate/AccountSettingsTemplate';
import { ISettingsPartStateProps } from '../types';
import { getConferenceSettingsFormInitialValues } from './utils';

export const AccountConferenceSettings: React.FC<ISettingsPartStateProps> = ({ settings }) => (
    <AccountSettingsTemplate
        settingsTitle={t({
            id: 'accountSetting.conference',
            message: 'Настройки для новых конференций',
        })}
        settingsDescription={t({
            id: 'accountSetting.conference.description',
            message: 'Вы можете поменять это непосредственно перед созданием конфренеции.',
        })}>
        <DynamicFormConnected
            structure={[conferenceSettings]}
            initialValues={getConferenceSettingsFormInitialValues(settings as any)}
        />
    </AccountSettingsTemplate>
);
