import { TUserRole } from 'admin-app/types/IAdminPanelUser';
import { INetworkService } from 'types/api/INetworkService';


interface IPayload {
    userId: string;
    roles: TUserRole[]
}

export const editUserRolesApiCall = (
    networkService: INetworkService,
    { userId, roles }: IPayload,
) =>{    
    return networkService.put({
        url: `/api/admin/users/${userId}/privileges`,
        data: roles,
    });
}
