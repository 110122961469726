import { connect } from 'react-redux';
import { TAppState } from 'types/app/TAppStore';
import { SimpleConferenceHeader } from './SimpleConferenceHeader';
import { IStateProps } from './types';
import { getCurrentConferenceNameSelector } from 'selectors/getCurrentConferenceNameSelector';

const mapStateToProps = (state: TAppState): IStateProps => ({
    conferenceName: getCurrentConferenceNameSelector(state),
});

export const SimpleConferenceHeaderConnected = connect(
    mapStateToProps,
)(SimpleConferenceHeader);
