import { IOwnProps } from './types';
import styles from './DynamicFormSelect.module.scss';
import { SelectField } from 'components/common/SelectField';

export const DynamicFormSelect: React.FC<IOwnProps> = ({ items, name, title, description,displayFilteredHeader }) => (
    <div className={styles.field}>
        <div className={styles.field__text}>
            <label className={styles.field__title}>{title}</label>
            {description && (
                <span className={styles.field__description}>{description}</span>
            )}
        </div>
        <div data-form-control>
            <SelectField
                name={name}
                styleType='new'
                items={items}
                className={styles.field__select}
                displayFilteredHeader={displayFilteredHeader}
            />
        </div>
    </div>
);
