import { createAsyncThunk } from '@reduxjs/toolkit';
import { updateUpcomingConferenceAction } from 'actions/conferencesList/updateUpcomingConferenceAction';
import { TAppState } from 'types/app/TAppStore';
import { IUpcomingConferenceInfo } from 'types/conferencesList/IUpcomingConferenceInfo';
import { TConferenceStatus } from 'types/TConferenceStatus';

interface IUpdateExistingUpcomingConferenceStatusPayload {
    conferenceId: string;
    newConferenceStatus: TConferenceStatus;
}

export const updateExistingUpcomingConferenceStatusThunkAction = createAsyncThunk<
    void,
    IUpdateExistingUpcomingConferenceStatusPayload
>(
    'updateExistingUpcomingConferenceStatus',
    async ({ conferenceId, newConferenceStatus }, { dispatch, getState }) => {
        const {
            conferencesList: { upcomingConferences },
        } = getState() as TAppState;

        const upcomingConference: IUpcomingConferenceInfo | undefined =
            upcomingConferences[conferenceId];
        if (upcomingConference) {
            dispatch(
                updateUpcomingConferenceAction({
                    ...upcomingConference,
                    ...{ status: newConferenceStatus },
                }),
            );
        }
    },
);
