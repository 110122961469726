import { useEffect, useState } from 'react';

interface IOwnProps {
    isShow: boolean;
    animations: {
        fadeInClass?: string;
        fadeOutClass?: string;
    };
}

export const useAnimation = ({ isShow, animations }: IOwnProps) => {
    const [componentCycleState, setComponentCycleState] = useState<
        'mounted' | 'willUnmounted' | 'unmounted'
    >('unmounted');

    const handleAnimationEnd = () => {
        if (!isShow && componentCycleState === 'willUnmounted') {
            setComponentCycleState('unmounted');
        } else if (isShow && !componentCycleState) {
            setComponentCycleState('mounted');
        }
    };

    useEffect(() => {
        if (isShow && componentCycleState === 'unmounted') {
            setComponentCycleState('mounted');
        } else if (!isShow && componentCycleState === 'mounted') {
            setComponentCycleState('willUnmounted');
        }
    }, [isShow]);

    const resultObj = {
        isRender: componentCycleState !== 'unmounted',
        activeAnimationClass:
            componentCycleState === 'mounted'
                ? animations?.fadeInClass || ''
                : animations?.fadeOutClass || '',
        handleAnimationEnd,
    };

    return resultObj;
};
