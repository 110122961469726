declare global {
    interface Window {
        Janus?: any
    }
}

export const loadJanus = (): Promise<void> =>
    !window.Janus ? new Promise((resolve) => {
        const janusScript = document.createElement('script');
        janusScript.onload = function () {
            resolve();
        };
        janusScript.src = '/janus.js';
        const body = document.getElementsByTagName('body')[0];
        body.appendChild(janusScript);
    }) : Promise.resolve()