import { showAudioDialogPermissionAction } from 'actions/applicationView/showAudioDialogPermissionAction';
import { MediaPermissionDialogMobile } from 'pages/ConferencePage/MediaPermissionDialog/MediaPermissionDialogMobile';
import { connect } from 'react-redux';
import { TAppDispatch } from 'types/app/TAppDispatch';
import { IDispatchProps } from './types';

const mapDispatchToProps = (dispatch: TAppDispatch): IDispatchProps => ({
    closeMediaPermissionDialog: () => dispatch(showAudioDialogPermissionAction(false)),
});

export const MediaPermissionDialogMobileConnected = connect(
    null,
    mapDispatchToProps,
)(MediaPermissionDialogMobile);
