import { useEffect, useState } from 'react';
import { IDispatchProps, IStateProps } from './types';
import { getLogText } from './utils';
import styles from './ShowLog.module.scss';

export const ShowLog: React.FC<IStateProps & IDispatchProps> = ({
    conferenceChatOpen,
    log,
    clearLog,
}) => {
    const [showLog, setShowLog] = useState(false);
    const [logIsFadeOut, setLogIsFadeOut] = useState(false);

    const logAnimationEnds = () => {
        if (logIsFadeOut) {
            setShowLog(false);
            setLogIsFadeOut(false);
        }
    };

    useEffect(() => {
        if (!logIsFadeOut && log.length !== 0) {
            clearLog();
        }
    }, [logIsFadeOut]);

    useEffect(() => {
        if (log.length !== 0) {
            setShowLog(true);
            const timer = setTimeout(() => {
                setLogIsFadeOut(true);
            }, 5000);
            return () => clearTimeout(timer);
        }
    }, [log]);

    const logRender = () =>
        log.map((logString, index) => (
            <div key={`${index}`}>
                {logString?.name && <span>{logString.name}</span>} {getLogText(logString.event)}
            </div>
        ));

    const animationStyle = logIsFadeOut ? styles.itemFadeOut : styles.itemFadeIn;
    return (
        <>
            {showLog && (
                <div
                    className={`${styles.log} ${animationStyle}`}
                    onAnimationEnd={logAnimationEnds}>
                    {!conferenceChatOpen && logRender()}
                </div>
            )}
        </>
    );
};
