import { ConferenceAudioDevice } from 'components/ConferenceFooter/ConferenceDevice/ConferenceAudioDevice';
import { ConferenceVideoDevice } from 'components/ConferenceFooter/ConferenceDevice/ConferenceVideoDevice';
import { ConferenceEmotionsButtonConnected } from 'components/ConferenceFooter/ConferenceEmotionsButton';
import { ConferenceParticipantsButton } from 'components/ConferenceFooter/ConferenceParticipantsButton';
import { ConferenceScreenShareBtn } from 'components/ConferenceFooter/ConferenceScreenShareBtn';
import styles from './ConferencePageMobileFooter.module.scss';
import { ConferencePageMobilePaginationConnected } from './ConferencePageMobilePagination';
import { IDispatchProps, IStateProps } from './types';

export const ConferencePageMobileFooter: React.FC<IStateProps & IDispatchProps> = ({
    changeAudioVideoProps,
    localAudioVideoProps: {
        audioPermitted,
        mediaEnabled,
        screenShared,
        useAudio,
        useVideo,
        videoPermitted,
    },
    participantsCount,
    participantsListOpen,
    setParticipantsListOpen,
    // setStreamProps,
    videoRoomConnected,
    canPublish,
}) => (
    <div className={styles.root}>
        <div className={styles.buttons}>
            <ConferenceVideoDevice
                deviceList={[]}
                deviceListIsLoading={false}
                deviceOn={useVideo}
                devicePermittedByAdmin={videoPermitted}
                mediaEnabled={mediaEnabled}
                mobile={true}
                onChangeValue={() => changeAudioVideoProps({ useVideo: !useVideo })}
                readyToStream={videoRoomConnected}
                canPublish={canPublish}
                className={styles.videoDevice}
            />
            <ConferenceAudioDevice
                deviceList={[]}
                deviceListIsLoading={false}
                deviceOn={useAudio}
                devicePermittedByAdmin={audioPermitted}
                mediaEnabled={mediaEnabled}
                mobile={true}
                onChangeValue={() => changeAudioVideoProps({ useAudio: !useAudio })}
                readyToStream={videoRoomConnected}
                canPublish={canPublish}
            />
            <ConferenceScreenShareBtn
                handleChangeStatus={() =>
                    changeAudioVideoProps({ screenShared: !screenShared, useVideo })
                }
                videoPermittedByAdmin={videoPermitted}
                status={!videoPermitted ? 'disabled' : screenShared ? 'on' : 'off'}
                mobile={true}
            />
            <ConferenceEmotionsButtonConnected mobile={true} />
            <ConferenceParticipantsButton
                setParticipantsListOpen={setParticipantsListOpen}
                participantsListOpen={participantsListOpen}
                participantsCount={participantsCount}
                chatState={false}
                toggleChat={() => {}}
                mobile={true}
                className={styles.participantsBtn}
            />
        </div>
    </div>
);
