import { createAction } from '@reduxjs/toolkit';
import { EJanusActions } from 'constants/actions/EJanusActions';
import { EModerateMediaDeviceStatus } from 'constants/EModerateMediaDeviceStatus';

type eventType =
    | EModerateMediaDeviceStatus.AUDIO_DISABLED
    | EModerateMediaDeviceStatus.AUDIO_ENABLED
    | EModerateMediaDeviceStatus.VIDEO_DISABLED
    | EModerateMediaDeviceStatus.VIDEO_ENABLED;

export const addModeratedMediaToLogAction = createAction<eventType>(
    EJanusActions.ADD_MODERATED_MEDIA_TO_LOG,
);
