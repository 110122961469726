import { t } from '@lingui/macro';
import { Trans } from '@lingui/react';
import { CircleLoader } from 'components/common/CircleLoader';
import { DateFilterConnected } from 'components/common/DateFilter';
import { PlanConferenceButtonConnected } from 'components/common/PlanConferenceButton';
import { format } from 'date-fns';
import { useEffect, useState } from 'react';
import { getCurrentDate } from 'utils/dates/getCurrentDate';
import { downloadRecord } from 'utils/records/downloadRecord';
import { ConferenceRecord } from './ConferenceRecord';
import styles from './ConferencesRecordsPage.module.scss';
import { IDispatchProps, IStateProps } from './types';
import { useRenderRecordPlayer } from './useShowRecord';
import { getGroupedRecords } from './utils';

export const ConferencesRecordsPage: React.FC<IDispatchProps & IStateProps> = ({
    recordsList,
    changeFilter,
    filters,
    getConferenceRecordData,
    addRecordReadyNotification,
    deleteRecordReadyNotification,
    deleteRecord,
    recordDownloadList,
    defaultOperationPending,
    reset,
}) => {
    useEffect(() => {
        changeFilter(filters);
        // Fix for cashing
        return reset;
    }, []);

    const [inProgressIdList, setInProgressIdList] = useState<string[]>([]);
    const [recordToShow, getRecordHandleClick] = useRenderRecordPlayer(styles);

    const groupedRecords = getGroupedRecords(recordsList);
    const recordReadyBtnHandler = (conferenceId: string, notifyState: boolean) => {
        setInProgressIdList((prevVal) => {
            return [...prevVal, ...[conferenceId]];
        });
        !notifyState
            ? addRecordReadyNotification(conferenceId)
            : deleteRecordReadyNotification(conferenceId);
        setInProgressIdList((prevVal) => prevVal.filter((id) => id !== conferenceId));
    };
    const handleDownloadRecord = (data: { conferenceId: string; sessionId: string, recordId: string}) =>
        downloadRecord({
            ...data,
            getRecord: getConferenceRecordData,
        });

    const resultList = groupedRecords.map((item) => {
        const resultDay =
            item.day === format(getCurrentDate(), 'dd.MM.yyyy')
                ? t({ message: 'Сегодня', id: 'Today' })
                : item.day;

        return (
            <div className={styles.conferenceGroup} key={resultDay}>
                <div className={styles.date}>{resultDay}</div>
                {item.records.map((record) => {
                    const recordDownloadItem = recordDownloadList[record.id];
                    const isDisabled = !!inProgressIdList.find((id) => id === record.id);
                    return (
                        <ConferenceRecord
                            recordData={record}
                            isDisabled={isDisabled}
                            recordDownloadItem={recordDownloadItem}
                            getRecordHandleClick={getRecordHandleClick}
                            downloadRecord={handleDownloadRecord}
                            deleteRecord={deleteRecord}
                            recordReadyBtnHandler={recordReadyBtnHandler}
                            key={record.id}
                        />
                    );
                })}
            </div>
        );
    });
    
    return (
        <>
            <div className={styles.header}>
                <span>
                    <Trans id='Records' message='Записи' />
                </span>
                <PlanConferenceButtonConnected />
            </div>
            <DateFilterConnected
                start={filters.from}
                end={filters.to}
                handleDateChange={({ end, start }) => changeFilter({ from: start, to: end })}
                className={styles.dateFilter}
                maxDate={getCurrentDate()}
            />
            {!defaultOperationPending && <div className={styles.records}>{resultList}</div>}
            {defaultOperationPending && <CircleLoader color='blue' />}
            {recordToShow()}
        </>
    );
};
