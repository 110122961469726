import { createAsyncThunk } from '@reduxjs/toolkit';
import { storeConferenceConnectInfoAction } from 'actions/conferences/storeConferenceConnectInfoAction';
import { allowedJoinToConferenceAction } from 'actions/lobby/allowedJoinToConferenceAction';
import { getCurrentConferenceConnectInfoSelector } from 'selectors/getCurrentConferenceConnectInfoSelector';
import { getConferenceJoinRequestTopic } from 'services/socketService/getConferenceJoinRequestTopic';
import {
    addSubscription,
    clearSubscription,
    getSocketServiceState,
} from 'services/socketService/socketService';
import { exitFromConferenceThunkAction } from 'thunk/conference/exitFromConferenceThunkAction';
import { TAppState } from 'types/app/TAppStore';
import { IConferenceConnectInfo } from 'types/conferences/IConferenceConnectInfo';
import { ISocketJoinToConferenceResponse } from 'types/socket/ISocketJoinToConferenceResponse';
import { getConnectToSocket } from 'utils/connectToSocketProxy';
import { log } from 'utils/log';

export const subscribeToConferenceJoinRequestActionThunk = createAsyncThunk(
    'subscribeToConferenceJoinRequest',
    async (participantId: string, { dispatch, getState }) => {
        const {
            conferenceSession: { currentConferenceId },
        } = getState() as TAppState;
        if (!currentConferenceId) {
            return;
        }
        getConnectToSocket()(() => {
            const { stompClient } = getSocketServiceState();
            if (!stompClient) {
                log.error('Stomp client does not init.');
                return;
            }
            addSubscription(
                'subscribeToConferenceJoinRequest',
                stompClient.subscribe(
                    getConferenceJoinRequestTopic(participantId),
                    async (message) => {
                        const data: ISocketJoinToConferenceResponse = JSON.parse(message.body);
                        if (data.actionType === 'WAITING') {
                            return;
                        }
                        if (data.actionType === 'ALLOW') {
                            dispatch(allowedJoinToConferenceAction(true));
                            clearSubscription('subscribeToConferenceJoinRequest');
                        } else if (data.actionType === 'FORBID') {
                            dispatch(allowedJoinToConferenceAction(false));
                            clearSubscription('subscribeToConferenceJoinRequest');
                        } else if (data.actionType === 'FINISHED') {
                            const currentConferenceShortInfo =
                                getCurrentConferenceConnectInfoSelector(
                                    getState() as TAppState,
                                ) as IConferenceConnectInfo;
                            dispatch(
                                storeConferenceConnectInfoAction({
                                    ...currentConferenceShortInfo,
                                    status: 'FINISHED',
                                }),
                            );

                            dispatch(exitFromConferenceThunkAction({}));
                        }
                    },
                    { conferenceId: currentConferenceId },
                ),
            );
        }, 'subscribeToConferenceJoinRequest');
    },
);
