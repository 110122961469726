import { IParticipantFrames } from 'pages/ConferencePage/ParticipantsLayout/types';
import { getOverallFrameSize } from 'pages/ConferencePage/ParticipantsLayout/utils';
import { IAudioVideoProps } from 'types/IAudioVideoProps';
import { IApplicationViewReducer } from '../applicationViewReducer.types';

export function frameExists(state: IApplicationViewReducer, frameId: string) {
    return state.fullScreenFrames.find((id) => id === frameId) ||
        state.displayedAudioFrames.find((id) => id === frameId) ||
        state.displayedVideoFrames.find((id) => id === frameId)
        ? true
        : false;
}
export function getLastTalkingUserOrItself(state: IApplicationViewReducer): string {
    let lastTalkingUser = '';
    let lastActivity = -1;
    for (const id of [...state.displayedVideoFrames, ...state.displayedAudioFrames]) {
        if (state.lastActivity[id] && state.lastActivity[id] > lastActivity) {
            lastTalkingUser = id;
            lastActivity = state.lastActivity[id];
        }
    }
    return lastTalkingUser ? lastTalkingUser : state.ownParticipantId;
}
export function participantMediaWasChanged(
    state: IApplicationViewReducer,
    participantId: string,
    audioVideoProps: Partial<IAudioVideoProps>,
) {
    const { useAudio, useVideo, audioPermitted, screenShared } = audioVideoProps;
    if (useAudio == false || audioPermitted === false) {
        state.participantsTalking[participantId] = false;
    }
    if (
        screenShared !== undefined &&
        screenShared !== state.participantsScreenShared[participantId]
    ) {
        state.participantsScreenShared[participantId] = screenShared;
    }

    const participantLocalVideoMute = state.participantsLocalMuteStatus[participantId]?.video;
    if (participantLocalVideoMute !== undefined) {
        if (
            participantLocalVideoMute &&
            participantLocalVideoMute !== !state.participantsVideo[participantId]
        ) {
            state.participantsVideo[participantId] = false;
            if (state.fullScreenFrames[0] === participantId) {
                state.fullScreenFrames = [];
                state.participantsFullScreen = [];
            }
        } else if (
            !participantLocalVideoMute &&
            useVideo !== undefined &&
            useVideo !== state.participantsVideo[participantId]
        ) {
            state.participantsVideo[participantId] = useVideo;
        }
    } else if (useVideo !== undefined && useVideo !== state.participantsVideo[participantId]) {
        state.participantsVideo[participantId] = useVideo;
        if (useVideo === false) {
            const i = state.fullScreenFrames.indexOf(participantId);
            if (i !== -1) {
                // Reset fullscreen for user without video
                state.fullScreenFrames.splice(i, 1);
                state.participantsFullScreen = state.participantsFullScreen.filter(
                    (id) => id !== participantId,
                );
                state.participantsFullScreenGlobal = state.participantsFullScreenGlobal.filter(
                    (id) => id !== participantId,
                );
            }
        }
    }

    const participantLocalAudioMute = state.participantsLocalMuteStatus[participantId]?.audio;
    if (participantLocalAudioMute !== undefined) {
        if (
            participantLocalAudioMute &&
            participantLocalAudioMute !== !state.participantsAudio[participantId]
        ) {
            state.participantsAudio[participantId] = false;
        } else if (
            !participantLocalAudioMute &&
            useAudio !== undefined &&
            useAudio !== state.participantsAudio[participantId]
        ) {
            state.participantsAudio[participantId] = useAudio;
        }
    } else if (useAudio !== undefined && useAudio !== state.participantsAudio[participantId]) {
        state.participantsAudio[participantId] = useAudio;
    }
}
export function addOrModifyJanusPublisher(
    state: IApplicationViewReducer,
    participantId: string,
    audioVideoProps: IAudioVideoProps,
) {
    const idIdx = state.participantFrames.indexOf(participantId);
    if (idIdx !== -1) {
        participantMediaWasChanged(state, participantId, audioVideoProps);
        return;
    }
    state.participantFrames.push(participantId);
    state.participantsAudio[participantId] = audioVideoProps.useAudio;
    state.participantsVideo[participantId] = audioVideoProps.useVideo;
    //What it it?
    if (state.supposedFullScreen && state.supposedFullScreen === participantId) {
        state.fullScreenFrames = [participantId];
        state.participantsFullScreen = [participantId];
        state.supposedFullScreen = '';
    }
}

export function calculateDiffVideoFrames(state: IApplicationViewReducer, videoFrames: string[]) {
    const videoFramesExists = new Set(videoFrames);
    const newVideoFramesExists = new Set([
        ...state.displayedVideoFrames,
        ...state.fullScreenFrames,
    ]);
    const unsubscribe = videoFrames.filter(
        (id) => !newVideoFramesExists.has(id) && id !== state.ownParticipantId,
    );
    const subscribe = [...state.displayedVideoFrames, ...state.fullScreenFrames].filter(
        (id) => !videoFramesExists.has(id) && id !== state.ownParticipantId,
    );
    state.videoStreamsSubscriptionsUpdate = { subscribe, unsubscribe };
}

export function calculateFramesLayout(state: IApplicationViewReducer) {
    const videoFrames = [...state.displayedVideoFrames];
    if (state.fullScreenFrames.length !== 0) {
        state.displayedVideoFrames = [];
        const fullScreenFramesExists = new Set(state.fullScreenFrames);
        if (state.alwaysShowOwnStream && !fullScreenFramesExists.has(state.ownParticipantId)) {
            state.displayedAudioFrames = [
                state.ownParticipantId,
                ...state.participantFrames.filter(
                    (id) => !fullScreenFramesExists.has(id) && id !== state.ownParticipantId,
                ),
            ];
        } else {
            state.displayedAudioFrames = state.participantFrames.filter(
                (id) => !fullScreenFramesExists.has(id),
            );
        }
        calculateDiffVideoFrames(state, videoFrames);
        return;
    }
    if (!state.participantFrames.some((id) => state.participantsVideo[id])) {
        state.displayedAudioFrames = state.participantFrames.slice();
        state.displayedVideoFrames = [];
        calculateDiffVideoFrames(state, videoFrames);
        return;
    }
    state.displayedVideoFrames = [];
    const isExistUserWithVideo = Object.values(state.participantsVideo).some((is) => is);
    const isVideoFrame = (id: string) => {
        if (state.deviceProps.isTabletOrMobile && isExistUserWithVideo) {
            return true;
        }
        return state.participantsVideo[id];
    };
    const sortedVideoFrames = state.participantFrames
        .filter(isVideoFrame)
        .sort((a, b) => {
            if (state.alwaysShowOwnStream) {
                if (a === state.ownParticipantId) {
                    return -1;
                }
                if (b === state.ownParticipantId) {
                    return 1;
                }
            }
            const aVal = state.lastActivity[a] || 0;
            const bVal = state.lastActivity[b] || 0;
            return bVal - aVal;
        })
        .slice(0, state.maxVideoFrames);
    state.displayedAudioFrames = [];
    const videoFramesSet = new Set(sortedVideoFrames);
    if (
        state.ownParticipantId &&
        state.alwaysShowOwnStream &&
        videoFramesSet.has(state.ownParticipantId)
    ) {
        state.displayedVideoFrames.push(state.ownParticipantId);
    }
    for (let i = 0; i < state.participantFrames.length; ++i) {
        const id = state.participantFrames[i];
        if (!videoFramesSet.has(id)) {
            state.displayedAudioFrames.push(id);
            continue;
        }
        if (id === state.ownParticipantId && state.alwaysShowOwnStream) {
            // Already in displayed frames
            continue;
        }
        state.displayedVideoFrames.push(id);
    }
    calculateDiffVideoFrames(state, videoFrames);
    return;
}
export function clearEmotionTimer(state: IApplicationViewReducer) {
    for (const participantId in state.participantsEmotion) {
        const emotion = state.participantsEmotion[participantId];
        if (emotion) {
            clearTimeout(emotion.emotionTimer);
        }
    }
}

const view_width_padding = 20;
const view_height_padding = 10;
const width_padding = 15;
const height_padding = 15;

export function calculateParticipantFrameSize(state: IApplicationViewReducer): void {
    const { fullScreenFrames, participantsLayoutProperties, displayedVideoFrames } = state;
    const visibleParticipantsFrames: IParticipantFrames = {};
    state.displayedVideoFrames.forEach(
        (id) => (visibleParticipantsFrames[id] = state.participantsFrameSize[id]),
    );

    if (fullScreenFrames[0]) {
        state.displayedFrameSize = {
            width: participantsLayoutProperties.width,
            height: participantsLayoutProperties.height,
        };
        return;
    }

    const layoutWidth = participantsLayoutProperties.width - view_width_padding * 2;
    const layoutHeight = participantsLayoutProperties.height - view_height_padding * 2;
    const { width, height } = getOverallFrameSize(
        visibleParticipantsFrames,
        participantsLayoutProperties.ratio,
    );
    if (displayedVideoFrames.length === 1) {
        const a = Math.min(layoutWidth / width, layoutHeight / height);
        state.displayedFrameSize = {
            width: Math.floor(width * a),
            height: Math.floor(height * a),
        };
        return;
    }

    let result = { width: 1, height: 1 };
    for (let rows = 1; rows <= displayedVideoFrames.length; rows++) {
        const columns = Math.floor(
            displayedVideoFrames.length / rows + (displayedVideoFrames.length % rows ? 1 : 0),
        );
        const ratio = Math.min(
            layoutWidth / (width + width_padding * 2) / columns,
            layoutHeight / (height + height_padding * 2) / rows,
        );
        const newResult = {
            width: width * ratio,
            height: height * ratio,
        };
        if (newResult.width > result.width && newResult.height > result.height) {
            result = newResult;
        }
    }
    state.displayedFrameSize = {
        width: Math.floor(result.width),
        height: Math.floor(result.height),
    };
}

const defaultWidthRightPanel = 290;
export function calculateParticipantsLayoutSize({
    state,
    isParticipantsList = false,
    isShowChat = false,
}: {
    state: IApplicationViewReducer;
    isShowChat?: boolean;
    isParticipantsList?: boolean;
}) {
    if ((isShowChat && !state.participantListOpen) || (isParticipantsList && !state.showChat)) {
        state.participantsLayoutProperties.width =
            state.participantsLayoutProperties.width - defaultWidthRightPanel;
    } else if (
        (!isShowChat && state.showChat) ||
        (!isParticipantsList && state.participantListOpen)
    ) {
        state.participantsLayoutProperties.width =
            state.participantsLayoutProperties.width + defaultWidthRightPanel;
    }
}
