import { t } from '@lingui/macro';
import { Trans } from '@lingui/react';
import { Cross } from 'components/common/Cross';
import { FullScreenPopup } from 'components/common/FullScreenPopup';
import { loginCheckerId } from 'components/LoginChecker/LoginChecker';
import { useEffect, useState } from 'react';
import styles from './ConferenceShareDialog.module.scss';
import { ConferenceShareDialogInviteLinkConnected } from './ConferenceShareDialogInviteLink';
import { ConferenceShareDialogInviteUsersConnected } from './ConferenceShareDialogInviteUsers';
import { ConferenceShareDialogSection } from './ConferenceShareDialogSection';
import { IOwnProps } from './types';

export const ConferenceShareDialog: React.FC<IOwnProps> = ({
    conferenceInfo,
    closeDialog,
    isOwner,
}) => {
    const [popupContainer, setPopupContainer] = useState<HTMLElement | null>(null);
    
    const getContainer = () => {
        const container = document.getElementById(`${loginCheckerId}`);
        if (container) {
            setPopupContainer(container);
        }
    };

    useEffect(() => {
        getContainer();
    }, []);

    return (
        <FullScreenPopup closePopup={closeDialog} container={popupContainer}>
            <div className={styles.shareDialog}>
                <div className={styles.shareDialog__header}>
                    <span>
                        <Trans id='share' />
                    </span>
                    <Cross onClick={closeDialog} />
                </div>
                <ConferenceShareDialogSection
                    title={t({ id: 'Link', message: 'Ссылка' })}
                    description={t({
                        id: 'shareDialog.inviteLink.description',
                        message:
                            'Скопируйте ссылку: ее смогут открыть даже незарегистрированные пользователи.',
                    })}>
                    <ConferenceShareDialogInviteLinkConnected
                        id={conferenceInfo.id}
                        pin={conferenceInfo.pin as string}
                    />
                </ConferenceShareDialogSection>
                {isOwner && (
                    <ConferenceShareDialogSection
                        title={t({ id: 'Invite', message: 'Приглашение' })}
                        description={t({
                            id: 'shareDialog.invite.description',
                            message:
                                'Отправьте приглашение на конференцию по почте. Чтобы добавить получателей, введите email или начните писать имя участника.',
                        })}>
                        <ConferenceShareDialogInviteUsersConnected
                            conferenceInfo={conferenceInfo}
                            closeDialog={closeDialog}
                        />
                    </ConferenceShareDialogSection>
                )}
            </div>
        </FullScreenPopup>
    );
};
