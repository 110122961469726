import { connect } from 'react-redux';
import { TAppState } from 'types/app/TAppStore';
import { AccountPageMobile } from './AccountPageMobile';
import { IStatePropsMobile } from './types';

const mapStateToProps = (state: TAppState): IStatePropsMobile => ({
    rememberMeRequestStatus: state.auth.rememberMeRequestStatus,
});

export const AccountPageMobileConnected = connect(mapStateToProps)(AccountPageMobile);
