import { t } from '@lingui/macro';
import { Trans } from '@lingui/react';
import { Avatar } from 'components/common/Avatar';
import { Button } from 'components/common/Button';
import { FullScreenPopup } from 'components/common/FullScreenPopup';
import { Input } from 'components/common/Input';
import { MobileFormBlock } from 'components/common/MobileFormBlock';
import { SelectField } from 'components/common/SelectField';
import { Spinner } from 'components/common/Spinner';
import { StatusLabel } from 'components/common/StatusLabel';
import { FormApi } from 'final-form';
import { useEffect, useRef, useState } from 'react';
import { Field, Form, FormRenderProps } from 'react-final-form';
import { setEscCloseDropdown } from 'utils/escCloseDropdown';
import { isFormHasValidationErrors } from 'utils/isFormHasValidationErrors';
import { getLanguageList } from 'utils/languagesOperations';
import { getTimeZonesNamesList } from 'utils/timeZones/getTimeZonesNamesList';
import { getAvatarUrl } from 'utils/users/getAvatarUrl';
import { getUserInitials } from 'utils/users/getUserInitials';
import styles from './AccountInformationFormMobile.module.scss';
import { EmailChangeDialogMobileConnected } from './EmailChangeDialog/EmailChangeDialogMobileConnected';
import {
    IAccountInformationFormRenderProps,
    IDispatchProps,
    IOwnProps,
    IStateProps,
} from './types';
import { getInitialValues, onChangeAvatar, validate } from './utils';

const timeZonesList = getTimeZonesNamesList();
export const AccountInformationFormMobile: React.FC<IOwnProps & IDispatchProps & IStateProps> = ({
    user,
    canChangeAvatar,
    canEditPersonalData,
    maxAvatarSize,
    showNotification,
    submitHandler,
    logout,
}) => {
    const container = document.getElementById('loginChecker');
    const avatarRef = useRef<HTMLInputElement>(null);
    const [avatar, setAvatar] = useState<string | undefined>();
    const currentComponentRef = useRef(null);
    const [emailChangeDialogOpen, setEmailChangeDialogOpen] = useState(false);

    useEffect(() => {
        setEscCloseDropdown(emailChangeDialogOpen);
    }, [emailChangeDialogOpen]);

    const setAvatarFromServer = () => {
        if (user && user.etag) {
            setAvatar(getAvatarUrl({ id: user.id, etag: user.etag, size: '180' }));
        } else {
            setAvatar(undefined);
        }
    };

    useEffect(() => {
        setAvatarFromServer();
    }, [user]);

    const initials = getUserInitials({
        lastName: user?.name?.last,
        firstName: user?.name?.first,
        email: user?.contact.email,
    });

    const onSubmit = async (
        { operation, ...values }: IAccountInformationFormRenderProps,
        form: FormApi<IAccountInformationFormRenderProps>,
    ) => {
        if (operation === 'save') {
            if (values.phone) {
                values.phone = values.phone.replace(/\D/g, '');
            }            
            const result = await submitHandler({
                avatar,
                avatarInputNode: avatarRef.current,
                formValues: values,
                resetForm: () => form.reset(),
            });
            return result;
        } else if (operation === 'cancel') {
            form.reset();
        }
    };
    const removeAvatar = (form: any) => {
        if (avatar !== null) {
            form.change('avatarWasChanged', true);
        }
        setAvatar(undefined);
        if (avatarRef.current) {
            avatarRef.current.value = '';
        }
    };
    const handleCloseEmailChangeDialog = () => setEmailChangeDialogOpen(false);
    const showDellButton = (user?.etag && avatar) || avatar;
    const render = ({
        handleSubmit,
        submitting,
        submitError,
        errors,
        form,
        values,
        pristine,
        dirtySinceLastSubmit,
    }: FormRenderProps<IAccountInformationFormRenderProps>) => {
        const disableButton = pristine || submitting;
        return (
            <>
                {emailChangeDialogOpen && (
                    <FullScreenPopup
                        closePopup={handleCloseEmailChangeDialog}
                        container={container}
                        className={styles.popup}>
                        <EmailChangeDialogMobileConnected
                            handleClose={handleCloseEmailChangeDialog}
                        />
                    </FullScreenPopup>
                )}
                <form onSubmit={handleSubmit} className={styles.form} ref={currentComponentRef}>
                    <div
                        className={`${styles.header} ${
                            disableButton ? styles.header_disabled : ''
                        }`}>
                        <button
                            onClick={() => {
                                form.restart();
                                setAvatarFromServer();
                            }}
                            disabled={disableButton}>
                            <Trans id='Cancel' />
                        </button>
                        {submitting && <Spinner wrapperClass='spinner_formMobileLoader' />}
                        <button type='submit'
                            onClick={() => {
                                form.change('operation', 'save');
                            }}
                            disabled={disableButton || isFormHasValidationErrors(errors)}>
                            <Trans id='Ready' />
                        </button>
                    </div>
                    <StatusLabel
                        text={!dirtySinceLastSubmit && submitError ? submitError : ''}
                        className={styles.statusLabel}
                    />
                    <Field name='avatarWasChanged'>{() => null}</Field>
                    <div className={styles.avatar}>
                        <input
                            type='file'
                            className={styles.avatar__file}
                            ref={avatarRef}
                            onChange={() =>
                                onChangeAvatar(
                                    form,
                                    avatarRef,
                                    maxAvatarSize,
                                    showNotification,
                                    setAvatar,
                                )
                            }
                            accept='image/png, image/jpeg'
                        />
                        <Avatar
                            image={avatar}
                            initials={initials}
                            style={{
                                cursor: canChangeAvatar ? 'pointer' : 'default',
                                width: '150px',
                                height: '150px',
                                borderRadius: '50%',
                            }}
                            clickHandler={() =>
                                canChangeAvatar && avatarRef.current?.click()
                            }></Avatar>
                        {canChangeAvatar && showDellButton && (
                            <div
                                className={styles.avatar__delete}
                                onClick={() => removeAvatar(form)}>
                                <Trans message='Удалить фотографию' id='Delete photo' />
                            </div>
                        )}
                    </div>
                    <MobileFormBlock annotation={t({ id: 'First name' })} className={styles.block}>
                        <Input
                            name='firstName'
                            placeholder={t({
                                id: 'First name',
                            })}
                            styleType='mobile'
                            disabled={!canEditPersonalData}
                            errorWithoutConditions={errors?.firstName}
                        />
                    </MobileFormBlock>
                    <MobileFormBlock annotation={t({ id: 'Last name' })} className={styles.block}>
                        <Input
                            name='lastName'
                            placeholder={t({
                                id: 'Last name',
                            })}
                            styleType='mobile'
                            disabled={!canEditPersonalData}
                            errorWithoutConditions={errors?.firstName}
                        />
                    </MobileFormBlock>
                    <MobileFormBlock annotation={t({ id: 'Phone' })} className={styles.block}>
                        <Input
                            name='phone'
                            type='tel'
                            placeholder='+x xxx xxx xx xx'
                            styleType='mobile'
                            pattern='\+\d+ [0-9]{3} [0-9]{3} [0-9]{2} [0-9]{2}'
                        />
                    </MobileFormBlock>
                    <MobileFormBlock
                        annotation={t({
                            message: 'Email',
                        })}
                        className={styles.block}>
                        <div
                            className={`${styles.likeInput} ${
                                !canEditPersonalData ? styles.disabled : ''
                            }`}>
                            <div>{values.email}</div>
                            {canEditPersonalData && (
                                <div
                                    className={styles.likeInput__button}
                                    onClick={() => setEmailChangeDialogOpen(true)}>
                                    {t({ id: 'change', message: 'Изменить' })}
                                </div>
                            )}
                        </div>
                    </MobileFormBlock>
                    <MobileFormBlock
                        annotation={t({
                            message: 'Settings',
                        })}
                        className={styles.block}>
                        <div className={styles.likeInput}>
                            <div>{t({ id: 'language' })}</div>
                            <SelectField
                                className={styles.likeInput__select}
                                items={getLanguageList()}
                                name='language'
                            />
                        </div>
                        <div className={styles.likeInput}>
                            <div>{t({ id: 'timeZone' })}</div>
                            <SelectField
                                className={styles.likeInput__select}
                                items={timeZonesList}
                                name='timeZone'
                            />
                        </div>
                    </MobileFormBlock>
                </form>
                <div className={styles.exit}>
                    <Button
                        styleType='exitMobile'
                        caption={t({ id: 'Exit' })}
                        clickHandler={logout}
                    />
                </div>
            </>
        );
    };

    return (
        <Form
            onSubmit={onSubmit}
            validate={validate}
            render={render}
            initialValues={getInitialValues(user)}
        />
    );
};
