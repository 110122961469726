import { appHistory } from 'appHistory';
import { connect } from 'react-redux';
import { TAppDispatch } from 'types/app/TAppDispatch';
import { TAppState } from 'types/app/TAppStore';
import { RegistrationForm } from './RegistrationForm';
import { IDispatchProps, IStateProps } from './types';
import { registrationThunkAction } from 'thunk/auth/registrationThunkAction';
import { showNotifyThunkAction } from 'thunk/notify/showNotifyThunkAction';
import { getDefaultErrorNotification } from 'utils/getDefaultErrorNotification';

const mapStateToProps = (state: TAppState): IStateProps => ({
    loggedIn: state.auth.rememberMeRequestStatus === 'success',
});

const mapDispatchToProps = (dispatch: TAppDispatch): IDispatchProps => ({
    register: (data: any) => dispatch(registrationThunkAction(data)),
    goToPersonalInfoPage: () => appHistory.push('/updateProfile'),
    showNotificationError: () => dispatch(showNotifyThunkAction(getDefaultErrorNotification())),
});

export const RegistrationFormConnected = connect(
    mapStateToProps,
    mapDispatchToProps,
)(RegistrationForm);
