import OutsideClick from 'components/common/OutsideClick';
import { ConferenceActionButtonsMobile } from 'pages/AccountPage/AccountConferencesPage/ConferenceActionButtons/ConferenceActionButtonsMobile';
import styles from './UpcomingConferenceMobile.module.scss';
import { IOwnProps } from './types';
import { UpcomingConferenceInfoMobile } from './UpcomingConferenceInfoMobile';

export const UpcomingConferenceMobile: React.FC<IOwnProps> = ({
    conference,
    userIsOwner,
    closeActionButtons,
    actionButtonsOpen,
    handleTouchMove,
    handleTouchStart,
    goToConnectConference,
    editConferenceHandler,
    deleteConferenceHandler,
}) => {
    const conferenceId = conference.seriesId || conference.id;

    return (
        <div key={conference.id}>
            <OutsideClick onOutsideClick={closeActionButtons}>
                <div className={styles.conference} id={conferenceId}>
                    <UpcomingConferenceInfoMobile
                        conference={conference}
                        conferenceResultId={conferenceId}
                        userIsOwner={userIsOwner}
                        goToConnectConference={goToConnectConference}
                        handleTouchMove={handleTouchMove}
                        handleTouchStart={handleTouchStart}
                    />
                    {userIsOwner && (
                        <ConferenceActionButtonsMobile
                            actionButtonsOpen={actionButtonsOpen}
                            onEdit={() => editConferenceHandler(conference)}
                            onDelete={() => deleteConferenceHandler(conference)}
                            scheduled={conference.status !== 'STARTED'}
                        />
                    )}
                </div>
            </OutsideClick>
        </div>
    );
};
