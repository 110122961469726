import { createAsyncThunk } from '@reduxjs/toolkit';
import { getUpcomingConferenceInfoApiCall } from 'api/conference/getUpcomingConferenceInfoApiCall';
import { getNetworkService } from 'api/getNetworkService';
import { IUpcomingConferenceInfo } from 'types/conferencesList/IUpcomingConferenceInfo';

interface IGetUpcomingConferenceDataThunkActionPayload {
    conferenceId: string;
}

export const getUpcomingConferenceDataThunkAction = createAsyncThunk<
    IUpcomingConferenceInfo | undefined,
    IGetUpcomingConferenceDataThunkActionPayload
>('getUpcomingConferenceData', async ({ conferenceId }) => {
    const { success, data } = await getUpcomingConferenceInfoApiCall(
        getNetworkService(),
        conferenceId,
    );

    if (success) {
        return data;
    }
});
