import { createReducer } from '@reduxjs/toolkit';
import { addAdminUsersAction } from 'admin-app/actions/addAdminUsersAction';
import { setUsersSearchAction } from 'admin-app/actions/setUsersSearchAction';
import { storeAdminUsersAction } from 'admin-app/actions/storeAdminUsersAction';
import { addWebHookAction } from 'admin-app/actions/webHooks/addWebHookAction';
import { editWebHookAction } from 'admin-app/actions/webHooks/editWebHookAction';
import { removeWebHookAction } from 'admin-app/actions/webHooks/removeWebHookAction';
import { setWebHooksAction } from 'admin-app/actions/webHooks/setWebHooksAction';
import { IAdminPanelUser } from 'admin-app/types/IAdminPanelUser';
import { IWebHook } from 'admin-app/types/IWebHook';

export interface IAdminReducer {
    users: {
        list: IAdminPanelUser[] | null;
        total: number;
    };
    webHooks: IWebHook[];
    usersSearch: string;
}

export const adminReducerInitialState: IAdminReducer = {
    users: {
        list: null,
        total: 0,
    },
    webHooks: [],
    usersSearch: '',
};

export const adminReducer = createReducer(adminReducerInitialState, (builder) => {
    builder
        .addCase(storeAdminUsersAction, (state, { payload }) => {
            state.users = payload;
        })
        .addCase(addAdminUsersAction, (state, { payload: users }) => {
            const currentUsers = state.users.list || [];
            state.users.list = [...currentUsers, ...users];
        })
        .addCase(setUsersSearchAction, (state, { payload }) => {
            state.usersSearch = payload;
        })
        .addCase(setWebHooksAction, (state, { payload: collection }) => {
            state.webHooks = collection;
        })
        .addCase(removeWebHookAction, (state, { payload: id }) => {
            state.webHooks = state.webHooks.filter((hook) => hook.id !== id);
        })
        .addCase(editWebHookAction, (state, { payload: editedHook }) => {
            state.webHooks = state.webHooks.map((hook) => {
                if (hook.id === editedHook.id) {
                    return editedHook;
                }
                return hook;
            });
        })
        .addCase(addWebHookAction, (state, { payload: webHook }) => {
            state.webHooks.push(webHook);
        });
});
