import { t } from '@lingui/macro';
import { Button } from 'components/common/Button';
import { JoinConfFormConnected } from 'components/forms/JoinConfForm';
import { PageTemplate } from '../templates/PageTemplate';
import { CreateConfBlockConnected } from './CreateConfBlock';
import styles from './HomePageDesktop.module.scss';
import { IDesktopDispatchProps } from './types';

export const HomePageDesktop: React.FC<IDesktopDispatchProps> = () => (
    <PageTemplate thisIsHomePage={true}>
        <div className={styles.mainSection}>
            <div className={styles.likeH3}>
                {t({
                    id: 'A CONVENIENT SOLUTION FOR BUSINESS',
                    message: 'УДОБНОЕ РЕШЕНИЕ ДЛЯ БИЗНЕСА',
                })}
            </div>
            <div className={styles.likeH1}>
                {t({
                    id: 'Make communication more accessible',
                    message: 'Сделай общение доступнее',
                })}
            </div>
            <div className={styles.formGroup}>
                <div className={styles.formGroup__left}>
                    <CreateConfBlockConnected className={styles.createBlock} />
                </div>
                <div className={styles.formGroup__right}>
                    <JoinConfFormConnected />
                </div>
            </div>
        </div>

        <div className={styles.blockOr}>{t({ id: 'OR', message: 'ИЛИ' })}</div>
    </PageTemplate>
);
