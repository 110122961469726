import { applicationStore } from 'store/applicationStore';
import { TAppState } from 'types/app/TAppStore';
import { IConferenceConnectInfo } from 'types/conferences/IConferenceConnectInfo';
import { IConferenceInfo } from 'types/conferences/IConferenceInfo';

export const convertConferenceInfoToConferenceConnectInfo = (
    conference: IConferenceInfo,
    janusUrl: string,
): IConferenceConnectInfo => {
    const {
        auth: { currentUser },
    } = applicationStore.getState() as TAppState;
    const seriesId = { seriesId: conference.seriesId };

    return {
        id: conference.id,
        lobby: conference.lobby,
        muteUponEntry: conference.muteUponEntry,
        name: conference.name,
        status: conference.status,
        timeStart: conference.timeStart,
        videoCreator: conference.videoCreator,
        videoParticipants: conference.videoParticipants,
        forbidAudio: conference.forbidAudio,
        forbidVideo:conference.forbidVideo,
        creator: {
            email: currentUser?.contact.email || '',
            firstName: currentUser?.name?.first || '' || '',
            lastName: currentUser?.name?.last || '',
            userId: currentUser?.id || '',
        },
        description: conference.description,
        pin: conference.pin,
        registeredOnly: conference.registeredOnly,
        waitCreator: conference.waitCreator,
        duration: conference.duration,
        janusUrl,
        ...seriesId,
    };
};
