import { showNotifyThunkAction } from 'thunk/notify/showNotifyThunkAction';
import { createAsyncThunk } from '@reduxjs/toolkit';
import { setActivePublishersAction } from 'actions/janus/setActivePublishersAction';
import { setCanPublishAction } from 'actions/janus/setCanPublishAction';
import { MAX_FEEDS } from 'constants/janusConstants';
import { batch } from 'react-redux';
import { TAppState } from 'types/app/TAppStore';
import { janusCtx } from 'utils/janus/janusCtx';
import { t } from '@lingui/macro';

export const checkActiveParticipantsAndCanPublishThunkAction = createAsyncThunk(
    'checkActiveParticipantsAndCanPublish',
    async (_: void, { dispatch, getState }) => {
        batch(() => {
            const {
                janus: { activePublishers, canPublish, streams },
            } = getState() as TAppState;
            const streamsKeys = Object.keys(streams);
            let actualActivePublishers = streamsKeys.length;
            if (janusCtx.published) {
                actualActivePublishers++;
            }
            const actualCanPublish = actualActivePublishers < MAX_FEEDS || janusCtx.published;
            if (
                actualActivePublishers >= MAX_FEEDS &&
                activePublishers !== actualActivePublishers
            ) {
                dispatch(
                    showNotifyThunkAction({
                        text: t({
                            id: 'notify.noFreeSlots',
                            message:
                                'Все слоты заняты, новые участники должны подождать, пока кто-нибудь отключит камеру и микрофон или выйдет',
                        }),
                        timeout: 5000,
                        type: 'notify',
                    }),
                );
            }
            if (
                activePublishers >= MAX_FEEDS &&
                actualActivePublishers < MAX_FEEDS &&
                activePublishers !== actualActivePublishers
            ) {
                dispatch(
                    showNotifyThunkAction({
                        text: t({
                            id: 'notify.freeSlotsExists',
                            message:
                                'Появились свободные слоты, новые участники могут включить микрофон или камеру',
                        }),
                        timeout: 5000,
                        type: 'notify',
                    }),
                );
            }
            if (activePublishers != actualActivePublishers || canPublish !== actualCanPublish) {
                batch(() => {
                    if (activePublishers !== actualActivePublishers) {
                        dispatch(setActivePublishersAction(actualActivePublishers));
                    }
                    if (actualCanPublish !== canPublish) {
                        dispatch(setCanPublishAction(actualCanPublish));
                    }
                });
            }
        });
    },
);
