import { connect } from 'react-redux';
import { showNotifyThunkAction } from 'thunk/notify/showNotifyThunkAction';
import { TAppDispatch } from 'types/app/TAppDispatch';
import { TAppState } from 'types/app/TAppStore';
import { ConferenceInfoFrameMobile } from './ConferenceInfoFrameMobile';
import { TDispatchPropsMobile, IStatePropsMobile } from './types';
import { getCurrentConferenceConnectInfoSelector } from 'selectors/getCurrentConferenceConnectInfoSelector';
import { changeConferenceRecordStateThunkAction } from 'thunk/conference/changeConferenceRecordStateThunkAction';
import { copyToClipboardThunkAction } from 'thunk/navigator/copyToClipboardThunkAction';

const mapStateToProps = (state: TAppState): IStatePropsMobile => ({
    conferenceConnectInfo: getCurrentConferenceConnectInfoSelector(state),
    record: state.conferenceSession.currentConferenceRecording,
    participantIsOwner: state.conferenceSession.currentUserIsOwner,
    conferencePin: state.conferenceSession.currentConferencePin,
    isPortrait: state.applicationView.deviceProps.isPortrait,
    isAuthorizedUser: !!state.auth.currentUser,
});

const mapDispatchToProps = (dispatch: TAppDispatch): TDispatchPropsMobile => ({
    toggleRecordState: () => dispatch(changeConferenceRecordStateThunkAction()),
    copyToClipboard: (payload) => dispatch(copyToClipboardThunkAction(payload)),
    showNotification: (text) =>
        dispatch(
            showNotifyThunkAction({
                text,
                timeout: 5000,
                type: 'notify',
            }),
        ),
});

export const ConferenceInfoFrameMobileConnected = connect(
    mapStateToProps,
    mapDispatchToProps,
)(ConferenceInfoFrameMobile);
