import { joinToCurrentConferenceAndStartJanusThunkAction } from './joinToCurrentConferenceAndStartJanusThunkAction';
import { createAsyncThunk } from '@reduxjs/toolkit';
import { setJanusStreamPropsAction } from 'actions/janus/setJanusStreamPropsAction';
import { getAndCheckConferenceForErrorsThunkAction } from 'thunk/conference/getAndCheckConferenceForErrorsThunkAction';
import { TAppState } from 'types/app/TAppStore';
import { IConferenceConnectInfo } from 'types/conferences/IConferenceConnectInfo';

interface IPayload {
    useAudio: boolean;
    useVideo: boolean;
}

export const joinCurrentConferenceMobileThunkAction = createAsyncThunk(
    'joinCurrentConferenceMobile',
    async ({ useAudio, useVideo }: IPayload, { dispatch, getState }) => {
        const {
            conferenceSession: { currentConferenceId: conferenceId },
        } = getState() as TAppState;
        const { payload: result } = await dispatch(
            getAndCheckConferenceForErrorsThunkAction(conferenceId),
        );
        if (result && typeof result === 'object' && 'error' in result) {
            return (result as { error?: string })?.error;
        }
        const conference: IConferenceConnectInfo = result as IConferenceConnectInfo;
        dispatch(
            setJanusStreamPropsAction({
                useAudio: useAudio && !conference.muteUponEntry,
                useVideo: useVideo && conference.videoParticipants,
            }),
        );
        await dispatch(joinToCurrentConferenceAndStartJanusThunkAction(true));
    },
);
