import { RELEASE_VERSIONS } from 'constants/releaseVersions';

export const isReleaseVersion = (versionString: string) => {
    if (!versionString) {
        return false;
    }
    const minusPos = versionString.indexOf('-');
    if (minusPos < 0) {
        return false;
    }
    const versionNum = versionString.substring(0, minusPos);
    return RELEASE_VERSIONS.some((releaseVersion) => releaseVersion === versionNum);
};
