import { t } from '@lingui/macro';
import { Trans } from '@lingui/react';
import { ReactComponent as RecordSvg } from 'assets/Record.svg';
import SettingsImg from 'assets/Settings.svg';
import { Button } from 'components/common/Button';
import { useAnimation } from 'hooks/useAnimation';
import { useEffect, useState } from 'react';
import OutsideClick from 'components/common/OutsideClick';
import { createInviteLink } from 'utils/conferences/createInviteLink';
import { getDisplayName } from 'utils/users/getDisplayName';
import styles from './ConferenceInfoFrameMobile.module.scss';
import { TDispatchPropsMobile, IOwnPropsMobile, IStatePropsMobile } from './types';
import { ECopyToClipboardNotification } from 'constants/ECopyToClipboardNotification';
import { classNames } from 'utils/classNames';
import { mobileConferenceMenuBtnId } from 'pages/ConferencePage/ConferencePageMobileHeader/utils';

export const ConferenceInfoFrameMobile: React.FC<
    IStatePropsMobile & TDispatchPropsMobile & IOwnPropsMobile
> = ({
    conferenceConnectInfo,
    conferencePin,
    record,
    showNotification,
    copyToClipboard,
    toggleRecordState,
    participantIsOwner,
    isPortrait,
    handleClickSettings,
    isShow,
    isAuthorizedUser,
    hideInfoFrame,
}) => {
    if (!conferenceConnectInfo) {
        return null;
    }

    const [headerHeight, setHeaderHeight] = useState(0);

    const { id, name } = conferenceConnectInfo;
    const { handleAnimationEnd, activeAnimationClass, isRender } = useAnimation({
        isShow,
        animations: {
            fadeInClass: styles.conferenceInfoFrame_show,
            fadeOutClass: styles.conferenceInfoFrame_hide,
        },
    });
    useEffect(() => {
        if (isPortrait) {
            const headerNode = document.querySelector('[data-header-mobile]');
            if (headerNode) {
                setHeaderHeight(headerNode.clientHeight);
            }
        }
    }, []);

    const onClickCopyInviteLink = (e: React.MouseEvent<HTMLButtonElement>) => {
        e.stopPropagation();
        const htmlElem = e.currentTarget;
        copyToClipboard({
            dataToCopy: htmlElem.dataset.inviteLink || '',
            notifyTextKey: ECopyToClipboardNotification.INVITE_LINK,
        });
    };

    const onClickInviteButton = async () => {
        createInviteLink(id, conferencePin);
        try {
            await navigator.share({ url: createInviteLink(id, conferencePin) });
        } catch (err) {
            const typedErr = err as any;
            if (typedErr.code && typedErr.code === 20) {
                return;
            }
            showNotification(
                t({
                    message: 'Эта функция недоступна на вашем устройстве',
                    id: 'share.issue',
                }),
            );
        }
    };
    const onClickCopyPin = () => {
        copyToClipboard({
            dataToCopy: conferencePin,
            notifyTextKey: ECopyToClipboardNotification.CONF_PIN,
        });
    };

    const onClickRecordButton = () => {
        toggleRecordState();
    };

    const onClickSettings = () => {
        handleClickSettings();
    };
    const onOutsideClick = (e: MouseEvent) => {
        const target = e.target;
        if (target) {
            !(target as HTMLElement).closest(`#${mobileConferenceMenuBtnId}`) && hideInfoFrame();
        }
    };
    const renderRecordText = () => {
        if (record === 'on') {
            return <Trans message='Остановить запись конференции' id='conference.record.stop' />;
        } else if (record === 'off') {
            return <Trans message='Начать запись конференции' id='conference.record.start' />;
        } else if (record === 'launch') {
            return <Trans message='Включение записи' id='record.launch' />;
        } else {
            return <Trans message='Выключение записи' id='record.terminate' />;
        }
    };
    return isRender ? (
        <OutsideClick onOutsideClick={onOutsideClick}>
            <div
                style={headerHeight ? { top: `${headerHeight}px` } : {}}
                onAnimationEnd={handleAnimationEnd}
                className={`${styles.conferenceInfoFrame} ${
                    !isPortrait ? styles.conferenceInfoFrame_tablet : ''
                } ${activeAnimationClass}`}>
                <ul className={styles.conferenceInfoFrame__list}>
                    <li className={styles.conferenceInfoFrame__item}>
                        <span>
                            <Trans message='Тема конференции:' id='conference.name' />
                        </span>
                        <div>{name}</div>
                    </li>
                    <li className={styles.conferenceInfoFrame__item}>
                        <span>
                            <Trans message='Организатор:' id='conference.owner' />
                        </span>
                        <div>{getDisplayName(conferenceConnectInfo.creator)}</div>
                    </li>
                    {isAuthorizedUser && (
                        <>
                            <li className={styles.conferenceInfoFrame__item}>
                                <span>
                                    <Trans message='Код доступа:' id='conference.pin' />
                                </span>
                                <div>
                                    <div>{conferencePin || ''}</div>
                                    <button
                                        className={styles.conferenceInfoFrame__linkBtn}
                                        onClick={onClickCopyPin}>
                                        <Trans message='Копировать код' id='conference.pin.copy' />
                                    </button>
                                </div>
                            </li>
                            <li className={styles.conferenceInfoFrame__item}>
                                <span>
                                    <Trans
                                        message='Ссылка приглашения:'
                                        id='conference.inviteLink'
                                    />
                                </span>
                                <div>
                                    <span className={styles.conferenceInfoFrame__linkText}>
                                        {createInviteLink(id, conferencePin)}
                                    </span>
                                    <button
                                        className={styles.conferenceInfoFrame__linkBtn}
                                        data-invite-link={createInviteLink(id, conferencePin)}
                                        onClick={onClickCopyInviteLink}>
                                        <Trans
                                            message='Копировать ссылку'
                                            id='conference.inviteLink.copy'
                                        />
                                    </button>
                                </div>
                            </li>
                        </>
                    )}
                </ul>
                {isAuthorizedUser && (
                    <div
                        className={`${styles.controls} ${
                            !isPortrait ? styles.controls_tablet : ''
                        }`}>
                        <Button
                            type='button'
                            styleType='mobileSemiTransparent'
                            clickHandler={onClickInviteButton}
                            className={styles.controls__button}>
                            <Trans message='Пригласить' id='conference.invite' />
                        </Button>
                        {participantIsOwner && (
                            <Button
                                type='button'
                                styleType='mobileSemiTransparent'
                                clickHandler={onClickRecordButton}
                                className={classNames([
                                    styles.controls__button,
                                    record === 'launch' || record === 'terminate'
                                        ? styles.controls__button_recordProcessing
                                        : '',
                                ])}>
                                <RecordSvg
                                    className={record === 'on' ? styles.record_active : ''}
                                />
                                {renderRecordText()}
                            </Button>
                        )}
                        {participantIsOwner && (
                            <Button
                                type='button'
                                styleType='mobileSemiTransparent'
                                clickHandler={onClickSettings}
                                className={styles.controls__button}>
                                <img src={SettingsImg} />
                                <Trans message='Настройки конференции' id='conference.settings' />
                            </Button>
                        )}
                    </div>
                )}
            </div>
        </OutsideClick>
    ) : null;
};
