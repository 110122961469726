import { t } from '@lingui/macro';
import { resetPendingConfirmationAction } from 'actions/applicationView/resetPendingConfirmationAction';
import { setFormEditFlagAction } from 'actions/applicationView/setFormEditFlagAction';
import { FORM_ERROR } from 'final-form';
import { applicationStore } from 'store/applicationStore';
import { updateConferenceInfoThunkAction } from 'thunk/conference/updateConferenceInfoThunkAction';
import { showNotifyThunkAction } from 'thunk/notify/showNotifyThunkAction';
import { TAppDispatch } from 'types/app/TAppDispatch';
import { TAppState } from 'types/app/TAppStore';
import { IConferenceInfo } from 'types/conferences/IConferenceInfo';
import { IThunkResult } from 'types/IThunkResult';
import { getParticipantsAudioVideoPermissions } from 'utils/forms/getParticipantsAudioVideoPermissions';
import { getResultErrorString } from 'utils/forms/getResultErrorString';
import { getDefaultErrorNotification } from 'utils/getDefaultErrorNotification';
import { IDispatchProps, IFormProps, IStateProps } from './types';

export const getConferenceStartedSettingsFormInitialValues = (
    conferenceInfo: IConferenceInfo | undefined,
) => {
    if (!conferenceInfo) {
        return;
    }
    const participantsAudioVideo = getParticipantsAudioVideoPermissions(conferenceInfo);
    return {
        topic: conferenceInfo.name,
        description: conferenceInfo.description,
        pin: conferenceInfo.pin,
        pinUse: conferenceInfo.pin && conferenceInfo.pin !== '' ? true : false,
        lobby: conferenceInfo.lobby,
        videoOwner: conferenceInfo.videoCreator,
        participantsVideoPermission: participantsAudioVideo.video,
        participantsAudioPermission: participantsAudioVideo.audio,
        registeredOnly: conferenceInfo.registeredOnly,
    };
};

export const sendData = async (
    values: IFormProps,
    updateConference: (conferenceInfo: Partial<IConferenceInfo>) => Promise<IThunkResult>,
    conferenceInfo?: IConferenceInfo,
) => {
    const dataToSend = {
        description: values.description,
        name: values.topic,
        lobby: values.lobby,
        videoCreator: values.videoOwner,
        registeredOnly: values.registeredOnly,
    };

    const response = await updateConference({ id: conferenceInfo?.id, ...dataToSend });
    return response;
};

export const handleSubmitClick = async (
    values: IFormProps,
    {
        updateConference,
        showNotification,
    }: Pick<IDispatchProps, 'updateConference' | 'showNotification'>,
    conferenceInfo?: IConferenceInfo,
    handleAfterSubmit?: () => void,
) => {
    const sendDataResponse = await sendData(values, updateConference, conferenceInfo);

    if (sendDataResponse.isSuccess) {
        showNotification({
            text: t({ id: 'The changes were saved successfully' }),
            timeout: 3000,
            type: 'success',
        });

        if (handleAfterSubmit) {
            handleAfterSubmit();
        }
    } else {
        const { errorDescription, errorCode } = sendDataResponse;
        if (errorDescription && errorCode) {
            return { [FORM_ERROR]: getResultErrorString({ errorCode, errorDescription }) };
        }
        showNotification(getDefaultErrorNotification());
    }
};

export const validateConferenceStartedSettingsForm = (values: IFormProps) => {
    const {
        applicationConfiguration: {
            config: { conferenceModelRestrictions },
        },
    } = applicationStore.getState() as TAppState;

    const errors: { [key: string]: string } = {};
    if (values.operation !== 'save') {
        return errors;
    }
    const requiredFieldList = ['topic'];

    for (const field of requiredFieldList) {
        const f = field as keyof IFormProps;
        if (!values[f] || values[f] === '') {
            errors[f] = 'Required';
        }
    }

    if (values.topic && values.topic.length > conferenceModelRestrictions.name) {
        errors.topic = t({
            id: 'scheduleConferenceErrors.conferenceName',
        });
    }

    if (values.description && values.description.length > conferenceModelRestrictions.description) {
        errors.description = t({
            id: 'scheduleConferenceErrors.conferenceDescription',
        });
    }

    return errors;
};

export const mapStateToProps = (state: TAppState): IStateProps => ({
    currentUser: state.auth.currentUser,
});
export const mapDispatchToProps = (dispatch: TAppDispatch): IDispatchProps => ({
    updateConference: (conferenceInfo) =>
        dispatch(updateConferenceInfoThunkAction(conferenceInfo)).unwrap(),
    showNotification: (args) => dispatch(showNotifyThunkAction(args)),
    onModified: (isEdit: boolean) => dispatch(setFormEditFlagAction(isEdit)),
    resetModified: () => dispatch(resetPendingConfirmationAction()),
});
