import { t } from '@lingui/macro';
import { InputField } from 'admin-app/components/common/InputField';
import { SearchIcon } from 'admin-app/components/common/icons';
import { EAdminPaths } from 'admin-app/constants/EAdminPaths';
import { Form, FormRenderProps } from 'react-final-form';
import { useLocation } from 'react-router-dom';
import { redirect } from 'utils/redirect';
import styles from './UserSearch.module.scss';
import { IDispatchProps, IFormProps, IStateProps } from './types';

export const UserSearch: React.FC<IDispatchProps & IStateProps> = ({
    searchUser,
    getUsers,
    gettingUsers,
}) => {
    const { pathname } = useLocation();
    const onSubmit = (values: IFormProps) => {
        if (gettingUsers) {
            return;
        }
        const formattedValue = values.search?.trim() || '';
        if (formattedValue) {
            searchUser(formattedValue);
        } else {
            getUsers();
        }

        if (!pathname.match(EAdminPaths.USERS)) {
            redirect(EAdminPaths.USERS);
        }
    };

    const renderForm = ({ handleSubmit, submitting, submitError }: FormRenderProps<IFormProps>) => {
        return (
            <form onSubmit={handleSubmit}>
                <InputField
                    name='search'
                    placeholder={t({ id: 'search' })}
                    icon={<SearchIcon />}
                    className={styles.form__searchField}
                    wrapperClassName={styles.form__searchFieldWrapper}
                    disabled={gettingUsers || submitting || submitError}
                />
            </form>
        );
    };
    return <Form render={renderForm} initialValues={{ search: '' }} onSubmit={onSubmit} />;
};
