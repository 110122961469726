import { MobileWrapperConnected } from 'components/common/MobileWrapper';
import { AccountSettingsPageConnected } from './AccountSettingsPageConnected';
import { AccountSettingsPageMobileConnected } from './AccountSettingsPageMobileConnected';

export const AccountSettingsPageResponsive: React.FC = () => (
    <MobileWrapperConnected
        renderDesktop={() => <AccountSettingsPageConnected />}
        renderMobile={() => <AccountSettingsPageMobileConnected />}
    />
);
