import { createAsyncThunk } from '@reduxjs/toolkit';
import { pingSocketConnection } from 'utils/socket/pingSocketConnection';

const timeoutIds: Record<string, NodeJS.Timeout> = {};

export const pingSocketConnectionThunkAction = createAsyncThunk<void, string>(
    'pingSocketConnectionThunkAction',
    async (conferenceId) => {
        sendMessage(conferenceId);
    },
)

const sendMessage = (conferenceId: string) => {
    timeoutIds[conferenceId] = setTimeout(() => {
        pingSocketConnection();
        sendMessage(conferenceId)
    }, 1 * 60 * 1000) // 1 minute
}

export const stopPingSocketConnection = (conferenceId: string) => {
    if (timeoutIds[conferenceId]) {
        clearTimeout(timeoutIds[conferenceId])
        delete timeoutIds[conferenceId]
    }
}
