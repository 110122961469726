import { Button } from 'components/common/Button';
import styles from './MobilePageTemplate.module.scss';
import { IOwnProps } from './types';
import ArrowBackImg from 'assets/ArrowBack.svg';
import LogoImg from 'assets/LogoBlack.svg';

export const MobilePageTemplate: React.FC<IOwnProps> = ({
    children,
    onClickBack,
    showIcon,
    title,
    titleClassName,
    rootClassName,
    headerClassName,
    additionalControl,
}) => (
    <div className={`${styles.root} ${rootClassName || ''}`}>
        <div className={`${styles.root__header} ${headerClassName || ''}`}>
            <Button styleType='common' clickHandler={onClickBack}>
                <img src={ArrowBackImg} />
            </Button>
            {title && <div className={`${styles.title} ${titleClassName || ''}`}>{title}</div>}
            {showIcon && (
                <div className={styles.logo}>
                    <img src={LogoImg} />
                </div>
            )}
            {additionalControl && additionalControl}
        </div>
        {children}
    </div>
);
