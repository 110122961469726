export const RELEASE_VERSIONS = [
    '0.1.0',
    '0.2.0',
    '0.2.1',
    '0.2.2',
    '0.2.3',
    '0.2.4',
    '0.2.5',
    '0.2.6',
];
