import { CircleLoader } from 'components/common/CircleLoader';
import { PinEnterFormDesktopConnected } from 'components/forms/PinEnterForm/PinEnterFormDesktop';
import { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import { getConferenceLinkParameters } from 'utils/conferences/getConferenceLinkParameters';
import { isValidConferenceIdFormat } from 'utils/conferences/isValidConferenceIdFormat';
import styles from './JoinConferenceByLinkPage.module.scss';
import { IConferenceStatus, IDispatchProps, IStateProps } from './types';
import { getErrorText } from './utils';

export const JoinConferenceByLinkPage: React.FC<IDispatchProps & IStateProps> = ({
    anonymous,
    conferencesConnectInfo,
    setCurrentConferenceData,
    getAndCheckConference,
    goToConnectPage,
    goToLoginForJoinPage,
    goToUsernameInputPage,
    goToSimpleConnectPage,
    checkPin,
    rememberMeRequestStatus,
}) => {
    const location = useLocation();
    const [conferenceStatus, setConferenceStatus] = useState<IConferenceStatus>({ success: true });
    const [loading, setLoading] = useState(true);
    const search = location.search;
    const {
        id,
        pin: pinFromQuery,
        recorderId,
        simpleConference,
    } = getConferenceLinkParameters(search);
    const [pin, setPin] = useState(pinFromQuery);

    useEffect(() => {
        const loadConference = async () => {
            if (id && isValidConferenceIdFormat(id)) {
                const { payload: result } = await getAndCheckConference(id);
                if (result.error) {
                    setConferenceStatus({ success: false, errorDescription: result.error });
                } else {
                    setCurrentConferenceData({
                        conferenceId: id,
                        creator: result.creator,
                        pin: result.pin,
                    });
                    setConferenceStatus({ success: true });
                }
            } else {
                setConferenceStatus({ success: false, errorDescription: 'id' });
            }
            setLoading(false);
        };
        if (rememberMeRequestStatus === 'success' || rememberMeRequestStatus === 'error') {
            loadConference();
        }
    }, [rememberMeRequestStatus]);

    useEffect(() => {
        const nextStep = async (id: string) => {
            const conferenceConnectInfo = conferencesConnectInfo[id];
            if (conferenceConnectInfo.pin) {
                goToConnectPage(id);
            } else if (pin) {
                const { isSuccess } = await checkPin({ id, pin });
                if (!isSuccess) {
                    setPin(undefined);
                } else {
                    successPinSubmitCallback();
                }
            }
        };
        if (!loading && conferenceStatus.success && id) {
            nextStep(id);
        }
    }, [loading, conferenceStatus, pin]);

    const successPinSubmitCallback = () => {
        if (id) {
            const conferenceConnectInfo = conferencesConnectInfo[id];
            if (recorderId) {
                goToSimpleConnectPage(id, recorderId);
            } else if (conferenceConnectInfo.registeredOnly && anonymous) {
                goToLoginForJoinPage();
            } else if (anonymous) {
                goToUsernameInputPage(id);
            } else {
                goToConnectPage(id, !!simpleConference);
            }
        }
    };

    const errorBlock = (
        <div className={styles.error}>{getErrorText(conferenceStatus?.errorDescription)}</div>
    );

    const showForms = !loading && id && conferenceStatus.success;

    return (
        <div className={styles.page}>
            <div className={styles.wrapper}>
                {showForms && !pin && conferencesConnectInfo && (
                    <PinEnterFormDesktopConnected
                        successSubmitCallback={successPinSubmitCallback}
                    />
                )}
                {loading && <CircleLoader color='white' />}
                {!conferenceStatus.success && errorBlock}
            </div>
        </div>
    );
};
