import { t } from '@lingui/macro';
import { createAsyncThunk } from '@reduxjs/toolkit';
import { FORM_ERROR } from 'final-form';
import { showNotifyThunkAction } from 'thunk/notify/showNotifyThunkAction';
import { IAccountInformation } from 'types/IAccountInformation';
import { getDefaultErrorNotification } from 'utils/getDefaultErrorNotification';
import { getLanguageByText } from 'utils/languagesOperations';
import { log } from 'utils/log';
import { getTimeZoneByName } from 'utils/timeZones/getTimeZoneByName';
import { updateProfileInfoThunkAction } from './updateProfileInfoThunkAction';

const getLiguiSuccessText = () =>
    t({
        id: 'Changes are saved',
        message: 'Изменения успешно сохранены',
    });

const getResultErrorString = ({ errorCode }: { errorCode: string }) => {
    if (errorCode === 'MOBILE_PHONE_ALREADY_IN_USE') {
        return t({
            id: 'accountInformation.form.error.phoneAlreadyInUse',
            message: 'Этот номер телефона уже используется',
        });
    }
    return '';
};

export interface IAccountInformationSubmitThunkPayload {
    avatar?: string | null;
    avatarInputNode: HTMLInputElement | null;
    formValues: IAccountInformation;
    resetForm: () => void;
}

export interface IAccountInformationSubmitThunkReturned {
    [key: string]: string;
}

export const accountInformationSubmitThunkAction = createAsyncThunk<
    IAccountInformationSubmitThunkReturned | undefined,
    IAccountInformationSubmitThunkPayload
>(
    'accountInformationSubmit',
    async ({ avatar, avatarInputNode, formValues, resetForm }, { dispatch }) => {
        let avatarFormData;
        if (avatar === null) {
            avatarFormData = null;
        } else {
            const files = avatarInputNode?.files;
            if (files && files?.length !== 0) {
                avatarFormData = new FormData();
                const a = avatarInputNode.files as FileList;
                avatarFormData.append('avatar', a[0]);
            }
        }
        const saveResponse = await dispatch(
            updateProfileInfoThunkAction({
                firstName: formValues.firstName,
                lastName: formValues.lastName,
                phone: formValues.phone,
                avatar: avatarFormData,
                timeZone: getTimeZoneByName(formValues.timeZone),
                locale: getLanguageByText(formValues.language),
            }),
        ).unwrap();

        if (saveResponse.success) {
            dispatch(
                showNotifyThunkAction({
                    text: getLiguiSuccessText(),
                    timeout: 3000,
                    type: 'success',
                }),
            );
            resetForm();
        } else {
            const { errorCode } = saveResponse;
            if (errorCode) {
                return { [FORM_ERROR]: getResultErrorString({ errorCode }) };
            }
            log.error('Profile updating failed');
            dispatch(showNotifyThunkAction(getDefaultErrorNotification()));
        }
    },
);
