import { IOwnProps } from './types';
import styles from './StatusText.module.scss';
import { useEffect, useRef } from 'react';
import WarningImg from 'assets/Warning.svg';

export const StatusText: React.FC<IOwnProps> = ({ errorType = 'error', errorText, className = '' }) => {
    const textContainer = useRef<HTMLDivElement>(null);
    useEffect(() => {
        if (errorText) {
            setTimeout(() => textContainer.current?.classList.add(styles.show), 200);
        }
    }, [errorText]);
    return errorText ? (
        <div ref={textContainer} className={`${styles.text} ${styles[errorType]} ${className}`}>
            {errorType === 'warning' && <img className={styles.warningImg} src={WarningImg} alt='warning image' />}
            {errorText}
        </div>
    ) : null;
};
