import { t } from '@lingui/macro';
import { Trans } from '@lingui/react';
import { Button } from 'components/common/Button/Button';
import { Cross } from 'components/common/Cross/Cross';
import { useLocation } from 'react-router';
import styles from './ExitConfirmationDialog.module.scss';
import { IDispatchProps, IOwnProps, IStateProps } from './types';
import { getDialogText, exitConference, exitAndFinishConference } from './utils';
import OutsideClick from 'components/common/OutsideClick';

export const ExitConfirmationDialog: React.FC<IOwnProps & IStateProps & IDispatchProps> = ({
    className,
    exitFromConference,
    finishConference,
    cancel,
    isConferenceOwner,
    showNotificationError,
}) => {
    const { pathname } = useLocation();

    const handleExitAndFinishClick = () =>
        exitAndFinishConference({
            finishConference,
            showNotificationError,
            exitFromConference,
            pathname,
        });

    const handleExitClick = () => exitConference({ exitFromConference, pathname });

    return (
        <OutsideClick onOutsideClick={cancel}>
            <div className={`${styles.dialog} ${className ? className : ''}`}>
                <Cross color='white' className={styles.cross} onClick={cancel} />
                <div className={styles.text}>{getDialogText(isConferenceOwner)}</div>
                {isConferenceOwner && (
                    <Button
                        styleType='primaryAction'
                        className={`${styles.button} ${styles.marginB}`}
                        caption={t({
                            id: 'conference.page.finish.text.id',
                            message: 'Завершить и выйти',
                        })}
                        clickHandler={handleExitAndFinishClick}
                    />
                )}
                <Button
                    styleType='primaryAction'
                    className={styles.button}
                    caption={t({ id: 'Exit', message: 'Выйти' })}
                    clickHandler={handleExitClick}
                />
                <div className={styles.cancel} onClick={cancel}>
                    <Trans id='Cancel' message='Отмена' />
                </div>
            </div>
        </OutsideClick>
    );
};
