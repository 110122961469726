import { t } from '@lingui/macro';
import { AnyAction, createAsyncThunk, ThunkDispatch } from '@reduxjs/toolkit';
import { setParticipantsFullScreenAction } from 'actions/applicationView/setParticipantsFullScreenAction';
import { addConferenceChatMessagesAction } from 'actions/chat/addConferenceChatMessagesAction';
import { storeConferenceConnectInfoAction } from 'actions/conferences/storeConferenceConnectInfoAction';
import { storeCurrentConferenceParticipantsAction } from 'actions/conferences/storeCurrentConferenceParticipantsAction';
import { updateStoreCurrentConferenceParticipantsAction } from 'actions/conferences/updateStoreCurrentConferenceParticipantsAction';
import { setCurrentConferenceRecordingAction } from 'actions/conferenceSession/setCurrentConferenceRecordingAction';
import { addJanusPublisherAction } from 'actions/janus/addJanusPublisherAction';
import { removeJanusPublisherAction } from 'actions/janus/removeJanusPublisherAction';
import { setParticipantMuteStatusAction } from 'actions/janus/setParticipantMuteStatusAction';
import { EChatMessageStatus } from 'constants/EChatMessageStatus';
import { EChatMessageType } from 'constants/EChatMessageType';
import { batch } from 'react-redux';
import { getConferenceEventsTopic } from 'services/socketService/getConferenceEventsTopic';
import { addSubscription, getSocketServiceState } from 'services/socketService/socketService';
import { setParticipantsFromServerThunkAction } from 'thunk/conference/participants/setParticipantsFromServerThunkAction';
import { showNotifyThunkAction } from 'thunk/notify/showNotifyThunkAction';
import { TAppState } from 'types/app/TAppStore';
import { IConferenceChatMessage } from 'types/chat/IConferenceChatMessage';
import { ESocketConferenceParticipantActionType } from 'types/conferences/ESocketConferenceParticipantActionType';
import { IAudioVideoProps } from 'types/IAudioVideoProps';
import { IConferenceParticipant } from 'types/IConferenceParticipant';
import { ISocketConferenceEventConfigured } from 'types/socket/conferenceEvents/ISocketConferenceEventConfigured';
import { ISocketConferenceEventInit } from 'types/socket/conferenceEvents/ISocketConferenceEventInit';
import { ISocketConferenceEventParticipant } from 'types/socket/conferenceEvents/ISocketConferenceEventParticipant';
import { ISocketConferenceEventRecorder } from 'types/socket/conferenceEvents/ISocketConferenceEventRecorder';
import { ISocketConferenceEventSettings } from 'types/socket/conferenceEvents/ISocketConferenceEventSettings';
import { ISocketConferenceEventUpdateSettings } from 'types/socket/conferenceEvents/ISocketConferenceEventUpdateSettings';
import { getConnectToSocket } from 'utils/connectToSocketProxy';
import { createRandomId } from 'utils/createRandomId';
import { getCurrentTimeByTemplate } from 'utils/dates/getCurrentTimeByTemplate';
import { getNewPublisher } from 'utils/janus/helpers/getNewPublisher';
import { log } from 'utils/log';
import { convertParticipantFromServerToParticipant } from 'utils/participants/convertParticipantFromServerToParticipant';
import { setStreamsPermissions } from 'utils/socket/conferenceEvents/setStreamsPermissions';
import { convertSocketRecordStatus } from 'utils/socket/convertSocketRecordStatus';
import { websocketLogger } from './../../utils/logger';

interface ISecondaryParams {
    getState: any;
    dispatch: any;
    topic: string;
    id: string;
}

const setInitialJanusPublishers = (getState: () => unknown, dispatch: any) => {
    const {
        conferenceSession: {
            currentConferenceParticipants,
            currentConferenceCreator,
            currentConferencePermissions,
            currentConferenceId,
        },
        conferences: { conferencesConnectInfo },
        janus: { publishers, ownParticipantId, localStreamProps },
    } = getState() as TAppState;
    batch(() => {
        for (const participantId in currentConferenceParticipants) {
            if (publishers[participantId]) {
                continue;
            }
            const participant = currentConferenceParticipants[participantId];
            const publisher = getNewPublisher({
                display: participant.name,
                participantId: participant.participantId,
                isOwner: !!(
                    participant?.userId && participant.userId === currentConferenceCreator?.userId
                ),
                conferenceConnectInfo: conferencesConnectInfo[currentConferenceId],
                moderationPermissions: currentConferencePermissions,
            });
            if (participant.participantId === ownParticipantId) {
                publisher.audioVideoProps = localStreamProps;
            }
            dispatch(addJanusPublisherAction(publisher));
        }
    });
};

const handleInit = async (
    {
        conferenceId,
        participants,
        records,
        recorderStatus,
        settings,
        participantStreams,
    }: ISocketConferenceEventInit,
    { dispatch, getState, ...secondaryParams }: ISecondaryParams,
) => {
    if (settings) {
        handleSettings(
            { actionType: 'SETTINGS', conferenceId, settings, type: 'CONFERENCE' },
            { ...secondaryParams, dispatch, getState },
        );
    }

    await dispatch(setParticipantsFromServerThunkAction(participants));
    setInitialJanusPublishers(getState, dispatch);
    batch(() => {
        for (const { participantId, streams } of participantStreams) {
            handleConfigured(
                { streams, participantId },
                { ...secondaryParams, dispatch, getState },
            );
        }
    });

    const {
        conferenceSession: { currentConferenceRecording },
    } = getState() as TAppState;
    if (!recorderStatus) {
        return;
    }
    const convertedRecordStatus = convertSocketRecordStatus(recorderStatus);
    currentConferenceRecording !== convertedRecordStatus &&
        dispatch(setCurrentConferenceRecordingAction(convertedRecordStatus));
};

export const handleConfigured = (
    { streams, participantId }: Pick<ISocketConferenceEventConfigured, 'participantId' | 'streams'>,
    { getState, dispatch }: ISecondaryParams,
) => {
    const {
        janus: { publishers, ownParticipantId },
    } = getState() as TAppState;
    if (participantId === ownParticipantId) {
        // Own stream
        return;
    }
    const publisher = publishers[participantId];
    if (publisher) {
        const audioVideoProps: Partial<IAudioVideoProps> = {};
        for (const mid in streams) {
            if (streams[mid].type === 'AUDIO') {
                audioVideoProps.useAudio = streams[mid].active;
            }
            if (streams[mid].type === 'VIDEO') {
                audioVideoProps.useVideo = streams[mid].active;
            }
        }
        dispatch(
            setParticipantMuteStatusAction({
                participantId,
                audioVideoProps,
            }),
        );
    }
};
const handleSettings = (
    {
        settings: {
            videoSettings: { masterFrames },
            forbiddenStreams,
            allowedStreams,
        },
    }: ISocketConferenceEventSettings,
    { getState, dispatch }: { getState: any; dispatch: any },
) => {
    const state = getState() as TAppState;
    const {
        janus: {
            ownParticipantId,
            localStreamProps: { screenShared },
        },
    } = state;

    setStreamsPermissions({
        forbiddenStreams,
        allowedStreams,
        getState,
        dispatch,
    });

    if (masterFrames && masterFrames.length !== 0) {
        let filteredFrames = masterFrames;
        if (screenShared) {
            filteredFrames = masterFrames.filter((frameId) => frameId !== ownParticipantId);
        }
        dispatch(setParticipantsFullScreenAction({ local: filteredFrames, global: filteredFrames }));
    } else {
        dispatch(setParticipantsFullScreenAction({ local: [], global: [] }));
    }
};

const handleParticipants = (
    { actionType, participant }: ISocketConferenceEventParticipant,
    { getState, dispatch }: { getState: any; dispatch: any },
) => {
    const {
        conferenceSession: {
            currentConferenceParticipants,
            currentConferenceCreator,
            currentConferencePermissions,
            currentConferenceId,
        },
        conferences: { conferencesConnectInfo },
        janus: { publishers, ownParticipantId, localStreamProps },
    } = getState() as TAppState;
    const newParticipantsList: IConferenceParticipant[] = Object.values(
        currentConferenceParticipants,
    );
    const publisher = publishers[participant.participantId];
    const participantIsLeave =
        actionType === ESocketConferenceParticipantActionType.PARTICIPANT_LEAVE;
    if (participantIsLeave) {
        if (participant) {
            dispatch(removeJanusPublisherAction(participant.participantId));
        }
    } else {
        newParticipantsList.push(
            convertParticipantFromServerToParticipant(participant, currentConferenceCreator),
        );
        if (!publisher) {
            const newPublisher = getNewPublisher({
                display: participant.name,
                participantId: participant.participantId,
                isOwner: !!(
                    participant?.userId && participant.userId === currentConferenceCreator?.userId
                ),
                conferenceConnectInfo: conferencesConnectInfo[currentConferenceId],
                moderationPermissions: currentConferencePermissions,
            });
            if (newPublisher.participantId === ownParticipantId) {
                newPublisher.audioVideoProps = {
                    audioPermitted: localStreamProps.audioPermitted,
                    useAudio: localStreamProps.useAudio,
                    useVideo: localStreamProps.useVideo,
                    videoPermitted: localStreamProps.videoPermitted,
                    screenShared: localStreamProps.screenShared,
                };
            }
            dispatch(addJanusPublisherAction(newPublisher));
        }
    }
    const chatSystemMessage: IConferenceChatMessage = {
        confUserId: '',
        ownUserId: '',
        status: EChatMessageStatus.SENT,
        time: getCurrentTimeByTemplate('HH:mm'),
        id: createRandomId(),
        contentType: participantIsLeave
            ? EChatMessageType.SYSTEM_LEAVE
            : EChatMessageType.SYSTEM_JOIN,
        text: participant.name,
    };
    dispatch(addConferenceChatMessagesAction({ messages: [chatSystemMessage] }));
    if (participantIsLeave) {
        dispatch(updateStoreCurrentConferenceParticipantsAction({ actionType, participant }));
    } else {
        dispatch(storeCurrentConferenceParticipantsAction(newParticipantsList));
    }
};

const handleUpdateConferenceConnectInfo = (
    { conferenceId, ...updatedConferenceConnectInfo }: ISocketConferenceEventUpdateSettings,
    { getState, dispatch }: { getState: any; dispatch: ThunkDispatch<unknown, unknown, AnyAction> },
) => {
    const {
        conferences: { conferencesConnectInfo },
    } = getState() as TAppState;
    const currentConnectInfo = conferencesConnectInfo[conferenceId];
    if (!currentConnectInfo) {
        return;
    }

    dispatch(
        storeConferenceConnectInfoAction({
            ...currentConnectInfo,
            ...updatedConferenceConnectInfo,
        }),
    );
};

const handleRecorder = (
    { conferenceId, status }: ISocketConferenceEventRecorder,
    { getState, dispatch }: { getState: any; dispatch: ThunkDispatch<unknown, unknown, AnyAction> },
) => {
    const {
        conferenceSession: { currentConferenceRecording },
        conferences: { conferencesConnectInfo },
    } = getState() as TAppState;

    const currentConnectInfo = conferencesConnectInfo[conferenceId];
    if (!currentConnectInfo) {
        return;
    }
    const convertedRecordStatus = convertSocketRecordStatus(status);
    dispatch(setCurrentConferenceRecordingAction(convertedRecordStatus));

    if (convertedRecordStatus === 'on' && currentConferenceRecording !== 'on') {
        dispatch(
            showNotifyThunkAction({
                title: currentConnectInfo?.name,
                text: t({
                    id: 'socket.conference.recording',
                    message: 'Конференция записывается',
                }),
                timeout: 5000,
                type: 'notify',
            }),
        );
    } else if (convertedRecordStatus === 'off' && currentConferenceRecording !== 'off') {
        dispatch(
            showNotifyThunkAction({
                title: currentConnectInfo?.name,
                text: t({
                    id: 'socket.conference.recordingStopped',
                    message: 'Запись конференции остановлена',
                }),
                timeout: 5000,
                type: 'notify',
            }),
        );
    }
};

export const subscribeToConferenceEventsActionThunk = createAsyncThunk(
    'subscribeToConferenceEvents',
    async (conferenceId: string, { dispatch, getState }) => {
        getConnectToSocket()(() => {
            const { stompClient } = getSocketServiceState();
            if (!stompClient) {
                log.error('Stomp client does not init.');
                return;
            }
            const topic = getConferenceEventsTopic(conferenceId);
            websocketLogger.debug('Connect to conference events');
            addSubscription(
                'subscribeToConferenceEvents',
                stompClient.subscribe(topic, async (message) => {
                    const { date: id, ...data } = JSON.parse(message.body);
                    websocketLogger.debug('Websocket message', { id, data });
                    if (data.actionType === 'INIT') {
                        handleInit(data, { dispatch, getState, topic, id });
                    } else if (data.actionType === 'CONFIGURED') {
                        handleConfigured(data, { dispatch, getState, topic, id });
                    } else if (data.actionType === 'SETTINGS') {
                        handleSettings(data, { dispatch, getState });
                    } else if (
                        data.actionType === 'PARTICIPANT_JOIN' ||
                        data.actionType === 'PARTICIPANT_LEAVE'
                    ) {
                        handleParticipants(data, { dispatch, getState });
                    } else if (data.actionType === 'UPDATE_SETTINGS') {
                        handleUpdateConferenceConnectInfo(data, { dispatch, getState });
                    } else if (data.actionType === 'RECORDER') {
                        handleRecorder(data, { dispatch, getState });
                    }
                }),
            );
        }, 'subscribeToConferenceEvents');
    },
);
