import React, { useEffect, useRef } from 'react';
import OutsideClick from '../OutsideClick';
import { Portal } from '../Portal';
import styles from './FullScreenPopup.module.scss';
import { IOwnProps } from './types';

export const FullScreenPopup: React.FC<IOwnProps> = ({
    theme = 'light',
    closePopup,
    listeners,
    children,
    container,
    className = '',
}) => {
    const popupWrapper = useRef<HTMLDivElement>(null);

    const onEscClick = (e: KeyboardEvent) => {
        if (e.code === 'Escape' && closePopup) {
            e.stopPropagation();
            closePopup();
        }
    };

    const addListeners = () => {
        listeners?.forEach((listener) => {
            popupWrapper.current?.addEventListener(`${listener.name}`, listener.handler);
        });
    };
    const addGeneralListeners = () => {
        document.body.addEventListener('keydown', onEscClick, { capture: true });
    };
    const removeGeneralListeners = () => {
        document.body.removeEventListener('keydown', onEscClick, { capture: true });
    };
    const removeListeners = () => {
        listeners?.forEach((listener) => {
            popupWrapper.current?.removeEventListener(`${listener.name}`, listener.handler);
        });
    };

    useEffect(() => {
        addListeners();
        return () => {
            removeListeners();
        }
    }, [popupWrapper.current]);

    useEffect(() => {
        addGeneralListeners();
        return () => {
            removeGeneralListeners();
        };
    }, []);

    const mainBackground = styles[`container_${theme}`];
    return (
        <Portal container={container}>
            <div ref={popupWrapper}
                onTouchStart={(e: React.TouchEvent) => e.stopPropagation()}>
                <div className={`${styles.container} ${mainBackground} ${className}`}>
                    {closePopup && (
                        <OutsideClick onOutsideClick={closePopup}>{children}</OutsideClick>
                    )}
                    {!closePopup && children}
                </div>
            </div>
        </Portal>
    );
};
