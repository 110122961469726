import { t } from '@lingui/macro';
import navArrow from 'assets/CalendarArrow.svg';
import navArrowYear from 'assets/CalendarArrowYear.svg';
import { tryConvertToDate } from 'components/ConferenceScheduleSettingsForm/utils';
import { isEqual } from 'date-fns';
import { TLanguages } from 'types/TLanguages';
import { TStepYear } from 'types/TStepYear';
import styles from './DatePickerMobile.module.scss';


export const getInitialDates = ({
    from,
    to,
}: {
    from?: string;
    to?: string;
}): { start: Date | null; end: Date | null } => {
    const end = tryConvertToDate(to || '') || null;
    const start = tryConvertToDate(from || '') || null;
    return { end, start };
};

export const onYearChange = (
    type: TStepYear,
    changeYearCallback: (year: number) => void,
    setYear: any,
    year: number,
) => {
    if (type === 'prev') {
        changeYearCallback(year - 1);
        setYear(year - 1);
        return;
    }
    changeYearCallback(year + 1);
    setYear(year + 1);
};

interface ICustomHeaderProps {
    monthDate: Date;
    customHeaderCount: number;
    changeYear: (year: number) => void;
    decreaseMonth: () => void;
    increaseMonth: () => void;
    setYear: any;
    year: number;
    lang?: TLanguages;
    onClose: () => void;
    onSave: () => void;
}

export const getCustomHeader = ({
    changeYear,
    customHeaderCount,
    decreaseMonth,
    increaseMonth,
    monthDate,
    setYear,
    year,
    lang,
    onClose,
    onSave,
}: ICustomHeaderProps) => (
    <div
        className={`react-datepicker__headerTop 
        ${customHeaderCount === 1 ? 'react-datepicker__headerTop_right' : ''} ${styles.header}`}>
        {customHeaderCount === 0 && (
            <div className={styles.header__top}>
                <div onClick={onClose}>{t({ id: 'Cancel' })}</div>
                <div onClick={onSave}>{t({ id: 'Ready', message: 'Готово' })}</div>
            </div>
        )}
        <div className={styles.header__bottom}>
            <button
                aria-label='Previous Year'
                className='react-datepicker__arrow'
                style={customHeaderCount === 1 ? { display: 'none' } : undefined}
                onClick={() => onYearChange('prev', changeYear, setYear, year)}>
                <img src={navArrowYear} />
            </button>
            <button
                aria-label='Previous Month'
                className='react-datepicker__arrow'
                style={customHeaderCount === 1 ? { display: 'none' } : undefined}
                onClick={decreaseMonth}>
                <img src={navArrow} />
            </button>
            <span className='react-datepicker__current-month'>
                {monthDate.toLocaleString(lang || 'en', {
                    month: 'long',
                    year: 'numeric',
                })}
            </span>
            <button
                aria-label='Next Month'
                className={'react-datepicker__navigation react-datepicker__navigation--next'}
                style={customHeaderCount === 0 ? { display: 'none' } : undefined}
                onClick={increaseMonth}>
                <img className='react-datepicker__arrow_rotate' src={navArrow} />
            </button>
            <button
                aria-label='Next Year'
                className={'react-datepicker__navigation react-datepicker__navigation--next'}
                style={customHeaderCount === 0 ? { display: 'none' } : undefined}
                onClick={() => onYearChange('next', changeYear, setYear, year)}>
                <img className='react-datepicker__arrow_rotate' src={navArrowYear} />
            </button>
        </div>
    </div>
);

// For check and not recall filter changes on close picker
export const datesAreEqual = (
    initialValues: {
        start: Date | null;
        end: Date | null;
    },
    values: { start: Date | null; end: Date | null },
): boolean => {
    let startEqual = false;
    let endEqual = false;
    if (!values.start && !initialValues.start) {
        startEqual = true;
    } else if ((values.start && !initialValues.start) || (!values.start && initialValues.start)) {
        startEqual = false;
    } else {
        startEqual = isEqual(initialValues.start as Date, values.start as Date);
    }
    if (!values.end && !initialValues.end) {
        endEqual = true;
    } else if ((values.end && !initialValues.end) || (!values.end && initialValues.end)) {
        endEqual = false;
    } else {
        endEqual = isEqual(initialValues.end as Date, values.end as Date);
    }
    return endEqual && startEqual;
};
