import { createAsyncThunk } from '@reduxjs/toolkit';
import { addWebHookAction } from 'admin-app/actions/webHooks/addWebHookAction';
import { createWebHookApiCall } from 'admin-app/api/webHooks/createWebHookApiCall';
import { EWebHooksActions } from 'admin-app/constants/thunk/EWebHooksActions';
import { IApiCallErrorData } from 'admin-app/types/IApiCallErrorData';
import { getNetworkService } from 'api/getNetworkService';

interface IPayload {
    url: string;
    token: string;
}

export const createWebHookThunkAction = createAsyncThunk<IApiCallErrorData | undefined, IPayload>(
    EWebHooksActions.CREATE_HOOK,
    async (data, { dispatch }) => {
        const {
            success,
            data: newHookData,
            errorCode,
            errorDescription,
        } = await createWebHookApiCall(getNetworkService(), data);
        if (!success) {
            return { errorCode, errorDescription };
        }
        dispatch(addWebHookAction(newHookData));
    },
);
