import { AnyAction } from '@reduxjs/toolkit';
import { addConferenceChatMessagesAction } from 'actions/chat/addConferenceChatMessagesAction';
import { EChatMessageType } from 'constants/EChatMessageType';
import { showParticipantEmotionThunkAction } from 'thunk/conference/showParticipantEmotionThunkAction';
import { IConferenceChatMessage } from 'types/chat/IConferenceChatMessage';
import { getEmotionImg } from 'utils/emotions/getEmotionImg';

export const conferenceChatMiddleware = (store: any) => (next: any) => (action: AnyAction) => {
    if (action.type === addConferenceChatMessagesAction.type) {
        const { messages, history }: { messages: IConferenceChatMessage[]; history: boolean } =
            action['payload'];
        if (!history) {
            for (const message of messages) {
                const { confUserId: participantId, text } = message as IConferenceChatMessage;
                /*if (typeof value === 'string') {
                    // For example emotion value :Heart:
                    if (value[value.length - 1] === ':' && value[0] === ':') {
                        const emotionName = value.slice(1, value.length - 1);
                        if (getEmotionImg(emotionName, 'List')) {
                            message.messageType = 'emotion';
                            message.value = emotionName;
                        }
                    }
                    const { messageType, value: text } = message;
                    if (messageType === 'emotion') {
                        const emotionName = text as string;
                        const { dispatch } = store;
                        dispatch(
                            showParticipantEmotionThunkAction({
                                emotionName,
                                participantId,
                            }),
                        );
                    }
                }*/
                // For example emotion value :Heart:
                if (text[text.length - 1] === ':' && text[0] === ':') {
                    const emotionName = text.slice(1, text.length - 1);
                    if (getEmotionImg(emotionName, 'List')) {
                        message.contentType = EChatMessageType.EMOTION;
                        message.text = emotionName;
                    }
                }
                if (message.contentType === EChatMessageType.EMOTION) {
                    const emotionName = message.text;
                    const { dispatch } = store;
                    dispatch(
                        showParticipantEmotionThunkAction({
                            emotionName,
                            participantId,
                        }),
                    );
                }
            }
        }
    }
    next(action);
};
