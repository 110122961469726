import { ESocketRequestStatus } from 'constants/ESocketRequestStatus';
import { ISocketServiceState } from 'types/socket/ISocketServiceState';
import Stomp, { Subscription } from 'stompjs';
import { SERVER_URL } from 'constants/serverUrl';
import { TPlatform } from 'types/TPlatform';

export const getSocketConnectionUrl = (platform: TPlatform): string => {
    const connectionUrl = platform === 'mobile-production' ? SERVER_URL : '';
    const socketConnectionUrl = `${connectionUrl}/ws`;
    return socketConnectionUrl;
}

const socketServiceState: ISocketServiceState = {
    connected: ESocketRequestStatus.INITIAL,
    socket: null,
    stompClient: null,
    subscriptions: {},
};

export const getSocketServiceState = () => socketServiceState;

export const setSocket = (socket: WebSocket) => (socketServiceState.socket = socket);

export const setSocketConnectionStatus = (status: ESocketRequestStatus) =>
    (socketServiceState.connected = status);

export const setStompClient = (stompClient: Stomp.Client) => {
    socketServiceState.stompClient = stompClient;
};

export const clearSubscription = (subscriptionName: string) => {
    socketServiceState.subscriptions[subscriptionName]?.unsubscribe();
    socketServiceState.subscriptions[subscriptionName] = null;
};

export const addSubscription = (subscriptionName: string, subscription: Subscription) => {
    if (socketServiceState.subscriptions[subscriptionName]) {
        // Already subscribed
        return;
    }
    socketServiceState.subscriptions[subscriptionName] = subscription;
};
