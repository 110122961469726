import { t } from '@lingui/macro';
import { resetFoundUsersAction } from 'actions/schedulingConference/resetFoundUsersAction';
import { setFoundUsersAction } from 'actions/schedulingConference/setFoundUsersAction';
import { setInvitedUserAction } from 'actions/schedulingConference/setInvitedUserAction';
import { copyToClipboardThunkAction } from 'thunk/navigator/copyToClipboardThunkAction';
import { showNotifyThunkAction } from 'thunk/notify/showNotifyThunkAction';
import { getInvitedUsersDataThunkAction } from 'thunk/schedulingConference/getInvitedUsersDataThunkAction';
import { getUsersSchedulingConfThunkAction } from 'thunk/schedulingConference/getUsersSchedulingConfThunkAction';
import { TAppDispatch } from 'types/app/TAppDispatch';
import { TAppState } from 'types/app/TAppStore';
import { isEmailValid } from 'utils/isEmailValid';
import { IDispatchProps, IPasteHandlerArgs, IStateProps } from './types';

export const mapStateToProps = (state: TAppState): IStateProps => ({
    ldap: state.applicationConfiguration.config.ldap,
    ldUsersData: state.schedulingConference.invitedUsers,
    users: state.schedulingConference.ldSearchUsers,
});
export const mapDispatchToProps = (dispatch: TAppDispatch): IDispatchProps => ({
    updateUsersList: (inputValue: string) =>
        dispatch(getUsersSchedulingConfThunkAction(inputValue)),
    resetUsersList: () => dispatch(setFoundUsersAction([])),

    getInvitedUsersData: (users) => dispatch(getInvitedUsersDataThunkAction(users)),
    setLdUsersData: (data) => dispatch(setInvitedUserAction(data)),
    resetLdUsersData: () => dispatch(resetFoundUsersAction()),
    showNotification: (text) =>
        dispatch(
            showNotifyThunkAction({
                text,
                timeout: 5000,
                type: 'error',
            }),
        ),
    copyToClipboard: (payload) => dispatch(copyToClipboardThunkAction(payload)),
});
export const fakeFieldPasteHandler = ({
    event,
    inputValues,
    setInvalidEmail,
    setFormField,
    showNotification,
    setFakeFieldValue,
    setMessageAboutPasted,
    copyToClipboard,
}: IPasteHandlerArgs) => {
    event.preventDefault();
    setInvalidEmail({ isInvalid: false, value: '' });

    const pasteText = event.clipboardData.getData('text');
    const invalidEmails: string[] = [];
    const extractedEmails = pasteText.split(/\s|;|,/g);
    const validEmails = extractedEmails.filter((email) => {
        email.trim();
        const isValid = isEmailValid(email);
        if (!isValid && email.length) {
            invalidEmails.push(email);
        } else {
            return email;
        }
    });
    validEmails.length > 0 ? setFormField(getListEmailsWithoutDuplicates([...inputValues, ...validEmails])) : false;
    if (invalidEmails.length > 0) {
        showNotification
            ? showNotification(t({ id: 'Invalid emails', message: 'Недействительные email-адреса - {emails}', values: {'emails': invalidEmails.join(', ')}})): false;
        if (invalidEmails.length > 1) {
            setMessageAboutPasted(t({
                id: 'messageAboutPastErrorEmails',
                message: '{number} успешно распознанных было применено, оставшаяся информация была сокпирована в буфер обмена',
                values: {'number': validEmails.length}}));
            copyToClipboard({dataToCopy: invalidEmails.join(', ')});
        } else {
            setInvalidEmail({ isInvalid: true, value: invalidEmails[0] });
            setFakeFieldValue(invalidEmails[0]);
            setMessageAboutPasted('');
        }
    } else {
        setMessageAboutPasted('');
    }

};
export function getAmountTitle(amount: number, titles: string[]) {
    const str = amount.toString(),
        last = Number(str[str.length - 1]);
    if (last === 1 && amount !== 11) {
        return titles[0];
    }
    if (last > 1 && last < 5 && amount < 21) {
        return titles[1];
    }
    return titles[2];
}

export function getListEmailsWithoutDuplicates(list:string[]) {
    const savedElements: {
        [key: string]: string
    } = {};
    const newArray: string[] = [];
    list.forEach((element) => {
        const keyByElement = element.toLowerCase();
        if (!savedElements[keyByElement]) {
            savedElements[keyByElement] = element;
            newArray.push(element);
        }
    });
    return newArray;
}
