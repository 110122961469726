import { connect } from 'react-redux';
import { updateConferenceInvitedUsersThunkAction } from 'thunk/conference/updateConferenceInvitedUsersThunkAction';
import { showNotifyThunkAction } from 'thunk/notify/showNotifyThunkAction';
import { TAppDispatch } from 'types/app/TAppDispatch';
import { ConferenceShareDialogInviteUsers } from './ConferenceShareDialogInviteUsers';
import { IDispatchProps } from './types';

const mapDispatchToProps = (dispatch: TAppDispatch): IDispatchProps => ({
    updateConferenceInvitedUsers: (data) =>
        dispatch(updateConferenceInvitedUsersThunkAction(data)).unwrap(),
    showNotification: (text) =>
        dispatch(
            showNotifyThunkAction({
                text,
                timeout: 5000,
                type: 'notify_green',
            }),
        ),
});

export const ConferenceShareDialogInviteUsersConnected = connect(
    null,
    mapDispatchToProps,
)(ConferenceShareDialogInviteUsers);
