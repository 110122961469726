import { IAnonymousUserData } from 'types/user/IAnonymousUserData';
import { IUserData } from 'types/user/IUserData';
import { getPublisherInitials } from 'utils/participants/getPublisherInitials';
import { getUserInitials } from './getUserInitials';

export const getInitials = ({
    user,
    anonymousUser,
}: {
    user: IUserData | null;
    anonymousUser: IAnonymousUserData | null;
}): string =>
    user
        ? getUserInitials({
            email: user.contact.email,
            firstName: user.name?.first,
            lastName: user.name?.last,
        })
        : getPublisherInitials({ displayName: anonymousUser?.name });
