export const getRadioFormatProps = () => ({
    format: (value: any) => {
        if (value === true) {
            return 'true';
        }
        return 'false';
    },
    parse: (value: any) => {
        if (value === 'true') {
            return true;
        }
        return false;
    },
});