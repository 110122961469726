import { IConferenceParticipant } from 'types/IConferenceParticipant';
import { IConferenceParticipants } from 'types/IConferenceParticipants';

export const compareParticipantsByName = (aParticipant:  IConferenceParticipant, bParticipant:  IConferenceParticipant) => {
    const [aFirstname, aLastname] = aParticipant.name.split(' ').map(el => el.toLowerCase())
    const [bFirstname, bLastname] = bParticipant.name.split(' ').map(el => el.toLowerCase())
    if (aFirstname > bFirstname) {
        return 1
    } else if (aFirstname < bFirstname) {
        return -1
    } else if (aFirstname === bFirstname && aLastname && bLastname) {
        if (aLastname > bLastname) {
            return 1
        } else if (aLastname < bLastname) {
            return -1
        }
    }
    return 0
}

export const prepareParticipantsForStore = (participants: IConferenceParticipant[]): IConferenceParticipants => {
    const result: IConferenceParticipants = {};
    participants.map((participant) => {
        result[participant.participantId] = participant;
    })
    return result
}
