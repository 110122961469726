import { ReactComponent as KebabImg } from 'assets/Kebab.svg';
import { Button } from 'components/common/Button';
import { useRef, useState } from 'react';
import { ConferenceContextMenuConnected } from './ConferenceContextMenu/ConferenceContextMenuConnected';
import { IOwnProps } from './types';
import styles from './UpcomingConferenceKebabButton.module.scss';

export const UpcomingConferenceKebabButton: React.FC<IOwnProps> = ({
    isOwner,
    isConferenceScheduled,
    isConferenceStarted,
    shareConferenceHandler,
    editConferenceHandler,
    deleteConferenceHandler,
    conferenceDetailsHandler,
}) => {
    const [contextMenuIsOpen, setContextMenuIsOpen] = useState(false);
    const kebabRef = useRef<HTMLDivElement>(null);
    const onKebabClick = () => setContextMenuIsOpen((prev) => !prev);
    const closeMenu = (e?: MouseEvent) => {
        const eventTarget = e?.target;
        if (!eventTarget) {
            setContextMenuIsOpen(false);
        }
        if (!kebabRef.current?.contains(eventTarget as any)) {
            setContextMenuIsOpen(false);
        }
    };
    return (
        <div className={styles.conferenceKebabWrapper} ref={kebabRef}>
            <div>
                <Button
                    styleType='common'
                    className={styles.conferencekebabBtn}
                    clickHandler={onKebabClick}>
                    <KebabImg />
                </Button>
                {contextMenuIsOpen && (
                    <ConferenceContextMenuConnected
                        closeMenu={closeMenu}
                        isOwner={isOwner}
                        editConferenceHandler={editConferenceHandler}
                        deleteConferenceHandler={deleteConferenceHandler}
                        isConferenceScheduled={isConferenceScheduled}
                        isConferenceStarted={isConferenceStarted}
                        shareConferenceHandler={() => {
                            setContextMenuIsOpen(false);
                            shareConferenceHandler();
                        }}
                        conferenceDetailsHandler={conferenceDetailsHandler}
                    />
                )}
            </div>
        </div>
    );
};
