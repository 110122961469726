import { createAsyncThunk } from '@reduxjs/toolkit';
import { setConferenceListScrollOffsetAction } from 'actions/sessionSettings/setConferenceListScrollOffsetAction';
import { appHistory } from 'appHistory';

interface IPayload {
    conferenceId: string;
    sessionId: string;
    offset: number;
}

export const setScrollOffsetAndRedirectToReportThunkAction = createAsyncThunk<void, IPayload>(
    'setScrollOffsetAndRedirectToReport',
    ({ conferenceId, sessionId, offset }, { dispatch }) => {
        dispatch(setConferenceListScrollOffsetAction(offset));
        appHistory.push(`/conference/${conferenceId}/${sessionId}/report`);
    },
);
