import { VideoPlaceholder } from 'components/VideoPlaceholder/VideoPlaceholder';
import { useEffect } from 'react';
import { IAudioVideoProps } from 'types/IAudioVideoProps';
import { IConferenceParticipant } from 'types/IConferenceParticipant';
import { clearMediaStreamStartCallbacks } from 'utils/media/mediaStreamStartCallbacks';
import { getPublisherInitials } from 'utils/participants/getPublisherInitials';
import { getAvatarUrl } from 'utils/users/getAvatarUrl';
import { getDisplayName } from 'utils/users/getDisplayName';
import { getInitials } from 'utils/users/getInitials';
import { getUserInitials } from 'utils/users/getUserInitials';
import { ParticipantAvatarOnly } from '../ParticipantAvatarOnly/ParticipantAvatarOnly';
import { ParticipantFrame } from '../ParticipantFrame';
import { IOwnProps as ParticipantFrameProps } from '../ParticipantFrame/types';
import { ParticipantMediaContent } from '../ParticipantMediaContent';
import styles from './ParticipantsView.module.scss';
import { IDispatchProps, IOwnProps, IStateProps } from './types';
import { addHandlerToOwnVideoTrack } from './utils';

export const ParticipantsView: React.FC<IStateProps & IOwnProps & IDispatchProps> = ({
    anonymousUser,
    appSettings,
    currentConferenceParticipants,
    currentParticipantId,
    currentUser,
    currentUserIsOwner,
    displayedAudioFrames,
    displayedVideoFrames,
    fullScreenFrames,
    isMobile,
    isOwnVideoStream,
    ownAudioVideoProps,
    ownEmotionToShow,
    ownParticipantId,
    ownStream,
    ownTalkingStatus,
    participantFrameHeight,
    participantFrameWidth,
    participantsAudio,
    participantsEmotion,
    participantsTalking,
    participantsVideo,
    publishers,
    setLocalStreamProps,
    setPriorityFrames,
    showChat,
    streams,
    updateParticipantsFrame,
    updateSubscriptionsVideoStreams,
    hideOwnFrame,
    forRecorder,
    videoStreamsSubscriptionsUpdate,
}) => {
    let userAvatar = '';
    if (currentUser?.etag) {
        const { id, etag } = currentUser;
        userAvatar = getAvatarUrl({ id, etag, size: '160' });
    }
    const initials = getInitials({ user: currentUser, anonymousUser: anonymousUser });
    const changePriorityFrame = (participantId: string) => {
        if (fullScreenFrames[0]) {
            setPriorityFrames({ participantIds: [], global: false });
            return;
        }
        if (fullScreenFrames[0] !== participantId) {
            setPriorityFrames({ participantIds: [participantId], global: false });
        }
    };
    const turnOffScreenSharing = () =>
        setLocalStreamProps({
            screenShared: false,
            useVideo: false,
        });

    useEffect(() => {
        const removeHandlerCallback = addHandlerToOwnVideoTrack({
            videoStream: ownStream?.video?.stream,
            turnOffScreenSharing,
        });

        return () => {
            removeHandlerCallback && removeHandlerCallback();
        };
    }, [ownStream]);

    useEffect(() => {
        if (
            videoStreamsSubscriptionsUpdate.subscribe.length ||
            videoStreamsSubscriptionsUpdate.unsubscribe.length
        ) {
            updateSubscriptionsVideoStreams();
        }
    }, [videoStreamsSubscriptionsUpdate]);

    useEffect(
        () => () => {
            clearMediaStreamStartCallbacks();
        },
        [],
    );
    const displayFullScreenFrames = (frames: string[]) =>
        frames.map((displayParticipantId) => {
            const priorityUserData = {
                userId: '',
                firstName: '',
                lastName: '',
                email: '',
                avatarUrl: '',
                avatarUrlMin: '',
            };
            const getPriorityParticipantData = (): IConferenceParticipant | undefined => {
                if (displayParticipantId === currentParticipantId && currentUserIsOwner) {
                    // This means the current user is the owner & priority frame
                    return {
                        userId: currentUser?.id || '',
                        name: anonymousUser ? anonymousUser.name : getDisplayName(currentUser),
                        participantId: currentParticipantId,
                        etag: currentUser?.etag || '',
                    };
                }
                if (!currentConferenceParticipants) {
                    return undefined;
                }
                return currentConferenceParticipants[displayParticipantId];
            };
            const participantWithPriorityScreen = getPriorityParticipantData();
            if (participantWithPriorityScreen) {
                const formattedFullName = participantWithPriorityScreen.name.trim().split(' ');
                priorityUserData.firstName = formattedFullName[0] || '';
                priorityUserData.lastName = formattedFullName[1] || '';
                const { userId: id, etag } = participantWithPriorityScreen;
                priorityUserData.avatarUrl = getAvatarUrl({ id, etag, size: '150' });
                priorityUserData.avatarUrlMin = getAvatarUrl({ id, etag, size: '46' });
                priorityUserData.userId = participantWithPriorityScreen?.userId || '';
            }
            const ownFrame = () => {
                return currentParticipantId === displayParticipantId;
            };
            const emotionToShow = ownFrame()
                ? ownEmotionToShow
                : participantsEmotion[displayParticipantId] || null;

            const { audioVideoProps, mainTalkingStatus } = ((): {
                audioVideoProps: IAudioVideoProps | undefined;
                mainTalkingStatus: boolean | undefined;
            } => {
                if (currentParticipantId === displayParticipantId) {
                    return {
                        audioVideoProps: ownAudioVideoProps,
                        mainTalkingStatus: ownTalkingStatus,
                    };
                }
                const foundPublisher = publishers[displayParticipantId];
                const foundPublisherTalkingStatus = participantsTalking[displayParticipantId];
                let audioVideoProps: IAudioVideoProps | undefined;
                if (foundPublisher) {
                    audioVideoProps = { ...foundPublisher.audioVideoProps };
                    audioVideoProps.useAudio = participantsAudio[foundPublisher.participantId];
                    audioVideoProps.useVideo = participantsVideo[foundPublisher.participantId];
                }
                return {
                    audioVideoProps,
                    mainTalkingStatus: foundPublisherTalkingStatus,
                };
            })();
            const mainMediaStreams =
                currentParticipantId !== displayParticipantId
                    ? {
                        video: streams[displayParticipantId]?.video?.stream,
                        audio: streams[displayParticipantId]?.audio?.stream,
                    }
                    : { video: ownStream.video?.stream, audio: ownStream.audio?.stream };

            if (!audioVideoProps) {
                return null;
            }
            // const isAudioMuted = !participantsAudio[displayParticipantId];
            return (
                <ParticipantFrame
                    active={mainTalkingStatus}
                    owner={
                        (displayParticipantId === currentParticipantId && currentUserIsOwner) ||
                        participantWithPriorityScreen?.creator
                    }
                    key={displayParticipantId}
                    isPriorityFrame={true}
                    initials={getUserInitials({
                        email: priorityUserData.email,
                        firstName: priorityUserData.firstName,
                        lastName: priorityUserData.lastName,
                    })}
                    displayName={`${priorityUserData.firstName} ${priorityUserData.lastName}`}
                    width={participantFrameWidth}
                    height={participantFrameHeight}
                    avatar={priorityUserData.avatarUrl}
                    avatarMin={priorityUserData.avatarUrlMin}
                    ownFrame={ownFrame()}
                    audioVideoProps={audioVideoProps}
                    cropVideo={false}
                    isMobile={isMobile}
                    participantId={displayParticipantId}
                    forRecorder={forRecorder}
                    emotionToShow={emotionToShow}>
                    <ParticipantMediaContent
                        // audioStream={mainMediaStreams?.audio}
                        displayUsersFrameInfo={appSettings?.displayUsersFrameInfo}
                        // muted={currentParticipantId === displayParticipantId || isAudioMuted}
                        // displayedVideoFrames={displayedVideoFrames}
                        participantId={displayParticipantId}
                        // currentParticipantId={currentParticipantId}
                        changePriorityFrame={() => changePriorityFrame(displayParticipantId)}
                        videoStream={
                            participantsVideo[displayParticipantId]
                                ? mainMediaStreams?.video
                                : undefined
                        }
                    />
                </ParticipantFrame>
            );
        });
    const displayVideoFrames = (frames: string[]) =>
        frames.map((participantId) => {
            let participantProps: any = {
                width: participantFrameWidth,
                height: participantFrameHeight,
                isMobile: isMobile,
                cropVideo: false,
            };
            let mediaContent;
            const isCurrentUserFrame = participantId === ownParticipantId;
            if (isCurrentUserFrame && !hideOwnFrame) {
                if (ownAudioVideoProps.screenShared) {
                    // FIXME This is not right, only a temporary fix
                    return null;
                }
                const currentUserProps = {
                    key: participantId,
                    active: ownTalkingStatus,
                    owner: currentUserIsOwner,
                    initials: initials,
                    displayName: getDisplayName(currentUser || anonymousUser),
                    avatar: userAvatar,
                    ownFrame: true,
                    anonymous: anonymousUser !== null,
                    participantId: participantId,
                    emotionToShow: ownEmotionToShow,
                    audioVideoProps: ownAudioVideoProps,
                };
                participantProps = { ...participantProps, ...currentUserProps };
                mediaContent = (
                    <ParticipantMediaContent
                        // audioStream={ownStream?.audio?.stream}
                        videoStream={
                            (!isMobile || !showChat) && isOwnVideoStream
                                ? ownStream?.video?.stream
                                : undefined
                        }
                        changePriorityFrame={() => changePriorityFrame(participantId)}
                        // muted
                        // displayedVideoFrames={displayedVideoFrames}
                        participantId={participantId}
                        // currentParticipantId={currentParticipantId}
                        notifyFrameSize={updateParticipantsFrame}
                        displayUsersFrameInfo={appSettings?.displayUsersFrameInfo}
                    />
                );
            } else {
                const publisher = publishers[participantId];
                if (!publisher) {
                    return null;
                }
                const participant = currentConferenceParticipants[publisher.participantId];
                const emotion = participantsEmotion[publisher.participantId];
                const talkingStatus = participantsTalking[publisher.participantId];
                const avatarUrl =
                    participant && participant?.userId
                        ? getAvatarUrl({
                            id: participant.userId,
                            etag: participant.etag,
                            size: '150',
                        })
                        : '';
                const mediaStreams =
                    participantsVideo[publisher.participantId] ||
                    participantsAudio[publisher.participantId]
                        ? streams[publisher.participantId]
                        : undefined;
                const calculatedAudioVideoProps = {
                    ...publisher.audioVideoProps,
                    ...{
                        useVideo: !!participantsVideo[publisher.participantId],
                        useAudio: !!participantsAudio[publisher.participantId],
                    },
                };
                const publisherProps: Partial<ParticipantFrameProps & { key: string }> = {
                    key: publisher.participantId,
                    active: talkingStatus,
                    owner: participant?.creator,
                    initials: getPublisherInitials(publisher),
                    displayName: publisher.displayName,
                    avatar: avatarUrl,
                    audioVideoProps: calculatedAudioVideoProps,
                    anonymous: participant?.userId === undefined,
                    participantId: publisher.participantId,
                    emotionToShow: emotion,
                };
                participantProps = { ...participantProps, ...publisherProps };
                mediaContent = (
                    <ParticipantMediaContent
                        // audioStream={
                        //     calculatedAudioVideoProps.useAudio
                        //         ? mediaStreams?.audio?.stream
                        //         : undefined
                        // }
                        videoStream={
                            (!isMobile || !showChat) && participantsVideo[publisher.participantId]
                                ? mediaStreams?.video?.stream
                                : undefined
                        }
                        changePriorityFrame={() => changePriorityFrame(participantId)}
                        displayUsersFrameInfo={appSettings?.displayUsersFrameInfo}
                        participantId={publisher.participantId}
                        // currentParticipantId={currentParticipantId}
                        // muted={!calculatedAudioVideoProps.useAudio}
                        // displayedVideoFrames={displayedVideoFrames}
                        notifyFrameSize={updateParticipantsFrame}
                        audioLevel={publisher.audioLevel}
                    />
                );
            }
            return (
                <ParticipantFrame
                    displayName={
                        isCurrentUserFrame
                            ? getDisplayName(currentUser || anonymousUser)
                            : publishers[participantId].displayName
                    }
                    forRecorder={forRecorder}
                    initials={''}
                    participantId={''}
                    {...participantProps}>
                    {mediaContent}
                </ParticipantFrame>
            );
        });

    if (fullScreenFrames.length !== 0) {
        return <>{displayFullScreenFrames(fullScreenFrames)}</>;
    }
    if (displayedVideoFrames.length !== 0) {
        return <div className={styles.videoView}>{displayVideoFrames(displayedVideoFrames)}</div>;
    }

    return (
        <div className={styles.avatarsOnly}>
            {!isMobile && <VideoPlaceholder />}
            <div className={styles.scroll}>
                {displayedAudioFrames.map((participantId: string) => {
                    const publisher = publishers[participantId];
                    if (!publisher) {
                        return null;
                    }
                    const allParticipantsEmotion = { ...participantsEmotion };
                    if (ownEmotionToShow) {
                        allParticipantsEmotion[ownParticipantId] = ownEmotionToShow;
                    }
                    const emotion =
                        participantId === ownParticipantId
                            ? ownEmotionToShow
                            : participantsEmotion[participantId];
                    const talking =
                        participantId === ownParticipantId
                            ? ownTalkingStatus
                            : participantsTalking[participantId];
                    const isShowDisabledAudio =
                        participantId === currentParticipantId
                            ? !ownAudioVideoProps.audioPermitted
                            : !publisher.audioVideoProps.audioPermitted;
                    const isShowMutedIcon =
                        participantId === currentParticipantId
                            ? !ownAudioVideoProps.useAudio
                            : !participantsAudio[participantId];

                    if (currentParticipantId === participantId && hideOwnFrame) {
                        return null;
                    }
                    return (
                        <ParticipantAvatarOnly
                            key={participantId}
                            publisher={publisher}
                            participant={currentConferenceParticipants[publisher.participantId]}
                            emotion={emotion}
                            talking={talking}
                            isShowDisabledAudio={isShowDisabledAudio}
                            isShowMutedIcon={isShowMutedIcon}
                            participantId={participantId}>
                            {/* {participantId !== currentParticipantId && (
                                <ParticipantAudioContentManagedConnected
                                    participantId={participantId}
                                    audioStream={
                                        participantsAudio[participantId]
                                            ? streams[participantId]?.audio?.stream
                                            : undefined
                                    }
                                    muted={!participantsAudio[participantId]}
                                    displayedFrames={displayedAudioFrames}
                                />
                            )} */}
                        </ParticipantAvatarOnly>
                    );
                })}
            </div>
        </div>
    );
};
