import { setParticipantsListOpenAction } from 'actions/applicationView/setParticipantsListOpenAction';
import { connect } from 'react-redux';
import { getCurrentConferenceConnectInfoSelector } from 'selectors/getCurrentConferenceConnectInfoSelector';
import { TAppDispatch } from 'types/app/TAppDispatch';
import { TAppState } from 'types/app/TAppStore';
import { ConferenceParticipantsList } from './ConferenceParticipantsList';
import { IDispatchProps, IStateProps } from './types';

const mapStateToProps = (state: TAppState): IStateProps => ({
    conferenceConnectInfo: getCurrentConferenceConnectInfoSelector(state),
    currentConferenceParticipants: state.conferenceSession.currentConferenceParticipants,
    currentConferenceParticipantsIdsSorted: state.conferenceSession.currentConferenceSortedParticipantsIds,
    currentUserIsAnonym: state.auth.anonymous,
    ownParticipantId: state.janus.ownParticipantId,
    participantsListOpen: state.applicationView.participantListOpen,
    publishers: state.janus.publishers,
    participantsCount: state.conferenceSession.currentConferenceSortedParticipantsIds.length,
});

const mapDispatchToProps = (dispatch: TAppDispatch): IDispatchProps => ({
    setParticipantsListOpen: (newState: boolean) =>
        dispatch(setParticipantsListOpenAction(newState)),
});

export const ConferenceParticipantsListConnected = connect(
    mapStateToProps,
    mapDispatchToProps,
)(ConferenceParticipantsList);
