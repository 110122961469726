import { FC } from 'react';
import styles from './Breadcrumbs.module.scss';
import { LinkWithPendingActionCheckConnected } from 'components/common/LinkWithPendingActionCheck';
import { Arrow } from 'components/common/Arrow';
import { IOwnProps } from './types';

export const Breadcrumbs: FC<IOwnProps> = ({ items }) => (
    <div className={styles.header}>
        {items.map(({name, link}, index) => {
            if (index === items.length - 1) {
                return <span key={`breadcrumb-${name}`}>{name}</span>
            }
            return (
                <div key={`breadcrumb-${name}`}>
                    <LinkWithPendingActionCheckConnected to={link}>
                        {name}
                    </LinkWithPendingActionCheckConnected>
                    <Arrow
                        className={styles.arrow}
                        orientation={'right'}
                        size={'medium'}
                        color={'gray'}
                    />
                </div>
            )
        })}
    </div>
)
