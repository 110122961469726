import { INetworkService } from 'types/api/INetworkService';
import { getFormData } from 'utils/getFormData';

interface IPayload {
    username: string;
    password: string;
    rememberMe?: boolean;
}

export const loginApiCall = (
    networkService: INetworkService,
    { username, password, rememberMe = true }: IPayload,
) =>
    networkService.post({
        url: '/login',
        data: getFormData({ username, password, rememberMe: String(rememberMe) }),
    });
