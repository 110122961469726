interface IDownloadRecord {
    conferenceId: string;
    sessionId: string;
    recordId: string;
    getRecord: (args: IGetRecord) => Promise<any>;
    onProgress?: (event: ProgressEvent) => void;
}

type IGetRecord = Omit<IDownloadRecord, 'getRecord'> & {
    onProgress?: (event: ProgressEvent) => void;
};

export const downloadRecord = async ({
    conferenceId,
    sessionId,
    recordId,
    getRecord,
    onProgress,
}:IDownloadRecord) => {
    const { payload: recordData } = await getRecord({
        conferenceId,
        sessionId,
        recordId,
        onProgress,
    });
    if (!recordData) return;
    const a = document.createElement('a');
    document.body.appendChild(a);
    a.href = recordData;
    a.download = `${sessionId}.mp4`;
    a.click();
    document.body.removeChild(a);
};
