import { t } from '@lingui/macro';
import SmileImg from 'assets/Smile.svg';
import SmileSmallImg from 'assets/SmileSmall.svg';
import OutsideClick from 'components/common/OutsideClick';
import { ConferenceButton } from 'components/ConferenceButton';
import { EChatMessageStatus } from 'constants/EChatMessageStatus';
import { EChatMessageType } from 'constants/EChatMessageType';
import { useEffect, useRef, useState } from 'react';
import { createRandomId } from 'utils/createRandomId';
import { setEscCloseDropdown } from 'utils/escCloseDropdown';
import styles from './ConferenceEmotionsButton.module.scss';
import { EmotionsList } from './EmotionsList';
import { IDispatchProps, IStateProps, IOwnProps } from './types';
import { getEmotionsList } from './utils';

export const ConferenceEmotionsButton: React.FC<IOwnProps & IStateProps & IDispatchProps> = ({
    addNewMessage,
    mobile,
    ownParticipantId,
    ownUserId,
    smallImage,
    inChat,
}) => {
    const [listOpen, setListOpen] = useState(false);
    const componentRef = useRef<HTMLDivElement>(null);

    useEffect(() => {
        setEscCloseDropdown(listOpen);
    }, [listOpen]);

    const onClick = () => setListOpen((value) => !value);
    const handleCloseList = (e: any) => {
        if (e.type === 'keydown') {
            setListOpen(false);
            return;
        }
        const target = e.target as Element;
        if (componentRef.current && !componentRef.current.contains(target)) {
            setListOpen(false);
        }
    };
    const handleEmotionClick = (emotionName: string) => {
        addNewMessage({
            text: emotionName,
            contentType: EChatMessageType.EMOTION,
            status: EChatMessageStatus.SENT,
            ownUserId,
            confUserId: ownParticipantId,
            id: createRandomId(),
        });
        // setOwnEmotionToShow(emotionName);
    };

    return (
        <div
            className={`${styles.emotions} ${mobile && smallImage ? styles.emotions_mobile : ''}`}
            onClick={onClick}
            ref={componentRef}>
            {listOpen && (
                <OutsideClick onOutsideClick={handleCloseList}>
                    <EmotionsList
                        emotionsList={getEmotionsList()}
                        inChat={inChat}
                        handleEmotionClick={handleEmotionClick}
                    />
                </OutsideClick>
            )}
            <ConferenceButton
                img={!smallImage ? SmileImg : SmileSmallImg}
                text={!smallImage ? t({ id: 'Reaction', message: 'Реакция' }) : undefined}
                mobile={mobile}
            />
        </div>
    );
};
