import { connect } from 'react-redux';
import { TAppState } from 'types/app/TAppStore';
import { TAppDispatch } from 'types/app/TAppDispatch';
import { LanguageMenu } from './LanguageMenu';
import { IStateProps, IDispatchProps } from './types';
import { setCurrentLanguageThunkAction } from 'thunk/appView/setCurrentLanguageThunkAction';
import { TLanguages } from 'types/TLanguages';
import { getCurrentLanguageSelector } from 'selectors/getCurrentLanguageSelector';

const mapStateToProps = (state: TAppState): IStateProps => ({
    currentLanguage: getCurrentLanguageSelector(state),
});
const mapDispatchToProps = (dispatch: TAppDispatch): IDispatchProps => ({
    setLanguage: (lang: TLanguages) => dispatch(setCurrentLanguageThunkAction(lang)),
});
export const LanguageMenuConnected = connect(mapStateToProps, mapDispatchToProps)(LanguageMenu);
