import { createAction } from '@reduxjs/toolkit';
import { EJanusActions } from 'constants/actions/EJanusActions';
import { IAudioVideoProps } from 'types/IAudioVideoProps';

interface IPayload {
    participantId: string;
    audioVideoProps: Partial<IAudioVideoProps>;
}

export const setParticipantMuteStatusAction = createAction<IPayload>(
    EJanusActions.SET_PARTICIPANT_MUTE_STATUS,
);
