import { t } from '@lingui/macro';
import { Field, FieldInputProps } from 'react-final-form';
import { classNames } from 'utils/classNames';
import styles from './DeviceField.module.scss';
import { IDispatchProps, IOwnProps } from './types';

export const DeviceField: React.FC<IOwnProps & IDispatchProps> = ({
    name,
    imgWrapperOffStyles,
    deviceStatusText,
    isDeviceDisabled,
    Icon,
    showNotification,
}) => {
    const onClick = (e: React.MouseEvent, input: FieldInputProps<any, HTMLElement>) => {
        e.preventDefault();
        if (isDeviceDisabled) {
            input.name === 'useAudio'
                ? showNotification(t({ id: 'audio.device.disabled.by.admin' }))
                : showNotification(t({ id: 'video.device.disabled.by.admin' }));
            return;
        } else {
            input.onChange(!input.value);
        }
    };

    return (
        <div className={styles.container}>
            <Field name={name}>
                {({ input }) => (
                    <label
                        htmlFor={name}
                        className={classNames([
                            styles.deviceCheckbox,
                            isDeviceDisabled ? styles.deviceCheckbox_disabled : '',
                        ])}
                        onClick={(e) => onClick(e, input)}>
                        <div
                            className={classNames([
                                styles.deviceCheckbox__imgWrapper,
                                !input.value ? styles.deviceCheckbox__imgWrapper_off : '',
                                !input.value ? imgWrapperOffStyles : '',
                            ])}>
                            <Icon className={styles.deviceCheckbox__img} />
                        </div>
                        <span className={styles.deviceCheckbox__text}>
                            {input.value ? deviceStatusText.isOn : deviceStatusText.isOff}
                        </span>
                        <input type='checkbox' id={name} disabled={isDeviceDisabled} {...input} />
                    </label>
                )}
            </Field>
        </div>
    );
};
