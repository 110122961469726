import { createReducer } from '@reduxjs/toolkit';
import { deleteConferenceInfoAction } from 'actions/conferences/deleteConferenceInfoAction';
import { resetConferencesStoreAction } from 'actions/conferences/resetConferencesStoreAction';
import { storeConferenceInfoAction } from 'actions/conferences/storeConferenceInfoAction';
import { storeConferenceConnectInfoAction } from 'actions/conferences/storeConferenceConnectInfoAction';
import { resetStoreAction } from 'actions/resetStoreAction';
import { IConferenceConnectInfo } from 'types/conferences/IConferenceConnectInfo';
import { IConferenceInfo } from 'types/conferences/IConferenceInfo';
import { storeDeviceNotification } from 'actions/conferences/storeDeviceNotification';
import { IDeviceNotification } from 'types/IDeviceNotification';
import { IConferenceUser } from 'types/IConferenceUser';
import { IConferenceParticipant } from 'types/IConferenceParticipant';
import {
    convertParticipantFromServerToParticipant,
} from 'utils/participants/convertParticipantFromServerToParticipant';
import { resetConferenceInvitedUsersAction } from 'actions/conferences/resetConferenceInvitedUsersAction';
import { setConferencesInvitedUsersAction } from 'actions/conferences/setConferencesInvitedUsersAction';
import { setConferencesParticipantsAction } from 'actions/conferences/setConferencesParticipantsAction';
import { setPastConferencesInfoAction } from 'actions/conferences/setPastConferencesInfoAction';

export interface IConferencesReducer {
    conferencesInfo: { [id: string]: IConferenceInfo };
    conferencesConnectInfo: { [id: string]: IConferenceConnectInfo };
    deviceNotification: IDeviceNotification | null;
    conferencesInvitedUsers: { [id: string]:  IConferenceUser[] };
    conferencesParticipants: { [id: string]:  IConferenceParticipant[] };
}

export const conferencesReducerInitialState: IConferencesReducer = {
    conferencesInfo: {},
    conferencesConnectInfo: {},
    deviceNotification: null,
    conferencesInvitedUsers: {},
    conferencesParticipants: {},
};

export const conferencesReducer = createReducer(conferencesReducerInitialState, (builder) =>
    builder
        .addCase(resetConferencesStoreAction, () => {
            return conferencesReducerInitialState;
        })
        .addCase(resetStoreAction, () => conferencesReducerInitialState)
        .addCase(storeConferenceConnectInfoAction, (state, { payload: conference }) => {
            state.conferencesConnectInfo[conference.id] = conference;
            if(state.conferencesInfo[conference.id]) {
                state.conferencesInfo[conference.id] = {...state.conferencesInfo[conference.id], ...conference};
            }
        })
        .addCase(storeConferenceInfoAction, (state, { payload: conference }) => {
            for (const item of conference) {
                state.conferencesInfo[item.id] = item;
            }
        })
        .addCase(deleteConferenceInfoAction, (state, { payload: conferenceId }) => {
            delete state.conferencesInfo[conferenceId];
        })
        .addCase(storeDeviceNotification, (state, { payload: notification}) => {
            state.deviceNotification = notification;
        })
        .addCase(setConferencesParticipantsAction, (state, { payload: {id, users}}) => {
            const conferenceCreator = state.conferencesConnectInfo[id].creator;
            if (conferenceCreator) {
                state.conferencesParticipants[id] = users
                    .map(user => convertParticipantFromServerToParticipant(user, conferenceCreator))
            }
        })
        .addCase(setPastConferencesInfoAction, (state, { payload}) => {
            state.conferencesParticipants[payload.conferenceId] = payload.participants
                .map(user => convertParticipantFromServerToParticipant(user, payload.creator))
        })
        .addCase(resetConferenceInvitedUsersAction, (state, {payload}) => {
            delete state.conferencesInvitedUsers[payload.id]
        })
        .addCase(setConferencesInvitedUsersAction, (state, {payload: {id, users}}) => {
            state.conferencesInvitedUsers[id] = users
        })
    ,
);
