import { IOwnProps } from './types';
import styles from '../ParticipantMediaContent.module.scss';
import { t } from '@lingui/macro';

export const StreamInfoHood: React.FC<IOwnProps> = ({ videoStreamInfo, audioLevel }) => {
    const { width, height, frameRate } = videoStreamInfo || {
        width: undefined,
        height: undefined,
        frameRate: undefined,
    };
    const videoResolution =
        width && height
            ? t({
                id: 'showResolutionInfo.text',
                message: 'Разрешение: {0}',
                values: {
                    '0': `${width}x${height}`,
                },
            })
            : t({
                id: 'videoResolution.NotAvailable.text',
                message: 'Разрешение: не доступно',
            });
    return (
        <div className={styles.debugInfo}>
            {<span>{videoResolution}</span>}
            {frameRate && (
                <span>
                    {t({
                        id: 'frameRate.text',
                        message: 'Частота: {0} к/с',
                        values: { '0': frameRate },
                    })}
                </span>
            )}
            {audioLevel && (
                <span>
                    {t({
                        id: 'audioLevel.text',
                        message: 'Уровень звука: {0}',
                        values: { '0': audioLevel },
                    })}
                </span>
            )}
        </div>
    );
};
