import { createAsyncThunk } from '@reduxjs/toolkit';
import { getNetworkService } from 'api/getNetworkService';
import { getPastConferenceInfoApiCall } from 'api/conference/getPastConferenceInfoApiCall';
import { IPastConferenceInfo } from 'types/conferencesList/IPastConferenceInfo';
import { setPastConferencesInfoAction } from 'actions/conferences/setPastConferencesInfoAction';

interface IPayload {
    conferenceId: string;
    sessionId: string;
}

export const getPastConferenceInfoThunkAction = createAsyncThunk<IPastConferenceInfo | undefined, IPayload>(
    'getPastConferenceInfo',
    async (payload, {dispatch}) => {
        const response = await getPastConferenceInfoApiCall(getNetworkService(), payload);
        if (!response.success) {
            return undefined;
        }
        dispatch(setPastConferencesInfoAction(response.data))
        return response.data;
    },
);
