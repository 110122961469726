import { Arrow } from 'components/common/Arrow';
import { useEffect, useRef, useState } from 'react';
import { setEscCloseDropdown } from 'utils/escCloseDropdown';
import OutsideClick from '../OutsideClick';
import styles from './Select.module.scss';
import { SelectItem } from './SelectItem';
import { IOwnProps } from './types';

export const Select: React.FC<IOwnProps> = ({
    className,
    errorStyles,
    tooltip,
    items,
    theme = 'light',
    selectedValue,
    changeHandler,
    displayFilteredHeader,
    styleType = 'default',
}) => {
    const [isOpen, setIsOpen] = useState(false);
    const activeItem = useRef(null);
    const itemsContainerRef = useRef(null);
    const componentRef = useRef<HTMLDivElement>(null);

    const selectHeaderClickHandler = (e: any) => {
        e.stopPropagation();
        setIsOpen(!isOpen);
    };

    const closeDropDown = (e: any) => {
        const target = e.target as Element;
        // Case 1: two Select components on a page. If you click on the first, the second should close
        // Case 2: click on an open Select should close the dropdown menu
        if (componentRef.current && !componentRef.current.contains(target)) {
            setIsOpen(false);
        }
    };

    const getCurrentValue = () => {
        if (displayFilteredHeader) {
            const targetOption = items?.find((item) => item.value === selectedValue);
            return targetOption ? targetOption.displayValue : '';
        }
        if (typeof selectedValue === 'object') {
            return selectedValue.displayValue;
        }
        return selectedValue;
    };

    const scrollToActiveItem = () => {
        if (activeItem.current && itemsContainerRef.current) {
            const element = activeItem.current as unknown as HTMLElement;
            const container = itemsContainerRef.current as unknown as HTMLElement;
            const scroll = container.scrollHeight > container.clientHeight;
            scroll && element.scrollIntoView({ behavior: 'smooth', block: 'center' });
        }
    };

    useEffect(() => {
        setEscCloseDropdown(isOpen);
        if (isOpen) {
            scrollToActiveItem();
        }
    }, [isOpen]);

    const renderItems = (handleItemChange: any) =>
        items?.map((item, idx) => (
            <SelectItem
                key={idx}
                item={item}
                selectedValue={selectedValue}
                activeItem={activeItem}
                enableTooltip={true}
                theme={theme}
                setIsOpen={setIsOpen}
                handleItemChange={handleItemChange}
            />
        ));

    const getArrowColor = () => {
        let color: 'white' | 'gray' | 'blue' = 'gray';
        if (theme === 'dark') {
            color = 'white';
        }
        if (theme === 'blue') {
            color = 'blue';
        }
        if (theme === 'light' && isOpen) {
            color = 'blue';
        }
        return color;
    };

    const style = isOpen
        ? `${styles[`listOpen_${styleType}`]} ${styles[`selectedItem_${styleType}`]}`
        : `${styles[`selectedItem_${styleType}`]}`;
    const selectContainerStyles = `${styles.select} ${className || ''} ${
        theme !== 'light' ? styles[`${theme}Theme`] : ''
    }`;
    const selectHeaderStyles = errorStyles ? `${style} ${errorStyles}` : style;
    return (
        <div
            className={selectContainerStyles}
            data-select-id={tooltip ? tooltip.id : ''}
            onClick={selectHeaderClickHandler}
            ref={componentRef}>
            <div className={selectHeaderStyles} data-js-select-header>
                <span className={styles.activeValue}>{getCurrentValue()}</span>
                {(styleType === 'default' || styleType === 'new') && (
                    <div className={styles.arrowWrapper}>
                        <Arrow
                            className={styles.arrow_relative}
                            color={getArrowColor()}
                            size={'medium'}
                            orientation={'down'}
                        />
                    </div>
                )}
            </div>
            {isOpen && (
                <OutsideClick onOutsideClick={closeDropDown}>
                    <div
                        className={`${styles.items} ${styles[`items_${styleType}`]}`}
                        ref={itemsContainerRef}>
                        {renderItems(changeHandler)}
                    </div>
                </OutsideClick>
            )}
        </div>
    );
};
