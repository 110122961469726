import { INetworkService } from 'types/api/INetworkService';
import { getUserTimeZone } from 'utils/timeZones/getUserTimeZone';

interface IPayload {
    name: string;
    type: string;
    pin?: string;
    lobby?: boolean;
    participants?: {
        added: any[];
    };
    guestsEmails?: {
        added: any[];
    };
    muteUponEntry?: boolean;
    videoCreator?: boolean;
    videoParticipants?: boolean;
    isRecording: boolean;
    duration?: number;
}

export const createConferenceApiCall = (
    networkService: INetworkService,
    {
        lobby = false,
        pin,
        participants = {
            added: [],
        },
        guestsEmails = {
            added: [],
        },
        duration = 30,
        ...etc
    }: IPayload,
) =>
    networkService.post({
        url: '/api/conferences/start',
        data: {
            pin,
            timeZone: getUserTimeZone(),
            participants,
            guestsEmails,
            lobby,
            duration,
            ...etc,
        },
    });
