import { IOwnProps, IStateProps } from './types';

type TProps = IStateProps & IOwnProps;

export const MobileWrapper: React.FC<TProps> = ({
    renderDesktop,
    isMobile,
    renderMobile,
    renderSmallScreen,
    isSmallScreen,
    isUltraSmallScreen,
}) => {
    if (isUltraSmallScreen) {
        return renderMobile();
    }
    if (isSmallScreen && renderSmallScreen) {
        return renderSmallScreen();
    }
    return isMobile ? renderMobile() : renderDesktop();
};
