import { IStateProps } from './types';
import styles from './SimpleConferenceHeader.module.scss';
import { AudioFramesConnected } from 'pages/ConferencePage/AudioFrames';

export const SimpleConferenceHeader: React.FC<IStateProps> = ({
    conferenceName,
}) => (
    <div className={styles.header}>
        <div className={styles.header__left}>
            <div className={styles.header__conferenceName}>{conferenceName}</div>
        </div>
        <AudioFramesConnected hideOwnAvatar={true} />
    </div>
);
