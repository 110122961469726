import { t } from '@lingui/macro';
import ViewChoiceImg from 'assets/ViewChoice.svg';
import { Arrow } from 'components/common/Arrow';
import { Button } from 'components/common/Button';
import OutsideClick from 'components/common/OutsideClick';
import React from 'react';
import { IOwnProps } from './types';
import styles from './ViewSelector.module.scss';

interface IView {
    maxParticipants: number;
    title: string;
}

const getViews = (): IView[] => [
    {
        maxParticipants: 4,
        title: t({ message: '4 пользователя', id: 'views_for_4' }),
    },
    {
        maxParticipants: 6,
        title: t({ message: '6 пользователей', id: 'views_for_6' }),
    },
    {
        maxParticipants: 12,
        title: t({ message: '12 пользователей', id: 'views_for_12' }),
    },
    {
        maxParticipants: 20,
        title: t({ message: '20 пользователей', id: 'views_for_20' }),
    },
];

export const ViewSelector: React.FC<IOwnProps> = ({ changeDisplayView, maxVideoFrames }) => {
    const [showDropDown, setShowDropDown] = React.useState<boolean>(false);
    const componentRef = React.useRef<HTMLDivElement>(null);

    const closeDropDown = (e: any) => {
        if (e.type === 'keydown') {
            setShowDropDown(false);
            return;
        }
        const target = e.target as Element;
        // Case 1: two Components on a page. If you click on the first, the second should close
        // Case 2: click on an open Component should close the dropdown menu
        if (componentRef.current && !componentRef.current.contains(target)) {
            setShowDropDown(false);
        }
    };

    const drawViews = () => (
        <div className={styles.dropDownContainer}>
            <OutsideClick onOutsideClick={closeDropDown}>
                <div className={styles.dropDown}>
                    {getViews().map((view: IView) => (
                        <ul
                            key={view.title}
                            className={
                                maxVideoFrames === view.maxParticipants ? styles.checked : ''
                            }
                            onClick={() => {
                                changeDisplayView({
                                    maxDisplayedParticipants: view.maxParticipants,
                                });
                                setShowDropDown(false);
                            }}>
                            <span>{view.title}</span>
                        </ul>
                    ))}
                </div>
            </OutsideClick>
        </div>
    );
    const clickHandler = () => setShowDropDown(!showDropDown);
    return (
        <div ref={componentRef}>
            <Button styleType='common' className={styles.view} clickHandler={clickHandler}>
                <img src={ViewChoiceImg} alt='view' />
                <span>{t({ message: 'Вид', id: 'View' })}</span>
                <Arrow
                    className={styles.arrow}
                    size={'medium'}
                    color={'white'}
                    orientation={showDropDown ? 'up' : 'down'}
                />
                {showDropDown && drawViews()}
            </Button>
        </div>
    );
};
