import { t } from '@lingui/macro';
import { Trans } from '@lingui/react';
import { Button } from 'components/common/Button/Button';
import { Checkbox } from 'components/common/Checkbox/Checkbox';
import { Input } from 'components/common/Input/Input';
import { RadioButton } from 'components/common/RadioButton/RadioButton';
import { Spinner } from 'components/common/Spinner';
import { StatusLabel } from 'components/common/StatusLabel';
import React, { useEffect, useRef } from 'react';
import { Form, FormRenderProps } from 'react-final-form';
import { getRadioFormatProps } from 'utils/forms/getRadioFormatProps';
import { isFormHasValidationErrors } from 'utils/isFormHasValidationErrors';
import { ConferenceAudioVideoSettings } from './ConferenceAudioVideoSettings/ConferenceAudioVideoSettings';
import { ConferenceSettingsBlock } from './ConferenceSettingsBlock/ConferenceSettingsBlock';
import styles from './ConferenceStartedSettingsForm.module.scss';
import { IDispatchProps, IFormProps, IOwnProps } from './types';
import { getConferenceStartedSettingsFormInitialValues, handleSubmitClick, validateConferenceStartedSettingsForm } from './utils';

export const ConferenceStartedSettingsForm: React.FC<IDispatchProps & Partial<IOwnProps>> = ({
    conferenceInfo,
    updateConference,
    handleAfterSubmit,
    onModified,
    resetModified,
    showNotification,
    handleCancel,
}) => {
    const submitButtonText = t({ id: 'Apply' });
    const initialValues = getConferenceStartedSettingsFormInitialValues(conferenceInfo);
    const formRef = useRef(null);

    const onSubmitClick = async (values: IFormProps) => {
        if (values.operation === 'save') {
            delete values.operation;
            if (values.description === undefined) {
                values.description = '';
            }
            const errors = await handleSubmitClick(
                values,
                { showNotification, updateConference },
                conferenceInfo,
                handleAfterSubmit,
            );
            if (!errors) {
                resetModified();
            }
            return errors;
        }
        resetModified();
    };

    useEffect(() => {
        return () => {
            resetModified();
        };
    }, []);

    const render = ({
        handleSubmit,
        submitError,
        dirtySinceLastSubmit,
        dirty,
        errors,
        form,
        submitting,
        pristine,
    }: FormRenderProps<IFormProps>) => {
        setTimeout(() => onModified(dirty), 0);
        const handleCancelClick = () => {
            if (!pristine) {
                form.restart();
            } else {
                resetModified();
                if (handleCancel) {
                    handleCancel();
                    return;
                }
            }
        };
        return (
            <form onSubmit={handleSubmit} className={styles.form} ref={formRef}>
                <div className={styles.form__submitErrorWrapper}>
                    <StatusLabel text={submitError && !dirtySinceLastSubmit ? submitError : ''} />
                </div>
                <ConferenceSettingsBlock
                    text={t({ id: 'Theme', message: 'Тема' })}
                    classNameFields={styles.form__fieldWithInput}>
                    <Input
                        name='topic'
                        placeholder={t({
                            id: 'Enter the conference theme',
                            message: 'Введите тему конференции',
                        })}
                        className={styles.form__input}
                        autoFocus={true}
                        errorText={errors?.topic}
                    />
                </ConferenceSettingsBlock>
                <ConferenceSettingsBlock
                    text={t({ id: 'Description', message: 'Описание' })}
                    classNameFields={styles.form__fieldWithInput}>
                    <Input
                        name='description'
                        placeholder={t({
                            id: 'Enter the conference description',
                            message: 'Введите описание конференции',
                        })}
                        className={styles.form__input}
                        errorText={errors?.description}
                    />
                </ConferenceSettingsBlock>

                <ConferenceSettingsBlock text={t({ message: 'Безопасность', id: 'Security' })}>
                    <Checkbox
                        name='lobby'
                        text={t({ message: 'Зал ожидания', id: 'Waiting room' })}
                    />
                </ConferenceSettingsBlock>
                <ConferenceSettingsBlock>
                    <div className={styles.form__notes}>
                        <Trans
                            message='В конференцию могут входить только пользователи, утвержденные организатором'
                            id='Only users approved by the organizer can enter the conference'
                        />
                    </div>
                </ConferenceSettingsBlock>
                <ConferenceSettingsBlock>
                    <Checkbox
                        name='registeredOnly'
                        text={t({
                            message: 'Только для зарегистрированных пользователей',
                            id: 'Only for registered users',
                        })}
                    />
                </ConferenceSettingsBlock>
                <ConferenceSettingsBlock>
                    <div className={styles.form__notes}>
                        <Trans
                            message='В конференцию могут входить только зарегистрированные пользователи'
                            id='Only registered users can join the conference'
                        />
                    </div>
                </ConferenceSettingsBlock>

                <ConferenceSettingsBlock
                    text={t({
                        message: 'Видео',
                        id: 'Video',
                    })}>
                    <div className={styles.text}>
                        {t({
                            message: 'Организатор',
                            id: 'Organizer',
                        })}
                    </div>
                    <div className={styles.form__radioButton}>
                        <RadioButton
                            name='videoOwner'
                            value='true'
                            id='videoOwner1'
                            {...getRadioFormatProps()}
                            text={t({ message: 'Вкл.', id: 'On' })}
                        />
                    </div>
                    <div className={styles.form__radioButton}>
                        <RadioButton
                            name='videoOwner'
                            value='false'
                            id='videoOwner0'
                            {...getRadioFormatProps()}
                            text={t({ message: 'Выкл.', id: 'Off' })}
                        />
                    </div>
                </ConferenceSettingsBlock>
                <ConferenceSettingsBlock>
                    <ConferenceAudioVideoSettings
                        name='participantsVideoPermission'
                    />
                </ConferenceSettingsBlock>

                <ConferenceSettingsBlock
                    text={t({
                        message: 'Микрофон',
                        id: 'Audio',
                    })}>
                    <ConferenceAudioVideoSettings
                        name='participantsAudioPermission'
                    />
                </ConferenceSettingsBlock>

                <ConferenceSettingsBlock className={styles.noMarginBottom}>
                    <div className={styles.form__footerButtons}>
                        <Button
                            styleType={submitting ? 'loading' : 'primaryAction'}
                            type='submit'
                            disabled={
                                submitting ||
                                isFormHasValidationErrors(errors) ||
                                (pristine && !!conferenceInfo)
                            }
                            clickHandler={() => form.change('operation', 'save')}>
                            {submitting ? <Spinner /> : submitButtonText}
                        </Button>
                        <Button
                            styleType='secondaryAction'
                            caption={t({
                                message: 'Отмена',
                                id: 'Cancel',
                            })}
                            clickHandler={handleCancelClick}
                        />
                    </div>
                </ConferenceSettingsBlock>
            </form>
        );
    };

    return (
        <Form
            onSubmit={onSubmitClick}
            validate={validateConferenceStartedSettingsForm}
            render={render}
            initialValues={initialValues}
        />
    );
};
