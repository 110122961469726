import { t } from '@lingui/macro';
import { Button } from 'components/common/Button';
import OutsideClick from 'components/common/OutsideClick';
import { useEffect, useRef, useState } from 'react';
import { setEscCloseDropdown } from 'utils/escCloseDropdown';
import { ConferenceLinkDialogConnected } from '../ConferenceLinkDialog/ConferenceLinkDialogConnected';
import styles from './ConferenceInviteButton.module.scss';
import { IOwnProps } from './types';

export const ConferenceInviteButton: React.FC<IOwnProps> = ({ isShowSmallButton = false }) => {
    const [inviteDialogOpen, setInviteDialogOpen] = useState(false);
    const containerRef = useRef<HTMLDivElement>(null);
    const closeDropDown = (e: MouseEvent) => {
        if (e.target && !containerRef.current?.contains(e.target as HTMLElement)) {
            setInviteDialogOpen(false);
        }
    };

    useEffect(() => {
        setEscCloseDropdown(inviteDialogOpen);
    }, [inviteDialogOpen]);

    const smallInviteButtonStyles = isShowSmallButton ? styles.inviteBtn_small : '';
    return (
        <div className={`${styles.inviteBtn} ${smallInviteButtonStyles}`} ref={containerRef}>
            <Button
                styleType='primaryAction'
                className={styles.button}
                caption={t({ message: 'Пригласить', id: 'Invite' })}
                clickHandler={() => setInviteDialogOpen(!inviteDialogOpen)}
            />
            {inviteDialogOpen && (
                <OutsideClick onOutsideClick={closeDropDown} className={styles.outsideClick}>
                    <ConferenceLinkDialogConnected
                        className={styles.link}
                        closeDialog={() => setInviteDialogOpen(false)}
                    />
                </OutsideClick>
            )}
        </div>
    );
};
