import Axios, { AxiosBasicCredentials, AxiosInstance, AxiosRequestConfig } from 'axios';
import { Capacitor } from '@capacitor/core';
import { INetworkService, IRequestStatus } from 'types/api/INetworkService';
import { getApplicationState } from 'utils/applicationStoreProvider';
import { log } from 'utils/log';
import { isAnonymousAccess } from 'utils/network/isAnonymousAccess';
import { redirect } from 'utils/redirect';
import { getExtendedPlatform } from 'utils/getExtendedPlatform';
import { SERVER_URL } from 'constants/serverUrl';
import { TCapacitorPlatform } from 'types/TPlatform';
interface IAxiosClient {
    networkService: INetworkService;
    axiosInstance: AxiosInstance;
    auth?: AxiosBasicCredentials;
    getAxiosRequestConfig: (auth?: AxiosBasicCredentials) => AxiosRequestConfig;
}

let networkServiceInstance: IAxiosClient;

export const getBaseUrl = (platform: TCapacitorPlatform) => {
    const currentPlatform = getExtendedPlatform(platform);

    if (currentPlatform === 'mobile-production') return SERVER_URL;
    return '';
};

const createAxiosInstance = (): AxiosInstance => {
    const config: AxiosRequestConfig = {
        baseURL: getBaseUrl(Capacitor.getPlatform() as TCapacitorPlatform),
    };

    if (process.env.NODE_ENV === 'test') {
        config.adapter = require('axios/lib/adapters/http');
    }
    return Axios.create(config);
};

const errorHandler = (e: any) => {
    const { response } = e;
    const additionalStatus: Pick<IRequestStatus, 'success' | 'errorCode' | 'errorDescription'> = {
        success: false,
    };
    const requestUrl = e?.config?.url;
    const excludedPaths = ['loginForJoin'];
    if (response?.status === 401) {
        if (requestUrl === '/api/account/me') {
            const {
                auth: { rememberMeRequestStatus },
            } = getApplicationState();
            if (rememberMeRequestStatus == 'error') {
                // Nothing changed, ignore it
                return e.response;
            }
        }
        const windowURL = window.location.pathname;
        const thisIsAnonymousAccess = isAnonymousAccess(windowURL);
        if (!thisIsAnonymousAccess) {
            const isExcludedPath = excludedPaths.find((path) => windowURL.match(new RegExp(path)));
            if (!isExcludedPath) {
                redirect(`/login?path=${window.location.pathname}`);
            }
        }
    } else {
        log.error(response.status, response.statusText);
        const { data } = response;
        if (data) {
            const { errorCode, errorDescription } = data;
            if (errorCode) {
                additionalStatus.errorCode = errorCode;
            }
            if (errorDescription) {
                additionalStatus.errorDescription = errorDescription;
            }
        }
    }
    return { ...e.response, ...additionalStatus };
};

export const getNetworkService = (): INetworkService => {
    if (!networkServiceInstance) {
        networkServiceInstance = {
            auth: undefined,
            axiosInstance: createAxiosInstance(),
            getAxiosRequestConfig: (authToTry?: AxiosBasicCredentials): AxiosRequestConfig =>
                ((auth) =>
                    auth ? { auth, headers: { 'X-Requested-With': 'XMLHttpRequest' } } : {})(
                    authToTry || networkServiceInstance.auth,
                ),
            networkService: {
                delete: ({ url, ...config }) =>
                    ((config) =>
                        networkServiceInstance.axiosInstance
                            .delete(url, config)
                            .then((response) => ({ ...response, success: true }))
                            .catch(errorHandler))({
                        ...networkServiceInstance.getAxiosRequestConfig(),
                        ...config,
                    }),
                get: ({ url, onProgress, auth, ...config }) =>
                    ((config) =>
                        networkServiceInstance.axiosInstance
                            .get(url, config)
                            .then((response) => ({ ...response, success: true }))
                            .catch(errorHandler))({
                        ...networkServiceInstance.getAxiosRequestConfig(auth),
                        onDownloadProgress: onProgress,
                        ...config,
                    }),
                post: ({ url, data, onProgress, auth, ...config }) =>
                    ((config) =>
                        networkServiceInstance.axiosInstance
                            .post(url, data, config)
                            .then((response) => ({ ...response, success: true }))
                            .catch(errorHandler))({
                        ...networkServiceInstance.getAxiosRequestConfig(auth),
                        onUploadProgress: onProgress,
                        ...config,
                    }),
                put: ({ url, data, ...config }) =>
                    ((config) =>
                        networkServiceInstance.axiosInstance
                            .put(url, data, config)
                            .then((response) => ({ ...response, success: true }))
                            .catch(errorHandler))({
                        ...networkServiceInstance.getAxiosRequestConfig(),
                        ...config,
                    }),
            },
        };
    }
    return networkServiceInstance.networkService;
};
