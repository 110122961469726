import { MobileWrapperConnected } from 'components/common/MobileWrapper';
import { InputUsernamePageConnected } from './InputUsernamePageConnected';
import { InputUsernamePageMobileConnected } from './InputUsernamePageMobileConnected';

export const InputUsernamePageResponsive = ({id}:{id:string}) => (
    <MobileWrapperConnected
        renderDesktop={() => <InputUsernamePageConnected id={id} />}
        renderMobile={() => <InputUsernamePageMobileConnected id={id} />}
    />
);
