import { IAudioVideoProps } from 'types/IAudioVideoProps';
import { IConferenceConnectInfo } from 'types/conferences/IConferenceConnectInfo';

export const calculateAudioVideoProps = (
    conferenceConnectInfo: IConferenceConnectInfo,
    currentUserIsOwner: boolean,
    audioVideoProps: IAudioVideoProps,
): IAudioVideoProps => {
    const conferenceOwner = currentUserIsOwner;

    if (conferenceConnectInfo.muteUponEntry) {
        audioVideoProps.useAudio = false;
    }
    if (
        (!conferenceConnectInfo?.videoCreator && conferenceOwner) ||
        (!conferenceConnectInfo?.videoParticipants && !conferenceOwner)
    ) {
        audioVideoProps.useVideo = false;
        audioVideoProps.videoPermitted = false;
    }

    return audioVideoProps;
};
