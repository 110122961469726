import { t } from '@lingui/macro';
import { Trans } from '@lingui/react';
import { Spinner } from 'components/common/Spinner';
import { intervalToDuration } from 'date-fns';
import { useEffect, useRef, useState } from 'react';
import { getDateInfoFromStartedConference } from 'utils/conferences/getDateInfoFromStartedConference';
import { getBrowserTimeZone } from 'utils/timeZones/getBrowserTimeZone';
import { getUserTimeZone } from 'utils/timeZones/getUserTimeZone';
import { IOwnProps, ITimer } from './types';

export const ConferenceTimer: React.FC<IOwnProps> = ({ conferenceTimeStart, isMobile }) => {
    const [remainingTimeToStartConference, setRemainingTimeToStartConference] = useState<ITimer>({
        hours: 0,
        minutes: 0,
        seconds: 0,
    });
    const timerToUpdatePageBeforeStart = useRef<NodeJS.Timeout | null>(null);

    const browserTimeZone = getBrowserTimeZone();
    const userTimeZone = getUserTimeZone();
    const { compareResult } = getDateInfoFromStartedConference(
        conferenceTimeStart ?? '',
        browserTimeZone,
        userTimeZone,
    );
    const conferenceShouldAlreadyStart = compareResult >= 0;

    const updateTimerBeforeConferenceStart = async () => {
        if (conferenceTimeStart) {
            const { startDate, currentDate } = getDateInfoFromStartedConference(
                conferenceTimeStart,
                browserTimeZone,
                userTimeZone,
            );
            const { hours, minutes, seconds } = intervalToDuration({
                start: currentDate,
                end: startDate,
            });
            setRemainingTimeToStartConference((prevVal) => {
                return { ...prevVal, ...{ hours, minutes, seconds } };
            });
        }
    };

    const startTimerBeforeConferenceStart = async () => {
        if (conferenceTimeStart) {
            const { compareResult } = getDateInfoFromStartedConference(
                conferenceTimeStart,
                browserTimeZone,
                userTimeZone,
            );
            if (compareResult >= 0) {
                return;
            }
            if (!timerToUpdatePageBeforeStart.current && compareResult < 0) {
                timerToUpdatePageBeforeStart.current = setInterval(() => {
                    updateTimerBeforeConferenceStart();
                }, 1000);
            }
        }
    };

    const resetTimer = () => {
        if (timerToUpdatePageBeforeStart.current && conferenceTimeStart) {
            clearInterval(timerToUpdatePageBeforeStart.current)
            timerToUpdatePageBeforeStart.current = null;
            startTimerBeforeConferenceStart()
        }
    }

    useEffect(() => {
        resetTimer()
    }, [conferenceTimeStart])
    

    useEffect(() => {
        startTimerBeforeConferenceStart();
        return () => {
            timerToUpdatePageBeforeStart.current
                ? clearInterval(timerToUpdatePageBeforeStart.current)
                : false;
        };
    }, []);

    const getTimerString = (
        hours: number | undefined,
        minutes: number | undefined,
        seconds: number | undefined,
    ) => {
        const formattedDate = [hours, minutes, seconds].map((timePart) =>
            timePart ? timePart : '0',
        );
        return t({
            id: 'conference.join.error.notStarted.timer',
            message: '{hours} ч {minutes} мин {seconds} сек',
            values: {
                hours: formattedDate[0],
                minutes: formattedDate[1],
                seconds: formattedDate[2],
            },
        });
    };

    const getTimerBeforeStartOfConference = () => {
        const { hours, minutes, seconds } = remainingTimeToStartConference;
        const showSpinnerCondition = !hours && !minutes && !seconds;
        if (showSpinnerCondition) {
            return <Spinner />;
        }
        return getTimerString(hours, minutes, seconds);
    };

    if (conferenceShouldAlreadyStart || !conferenceTimeStart) {
        return null;
    }
    const text = !isMobile ? (
        <Trans id='conference.join.error.notStarted.id' message='Время до начала конференции' />
    ) : (
        <Trans id='conference.join.error.notStartedMobile.id' message='Начало через' />
    );
    return (
        <>
            <span>{text}</span>
            <span> {getTimerBeforeStartOfConference()}</span>
        </>
    );
};
