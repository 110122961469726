import { IJanusContext } from 'types/janus/IJanusContext';
import { getApplicationState } from 'utils/applicationStoreProvider';
import { janusLogger } from 'utils/logger';

const getJanusCodecs = () => {
    const {
        auth: { appSettings },
    } = getApplicationState();
    return {
        vcodec: appSettings?.videoCodec || '',
        acodec: appSettings?.audioCodec || '',
    };
};

export const getJanusCtxInitial = (): IJanusContext => ({
    bitrateTimer: [],
    browser: '',
    chatIsReady: false,
    creatingSubscriptionsHandler: false,
    currentUserPrivateId: null,
    dispatch: () => {},
    multiStreamFeedStreams: {},
    // feeds: [],
    getState: () => ({}),
    janusInstance: null,
    myid: '',
    mypvtid: '',
    opaqueId: 'emeet-' + Janus.randomString(12),
    published: false,
    multiStreamRemoteTracks: {},
    roomId: '',
    safariVp8: false,
    server: '',
    singleStreamPublishersStreams: {},
    singleStreamSubscribeHandlers: [],
    singleStreamSubscriptions: {},
    multiStreamSubStreams: {},
    subscribeType: 'multi',
    multiStreamSubscriptions: {},
    terminated: false,
    // textRoomPlugin: null,
    textRoomPluginHandler: null,
    textRoomTransactions: {},
    token: '',
    transceivers: {},
    videoRoomPublisherPluginHandler: null,
    videoRoomSubscriberPluginHandler: null,
    ...getJanusCodecs(),
});

export let janusCtx: IJanusContext;

export const initializeJanusCtx = (props: Partial<IJanusContext>) =>
    (janusCtx = { ...getJanusCtxInitial(), ...props });

export const clearJanusCtx = () => {
    if (janusCtx) {
        janusCtx = getJanusCtxInitial();
    }
    janusLogger.debug('Janus context clear');
};

(window as any).janusCtx = () => janusCtx;

(window as any).peerConnections = () => {
    const result: any = { publisherStreams: [], participantsStreams: [] };
    if (janusCtx.subscribeType === 'single') {
        for (const feed of janusCtx.singleStreamSubscribeHandlers) {
            if (feed) {
                result.participantsStreams.push(feed.webrtcStuff.pc.getTransceivers());
            }
        }
        result.publisherStreams =
            janusCtx.videoRoomPublisherPluginHandler.webrtcStuff.pc.getTransceivers();
    }
    return result;
};
