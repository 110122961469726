import { createAsyncThunk } from '@reduxjs/toolkit';
import { addRecordReadyNotificationApiCall } from 'api/records/addRecordReadyNotificationApiCall';
import { getNetworkService } from 'api/getNetworkService';
import { setConferenceRecordNotifyStateAction } from 'actions/records/setConferenceRecordNotifyStateAction';

export const addRecordReadyNotificationThunkAction = createAsyncThunk(
    'deleteRecordReadyNotification',
    async (conferenceId: string, { dispatch }) => {
        const request = await addRecordReadyNotificationApiCall(getNetworkService(), conferenceId);
        if (request.status === 200) {
            dispatch(
                setConferenceRecordNotifyStateAction({ recordId: conferenceId, notifyState: true }),
            );
        }
    },
);
