import { createAsyncThunk } from '@reduxjs/toolkit';
import { IPayload } from 'components/forms/PinEnterForm/types';
import { EPaths } from 'constants/EPaths';
import { TAppState } from 'types/app/TAppStore';
import { redirect } from 'utils/redirect';
import { checkPinConferenceThunkAction } from './checkPinConferenceThunkAction';

export const submitPinEnterFormThunkActon = createAsyncThunk(
    'submitPinEnterForm',
    async ({ values, successSubmitCallback }: IPayload, { dispatch, getState }) => {
        const {
            conferenceSession: { currentConferenceId },
        } = getState() as TAppState;
        const { isSuccess } = await dispatch(
            checkPinConferenceThunkAction({ id: currentConferenceId, pin: values.pin }),
        ).unwrap();
        if (!isSuccess) {
            return { pin: 'error' };
        }
        if (successSubmitCallback) {
            successSubmitCallback();
        } else {
            redirect(`${EPaths.CONNECT_TO_CONFERENCE}/${currentConferenceId}`);
        }
    },
);
