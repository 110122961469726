import { MobileWrapperConnected } from 'components/common/MobileWrapper';
import { ConnectToConferencePageConnected } from './ConnectToConferencePageConnected';
import { ConnectToConferencePageMobileConnected } from './ConnectToConferencePageMobileConnected';
import { IOwnProps } from './types';

export const ConnectToConferencePageResponsive: React.FC<IOwnProps> = ({ id }) => (
    <MobileWrapperConnected
        renderDesktop={() => <ConnectToConferencePageConnected id={id} />}
        renderMobile={() => <ConnectToConferencePageMobileConnected id={id} />}
    />
);
