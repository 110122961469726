import { useEffect, useRef, useState } from 'react';
import { HoverTooltip } from '../HoverTooltip';
import { Portal } from '../Portal';
import { IOwnProps } from './types';
import styles from './DynamicHoverTooltip.module.scss';

export const DynamicHoverTooltip: React.FC<IOwnProps> = ({name, description}) => {
    const [position, setPosition] = useState<null | { x: number; y: number }>(null);
    const tooltipContainerRef = useRef<HTMLDivElement>(null);
    const portalContainerRef = useRef<HTMLElement | null>(null);

    const getContainerPosition = () => {
        const tooltipContainer = tooltipContainerRef.current;
        if (position || !tooltipContainer) {
            return;
        }
        const avatar = tooltipContainer.nextSibling;
        if (parent) {
            const data = (avatar as HTMLElement).getBoundingClientRect();
            setPosition({
                x: Math.round(data.x + data.width / 2),
                y: Math.round(data.y),
            });
        }
    };

    const getPortalContainer = () => {
        const targetContainer = document.body.querySelector('#tooltips');
        if (targetContainer) {
            portalContainerRef.current = targetContainer as HTMLElement;
        }
    };

    useEffect(() => {
        getPortalContainer();
    }, []);

    useEffect(() => {
        getContainerPosition();
    }, [tooltipContainerRef, portalContainerRef]);

    return (
        <div ref={tooltipContainerRef}>
            <Portal container={portalContainerRef.current}>
                <div
                    style={{ left: `${position?.x}px`, top: `${position?.y}px` }}
                    className={styles.dynamicTooltip}>
                    <HoverTooltip name={name} description={description} />
                </div>
            </Portal>
        </div>
    );
};
