import { addTAndZToISOFormat } from 'utils/dates/addTAndZToISOFormat';
import { convertDateToStringByTemplate } from 'utils/dates/convertDateToStringByTemplate';
import { convertStringToDateByTemplate } from 'utils/dates/convertStringToDateByTemplate';
import { getDateBasedOnUTC0 } from 'utils/dates/getDateBasedOnUTC0';
import { getUserTimeZone } from './getUserTimeZone';

export const getStartEndTimeUtc0 = (from: string | undefined, to: string | undefined) => {
    let fromUtc0;
    if (from) {
        fromUtc0 = addTAndZToISOFormat(
            convertDateToStringByTemplate(
                getDateBasedOnUTC0(
                    convertStringToDateByTemplate(`${from} 00:00:00`, 'dd.MM.yyyy HH:mm:ss', false),
                    getUserTimeZone(),
                ),
                'yyyy-MM-dd HH:mm:ss',
            ),
        );
    }
    let toUtc0;
    if (to) {
        toUtc0 = addTAndZToISOFormat(
            convertDateToStringByTemplate(
                getDateBasedOnUTC0(
                    convertStringToDateByTemplate(`${to} 23:59:59`, 'dd.MM.yyyy HH:mm:ss', false),
                    getUserTimeZone(),
                ),
                'yyyy-MM-dd HH:mm:ss',
            ),
        );
    }
    return [fromUtc0, toUtc0];
};
