import { connect } from 'react-redux';
import { changeConferenceRecordStateThunkAction } from 'thunk/conference/changeConferenceRecordStateThunkAction';
import { TAppDispatch } from 'types/app/TAppDispatch';
import { TAppState } from 'types/app/TAppStore';
import { ConferenceRecordBtn } from './ConferenceRecordBtn';
import { IDispatchProps, IStateProps } from './types';

const mapStateToProps = (state: TAppState): IStateProps => ({
    recording: state.conferenceSession.currentConferenceRecording,
    participantIsOwner: state.conferenceSession.currentUserIsOwner,
});

const mapDispatchToProps = (dispatch: TAppDispatch): IDispatchProps => ({
    toggleRecordState: () => dispatch(changeConferenceRecordStateThunkAction()),
});

export const ConferenceRecordBtnConnected = connect(
    mapStateToProps,
    mapDispatchToProps,
)(ConferenceRecordBtn);
