import { createReducer } from '@reduxjs/toolkit';
import { addConferenceChatMessagesAction } from 'actions/chat/addConferenceChatMessagesAction';
import { resetConferenceChatStoreAction } from 'actions/chat/resetConferenceChatStoreAction';
import { setConferenceChatAutoScroll } from 'actions/chat/setConferenceChatAutoScroll';
import { setConferenceChatMessageStatus } from 'actions/chat/setConferenceChatMessageStatus';
import { resetStoreAction } from 'actions/resetStoreAction';
import { IConferenceChatMessage } from 'types/chat/IConferenceChatMessage';
export interface IConferenceChatReducer {
    chatMessages: Array<IConferenceChatMessage>;
    chatAutoScroll: boolean;
    isMessagesFromHistory: boolean;
}

const conferenceChatReducerInitialState: IConferenceChatReducer = {
    chatMessages: [],
    chatAutoScroll: false,
    isMessagesFromHistory: false,
};

export const conferenceChatReducer = createReducer(conferenceChatReducerInitialState, (builder) =>
    builder
        .addCase(resetConferenceChatStoreAction, () => {
            return conferenceChatReducerInitialState;
        })
        .addCase(
            addConferenceChatMessagesAction,
            (state, { payload: { messages, history } }) => {
                state.chatMessages = [...state.chatMessages, ...messages];
                if (history) {
                    state.isMessagesFromHistory = true;
                }
                if (!history && state.isMessagesFromHistory) {
                    state.isMessagesFromHistory = false;
                }
            },
        )
        .addCase(setConferenceChatMessageStatus, (state, { payload: messageData }) => {
            state.chatMessages = state.chatMessages.map((message) => {
                return message.id === messageData.messageId
                    ? { ...message, ...{ status: messageData.messageStatus } }
                    : message;
            });
        })
        .addCase(setConferenceChatAutoScroll, (state, { payload: autoScrollState }) => {
            state.chatAutoScroll = autoScrollState;
        })
        .addCase(resetStoreAction, () => conferenceChatReducerInitialState),
);
