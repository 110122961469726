import { t } from '@lingui/macro';
import { Button } from 'components/common/Button';
import { useEffect, useState } from 'react';
import styles from './ConferenceFullScreenButton.module.scss';
import { IDataWithWebkit, IOwnProps } from './types';
import FullScreenImg from 'assets/FullBrowserScreen.svg';
import DefaultScreenImg from 'assets/DefaultBrowserScreen.svg';

export const ConferenceFullScreenButton: React.FC<IOwnProps> = () => {
    const [isDefaultScreen, setIsDefaultScreen] = useState(true);

    const getInitialScreenSize = () => {
        if (
            document.fullscreenElement ||
            (document as IDataWithWebkit<typeof document>)?.webkitFullscreenElement
        ) {
            setIsDefaultScreen(false);
        } else {
            setIsDefaultScreen(true);
        }
    };

    const handleClick = () => {
        if (isDefaultScreen) {
            const targetElement = document.querySelector('#conferencePage');
            if (!targetElement) {
                return;
            }
            if (targetElement.requestFullscreen) {
                targetElement.requestFullscreen();
            } else if (
                (targetElement as IDataWithWebkit<typeof targetElement>)?.webkitRequestFullscreen
            ) {
                /* Safari */
                (targetElement as IDataWithWebkit<typeof targetElement>).webkitRequestFullscreen();
            }
            setIsDefaultScreen(false);
            return;
        }

        if (document.exitFullscreen) {
            document.exitFullscreen();
        } else if ((document as IDataWithWebkit<typeof document>)?.webkitExitFullscreen) {
            /* Safari */
            (document as IDataWithWebkit<typeof document>).webkitExitFullscreen();
        }
        setIsDefaultScreen(true);
    };

    useEffect(() => {
        getInitialScreenSize();
    }, []);

    return (
        <div>
            <Button
                styleType='common'
                className={styles.conferenceFullScreenBtn}
                caption={t({ message: 'На весь экран', id: 'Full screen' })}
                clickHandler={handleClick}>
                <img src={isDefaultScreen ? FullScreenImg : DefaultScreenImg} />
            </Button>
        </div>
    );
};
