import { connect } from 'react-redux';
import { EditWebHookDialog } from './EditWebHookDialog';
import { TAppDispatch } from 'types/app/TAppDispatch';
import { IDispatchProps } from './types';
import { createWebHookThunkAction } from 'admin-app/thunk/webHooks/createWebHookThunkAction';
import { editWebHookThunkAction } from 'admin-app/thunk/webHooks/editWebHookThunkAction';

const mapDispatchToProps = (dispatch: TAppDispatch): IDispatchProps => ({
    editWebHook: (data) => dispatch(editWebHookThunkAction(data)).unwrap(),
});

export const EditWebHookDialogConnected = connect(null, mapDispatchToProps)(EditWebHookDialog);
