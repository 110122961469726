import { createAsyncThunk } from '@reduxjs/toolkit';
import { deleteConferenceRecordAction } from 'actions/records/deleteConferenceRecordAction';
import { getNetworkService } from 'api/getNetworkService';
import { deleteConferenceRecordApiCall } from 'api/records/deleteConferenceRecordApiCall';
import { showNotifyThunkAction } from 'thunk/notify/showNotifyThunkAction';
import { getDefaultErrorNotification } from 'utils/getDefaultErrorNotification';

interface IPayload {
    conferenceId: string;
    sessionId: string;
    recordId: string;
}

export const deleteConferenceRecordThunkAction = createAsyncThunk(
    'deleteConferenceRecord',
    async (data: IPayload, { dispatch }) => {
        const response = await deleteConferenceRecordApiCall(
            getNetworkService(),
            data,
        );
        if (response.success) {
            dispatch(deleteConferenceRecordAction(data.recordId));
        } else {
            dispatch(showNotifyThunkAction(getDefaultErrorNotification()));
        }
    },
);
