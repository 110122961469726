import { setLocalStreamForJanusAction } from 'actions/janus/setLocalStreamForJanusAction';
import { sendOwnAudioVideoPropsToServerThunkAction } from 'thunk/conference/sendOwnAudioVideoPropsToServerThunkAction';
import { TAppState } from 'types/app/TAppStore';
import { janusCtx } from './janusCtx';

export const handleOwnStreams = (streams: any[]) => {
    const {
        janus: {
            ownStream: { audio: ownAudio, video: ownVideo },
            localStreamProps,
        },
    } = janusCtx.getState() as TAppState;
    for (const streamProps of streams) {
        streamProps['id'] = janusCtx.myid;
        streamProps['display'] = janusCtx.currentUserPrivateId; // FIXME need check currentUserPrivateId
        const { type, mid } = streamProps;
        // It can also be 'data' type
        if (type === 'audio' || type === 'video') {
            const alreadySavedStream = type === 'audio' ? ownAudio : ownVideo;
            const streamToSave = alreadySavedStream ? alreadySavedStream.stream : new MediaStream();
            janusCtx.dispatch(
                setLocalStreamForJanusAction({
                    stream: streamToSave,
                    type: type,
                    mid,
                }),
            );
            let useDevice = false;
            if (type === 'audio') {
                useDevice = localStreamProps.useAudio;
                janusCtx.transceivers.audio_mid = mid;
            } else if (type === 'video') {
                useDevice = localStreamProps.useVideo || localStreamProps.screenShared;
                janusCtx.transceivers.video_mid = mid;
            }
            if (streamToSave.active && useDevice) {
                janusCtx.dispatch(
                    sendOwnAudioVideoPropsToServerThunkAction({
                        [mid]: { active: true, type: type.toUpperCase() as 'AUDIO' | 'VIDEO' },
                    }),
                );
            }
        }
    }
};
