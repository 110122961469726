import { createAsyncThunk } from '@reduxjs/toolkit';
import { storeConferenceInfoAction } from 'actions/conferences/storeConferenceInfoAction';
import { scheduleConferenceApiCall } from 'api/conference/scheduleConferenceApiCall';
import { getNetworkService } from 'api/getNetworkService';
import { IThunkResult } from 'types/IThunkResult';
import { IConferenceInfo } from 'types/conferences/IConferenceInfo';

interface IPayload {
    name: string;
    timeZone: string;
    pin: string;
    lobby: boolean;
    timeStart: string;
    description?: string;
    recording?: boolean;
    videoCreator?: boolean;
    videoParticipants?: boolean;
    muteUponEntry?: boolean;
    duration: string;
    invitedUsers?: string[];
    cron?: string;
    startDateOfRecurrence?: string;
    endDateOfRecurrence?: string;
}

type IResponse = IThunkResult<IConferenceInfo>

export const scheduleConferenceThunkAction = createAsyncThunk<IResponse, IPayload>(
    'scheduleConference',
    async (conferenceData, { dispatch }) => {
        // Swap recording & recurring to isRecording & isRecurring
        const convertedData = {
            ...conferenceData,
            isRecording: conferenceData.recording,
        };
        delete convertedData.recording;
        const response = await scheduleConferenceApiCall(getNetworkService(), convertedData);
        const data: IResponse = {
            isSuccess: response.success,
            errorCode: response.data?.errorCode,
            errorDescription: response.data?.errorDescription,
        };
        if (response.success) {
            const newConference = response.data;
            data.data = newConference;
            dispatch(storeConferenceInfoAction([newConference]));
        }
        return data;
    },
);
