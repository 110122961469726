import { janusCtx } from 'utils/janus/janusCtx';
import { createAsyncThunk } from '@reduxjs/toolkit';
import { moderateParticipantApiCall } from 'api/conference/moderate/moderateParticipantApiCall';
import { setAudioVideoPermissionApiCall } from 'api/conference/moderate/setAudioVideoPermissionApiCall';
import { getNetworkService } from 'api/getNetworkService';
import { setFramesToFullscreenThunkAction } from 'thunk/appView/setFramesToFullscreenThunkAction';
import { showNotifyThunkAction } from 'thunk/notify/showNotifyThunkAction';
import { TAppState } from 'types/app/TAppStore';
import { getDefaultErrorNotification } from 'utils/getDefaultErrorNotification';
import { log } from 'utils/log';

interface IPayload {
    participantId: string;
    type: 'audio' | 'video';
}

export const changeAudioVideoPermissionThunkAction = createAsyncThunk(
    'changeAudioVideoPermission',
    async ({ participantId, type }: IPayload, { dispatch, getState }) => {
        const {
            janus: { publishers },
            conferenceSession: { currentConferenceId },
            applicationView: { participantsFullScreenGlobal },
        } = getState() as TAppState;
        const publisher = publishers[participantId];
        const { audioPermitted, videoPermitted } = publisher.audioVideoProps;
        // Set mute status.
        // For example,  { video: true, audio: false } - mean video muted, audio - not
        const publisherStreams = janusCtx.multiStreamFeedStreams[participantId];
        const stream = publisherStreams?.streams.find((stream) => stream.type === type);
        let mute;
        if (type === 'audio') {
            mute = audioPermitted;
        } else {
            mute = videoPermitted;
        }
        if (type === 'video' && mute) {
            if (participantsFullScreenGlobal.some((id) => id === participantId)) {
                await dispatch(
                    setFramesToFullscreenThunkAction({ global: true, participantIds: [] }),
                );
            }
        }
        if (!stream) {
            await moderateParticipantApiCall(getNetworkService(), {
                conferenceId: currentConferenceId,
                participantId,
                mute,
                streamType: type === 'audio' ? 'AUDIO' : 'VIDEO',
            });
            // dispatch(showNotifyThunkAction(getDefaultErrorNotification()));
            return;
        }

        const response = await setAudioVideoPermissionApiCall(getNetworkService(), {
            conferenceId: currentConferenceId,
            participantId,
            permission: {
                mute,
                streamId: stream.mid,
                streamType: type === 'audio' ? 'AUDIO' : 'VIDEO',
            },
        });
        if (response.success) {
            // This status will be dispatched in applyAudioVideoPermissionFromModerator from janus event 'moderation'
        } else {
            log.error('Failed to send audio/video props');
            dispatch(showNotifyThunkAction(getDefaultErrorNotification()));
        }
    },
);
