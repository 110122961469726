import { createAsyncThunk } from '@reduxjs/toolkit';
import { setCurrentLanguageAction } from 'actions/applicationView/setCurrentLanguageAction';
import { TLanguages } from 'types/TLanguages';

export const setCurrentLanguageThunkAction = createAsyncThunk(
    'setCurrentLanguage',
    async (language: TLanguages, { dispatch  }) => {
        dispatch(setCurrentLanguageAction(language));
    },
);
