import { t } from '@lingui/macro';
import { convertFromSecondsToHoursMinutesSeconds } from 'utils/convertFromSecondsToHoursMinutesSeconds';

export const convertRecordDuration = (duration: number | undefined): string => {
    const secLingui = t({ id: 'sec', message: 'сек' });
    const minLingui = t({ id: 'min', message: 'мин' });
    const hLingui = t({ id: 'h', message: 'ч' });
    if (!duration) return '0 ' + secLingui;
    const { hours, minutes, seconds } = convertFromSecondsToHoursMinutesSeconds(duration);
    let hoursStr = '',
        minutesStr = '',
        secondsStr = '';
    if (hours) {
        hoursStr = hours.toString() + ' ' + hLingui;
    }
    if (minutes) {
        minutesStr = ' ' + minutes.toString() + ' ' + minLingui;
    }
    if (seconds) {
        secondsStr = ' ' + seconds.toString() + ' ' + secLingui;
    }
    return `${hoursStr}${minutesStr}${secondsStr}`.trim();
};
