import { INetworkService } from 'types/api/INetworkService';

type TOrderType = 'desc' | 'asc';

interface IPayload {
    conferenceId: string;
    sessionId: string;
    sort?: {
        sentTime: string;
        orderType?: TOrderType;
    };
    page?: number;
    pageSize?: number;
}

export const getChatMessagesApiCall = (
    networkService: INetworkService,
    { conferenceId, sort, page, pageSize, sessionId }: IPayload,
) => {
    const requestPath = `/api/conferences/${conferenceId}/sessions/${sessionId}/messages`;
    const requestUrl = new window.URL(requestPath, window.location.origin);
    sort && requestUrl.searchParams.append('sort', `${sort.sentTime},${sort.orderType}`);
    page && requestUrl.searchParams.append('page', `${page}`);
    pageSize && requestUrl.searchParams.append('pageSize', `${pageSize}`);

    return networkService.get({
        url: requestUrl.toString(),
    });
};
