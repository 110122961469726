import { EUse3dAudioVariant } from 'constants/EUse3dAudioVariant';
import { IAppSettings } from 'types/app/IAppSettings';
import { IDynamicSectionStructure } from 'types/dynamicForm/IDynamicSectionStructure';

export const unitSettingsWithDefault = (
    appSettings: IAppSettings,
    structure: IDynamicSectionStructure[],
    fields: string[] | undefined = undefined,
) => {
    const values: IAppSettings = {};
    for (const section of structure) {
        for (const field of section.fields) {
            if (!fields || fields.indexOf(field.name) !== -1) {
                values[field.name] = appSettings
                    ? appSettings[field.name] !== undefined
                        ? appSettings[field.name]
                        : field.defaultValue
                    : field.defaultValue;
            }
        }
    }
    return values;
};

export const use3dAudioVariant = EUse3dAudioVariant.V2;

export const getDefaultSettings = (structure: IDynamicSectionStructure[]) => {
    const values: IAppSettings = { use3dAudioVariant };
    for (const section of structure) {
        for (const field of section.fields) {
            values[field.name] = field.defaultValue;
        }
    }
    return values;
};
