import { IOwnProps } from './types';
import styles from '../Select.module.scss';
import { classNames } from 'utils/classNames';
import { ReactComponent as ArrowIcon } from 'assets/CalendarArrow.svg';

export const SelectActiveValue: React.FC<IOwnProps> = ({
    currentValue,
    options,
    className,
    isOpen,
    onClick,
}) => {
    const getCurrentFormattedValue = () => {
        const target = options.find((option) => option.value === currentValue);
        if (!target) {
            return '';
        }
        return (
            <>
                {target.img && <img src={target.img} />}
                <span className={target.className}>{target.displayValue}</span>
            </>
        );
    };
    return (
        <div
            className={classNames([styles.select__value, className])}
            onClick={onClick}
            data-select-value>
            {getCurrentFormattedValue()}
            <ArrowIcon
                className={classNames([
                    styles.select__valueArrow,
                    isOpen ? styles.select__valueArrow_active : '',
                ])}
            />
        </div>
    );
};
