import { ReactElement, useState } from 'react';
import { ConfirmDialogResponsive } from 'components/common/ConfirmDialog';
import { TButtonColor } from 'components/common/Button/types';

interface IDialogProps {
    handleSecondaryAction: () => void;
    handlePrimaryAction: () => void;
    headerText: JSX.Element | string;
    bodyText?: JSX.Element | string;
    primaryText: string;
    secondaryText: string;
    theme?: TButtonColor;
}
export const useConfirmDialog = (): [
    (props: IDialogProps | null) => void,
    () => ReactElement | null,
] => {
    const [dialogOptions, setDialogOptions] = useState<IDialogProps | null>(null);
    function confirmDialogRender() {
        if (dialogOptions === null) {
            return null;
        }
        return (
            <ConfirmDialogResponsive
                handlePrimaryAction={() => {
                    dialogOptions.handlePrimaryAction(), setDialogOptions(null);
                }}
                handleClose={() => setDialogOptions(null)}
                handleSecondaryAction={() => {
                    dialogOptions.handleSecondaryAction(), setDialogOptions(null);
                }}
                textSecondaryAction={dialogOptions.secondaryText}
                textHeader={dialogOptions.headerText}
                textBody={dialogOptions.bodyText}
                textPrimaryAction={dialogOptions.primaryText}
                theme={dialogOptions.theme}
            />
        );
    }
    return [setDialogOptions, confirmDialogRender];
};
