import { createAsyncThunk } from '@reduxjs/toolkit';
import { setJanusStreamPropsAction } from 'actions/janus/setJanusStreamPropsAction';
import { checkConferenceAndJoinRecorderToConferenceThunkAction } from './checkConferenceAndJoinRecorderToConferenceThunkAction';
import { setRecorderUserDataThunkAction } from './setRecorderUserDataThunkAction';
import { ERecorderThunkAction } from 'constants/thunk/ERecorderThunkAction';

export const prepareRecorderToConnectThunkAction = createAsyncThunk(
    ERecorderThunkAction.PREPARE_RECORDER_TO_CONNECT,
    async (_, { dispatch }) => {
        dispatch(setRecorderUserDataThunkAction());
        dispatch(setJanusStreamPropsAction({
            useAudio: false,
            useVideo: false,
        }));
        dispatch(checkConferenceAndJoinRecorderToConferenceThunkAction());
    },
);
