import { convertStringToDateByTemplate } from 'utils/dates/convertStringToDateByTemplate';
import { getDateBasedOnTimezone } from 'utils/dates/getDateBasedOnTimezone';

export function getStartDateFromConferenceInfo(
    timeStart: string | undefined,
    timeZone?: string,
) {
    if (!timeStart) {
        throw new Error('Can`t found start date of conference');
    }
    const startDate = getDateBasedOnTimezone(
        convertStringToDateByTemplate(timeStart, 'yyyy-MM-dd HH:mm:ss.SSSSSS'),
        timeZone || '',
    );
    if (!startDate) {
        throw new Error('Can`t parse start date of conference');
    }
    return startDate;
}
