import { connect } from 'react-redux';
import { TAppState } from 'types/app/TAppStore';
import { MobileWrapper } from './MobileWrapper';
import { IStateProps } from './types';

const mapStateToProps = (state: TAppState): IStateProps => ({
    isMobile: state.applicationView.deviceProps.isTabletOrMobile,
    isSmallScreen: state.applicationView.deviceProps.isSmallScreen,
    isUltraSmallScreen: state.applicationView.deviceProps.isUltraSmallScreen,
});

export const MobileWrapperConnected = connect(mapStateToProps)(MobileWrapper);
