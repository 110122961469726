import { Trans } from '@lingui/react';
import styles from './UpcomingConferenceInfo.module.scss';
import { IOwnProps } from './types';

export const UpcomingConferenceInfo: React.FC<IOwnProps> = ({
    conferenceName,
    conferenceDescription,
    day,
    isRecurringConference,
    conferenceDetailsHandler,
}) => {
    return (
        <div className={styles.conferenceInfo}>
            <span className={styles.conferenceInfo__name} onClick={conferenceDetailsHandler}>{conferenceName}</span>
            {conferenceDescription && (
                <span className={styles.conferenceInfo__description}>{conferenceDescription}</span>
            )}
            <div className={styles.conferenceInfo__schedule}>
                {day !== 'STARTED' && (
                    <time className={styles.conferenceInfo__scheduleDate}>{day}</time>
                )}
                {isRecurringConference && (
                    <span className={styles.conferenceInfo__scheduleRepeat}>
                        <Trans id={'everyWeek'} />
                    </span>
                )}
            </div>
        </div>
    );
};
