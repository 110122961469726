import { INetworkService } from 'types/api/INetworkService';
import { ISocketParticipantStreams } from 'types/socket/conferenceEvents/ISocketParticipantStreams';

interface IPayload {
    conferenceId: string;
    props: ISocketParticipantStreams;
}

export const sendOwnAudioVideoPropsApiCall = (
    networkService: INetworkService,
    { conferenceId, props: streams }: IPayload,
) =>
    networkService.put({
        url: `/api/conferences/${conferenceId}/streams`,
        data: {
            streams,
        },
    });
