import { connect } from 'react-redux';
import { addMessageToConferenceChatThunkAction } from 'thunk/chat/addMessageToConferenceChatThunkAction';
import { saveConferenceChatMediaFileThunkAction } from 'thunk/chat/saveConferenceChatMediaFile';
import { showNotifyThunkAction } from 'thunk/notify/showNotifyThunkAction';
import { TAppDispatch } from 'types/app/TAppDispatch';
import { TAppState } from 'types/app/TAppStore';
import { ConferenceChatForm } from './ConferenceChatForm';
import { IDispatchProps, IStateProps } from './types';
import {saveChatDraftMessageAction} from '../../../../../actions/chat/saveChatDraftMessageAction';

export const mapDispatchToProps = (dispatch: TAppDispatch): IDispatchProps => ({
    addNewMessage: (messageData) => dispatch(addMessageToConferenceChatThunkAction(messageData)),
    saveMedia: (mediaFiles) =>
        dispatch(saveConferenceChatMediaFileThunkAction(mediaFiles)).unwrap(),
    showNotification: (text) =>
        dispatch(
            showNotifyThunkAction({
                text,
                timeout: 5000,
                type: 'error',
            }),
        ),
    setDraftChatMessage: (messageData) => dispatch(saveChatDraftMessageAction(messageData)),
});

export const mapStateToProps = (state: TAppState): IStateProps => ({
    ownUserId: state.auth.currentUser?.id || null,
    confUserId: state.janus.ownParticipantId,
    isMobile: state.applicationView.deviceProps.isTabletOrMobile,
    maxMessageLength: state.applicationConfiguration.config.maxMessageLength,
    maxMediaFileSizeByte: state.applicationConfiguration.config.maxMediaFileSizeByte - 200,
    draftChatMessage: state.applicationView.draftChatMessage,
});

export const ConferenceChatFormConnected = connect(
    mapStateToProps,
    mapDispatchToProps,
)(ConferenceChatForm);
