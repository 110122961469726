import { createAction } from '@reduxjs/toolkit';
import { EConferencesActions } from 'constants/actions/EConferencesActions';

interface IPayload {
    id: string;
}

export const resetConferenceInvitedUsersAction = createAction<IPayload>(
    EConferencesActions.RESET_CONFERENCE_INVITED_USERS,
);
