import { INetworkService } from 'types/api/INetworkService';


interface IPayload {
    email: string;
}

export const sendUserInviteApiCall = (
    networkService: INetworkService,
    { email }: IPayload,
) =>{    
    return networkService.post({
        url: '/api/account/invite',
        data: { 
            email,
        },
    });
}
