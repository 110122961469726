import { createAsyncThunk } from '@reduxjs/toolkit';
import { sendOwnAudioVideoPropsApiCall } from 'api/conference/sendOwnAudioVideoPropsApiCall';
import { getNetworkService } from 'api/getNetworkService';
import { TAppState } from 'types/app/TAppStore';
import { ISocketParticipantStreams } from 'types/socket/conferenceEvents/ISocketParticipantStreams';

export const sendOwnAudioVideoPropsToServerThunkAction = createAsyncThunk<
    boolean,
    ISocketParticipantStreams
>('sendOwnAudioVideoPropsToServer', async (props, { getState }) => {
    const {
        conferenceSession: { currentConferenceId: conferenceId },
    } = getState() as TAppState;
    const result = await sendOwnAudioVideoPropsApiCall(getNetworkService(), {
        conferenceId,
        props,
    });
    if (!result.success) {
        return false;
    }
    return true;
});
