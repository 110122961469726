import { t } from '@lingui/macro';
import { parse } from 'date-fns';
import { IDateFilters } from 'types/IDateFilters';
import { IRecordsFilter } from 'types/IRecordsFilters';
import { TLanguages } from 'types/TLanguages';
import { getCurrentDate } from 'utils/dates/getCurrentDate';
import { getLocaleByLanguage } from 'utils/getLocaleByLanguage';

export const getDates = (
    {
        end,
        start,
    }: {
        start?: string;
        end?: string;
    },
    lang?: TLanguages,
): { start?: Date; end?: Date } => {
    const locale = { locale: getLocaleByLanguage(lang) };
    const currentDate = getCurrentDate();
    const template = 'dd.MM.yyyy';
    return {
        start: start ? parse(start, template, currentDate, locale) : undefined,
        end: end ? parse(`${end}`, template, currentDate, locale) : undefined,
    };
};

export const getStartEndDates = (
    filter: IDateFilters | IRecordsFilter,
    type: 'conferences' | 'records',
) => {
    if (type === 'conferences') {
        const { finished, past, upcoming } = filter as IDateFilters;
        if (finished) {
            return { start: past.from, end: past.to };
        }
        return { start: upcoming.from, end: upcoming.to };
    }
    const { from, to } = filter as IRecordsFilter;
    return { start: from, end: to };
};

export const getFilterStringRepresentation = (
    filter: IDateFilters | IRecordsFilter,
    type: 'conferences' | 'records',
) => {
    const message = t({ id: 'filter.by.dates', message: 'Фильтр по дате' });
    if (type === 'conferences') {
        const { finished, past, upcoming } = filter as IDateFilters;
        if (finished) {
            return `${message}: ${sliceYear(past.from as string)} - ${
                past.to ? `${sliceYear(past.to)}` : '...'
            }`;
        }
        return `${message}: ${sliceYear(upcoming.from as string)} - ${
            upcoming.to ? `${sliceYear(upcoming.to)}` : '...'
        }`;
    }
    const { from, to } = filter as IRecordsFilter;
    return `${message}: ${sliceYear(from as string)} - ${to ? `${sliceYear(to)}` : '...'}`;
};

export const sliceYear = (date: string): string =>
    date.slice(0, date.length - 4) + date.slice(date.length - 2, date.length);
