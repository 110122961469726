import { Trans } from '@lingui/react';
import React, { useEffect, useRef, useState } from 'react';
import { classNames } from 'utils/classNames';
import { isEmailValid } from 'utils/isEmailValid';
import styles from './SimpleEmailsEditor.module.scss';
import { IInvalidEmail, IOwnProps } from './types';
import { fakeFieldPasteHandler } from './utils';

export const SimpleEmailsEditor: React.FC<IOwnProps> = ({
    inputValues,
    containerClassName,
    setFormField,
}) => {
    const [fakeFieldValue, setFakeFieldValue] = useState('');
    const [invalidEmail, setInvalidEmail] = useState<IInvalidEmail>({
        isInvalid: false,
        value: '',
    });
    const [messageAboutPasted, setMessageAboutPasted] = useState<string>('');
    const [containerInFocus, setContainerInFocus] = useState(false);
    const [searchTimeOut, setSearchTimeOut] = useState<any>(null);
    const [showUsers, setShowUsers] = useState(false);

    const fakeInputRef = useRef<HTMLInputElement>(null);

    const separatesRegex = /^[,|\s]/gi;
    const separatesAtEndRegex = /^.*[,|\s]{1,}$/;
    const symbolOrDigitRegex = /[a-zA-z]$|[0-9]$/gi;

    const fakeFieldChangeHandler = (event: React.ChangeEvent<HTMLInputElement>) => {
        setFakeFieldValue(event.target.value);
    };

    const fakeFieldValidation = () => {
        const targetVal = fakeFieldValue;
        const clearTargetValue = targetVal.trim().replace(/[,|\s]/g, '');
        const symbolOrDigitCondition = targetVal.match(symbolOrDigitRegex);
        const emptyValueWithSeparateAtStartCondition =
            targetVal.match(separatesRegex) && !fakeFieldValue;
        let validEmailWithSeparateAtEndCondition = false;
        const isContainSeparate = targetVal.match(separatesAtEndRegex);
        if (isContainSeparate) {
            validEmailWithSeparateAtEndCondition = isEmailValid(clearTargetValue);
        }

        if (fakeFieldValue && invalidEmail.value !== fakeFieldValue) {
            invalidEmail.isInvalid
                ? setInvalidEmail({ isInvalid: false, value: fakeFieldValue })
                : false;
        }
        searchTimeOut ? clearTimeout(searchTimeOut) : false;
        showUsers ? setShowUsers(false) : false;

        if (!targetVal) {
            invalidEmail.isInvalid ? setInvalidEmail({ isInvalid: true, value: '' }) : false;
            return;
        }

        if (emptyValueWithSeparateAtStartCondition) {
            setFakeFieldValue('');
            return;
        }

        if (symbolOrDigitCondition) {
            setSearchTimeOut(setTimeout(() => setShowUsers(true), 500));
        }

        if (isContainSeparate) {
            if (!validEmailWithSeparateAtEndCondition) {
                setInvalidEmail({ isInvalid: true, value: clearTargetValue });
                setFakeFieldValue(clearTargetValue);
                return;
            }
        }
        if (validEmailWithSeparateAtEndCondition) {
            setFormField([...inputValues, ...[clearTargetValue]]);
            setFakeFieldValue('');
            return;
        }
    };

    const dellClickHandler = (event: React.MouseEvent<HTMLButtonElement>) => {
        const targetId = event.currentTarget.id;
        const newInputValues = inputValues.filter((elem, idx) => {
            return Number(targetId) !== idx;
        });
        setFormField(newInputValues);
    };

    const fakeFieldKeyDownHandler = (event: React.KeyboardEvent<HTMLInputElement>) => {
        if (event.code === 'Backspace' && !fakeFieldValue) {
            if (inputValues.length === 0) {
                return;
            }
            event.preventDefault();
            setFakeFieldValue(inputValues[inputValues.length - 1]);
            setFormField([...inputValues.slice(0, inputValues.length - 1)]);
            return;
        }
        if (event.code === 'Enter') {
            event.stopPropagation();
            setFakeFieldValue((prevVal) => `${prevVal},`);
            return;
        }
    };

    const setFakeInputWidth = () => {
        const fakeFieldValueLength = fakeFieldValue?.length || 0;
        const style = fakeInputRef?.current?.style;
        if (!style) {
            return;
        }
        style.width = fakeFieldValueLength === 0 ? '1em' : `${fakeFieldValueLength}em`;
    };

    const outsideClickHandler = () => {
        containerInFocus ? setContainerInFocus(false) : false;
        setTimeout(() => {
            showUsers ? setShowUsers(false) : false;
        }, 300);
        const validEmailWithSeparateAtEndCondition = isEmailValid(fakeFieldValue);
        const clearTargetValue = fakeFieldValue.trim().replace(/[,|\s]/g, '');
        if (!clearTargetValue) {
            return;
        }

        if (!validEmailWithSeparateAtEndCondition) {
            setInvalidEmail({ isInvalid: true, value: clearTargetValue });
            return;
        }
        setFormField([...inputValues, ...[clearTargetValue]]);
        setFakeFieldValue('');
    };

    const containerClickHandler = (event: React.MouseEvent<HTMLUListElement>) => {
        event.preventDefault();
        event.currentTarget.dataset.invitesContainer ? fakeInputRef?.current?.focus() : false;
    };

    useEffect(() => {
        fakeFieldValidation();
        setFakeInputWidth();
    }, [fakeFieldValue]);
    
    //FIXME Temp,need to delete
    useEffect(() => {
        inputValues.length !== 0 && setContainerInFocus(false);
    }, [inputValues]);

    const renderMailTags = () =>
        inputValues.map((tag, index) => {
            const displayName = tag;
            return (
                <li className={styles.tag} key={tag}>
                    <span>{displayName}</span>
                    <button
                        type='button'
                        onClick={dellClickHandler}
                        className={styles.cross}
                        id={`${index}`}></button>
                </li>
            );
        });

    const listComputedStyles = classNames([
        containerClassName,
        containerInFocus ? styles.container_focus : '',
    ]);
    const fakeInputComputedStyles = invalidEmail.isInvalid ? styles.error : '';

    return (
        <div className={styles.wrapper}>
            <div>
                <ul
                    className={classNames([styles.container, listComputedStyles])}
                    onClick={containerClickHandler}
                    data-invites-container>
                    {inputValues && renderMailTags()}
                    {inputValues.length === 0 && (
                        <li>
                            <input
                                type='text'
                                onChange={fakeFieldChangeHandler}
                                onPaste={(event: React.ClipboardEvent) =>
                                    fakeFieldPasteHandler({
                                        event,
                                        setFakeFieldValue,
                                        setFormField,
                                        setInvalidEmail,
                                        inputValues,
                                        setMessageAboutPasted,
                                    })
                                }
                                onFocus={() => setContainerInFocus(true)}
                                onBlur={outsideClickHandler}
                                onKeyDown={fakeFieldKeyDownHandler}
                                value={fakeFieldValue}
                                ref={fakeInputRef}
                                className={`${styles.fakeInput} ${fakeInputComputedStyles}`}
                            />
                        </li>
                    )}
                    {!containerInFocus && !inputValues.length && !fakeFieldValue && (
                        <div className={styles.placeholder}>
                            <Trans id='admin.emailEditor.placeholder' message='Введите email' />
                        </div>
                    )}
                </ul>
            </div>
            {messageAboutPasted.length ? (
                <div className={`${styles.info__container} ${styles.error}`}>
                    {messageAboutPasted}
                </div>
            ) : null}
        </div>
    );
};
