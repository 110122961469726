import { createAsyncThunk } from '@reduxjs/toolkit';
import { finishConferenceApiCall } from 'api/conference/finishConferenceApiCall';
import { getNetworkService } from 'api/getNetworkService';
import { TAppState } from 'types/app/TAppStore';

export const finishConferenceThunkAction = createAsyncThunk<boolean>(
    'finishConference',
    async (_: void, { getState }) => {
        const {
            conferenceSession: { currentConferenceId },
        } = getState() as TAppState;
        const response = await finishConferenceApiCall(getNetworkService(), currentConferenceId);
        return response.success;
    },
);
