import { createAsyncThunk } from '@reduxjs/toolkit';
import { setJanusStreamPropsAction } from 'actions/janus/setJanusStreamPropsAction';
import { TAppState } from 'types/app/TAppStore';
import { getFromLocalStorage } from 'utils/localStorage/getFromLocalStorage';

export const getPreviousCurrentConferenceDeviceValuesThunkAction = createAsyncThunk(
    'getPreviousCurrentConferenceDeviceValues',
    async (
        defaultDeviceValues: { useVideo: boolean; useAudio: boolean } | null,
        { dispatch, getState },
    ) => {
        const {
            conferenceSession: { currentConferenceId },
        } = getState() as TAppState;
        const localDeviceValues = getFromLocalStorage(currentConferenceId)?.conferenceDeviceValues;
        const resultValues = localDeviceValues
            ? { ...defaultDeviceValues, ...localDeviceValues }
            : defaultDeviceValues;
        if (resultValues) {
            dispatch(setJanusStreamPropsAction(resultValues));
        }
    },
);
