
export function getSeriesIdFromConferenceInfo({
    seriesId,
    startDateOfRecurrence,
    id,
}: {
    seriesId?: string;
    startDateOfRecurrence?: string;
    id: string;
}) {
    if (seriesId) {
        return seriesId;
    }
    if (startDateOfRecurrence) {
        return id;
    }
}
