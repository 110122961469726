import { createAsyncThunk } from '@reduxjs/toolkit';
import { setConferencesRecordsAction } from 'actions/records/setConferencesRecordsAction';
import { getNetworkService } from 'api/getNetworkService';
import { getConferencesRecordsApiCall } from 'api/records/getConferencesRecordsApiCall';
import { executePendingOperationThunkAction } from 'thunk/appView/executePendingOperationThunkAction';
import {
    covertServerRecords,
} from 'utils/records/covertServerRecords';

export interface IPayload {
    from: undefined | string;
    to: undefined | string;
}

export const getConferencesRecordsThunkAction = createAsyncThunk(
    'getConferencesRecords',
    async (filters: IPayload, { dispatch }) => {
        dispatch(
            executePendingOperationThunkAction({
                operation: 'defaultOperation',
                pendingFunction: async () => {
                    const response = await getConferencesRecordsApiCall(
                        getNetworkService(),
                        filters,
                    );
                    if (response.success) {
                        dispatch(
                            setConferencesRecordsAction(
                                covertServerRecords(response.data),
                            ),
                        );
                    }
                },
            }),
        );
    },
);
