import { RegistrationForm } from 'pages/RegistrationPage/RegistrationForm/RegistrationForm';
import { connect } from 'react-redux';
import { inviteRegistrationThunkAction } from 'thunk/auth/inviteRegistrationThunkAction';
import { showNotifyThunkAction } from 'thunk/notify/showNotifyThunkAction';
import { TAppDispatch } from 'types/app/TAppDispatch';
import { TAppState } from 'types/app/TAppStore';
import { getDefaultErrorNotification } from 'utils/getDefaultErrorNotification';
import { redirect } from 'utils/redirect';
import { IDispatchProps, IStateProps } from './types';

const mapStateToProps = (state: TAppState): IStateProps => ({
    loggedIn: state.auth.rememberMeRequestStatus === 'success',
});

const mapDispatchToProps = (dispatch: TAppDispatch): IDispatchProps => ({
    register: (data) => dispatch(inviteRegistrationThunkAction(data)),
    goToPersonalInfoPage: () => redirect('/updateProfile'),
    showNotificationError: () => dispatch(showNotifyThunkAction(getDefaultErrorNotification())),
});

export const InviteRegistrationFormConnected = connect(
    mapStateToProps,
    mapDispatchToProps,
)(RegistrationForm);
