import styles from './VideoPlaceholder.module.scss';
import Video from 'assets/video/Placeholder.mp4';

export const VideoPlaceholder: React.FC = () => (
    <video
        typeof='video/mp4'
        src={Video}
        playsInline
        autoPlay
        loop={true}
        muted={true}
        className={styles.hidden}
    />
);
