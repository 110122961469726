let emotionsAssetsTypeList: undefined | __WebpackModuleApi.RequireContext;
let emotionsAssetsTypeAll: undefined | __WebpackModuleApi.RequireContext;
let emotionsAnimations: undefined | __WebpackModuleApi.RequireContext;

export const getEmotionsAssetsTypeAll = () => emotionsAssetsTypeAll;
export const setEmotionsAssetsTypeAll = (value: __WebpackModuleApi.RequireContext) => {
    emotionsAssetsTypeAll = value;
};

export const getEmotionsAssetsTypeList = () => emotionsAssetsTypeList;
export const setEmotionsAssetsTypeList = (value: __WebpackModuleApi.RequireContext) => {
    emotionsAssetsTypeList = value;
};

export const getEmotionsAnimations = () => emotionsAnimations;
export const setEmotionsAnimations = (value: any) => {
    emotionsAnimations = value;
};
