import { IOwnProps } from './types';
import styles from '../ConferenceChatMessageContent.module.scss';
import { getImagePreviewPath } from 'utils/getImagePreviewPath';

export const ConferenceChatMessageImage: React.FC<IOwnProps> = ({
    id,
    conferenceId,
    sessionId,
    name,
    authorId,
    time,
    handleImageLoad,
}) => {
    return (
        <span key={id} className={`${styles.file} ${styles.file_image}`}>
            <img
                src={`${getImagePreviewPath({
                    conferenceId,
                    imgId: id,
                    sessionId,
                    name,
                })}`}
                alt={name}
                key={id}
                data-content-info={JSON.stringify({
                    authorId,
                    time,
                    id,
                    fileName: name,
                })}
                onLoad={handleImageLoad}
            />
        </span>
    );
};
