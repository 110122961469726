import { addUsersTimeZoneToDate } from 'utils/dates/addUsersTimeZoneToDate';
import { convertDateToStringByTemplate } from 'utils/dates/convertDateToStringByTemplate';
import { convertStringToDateByTemplate } from 'utils/dates/convertStringToDateByTemplate';

/**
 * @param dispatchTime '2021-12-23T06:42:21.368208Z' || ''
 */
export const getFormattedDispatchTime = (dispatchTime: string) => {
    if (!dispatchTime) {
        return ''
    }
    const dateFromDispatchTime = convertStringToDateByTemplate(
        dispatchTime,
        'yyyy-MM-dd HH:mm:ss.SSSSSS',
        true,
    );
    return convertDateToStringByTemplate(addUsersTimeZoneToDate(dateFromDispatchTime), 'HH:mm');
};
