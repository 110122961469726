import { configureStore, getDefaultMiddleware } from '@reduxjs/toolkit';
import { applicationConfigurationReducer } from 'reducers/applicationConfigurationReducer';
import { applicationViewReducer } from 'reducers/applicationViewReducer';
import { authReducer } from 'reducers/authReducer';
import { conferenceChatReducer } from 'reducers/conferenceChatReducer';
import { conferenceSessionReducer } from 'reducers/conferenceSessionReducer';
import { conferencesListReducer } from 'reducers/conferencesListReducer';
import { conferencesRecordsReducer } from 'reducers/conferencesRecordsReducer';
import { conferencesReducer } from 'reducers/conferencesReducer';
import { conferencesReportsReducer } from 'reducers/conferencesReportsReducer';
import { featuresReducer } from 'reducers/featuresReducer';
import { janusReducer } from 'reducers/janusReducer';
import { lobbyReducer } from 'reducers/lobbyReducer';
import { schedulingConferenceReducer } from 'reducers/schedulingConferenceReducer';
import { sessionSettingsReducer } from 'reducers/sessionSettingsReducer';
import { usersReducer } from 'reducers/usersReducer';
import { adminReducer } from 'admin-app/reducers/adminReducer'
import { registerApplicationStore } from 'utils/applicationStoreProvider';
import { initFeaturesBrowserApi } from 'utils/dev/features';
import { conferenceChatMiddleware } from './conferenceChatMiddleware';
import { janusMiddleware } from './janusMiddleware';

const middleware = getDefaultMiddleware({
    immutableCheck: false,
    serializableCheck: false,
    thunk: true,
});
middleware.push(janusMiddleware);
middleware.push(conferenceChatMiddleware);
// middleware.push(conferencesMiddleware);

export const applicationStore = configureStore({
    reducer: {
        applicationConfiguration: applicationConfigurationReducer,
        applicationView: applicationViewReducer,
        auth: authReducer,
        conferenceChat: conferenceChatReducer,
        conferenceSession: conferenceSessionReducer,
        conferences: conferencesReducer,
        conferencesList: conferencesListReducer,
        conferencesRecords: conferencesRecordsReducer,
        conferencesReports: conferencesReportsReducer,
        janus: janusReducer,
        lobby: lobbyReducer,
        schedulingConference: schedulingConferenceReducer,
        sessionSettings: sessionSettingsReducer,
        users: usersReducer,
        features: featuresReducer,
        admin: adminReducer,
    },
    middleware,
    devTools: true,
});

initFeaturesBrowserApi(applicationStore);

registerApplicationStore(applicationStore);
