import { t } from '@lingui/macro';
import { Button } from 'components/common/Button';
import { LobbyLoader } from 'pages/LobbyPage/LobbyLoader/LobbyLoader';
import React, { useEffect } from 'react';
import { AccessDeniedDialogConnected } from './AccessDeniedDialog';
import styles from './LobbyPage.module.scss';
import { IDispatchProps, IStateProps } from './types';
import {redirect} from '../../utils/redirect';

export const LobbyPageMobile: React.FC<IStateProps & IDispatchProps> = ({
    conferenceConnectInfo,
    confId,
    responseToConferenceJoinRequest,
    goToConferencePage,
    goToUpcomingConferences,
    leaveFromLobby,
    isAnonymous,
}) => {
    useEffect(() => {
        if (!conferenceConnectInfo) {
            goToUpcomingConferences();
        }
        const leaveHandler = () => {
            if (responseToConferenceJoinRequest === null) {
                leaveFromLobby();
            }
        };
        window.addEventListener('popstate', leaveHandler);
        return () => window.removeEventListener('popstate', leaveHandler);
    }, []);

    useEffect(() => {
        if (responseToConferenceJoinRequest === null) return;
        if (responseToConferenceJoinRequest) {
            goToConferencePage(confId);
        }
    }, [responseToConferenceJoinRequest]);

    const handleClick = () => {
        leaveFromLobby();
        if (isAnonymous) {
            redirect('/');
        } else {
            goToUpcomingConferences();
        }
    };
    return (
        <div className={[styles.root, styles.root_mobile].join(' ')}>
            <div className={styles.lobbyMobile}>
                {responseToConferenceJoinRequest === null && (
                    <LobbyLoader className={styles.loader_mobile} />
                )}
                {responseToConferenceJoinRequest === false && (
                    <AccessDeniedDialogConnected device='mobile' />
                )}
                <Button
                    type='button'
                    styleType='mobileSemiTransparent'
                    clickHandler={handleClick}
                    className={styles.submitBtn_mobile}
                    caption={t({ id: 'Cancel', message: 'Отменить' })}
                />
            </div>
        </div>
    );
};
