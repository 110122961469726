import { Button } from 'components/common/Button';
import { EmailListEditorWrapper } from 'components/common/EmailListEditor/EmailListEditorWrapper';
import { Form, FormRenderProps } from 'react-final-form';
import { IDispatchProps, IOwnProps, IShareDialogInvitedUsersForm } from './types';
import styles from './ConferenceShareDialogInviteUsers.module.scss';
import { Trans } from '@lingui/react';
import { getInvitedUsersToSend } from 'utils/getInvitedUsersToSend';
import { t } from '@lingui/macro';

export const ConferenceShareDialogInviteUsers: React.FC<IOwnProps & IDispatchProps> = ({
    conferenceInfo,
    updateConferenceInvitedUsers,
    showNotification,
    closeDialog,
}) => {
    const handleSubmit = async (values: IShareDialogInvitedUsersForm) => {
        if (!values.invitedUsers.length) {
            return;
        }
        const { added } = getInvitedUsersToSend(values.invitedUsers, conferenceInfo.invitedUsers);
        if (!added.length) {
            return;
        }
        const dataToSend = {
            invitesData: { added, removed: [] },
            conferenceId: conferenceInfo.id,
        };
        const { isSuccess } = await updateConferenceInvitedUsers(dataToSend);

        if (isSuccess) {
            closeDialog();
            showNotification(
                t({ id: 'invitations.sent', message: 'Приглашения успешно отправлены' }),
            );
        }
    };

    const renderForm = ({
        values,
        handleSubmit,
        submitting,
    }: FormRenderProps<IShareDialogInvitedUsersForm>) => {
        const isPristine = values.invitedUsers.length;
        return (
            <form onSubmit={handleSubmit}>
                <EmailListEditorWrapper name='invitedUsers' containerClassName={styles.emailList} />
                <Button
                    styleType='primaryActionNew'
                    className={styles.button}
                    type='submit'
                    disabled={!isPristine || submitting}>
                    <Trans id='sendInvite' message='Отправить приглашение' />
                </Button>
            </form>
        );
    };

    const initialValues = {
        invitedUsers: [],
    };

    return (
        <div>
            <span>
                <Trans id='whom' message='Кому' />
            </span>
            <Form onSubmit={handleSubmit} initialValues={initialValues} render={renderForm} />
        </div>
    );
};
