import { connect } from 'react-redux';
import { SimpleConferencePage } from './SimpleConferencePage';
import { TAppState } from 'types/app/TAppStore';
import { IDispatchProps, IStateProps } from './types';
import { exitFromConferenceThunkAction } from 'thunk/conference/exitFromConferenceThunkAction';
import { TAppDispatch } from 'types/app/TAppDispatch';
import { subscribeToConferenceEventsActionThunk } from 'thunk/socket/subscribeToConferenceEventsThunkAction';

const mapStateToProps = (state: TAppState): IStateProps => ({
    id: state.conferenceSession.currentConferenceId,
    janusIsReady: state.janus.videoRoomConnected,
    showAudioPermissionDialog: state.applicationView.showAudioPermissionDialog,
    isNoFramesToDisplay: !Object.keys(state.conferenceSession.currentConferenceParticipants),
});

const mapDispatchToProps = (dispatch: TAppDispatch): IDispatchProps => ({
    subscribeToConferenceEvents: (confId) =>
        dispatch(subscribeToConferenceEventsActionThunk(confId)),

    closeJanusSession: () =>
        dispatch(exitFromConferenceThunkAction({ stopJanus: true, redirect: false })),
});

export const SimpleConferencePageConnected = connect(
    mapStateToProps,
    mapDispatchToProps,
)(SimpleConferencePage);
