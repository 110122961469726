import { t } from '@lingui/macro';
import { Field } from 'react-final-form';
import styles from './RepeatDay.module.scss';
import { IOwnProps } from './types';

export const RepeatDay: React.FC<IOwnProps> = ({ className = '' }) => {
    const daysList = [
        { text: t({ id: 'shortDay.mn', message: 'Пн' }), value: 'monday' },
        { text: t({ id: 'shortDay.tu', message: 'Вт' }), value: 'tuesday' },
        { text: t({ id: 'shortDay.we', message: 'Ср' }), value: 'wednesday' },
        { text: t({ id: 'shortDay.th', message: 'Чт' }), value: 'thursday' },
        { text: t({ id: 'shortDay.fr', message: 'Пт' }), value: 'friday' },
        { text: t({ id: 'shortDay.sa', message: 'Сб' }), value: 'saturday' },
        { text: t({ id: 'shortDay.su', message: 'Вс' }), value: 'sunday' },
    ];

    return (
        <div className={`${styles.container} ${className}`}>
            {daysList.map(({ text, value }) => (
                <Field type='checkbox' name='days' value={value} key={value}>
                    {({ input }) => (
                        <label
                            htmlFor={value}
                            className={`${styles.label} ${
                                input.checked ? styles.label_checked : ''
                            }`}>
                            <span>{text}</span>
                            <input
                                id={value}
                                type='checkbox'
                                {...input}
                                className={styles.checkbox}
                            />
                        </label>
                    )}
                </Field>
            ))}
        </div>
    );
};
