import { createAsyncThunk } from '@reduxjs/toolkit';
import { getConferenceParticipantsApiCall } from 'api/conference/getConferenceParticipantsApiCall';
import { getNetworkService } from 'api/getNetworkService';
import { TAppState } from 'types/app/TAppStore';
import { setParticipantsFromServerThunkAction } from './setParticipantsFromServerThunkAction';

export const getCurrentConferenceParticipantsThunkAction = createAsyncThunk(
    'getCurrentConferenceParticipants',
    async (_: void, { dispatch, getState }) => {
        const {
            conferenceSession: { currentConferenceId, currentConferencePin },
        } = getState() as TAppState;
        const response = await getConferenceParticipantsApiCall(getNetworkService(), {
            id: currentConferenceId,
            pin: currentConferencePin,
        });
        if (!response.success) {
            return;
        }
        dispatch(setParticipantsFromServerThunkAction(response.data));
    },
);
