import { t } from '@lingui/macro';
import { Trans } from '@lingui/react';
import navArrow from 'assets/CalendarArrow.svg';
import navArrowYear from 'assets/CalendarArrowYear.svg';
import CalendarGrayImg from 'assets/CalendarGray.svg';
import CrossInCircleImg from 'assets/CrossInCircle.svg';
import { format, getYear } from 'date-fns';
import { MouseEvent, useEffect, useRef, useState } from 'react';
import ReactDatePicker from 'react-datepicker';
import { TStepYear } from 'types/TStepYear';
import { getCurrentDate } from 'utils/dates/getCurrentDate';
import { getLocaleByLanguage } from 'utils/getLocaleByLanguage';
import './DateFilter.css';
import styles from './DateFilter.module.scss';
import { IOwnProps, IStateProps } from './types';
import { getDates } from './utils';

export const DateFilter: React.FC<IOwnProps & IStateProps> = ({
    start,
    end,
    handleDateChange,
    className,
    maxDate,
    minDate,
    lang,
}) => {
    const [dates, setDates] = useState(getDates({ end, start }, lang));
    const [year, setYear] = useState(
        dates.start ? getYear(dates.start) : getYear(getCurrentDate()),
    );
    const [error, setError] = useState(false);
    const inputRef = useRef<ReactDatePicker>(null);

    const onDateChange = (newValue: [Date, Date]) => {
        const [start, end] = newValue;

        error ? setError(false) : false;
        setDates((dates) => {
            const newDate = {
                ...dates,
                ...{ start: start ? start : undefined, end: end ? end : undefined },
            };

            if (!newDate.start || !newDate.end) {
                setError(true);
            }
            return newDate;
        });
    };

    const onYearChange = (type: TStepYear, changeYearCallback: (year: number) => void) => {
        if (type === 'prev') {
            changeYearCallback(year - 1);
            setYear(year - 1);
            return;
        }
        changeYearCallback(year + 1);
        setYear(year + 1);
    };

    const renderDayContents = (day: number) => {
        return <span className='react-datepicker__dayNumber'>{day}</span>;
    };

    const resetYear = () => {
        setYear(dates.start ? getYear(dates.start) : getYear(getCurrentDate()));
    };

    const customHeader = ({
        monthDate,
        customHeaderCount,
        decreaseMonth,
        increaseMonth,
        changeYear,
    }: {
        monthDate: Date;
        customHeaderCount: number;
        changeYear: (year: number) => void;
        decreaseMonth: () => void;
        increaseMonth: () => void;
    }) => (
        <div
            className={`react-datepicker__headerTop 
            ${customHeaderCount === 1 ? 'react-datepicker__headerTop_right' : ''}`}>
            <button
                aria-label='Previous Year'
                className='react-datepicker__arrow'
                style={customHeaderCount === 1 ? { display: 'none' } : undefined}
                onClick={() => onYearChange('prev', changeYear)}>
                <img src={navArrowYear} />
            </button>
            <button
                aria-label='Previous Month'
                className='react-datepicker__arrow'
                style={customHeaderCount === 1 ? { display: 'none' } : undefined}
                onClick={decreaseMonth}>
                <img src={navArrow} />
            </button>
            <span className='react-datepicker__current-month'>
                {monthDate.toLocaleString(lang || 'en', {
                    month: 'long',
                    year: 'numeric',
                })}
            </span>
            <button
                aria-label='Next Month'
                className={'react-datepicker__navigation react-datepicker__navigation--next'}
                style={customHeaderCount === 0 ? { display: 'none' } : undefined}
                onClick={increaseMonth}>
                <img className='react-datepicker__arrow_rotate' src={navArrow} />
            </button>
            <button
                aria-label='Next Year'
                className={'react-datepicker__navigation react-datepicker__navigation--next'}
                style={customHeaderCount === 0 ? { display: 'none' } : undefined}
                onClick={() => onYearChange('next', changeYear)}>
                <img className='react-datepicker__arrow_rotate' src={navArrowYear} />
            </button>
        </div>
    );

    const onResetClick = () => {
        error ? setError(false) : false;
        const data = { start: undefined, end: undefined };
        setDates(data);
        setYear(getYear(getCurrentDate()));
        handleDateChange(data);
    };

    const onLabelClick = (e: MouseEvent) => {
        if (!inputRef.current) {
            return;
        }
        const datePickerState = inputRef?.current?.state as any;
        datePickerState?.open && e.preventDefault();
    };

    const formattedDateForRender = {
        start: dates.start
            ? format(dates.start, 'dd.MM.yyyy')
            : t({ message: 'Начало', id: 'date.start' }),
        end: dates.end ? format(dates.end, 'dd.MM.yyyy') : t({ message: 'Конец', id: 'date.end' }),
    };

    useEffect(() => {
        if (dates.start && dates.end) {
            const formattedDate = {
                start: format(dates.start, 'dd.MM.yyyy'),
                end: format(dates.end, 'dd.MM.yyyy'),
            };
            if (formattedDate.start !== start || formattedDate.end !== end) {
                handleDateChange(formattedDate);
            }
        }
    }, [dates]);
    useEffect(() => {
        setDates(getDates({ end, start }, lang));
    }, [start, end]);

    return (
        <div className={styles.positionWrapper}>
            <label className={className} onClick={onLabelClick}>
                <div className={`${styles.wrapper} ${error ? styles.error : ''}`}>
                    <img src={CalendarGrayImg} alt='calendar' />
                    <ReactDatePicker
                        ref={inputRef}
                        renderCustomHeader={({
                            monthDate,
                            customHeaderCount,
                            decreaseMonth,
                            increaseMonth,
                            changeYear,
                        }) =>
                            customHeader({
                                monthDate,
                                changeYear,
                                customHeaderCount,
                                decreaseMonth,
                                increaseMonth,
                            })
                        }
                        focusSelectedMonth
                        shouldCloseOnSelect={true}
                        selected={dates.start}
                        renderDayContents={renderDayContents}
                        startDate={dates.start}
                        endDate={dates.end}
                        calendarClassName={styles.datePicker}
                        monthsShown={2}
                        locale={getLocaleByLanguage(lang)}
                        onChange={onDateChange}
                        onCalendarClose={resetYear}
                        dateFormat='dd.MM.yyyy'
                        selectsRange
                        maxDate={maxDate}
                        minDate={minDate}
                    />
                    <span className={`${!dates.start ? styles.datePlaceholder : ''}`}>
                        {formattedDateForRender.start}
                    </span>
                    <span className={styles.datesSeparator}>
                        <Trans id='to' message='до' />
                    </span>
                    <span className={`${!dates.end ? styles.datePlaceholder : ''}`}>
                        {formattedDateForRender.end}
                    </span>
                </div>
            </label>
            <img
                src={CrossInCircleImg}
                className={styles.cross}
                alt='reset'
                onClick={onResetClick}
            />
        </div>
    );
};
