import { TDeviceKind } from 'components/HOCs/ComponentWithDevices/types';
import { IConferenceCreator } from 'types/IConferenceCreator';
import { IUserDevice } from 'types/IUserDevice';
import { convertDateToStringByTemplate } from 'utils/dates/convertDateToStringByTemplate';
import { convertStringToDateByTemplate } from 'utils/dates/convertStringToDateByTemplate';
import { getDateBasedOnTimezone } from 'utils/dates/getDateBasedOnTimezone';
import { redirect } from 'utils/redirect';
import { getTimeZoneName } from 'utils/timeZones/getTimeZoneName';
import { getUserTimeZone } from 'utils/timeZones/getUserTimeZone';
import { getUserInitials } from 'utils/users/getUserInitials';

export const getOwnerData = (conferenceOwner: IConferenceCreator | undefined) => {
    let fullName = '';
    let initials = '';
    let email = '';
    let id = '';

    if (conferenceOwner) {
        fullName = `${conferenceOwner.firstName} ${conferenceOwner.lastName}`;
        initials = getUserInitials(conferenceOwner);
        email = conferenceOwner.email;
        id = conferenceOwner.userId;
    }

    return { fullName, initials, email, id };
};

export const getConferenceTimeStartInfo = (timeStart?: string, isMobile?: boolean) => {
    if (!timeStart) {
        return '';
    }
    const resultDateString = convertDateToStringByTemplate(
        getDateBasedOnTimezone(
            convertStringToDateByTemplate(timeStart, 'yyyy-MM-dd HH:mm:ss.SSSSSS'),
            getUserTimeZone(),
        ),
        'dd.MM.yy, HH:mm',
    );
    const timeZoneString = getTimeZoneName(getUserTimeZone());

    if (isMobile) {
        return `${resultDateString} ${timeZoneString}`;
    }

    return (
        <>
            <span>{resultDateString}</span>
            <span>{timeZoneString}</span>
        </>
    );
};

export const checkDevice = (
    deviceType: TDeviceKind,
    device: IUserDevice | undefined,
    devicesList: IUserDevice[],
    newStreamProps: {
        audioDevice?: IUserDevice;
        videoDevice?: IUserDevice;
    },
) => {
    if (!device || !devicesList.some((i) => i.deviceId === device.deviceId)) {
        const val = devicesList.length !== 0 ? devicesList[0] : undefined;
        if (deviceType === 'audioinput') {
            newStreamProps.audioDevice = val;
        } else {
            newStreamProps.videoDevice = val;
        }
    }
};

export const processFinishedConference = (confId: string) => {
    redirect(`/conference/${confId}/finished`);
};
