import { setJanusStreamPropsAction } from './../../actions/janus/setJanusStreamPropsAction';
import { t } from '@lingui/macro';
import { setLocalStreamForJanusAction } from 'actions/janus/setLocalStreamForJanusAction';
import { appHistory } from 'appHistory';
import { showNotifyThunkAction } from 'thunk/notify/showNotifyThunkAction';
import { TAppState } from 'types/app/TAppStore';
import { janusVideoRoomLogger } from 'utils/logger';
import { janusCtx } from './janusCtx';
import { janusInitializationErrorEvent } from './janusCustomEvents';
import { janusTextRoomMessagesHandler } from './janusTextRoomMessagesHandler';
import { janusVideoRoomMessagesHandler } from './janusVideoRoomMessagesHandler';
import { videoRoomPluginSuccessConnectedHandler } from './videoRoomPluginSuccessConnectedHandler';

export const getJanusVideoRoomPluginAttachArgument = (
    resolve: () => void,
    reject: (reason: any) => void,
) => ({
    plugin: 'janus.plugin.videoroom',
    opaqueId: janusCtx.opaqueId,
    success: (pluginHandle: any) => {
        videoRoomPluginSuccessConnectedHandler(pluginHandle);
        resolve();
    },
    error: (error: any) => {
        janusVideoRoomLogger.error('  -- Error attaching plugin...', error);
        reject({ reason: 'Error attaching plugin Janus VideoRoom plugin' });
        document.dispatchEvent(janusInitializationErrorEvent);
        janusCtx.dispatch(
            showNotifyThunkAction({
                timeout: 5000,
                type: 'error',
                text: t({
                    id: 'Janus initialization failed',
                    message:
                        'Ошибка при инициализации плагина Janus. Будет осуществлён возврат на домашнюю страничку',
                }),
                closeAction: () => {
                    if (window.location.pathname !== '/') {
                        appHistory.push('/');
                    }
                },
            }),
        );
    },
    consentDialog: (on: any) => {
        janusVideoRoomLogger.debug('Consent dialog should be ' + (on ? 'on' : 'off') + ' now');
    },
    iceState: (state: any) => {
        janusVideoRoomLogger.debug('ICE state changed to ' + state);
    },
    mediaState: (medium: any, on: any, mid: any) => {
        janusVideoRoomLogger.debug(
            `Janus ${on ? 'started' : 'stopped'} receiving our ${medium} (mid=${mid})`,
        );
    },
    webrtcState: (on: any) => {
        janusVideoRoomLogger.debug(
            'Janus says our WebRTC PeerConnection is ' + (on ? 'up' : 'down') + ' now',
        );
        if (!on) return;
    },
    slowLink: function (uplink: any, lost: any, mid: any) {
        janusVideoRoomLogger.debug(
            `Janus reports problems ${
                uplink ? 'sending' : 'receiving'
            } packets on mid ${mid} (${lost} lost packets)`,
        );
    },
    onmessage: janusVideoRoomMessagesHandler,
    onlocaltrack: (track: MediaStreamTrack, on: boolean) => {
        janusVideoRoomLogger.debug(' ::: Got a local track :::', { track, on });
        // Here we don't expect multi streams. Only 1 video and 1 audio tracks
        const {
            janus: {
                ownStream: { audio: ownAudio, video: ownVideo },
            },
        } = janusCtx.getState() as TAppState;
        const [alreadySavedAudioMid, alreadySavedVideoMid] = [
            ownAudio?.mid ?? '0',
            ownVideo?.mid ?? '0',
        ];

        if (!on) {
            // This piece of code hasn't been run yet. Maybe there is an error
            if (!track) {
                return;
            }
            if (track.kind === 'video' && alreadySavedVideoMid) {
                stopTracksInStream(ownVideo?.stream);
            } else if (track.kind === 'audio' && alreadySavedAudioMid) {
                stopTracksInStream(ownAudio?.stream);
            }
            return;
        }
        // Add track
        if (track.kind === 'audio' && alreadySavedAudioMid) {
            const stream = new MediaStream();
            stream.addTrack(track);
            janusCtx.dispatch(
                setLocalStreamForJanusAction({ stream, type: 'audio', mid: alreadySavedAudioMid }),
            );
        } else if (track.kind === 'video' && alreadySavedVideoMid) {
            const stream = new MediaStream();
            stream.addTrack(track);
            janusCtx.dispatch(
                setLocalStreamForJanusAction({ stream, type: 'video', mid: alreadySavedVideoMid }),
            );
        }
        if (
            janusCtx.videoRoomPublisherPluginHandler.webrtcStuff.pc.iceConnectionState !==
                'completed' &&
            janusCtx.videoRoomPublisherPluginHandler.webrtcStuff.pc.iceConnectionState !==
                'connected'
        ) {
            janusVideoRoomLogger.debug({
                iceConnectionState:
                    janusCtx.videoRoomPublisherPluginHandler.webrtcStuff.pc.iceConnectionState,
            });
        }
    },
    onremotetrack: (track: any, mid: any, on: any) => {
        // The publisher stream is sendonly, we don't expect anything here
        janusVideoRoomLogger.debug(' ::: Got a remote stream ::: ');
        janusVideoRoomLogger.debug({ track, mid, on });
    },
    oncleanup: () => {
        janusVideoRoomLogger.debug(' ::: Got a cleanup notification: we are unpublished now :::');
        // stopStream((janusCtx.getState() as TAppState).janus.ownStream);
        // janusCtx.videoRoomPublisherPluginHandler.hangup();
        // const { janus } = janusCtx.getState() as TAppState;
        // if (janus) {
        //     const { ownStream } = janus;
        //     if (ownStream.audio) {
        //         const tracks = ownStream.audio.stream.getTracks();
        //         tracks.forEach((track) => track.stop());
        //     }
        //     if (ownStream.video) {
        //         const tracks = ownStream.video.stream.getTracks();
        //         tracks.forEach((track) => track.stop());
        //     }
        // }
        janusCtx.published = false;
        janusCtx.dispatch(setJanusStreamPropsAction({ useAudio: false, useVideo: false }));
    },
    ondata: janusTextRoomMessagesHandler,
});

const stopTracksInStream = (stream: any) => {
    if (stream) {
        try {
            const tracks = stream.getTracks();
            for (const t of tracks) {
                if (t) {
                    t.stop();
                }
            }
        } catch (e) {
            janusVideoRoomLogger.error(e);
        }
    }
};
