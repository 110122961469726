import { t } from '@lingui/macro';
import { EPaths } from 'constants/EPaths';
import { submitInputUsernameFromThunkAction } from 'thunk/conference/submitInputUsernameFromThunkAction';
import { TAppDispatch } from 'types/app/TAppDispatch';
import { TAppState } from 'types/app/TAppStore';
import { IConferenceLocalStorageData } from 'types/IConferenceLocalStorageData';
import { getFromLocalStorage } from 'utils/localStorage/getFromLocalStorage';
import { IDispatchProps, IFields, IStateProps } from './types';

export const mapStateToProps = (state: TAppState): IStateProps => ({
    currentConferenceId: state.conferenceSession.currentConferenceId,
    currentConferencePin: state.conferenceSession.currentConferencePin,
});
export const mapDispatchToProps = (dispatch: TAppDispatch): IDispatchProps => ({
    getSubmitHandler: (successSubmitCallback) => (values: IFields) =>
        dispatch(
            submitInputUsernameFromThunkAction({
                successSubmitCallback,
                values,
            }),
        ),
});
export const getPathToLoginWithRedirectToConnect = ({ pin, id }: { pin?: string; id: string }) =>
    `/login?path=${pin ? `${EPaths.CONNECT_TO_CONFERENCE}/${id}` : `/enter/${id}`}`;

export const afterSubmit = (defaultCallback: () => void, userCallback?: () => void) => {
    if (userCallback) {
        userCallback();
        return;
    }
    defaultCallback();
};
export const getUsernameFromLocalStorage = (conferenceId: string): undefined | string => {
    const data = getFromLocalStorage(conferenceId) as IConferenceLocalStorageData;
    return data?.anonymousUsername;
};
export const getDefaultValuesFromLocalStorage = (conferenceId: string) => {
    return {
        username: getUsernameFromLocalStorage(conferenceId),
    };
};
export function handleValidate(values: IFields) {
    const errors: IFields = {};
    if (!values.username || values.username.trim() === '') {
        errors.username = t({ id: 'RequiredField' });
    }
    return errors;
}
