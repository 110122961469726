import { t } from '@lingui/macro';
import { resetConferencesListStoreAction } from 'actions/conferencesList/resetConferencesListStoreAction';
import { setConferenceIdsToShowAction } from 'actions/sessionSettings/setConferenceIdsToShowAction';
import { setConferenceListScrollOffsetAction } from 'actions/sessionSettings/setConferenceListScrollOffsetAction';
import { EPaths } from 'constants/EPaths';
import { format, parseISO } from 'date-fns';
import { getPendingOperationStatusSelector } from 'selectors/getPendingOperationStatusSelector';
import { deleteConferenceThunkAction } from 'thunk/conference/deleteConferenceThunkAction';
import { prepareToConnectToConferenceThunkAction } from 'thunk/conference/prepareToConnectToConferenceThunkAction';
import { setScrollOffsetAndRedirectToReportThunkAction } from 'thunk/conferenceReport/setScrollOffsetAndRedirectToReportThunkAction';
import { changeConferencesListFilterThunkAction } from 'thunk/conferences/changeConferencesListFilterThunkAction';
import { TAppDispatch } from 'types/app/TAppDispatch';
import { TAppState } from 'types/app/TAppStore';
import { IPastConferenceInfo } from 'types/conferencesList/IPastConferenceInfo';
import { IUpcomingConferenceInfo } from 'types/conferencesList/IUpcomingConferenceInfo';
import { EPredefinedDatesRange } from 'types/dates/EPredefinedDatesRange';
import { IDatesRange } from 'types/dates/IDatesRange';
import { IConferenceRecord } from 'types/IConferenceRecord';
import { IDateFilters } from 'types/IDateFilters';
import { convertFromSecondsToHoursMinutesSeconds } from 'utils/convertFromSecondsToHoursMinutesSeconds';
import { getDatesByPredefinedRange } from 'utils/dates/getDatesByPredefinedRange';
import { redirect } from 'utils/redirect';
import {
    dateTemplate,
    EConferencesGroup,
    IDispatchProps,
    IStateProps,
    TGroupContainerItem,
} from './types';

export const getGroupedConferences = (
    pastConferences: {
        [id: string]: IPastConferenceInfo;
    },
    upcomingConferences: {
        [id: string]: IUpcomingConferenceInfo;
    },
    finished: boolean,
    records: IConferenceRecord[],
    conferenceIdsToShow: string[],
): TGroupContainerItem[] =>
    finished
        ? getPastGroupedConferences(conferenceIdsToShow, pastConferences, records)
        : getUpcomingGroupedConferences(conferenceIdsToShow, upcomingConferences);

export const getPastGroupedConferences = (
    conferenceIdsToShow: string[],
    conferencesInfo: { [id: string]: IPastConferenceInfo },
    records: IConferenceRecord[],
): TGroupContainerItem[] => {
    const groupContainers = createGroupsForPastConferences();
    const existsId: { [id: string]: boolean } = {};
    for (const { dates, conferences } of groupContainers) {
        for (const id of conferenceIdsToShow) {
            const conference = conferencesInfo[id];
            if (conference === undefined || existsId[id]) {
                continue;
            }
            const dateTime = new Date(conference.timeStart).getTime();
            if (
                (dates.startDate === null || dates.startDate.getTime() <= dateTime) &&
                (dates.endDate === null || dateTime <= dates.endDate.getTime())
            ) {
                const conferenceRecords = records.filter((record) => record.sessionId === conference.id);
                const additionalConferenceData = {
                    formattedDuration: getDurationByStartEnd(
                        conference.timeStart,
                        conference.timeEnd,
                    ),
                    recordsCount: conferenceRecords.length,
                };
                conferences.push({ ...conference, ...additionalConferenceData });
                existsId[id] = true;
            }
        }
        conferences.sort(
            (a, b) => new Date(b.timeStart).getTime() - new Date(a.timeStart).getTime(),
        );
    }
    return groupContainers;
};
export function getUpcomingGroupedConferences(
    conferenceIdsToShow: string[],
    conferencesInfo: { [id: string]: IUpcomingConferenceInfo },
): TGroupContainerItem[] {
    const groupContainers = createGroupsForUpcomingConferences();
    const existsId: { [id: string]: boolean } = {};
    for (const { key, dates, conferences } of groupContainers) {
        if (key === EConferencesGroup.Started) {
            for (const id of conferenceIdsToShow) {
                const conference = conferencesInfo[id];
                if (conference !== undefined && conference.status === 'STARTED') {
                    conferences.push(conference);
                    existsId[id] = true;
                }
            }
            continue;
        }
        if (dates.startDate === null && dates.endDate === null) {
            continue;
        }
        for (const id of conferenceIdsToShow) {
            const conference = conferencesInfo[id];
            if (conference === undefined || existsId[id]) {
                continue;
            }
            const dateTime = new Date(conference.timeStart).getTime();
            if (
                (dates.startDate === null || dates.startDate.getTime() <= dateTime) &&
                (dates.endDate === null || dateTime <= dates.endDate.getTime())
            ) {
                conferences.push(conference);
                existsId[id] = true;
            }
        }
        conferences.sort(
            (a, b) => new Date(a.timeStart).getTime() - new Date(b.timeStart).getTime(),
        );
    }
    return groupContainers;
}
function createGroupsForPastConferences(): TGroupContainerItem[] {
    const groupBy: TGroupContainerItem[] = [];
    // groupBy.push({
    //     key: EConferencesGroup.Started,
    //     dates: getDatesByPredefinedRange(null, true),
    //     conferences: [],
    // });
    const todayDates = getDatesByPredefinedRange(EPredefinedDatesRange.Today, true);
    groupBy.push({
        key: EConferencesGroup.Today,
        dates: todayDates,
        conferences: [],
    });
    const yesterdayDates = getDatesByPredefinedRange(EPredefinedDatesRange.Yesterday, true);
    groupBy.push({
        key: EConferencesGroup.Yesterday,
        dates: yesterdayDates,
        conferences: [],
    });
    const thisWeekDates = getDatesByPredefinedRange(EPredefinedDatesRange.ThisWeek, true);
    groupBy.push({
        key: EConferencesGroup.ThisWeek,
        dates: thisWeekDates,
        conferences: [],
    });
    const lastWeekDates = getDatesByPredefinedRange(EPredefinedDatesRange.LastWeek, true);
    groupBy.push({
        key: EConferencesGroup.LastWeek,
        dates: lastWeekDates,
        conferences: [],
    });
    const thisMonthDates = getDatesByPredefinedRange(EPredefinedDatesRange.ThisMonth, true);
    groupBy.push({
        key: EConferencesGroup.ThisMonth,
        dates: thisMonthDates,
        conferences: [],
    });
    const lastMonthDates = getDatesByPredefinedRange(EPredefinedDatesRange.LastMonth, true);
    groupBy.push({
        key: EConferencesGroup.LastMonth,
        dates: lastMonthDates,
        conferences: [],
    });
    const pastStartDate = new Date(todayDates.endDate);
    pastStartDate.setDate(pastStartDate.getDate() - 180);
    const inPastDates: IDatesRange = {
        startDate: pastStartDate,
        endDate: new Date(todayDates.startDate),
    };
    groupBy.push({
        key: EConferencesGroup.InPast,
        dates: inPastDates,
        conferences: [],
    });
    return groupBy;
}

const createGroupsForUpcomingConferences = (): TGroupContainerItem[] => {
    const groupBy: TGroupContainerItem[] = [];
    groupBy.push({
        key: EConferencesGroup.Started,
        dates: getDatesByPredefinedRange(EPredefinedDatesRange.Today, false),
        conferences: [],
    });
    const todayDates = getDatesByPredefinedRange(EPredefinedDatesRange.Today, false);
    groupBy.push({
        key: EConferencesGroup.Today,
        dates: todayDates,
        conferences: [],
    });
    const tomorrowDates = getDatesByPredefinedRange(EPredefinedDatesRange.Tomorrow, false);
    groupBy.push({
        key: EConferencesGroup.Tomorrow,
        dates: tomorrowDates,
        conferences: [],
    });
    const thisWeekDates = getDatesByPredefinedRange(EPredefinedDatesRange.ThisWeek, false);
    groupBy.push({
        key: EConferencesGroup.ThisWeek,
        dates: thisWeekDates,
        conferences: [],
    });
    const nextWeekDates = getDatesByPredefinedRange(EPredefinedDatesRange.NextWeek, false);
    groupBy.push({
        key: EConferencesGroup.NextWeek,
        dates: nextWeekDates,
        conferences: [],
    });
    const thisMonthDates = getDatesByPredefinedRange(EPredefinedDatesRange.ThisMonth, false);
    groupBy.push({
        key: EConferencesGroup.ThisMonth,
        dates: thisMonthDates,
        conferences: [],
    });
    const nextMonthDates = getDatesByPredefinedRange(EPredefinedDatesRange.NextMonth, true);
    groupBy.push({
        key: EConferencesGroup.NextMonth,
        dates: nextMonthDates,
        conferences: [],
    });
    const futureEndDate = new Date(todayDates.startDate);
    futureEndDate.setDate(futureEndDate.getDate() + 180);
    const inFutureDates: IDatesRange = {
        startDate: new Date(todayDates.startDate),
        endDate: futureEndDate,
    };
    groupBy.push({
        key: EConferencesGroup.InFuture,
        dates: inFutureDates,
        conferences: [],
    });
    return groupBy;
};

export const getDurationByStartEnd = (timeStart?: string, timeEnd?: string) => {
    if (timeStart && timeEnd) {
        const diff = parseISO(timeEnd).getTime() - parseISO(timeStart).getTime();
        const rSeconds = Math.floor(diff / 1000);
        const { hours, minutes, seconds } = convertFromSecondsToHoursMinutesSeconds(rSeconds);
        const resultHours = hours < 10 ? `0${hours}` : `${hours}`;
        const resultMinutes = minutes < 10 ? `0${minutes}` : `${minutes}`;
        const resultSeconds = seconds < 10 ? `0${seconds}` : `${seconds}`;
        return `${resultHours}:${resultMinutes}:${resultSeconds}`;
    }
    return 'err';
};

export const mapStateToProps = (state: TAppState): IStateProps => ({
    pastConferences: state.conferencesList.pastConferences,
    upcomingConferences: state.conferencesList.upcomingConferences,
    filters: state.sessionSettings.conferencesFilter,
    recordsList: state.conferencesRecords.conferencesRecords,
    conferenceIdsToShow: state.sessionSettings.conferenceIdsToShow,
    scrollOffset: state.sessionSettings.conferenceListScrollOffset,
    currentUser: state.auth.currentUser,
    pastConferencesListOperationPending: getPendingOperationStatusSelector(
        state,
        'getPastConferencesListOperation',
        true,
    ),
    upcomingConferencesListOperationPending: getPendingOperationStatusSelector(
        state,
        'getUpcomingConferencesListOperation',
        true,
    ),
});

export const mapDispatchToProps = (dispatch: TAppDispatch): IDispatchProps => ({
    reset: () => {
        dispatch(resetConferencesListStoreAction());
        dispatch(setConferenceIdsToShowAction([]));
    },
    clearScrollOffset: () => dispatch(setConferenceListScrollOffsetAction(null)),
    changeFilter: (filters) => dispatch(changeConferencesListFilterThunkAction(filters)),
    prepareToConnectToConference: (conferenceId) =>
        dispatch(prepareToConnectToConferenceThunkAction({ conferenceId })),
    goToEditConference: (id) => redirect(`${EPaths.EDIT_CONFERENCE}/${id}`),
    deleteConference: (data) => {
        dispatch(deleteConferenceThunkAction(data));
    },
    goToConferenceHistory: (data) => dispatch(setScrollOffsetAndRedirectToReportThunkAction(data)),
});

export const isFilterSet = ({ finished, past, upcoming }: IDateFilters): boolean => {
    if (finished) {
        return past.from !== undefined || past.to !== undefined;
    }
    return upcoming.from !== undefined || upcoming.to !== undefined;
};

export const sliceYear = (date: string): string =>
    date.slice(0, date.length - 4) + date.slice(date.length - 2, date.length);

export function getFilterDates(dates: IDatesRange): { from: string; to: string } {
    if (dates.startDate && dates.endDate) {
        return {
            from: format(dates.startDate, dateTemplate),
            to: format(dates.endDate, dateTemplate),
        };
    }
    return { from: '', to: '' };
}
export function getGroupTitle(group: TGroupContainerItem): string {
    switch (group.key) {
        case EConferencesGroup.Started:
            return t({ id: 'Conferences.Started', message: 'Начатые' });
        case EConferencesGroup.Today:
            return t({ id: 'Conferences.Today', message: 'Сегодня' });
        case EConferencesGroup.Tomorrow:
            return t({ id: 'Conferences.Tomorrow', message: 'Завтра' });
        case EConferencesGroup.Yesterday:
            return t({ id: 'Conferences.Yesterday', message: 'Вчера' });
        case EConferencesGroup.ThisWeek:
            return t({ id: 'Conferences.ThisWeek', message: 'На этой неделе' });
        case EConferencesGroup.NextWeek:
            return t({ id: 'Conferences.NextWeek', message: 'На следующей неделе' });
        case EConferencesGroup.LastWeek:
            return t({ id: 'Conferences.LastWeek', message: 'На прошлой неделе' });
        case EConferencesGroup.ThisMonth:
            return t({ id: 'Conferences.ThisMonth', message: 'В этом месяце' });
        case EConferencesGroup.NextMonth:
            return t({ id: 'Conferences.NextMonth', message: 'В следующем месяце' });
        case EConferencesGroup.LastMonth:
            return t({ id: 'Conferences.LastMonth', message: 'В прошлом месяце' });
        case EConferencesGroup.InFuture:
            return t({ id: 'Conferences.InFuture', message: 'В будущем' });
        case EConferencesGroup.InPast:
            return t({ id: 'Conferences.InPast', message: 'В прошлом' });
    }
}
