import { janusLogger } from 'utils/logger';
import { janusCtx } from './janusCtx';

export const destroyJanusSession = () => {
    const janusSession = janusCtx.janusInstance;
    if (janusSession) {
        janusLogger.debug('Destroying Janus session ' + janusSession.getSessionId());
        janusSession.destroy({ unload: true, cleanupHandles: true });
    }
};
