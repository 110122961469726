import { PastConferenceControls } from './PastConferenceControls';
import { PastConferenceInfo } from './PastConferenceInfo';
import { PastConferenceParticipants } from './PastConferenceParticipants';
import styles from './PastConferences.module.scss';
import { IOwnProps } from './types';
import { redirect } from 'utils/redirect';
import { EPaths } from 'constants/EPaths';

export const PastConferences: React.FC<IOwnProps> = ({
    conferences,
    goToConferenceHistory,
    goToConnectConference,
    scrollableWrapper,
}) => (
    <>
        {conferences.map((conference) => {
            const handleConferenceHistory = () => {
                if (conference.conferenceId) {
                    goToConferenceHistory({
                        conferenceId: conference.conferenceId,
                        sessionId: conference.id,
                        offset: scrollableWrapper.current ? scrollableWrapper.current.scrollTop : 0,
                    });
                }
            };

            const handleStartAgain = () => {
                goToConnectConference(conference.conferenceId);
            };
            return (
                <div className={styles.conference} key={conference.id} id={conference.id}>
                    <PastConferenceInfo
                        conferenceName={conference.name}
                        timeStart={conference.timeStart}
                        timeEnd={conference.timeEnd}
                        recordsCount={conference.recordsCount}
                        conferenceDetailsHandler={() =>
                            redirect(`${EPaths.PAST_CONFERENCES}/${conference.conferenceId}/sessions/${conference.id}`)}
                    />
                    <div className={styles.conference__right}>
                        <PastConferenceParticipants participants={conference.participants} />
                        <PastConferenceControls
                            handleConferenceHistory={handleConferenceHistory}
                            handleStartAgain={conference.status !== 'DELETED' ? handleStartAgain : undefined}
                        />
                    </div>
                </div>
            );
        })}
    </>
);
{
    /* {recordDownloadItem &&
                                <div className={styles.conference__recordDownload}>
                                    <div className={styles.emptySpace}></div>
                                    <RecordDownloadBar item={recordDownloadItem} />
                                </div>
                            } */
}
