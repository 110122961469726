import { videoSettings } from 'constants/appSettingsStructure';
import { IAppSettingsTyped } from 'types/app/IAppSettings';
import { getAppSettingsDefaultValues } from 'utils/getAppSettingsDefaultValues';

export const getVideoSettingsFormInitialValues = (appSettings: IAppSettingsTyped | null) => {
    if (appSettings) {
        const { alwaysShowOwnStream, displayUsersFrameInfo, ownVideoOnConnect, resolution } =
            appSettings;
        return {
            alwaysShowOwnStream,
            displayUsersFrameInfo,
            ownVideoOnConnect,
            resolution,
        };
    }
    return getAppSettingsDefaultValues([videoSettings]);
};
