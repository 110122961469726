import { t } from '@lingui/macro';
import React, { FC } from 'react';
import styles from './HomePageSmallScreen.module.scss';
import { PageTemplate } from '../templates/PageTemplate';
import {CreateConfBlockConnected} from './CreateConfBlock';
import {JoinConfFormConnected} from 'components/forms/JoinConfForm';

export const HomePageSmallScreen:FC = () => {
    return (
        <PageTemplate showBcgImg={false} className={styles.template}>
            <div className={styles.root}>
                <div className={styles.text}>
                    <h3 className={styles.title}>
                        {t({
                            id: 'A CONVENIENT SOLUTION FOR BUSINESS',
                            message: 'УДОБНОЕ РЕШЕНИЕ ДЛЯ БИЗНЕСА',
                        })}
                    </h3>
                    <h2>
                        {t({
                            id: 'Make communication more accessible',
                            message: 'Сделай общение доступнее',
                        })}
                    </h2>
                </div>

                <div className={styles.content}>
                    <div>
                        <div className={styles.bgImage}></div>
                    </div>
                    <CreateConfBlockConnected className={styles.createBlock} isShortText/>
                </div>
                <div className={styles.joinConfBlock}>
                    <JoinConfFormConnected className={styles.joinContent} isShortText/>
                </div>
            </div>
        </PageTemplate>
    );
};
