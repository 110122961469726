import React from 'react';
import { IOwnProps } from './types';
import styles from './ProgressBar.module.scss';

export const ProgressBar: React.FC<IOwnProps> = ({ curValue, maxValue }) => {
    const progressPercent = curValue / maxValue * 100;
    const progressWidthStyle = { width: `${progressPercent > 100 ? 100 : progressPercent}%` };

    return (
        <div className={styles.progressBar}>
            <div className={styles.progressBar__progress} style={progressWidthStyle}></div>
        </div>
    );
};