import { connect } from 'react-redux';
import { createNewConferenceThunkAction } from 'thunk/conference/createNewConferenceThunkAction';
import { TAppDispatch } from 'types/app/TAppDispatch';
import { redirect } from 'utils/redirect';
import { MainMobileMenu } from './MainMobileMenu';
import { IDispatchProps } from './types';
import { TAppState } from 'types/app/TAppStore';
import { IStateProps } from 'pages/HomePage/CreateConfBlock/types';

const mapDispatchToProps = (dispatch: TAppDispatch): IDispatchProps => ({
    scheduleConference: () => redirect('/account/conferences/scheduling'),
    startConference: () => dispatch(createNewConferenceThunkAction()),
});

const mapStateToProps = (state: TAppState): IStateProps => ({
    disabled: state.applicationView.disabledStartConfButton,
});

export const MainMobileMenuConnected = connect(mapStateToProps, mapDispatchToProps)(MainMobileMenu);
