import { Trans } from '@lingui/react';
import { HorizontalLoader } from 'components/common/HorizontalLoader';
import { IOwnProps } from './types';
import styles from './LobbyLoader.module.scss';

export const LobbyLoader: React.FC<IOwnProps> = ({className}) => (
    <div className={`${styles.block} ${className || ''}`}>
        <HorizontalLoader color='white' />{' '}
        <div className={styles.text}>
            <Trans
                id='lobby.awaitResponse'
                message='Вы сможете подключиться к конференции сразу после того, как организатор даст разрешение'
            />
        </div>
    </div>
);
