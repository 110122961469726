import { t } from '@lingui/macro';
import { ReactComponent as CameraSvg } from 'assets/Camera.svg';
import { DeviceFieldConnected } from '..';
import styles from '../DeviceField.module.scss';

export const VideoDevice: React.FC<{ disabled: boolean }> = ({ disabled }) => {
    const deviceStatusText = {
        isOn: t({ id: 'video.on' }),
        isOff: t({ id: 'video.off' }),
    };
    return (
        <DeviceFieldConnected
            name='useVideo'
            Icon={CameraSvg}
            isDeviceDisabled={disabled}
            deviceStatusText={deviceStatusText}
            imgWrapperOffStyles={styles.deviceCheckbox__imgWrapper_videoOff}
        />
    );
};
