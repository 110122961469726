import { connect } from 'react-redux';
import { getConferenceRecordUrlThunkAction } from 'thunk/records/getConferenceRecordUrlThunkAction';
import { TAppDispatch } from 'types/app/TAppDispatch';
import { ConferenceRecordPlayer } from './ConferenceRecordPlayer';
import { IDispatchProps } from './types';

const mapDispatchToProps = (dispatch: TAppDispatch): IDispatchProps => ({
    getConferenceRecordData: (data) => dispatch(getConferenceRecordUrlThunkAction(data)),
});

export const ConferenceRecordPlayerConnected = connect(
    null,
    mapDispatchToProps,
)(ConferenceRecordPlayer);
