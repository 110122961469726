import { IConferenceCreator } from 'types/IConferenceCreator';
import { IAnonymousUserData } from 'types/user/IAnonymousUserData';
import { IUserData } from 'types/user/IUserData';

export const getDisplayName = (
    userData: IUserData | IConferenceCreator | IAnonymousUserData | null,
): string => {
    if (!userData) {
        return '';
    }

    if ('name' in userData) {
        if (typeof userData.name === 'object' && 'contact' in userData) {
            return userData.name?.first || userData?.name?.last
                ? `${userData.name.first || ''} ${userData.name.last || ''}`
                : userData?.contact.email;
        }
        if (typeof userData.name === 'string') {
            const { name } = userData;
            return name;
        }
    }

    if ('firstName' in userData) {
        return `${userData.firstName} ${userData.lastName}`;
    }
    
    return '';
};
