import { t } from '@lingui/macro';
import { Trans } from '@lingui/react';
import { Button } from 'components/common/Button';
import { Input } from 'components/common/Input';
import { StatusLabel } from 'components/common/StatusLabel';
import { FormApi } from 'final-form';
import React from 'react';
import { Form, FormRenderProps } from 'react-final-form';
import { getConferenceLinkParameters } from 'utils/conferences/getConferenceLinkParameters';
import { isConferenceLink } from 'utils/conferences/isConferenceLink';
import { isFormHasValidationErrors } from 'utils/isFormHasValidationErrors';
import styles from './ConferenceConnectionSettingsFormMobile.module.scss';
import { IFormProps, IOwnProps } from './types';

export const ConferenceConnectionSettingsFormMobile: React.FC<IOwnProps> = ({
    prepareToConferenceConnectMobile,
    initialValues,
}) => {
    const getInitialValues = () => {
        const result: IFormProps = {
            conferenceIdOrUrl: '',
            displayName: '',
            pin: '',
        };
        if (initialValues) {
            const { conferenceId, displayName, pin } = initialValues;
            if (conferenceId) {
                result.conferenceIdOrUrl = conferenceId;
            }
            if (displayName) {
                result.displayName = displayName;
            }
            if (pin) {
                result.pin = pin;
            }
        }
        return result;
    };

    const validate = (values: IFormProps) => {
        const errors: { [key: string]: string } = {};
        const requiredError = t({ id: 'RequiredField' });
        if (!values.conferenceIdOrUrl) {
            errors.conferenceIdOrUrl = requiredError;
        }
        if (!values.displayName) {
            errors.displayName = requiredError;
        }
        return errors;
    };

    const onSubmit = async (value: IFormProps) => {
        const response = await prepareToConferenceConnectMobile(value);
        if (response) {
            return {
                submit: response,
            };
        }
    };

    const pasteConferenceIdOrLink = (e: any, form: FormApi<IFormProps, Partial<IFormProps>>) => {
        const clipboardData = e.clipboardData;
        const pastedData = clipboardData.getData('Text');
        if (isConferenceLink(pastedData)) {
            const { pin, id } = getConferenceLinkParameters(pastedData.split('?')[1]);
            form.change('conferenceIdOrUrl', id);
            form.change('pin', pin);
            e.stopPropagation();
            e.preventDefault();
        }
    };

    const formRender = ({
        handleSubmit,
        submitting,
        submitErrors,
        dirtySinceLastSubmit,
        errors,
        form,
    }: FormRenderProps<IFormProps>) => (
        <form onSubmit={handleSubmit} className={styles.root}>
            <div className={styles.name}>
                <Trans id='Connect to conference' message='Присоединиться к конференции'></Trans>
            </div>
            <StatusLabel
                text={!dirtySinceLastSubmit && submitErrors?.submit ? submitErrors.submit : ''}
                className={styles.fail}
            />
            {(!initialValues || !initialValues?.conferenceId) && (
                <>
                    <div className={styles.annotation}>
                        <Trans
                            id='Conference id or personal link'
                            message='Идентификатор конференции или персональная ссылка'
                        />
                    </div>
                    <div>
                        <Input
                            placeholder={t({
                                id: 'Enter conference id',
                                message: 'Введите идентификатор конференции',
                            })}
                            name='conferenceIdOrUrl'
                            autoFocus={true}
                            styleType='mobile'
                            errorText={errors?.conferenceIdOrUrl}
                            touched={false}
                            handlePaste={(e) => {
                                pasteConferenceIdOrLink(e, form);
                            }}
                        />
                    </div>
                </>
            )}
            {(!initialValues || !initialValues?.displayName) && (
                <>
                    <div className={styles.annotation}>
                        <Trans id='displayedName' message='Отображаемое имя' />
                    </div>
                    <div>
                        <Input
                            placeholder={t({
                                id: 'displayedName',
                                message: 'Отображаемое имя',
                            })}
                            name='displayName'
                            errorText={errors?.displayName}
                            touched={false}
                            styleType='mobile'
                        />
                    </div>
                </>
            )}
            {(!initialValues || !initialValues?.pin) && (
                <>
                    <div className={styles.annotation}>
                        <Trans id='pin' message='Пин' />
                    </div>
                    <div>
                        <Input
                            placeholder={t({
                                id: 'conference.pin',
                                message: 'Пин конференции',
                            })}
                            name='pin'
                            styleType='mobile'
                        />
                    </div>
                </>
            )}
            <div>
                <Button
                    styleType='mobilePrimaryAction'
                    disabled={submitting || isFormHasValidationErrors(errors)}
                    type='submit'
                    tabIndex={2}
                    data-qa='joinConferenceFormButton'>
                    <Trans id='Join a conference' message='Присоединиться к конференции' />
                </Button>
            </div>
            {/* <div className={styles.annotation}>
                <Trans id='parameters' message='Параметры' />
            </div>
            <div className={styles.toggle}>
                <span>
                    <Trans id='Use.microphone' message='Включить микрофон' />
                </span>
                <div>
                    <Field name='useAudio'>
                        {({ input }) => (
                            <Toggle
                                value={input.value}
                                styleType='mobile'
                                onChangeValue={(value) => input.onChange(value)}
                            />
                        )}
                    </Field>
                </div>
            </div>
            <div className={styles.toggle}>
                <span>
                    <Trans id='Use.video' message='Включить камеру' />
                </span>
                <div>
                    <Field name='useVideo'>
                        {({ input }) => (
                            <Toggle
                                value={input.value}
                                styleType='mobile'
                                onChangeValue={(value) => input.onChange(value)}
                            />
                        )}
                    </Field>
                </div>
            </div> */}
        </form>
    );

    return (
        <Form
            onSubmit={onSubmit}
            validate={validate}
            render={formRender}
            initialValues={getInitialValues()}
        />
    );
};
