import { EChatMessageType } from 'constants/EChatMessageType';
import { useContext } from 'react';
import { IChatFile } from 'types/chat/IChatFile';
import { isImageFile } from 'utils/files/isImageFile';
import { ConferenceChatContext } from '../../../utils';
import styles from './ConferenceChatMessageContent.module.scss';
import { ConferenceChatMessageEmotion } from './ConferenceChatMessageEmotion/ConferenceChatMessageEmotion';
import { ConferenceChatMessageImage } from './ConferenceChatMessageImage/ConferenceChatMessageImage';
import { ConferenceChatMessageWithLinks } from './ConferenceChatMessageWithLinks/ConferenceChatMessageWithLinks';
import { ConferenceChatMessageMedia } from './ConferenceChatMessageMedia/ConferenceChatMessageMedia';
import { IOwnProps } from './types';

export const ConferenceChatMessageContent: React.FC<IOwnProps> = ({
    type,
    text,
    files,
    conferenceId,
    sessionId,
    authorId,
    time,
    isOwnMessage,
}) => {
    const { handleImageLoad } = useContext(ConferenceChatContext);

    const renderMessage = () => {
        if (text) {
            if (type === EChatMessageType.EMOTION) {
                return <ConferenceChatMessageEmotion text={text} />;
            }
            return <ConferenceChatMessageWithLinks text={text} />;
        }
    };

    const renderFiles = (files: IChatFile[]) => {
        return (
            <>
                {files.map(({ id, name, size }) => {
                    if (!isImageFile(name)) {
                        return (
                            <ConferenceChatMessageMedia
                                id={id}
                                conferenceId={conferenceId}
                                name={name}
                                size={size}
                                sessionId={sessionId}
                                key={id}
                                isOwnMessage={isOwnMessage}
                            />
                        );
                    }
                    return (
                        <ConferenceChatMessageImage
                            id={id}
                            name={name}
                            conferenceId={conferenceId}
                            sessionId={sessionId}
                            authorId={authorId}
                            time={time}
                            handleImageLoad={handleImageLoad}
                            key={id}
                        />
                    );
                })}
            </>
        );
    };

    const getMessageContainerStyles = () => {
        if (files?.length) {
            if (isImageFile(files[0].name)) {
                return styles.imgContainer;
            } else {
                return styles.mediaContainer;
            }
        }
        return styles.text;
    };
    return (
        <span className={getMessageContainerStyles()}>
            {renderMessage()}
            {files?.length && renderFiles(files)}
        </span>
    );
};
