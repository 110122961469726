import { INetworkService, IRequestResult } from 'types/api/INetworkService';
import { IAdminPanelUser } from 'admin-app/types/IAdminPanelUser';
import { EGetUsers } from 'admin-app/constants/EGetUsers';

interface IResponse {
    count: number;
    array: IAdminPanelUser[];
}
/**
 * @param search - login, email, firstName, lastName, id
 */
interface IPayload {
    search?: string;
    page?: number;
    pageSize?: number;
    activated?: boolean;
    sort?: {
        type?: 'asc' | 'desc';
        target: keyof IAdminPanelUser;
    };
}

export const getUsersApiCall = (
    networkService: INetworkService,
    { search, page, pageSize = EGetUsers.DEFAULT_PAGE_SIZE, sort, activated }: IPayload,
): Promise<IRequestResult<IResponse>> => {
    const requestPath = '/api/admin/users';
    const requestUrl = new window.URL(requestPath, window.location.origin);

    page && requestUrl.searchParams.append('page', `${page}`);
    pageSize && requestUrl.searchParams.append('pageSize', `${pageSize}`);
    sort && requestUrl.searchParams.append('sort', `${sort.target},${sort.type || 'asc'}`);
    search && requestUrl.searchParams.append('search', `${search}`);
    activated !== undefined && requestUrl.searchParams.append('activated', `${activated}`);

    return networkService.get({
        url: requestUrl.toString(),
    });
};
