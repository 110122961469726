import { EmotionContainer } from 'components/common/EmotionContainer/EmotionContainer';
import React from 'react';
import styles from './EmotionsList.module.scss';
import { IOwnProps } from './types';

export const EmotionsList: React.FC<IOwnProps> = ({ emotionsList, inChat, handleEmotionClick }) => (
    <div className={`${styles.wrapper} ${inChat ? styles.wrapper_inChat : ''}`}>
        <EmotionContainer style='bottom' direction={inChat ? 'column' : undefined}>
            {emotionsList.map(({ name, file }) => (
                <div className={styles.item} onClick={() => handleEmotionClick(name)} key={name}>
                    <img src={file.default} alt={name} />
                </div>
            ))}
        </EmotionContainer>
    </div>
);
