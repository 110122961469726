import { Trans } from '@lingui/react';
import { Avatar } from 'components/common/Avatar';
import { Button } from 'components/common/Button';
import React, { useRef, useState } from 'react';
import { getParticipantAvatar } from 'utils/participants/getParticipantAvatar';
import { getUserColor } from 'utils/users/getUserColor';
import { getUserInitials } from 'utils/users/getUserInitials';
import styles from './ConferenceMembersMobile.module.scss';
import { IDispatchProps, IStateProps, IOwnProps } from './types';
import { useGetCurrentParticipants } from '../VideoCallMembers/useGetCurrentParticipants';

export const ConferenceMembersMobile: React.FC<IStateProps & IDispatchProps & IOwnProps> = ({
    conferenceConnectInfo,
    creator,
    currentParticipants,
    currentParticipantsIds,
    invitedUsers,
    updateUsers,
    isConferenceAvailable,
    isGettingCurrentParticipants,
}) => {
    if (!creator) {
        return null;
    }
    const [activeTab, setActiveTab] = useState<'invited' | 'current'>('invited');
    const [maxParticipantsListMaxHeight, setMaxParticipantsListMaxHeight] = useState<number | null>(
        null,
    );
    const [startTimerToUpdateParticipants, stopTimerToUpdateParticipants] =
        useGetCurrentParticipants(updateUsers, isGettingCurrentParticipants);

    const participantsListRef = useRef<HTMLDivElement>(null);
    const timerToUpdateParticipants = useRef<NodeJS.Timeout | null>(null);

    const participants =
        activeTab === 'invited'
            ? invitedUsers
            : currentParticipantsIds.map((id) => currentParticipants[id]);
    const owner = participants.find((participant) => participant.creator);
    const creatorAvatar = owner ? getParticipantAvatar(owner) : '';
    const creatorInitials = getUserInitials(creator);
    const conferenceStarted = conferenceConnectInfo?.status === 'STARTED';
    const creatorName = `${creator.firstName} ${creator.lastName}`;

    const calculateParticipantsListMaxHeight = () => {
        const list = participantsListRef.current;
        if (!maxParticipantsListMaxHeight && list) {
            const parentDefaultHeight = list.offsetParent?.clientHeight;
            if (!parentDefaultHeight) {
                return;
            }
            setMaxParticipantsListMaxHeight(parentDefaultHeight - list.offsetTop);
        }
    };

    React.useEffect(() => {
        if (!conferenceStarted) {
            updateUsers(true);
        } else {
            if (!timerToUpdateParticipants.current) {
                startTimerToUpdateParticipants();
            }
        }
    }, [conferenceStarted, conferenceConnectInfo?.id]);

    React.useEffect(() => {
        calculateParticipantsListMaxHeight();
        return () => {
            stopTimerToUpdateParticipants();
        };
    }, []);

    const participantsListDynamicStyles = {
        maxHeight: maxParticipantsListMaxHeight ? `${maxParticipantsListMaxHeight}px` : 'auto',
    };
    //Need to calculate the max height of list without overflow
    const tempHideParticipants = !maxParticipantsListMaxHeight
        ? styles.participantsList_hidden
        : '';

    // When the invited list isn't empty, the owner is also included in this list
    // If the invited list empty, set the counter to 1 (owner)
    const invitedUsersCount = invitedUsers.length === 0 ? 1 : invitedUsers.length;

    return (
        <div className={styles.tabs}>
            <div className={styles.tabs__controls}>
                {isConferenceAvailable && (
                    <Button
                        styleType='common'
                        className={`${styles.tabs__controlBtn} ${
                            activeTab === 'current' ? styles.tabs__controlBtn_active : ''
                        }`}
                        clickHandler={() => setActiveTab('current')}>
                        <Trans id='connectToConference.tab.atConference' message='На конференции' />
                        <span>({conferenceStarted ? currentParticipantsIds.length : 0})</span>
                    </Button>
                )}

                <Button
                    styleType='common'
                    className={`${styles.tabs__controlBtn} ${
                        activeTab === 'invited' ? styles.tabs__controlBtn_active : ''
                    }`}
                    clickHandler={() => setActiveTab('invited')}>
                    <Trans id='connectToConference.tab.invited' message='Приглашенные' />
                    <span>({invitedUsersCount})</span>
                </Button>
            </div>
            <div
                className={`${styles.participantsList} ${tempHideParticipants}`}
                ref={participantsListRef}
                style={participantsListDynamicStyles}>
                {activeTab === 'invited' && (
                    <div className={styles.creator}>
                        <Avatar
                            image={creatorAvatar}
                            initials={creatorInitials}
                            style={{
                                color: 'white',
                                width: '36px',
                                height: '36px',
                                flexShrink: 0,
                            }}
                            userBgColor={getUserColor(creatorName)}
                        />
                        <div className={styles.participant__info}>
                            <div>{creatorName}</div>
                            <div>
                                <Trans id='conferenceOwner' message='Создатель' />
                            </div>
                        </div>
                    </div>
                )}
                {participants.map((user) => {
                    if (user.userId === creator.userId && activeTab === 'invited') {
                        return null;
                    }
                    const avatar = user?.userId ? getParticipantAvatar(user) : undefined;
                    const [firstName, lastName] = user.name.split(' ');
                    const initials = getUserInitials({
                        lastName,
                        firstName,
                        email: user?.email,
                    });
                    return (
                        <div className={styles.participant} key={user.participantId}>
                            <Avatar
                                image={avatar}
                                initials={initials}
                                style={{
                                    color: 'white',
                                    flexShrink: 0,
                                    height: '36px',
                                    width: '36px',
                                }}
                                userBgColor={getUserColor(user.name)}
                            />
                            <div className={styles.participant__info}>
                                {user.name.trim() || user.email}
                            </div>
                        </div>
                    );
                })}
            </div>
        </div>
    );
};
