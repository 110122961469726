import { createReducer } from '@reduxjs/toolkit';
import { storeConferenceReportAction } from 'actions/conferencesReports/storeConferenceReportAction';
import { resetStoreAction } from 'actions/resetStoreAction';
import { IConferenceReport } from 'types/conferenceReport/IConferenceReport';

export interface IConferencesReportsReducer {
    reports: IConferenceReport[];
}

export const conferencesReportsReducerInitialState: IConferencesReportsReducer = {
    reports: [],
};

export const conferencesReportsReducer = createReducer(
    conferencesReportsReducerInitialState,
    (builder) =>
        builder
            .addCase(storeConferenceReportAction, (state, { payload: conferenceReport }) => {
                state.reports.push(conferenceReport);
            })
            .addCase(resetStoreAction, () => conferencesReportsReducerInitialState),
);
