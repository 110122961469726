import { connect } from 'react-redux';
import { copyToClipboardThunkAction } from 'thunk/navigator/copyToClipboardThunkAction';
import { TAppDispatch } from 'types/app/TAppDispatch';
import { ConferenceShareDialogInviteLink } from './ConferenceShareDialogInviteLink';
import { IDispatchProps } from './types';

const mapDispatchToProps = (dispatch: TAppDispatch): IDispatchProps => ({
    copyToClipboard: (payload) => dispatch(copyToClipboardThunkAction(payload)),
});
export const ConferenceShareDialogInviteLinkConnected = connect(
    null,
    mapDispatchToProps,
)(ConferenceShareDialogInviteLink);
