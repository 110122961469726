import { connect } from 'react-redux';
import { TAppState } from 'types/app/TAppStore';
import { WebHooks } from './WebHooks';
import { IDispatchProps, IStateProps } from './types';
import { getWebHooksThunkAction } from 'admin-app/thunk/webHooks/getWebHooksThunkAction';
import { TAppDispatch } from 'types/app/TAppDispatch';

const mapStateToProps = (state: TAppState): IStateProps => ({
    hookList: state.admin.webHooks,
});

const mapDispatchToProps = (dispatch: TAppDispatch): IDispatchProps => ({
    getHookList: () => dispatch(getWebHooksThunkAction()).unwrap(),
});

export const WebHooksConnected = connect(mapStateToProps, mapDispatchToProps)(WebHooks);
