import { connect } from 'react-redux';
import { IStateProps, IOwnProps } from './types';
import { ConferenceChatMessages } from './ConferenceChatMessages';
import { TAppState } from 'types/app/TAppStore';

export const mapStateToProps = (store: TAppState): IStateProps => ({
    existAndExitParticipants: store.conferenceSession.existAndExitParticipants,
    isAnonymous: store.auth.anonymous,
    anonymId: store.janus.ownParticipantId,
    authUserId: store.auth.currentUser?.id,
    conferenceId: store.conferenceSession.currentConferenceId,
    sessionId: store.conferenceSession.currentSessionId,
    locale: store.auth.currentUser?.language,
});

const mergeProps = (stateProps: IStateProps, dispatchProps: null, ownProps: IOwnProps) => {
    const resultObj = { ...ownProps, ...stateProps }
    const sessionId =  ownProps?.sessionId ?  ownProps.sessionId : stateProps.sessionId ;
    const conferenceId =  ownProps?.conferenceId ?  ownProps.conferenceId : stateProps.conferenceId ;
    resultObj.sessionId = sessionId
    resultObj.conferenceId = conferenceId
    return resultObj;
};

export const ConferenceChatMessagesConnected = connect(
    mapStateToProps,
    null,
    mergeProps,
)(ConferenceChatMessages);
