import { t } from '@lingui/macro';
import ArrowLeftImg from 'assets/ArrowLeft.svg';
import navArrow from 'assets/CalendarArrow.svg';
import DatePickerImg from 'assets/DatePicker.svg';
import { SubmissionErrors } from 'final-form';
import { CSSProperties, useEffect, useRef, useState } from 'react';
import ReactDatePicker from 'react-datepicker';
import { Form, FormRenderProps } from 'react-final-form';
import { EPredefinedDatesRange } from 'types/dates/EPredefinedDatesRange';
import { IDatesRange } from 'types/dates/IDatesRange';
import { TLanguages } from 'types/TLanguages';
import { getCurrentDate } from 'utils/dates/getCurrentDate';
import { getDatesByPredefinedRange } from 'utils/dates/getDatesByPredefinedRange';
import { getLocaleByLanguage } from 'utils/getLocaleByLanguage';
import { logger } from 'utils/logger';
import { Button } from '../Button';
import '../DateFilter/DateFilter.css';
import { Input } from '../Input';
import OutsideClick from '../OutsideClick';
import { RadioButton } from '../RadioButton';
import style from './DateFilterType2.module.scss';
import { IDispatchProps, IFilter, IOwnProps, IStateProps } from './types';
import {
    getDateByStr,
    getDateStr,
    getFilterDefValue,
    getFilterTitle,
    getTitle,
    IncomingConferencesFilter,
    PastConferencesFilter,
} from './utils';

export const DateFilterType2: React.FC<IOwnProps & IStateProps & IDispatchProps> = ({
    maxDate,
    minDate,
    lang,
    changeFilter: handleDateChange,
    filter: filterFromState,
    isPast,
}) => {
    const [isShowRangeDialog, setIsShowRangeDialog] = useState(false);
    const [isShowDropdown, setIsShowDropdown] = useState(false);
    const [filter, setFilter] = useState<IFilter>(getFilterDefValue(filterFromState, isPast));
    const [rangeDialogDates, setRangeDialogDates] = useState<IDatesRange>(filter.dates);
    const [datesPopupStyle, setDatesPopupStyle] = useState<CSSProperties | undefined>();
    const listForm = useRef<HTMLFormElement>(null);
    const datesPopup = useRef<HTMLDivElement>(null);
    if (isPast && !maxDate) {
        maxDate = getCurrentDate();
    }
    if (!isPast && !minDate) {
        minDate = getCurrentDate();
    }
    useEffect(() => {
        setIsShowDropdown(false);
        setIsShowRangeDialog(false);
    }, [filter]);
    useEffect(() => {
        const popup = datesPopup.current;
        if (!isShowRangeDialog || !popup) {
            setDatesPopupStyle(undefined);
            return;
        }
        const rect = datesPopup.current.getBoundingClientRect();
        const pageHeight = document.documentElement.offsetHeight;
        if (rect.top + rect.height > pageHeight && pageHeight > rect.height) {
            setDatesPopupStyle({
                marginTop: Math.round(pageHeight - (rect.top + rect.height)) + 'px',
            });
            return;
        }
        setDatesPopupStyle(undefined);
    }, [isShowRangeDialog]);

    const renderDropdown = () => {
        const handleSubmit = (
            values: Record<string, any>,
        ): void | SubmissionErrors | Promise<SubmissionErrors> =>
            //setFilter({dates:});
            logger.log({ values });
        const handleChange = (evt: any) => {
            const predefinedRange = parseInt(evt.target.value) as EPredefinedDatesRange;
            const dates = getDatesByPredefinedRange(predefinedRange, isPast);
            const newFilter = {
                predefinedRange,
                dates,
            };
            setFilter(newFilter);
            setRangeDialogDates(dates);
            handleDateChange(dates, predefinedRange, isPast);
        };
        const rangeList = isPast ? PastConferencesFilter : IncomingConferencesFilter;
        return (
            <OutsideClick
                onOutsideClick={() => {
                    setIsShowDropdown(false);
                    setIsShowRangeDialog(false);
                }}>
                <div
                    className={style.popup}
                    style={{ background: 'red' }}
                    onClick={(evt) => evt.stopPropagation()}>
                    <div className={style.dropDown}>
                        <Form
                            initialValues={{ filterVariant: filter.predefinedRange }}
                            onSubmit={handleSubmit}
                            render={({ handleSubmit }: FormRenderProps) => {
                                return (
                                    <form
                                        ref={listForm}
                                        className={style.popupListForm}
                                        onSubmit={handleSubmit}
                                        onChange={handleChange}>
                                        <ul>
                                            {rangeList.map((variant) => (
                                                <li key={variant}>
                                                    <RadioButton
                                                        name='filterVariant'
                                                        value={variant}
                                                        id={`${variant}`}
                                                        text={getTitle(variant)}
                                                    />
                                                </li>
                                            ))}
                                            <li>
                                                <label
                                                    className={
                                                        filter.dates.startDate
                                                            ? `${style.chooseDates} ${style.sel}`
                                                            : style.chooseDates
                                                    }
                                                    onClick={() =>
                                                        setIsShowRangeDialog(!isShowRangeDialog)
                                                    }>
                                                    <span className={style.icon}>+</span>
                                                    <span className={style.title}>
                                                        {t({
                                                            id: 'DateFilter.chooseDates',
                                                            message: 'Выбрать даты',
                                                        })}
                                                    </span>
                                                </label>
                                            </li>
                                        </ul>
                                    </form>
                                );
                            }}
                        />
                        {isShowRangeDialog && datesFormRender()}
                    </div>
                </div>
            </OutsideClick>
        );
    };
    const datesFormRender = () => (
        <div className={style.datesPopup} ref={datesPopup} style={datesPopupStyle}>
            <ReactDatePicker
                open={true}
                selectsRange
                focusSelectedMonth
                shouldCloseOnSelect={false}
                renderCustomHeader={({ monthDate, decreaseMonth, increaseMonth }) =>
                    customHeader(lang, {
                        monthDate,
                        decreaseMonth,
                        increaseMonth,
                    })
                }
                renderDayContents={renderDayContents}
                selected={rangeDialogDates.startDate}
                startDate={rangeDialogDates.startDate}
                endDate={rangeDialogDates.endDate}
                locale={getLocaleByLanguage(lang)}
                onChange={([startDate, endDate]: [Date, Date]) => {
                    setRangeDialogDates({ startDate, endDate });
                }}
                minDate={minDate}
                maxDate={maxDate}
            />
            <Form
                initialValues={{
                    startDate: getDateStr(rangeDialogDates.startDate),
                    endDate: getDateStr(rangeDialogDates.endDate),
                }}
                onSubmit={(
                    values: Record<string, any>,
                ): void | SubmissionErrors | Promise<SubmissionErrors> => {
                    const startDate = getDateByStr(values.startDate);
                    const endDate = getDateByStr(values.endDate);
                    if (startDate && endDate) {
                        handleDateChange({ startDate, endDate }, undefined, isPast);
                        setIsShowDropdown(false);
                        setIsShowRangeDialog(false);
                        setFilter({
                            dates: {
                                startDate,
                                endDate,
                            },
                            predefinedRange: undefined,
                        });
                    }
                }}
                render={({ handleSubmit }: FormRenderProps) => {
                    return (
                        <form
                            className={style.popupDateForm}
                            onSubmit={handleSubmit}
                            // onReset={() => setDates(filter.dates)}
                        >
                            <div className={style.fields}>
                                <Input type='date' name='startDate' required={true} />
                                <img src={ArrowLeftImg} />
                                <Input type='date' name='endDate' required={true} />
                            </div>
                            <div className={style.buttons}>
                                <Button styleType={'text'} type='reset'>
                                    {t({
                                        id: 'DateFilter.cancel',
                                        message: 'Отменить',
                                    })}
                                </Button>
                                <Button styleType={'text'} type='submit'>
                                    {t({
                                        id: 'DateFilter.apply',
                                        message: 'Применить',
                                    })}
                                </Button>
                            </div>
                        </form>
                    );
                }}
            />
        </div>
    );
    return (
        <>
            <div className={style.chooseButton} onClick={() => setIsShowDropdown(!isShowDropdown)}>
                <img src={DatePickerImg} alt='Date picker' />
                <span>{getFilterTitle(filter)}</span>
            </div>
            {isShowDropdown && renderDropdown()}
        </>
    );
};
//todo replace this pattern and also other DatePicker(s)
const customHeader = (
    lang: TLanguages | undefined,
    {
        monthDate,
        decreaseMonth,
        increaseMonth,
    }: {
        monthDate: Date;
        decreaseMonth: () => void;
        increaseMonth: () => void;
    },
) => (
    <div className='react-datepicker__headerTop'>
        <button
            aria-label='Previous Month'
            className='react-datepicker__arrow'
            onClick={decreaseMonth}
            type='button'>
            <img src={navArrow} />
        </button>
        <span className='react-datepicker__current-month react-datepicker__current-month_center'>
            {monthDate.toLocaleString(lang || 'en', {
                month: 'long',
                year: 'numeric',
            })}
        </span>
        <button
            aria-label='Next Month'
            className='react-datepicker__navigation react-datepicker__navigation--next'
            onClick={increaseMonth}
            type='button'>
            <img className='react-datepicker__arrow_rotate' src={navArrow} />
        </button>
    </div>
);
const renderDayContents = (day: number) => {
    return <span className='react-datepicker__dayNumber'>{day}</span>;
};
