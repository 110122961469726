import { IOwnProps } from './types';
import styles from './ConferenceButton.module.scss';
import { classNames } from 'utils/classNames';

export const ConferenceButton: React.FC<IOwnProps> = ({
    children,
    className,
    clickHandler,
    img,
    mobile,
    text,
}) => (
    <button
        className={classNames([
            styles.button,
            className ? className : '',
            mobile ? styles.mobile : '',
        ])}
        onClick={clickHandler}
        type='button'>
        {children && children}
        {img && (
            <>
                <img src={img} alt='img' />
            </>
        )}
        {text && (
            <>
                <div className={classNames([styles.text, mobile ? styles.mobile : ''])}>{text}</div>
            </>
        )}
    </button>
);
