import { MobileWrapperConnected } from 'components/common/MobileWrapper';
import { RegistrationPage } from './RegistrationPage';
import { RegistrationPageMobile } from './RegistrationPageMobile';

export const RegistrationPageResponsive = () => (
    <MobileWrapperConnected
        renderDesktop={() => <RegistrationPage />}
        renderMobile={() => <RegistrationPageMobile />}
    />
);
