import { t } from '@lingui/macro';
import ConferencesActiveImg from 'assets/CameraBlue.svg';
import ConferencesInactiveImg from 'assets/CameraDarkGray.svg';
import ActionImg from 'assets/PlusInCircle.svg';
import ProfileActiveImg from 'assets/ProfileBlue.svg';
import ProfileInactiveImg from 'assets/ProfileDarkGray.svg';
import RecordsActiveImg from 'assets/RecordBlue.svg';
import RecordsInactiveImg from 'assets/RecordDarkGray.svg';
import SettingsActiveImg from 'assets/SettingsBlue.svg';
import SettingsInactiveImg from 'assets/SettingsDarkGray.svg';

export const getDesktopSections = () =>
    [
        { name: t({ id: 'Profile', message: 'Профиль' }), route: 'profile' },
        {
            name: t({ id: 'Conferences', message: 'Конференции' }),
            route: ['conferences/upcoming', 'conferences/past', 'conferences/scheduling'],
        },
        { name: t({ id: 'Records', message: 'Записи' }), route: 'records' },
        {
            name: t({ id: 'Settings', message: 'Настройки' }),
            route: ['settings/conference', 'settings/video', 'settings/audio', 'settings/dev'],
        },
        { name: t({ id: 'Exit', message: 'Выйти' }), route: 'Exit' },
    ].map((item) => {
        return { name: item.name, route: item.route };
    });

export const getMobileSections = () =>
    [
        {
            name: t({ id: 'Conferences', message: 'Конференции' }),
            img: { active: ConferencesActiveImg, inactive: ConferencesInactiveImg },
            route: ['conferences/upcoming', 'conferences/past', 'conferences/scheduling'],
        },
        {
            name: t({ id: 'Records', message: 'Записи' }),
            route: 'records',
            img: { active: RecordsActiveImg, inactive: RecordsInactiveImg },
        },
        { name: '', img: { active: '', inactive: ActionImg } },
        {
            name: t({ id: 'Profile', message: 'Профиль' }),
            route: 'profile',
            img: { active: ProfileActiveImg, inactive: ProfileInactiveImg },
        },

        {
            name: t({ id: 'Settings', message: 'Настройки' }),
            route: 'settings',
            img: { active: SettingsActiveImg, inactive: SettingsInactiveImg },
        },
    ].map((item) => {
        return { name: item.name, route: item.route, img: item.img };
    });
