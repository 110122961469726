import { TAppDispatch } from 'types/app/TAppDispatch';
import { IDispatchProps } from './types';
import { prepareToConnectToConferenceThunkAction } from 'thunk/conference/prepareToConnectToConferenceThunkAction';
import { copyToClipboardThunkAction } from 'thunk/navigator/copyToClipboardThunkAction';

export const mapDispatchToProps = (dispatch: TAppDispatch): IDispatchProps => ({
    prepareToConnectToConference: (conferenceId) =>
        dispatch(prepareToConnectToConferenceThunkAction({ conferenceId })),
    copyToClipboard: (payload) => dispatch(copyToClipboardThunkAction(payload)),
});
