import { createAsyncThunk } from '@reduxjs/toolkit';
import { IDeviceNotification } from 'types/IDeviceNotification';
import { storeDeviceNotification } from 'actions/conferences/storeDeviceNotification';

let timeoutId: NodeJS.Timeout;

export const showDeviceNotificationThunkAction = createAsyncThunk<void, IDeviceNotification>(
    'showDeviceNotificationThunkAction',
    async (notification, { dispatch }) => {
        if (timeoutId) {
            clearTimeout(timeoutId)
        }
        timeoutId = setTimeout(() => {
            dispatch(storeDeviceNotification(null));
        }, 3000);
        dispatch(storeDeviceNotification(notification))
    },
);
