import { createReducer } from '@reduxjs/toolkit';
import { closeRedirectConfirmationAction } from 'actions/applicationView/closeRedirectConfirmationAction';
import { modifyParticipantTalkingAction } from 'actions/applicationView/modifyParticipantTalkingAction';
import { resetPendingConfirmationAction } from 'actions/applicationView/resetPendingConfirmationAction';
import { setConferenceExitConfirmationDialogVisibilityAction } from 'actions/applicationView/setConferenceExitConfirmationDialogVisibilityAction';
import { setConferenceInfoFrameVisibility } from 'actions/applicationView/setConferenceInfoFrameVisibility';
import { setCurrentLanguageAction } from 'actions/applicationView/setCurrentLanguageAction';
import { setDevicePropsAction } from 'actions/applicationView/setDevicePropsAction';
import { setDisabledStartConfButtonAction } from 'actions/applicationView/setDisabledStartConfButtonAction';
import { setDisplayLayoutAction } from 'actions/applicationView/setDisplayLayoutAction';
import { setFormEditFlagAction } from 'actions/applicationView/setFormEditFlagAction';
import { setFrameToFullscreenAction } from 'actions/applicationView/setFrameToFullscreenAction';
import { setLoginDialogVisibilityAction } from 'actions/applicationView/setLoginDialogVisibilityAction';
import { setNotificationForDisplayAction } from 'actions/applicationView/setNotificationForDisplayAction';
import { setParticipantEmotionToShowAction } from 'actions/applicationView/setParticipantEmotionToShowAction';
import { setParticipantLastActivityTimeAction } from 'actions/applicationView/setParticipantLastActivityTime';
import { setParticipantLocalMuteStatusAction } from 'actions/applicationView/setParticipantLocalMuteStatusAction';
import { setParticipantsFullScreenAction } from 'actions/applicationView/setParticipantsFullScreenAction';
import { setParticipantsLayoutPropertiesAction } from 'actions/applicationView/setParticipantsLayoutPropertiesAction';
import { setParticipantsListOpenAction } from 'actions/applicationView/setParticipantsListOpenAction';
import { setPendingOperationAction } from 'actions/applicationView/setPendingOperationAction';
import { setShowRedirectConfirmationAction } from 'actions/applicationView/setShowRedirectConfirmationAction';
import { setViewSettingsAction } from 'actions/applicationView/setViewSettings';
import { showAudioDialogPermissionAction } from 'actions/applicationView/showAudioDialogPermissionAction';
import { toggleConferenceChatAction } from 'actions/applicationView/toggleConferenceChatAction';
import { updateParticipantsFrameAction } from 'actions/applicationView/updateParticipantsFrameAction';
import { setAppSettingsAction } from 'actions/auth/setAppSettingsAction';
import { setCurrentUserDataAction } from 'actions/auth/setCurrentUserDataAction';
import { storeCurrentConferenceParticipantsAction } from 'actions/conferences/storeCurrentConferenceParticipantsAction';
import { addJanusPublisherAction } from 'actions/janus/addJanusPublisherAction';
import { removeJanusPublisherAction } from 'actions/janus/removeJanusPublisherAction';
import { resetJanusStoreAction } from 'actions/janus/resetJanusStoreAction';
import { resetOwnStreamAction } from 'actions/janus/resetOwnStreamAction';
import { saveOwnParticipantIdAction } from 'actions/janus/saveOwnParticipantIdAction';
import { setJanusStreamPropsAction } from 'actions/janus/setJanusStreamPropsAction';
import { setParticipantMuteStatusAction } from 'actions/janus/setParticipantMuteStatusAction';
import { resetStoreAction } from 'actions/resetStoreAction';
import { EApplicationViewThunkActions } from 'constants/thunk/EApplicationViewThunkActions';
import { EConferenceThunkActions } from 'constants/thunk/EConferenceThunkActions';
import { APPLICATION_VIEW_REDUCER_INITIAL_STATE } from 'reducers/applicationViewReducer.const';
import { getDummyThunk } from 'utils/getDummyThunk';
import {
    addOrModifyJanusPublisher,
    calculateFramesLayout,
    calculateParticipantFrameSize,
    calculateParticipantsLayoutSize,
    clearEmotionTimer,
    frameExists,
    getLastTalkingUserOrItself,
    participantMediaWasChanged,
} from './utils/applicationViewReducerUtils';
import {saveChatDraftMessageAction} from '../actions/chat/saveChatDraftMessageAction';

export const applicationViewReducer = createReducer(
    APPLICATION_VIEW_REDUCER_INITIAL_STATE,
    (builder) =>
        builder
            .addCase(setLoginDialogVisibilityAction, (state, { payload: visibility }) => {
                state.showLoginDialog = visibility;
            })
            .addCase(setConferenceInfoFrameVisibility, (state, { payload: visibility }) => {
                state.showConferenceInfoFrame = visibility;
            })
            .addCase(setNotificationForDisplayAction, (state, { payload: notification }) => {
                state.notificationToShow = notification;
            })
            .addCase(setFrameToFullscreenAction, (state, { payload: frameId }) => {
                // Check that frameId is exist
                if (frameId && !frameExists(state, frameId)) {
                    state.supposedFullScreen = frameId;
                    return;
                }
                if (frameId) {
                    state.fullScreenFrames = [frameId];
                } else {
                    state.fullScreenFrames = [];
                }
                calculateFramesLayout(state);
            })
            .addCase(setParticipantsFullScreenAction, (state, { payload: { local, global } }) => {
                if (global) {
                    state.participantsFullScreenGlobal = global;
                    state.fullScreenFrames = global;
                    global.forEach((id) => {
                        const muteStatus = state.participantsLocalMuteStatus[id];
                        if (muteStatus && muteStatus.video === true) {
                            muteStatus.video = false;
                        }
                    });
                }
                if (local) {
                    state.participantsFullScreen = local;
                    state.fullScreenFrames = local;
                    local.forEach((id) => {
                        const muteStatus = state.participantsLocalMuteStatus[id];
                        if (muteStatus && muteStatus.video === true) {
                            muteStatus.video = false;
                        }
                    });
                }
                calculateFramesLayout(state);
            })
            .addCase(setParticipantsListOpenAction, (state, { payload: listState }) => {
                if (!state.deviceProps.isTabletOrMobile) {
                    calculateParticipantsLayoutSize({ state, isParticipantsList: listState });
                    calculateParticipantFrameSize(state);
                }
                state.participantListOpen = listState;
            })
            .addCase(
                setParticipantEmotionToShowAction,
                (state, { payload: { emotionToShow, participantId } }) => {
                    state.participantsEmotion[participantId] = emotionToShow;
                },
            )
            .addCase(
                modifyParticipantTalkingAction,
                (state, { payload: { participantId, talking } }) => {
                    const currentTalkingStatus = state.participantsTalking[participantId];
                    state.participantsTalking[participantId] = talking ?? currentTalkingStatus;
                },
            )
            .addCase(
                setConferenceExitConfirmationDialogVisibilityAction,
                (state, { payload: visibility }) => {
                    state.showConferenceExitConfirmationDialog = visibility;
                },
            )
            .addCase(resetStoreAction, (state) => {
                clearEmotionTimer(state);
                return APPLICATION_VIEW_REDUCER_INITIAL_STATE;
            })
            .addCase(
                addJanusPublisherAction,
                (state, { payload: { participantId, audioVideoProps } }) => {
                    addOrModifyJanusPublisher(state, participantId, audioVideoProps);
                    calculateFramesLayout(state);
                },
            )
            .addCase(resetJanusStoreAction, (state) => {
                state.participantFrames = [];
                state.displayedVideoFrames = [];
                state.displayedAudioFrames = [];
                state.participantsFullScreen = [];
                state.participantsFullScreenGlobal = [];
                state.fullScreenFrames = [];
                state.participantsAudio = {};
                state.participantsVideo = {};
                state.lastActivity = {};
                state.displayLayout = 'default';
                calculateFramesLayout(state);
            })
            .addCase(saveOwnParticipantIdAction, (state, { payload: participantId }) => {
                state.ownParticipantId = participantId;
                if (!state.participantFrames.some((id) => id === participantId)) {
                    state.participantFrames.push(participantId);
                }
                calculateFramesLayout(state);
            })
            .addCase(removeJanusPublisherAction, (state, { payload: idForRemove }) => {
                delete state.participantsAudio[idForRemove];
                delete state.participantsVideo[idForRemove];
                if (state.fullScreenFrames[0] === idForRemove) {
                    state.fullScreenFrames = [];
                }
                const fullScreenFramesIdx = state.fullScreenFrames.indexOf(idForRemove);
                if (fullScreenFramesIdx !== -1) {
                    state.fullScreenFrames.splice(fullScreenFramesIdx, 1);
                }
                const participantFramesIdx = state.participantFrames.indexOf(idForRemove);
                if (participantFramesIdx !== -1) {
                    state.participantFrames.splice(participantFramesIdx, 1);
                }
                calculateFramesLayout(state);
            })
            .addCase(
                setParticipantMuteStatusAction,
                (state, { payload: { participantId, audioVideoProps } }) => {
                    participantMediaWasChanged(state, participantId, audioVideoProps);
                    calculateFramesLayout(state);
                },
            )
            .addCase(
                setParticipantLocalMuteStatusAction,
                (
                    state,
                    { payload: { participantId, muteStatus, type, publisherAudioVideoProps } },
                ) => {
                    const currentParticipantData = state.participantsLocalMuteStatus[participantId];
                    if (currentParticipantData) {
                        state.participantsLocalMuteStatus[participantId][type] = muteStatus;
                    } else {
                        state.participantsLocalMuteStatus[participantId] = {
                            [type]: muteStatus,
                        };
                    }
                    participantMediaWasChanged(state, participantId, publisherAudioVideoProps);
                    calculateFramesLayout(state);
                },
            )
            .addCase(setParticipantLastActivityTimeAction, (state, { payload: { id, time } }) => {
                state.lastActivity[id] = time;
                if (state.displayLayout === 'talking' && state.fullScreenFrames[0] !== id) {
                    state.participantsFullScreen = [id];
                    state.fullScreenFrames = [id];
                }
                calculateFramesLayout(state);
            })
            .addCase(
                setViewSettingsAction,
                (state, { payload: { maxDisplayedParticipants: maxParticipants } }) => {
                    state.maxVideoFrames = maxParticipants;
                    calculateFramesLayout(state);
                },
            )
            .addCase(setJanusStreamPropsAction, (state, { payload }) => {
                if (state.ownParticipantId) {
                    participantMediaWasChanged(state, state.ownParticipantId, payload);
                    if (
                        payload.screenShared &&
                        state.fullScreenFrames[0] === state.ownParticipantId
                    ) {
                        state.participantsFullScreen = [];
                        state.fullScreenFrames = [];
                    }
                    calculateFramesLayout(state);
                }
            })
            .addCase(setDevicePropsAction, (state, { payload }) => {
                state.deviceProps = payload;
            })
            .addCase(setPendingOperationAction, (state, { payload: { operation, pending } }) => {
                state.pendingOperations[operation] = pending;
            })
            .addCase(storeCurrentConferenceParticipantsAction, (state, { payload }) => {
                if (Object.keys(payload).length > 1) {
                    state.showConferenceInfoFrame = false;
                }
            })
            .addCase(setDisplayLayoutAction, (state, { payload: displayLayout }) => {
                if (state.displayLayout !== displayLayout) {
                    if (displayLayout === 'default') {
                        state.participantsFullScreen = [];
                        state.fullScreenFrames = [];
                    } else {
                        const participantId = getLastTalkingUserOrItself(state);
                        state.participantsFullScreen = [participantId];
                        state.fullScreenFrames = [participantId];
                    }
                    state.displayLayout = displayLayout;
                    calculateFramesLayout(state);
                }
            })
            .addCase(setCurrentLanguageAction, (state, { payload: language }) => {
                state.currentLanguage = language;
            })
            .addCase(
                setShowRedirectConfirmationAction,
                (state, { payload: { awaitUserConfirmation, redirectData } }) => {
                    state.pendingConfirmation.awaitUserConfirmation = awaitUserConfirmation;
                    state.pendingConfirmation.redirectData = redirectData;
                },
            )
            .addCase(setFormEditFlagAction, (state, { payload }) => {
                state.pendingConfirmation.isFormEditing = payload;
            })
            .addCase(closeRedirectConfirmationAction, (state, { payload: isClearState }) => {
                state.pendingConfirmation.awaitUserConfirmation = false;
                if (isClearState) {
                    state.pendingConfirmation.isFormEditing = false;
                    delete state.pendingConfirmation.redirectData;
                }
            })
            .addCase(resetPendingConfirmationAction, (state) => {
                state.pendingConfirmation.isFormEditing = false;
                delete state.pendingConfirmation.redirectData;
            })
            .addCase(setCurrentUserDataAction, (state, { payload: userData }) => {
                if (!userData) {
                    state.alwaysShowOwnStream = true;
                    calculateFramesLayout(state);
                }
            })
            .addCase(setAppSettingsAction, (state, { payload: appSettings }) => {
                state.alwaysShowOwnStream = !!appSettings?.alwaysShowOwnStream;
            })
            .addCase(showAudioDialogPermissionAction, (state, { payload }) => {
                state.showAudioPermissionDialog = payload;
            })
            .addCase(toggleConferenceChatAction, (state, { payload: chatState }) => {
                if (!state.deviceProps.isTabletOrMobile) {
                    calculateParticipantsLayoutSize({ state, isShowChat: chatState });
                    calculateParticipantFrameSize(state);
                }
                state.showChat = chatState;
            })
            .addCase(updateParticipantsFrameAction, (state, { payload: { width, height, id } }) => {
                state.participantsFrameSize[id] = { width, height };
                calculateParticipantFrameSize(state);

            })
            .addCase(setParticipantsLayoutPropertiesAction, (state, { payload }) => {
                state.participantsLayoutProperties = payload;
                calculateParticipantFrameSize(state);
            })
            .addCase(setDisabledStartConfButtonAction, (state, { payload }) => {
                state.disabledStartConfButton = payload;
            })
            .addCase(
                getDummyThunk(EConferenceThunkActions.CREATE_NEW_CONFERENCE).rejected,
                (state) => {
                    if (state.disabledStartConfButton) {
                        state.disabledStartConfButton = false;
                    }
                },
            )
            .addCase(
                getDummyThunk(EConferenceThunkActions.EXIT_FROM_CONFERENCE).fulfilled,
                (state) => {
                    if (state.participantListOpen) {
                        state.participantListOpen = false;
                    }
                    if (state.showChat) {
                        state.showChat = false;
                    }
                    state.showConferenceExitConfirmationDialog = false;
                },
            )
            .addCase(
                getDummyThunk(EApplicationViewThunkActions.SET_FRAME_TO_FULLSCREEN).fulfilled,
                (state) => {
                    if (
                        state.deviceProps.isTabletOrMobile &&
                        state.participantListOpen &&
                        state.fullScreenFrames[0]
                    ) {
                        state.participantListOpen = false;
                    }
                },
            )
            .addCase(resetOwnStreamAction, (state) => {
                if (state.ownParticipantId) {
                    state.participantsAudio[state.ownParticipantId] = false;
                    state.participantsVideo[state.ownParticipantId] = false;
                    state.participantsScreenShared[state.ownParticipantId] = false;
                    if (state.fullScreenFrames[0] === state.ownParticipantId) {
                        state.fullScreenFrames = [];
                        state.participantsFullScreen = [];
                    }
                    calculateFramesLayout(state);
                }
            }).addCase(saveChatDraftMessageAction, (state, {payload: draftChatMessage}) => {
                state.draftChatMessage = draftChatMessage;
            })
    ,
);
