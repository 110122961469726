import { createReducer } from '@reduxjs/toolkit';
import { setApplicationConfigAction } from 'actions/applicationConfiguration/setApplicationConfigAction';
import { resetStoreAction } from 'actions/resetStoreAction';
import { IApplicationConfig } from 'types/app/IApplicationConfig';

export interface IApplicationConfigurationReducer {
    config: IApplicationConfig;
}
export const appConfigurationReducerInitialState: IApplicationConfigurationReducer = {
    config: {
        emailConfirmation: false,
        ldap: false,
        maxAvatarFileSizeByte: 0,
        maxMediaFileSizeByte: 0,
        version: '0.0.1-39752.202207251300',
        videoStreamingUrl: '',
        maxMessageLength: 0,
        maxPublishers: 25,
        conferenceModelRestrictions: {
            name: 0,
            description: 0,
            pin: 0,
        },
    },
};

export const applicationConfigurationReducer = createReducer(
    appConfigurationReducerInitialState,
    (builder) =>
        builder
            .addCase(setApplicationConfigAction, (state, { payload: config }) => {
                state.config = config;
            })
            .addCase(resetStoreAction, () => appConfigurationReducerInitialState),
);
