import { t } from '@lingui/macro';
import { getStartDateTime } from 'components/ConferenceScheduleSettingsForm/utils';
import { addMonths, compareAsc, differenceInCalendarMonths, format, parse } from 'date-fns';
import { ValidationErrors } from 'final-form';
import { IRecurringData } from 'types/conferences/IRecurringData';
import { TStep } from 'types/conferences/TStep';
import { getCurrentDate } from 'utils/dates/getCurrentDate';
import { getCurrentDateWithTimezone } from 'utils/dates/getCurrentDateWithTimezone';
import { getCurrentTimeByTemplate } from 'utils/dates/getCurrentTimeByTemplate';

export const dateFormat = 'dd.MM.yyyy';
export const mainFormField = 'recurringData';
export const invalidFormat = 'invalidFormat';
const resultDateRegex = /^[0-9]{2}\.[0-9]{2}\.[0-9]{4}$/;
const resultTimeRegex = /^([0-1]?[0-9]|2[0-3]):[0-5][0-9]$/;

export const getMinDate = () => getCurrentDateWithTimezone();

export const getMaxDate = (startDate: string | undefined) => {
    if (!startDate) {
        return getMinDate();
    }
    return addMonths(parse(startDate, dateFormat, getCurrentDate()), 6);
};

export const basicValidation = (
    formValues: IRecurringData,
    errorsObj: { [key: string]: string },
) => {
    if (!formValues.startDate || !formValues.startDate.match(resultDateRegex)) {
        errorsObj.startDate = 'Required';
    }
    if (!formValues.endDate || !formValues.endDate.match(resultDateRegex)) {
        errorsObj.endDate = 'Required';
    }
    if (!formValues.time || !formValues.time.match(resultTimeRegex)) {
        errorsObj.time = 'Required';
    }
};

export const validateDateString = (dateString: string) => {
    const dateParts = dateString.split('.');
    const day = dateParts[0];
    const month = dateParts[1];

    if (day[0] !== '0') {
        if (Number(day) > 31) {
            return 'invalidFormatDay';
        }
    } else if (day === '00') {
        return 'invalidFormatDay';
    }

    if (month[0] !== '0') {
        if (Number(month) > 12) {
            return 'invalidFormatMonth';
        }
    } else if (month === '00') {
        return 'invalidFormatMonth';
    }
    return '';
};

export const checkDates = (startDate: string, endDate: string) => {
    const resultValidationStartDate = validateDateString(startDate);
    const resultValidationEndDate = validateDateString(endDate);
    if (resultValidationStartDate) {
        return { startDate: resultValidationStartDate, endDate: null };
    }
    if (resultValidationEndDate) {
        return { startDate: null, endDate: resultValidationEndDate };
    }

    const parseStartDate = parse(startDate, dateFormat, getCurrentDate());
    const parseEndDate = parse(endDate, dateFormat, getCurrentDate());
    if (parseStartDate.toString() === 'Invalid Date') {
        return { startDate: invalidFormat, endDate: null };
    }
    if (parseEndDate.toString() === 'Invalid Date') {
        return { startDate: null, endDate: invalidFormat };
    }

    const calculatedMaxDate = addMonths(parse(startDate, dateFormat, getCurrentDate()), 6);
    const currentDateWithoutTime = getCurrentDate();
    currentDateWithoutTime.setHours(0, 0, 0, 0);
    let isValidStartDate = '';
    let isValidEndDate = '';

    if (compareAsc(parseStartDate, currentDateWithoutTime) < 0) {
        isValidStartDate = 'minDate';
    } else if (
        compareAsc(
            parseStartDate,
            currentDateWithoutTime.setFullYear(currentDateWithoutTime.getFullYear() + 1),
        ) === 1
    ) {
        isValidStartDate = 'invalidFormatYear';
    }

    if (compareAsc(parseEndDate, calculatedMaxDate) === 1) {
        isValidEndDate = 'maxRange';
    } else if (compareAsc(parseEndDate, parseStartDate) === 0) {
        isValidEndDate = 'startEqualEnd';
    } else if (compareAsc(parseEndDate, parseStartDate) < 0) {
        isValidEndDate = 'minDate';
    }

    return { startDate: isValidStartDate, endDate: isValidEndDate };
};

export const checkDatesOnChange = (startDate: string) => {
    const parseStartDate = parse(startDate, dateFormat, getCurrentDate());
    if (parseStartDate.toString() === 'Invalid Date') {
        return { startDate: null };
    }

    if (differenceInCalendarMonths(parseStartDate, getCurrentDate()) >= 6) {
        return { startDate: 'tooFarDate' };
    }

    return { startDate: null };
};

export const datesValidation = (
    formStartDate: string,
    formEndDate: string,
    errorsObj: { [key: string]: string },
) => {
    const { startDate, endDate } = checkDates(formStartDate, formEndDate);

    if (startDate === 'minDate') {
        errorsObj.startDate = 'minDate';
    } else if (startDate === invalidFormat) {
        errorsObj.startDate = 'Required';
    } else if (startDate) {
        errorsObj.startDate = startDate;
    }

    if (endDate === 'maxRange') {
        errorsObj.endDate = 'maxRange';
    } else if (endDate === invalidFormat) {
        errorsObj.endDate = 'Required';
    } else if (endDate === 'startEqualEnd') {
        errorsObj.endDate = 'Equal';
    } else if (endDate) {
        errorsObj.endDate = endDate;
    }
};

export const datesValidationOnChange = (
    formStartDate: string,
    errorsObj: { [key: string]: string },
) => {
    const { startDate } = checkDatesOnChange(formStartDate);

    if (startDate === 'tooFarDate') {
        errorsObj.startDate = t({
            id: 'incorrect.date',
            message: 'Проверьте корректность даты',
        });
    }
};

export const getStatusLabelText = (errors: ValidationErrors) => {
    const generalDateErrors: { [key: string]: string } = {
        invalidFormatDay: t({
            id: 'repeat.dialog.error.date.dayFormat',
            message: 'Выберите корректный день',
        }),
        invalidFormatMonth: t({
            id: 'repeat.dialog.error.date.monthFormat',
            message: 'Выберите корректный месяц',
        }),
        invalidFormatYear: t({
            id: 'repeat.dialog.error.date.yearFormat',
            message: 'Вы можете выбрать только {year} или {secondYear} год',
            values: {
                year: getCurrentDate().getFullYear(),
                secondYear: getCurrentDate().getFullYear() + 1,
            },
        }),
        minDate: t({
            id: 'repeat.dialog.error.date.min',
            message: 'Нельзя выбрать дату в прошлом',
        }),
    };

    const startDateError = errors?.startDate;
    const endDateError = errors?.endDate;
    if (generalDateErrors[startDateError] || generalDateErrors[endDateError]) {
        return generalDateErrors[startDateError] || generalDateErrors[endDateError];
    }

    if (endDateError === 'maxRange') {
        return t({
            id: 'repeat.dialog.error.endDate.range',
            message: 'Максимальный период повтора конференции - 6 месяцев',
        });
    } else if (endDateError === 'Equal') {
        return t({
            id: 'repeat.dialog.error.dates.equal',
            message: 'Даты не могут совпадать',
        });
    }

    return '';
};

export const getInitialValues = (
    recurringData: IRecurringData | null,
    selectedOption: TStep,
    specifiedDateAndTime: { specifiedDate: string; specifiedTime: string },
): IRecurringData => {
    const currentDate = getCurrentDate();
    const { startTime } = getStartDateTime(currentDate);
    const resultStartDate =
        specifiedDateAndTime.specifiedDate || getCurrentTimeByTemplate(dateFormat);
    const initialValues = {
        startDate: resultStartDate,
        endDate: format(getMaxDate(resultStartDate), dateFormat),
        count: '1',
        step: 'week',
        days: [],
        time: specifiedDateAndTime.specifiedTime || startTime,
    };
    if (recurringData) {
        return selectedOption === recurringData.step
            ? recurringData
            : { ...initialValues, step: selectedOption };
    }
    return { ...initialValues, step: selectedOption };
};

interface IValidateFormValuesParams {
    values: IRecurringData;
    recurringStartDate?: string;
    recurringEndDate?: string;
}

export const validateFormValues = ({
    values,
    recurringStartDate,
    recurringEndDate,
}: IValidateFormValuesParams) => {
    const errors: { [key: string]: string } = {};
    basicValidation(values, errors);

    if (
        recurringStartDate &&
        recurringEndDate &&
        recurringStartDate === values.startDate &&
        recurringEndDate === values.endDate
    ) {
        return errors;
    }

    if (!errors?.startDate && !errors?.endDate) {
        datesValidation(values.startDate, values.endDate, errors);
    }
    return errors;
};

export const validateFormValuesOnChange = ({
    values,
    recurringStartDate,
}: IValidateFormValuesParams) => {
    const warnings: { [key: string]: string } = {};

    if (recurringStartDate && recurringStartDate === values.startDate) {
        return warnings;
    }

    datesValidationOnChange(values.startDate, warnings);

    return warnings;
};
