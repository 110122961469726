import { connect } from 'react-redux';
import { getCurrentConferenceConnectInfoSelector } from 'selectors/getCurrentConferenceConnectInfoSelector';
import { loadDataForConnectAndConferencePagesThunkAction } from 'thunk/conference/loadDataForConnectAndConferencePagesThunkAction';
import { TAppDispatch } from 'types/app/TAppDispatch';
import { TAppState } from 'types/app/TAppStore';
import { redirect } from 'utils/redirect';
import { PinEnterPageMobile } from './PinEnterPageMobile';
import { IDispatchProps, IStateProps } from './types';

const mapStateToProps = (state: TAppState): IStateProps => ({
    conferenceConnectInfo: getCurrentConferenceConnectInfoSelector(state),
    //currentConferenceId: state.conferenceSession.currentConferenceId,
});

const mapDispatchToProps = (dispatch: TAppDispatch): IDispatchProps => ({
    loadData: (id) =>
        dispatch(loadDataForConnectAndConferencePagesThunkAction({ id, page: 'enter' })),
    goToHomePage: () => redirect('/'),
});

export const PinEnterPageMobileConnected = connect(
    mapStateToProps,
    mapDispatchToProps,
)(PinEnterPageMobile);
