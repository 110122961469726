import { IConferenceRecordsInfo } from 'types/conferencesList/IConferenceRecordsInfo';
import { IPastConferenceInfo } from 'types/conferencesList/IPastConferenceInfo';
import { IUpcomingConferenceInfo } from 'types/conferencesList/IUpcomingConferenceInfo';
import { IDatesRange } from 'types/dates/IDatesRange';
import { IConferenceRecord } from 'types/IConferenceRecord';
import { IDateFilters } from 'types/IDateFilters';
import { IUserData } from 'types/user/IUserData';

export interface IStateProps {
    upcomingConferences: { [id: string]: IUpcomingConferenceInfo };
    pastConferences: { [id: string]: IPastConferenceInfo };
    filters: IDateFilters;
    recordsList: IConferenceRecord[];
    conferenceIdsToShow: string[];
    scrollOffset: null | number;
    currentUser: IUserData | null;
    pastConferencesListOperationPending: boolean;
    upcomingConferencesListOperationPending: boolean;
}

export interface IDispatchProps {
    changeFilter: (filters: Partial<IDateFilters>) => Promise<any>;
    reset: () => void;
    clearScrollOffset: () => void;
    prepareToConnectToConference: (id: string) => void;
    goToEditConference: (id: string) => void;
    deleteConference: (id: string) => void;
    goToConferenceHistory: (data: {
        offset: number;
        conferenceId: string;
        sessionId: string;
    }) => void;
}

export interface IOwnProps {
    containerRef: any;
}
export type TConferenceItem =
    | IUpcomingConferenceInfo
    | (IPastConferenceInfo & IConferenceRecordsInfo);
export type TGroupContainerItem = {
    key: EConferencesGroup;
    dates: IDatesRange;
    conferences: TConferenceItem[];
};
export enum EConferencesGroup {
    Started = 'STARTED',
    Today = 'Today  ',
    Tomorrow = 'Tomorrow',
    Yesterday = 'Yesterday',
    ThisWeek = 'ThisWeek',
    NextWeek = 'NextWeek',
    LastWeek = 'LastWeek',
    ThisMonth = 'ThisMonth',
    NextMonth = 'NextMonth',
    LastMonth = 'LastMonth',
    InFuture = 'InFuture',
    InPast = 'InPast',
}
export const dateTemplate = 'dd.MM.yyyy';
