import { createAsyncThunk } from '@reduxjs/toolkit';
import { storeConferenceConnectInfoAction } from 'actions/conferences/storeConferenceConnectInfoAction';
import { storeConferenceInfoAction } from 'actions/conferences/storeConferenceInfoAction';
import { updateConferenceInfoApiCall } from 'api/conference/updateConferenceInfoApiCall';
import { getNetworkService } from 'api/getNetworkService';
import { TAppState } from 'types/app/TAppStore';
import { IConferenceInfo } from 'types/conferences/IConferenceInfo';
import { IThunkResult } from 'types/IThunkResult';
import { convertConferenceInfoToConferenceConnectInfo } from 'utils/conferences/convertConferenceInfoToConferenceConnectInfo';
import { getConferenceConnectInfoThunkAction } from './getConferenceConnectInfoThunkAction';
import { resetConferenceInvitedUsersAction } from 'actions/conferences/resetConferenceInvitedUsersAction';

interface IPayload {
    id: string;
    name?: string;
    timeZone?: string;
    pin?: string;
    lobby?: boolean;
    timeStart?: string;
    description?: string;
    recurring?: boolean;
    recording?: boolean;
    videoCreator?: boolean;
    videoParticipants?: boolean;
    muteUponEntry?: boolean;
    duration?: string;
    invitedUsers?: string[];
    startDateOfRecurrence?: string;
    endDateOfRecurrence?: string;
    cron?: string;
}

export const updateConferenceInfoThunkAction = createAsyncThunk<IThunkResult, IPayload>(
    'updateConferenceInfo',
    async (conferenceData, { dispatch, getState }) => {
        const {
            conferences: { conferencesInfo, conferencesConnectInfo },
        } = getState() as TAppState;
        interface Data {
            [key: string]: string | boolean | string[] | undefined;
        }
        const relatedFields = ['cron', 'startDateOfRecurrence', 'endDateOfRecurrence'];
        const dataToSend: Data = {};
        const conference = conferencesInfo[conferenceData.id];

        for (const field in conferenceData) {
            const f = field as keyof IPayload;
            const isItRelatedField = relatedFields.indexOf(f);
            if (conferenceData[f] !== conference[f]) {
                dataToSend[f] = conferenceData[f];
            }
            if (isItRelatedField !== -1) {
                dataToSend['cron'] = conferenceData['cron'];
                dataToSend['startDateOfRecurrence'] = conferenceData['startDateOfRecurrence'];
                dataToSend['endDateOfRecurrence'] = conferenceData['endDateOfRecurrence'];
            }
        }
        if (Object.keys(dataToSend).length !== 0) {
            const convertedData: Data = {
                ...dataToSend,
                id: conference.id,
            };
            // Swap recording & recurring to isRecording & isRecurring
            if (dataToSend.recording !== undefined) {
                convertedData.isRecording = dataToSend.recording;
                delete convertedData.recording;
            }
            if (!conferenceData.cron && conference.cron && !convertedData.timeStart) {
                convertedData.timeStart = conference.timeStart;
            }
            const response = await updateConferenceInfoApiCall(getNetworkService(), {
                id: conferenceData.id,
                ...convertedData,
            });
            if (response.success) {
                const newConference = response.data as IConferenceInfo;
                dispatch(storeConferenceInfoAction([newConference]));
                dispatch(resetConferenceInvitedUsersAction({ id: newConference.id }));
                if (newConference.status === 'STARTED') {
                    let newConferenceConnectInfo = conferencesConnectInfo[newConference.id];
                    if (!newConferenceConnectInfo) {
                        await dispatch(
                            getConferenceConnectInfoThunkAction({
                                id: newConference.id,
                                forceUpdate: true,
                            }),
                        );
                    } else {
                        newConferenceConnectInfo = convertConferenceInfoToConferenceConnectInfo(
                            newConference,
                            newConferenceConnectInfo.janusUrl,
                        );
                        dispatch(storeConferenceConnectInfoAction(newConferenceConnectInfo));
                    }
                }
            } else {
                return {
                    isSuccess: false,
                    errorCode: response?.data?.errorCode,
                    errorDescription: response?.data?.errorDescription,
                };
            }
        }
        return { isSuccess: true };
    },
);
