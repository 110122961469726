import { t } from '@lingui/macro';
import { Trans } from '@lingui/react';
import { Avatar } from 'components/common/Avatar/Avatar';
import { Button } from 'components/common/Button/Button';
import { Input } from 'components/common/Input/Input';
import { SelectField } from 'components/common/SelectField';
import { Spinner } from 'components/common/Spinner';
import { StatusLabel } from 'components/common/StatusLabel';
import { FormApi } from 'final-form';
import { useEffect, useRef, useState } from 'react';
import { Field, Form, FormRenderProps } from 'react-final-form';
import { setEscCloseDropdown } from 'utils/escCloseDropdown';
import { isFormHasValidationErrors } from 'utils/isFormHasValidationErrors';
import { getLanguageList } from 'utils/languagesOperations';
import { getTimeZonesNamesList } from 'utils/timeZones/getTimeZonesNamesList';
import { getAvatarUrl } from 'utils/users/getAvatarUrl';
import { getUserInitials } from 'utils/users/getUserInitials';
import styles from './AccountInformationForm.module.scss';
import { EmailChangeDialogConnected } from './EmailChangeDialog';
import {
    IAccountInformationFormRenderProps,
    IDispatchProps,
    IOwnProps,
    IStateProps,
} from './types';
import { getInitialValues, onChangeAvatar, validate } from './utils';

const timeZonesList = getTimeZonesNamesList();

export const AccountInformationForm: React.FC<IOwnProps & IDispatchProps & IStateProps> = ({
    user,
    canChangeAvatar,
    canEditPersonalData,
    maxAvatarSize,
    showNotification,
    submitHandler,
    onModified,
    resetModified,
}) => {
    const avatarRef = useRef<HTMLInputElement>(null);
    const [avatar, setAvatar] = useState<string | undefined>();
    const currentComponentRef = useRef(null);
    const [emailChangeDialogOpen, setEmailChangeDialogOpen] = useState(false);
    useEffect(() => {
        setEscCloseDropdown(emailChangeDialogOpen);
    }, [emailChangeDialogOpen]);
    const setAvatarFromServer = () => {
        if (user && user.etag) {
            setAvatar(getAvatarUrl({ id: user.id, etag: user.etag, size: '180' }));
        } else {
            setAvatar(undefined);
        }
    };
    useEffect(() => {
        setAvatarFromServer();
    }, [user]);
    const initials = getUserInitials({
        lastName: user?.name?.last,
        firstName: user?.name?.first,
        email: user?.contact.email,
    });
    const onSubmit = async (
        values: IAccountInformationFormRenderProps,
        form: FormApi<IAccountInformationFormRenderProps>,
    ) => {
        if (values.phone) {
            values.phone = values.phone.replace(/\D/g, '');
        }
        const result = await submitHandler({
            avatar,
            avatarInputNode: avatarRef.current,
            formValues: values,
            resetForm: () => form.reset(),
        });
        if (!result) {
            resetModified();
        }
        return result;
    };
    const removeAvatar = (form: any) => {
        if (avatar !== null) {
            form.change('avatarWasChanged', true);
        }
        setAvatar(undefined);
        if (avatarRef.current) {
            avatarRef.current.value = '';
        }
    };
    const showDellButton = (user?.etag && avatar) || avatar;
    const render = ({
        handleSubmit,
        submitting,
        submitError,
        errors,
        form,
        values,
        pristine,
        dirtySinceLastSubmit,
        dirty,
    }: FormRenderProps<IAccountInformationFormRenderProps>) => {
        setTimeout(() => onModified(dirty), 0);
        const disableButton = pristine || submitting;
        return (
            <>
                {emailChangeDialogOpen && (
                    <EmailChangeDialogConnected
                        handleClose={() => setEmailChangeDialogOpen(false)}
                    />
                )}
                <StatusLabel
                    text={!dirtySinceLastSubmit && submitError ? submitError : ''}
                    className={styles.statusLabel}
                />
                <form onSubmit={handleSubmit} className={styles.form} ref={currentComponentRef}>
                    <div className={styles.wrapper}>
                        <Field name='avatarWasChanged'>{() => null}</Field>
                        <div className={styles.avatar}>
                            <input
                                type='file'
                                className={styles.avatar__file}
                                ref={avatarRef}
                                onChange={() =>
                                    onChangeAvatar(
                                        form,
                                        avatarRef,
                                        maxAvatarSize,
                                        showNotification,
                                        setAvatar,
                                    )
                                }
                                accept='image/png, image/jpeg'
                            />
                            <Avatar
                                image={avatar}
                                initials={initials}
                                style={{
                                    cursor: canChangeAvatar ? 'pointer' : 'default',
                                    width: '150px',
                                    height: '150px',
                                }}
                                clickHandler={() => canChangeAvatar && avatarRef.current?.click()}
                            />
                            {/* TODO Hover avatar */}
                            {canChangeAvatar && showDellButton && (
                                <div
                                    className={styles.avatar__delete}
                                    onClick={() => removeAvatar(form)}>
                                    <Trans message='Удалить фотографию' id='Delete photo' />
                                </div>
                            )}
                        </div>
                        <div className={styles.information}>
                            <section>
                                <div className={styles.email}>
                                    <label className={styles.information__label} htmlFor='email'>
                                        <Trans id='Email' message='Электронная почта' />
                                    </label>
                                    <div className={styles.email__block}>
                                        <div>{values.email}</div>
                                        {canEditPersonalData && (
                                            <div
                                                className={styles.email__change}
                                                onClick={() => setEmailChangeDialogOpen(true)}>
                                                {t({ id: 'change', message: 'Изменить' })}
                                            </div>
                                        )}
                                    </div>
                                </div>
                            </section>
                            <section>
                                <div className={styles.information__userName}>
                                    <div>
                                        <label
                                            className={styles.information__label}
                                            htmlFor='firstName'>
                                            <Trans id='First name' message='Имя' />
                                        </label>
                                        <Input
                                            placeholder={t({ id: 'First name', message: 'Имя' })}
                                            name='firstName'
                                            type='text'
                                            className={styles.information__input}
                                            disabled={!canEditPersonalData}
                                            errorText={errors?.firstName}
                                        />
                                    </div>
                                    <div>
                                        <label
                                            className={styles.information__label}
                                            htmlFor='lastName'>
                                            <Trans id='Last name' message='Фамилия' />
                                        </label>
                                        <Input
                                            placeholder={t({ id: 'Last name', message: 'Фамилия' })}
                                            name='lastName'
                                            type='text'
                                            className={styles.information__input}
                                            disabled={!canEditPersonalData}
                                        />
                                    </div>
                                </div>
                            </section>
                            <section>
                                <label className={styles.information__label} htmlFor='phone'>
                                    <Trans id='Phone' message='Телефон' />
                                </label>
                                <Input
                                    name='phone'
                                    type='tel'
                                    placeholder='+x xxx xxx xx xx'
                                    pattern='\+\d+ [0-9]{3} [0-9]{3} [0-9]{2} [0-9]{2}'
                                    className={styles.information__input}
                                />
                            </section>
                            <section>
                                <label className={styles.information__label} htmlFor='timeZone'>
                                    <Trans id='timeZone' message='Часовой пояс' />
                                </label>
                                <SelectField
                                    className={styles.information_width100}
                                    items={timeZonesList}
                                    name='timeZone'
                                />
                            </section>
                            <section>
                                <label className={styles.information__label} htmlFor='language'>
                                    <Trans id='language' message='Язык' />
                                </label>
                                <SelectField
                                    className={styles.information_width100}
                                    items={getLanguageList()}
                                    name='language'
                                />
                            </section>
                            <div className={styles.information__buttons}>
                                <Button
                                    styleType={submitting ? 'loading' : 'primaryAction'}
                                    disabled={disableButton || isFormHasValidationErrors(errors)}
                                    type='submit'
                                    className={styles.buttons__save}>
                                    {submitting ? (
                                        <Spinner />
                                    ) : (
                                        t({ id: 'Save', message: 'Сохранить' })
                                    )}
                                </Button>
                                <Button
                                    styleType='secondaryAction'
                                    type='button'
                                    caption={t({ id: 'Cancel', message: 'Отмена' })}
                                    clickHandler={() => {
                                        form.restart();
                                        setAvatarFromServer();
                                    }}
                                    disabled={disableButton}
                                    className={styles.buttons__cancel}
                                />
                            </div>
                        </div>
                    </div>
                </form>
            </>
        );
    };

    return (
        <Form
            onSubmit={onSubmit}
            validate={validate}
            render={render}
            initialValues={getInitialValues(user)}
        />
    );
};
