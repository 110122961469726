import { INetworkService } from 'types/api/INetworkService';

interface IPayload {
    password: string;
    email: string;
}

export const changeEmailApiCall = (
    networkService: INetworkService,
    { password, email }: IPayload,
) =>
    networkService.post({ url: '/api/account/email/change', data: { password, email } });
