import { t } from '@lingui/macro';
import { Trans } from '@lingui/react';
import { Button } from 'components/common/Button';
import { useEffect } from 'react';
import { showNotifies } from '../LobbyAdminDialog/utils';
import styles from './LobbyAdminDialogMobile.module.scss';
import { IOwnProps } from './types';

export const LobbyAdminDialogMobile: React.FC<IOwnProps> = ({ displayName,isAudioNotification, onAllow, onDeny }) =>
{
    useEffect(() => {
        const cleanUpCallback = showNotifies(isAudioNotification);

        return () => {
            cleanUpCallback && cleanUpCallback();
        };
    }, []);

    return (
        <div className={styles.root}>
            <div>
                <div>{displayName}</div>
                <div>
                    <Trans
                        message='хочет подключиться к конференции'
                        id='wants to connect to the conference'
                    />
                </div>
            </div>
            <Button
                styleType='lobbyDenyMobile'
                clickHandler={onDeny}
                caption={t({ id: 'lobby.mobile.deny', message: 'Отклон.' })}
            />
            <Button
                styleType='lobbyAllowMobile'
                clickHandler={onAllow}
                caption={t({ id: 'lobby.mobile.allow', message: 'Разреш.' })}
            />
        </div>
    );
} 
