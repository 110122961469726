import { getChatMessagesThunkAction } from 'thunk/chat/getChatMessagesThunkAction';
import { TAppState } from 'types/app/TAppStore';
import { janusCtx } from 'utils/janus/janusCtx';
import { janusTextRoomLogger } from 'utils/logger';
import { randomString } from 'utils/randomString';
import { getDisplayName } from 'utils/users/getDisplayName';

export const joinTextroom = (): Promise<void> =>
    new Promise((resolve, reject) => {
        // Try join to room
        const transaction = randomString(12);
        const {
            auth: { currentUser, anonymousUser },
            janus: { ownParticipantId },
            conferenceSession: { currentConferenceId, currentConferencePin: pin, currentSessionId },
        } = janusCtx.getState() as TAppState;

        const join = {
            display: getDisplayName(currentUser || anonymousUser),
            pin,
            room: janusCtx.roomId,
            textroom: 'join',
            token: ownParticipantId,
            transaction,
            username: ownParticipantId, //janusCtx.myid,
        };
        janusCtx.textRoomTransactions[transaction] = (dataResponse: any) => {
            if (dataResponse.error_code) {
                reject({
                    error: dataResponse.error,
                    createTextRoomFirst: false, //dataResponse.error_code === 417,
                });
            }
            resolve();
        };
        janusCtx.textRoomPluginHandler.data({
            text: JSON.stringify(join),
            error: (error: any) => {
                janusTextRoomLogger.debug(`Join request failed ${error}`);
            },
            success: () => {
                janusCtx.dispatch(
                    getChatMessagesThunkAction({
                        conferenceId: currentConferenceId,
                        sessionId: currentSessionId,
                    }),
                );
                janusTextRoomLogger.debug('Join to room request success');
            },
        });
    });
