import { AnyAction, createAsyncThunk, ThunkDispatch } from '@reduxjs/toolkit';
import { setParticipantEmotionToShowAction } from 'actions/applicationView/setParticipantEmotionToShowAction';
import { setOwnEmotionToShowAction } from 'actions/janus/setOwnEmotionToShowAction';
// import { TAppDispatch } from 'types/app/TAppDispatch';
import { TAppState } from 'types/app/TAppStore';

interface IPayload {
    emotionName: string;
    participantId: string;
}

const setEmotionTimeout = (
    ownEmotion: boolean,
    participantId: string,
    emotionName: string,
    dispatch: ThunkDispatch<unknown, unknown, AnyAction>,
) => {
    if (ownEmotion) {
        const emotionTimer = setTimeout(() => {
            dispatch(setOwnEmotionToShowAction(null));
        }, 3000);
        dispatch(setOwnEmotionToShowAction({ emotionTimer, emotionName }));
    } else {
        const emotionTimer = setTimeout(() => {
            dispatch(setParticipantEmotionToShowAction({ participantId, emotionToShow: null }));
        }, 3000);
        dispatch(
            setParticipantEmotionToShowAction({
                emotionToShow: { emotionTimer, emotionName },
                participantId,
            }),
        );
    }
};

export const showParticipantEmotionThunkAction = createAsyncThunk<void, IPayload>(
    'showParticipantEmotion',
    async ({ emotionName, participantId }, { getState, dispatch }) => {
        const {
            janus: { ownParticipantId, ownEmotionToShow, publishers },
            applicationView: {participantsEmotion},
        } = getState() as TAppState;
        if (participantId === ownParticipantId) {
            if (ownEmotionToShow) {
                clearTimeout(ownEmotionToShow.emotionTimer);
            }
            setEmotionTimeout(true, participantId, emotionName, dispatch);
        } else {
            const emotion = participantsEmotion[participantId];
            if (publishers[participantId]) {
                if (emotion) {
                    clearTimeout(emotion.emotionTimer);
                }
                setEmotionTimeout(false, participantId, emotionName, dispatch);
            }
        }
    },
);
