import { Button } from 'components/common/Button';
import styles from './CreateConfBlock.module.scss';
import { IDispatchProps, IOwnProps, IStateProps } from './types';
import { PlanConferenceButtonConnected } from 'components/common/PlanConferenceButton';
import { Trans } from '@lingui/react';

export const CreateConfBlock: React.FC<IOwnProps & IDispatchProps & IStateProps> = ({
    className,
    isShortText,
    createNewConference,
    disabled,
}) => (
    <div className={className}>
        <div className={styles.name}>
            <Trans message='Создать конференцию' id='Create conference' />
        </div>
        <div className={styles.annotation}>
            <Trans
                message='Простой способ начать видео-конференцию прямо сейчас, либо запланировать на определенное время'
                id='An easy way to start a video conference right now, or schedule it for a certain time'
            />
        </div>
        <div className={styles.buttonGroup}>
            <Button
                className={styles.button}
                styleType='primaryAction'
                clickHandler={createNewConference}
                disabled={disabled}
                tabIndex={3}
                data-qa='startConferenceMainButton'>
                <Trans message='Начать конференцию' id='Start a conference' />
            </Button>
            <PlanConferenceButtonConnected isShortText={isShortText}/>
        </div>
    </div>
);
