import { EPaths } from 'constants/EPaths';
import { connect } from 'react-redux';
import { checkPinConferenceThunkAction } from 'thunk/conference/checkPinConferenceThunkAction';
import { getAndCheckConferenceForErrorsThunkAction } from 'thunk/conference/getAndCheckConferenceForErrorsThunkAction';
import { setCurrentConferenceDataThunkAction } from 'thunk/conferenceSession/setCurrentConferenceDataThunkAction';
import { TAppDispatch } from 'types/app/TAppDispatch';
import { TAppState } from 'types/app/TAppStore';
import { redirect } from 'utils/redirect';
import { JoinConferenceByLinkPage } from './JoinConferenceByLinkPage';
import { IDispatchProps, IStateProps } from './types';

const mapStateToProps = (state: TAppState): IStateProps => ({
    anonymous: state.auth.anonymous,
    conferencesConnectInfo: state.conferences.conferencesConnectInfo,
    rememberMeRequestStatus: state.auth.rememberMeRequestStatus,
});

const mapDispatchToProps = (dispatch: TAppDispatch): IDispatchProps => ({
    getAndCheckConference: (id) => dispatch(getAndCheckConferenceForErrorsThunkAction(id)),
    goToPinInputPage: (id) => redirect(`/enter/${id}`),
    goToUsernameInputPage: (id) => redirect(`/inputUsername/${id}`),
    goToLoginForJoinPage: () => redirect('/loginForJoin', { from: 'connectViaLink' }),
    setCurrentConferenceData: (data) => dispatch(setCurrentConferenceDataThunkAction(data)),
    goToConnectPage: (id, simpleConference) =>
        simpleConference
            ? redirect(`${EPaths.CONNECT_TO_CONFERENCE}/${id}?simpleConference=true`)
            : redirect(`${EPaths.CONNECT_TO_CONFERENCE}/${id}`),
    goToSimpleConnectPage: (id, recorderId) =>
        redirect(`${EPaths.SIMPLE_CONNECT_TO_CONFERENCE}/${id}?recorderId=${recorderId}`),
    checkPin: (data) => dispatch(checkPinConferenceThunkAction(data)).unwrap(),
});

export const JoinConferenceByLinkPageConnected = connect(
    mapStateToProps,
    mapDispatchToProps,
)(JoinConferenceByLinkPage);
