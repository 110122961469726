import { ESocketAppActions } from 'constants/ESocketAppActions';
import { TConferenceEvents } from 'types/socket/conferenceEvents/TConferenceEvents';

export const createConferenceEventsNotificationChecker = (
    actionType: TConferenceEvents,
    conferenceId: string,
    getCurrentConferenceId: () => string | undefined,
) => {
    if (
        actionType === ESocketAppActions.CONFERENCE_STARTED ||
        actionType === ESocketAppActions.CONFERENCE_SCHEDULED ||
        actionType === ESocketAppActions.CONFERENCE_UPDATED
    ) {
        return () => conferenceId === getCurrentConferenceId();
    }
};
