import { t } from '@lingui/macro';
import { Trans } from '@lingui/react';
import { Button } from 'components/common/Button';
import { ConferenceOwnerInfo } from 'components/common/ConferenceOwnerInfo';
import { format } from 'date-fns';
import { useEffect, useRef, useState } from 'react';
import { IConferenceParticipant } from 'types/IConferenceParticipant';
import { getDateInfoFromStartedConference } from 'utils/conferences/getDateInfoFromStartedConference';
import { convertInvitedUserFromServerToParticipant } from 'utils/convertInvitedUserFromServerToParticipant';
import { getParticipantAvatar } from 'utils/participants/getParticipantAvatar';
import { getBrowserTimeZone } from 'utils/timeZones/getBrowserTimeZone';
import { getTimeZoneName } from 'utils/timeZones/getTimeZoneName';
import { getUserTimeZone } from 'utils/timeZones/getUserTimeZone';
import { getAvatarUrl } from 'utils/users/getAvatarUrl';
import { getUserInitials } from 'utils/users/getUserInitials';
import { getOwnerData } from '../utils';
import { IDispatchProps, IStateProps } from './types';
import { VideoCallMember } from './VideoCallMember';
import styles from './VideoCallMembers.module.scss';
import { useGetCurrentParticipants } from './useGetCurrentParticipants';

export const VideoCallMembers: React.FC<IStateProps & IDispatchProps> = ({
    currentParticipants,
    currentParticipantsIds,
    conferenceId,
    anonymous,
    conferenceConnectInfo,
    getInvitedUsers,
    showNotificationError,
    goToHomePage,
    getConferenceParticipants,
    connectToConference,
    userIsConferenceOwner,
    isGettingCurrentParticipants,
}) => {
    if (!conferenceConnectInfo) {
        return null;
    }
    const owner = getOwnerData(conferenceConnectInfo?.creator);

    const [invitedUsers, setInvitedUsers] = useState<IConferenceParticipant[] | null | undefined>(
        null,
    );
    const [invitedUsersIsLoading, setInvitedUsersIsLoading] = useState(false);
    const [startTimerToUpdateParticipants, stopTimerToUpdateParticipants] =
        useGetCurrentParticipants(
            () => getConferenceParticipants(conferenceId),
            isGettingCurrentParticipants,
        );

    const conferenceStarted = conferenceConnectInfo.status === 'STARTED';

    const browserTimeZone = getBrowserTimeZone();
    const userTimeZone = getUserTimeZone();

    const getConferenceTimeStartInfo = () => {
        if (conferenceConnectInfo.timeStart) {
            const { startDate } = getDateInfoFromStartedConference(
                conferenceConnectInfo.timeStart,
                browserTimeZone,
                userTimeZone,
            );
            const date = format(startDate, 'dd/MM/yyyy HH:mm');
            const timeZone = getTimeZoneName(getUserTimeZone());
            return `${date} ${timeZone}`;
        }
        return '';
    };

    const getListInvitedUsers = async () => {
        setInvitedUsersIsLoading(true);
        const { payload: result } = await getInvitedUsers();
        if (result?.error) {
            showNotificationError();
            setInvitedUsers(undefined);
            goToHomePage();
            return;
        }
        setInvitedUsers(convertInvitedUserFromServerToParticipant(result || []));
        setInvitedUsersIsLoading(false);
    };

    useEffect(() => {
        return () => {
            stopTimerToUpdateParticipants();
        };
    }, []);

    useEffect(() => {
        if (!conferenceStarted) {
            if (!invitedUsersIsLoading && invitedUsers === null) {
                getListInvitedUsers();
            }
        } else {
            startTimerToUpdateParticipants();
        }
    }, [conferenceConnectInfo]);

    const buttonBlock = () => {
        if (!conferenceStarted && conferenceConnectInfo.waitCreator && !userIsConferenceOwner) {
            return (
                <div className={styles.startInfoWrapper}>
                    <span>
                        <Trans message='Конференция начнется' id='connectToConference.notStarted' />
                    </span>
                    <span>{getConferenceTimeStartInfo()}</span>
                </div>
            );
        } else if (
            !conferenceConnectInfo.waitCreator ||
            conferenceStarted ||
            userIsConferenceOwner
        ) {
            return (
                <Button
                    styleType='primaryAction'
                    caption={t({ message: 'Присоединиться', id: 'Join' })}
                    clickHandler={connectToConference}
                />
            );
        }
    };
    const avatarOwner = getAvatarUrl({ id: owner.id, size: '50' });
    let targetUserList: IConferenceParticipant[] = [];
    if (conferenceStarted && currentParticipants && currentParticipantsIds) {
        currentParticipantsIds.forEach((id) => targetUserList.push(currentParticipants[id]));
    } else {
        targetUserList = invitedUsers ? invitedUsers : [];
    }
    const membersList = targetUserList?.map((participant, idx) => {
        const avatar = getParticipantAvatar(participant);
        const [firstName, lastName] = participant.name.split(' ');
        const initials = getUserInitials({
            lastName,
            firstName,
            email: participant?.email,
        });
        const key = participant?.userId || participant.participantId || idx;
        return (
            <VideoCallMember
                key={key}
                initials={initials}
                name={participant.name}
                avatar={avatar}
                userId={participant.userId}
                email={participant.email}
            />
        );
    });

    return (
        <div className={styles.videoCallMembers}>
            <div className={styles.header}>
                <Trans message='Создатель конференции' id='Conference owner' />
            </div>
            <div className={styles.conferenceOwner}>
                <ConferenceOwnerInfo
                    anonymous={anonymous}
                    owner={{ avatar: avatarOwner, ...owner }}
                />
            </div>
            <div className={styles.header}>
                {conferenceStarted && <Trans message='Участники' id='Participants' />}
                {!conferenceStarted && (
                    <Trans
                        message='Приглашенные пользователи'
                        id='connectToConference.users.invited'
                    />
                )}
            </div>
            <div className={styles.membersBlock}>{membersList}</div>
            <div className={styles.button}>{buttonBlock()}</div>
        </div>
    );
};
