import { createAsyncThunk } from '@reduxjs/toolkit';
import { sendConferenceScreenSharingApiCall } from 'api/conference/sendConferenceScreenSharingApiCall';
import { getNetworkService } from 'api/getNetworkService';
import { TAppState } from 'types/app/TAppStore';

export const updateConferenceScreenSharingStateThunkAction = createAsyncThunk(
    'updateConferenceScreenSharingState',
    (isStarted: boolean, {  getState }) => {
        const {
            conferenceSession: { currentConferenceId },
        } = getState() as TAppState;
        if (!currentConferenceId) {
            return;
        }
        sendConferenceScreenSharingApiCall(getNetworkService(), {
            conferenceId: currentConferenceId,
            isStarted,
        });
    },
);
