import { isUserIsConferenceOwner } from 'utils/users/isUserIsConferenceOwner';
import { IOwnProps } from './types';
import { UpcomingConference } from './UpcomingConference';

export const UpcomingConferences: React.FC<IOwnProps> = ({
    conferences,
    currentUser,
    ...props
}) => (
    <>
        {conferences.map((conference) => {
            const currentUserIsOwner = isUserIsConferenceOwner(currentUser, conference);
            const started = conference.status === 'STARTED';
            const scheduled = !started;
            return (
                <UpcomingConference
                    key={conference.id}
                    conference={conference}
                    started={started}
                    scheduled={scheduled}
                    userIsOwner={currentUserIsOwner}
                    {...props}
                />
            );
        })}
    </>
);
